import { Injectable, inject } from '@angular/core';

import { ServiceLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';
import { EditServiceLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-task';

import { EditLaborRoleServiceTaskDto, ServiceLaborRoleTaskDto } from '../../../dto/estimate/modules/labor/labor-role-task.dto';
import { IMapperFromDto } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

import { CommonLaborRoleTaskPropertiesMapper } from './common-labor-role-task-properties.mapper';
import { LaborRoleHoursMapper } from './labor-role-hours.mapper';

/** Labor role service task entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleServiceTaskMapper implements IMapperFromDto<ServiceLaborRoleTaskDto, ServiceLaborRoleTask> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly commonLaborRoleTaskPropertiesMapper = inject(CommonLaborRoleTaskPropertiesMapper);

  private readonly laborRoleHoursMapper = inject(LaborRoleHoursMapper);

  /** @inheritdoc */
  public fromDto(dto: ServiceLaborRoleTaskDto): ServiceLaborRoleTask {
    return new ServiceLaborRoleTask({
      ...this.commonLaborRoleTaskPropertiesMapper.fromDto(dto),
      servicePercent: dto.servicePercent,
      hours: this.laborRoleHoursMapper.fromDto(dto),
    });
  }

  /**
   * Map to edit service task DTO.
   * @param data Data.
   */
  public toEditDto(data: EditServiceLaborRoleTask): EditLaborRoleServiceTaskDto {
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      code: this.confirmedOverridableMapper.toDto(data.code),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.originalLaborRoleId),
      servicePercent: data.servicePercent,
    };
  }
}
