import { inject, Injectable } from '@angular/core';

import { RecentProject } from '@dartsales/common/core/models/project/recent-project';

import { IMapperFromDto } from '../mappers';
import { RecentProjectDto } from '../dto/project/recent-project.dto';
import { DateMapper } from '../date-mapper';

/** Recent project mapper. */
@Injectable({
  providedIn: 'root',
})
export class RecentProjectMapper implements IMapperFromDto<RecentProjectDto, RecentProject> {

  private readonly dateMapper = inject(DateMapper);

  /** @inheritdoc */
  public fromDto(dto: RecentProjectDto): RecentProject {
    return {
      id: dto.id,
      name: dto.name,
      status: dto.status,
      salesperson: dto.salesperson,
      organizationId: dto.organizationId,
      organizationName: dto.organizationName,
      code: dto.number,
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      logoUrl: dto.organizationLogoDownloadUrl?.url ?? null,
    };
  }
}
