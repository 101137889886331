import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';
import { TermServiceTreeItem } from '@dartsales/common/core/models/estimate-services/term-service-tree-item';
import { TermSubCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-subcategory-tree-item';
import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';

import { NestedNode } from '../../nested-tree-select/models/nested-node';
import { FlatNode } from '../../nested-tree-select/models/flat-node';

export namespace EstimateItemsMultipleExportTreeTypes {

  /** Node type. */
  export enum NodeType {
    Null = 'Type for nodes whose value is not important',
    Term = 'Term',
    Category = 'Category',
    SubCategory = 'SubCategory',
    Service = 'Service',
    EstimateModuleType = 'EstimateModuleType',
    ServiceModuleType = 'ServiceModuleType',
  }

  /** Node value. */
  export type NodeValue<TType extends NodeType, TValue> = {

    /** Node type. */
    readonly type: TType;

    /** Item. */
    readonly item: TValue;

    /** Unique key. */
    readonly key: string;
  };

  export type NullNodeValue = NodeValue<NodeType.Null, null>;
  export type EstimateModuleTypeNodeValue = NodeValue<NodeType.EstimateModuleType, ModuleType>;
  export type TermNodeValue = NodeValue<NodeType.Term, TermTreeItem['value']>;
  export type CategoryNodeValue = NodeValue<NodeType.Category, TermCategoryTreeItem['value']>;
  export type SubCategoryNodeValue = NodeValue<NodeType.SubCategory, TermSubCategoryTreeItem['value']>;
  export type ServiceNodeValue = NodeValue<NodeType.Service, TermServiceTreeItem['value']>;
  export type ServiceModuleTypeNodeValue = NodeValue<NodeType.ServiceModuleType, ModuleType>;

  type EstimateChildrenNodeValue =
  | NullNodeValue
  | EstimateModuleTypeNodeValue
  | TermNodeValue
  | CategoryNodeValue
  | SubCategoryNodeValue
  | ServiceNodeValue
  | ServiceModuleTypeNodeValue;

  export type EstimateChildrenNestedNode = NestedNode<EstimateChildrenNodeValue>;
  export type EstimateChildrenFlatNode = FlatNode<EstimateChildrenNodeValue>;
}
