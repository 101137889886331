<ng-container
  *ngIf="catalogItemsSelectionService.selectedItems$ | async as selectedItems"
>
  <mat-chip
    *ngIf="selectedItems.length > 0"
    class="chip"
    [removable]="true"
    (removed)="catalogItemsSelectionService.clearSelectedItems()"
  >
    <span>
      {{ selectedItems.length | pluralize: "item" : "items" }} selected
    </span>
    <button
      matChipRemove
      type="button"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
</ng-container>
