import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { PartFilter } from '../../../../filters/multi-condition-filters/part-search-filters/part-filters';
import { MaterialPricingNoOverride } from '../material-pricing-no-override';
import { MaterialModuleLockablePercentsStatus } from '../properties/material-lockable-percents';

import { StandardMaterialItem } from './standard-material-item';

/** Standard material group. */
export class StandardMaterialGroup {
  /** ID. Null if it's default group. */
  public readonly id: number | null;

  /** Name. */
  public readonly name: string;

  /** Whether group is readonly or not. */
  public readonly isReadonly: boolean;

  /** Parts groups list. */
  public readonly partsGroups: readonly StandardMaterialItem[];

  /** Conditions. */
  public readonly conditions: readonly PartFilter[];

  /** Material group pricing. */
  public readonly pricing: MaterialPricingNoOverride;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: MaterialModuleLockablePercentsStatus;

  /** Whether group is default. */
  public readonly isDefault: boolean;

  public constructor(data: StandardMaterialGroupInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.isReadonly = data.isReadonly;
    this.partsGroups = data.partsGroups;
    this.conditions = data.conditions;
    this.pricing = data.pricing;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
    this.isDefault = data.id == null;
  }
}

export type StandardMaterialGroupInitArgs = StrictOmit<StandardMaterialGroup, 'isDefault'>;
