import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Progress bar. */
@Component({
  selector: 'dartsalesc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {

  /** Is loading. */
  @Input()
  public loading: boolean | null = false;
}
