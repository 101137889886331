import { Injectable, inject } from '@angular/core';

import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';

import { TermTreeItemDto } from '../dto/estimate-services/term-tree-item.dto';
import { TermExportItemDto } from '../dto/export/term-export-items.dto';
import { IMapperFromDto } from '../mappers';

import { TermCategoryTreeItemMapper } from './term-category-tree-item.mapper';

/** Term tree item mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermTreeItemMapper implements IMapperFromDto<TermTreeItemDto, TermTreeItem> {

  private readonly termCategoryTreeItemMapper = inject(TermCategoryTreeItemMapper);

  /** @inheritdoc */
  public fromDto(dto: TermTreeItemDto): TermTreeItem {
    return new TermTreeItem({
      value: {
        termId: dto.termId,
      },
      title: dto.termName,
      children: dto.categories.map(category => this.termCategoryTreeItemMapper.fromDto(category)),
    });
  }

  /** @inheritdoc */
  public toDto(data: TermTreeItem): TermExportItemDto {
    return {
      termId: data.value.termId,
      categories: data.children.map(category => this.termCategoryTreeItemMapper.toDto(category)),
    };
  }
}
