<mat-chip-grid
  #chipGrid
  aria-label="Member selection"
  [class.chips-container]="controlValue?.length"
  [disabled]="searchControl.disabled"
>
  <mat-chip-row
    *ngFor="let item of controlValue; trackBy: trackByIndex; let index = index"
    (removed)="removeChipItem(index)"
  >
    <span [title]="item">
      {{ item }}
    </span>
    <button
      *ngIf="!disabled"
      matChipRemove
      type="button"
      [attr.aria-label]="'remove ' + item"
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip-row>
</mat-chip-grid>
<div
  *ngIf="!disabled"
  #origin="matAutocompleteOrigin"
  matAutocompleteOrigin
  class="search"
  matTooltipPosition="above"
  matTooltipClass="array-input-tooltip"
  [matTooltip]="tooltipText"
  [matTooltipDisabled]="shouldDisableTooltip"
>
  <input
    matInput
    class="search__input"
    type="text"
    [maxlength]="maxLength"
    [placeholder]="placeholder"
    [formControl]="searchControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="origin"
    (click)="focus()"
    (matChipInputTokenEnd)="addChipItem($event)"
  />
  <mat-icon class="search__icon">arrow_drop_down</mat-icon>
</div>
<mat-autocomplete
  #auto
  dartsalescFixedScroll
  class="chips-autocomplete"
  [hideSingleSelectionIndicator]="true"
  (optionSelected)="onOptionSelected($event)"
>
  <div class="progress-bar">
    <mat-progress-bar
      *ngIf="isLoading"
      mode="indeterminate"
    />
  </div>
  <div
    *ngIf="optionsList"
    infiniteScroll
    class="options"
    infiniteScrollContainer=".chips-autocomplete"
    [fromRoot]="true"
    [infiniteScrollDisabled]="!auto.isOpen"
    (scrolled)="onOptionsScroll()"
  >
    <div class="hint">{{ suggestedText }}</div>
    <!-- We do not need trackBy function because we get updated options list. -->
    <!-- eslint-disable @angular-eslint/template/use-track-by-function -->
    <mat-option
      *ngFor="let item of optionsList"
      [value]="item"
    >
      {{ item }}
    </mat-option>
  </div>
</mat-autocomplete>
