import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';

/** Sort filters section. */
@Component({
  selector: 'dartsalesw-sort-section',
  templateUrl: './sort-section.component.html',
  styleUrls: ['./sort-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortSectionComponent {
  /**
   * Whether current filter used to sort.
   * Table can have several sort filters, but only one at a time can be used.
   */
  @Input()
  public isActive = false;

  /** Sort direction. */
  @Input()
  public sortDirection = SortDirection.ASC;

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  /** Whether is sort active and ascending. */
  protected get isAscendingSortActive(): boolean {
    return this.sortDirection === SortDirection.ASC && this.isActive;
  }

  /** Whether is sort active and descending. */
  protected get isDescendingSortActive(): boolean {
    return this.sortDirection === SortDirection.DESC && this.isActive;
  }

  /** Handle ascending sort button click. */
  protected onAscendingSortClick(): void {
    this.sortDirectionChange.emit(SortDirection.ASC);
  }

  /** Handle descending sort button click. */
  protected onDescendingSortClick(): void {
    this.sortDirectionChange.emit(SortDirection.DESC);
  }
}
