import { PortPropertyType } from '@dartsales/common/core/enums/port-property-type';

import { ConfirmedOverridable } from '../../confirmed-overridable';

/** Abstract estimate part port property. */
abstract class AbstractPartPortProperty {

  /** Type. */
  public readonly type: PortPropertyType;

  /** Value. */
  public abstract readonly value: ConfirmedOverridable<string> | string;

  public constructor(data: AbstractPartPortPropertyInitArgs) {
    this.type = data.type;
  }
}

type AbstractPartPortPropertyInitArgs = AbstractPartPortProperty;

/** Overridable estimate part port property. */
export class OverridablePartPortProperty extends AbstractPartPortProperty {

  /** @inheritdoc */
  public readonly value: ConfirmedOverridable<string>;

  public constructor(data: OverridablePartPortPropertyInitArgs) {
    super(data);
    this.value = data.value;
  }
}

type OverridablePartPortPropertyInitArgs = OverridablePartPortProperty;

/** Estimate part port property. */
export class PartPortProperty extends AbstractPartPortProperty {

  /** @inheritdoc */
  public readonly value: string;

  public constructor(data: PartPortPropertyInitArgs) {
    super(data);
    this.value = data.value;
  }
}

type PartPortPropertyInitArgs = PartPortProperty;
