import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { BulkUpdateOption } from '@dartsales/common/core/models/bulk-update-option';

import { AbstractTableCellBulkUpdateComponent } from '../abstract-table-cell-bulk-update/abstract-table-cell-bulk-update.component';

/** Table cell bulk update component. */
@Component({
  selector: 'dartsalesc-table-cell-bulk-update',
  templateUrl: './table-cell-bulk-update.component.html',
  styleUrls: [
    '../abstract-table-cell-bulk-update/abstract-table-cell-bulk-update.component.css',
    './table-cell-bulk-update.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellBulkUpdateComponent extends AbstractTableCellBulkUpdateComponent {
  /**
   * Tooltip removed text.
   * @deprecated Use `TableCellBulkUpdateRemoveComponent` instead.
   */
  @Input()
  public tooltipRemovedText: string | null = null;

  /** Are all updates confirmed. */
  @Input()
  public isUpdateConfirmed = true;

  /**
   * Is remove confirmed.
   * @deprecated Use `TableCellBulkUpdateRemoveComponent` instead.
   */
  @Input()
  public isRemoveConfirmed = true;

  /**
   * Apply update button click event.
   * @deprecated Use `confirmUpdate` event instead.
   */
  @Output()
  public readonly applyUpdateClick = new EventEmitter<void>();

  /**
   * Discard update button click event.
   * @deprecated Use `confirmUpdate` event instead.
   */
  @Output()
  public readonly discardUpdateClick = new EventEmitter<void>();

  /**
   * Remove message template.
   * @deprecated Use `TableCellBulkUpdateRemoveComponent` instead.
   */
  @ContentChild('removeMessage')
  public readonly removeMessage?: TemplateRef<unknown>;

  /** Bulk update buttons template. */
  @ContentChild('buttons')
  public readonly buttons?: TemplateRef<unknown>;

  /** Is bulk update available. */
  protected get isBulkUpdateAvailable(): boolean {
    return !(this.isUpdateConfirmed && this.isRemoveConfirmed);
  }

  /** Get tooltip text. */
  protected getTooltipText(): string {
    if (this.isRemoveConfirmed === false) {
      return this.tooltipRemovedText ?? this.tooltipText ?? `Item was removed. Click here to apply changes.`;
    }

    return this.tooltipText ?? `Item was updated. Click here to apply changes.`;
  }

  /** @inheritdoc */
  protected override onButtonClick(selectedOption: BulkUpdateOption): void {
    super.onButtonClick(selectedOption);

    // TODO (DART-1699): Remove this code after 'applyUpdateClick' and 'discardUpdateClick' are removed.
    if (selectedOption === BulkUpdateOption.ResourceValue) {
      this.applyUpdateClick.emit();
    } else {
      this.discardUpdateClick.emit();
    }
  }

}
