import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

import { FlatNode } from '../models/flat-node';
import { NestedNode } from '../models/nested-node';

/** Nested tree service. */
export class NestedTreeService<T> {
  private readonly treeFlattener = new MatTreeFlattener(
    this.mapNestedToFlat.bind(this),
    flatNode => flatNode.level,
    flatNode => flatNode.isExpandable,
    nestedNode => [...nestedNode.children],
  );

  /** @inheritdoc */
  public readonly treeControl = new FlatTreeControl<FlatNode<T>>(row => row.level, row => row.isExpandable);

  /** @inheritdoc */
  public readonly dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  /**
   * Check has children.
   * @param _index _index.
   * @param node Node.
   */
  public checkHasChildren(_index: number, node: FlatNode<T>): boolean {
    return node.isExpandable;
  }

  /**
   * Set tree nodes.
   * @param nodes Nodes.
   */
  public setNestedNodes(nodes: readonly NestedNode<T>[]): void {
    this.dataSource.data = [...nodes];
  }

  /**
   * Get parent node.
   * This algorithm was taken from the angular material documentation
   * https://v15.material.angular.io/components/tree/examples#tree-checklist.
   * @param node Node whose parent we are looking for.
   */
  protected getParentNode(node: FlatNode<T>): FlatNode<T> | null {
    const currentLevel = node.level;

    if (currentLevel === 0) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }

  /**
   * Map nested node to flat.
   * @param nestedNode Nested node.
   * @param level Nested level.
   */
  private mapNestedToFlat(nestedNode: NestedNode<T>, level: number): FlatNode<T> {
    return {
      level,
      isExpandable: nestedNode.children.length > 0,
      title: nestedNode.title,
      value: nestedNode.value,
      hasBorder: nestedNode.hasBorder ?? false,
    };
  }
}

export type INestedTreeService<T> = NestedTreeService<T>;
