import { TreeItem } from '@dartsales/common/core/models/tree-item';

import { FlatNode } from '../../nested-tree-select/models/flat-node';
import { NestedNode } from '../../nested-tree-select/models/nested-node';

import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';
import { EstimateItemsMultipleExportTreeMapperStrategies as MapperStrategies } from './estimate-items-multiple-export-tree-mapper-strategies';

export namespace EstimateItemsMultipleExportTreeUtils {

  /**
   * Filter not selected children.
   * @param nestedNodeTree Nested node tree.
   * @param selectedNodes Selected nodes.
   */
  export function filterNotSelectedChildren<T extends Types.NodeValue<Types.NodeType, unknown>>(
    nestedNodeTree: readonly NestedNode<T>[],
    selectedNodes: readonly FlatNode<T>[],
  ): NestedNode<T>[] {
    const filteredNodes = nestedNodeTree.filter(node => {
      const isNodeSelected = selectedNodes.find(selectedNode => node.value.key === selectedNode.value.key);
      return isNodeSelected;
    });

    const filteredNodesWithFilteredChildren = filteredNodes.map(node => {
      if (node.children.length === 0) {
        return node;
      }

      return {
        ...node,
        children: filterNotSelectedChildren(node.children, selectedNodes),
      };
    });

    return filteredNodesWithFilteredChildren;
  }

  /**
   * Map nodes to items.
   * @param nodes Nodes.
   * @param childrenMappingStrategy Children mapping strategy.
   */
  export function mapItems<TValue, TChild>(
    nodes: readonly NestedNode<Types.NodeValue<Types.NodeType, unknown>>[],
    childrenMappingStrategy: MapperStrategies.NodeMapperStrategy<TValue, TChild>,
  ): TreeItem<TValue, TChild>[] {
    return nodes.reduce<TreeItem<TValue, TChild>[]>((items, node) => {
      if (node.value.type !== childrenMappingStrategy.type) {
        return items;
      }

      const item = childrenMappingStrategy.map(node);
      if (item != null) {
        return items.concat(item);
      }
      return items;
    }, []);
  }
}
