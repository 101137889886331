import { StrictOmit } from '../../utils/types/strict-omit';
import { BasePricingOverrides } from '../pricing/base-pricing-overrides';

import { TermSummary } from './term-summary';
import { TermData } from './term-data';

/** Term (detailed model). */
export class Term extends TermSummary {

  /** Term data. */
  public readonly data: TermData;

  public constructor(data: TermInitArgs) {
    super(data);

    this.data = data.data;
  }
}

type TermInitArgs = StrictOmit<Term, 'name'>;

/** Term edit model. */
export class TermEdit {

  /** Term number. If null, number won't be updated. */
  public readonly number: number | null;

  /** Properties overrides. If null, properties won't be updated. */
  public readonly propertiesOverrides: BasePricingOverrides | null;

  public constructor(data: Partial<TermEdit>) {
    this.number = data.number ?? null;
    this.propertiesOverrides = data.propertiesOverrides ?? null;
  }
}
