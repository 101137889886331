import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';

/** Readonly amount percent display component. */
@Component({
  selector: 'dartsalesw-readonly-amount-percent-display',
  templateUrl: './readonly-amount-percent-display.component.html',
  styleUrls: ['./readonly-amount-percent-display.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyAmountPercentDisplayComponent {

  /** Value. */
  @Input({ required: true })
  public value: AmountPercent | null = null;

  /** Units. */
  @Input()
  public units = AmountCalcUnits.DEFAULT;

  /** Amount calc units. */
  protected readonly amountCalcUnits = AmountCalcUnits;
}
