import { ContentChild, Directive } from '@angular/core';

import { TableCellInputDirective } from './table-cell-input.directive';

/** Edit mode for table cell. */
@Directive({
  selector: '[dartsalescTableCellEditMode]',
})
export class TableCellEditModeDirective {
  /** Input. */
  @ContentChild(TableCellInputDirective)
  public readonly input?: TableCellInputDirective;
}
