import { FilterData } from '../../filter-data';
import { isEmpty } from '../../shared-filter-operators';
import { bidCategoriesSelect, bidLeadsSelect, bidMarketSelect } from '../../shared-filter-select-operators';

/** Bid Info field for filter. */
export enum BidInfoField {
  Markets = 'bidInfoMarket',
  BidCategories = 'bidInfoCategoryIds',
  Lead = 'bidInfoLead',
}

export namespace BidInfoField {
  export const GROUP_NAME = 'Bid Info';

  const markets = {
    type: BidInfoField.Markets,
    label: 'Markets',
    operators: [
      ...bidMarketSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<BidInfoField>;

  const bidCategories = {
    type: BidInfoField.BidCategories,
    label: 'Bid Categories',
    operators: [
      ...bidCategoriesSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<BidInfoField>;

  const lead = {
    type: BidInfoField.Lead,
    label: 'Lead',
    operators: [...bidLeadsSelect] as const,
  } satisfies FilterData<BidInfoField>;

  const ALL_BID_INFO_FIELDS = [
    markets,
    lead,
    bidCategories,
  ] as const;

  /** Get all fields. */
  export function getAllFields(): typeof ALL_BID_INFO_FIELDS {
    return ALL_BID_INFO_FIELDS;
  }
}
