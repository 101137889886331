/** API transaction identifier. This entity is used to store ID of transaction which can have undo/redo actions. */
export class ApiTransactionId {
  /** UUID of the transaction. */
  public readonly id: string;

  public constructor(data: ApiTransactionIdInitArgs) {
    this.id = data.id;
  }
}

type ApiTransactionIdInitArgs = ApiTransactionId;
