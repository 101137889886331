import { Injectable } from '@angular/core';

import { UserEstimate } from '../../models/user/user-estimate';

import { IMapperFromDto } from './mappers';
import { UserEstimateDto } from './dto/user/user-estimate.dto';

/** User estimate mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserEstimateMapper implements IMapperFromDto<UserEstimateDto, UserEstimate> {

  /** @inheritdoc */
  public fromDto(dto: UserEstimateDto): UserEstimate {
    return new UserEstimate({
      id: dto.id,
      name: dto.name,
      isCreator: dto.isCreator,
    });
  }
}
