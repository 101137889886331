<div class="cell">
  <dartsalesc-masked-number-cell-editor
    *ngIf="isEditMode$ | async; else rendererMode"
    class="editor"
    [ngClass]="getCellCssClasses()"
    [errorStateMatcher]="errorStateMatcher"
    [mask]="mask"
    [formControl]="formControl"
    [placeholder]="placeholder"
  />
  <ng-template #rendererMode>
    <dartsalesc-masked-number-cell-renderer
      dartsalescTableCellInput
      class="renderer"
      [ngClass]="getCellCssClasses()"
      [value]="controlValue?.combinedValue ?? null"
      [mask]="mask"
      [isFocusable]="(isFocusable$ | async) ?? false"
      [placeholder]="placeholder"
    />
  </ng-template>

  <div [ngClass]="getResetButtonCssClasses()">
    <button
      *ngIf="isResetAvailable && (isReadonly$ | async) === false"
      mat-icon-button
      type="button"
      class="reset-button"
      [matTooltip]="tooltipMessage"
      (click)="onResetButtonClick()"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
