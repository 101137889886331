<div
  *ngIf="status !== null"
  class="status"
>
  <div
    class="status__color-indicator"
    [ngClass]="toCSSClass(status)"
  ></div>
  <span class="body-2">{{ projectStatus.toReadable(status) }}</span>
</div>
