<mat-form-field class="no-label">
  <mat-select
    placeholder="Select"
    [multiple]="true"
    [formControl]="formControl"
  >
    <mat-option
      *ngFor="let item of data.options; trackBy: trackByIndex"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
