import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { BidCategory, BidCategoryEdit } from '../../models/resources/bid-category';
import { BidCategoryMapper } from '../mappers/resources/bid-category.mapper';
import { BidCategoryDto } from '../mappers/dto/resources/bid-category.dto';
import { BidCategoryFiltersDto } from '../mappers/dto/filters/bid-category-filters.dto';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { SortMapper } from '../mappers/sort.mapper';
import { SortDirection } from '../../enums/sort-direction';
import { buildHttpParams } from '../../utils/build-http-params';
import { getBiggestDefaultOrder } from '../../utils/getBiggestOrder';

import { AppUrlsConfig } from './app-urls.config';

/** Bid category API service. */
@Injectable({
  providedIn: 'root',
})
export class BidCategoryApiService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly http: HttpClient,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly bidCategoryMapper: BidCategoryMapper,
    private readonly sortMapper: SortMapper,
  ) { }

  /** Get all available for user bid categories. */
  public getUserBidCategories(): Observable<BidCategory[]> {
    return this.http.get<BidCategoryDto[]>(this.apiUrls.bidCategoryApi.getAvailableForUser).pipe(
      map(dtos => dtos.map(item => this.bidCategoryMapper.fromDto(item))),
    );
  }

  /**
   * Get list of bid categories.
   * @param organizationId Organization id.
   */
  public getBidCategories(organizationId: number): Observable<BidCategory[]> {
    const filters: BidCategoryFiltersDto = {
      organizationId,
      includeRemoved: false,
    };
    const params = buildHttpParams({
      ...filters,
      ...this.sortMapper.toDto({ field: 'order', direction: SortDirection.ASC }),
    });
    return this.http.get<BidCategoryDto[]>(this.apiUrls.bidCategoryApi.getList, {
      params,
    }).pipe(
      map(dtos => dtos.map(item => this.bidCategoryMapper.fromDto(item))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create bid category.
   * @param organizationId Organization id.
   * @param data Bid category edit model.
   */
  public createBidCategory(organizationId: number, data: BidCategoryEdit): Observable<void> {
    const createdBidCategoryDto = this.bidCategoryMapper.toDto(data);

    return this.http.post<void>(this.apiUrls.bidCategoryApi.create, createdBidCategoryDto, {
      params: {
        organizationId,
      },
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.bidCategoryMapper,
      ),
    );
  }

  /**
   * Update bid category.
   * @param id Bid category id.
   * @param data Bid category edit model.
   */
  public updateBidCategory(id: number, data: BidCategoryEdit): Observable<void> {
    const editDto = this.bidCategoryMapper.toDto(data);

    return this.http.put<void>(this.apiUrls.bidCategoryApi.update(id), editDto).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.bidCategoryMapper,
      ),
    );
  }

  /**
   * Delete bid category.
   * @param id Bid category id.
   */
  public deleteBidCategory(id: number): Observable<void> {
    return this.http.delete<void>(this.apiUrls.bidCategoryApi.delete(id)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update bid categories order.
   * @param values Bid categories.
   */
  public updateBidCategoriesOrder(values: readonly BidCategory[]): Observable<void> {
    const biggestDefaultOrder = getBiggestDefaultOrder(values);
    const dtos = values
      .filter(value => !value.isDefault)
      .map((value, index) => this.bidCategoryMapper.mapBidCategoryOrderToDto(value, biggestDefaultOrder + index));

    return this.http.post<void>(this.apiUrls.bidCategoryApi.updateOrder, {
      resourceOrders: dtos,
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
