import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { assertNonNullWithReturn } from '@dartsales/common/core/utils/assert-non-null';
import { pluralize } from '@dartsales/common/core/utils/pluralize';
import { AnyObject } from '@dartsales/common/core/utils/types/any-object';

/** Get field function. */
export type GetFieldFn<TRow> = (row: TRow) => ConfirmedOverridable<unknown>;

/**
 * Table column bulk update.
 * NOTE: It's a temporary solution, because rows bulk update isn't ready yet.
 * TODO: Refactor to reduce complexity.
 */
@Component({
  selector: 'dartsalesc-table-column-bulk-update',
  templateUrl: './table-column-bulk-update.component.html',
  styleUrls: ['./table-column-bulk-update.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnBulkUpdateComponent<TRow extends AnyObject> {

  /** Callbacks to get updated fields. */
  @Input({ required: true })
  public getFieldCallbacks: readonly GetFieldFn<TRow>[] = [];

  /** Callback to get is removed field. If not provided, it won't show remove message. */
  @Input()
  public getIsRemovedField: GetFieldFn<TRow> | null = null;

  /** Rows. */
  @Input({ required: true })
  public rows: readonly TRow[] = [];

  /** Field name in singular form. */
  @Input()
  public singularFieldName = 'Item';

  /** Field name in plural form. */
  @Input()
  public pluralFieldName = 'Items';

  /** Row name in singular form. */
  @Input()
  public singularRowName = 'Row';

  /** Row name in plural form. */
  @Input()
  public pluralRowName = 'Rows';

  /** Max width of bulk update button in percent. Default is 50. */
  @Input()
  public maxWidth = 50;

  /** Bulk update. */
  @Output()
  public bulkUpdate = new EventEmitter<boolean>();

  /** Tooltip text. */
  protected get tooltipText(): string {
    return `Multiple ${this.pluralFieldName} were updated. Click here to apply changes.`;
  }

  /**
   * Check if all rows are confirmed.
   * @param rows Rows to check.
   */
  protected areAllRowsConfirmed(rows: readonly TRow[]): boolean {
    const getFieldCallbacks = assertNonNullWithReturn(this.getFieldCallbacks);

    return rows.every(
      row =>
        getFieldCallbacks.every(getFieldCallback => getFieldCallback(row).isConfirmed) &&
        (this.getIsRemovedField?.(row).isConfirmed ?? true),
    );
  }

  /**
   * Has remove message.
   * @param rows Rows to check.
   */
  protected hasRemoveMessage(rows: readonly TRow[]): boolean {
    return this.getRemovedFieldsCount(rows) > 0;
  }

  /**
   * Get remove message.
   * @param rows Rows to check.
   */
  protected getRemoveMessage(rows: readonly TRow[]): string {
    return pluralize({
      count: this.getRemovedFieldsCount(rows),
      singular: `${this.singularRowName} was removed in the Resources section.`,
      plural: `${this.pluralRowName} were removed in the Resources section.`,
    });
  }

  /**
   * Has update message.
   * @param rows Rows to check.
   */
  protected hasUpdateMessage(rows: readonly TRow[]): boolean {
    return this.getUpdatedFieldsCount(rows) > 0;
  }

  /**
   * Get update message.
   * @param rows Rows to check.
   */
  protected getUpdateMessage(rows: readonly TRow[]): string {
    return pluralize({
      count: this.getUpdatedFieldsCount(rows),
      singular: `${this.singularFieldName} was updated in the Resources section.`,
      plural: `${this.pluralFieldName} were updated in the Resources section.`,
    });
  }

  /**
   * Get removed fields count.
   * @param rows Rows to check.
   */
  private getRemovedFieldsCount(rows: readonly TRow[]): number {
    const { getIsRemovedField } = this;
    if (getIsRemovedField == null) {
      return 0;
    }
    return rows.filter(row => !getIsRemovedField(row).isConfirmed).length;
  }

  /**
   * Get updated fields count.
   * @param rows Rows to check.
   */
  private getUpdatedFieldsCount(rows: readonly TRow[]): number {
    const getFieldCallbacks = assertNonNullWithReturn(this.getFieldCallbacks);
    return rows.filter(row => getFieldCallbacks.some(getFieldCallback => !getFieldCallback(row).isConfirmed)).length;
  }
}
