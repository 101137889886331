import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';

import { ProjectSidebarService } from '@dartsales/common/core/services/project-sidebar.service';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';

import { PROJECT_LAYOUT_PROVIDERS } from './project-layout.providers';

/** Project layout component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...PROJECT_LAYOUT_PROVIDERS],
})
export class ProjectLayoutComponent implements OnInit {

  private readonly sidebarService = inject(ProjectSidebarService);

  private readonly layoutService = inject(ProjectLayoutService);

  private readonly titleService = inject(Title);

  /** Whether is sidebar opened or not.  */
  protected readonly isSidebarOpened$ = this.sidebarService.isSidebarOpened$;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToTitleChange();
  }

  /** Toggle sidebar. */
  protected toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  private subscribeToTitleChange(): void {
    this.layoutService.project$.pipe(
      tap(project => this.titleService.setTitle(`${project.projectInfo.details.name} | ${project.projectInfo.details.projectNumber}`)),
      finalize(() => this.titleService.setTitle('Div25 Sales App')),
      untilDestroyed(this),
    ).subscribe();
  }
}
