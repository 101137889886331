import { MaterialPricingWithOverride } from '../material-pricing-with-override';

import { AbstractMaterialItem } from './abstract-material-item';

/** Standard material group of parts. */
export class StandardMaterialItem extends AbstractMaterialItem {

  /** Parts group ID. */
  public readonly partsGroupId: string;

  /** Part IDs. */
  public readonly partIds: readonly string[];

  /** Item pricing. */
  public readonly pricing: MaterialPricingWithOverride;

  public constructor(data: StandardMaterialItemInitArgs) {
    super(data);
    this.partsGroupId = data.partsGroupId;
    this.partIds = data.partIds;
    this.pricing = data.pricing;
  }
}
type StandardMaterialItemInitArgs = StandardMaterialItem;

/** Parts group common properties. */
export class PartsGroupCommonProperties {

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Model number. */
  public readonly modelNumber: string;

  /** Tags list. */
  public readonly tags: readonly string[];

  /** Unit cost. */
  public readonly unitCost: number;

  /** Manufacturer. */
  public readonly manufacturer: string;

  /** Supplier. */
  public readonly supplier: string;

  public constructor(data: PartsGroupCommonPropertiesInitArgs) {
    this.name = data.name;
    this.description = data.description;
    this.modelNumber = data.modelNumber;
    this.tags = data.tags;
    this.unitCost = data.unitCost;
    this.manufacturer = data.manufacturer;
    this.supplier = data.supplier;
  }
}

type PartsGroupCommonPropertiesInitArgs = PartsGroupCommonProperties;
