<span class="header-units-label">
  {{ select.triggerValue }}
</span>
<mat-select
  #select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="formControl"
  (selectionChange)="selectionChange.emit($event.value)"
>
  <mat-option
    *ngFor="let item of options; trackBy: trackByItem"
    class="header-units-select__option"
    [value]="item.value"
    [class.header-units-select__option_hidden]="
      item.value === formControl.value
    "
  >
    {{ item.label }}
  </mat-option>
</mat-select>
