/** Amount calc units. */
export enum AmountCalcUnits {
  Amount = 'amount',
  Percent = 'percent',
}

export namespace AmountCalcUnits {
  const TO_TITLE_MAP: Record<AmountCalcUnits, string> = {
    [AmountCalcUnits.Amount]: '$',
    [AmountCalcUnits.Percent]: '%',
  };

  /** Default amount calc units. */
  export const DEFAULT = AmountCalcUnits.Percent;

  /**
   * Converts a certain amount calc to readable title.
   * @param value Amount calc.
   */
  export function toReadable(value: AmountCalcUnits): string {
    return TO_TITLE_MAP[value];
  }
}
