import { Injectable, inject } from '@angular/core';

import { AbstractMaterialPricing } from '@dartsales/common/core/models/estimate/modules/material/abstract-material-pricing';
import { ClassProperties } from '@dartsales/common/core/utils/types/class-properties';

import { IMapperFromDto } from '../../../mappers';
import { BaseMaterialPricingDto } from '../../../dto/estimate/modules/material/data/material-pricing.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { AmountPercentMapper } from '../../amount-percent.mapper';

/** Abstract material pricing entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class AbstractMaterialPricingMapper implements
  IMapperFromDto<BaseMaterialPricingDto, ClassProperties<AbstractMaterialPricing>> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  /** @inheritdoc */
  public fromDto(dto: BaseMaterialPricingDto): ClassProperties<AbstractMaterialPricing> {
    return {
      ...this.basePricingMapper.fromDto(dto),
      totalDirectCost: dto.totalDirectCost,
      unitCost: dto.unitCost,
      warranty: this.amountPercentMapper.fromDto(dto.warranty),
      freight: this.amountPercentMapper.fromDto(dto.freight),
      salesTax: this.amountPercentMapper.fromDto(dto.salesTax),
    };
  }
}
