import { ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  Observable, OperatorFunction,
  Subject, throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, AppValidationError, EntityValidationErrors } from '../../models/errors/app-error';
import { AppValidators } from '../validators';

/**
 * Util operator function to catch `AppValidationError` on presentational logic.
 * @param formOrSubject Subject to emit data if it was there.
 * @param cdr Change detector reference. It's required for updating UI in nested forms.
 */
export function catchValidationData<R>(
  formOrSubject: Subject<EntityValidationErrors<Record<string, unknown>>> | FormGroup,
  cdr?: ChangeDetectorRef,
): OperatorFunction<R, R | never> {
  return source$ =>
    source$.pipe(
      catchValidationError(({ validationData, message }) => {
        if (validationData === undefined) {
          return throwError(() => new AppError(message));
        }
        if (formOrSubject instanceof FormGroup) {
          fillFormWithError(formOrSubject, validationData);
          cdr?.markForCheck();
        } else {
          formOrSubject.next(validationData);
        }
        return throwError(() => new AppError(message));
      }),
    );
}

/**
 * Fill the form with error data.
 * @param form Form to fill.
 * @param errors Array of errors.
 */
function fillFormWithError<T extends Record<string, unknown>>(
  form: FormGroup,
  errors: EntityValidationErrors<T>,
): void {
  const controlKeys = Object.keys(form.controls) as (keyof T)[];
  controlKeys.forEach(key => {
    const error = errors[key];
    const control = form.controls[key as string];
    if (error && control) {
      // If error is not nested
      if (typeof error === 'string') {
        control.setErrors(AppValidators.buildAppError(error));
      } else if (control instanceof FormGroup) {
        // Since we checked the error type, help typescript with error typing
        fillFormWithError(control, error as EntityValidationErrors<T>);
      } else if (control instanceof FormArray && Array.isArray(error)) {
        control.controls.forEach((ctrl, index) => {
          fillFormWithError(ctrl as FormGroup, error[index]);
        });
      }
    }
  });
}

/**
 * Catch application validation error (instance of AppValidationError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
function catchValidationError<T, R>(
  selector: (error: AppValidationError) => Observable<R>,
): OperatorFunction<T, T | R> {
  return catchError((error: unknown) => {
    if (error instanceof AppError) {
      return selector(error);
    }
    return throwError(() => error);
  });
}
