import { FinalEstimatePricing, FinalEstimatePricingInitArgs } from '../../../pricing/final-estimate-pricing';

import { FinalEstimateServiceByModules } from './final-estimate-service-by-modules';
import { FinalEstimateServiceByServices } from './final-estimate-service-by-services';

/** Final estimate service totals. */
export class FinalEstimateServiceTotals extends FinalEstimatePricing {

  /** Module summaries. */
  public readonly moduleSummaries: FinalEstimateServiceByModules;

  /** Service summaries. */
  public readonly serviceSummaries: FinalEstimateServiceByServices;

  public constructor(data: FinalEstimateServiceTotalsInitArgs) {
    super(data);
    this.moduleSummaries = data.moduleSummaries;
    this.serviceSummaries = data.serviceSummaries;
  }
}

export type FinalEstimateServiceTotalsInitArgs = FinalEstimatePricingInitArgs &
  Pick<FinalEstimateServiceTotals, 'moduleSummaries' | 'serviceSummaries'>;
