import { BaseModulesList } from '../estimate/modules-list';

/**
 * Term service.
 * This model is similar to `EstimateService` because it represents the same entity.
 * The only difference is that `EstimateService` doesn't contain module data (only module properties).
 * `TermService` contains module data as well as module properties.
 */
export class TermService {
  /** Service ID. */
  public readonly serviceId: number;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Term number. */
  public readonly termId: number;

  /** Modules. */
  public readonly modules: BaseModulesList;

  public constructor(data: TermServiceModulesInitArgs) {
    this.serviceId = data.serviceId;
    this.modules = data.modules;
    this.name = data.name;
    this.description = data.description;
    this.termId = data.termId;
  }
}

type TermServiceModulesInitArgs = TermService;
