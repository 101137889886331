import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';

import { TableCellFormControl } from '../table-cell-form-control.component';

/** Multi Select table cell component. */
@Component({
  selector: 'dartsalesc-multi-select-table-cell',
  templateUrl: './multi-select-table-cell.component.html',
  styleUrls: ['./multi-select-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MultiSelectTableCellComponent<T> extends TableCellFormControl<readonly T[] | null> implements OnChanges {

  /** Is cell readonly. */
  @Input()
  public isReadonly = false;

  /** Select options. */
  @Input()
  public options: readonly OptionSelect<T>[] = [];

  /** Select placeholder.. */
  @Input()
  public placeholder = 'Select values';

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  @Input()
  public compareWith = (first?: T, second?: T): boolean => first === second;

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<readonly T[]>();

  /** @inheritdoc */
  // We do this check here because angular triggers setDisableState after the input arrives.
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isReadonly'] && this.isReadonly) {
      this.selectControl.disable();
    } else if (changes['isReadonly'] && !this.isReadonly) {
      this.selectControl.enable();
    }
  }

  private readonly fb = inject(NonNullableFormBuilder);

  /** Select form control. */
  protected readonly selectControl = this.fb.control<readonly T[] | null>(null);

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** @inheritdoc */
  public override writeValue(value: readonly T[] | null): void {
    this.selectControl.setValue(value);
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** Handle selection change. */
  protected onSelectionChange(): void {
    if (this.selectControl.enabled) {
      this.controlValue = this.selectControl.value;
      this.selectionChange.emit(this.selectControl.value ?? []);
    }
  }
}
