import { ExpensesRole } from './expenses-role';

/** Expenses role cost. */
export class ExpensesRoleCost {

  /** Role. */
  public readonly role: {

    /** Role ID. */
    readonly id: ExpensesRole['id'];

    /** Role name. */
    readonly name: ExpensesRole['name'];
  };

  /** Cost. */
  public readonly cost: number;

  public constructor(data: ExpensesRoleCostInitArgs) {
    this.role = data.role;
    this.cost = data.cost;
  }
}

type ExpensesRoleCostInitArgs = ExpensesRoleCost;
