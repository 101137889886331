import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { EstimateMultipleExportParamsFormControls } from '@dartsales/common/core/models/forms/export/estimate-multiple-export-params-form';

/** Export multiple estimate form error state matcher. */
export class ExportMultipleEstimateFormErrorStateMatcher implements ErrorStateMatcher {

  private readonly defaultStateMatcher = new ErrorStateMatcher();

  public constructor(
    private readonly form: FormGroup<EstimateMultipleExportParamsFormControls>,
  ) {}

  /**
   * Is error state.
   * @param _control Control.
   */
  public isErrorState(): boolean {
    return (
      this.form.controls.customerView.touched ||
        this.form.controls.internalView?.touched) &&
      this.defaultStateMatcher.isErrorState(this.form, null);
  }
}
