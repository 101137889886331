import { enumToArray } from '../utils/enum-to-array';

/** Project status. */
export enum ProjectStatus {
  InProgress = 'InProgress',
  InReview = 'InReview',
  Ready = 'Ready',
  Submitted = 'Submitted',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  OnHold = 'OnHold',
}

/** Namespace related to project status. */
export namespace ProjectStatus {
  const TO_TITLE_MAP: Record<ProjectStatus, string> = {
    [ProjectStatus.InProgress]: 'In progress',
    [ProjectStatus.InReview]: 'In review',
    [ProjectStatus.Ready]: 'Ready',
    [ProjectStatus.Submitted]: 'Submitted',
    [ProjectStatus.Accepted]: 'Accepted',
    [ProjectStatus.Rejected]: 'Rejected',
    [ProjectStatus.Canceled]: 'Canceled',
    [ProjectStatus.OnHold]: 'On-hold',
  };

  /**
   * Converts a certain project status to readable title.
   * @param value Project status.
   */
  export function toReadable(value: ProjectStatus): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all project status. */
  export function toArray(): ProjectStatus[] {
    return enumToArray(ProjectStatus);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: ProjectStatus): ProjectStatus {
    return item;
  }
}
