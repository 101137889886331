import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FinalEstimateStatus } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate-status';

/** Awarded badge. */
@Component({
  selector: 'dartsalesw-awarded-badge',
  templateUrl: './awarded-badge.component.html',
  styleUrls: ['./awarded-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwardedBadgeComponent {

  /** Final estimate status. */
  protected readonly estimateStatus = FinalEstimateStatus;
}
