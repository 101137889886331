import { AfterViewInit, ChangeDetectorRef, DestroyRef, Directive, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { TippyDirective } from '@ngneat/helipopper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BulkUpdateOption } from '@dartsales/common/core/models/bulk-update-option';

/** Abstract component for bulk update table cell component. */
@Directive()
export abstract class AbstractTableCellBulkUpdateComponent implements AfterViewInit {
  /** Tooltip text. */
  @Input()
  public tooltipText: string | null = null;

  /** Popover header text. */
  @Input()
  public headerText = 'Data Update Available';

  /** Max width of bulk update button in percent. Default is 50. */
  @Input()
  public maxWidth = 50;

  /** Max width of bulk update popover in pixels. Default is 270. */
  @Input()
  public maxPopoverWidth = 270;

  /** Confirm bulk update event. */
  @Output()
  public readonly confirmUpdate = new EventEmitter<BulkUpdateOption>();

  @ViewChild(TippyDirective)
  private readonly popoverTriggerElement?: TippyDirective;

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly destroyRef = inject(DestroyRef);

  /** Bulk update option. */
  protected readonly bulkUpdateOption = BulkUpdateOption;

  /** Is popover opened. */
  protected get isPopoverOpened(): boolean {
    return this.popoverTriggerElement?.isVisible ?? false;
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    // '@ngneat/helipopper' has a strange behavior with change detection.
    // That's why we trigger CDR manually.
    this.popoverTriggerElement?.visible.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
       this.cdr.markForCheck();
     });
  }

  /**
   * Handle button click.
   * @param selectedOption Selected bulk update option.
   */
  protected onButtonClick(selectedOption: BulkUpdateOption): void {
    this.popoverTriggerElement?.hide();
    this.confirmUpdate.emit(selectedOption);
  }

  /**
   * Stop propagation.
   * Used to prevent renderer cell changing to editor cell when clicking on bulk update button.
   * @param event Mouse event.
   */
  protected stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
