import { Directive } from '@angular/core';

import { BaseValueAccessor } from './base-value-accessor';

// Angular requires some decorator so that DI would get things right, Directive is added to avoid specifying Component's metadata.

/**
 * Simple synchronous value accessor implementation. Has a synchronous `controlValue` property that contains a value.
 * Reduces boilerplate needed for implementing custom controls via
 * Angular's [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor).
 * @see ControlValueAccessor.
 */
@Directive()
export abstract class SimpleValueAccessor<T> extends BaseValueAccessor<T> {

  /** Control value. */
  public set controlValue(v: T | null) {
    this._value = v;
    this.emitChange(this._value);
  }

  /** Value. */
  public get controlValue(): T | null {
    return this._value;
  }

  private _value: T | null = null;

  /**
   * Used by Angular to write when assigned FormControl is changed.
   * @param value Value passed from the outside of value accessor by Angular's FormControl.
   * `null` when form is initialized without initial data.
   */
  public override writeValue(value: T | null): void {
    this._value = value;
    this.changeDetectorRef.markForCheck();
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }
}
