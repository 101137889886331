import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReadonlySignal, effect } from '@preact/signals-core';

/**
 * Auto run Angular change detection for reactive formulas evaluation.
 * This solution is based on 'mobxAutorun' directive from https://github.com/mobxjs/mobx-angular.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[signalRender]',
})
export class SignalRenderDirective implements OnInit, OnDestroy {

  /** Signal. */
  @Input()
  public set signalRender(value: ReadonlySignal | null) {
    if (value != null && this.view != null) {
      this.autoDetect(this.view);
    }
  }

  /** Dispose function. */
  protected dispose?: () => void;

  /** Embedded view ref. */
  protected view?: EmbeddedViewRef<unknown>;

  public constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.view = this.viewContainer.createEmbeddedView(this.templateRef);
    this.autoDetect(this.view);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    if (this.dispose) {
      this.dispose();
    }
  }

  /**
   * Auto detect changes.
   * @param view Embedded view ref.
   */
  private autoDetect(view: EmbeddedViewRef<unknown>): void {
    if (this.dispose) {
      this.dispose();
    }
    this.dispose = effect(() => view.detectChanges());
  }
}
