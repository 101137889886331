import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { ExpensesModuleLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/expenses-module-local-storage';
import { LaborModuleLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/labor-module-local-storage';
import { MaterialModuleLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/material-module-local-storage';
import { ModuleSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/module-settings';
import { ModuleUnitsSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/module-units-settings';
import { PointListLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/point-list-local-storage';
import { SubcontractorModuleLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/subcontractor-module-local-storage';
import { DEFAULT_MARGIN_PARAMS } from '@dartsales/common/core/models/margin-params';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { PointsListExpandedColumnsState } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-table/services/points-list-table-columns.service';

import { DEFAULT_PART_TAB_COLUMNS, DEFAULT_PROJECT_TAB_COLUMNS, DEFAULT_SYSTEM_TAB_COLUMNS } from './default-catalog-tabs-settings';

/** Default option units. */
export const DEFAULT_OPTIONS_UNITS = new ModuleUnitsSettingsLocalStorage({
  contingencyUnits: AmountCalcUnits.DEFAULT,
  escalationUnits: AmountCalcUnits.DEFAULT,
  marginParams: DEFAULT_MARGIN_PARAMS,
  dcUnits: AmountCalcUnits.DEFAULT,
});

/** Default module settings. */
export const DEFAULT_MODULE_SETTINGS = new ModuleSettingsLocalStorage({
  units: DEFAULT_OPTIONS_UNITS,
  isExpandedEscalation: false,
  isExpandedPricing: false,
});

/** Default point list expand state. */
export const DEFAULT_POINT_LIST_EXPAND_STATE: PointsListExpandedColumnsState = {
  isLaborSectionExpanded: false,
  isPortsSectionExpanded: false,
  isCustomCategoryExpanded: false,
  isInputCategoryExpanded: false,
  isNetworkCategoryExpanded: false,
  isOtherCategoryExpanded: false,
  isOutputCategoryExpanded: false,
  isNoneCategoryExpanded: false,
  isPowerCategoryExpanded: false,
};

/** Default estimate settings. */
export const DEFAULT_ESTIMATE_SETTINGS = new EstimateSettingsLocalStorage({
  modules: {
    subcontractor: new SubcontractorModuleLocalStorage({
      isExpandCalculator: true,
      lumpSumPricing: DEFAULT_MODULE_SETTINGS,
      unitPricing: DEFAULT_MODULE_SETTINGS,
    }),
    labor: new LaborModuleLocalStorage({
      ...DEFAULT_MODULE_SETTINGS,
      isExpandRates: false,
      collapsedRowTypes: [],
      collapsibleRowData: [],
    }),
    custom: DEFAULT_MODULE_SETTINGS,
    material: new MaterialModuleLocalStorage({
      ...DEFAULT_MODULE_SETTINGS,
      isExpandWFS: false,
      isExpandModel: false,
      collapsedRowTypes: [],
      collapsibleRowData: [],
    }),
    expenses: new ExpensesModuleLocalStorage({
      calculationDataSection: {
        isExpanded: true,
        isExpandLaborRolesSection: true,
        isExpandResourceSection: true,
      },
      summarySection: {
        isExpanded: true,
        table: DEFAULT_MODULE_SETTINGS,
      },
    }),
  },
  pointList: new PointListLocalStorage({
    activeTabId: null,
    expandColumns: DEFAULT_POINT_LIST_EXPAND_STATE,
    collapseRowsByTabs: {},
    columnsWidthByTabs: {},
    catalog: {
      width: 300,
      isExpanded: true,
      systemTab: {
        columnsWidth: DEFAULT_SYSTEM_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
      partTab: {
        columnsWidth: DEFAULT_PART_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
      projectTab: {
        columnsWidth: DEFAULT_PROJECT_TAB_COLUMNS,
        filterParams: {
          filter: {},
          sort: [{ field: 'name', direction: SortDirection.ASC }],
        },
      },
    },
  }),
  meta: {
    timestamp: new Date().getTime(),
  },
});
