import { Injectable } from '@angular/core';

import { Address } from '../../models/address';

import { AddressDto } from './dto/address.dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** Address entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class AddressMapper implements
  IMapperFromDto<AddressDto, Address>,
  IMapperToDto<AddressDto, Address> {
  /** @inheritdoc */
  public fromDto(dto: AddressDto): Address {
    return new Address({
      address: dto.line,
      country: dto.country,
      state: dto.state,
      city: dto.city,
      zip: dto.zip,
    });
  }

  /** @inheritdoc */
  public toDto(data: Address): AddressDto {
    return {
      line: data.address,
      country: data.country,
      state: data.state,
      city: data.city,
      zip: data.zip,
    };
  }
}
