import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';
import { AmountPercentInputValues } from '@dartsales/common/core/models/forms/amount-percent-form';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';

import { AmountInputHelpers } from '../../abstract-input';
import { AbstractAmountToggledInputComponent } from '../abstract-toggled-input';

/** Amount toggled input. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-overridable-amount-toggled-input',
  templateUrl: './amount-overridable-toggled-input.component.html',
  styleUrls: ['./amount-overridable-toggled-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => OverridableAmountToggledInputComponent)],
})
export class OverridableAmountToggledInputComponent extends AbstractAmountToggledInputComponent<Overridable<AmountPercent>> {

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      this.controlValue?.override !== null
    );
  }

  /** @inheritdoc */
  protected override fillForm(value: Overridable<AmountPercent> | null): void {
    if (!compareRoundNumbers(value?.combinedValue.amount, this.controlValue?.combinedValue.amount)) {
      this.form.controls.amount.patchValue(value !== null ? this.valueToInput(value).amount : '', { emitEvent: false });
    }
    if (!compareRoundNumbers(value?.combinedValue.percent, this.controlValue?.combinedValue.percent)) {
      this.form.controls.percent.patchValue(value !== null ? this.valueToInput(value).percent : '', { emitEvent: false });
    }
  }

  /** @inheritdoc */
  protected valueToInput(value: Overridable<AmountPercent>): AmountPercentInputValues {
    return {
      amount: AmountInputHelpers.numberToInput(value.combinedValue.amount),
      percent: AmountInputHelpers.percentToInput(value.combinedValue.percent),
    };
  }

  /** @inheritdoc */
  protected valueFromInput(valueVm: AmountPercentInputValues): Overridable<AmountPercent> | null {
    const amount = AmountInputHelpers.numberFromInput(valueVm.amount, this.amountInputMask);
    const percent = AmountInputHelpers.percentFromInput(valueVm.percent, this.percentInputMask);

    if (amount !== null && percent !== null && this.controlValue !== null) {
      return new Overridable({
        initial: this.controlValue.initial,
        override: new AmountPercent({ amount, percent }),
      });
    }

    return null;
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new Overridable({ initial: this.controlValue.initial, override: null });
      this.writeValue(clearedOverride);
      this.controlValue = clearedOverride;
    }
  }

  /** @inheritdoc */
  protected checkShouldSetValue(value: Overridable<AmountPercent> | null): boolean {
    return this.controlValue != null &&
      value?.override != null &&
      !AmountPercent.checkIsEqualWithRound(value.override, this.controlValue.combinedValue);
  }
}
