import { Injectable } from '@angular/core';

import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../mappers';
import { EditModuleLockablePercentsStatusDto, ModuleLockablePercentsStatusDto } from '../dto/estimate/module-lockable-percents-status.dto';

/** Module lockable percents status mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModuleLockablePercentsStatusMapper implements IMapper<ModuleLockablePercentsStatusDto, ModuleLockablePercentsStatus> {
  /** @inheritdoc */
  public fromDto(dto: ModuleLockablePercentsStatusDto): ModuleLockablePercentsStatus {
    return new ModuleLockablePercentsStatus({
      isContingencyLocked: dto.isContingencyLocked,
      isGrossMarginLocked: dto.isGrossMarginLocked,
      isEscalationLocked: dto.isEscalationLocked,
    });
  }

  /** @inheritdoc */
  public toDto(dto: ModuleLockablePercentsStatus): ModuleLockablePercentsStatusDto {
    return {
      isContingencyLocked: dto.isContingencyLocked,
      isGrossMarginLocked: dto.isGrossMarginLocked,
      isEscalationLocked: dto.isEscalationLocked,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: Partial<ModuleLockablePercentsStatus>): EditModuleLockablePercentsStatusDto {
    return {
      isContingencyLocked: data?.isContingencyLocked,
      isGrossMarginLocked: data?.isGrossMarginLocked,
      isEscalationLocked: data?.isEscalationLocked,
    };
  }
}
