<div class="format-select">
  <label class="format-select__label caption-2">Export as:</label>
  <mat-radio-group
    class="format-select__selects"
    [(ngModel)]="controlValue"
  >
    <mat-radio-button
      *ngFor="
        let option of supportedExportFormats;
        trackBy: exportFormat.trackBy
      "
      [value]="option"
    >
      {{ exportFormat.toReadable(option) }}
    </mat-radio-button>
  </mat-radio-group>
</div>
