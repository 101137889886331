/** Bid category. */
export class BidCategory {
  /** Id. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Organization id. */
  public readonly organizationId: number;

  /** Organization name. */
  public readonly organizationName: string;

  /** Remove at. */
  public readonly removedAt: Date | null;

  /** Is default. */
  public readonly isDefault: boolean;

  /** Order. */
  public readonly order: number;

  public constructor(data: BidCategoryInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.organizationId = data.organizationId;
    this.removedAt = data.removedAt;
    this.isDefault = data.isDefault;
    this.organizationName = data.organizationName;
    this.order = data.order;
  }
}

type BidCategoryInitArgs = BidCategory;

export type BidCategoryEdit = Pick<BidCategory, 'name' | 'description'> & Partial<Pick<BidCategory, 'order'>>;
export type ExploreBidCategory = Pick<BidCategory, 'id' | 'name'>;
export type FilterBidCategory = Pick<BidCategory, 'id' | 'name' | 'organizationName' | 'organizationId'>;
