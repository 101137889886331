import { Module } from '../../module';

import { ExpensesModuleData } from './expenses-module-data';
import { ExpensesModuleProperties } from './expenses-module-properties';

/** Expenses module data. */
export class ExpensesModule extends Module<ExpensesModuleProperties, ExpensesModuleData> {

  public constructor(data: ExpensesModuleInitArgs) {
    super(data);
  }
}

type ExpensesModuleInitArgs = ExpensesModule;
