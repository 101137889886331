import { Injectable, inject } from '@angular/core';

import { PointsListTotalsSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system-totals/points-list-totals-system';

import { IMapper } from '../../../mappers';
import { PointsListTotalsSystemDto } from '../../../dto/estimate/modules/points-list/system-totals/points-list-totals-system.dto';

import { PointsListLaborTotalsMapper } from './points-list-labor-totals.mapper';
import { PointsListMaterialTotalsMapper } from './points-list-material-totals.mapper';

/** PointsListTotalsSystem entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListTotalsSystemMapper implements IMapper<PointsListTotalsSystemDto, PointsListTotalsSystem> {

  private readonly materialTotalsMapper = inject(PointsListMaterialTotalsMapper);

  private readonly laborTotalsMapper = inject(PointsListLaborTotalsMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListTotalsSystemDto): PointsListTotalsSystem {
    return new PointsListTotalsSystem({
      id: dto.id,
      name: dto.name,
      quantity: dto.quantity,
      pointsCount: dto.totalPoints,
      materialCost: dto.materialCost,
      laborCost: dto.laborCost,
      unitPrice: dto.unitPrice,
      directCost: dto.directCost,
      laborHours: dto.laborHours,
      isExcluded: dto.isExcluded,
      laborTotals: this.laborTotalsMapper.fromDto(dto.laborTotals),
      laborUnitTotals: this.laborTotalsMapper.fromDto(dto.laborUnitTotals),
      materialTotals: this.materialTotalsMapper.fromDto(dto.materialTotals),
      materialUnitTotals: this.materialTotalsMapper.fromDto(dto.materialUnitTotals),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListTotalsSystem): PointsListTotalsSystemDto {
    return {
      id: data.id,
      name: data.name,
      quantity: data.quantity,
      totalPoints: data.pointsCount,
      materialCost: data.materialCost,
      laborCost: data.laborCost,
      unitPrice: data.unitPrice,
      directCost: data.directCost,
      laborHours: data.laborHours,
      isExcluded: data.isExcluded,
      laborTotals: this.laborTotalsMapper.toDto(data.laborTotals),
      laborUnitTotals: this.laborTotalsMapper.toDto(data.laborUnitTotals),
      materialTotals: this.materialTotalsMapper.toDto(data.materialTotals),
      materialUnitTotals: this.materialTotalsMapper.toDto(data.materialUnitTotals),
    };
  }
}
