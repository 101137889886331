import { Injectable } from '@angular/core';

import { ApiTransactionId } from '../../models/api-transaction-id';

import { ApiTransactionDto } from './dto/api-transaction.dto';
import { IMapperFromDto } from './mappers';

/** API transaction mapper. */
@Injectable({
  providedIn: 'root',
})
export class ApiTransactionMapper implements IMapperFromDto<ApiTransactionDto, ApiTransactionId> {

  /** @inheritdoc */
  public fromDto(dto: ApiTransactionDto): ApiTransactionId {
    return new ApiTransactionId({
      id: dto.transactionId,
    });
  }

}
