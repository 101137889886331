import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { map, combineLatest, BehaviorSubject, shareReplay, Observable } from 'rxjs';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { assertNonNullWithReturn } from '@dartsales/common/core/utils/assert-non-null';
import { TableHeaderRowSortingService } from '@dartsales/common/shared/components/editable-table/services/table-header-row-sorting.service';

/** Sort arrow animation state. */
const sortAnimationStateMap: Record<SortDirection, string> = {
  [SortDirection.ASC]: 'asc',
  [SortDirection.DESC]: 'desc',
  [SortDirection.NONE]: 'none',
};

/** Table header sorting button. */
@Component({
  selector: 'dartsalesc-table-header-sorting-button',
  templateUrl: './table-header-sorting-button.component.html',
  styleUrls: ['./table-header-sorting-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('arrowDirection', [
      state(sortAnimationStateMap[SortDirection.ASC], style({ transform: 'rotate(0deg)' })),
      state(sortAnimationStateMap[SortDirection.DESC], style({ transform: 'rotate(180deg)' })),
      transition(`${sortAnimationStateMap[SortDirection.ASC]} => ${sortAnimationStateMap[SortDirection.DESC]}`,
        [animate('200ms ease-out')]),
      transition(`${sortAnimationStateMap[SortDirection.DESC]} => ${sortAnimationStateMap[SortDirection.NONE]}`, [
        animate('200ms ease-out', style({ opacity: 0 })),
        animate('200ms 1s ease-out', style({ transform: 'rotate(0deg)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class TableHeaderSortingButtonComponent {
  /** Sort field name. */
  @Input()
  public set sortFieldName(value: string) {
    this.sortFieldName$.next(value);
  }

  private readonly tableHeaderRowSortingService = inject(TableHeaderRowSortingService);

  /** Sort field name. */
  protected readonly sortFieldName$ = new BehaviorSubject<string | null>(null);

  /** Sort direction. */
  protected readonly sortDirection$ = this.createSortDirectionStream();

  /** Is sort arrow button visible. */
  protected readonly isArrowVisible$ = this.sortDirection$.pipe(
    map(sortDirection => sortDirection !== SortDirection.NONE),
  );

  /**
   * Toggle sort direction.
   * @param direction Current sort direction.
   */
  protected toggleSortDirection(direction: SortDirection | null): void {
    const nextDirection = SortDirection.getNextDirection(direction ?? SortDirection.NONE);
    const sortFieldName = assertNonNullWithReturn(this.sortFieldName$.value);

    this.tableHeaderRowSortingService.changeSort({
      field: sortFieldName,
      direction: nextDirection,
    });
  }

  /**
   * Get arrow animation state.
   * @param direction Sort direction.
   */
  protected getArrowAnimationState(direction: SortDirection | null): string {
    const sortDirection = direction ?? SortDirection.NONE;
    return sortAnimationStateMap[sortDirection];
  }

  private createSortDirectionStream(): Observable<SortDirection> {
    return combineLatest([
      this.tableHeaderRowSortingService.activeSort$,
      this.sortFieldName$,
    ]).pipe(
      map(([sort, sortFieldName]) => {
        const isActiveSorting = sortFieldName === sort.field;
        if (isActiveSorting) {
          return sort.direction;
        }

        return SortDirection.NONE;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
