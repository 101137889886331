import { Address } from '../address';

/** Edit project details. */
export class EditProjectDetails {

  /** Organization id. */
  public readonly organizationId: number;

  /** Project name. */
  public readonly name: string;

  /** User-entered project id/code. */
  public readonly code: string;

  /** Project description. */
  public readonly description: string;

  /** Project start date ('null' is possible because date is optional). */
  public readonly startDate: Date | null;

  /** Project end date ('null' is possible because date is optional). */
  public readonly endDate: Date | null;

  /** Total building square (in sq. Ft.). */
  public readonly totalBuildingSquare: number;

  /** Bid due date and time ('null' is possible because date is optional). */
  public readonly bidDueDateTime: Date | null;

  /** Product types. */
  public readonly productTypes: readonly string[];

  /** Product tags. */
  public readonly tags: readonly string[];

  /** Address. */
  public readonly address: Address;

  public constructor(data: EditProjectDetailsInitArgs) {
    this.organizationId = data.organizationId;
    this.name = data.name;
    this.code = data.code;
    this.description = data.description;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.totalBuildingSquare = data.totalBuildingSquare;
    this.bidDueDateTime = data.bidDueDateTime;
    this.productTypes = data.productTypes;
    this.tags = data.tags;
    this.address = data.address;
  }
}

type EditProjectDetailsInitArgs = EditProjectDetails;
