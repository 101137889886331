import { Directive, inject, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, switchMap } from 'rxjs';

import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { BaseOrganization } from '@dartsales/common/core/models/organization';
import { PermissionsService } from '@dartsales/common/core/services/permissions.service';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';

import { AbstractPermissionDirective } from './permissions/abstract-permission.directive';

/** Directive regulating availability of content depending on organization permissions. */
@Directive({
  selector: '[dartsalescOrganizationPermissions]',
})
export class OrganizationPermissionsDirective extends AbstractPermissionDirective {

  /** Permissions of which regulates availability of content. */
  @Input({ required: true })
  public set dartsalescOrganizationPermissions(value: readonly UserPermission[]) {
    this.userPermissions$.next([...value]);
  }

  /** Organization ID. */
  @Input({ required: true })
  public set dartsalescOrganizationPermissionsOrganizationId(id: BaseOrganization['id']) {
    this.organizationId$.next(id);
  }

  private readonly organizationId$ = new ReplaySubject<BaseOrganization['id']>(1);

  private readonly permissionsService = inject(PermissionsService);

  private readonly userPermissions$ = new BehaviorSubject<UserPermission[]>([]);

  /** @inheritdoc */
  protected readonly hasPermissions$ = this.createHasPermissionStream();

  private createHasPermissionStream(): Observable<boolean> {
    return combineLatest([
      this.userPermissions$.pipe(filterNull()),
      this.organizationId$,
    ])
      .pipe(
        switchMap(([permissions, organizationId]) =>
          this.permissionsService.hasPermissionsInOrganization(permissions, organizationId)),
      );
  }
}
