import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

/** Draggable item preview. */
@Component({
  selector: 'dartsalesw-draggable-item-preview',
  templateUrl: './draggable-item-preview.component.html',
  styleUrls: ['./draggable-item-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableItemPreviewComponent {
  /** Selected items count. */
  @Input()
  public selectedItemsCount = 0;

  /** Single item drag template. */
  @ContentChild('singleItemDragTemplate')
  protected readonly singleItemDragTemplate?: TemplateRef<unknown>;

  /** Multiple items drag template. */
  @ContentChild('multipleItemsDragTemplate')
  protected readonly multipleItemsDragTemplate?: TemplateRef<unknown>;
}
