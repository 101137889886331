import { Address } from '../address';

/** Project Company. */
export class ProjectCompany {

  /** Phone number. */
  public readonly phoneNumber: string;

  /** Fax number. */
  public readonly faxNumber: string;

  /** Address. */
  public readonly address: Address;

  public constructor(data: ProjectCompanyInitArgs) {
    this.phoneNumber = data.phoneNumber;
    this.faxNumber = data.faxNumber;
    this.address = data.address;
  }
}

type ProjectCompanyInitArgs = ProjectCompany;
