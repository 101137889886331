import { ContentChild, Directive, Input } from '@angular/core';

import { EditableTableCellDirective } from './editable-table-cell.directive';

/**
 * Editable table column definition directive.
 * We need this directive to describe the layout of a table column.
 * This directive is designed to be used inside table row directive (e.g. EditableTableBodyRowDirective).
 * @example
 * ```html
 * <ng-container dartsalescEditableTableBodyRow>
 *   <ng-container dartsalescEditableTableCol="quote">
 *     <ng-container *dartsalescEditableTableCell>
 *       <!-- Cell layout. -->
 *     </ng-container>
 *   </ng-container>
 *   <ng-container dartsalescEditableTableCol="quantity">
 *     <ng-container *dartsalescEditableTableCell>
 *       <!-- Cell layout. -->
 *     </ng-container>
 *   </ng-container>
 * </ng-container>
 * ```
 */
@Directive({
  selector: '[dartsalescEditableTableCol]',
})
export class EditableTableColDirective {
  /** Name of the column. */
  @Input('dartsalescEditableTableCol')
  public name?: string;

  /** Table cell. */
  @ContentChild(EditableTableCellDirective)
  public readonly tableCell?: EditableTableCellDirective;
}
