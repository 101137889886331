/** Contractor. */
export class Contractor {

  /** Contractor company name. */
  public readonly companyName: string;

  /** Contractor contact name. */
  public readonly contact: string;

  /** Contractor phone number. */
  public readonly phoneNumber: string;

  public constructor(data: ContractorInitArgs) {
    this.companyName = data.companyName;
    this.contact = data.contact;
    this.phoneNumber = data.phoneNumber;
  }
}

type ContractorInitArgs = Contractor;
