import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TableCellFormControl } from '../table-cell-form-control.component';
import { TableCellSuffixDirective } from '../../directives/table-cell-suffix.directive';

/** Text table cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-text-table-cell',
  templateUrl: './text-table-cell.component.html',
  styleUrls: ['./text-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextTableCellComponent extends TableCellFormControl<string> implements OnInit {

  /** Placeholder. */
  @Input()
  public placeholder = 'Enter value';

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Cell focus. */
  @Output()
  public readonly cellFocus = new EventEmitter<void>();

  /** Cell blur. */
  @Output()
  public readonly cellBlur = new EventEmitter<void>();

  /** Table cell suffix. */
  @ContentChild(TableCellSuffixDirective)
  protected readonly cellSuffix?: TableCellSuffixDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Text form control. */
  protected readonly textControl = this.fb.control<string>('');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: string | null): void {
    this.textControl.setValue(value ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.textControl.disable();
    } else {
      this.textControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  private subscribeToControlChanges(): void {

    // We don't use `listenControlChanges`, because
    // under the hood it contains `distinctUntilChanged`
    // observer does not emit the value if paste such value after resetting the parent control.
    this.textControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
