import { Contractor } from '../contractor';

/** Contracting team. */
export class ContractingTeam {

  /** Architect. */
  public readonly architect: Contractor;

  /** Consulting Engineer. */
  public readonly consultingEngineer: Contractor;

  /** Prime contractor. */
  public readonly primeContractor: Contractor;

  /** Contract with. */
  public readonly contractWith: Contractor;

  public constructor(data: ContractingTeamInitArgs) {
    this.architect = data.architect;
    this.consultingEngineer = data.consultingEngineer;
    this.primeContractor = data.primeContractor;
    this.contractWith = data.contractWith;
  }
}

type ContractingTeamInitArgs = ContractingTeam;
