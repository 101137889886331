<div class="masked-input-container container">
  <input
    class="amount-input body-2"
    placeholder="0"
    [class.amount-input_has-suffix]="suffix"
    [inputMask]="mask"
    [formControl]="maskedNumberControl"
  />
  <span
    *ngIf="suffix"
    class="suffix"
  >
    {{ suffix }}
  </span>
</div>
