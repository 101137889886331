import { Directive, ElementRef, inject } from '@angular/core';
import { MatInput } from '@angular/material/input';

/** Table cell input. */
@Directive({
  selector: '[dartsalescTableCellInput]',
})
export class TableCellInputDirective extends MatInput {

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  /** Table row element. */
  public get tableRowElement(): HTMLTableRowElement | null {
    return this.elementRef.nativeElement.closest('tr') ?? this.elementRef.nativeElement.closest('.table-row');
  }
}
