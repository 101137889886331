import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Placeholder component.
 * This component is created to be a placeholder for empty routes.
 * If we don't use it anywhere in the app it can be safely deleted.
 */
@Component({
  selector: 'dartsalesw-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderComponent {}
