import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { AbstractMaterialPricing, AbstractMaterialPricingInitArgs } from './abstract-material-pricing';

/** Base material pricing with override properties. */
export class MaterialPricingWithOverride extends AbstractMaterialPricing {

  /** Overridable quantity. */
  public readonly quantity: Overridable<number>;

  public constructor(data: MaterialPricingWithOverrideInitArgs) {
    super(data);
    this.quantity = data.quantity;
  }
}

export type MaterialPricingWithOverrideInitArgs = Pick<MaterialPricingWithOverride, 'quantity'> & AbstractMaterialPricingInitArgs;
