import { NgModule } from '@angular/core';
import { TippyDirective, provideTippyConfig, popperVariation } from '@ngneat/helipopper';

/** Popover module. */
@NgModule({
  imports: [TippyDirective],
  exports: [TippyDirective],
  providers: [
    provideTippyConfig({
      defaultVariation: 'popperBorder',
      variations: {
        popperBorder: { ...popperVariation, placement: 'right', theme: 'light-border', animation: 'scale' },
      },
    }),
  ],
})
export class PopoverModule { }
