import { Injectable, inject } from '@angular/core';

import { SubcontractorUnitPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/unit-pricing/unit-pricing';
import { PointListUnitPricingSystem } from '@dartsales/common/core/models/estimate/modules/subcontractor/unit-pricing/point-list-unit-pricing-system';
import { CreateSubcontractorUnitPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/unit-pricing/create-unit-pricing';
import { EditSubcontractorUnitPricing, BatchEditSubcontractorUnitPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/unit-pricing/edit-unit-pricing';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../../../mappers';
import { BaseUnitPricingMapper } from '../../../pricing/base-unit-pricing.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';
import { BatchEditLockablePercentsStatusDto, BatchEditSubcontractorUnitPricingDto, CreateSubcontractorUnitPricingDto, EditSubcontractorUnitPricingDto, SubcontractorUnitPricingDto } from '../../../dto/estimate/modules/subcontractor/subcontractor-unit-pricing.dto';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

import { SubcontractorLumpSumPricingMapper } from './subcontractor-lump-sum-pricing.mapper';

/** Subcontractor unit pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubcontractorUnitPricingMapper implements IMapper<SubcontractorUnitPricingDto, SubcontractorUnitPricing> {

  private readonly subcontractorCalcMapper = inject(SubcontractorLumpSumPricingMapper);

  private readonly baseUnitPricingMapper = inject(BaseUnitPricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: SubcontractorUnitPricingDto): SubcontractorUnitPricing {
    return new SubcontractorUnitPricing({
      ...this.subcontractorCalcMapper.fromDto(dto),
      ...this.baseUnitPricingMapper.fromDto(dto),
      quantity: this.confirmedOverridableMapper.fromDto(dto.quantity),
      systems: dto.systems.map(systemDto => new PointListUnitPricingSystem({
        id: systemDto.id,
        name: systemDto.name,
        typicalCount: systemDto.typicalCount,
      })),
    });
  }

  /**
   * Map to create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreateSubcontractorUnitPricing): CreateSubcontractorUnitPricingDto {
    return {
      ...this.subcontractorCalcMapper.toCreateDto(data),
      ...this.baseUnitPricingMapper.toDto(data),
      quantity: this.confirmedOverridableMapper.toDto(data.quantity),
      systemIds: data.componentSystemIds,
    };
  }

  /**
   * Map to edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditSubcontractorUnitPricing): EditSubcontractorUnitPricingDto {
    return {
      ...this.subcontractorCalcMapper.toEditDto(data),
      ...this.baseUnitPricingMapper.toDto(data),
      quantity: this.confirmedOverridableMapper.toDto(data.quantity),
      systemIds: data.componentSystemIds,
    };
  }

  /**
   * Map to batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditSubcontractorUnitPricing): BatchEditSubcontractorUnitPricingDto {
    return {
      unitPricingId: data.id,
      unitPricing: this.toEditDto(data.value),
    };
  }

  /** @inheritdoc */
  public toDto(data: SubcontractorUnitPricing): SubcontractorUnitPricingDto {
    return {
      ...this.subcontractorCalcMapper.toDto(data),
      ...this.baseUnitPricingMapper.toDto(data),
      quantity: this.confirmedOverridableMapper.toDto(data.quantity),
      systems: data.systems.map(system => ({
        id: system.id,
        name: system.name,
        typicalCount: system.typicalCount,
      })),
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Subcontractor module pricing ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: string,
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      unitPricings: [
        {
          subcontractorId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
