import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '../../services/cell.provider';

/** Single select cell component. */
@Component({
  selector: 'dartsalesc-single-select-cell',
  templateUrl: './single-select-cell.component.html',
  styleUrls: ['./single-select-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CELL_PROVIDERS],
})
export class SingleSelectCellComponent<T> extends AbstractCellFormControlComponent<T> {

  /** Select options. */
  @Input({ required: true })
  public options: readonly OptionSelect<T>[] = [];

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  protected compareWith(first?: T, second?: T): boolean {
    return first === second;
  }
}
