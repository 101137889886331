import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { PasswordChangeData } from '@dartsales/common/core/models/auth/password-change';
import { PasswordChangeDataFormControls } from '@dartsales/common/core/models/forms/password-change-data-form';
import { RawFormValues, ControlsOf } from '@dartsales/common/core/utils/types/form';
import { AppValidators } from '@dartsales/common/core/utils/validators';

import { IFormToModel, IModelToForm } from './form-mappers';

/** Change password data form mapper service. */
@Injectable({ providedIn: 'root' })
export class ChangePasswordDataFormMapper implements
  IFormToModel<PasswordChangeDataFormControls, PasswordChangeData>,
  IModelToForm<PasswordChangeData, PasswordChangeDataFormControls> {

  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public modelToForm(): FormGroup<PasswordChangeDataFormControls> {
    return this.fb.group<PasswordChangeDataFormControls>({
      currentPassword: this.fb.control('', [Validators.required]),
      newPassword: this.fb.control('', [Validators.required]),
      newPasswordConfirmation: this.fb.control('', [Validators.required, AppValidators.matchControl('newPassword', 'Password')]),
    });
  }

  /** @inheritdoc */
  public formToModel(formValues: RawFormValues<ControlsOf<PasswordChangeData>>): PasswordChangeData {
    return {
      currentPassword: formValues.currentPassword,
      newPassword: formValues.newPassword,
    };
  }
}
