import { inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { TermExportApiService } from '@dartsales/common/core/services/api/term-export-api.service';
import { ExportParams } from '@dartsales/common/core/models/exports/export-params';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';

import { DownloadService } from '../../../../shared/services/download.service';

/** Term export service. */
@Injectable({
  providedIn: 'root',
})
export class TermExportService {

  private readonly termExportApiService = inject(TermExportApiService);

  private readonly downloadService = inject(DownloadService);

  /**
   * Export term.
   * @param termId Term ID.
   * @param exportParams Export params.
   */
  public export(
    termId: number,
    exportParams: ExportParams,
  ): Observable<void> {
    return this.prepareExportedFile(termId, exportParams, []).pipe(
      switchMap(file => this.downloadService.downloadFile(file)),
    );
  }

  /**
   * Export multiple term.
   * @param termId Term ID.
   * @param exportParams Export params.
   * @param exportedCategories Exported categories.
   */
  public exportMultiple(
    termId: number,
    exportParams: ExportParams,
    exportedCategories: readonly TermCategoryTreeItem[],
  ): Observable<void> {
    return this.prepareExportedFile(termId, exportParams, exportedCategories).pipe(
      switchMap(file => this.downloadService.downloadFile(file)),
    );
  }

  private prepareExportedFile(
    termId: number,
    config: ExportParams,
    exportedItems: readonly TermCategoryTreeItem[],
  ): Observable<File> {
    return this.termExportApiService.export(termId, config, exportedItems);
  }
}
