import { Injectable, inject } from '@angular/core';

import { PointsListSystemTotal } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-total';
import { EditPointsListSystemTotal } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-total';

import { EditPointsListSystemTotalDto, PointsListSystemTotalDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-total.dto';
import { IMapper } from '../../../mappers';
import { EditPointsListPartLaborTaskHoursDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-labor-task-hours.dto';

import { PointsListOverridableItemTotalMapper } from './points-list-overridable-item-total.mapper';
import { PointsListPartLaborTaskHoursMapper } from './points-list-part-labor-task-hours.mapper';
import { PointsListSystemPortsTotalMapper } from './points-list-system-ports-total.mapper';

/** Points list system total mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemTotalMapper implements IMapper<PointsListSystemTotalDto, PointsListSystemTotal> {
  private readonly pointsListPartLaborTaskHoursMapper = inject(PointsListPartLaborTaskHoursMapper);

  private readonly pointsListItemTotalMapper = inject(PointsListOverridableItemTotalMapper);

  private readonly pointsListSystemPortsTotalMapper = inject(PointsListSystemPortsTotalMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemTotalDto): PointsListSystemTotal {
    return new PointsListSystemTotal({
      total: this.pointsListItemTotalMapper.fromDto(dto),
      partTasksTotalHours: dto.partTasks.map(task => this.pointsListPartLaborTaskHoursMapper.fromDto(task)),
      portsTotal: dto.ports.map(port => this.pointsListSystemPortsTotalMapper.fromDto(port)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemTotal): PointsListSystemTotalDto {
    return {
      ...this.pointsListItemTotalMapper.toDto(data.total),
      partTasks: data.partTasksTotalHours.map(task => this.pointsListPartLaborTaskHoursMapper.toDto(task)),
      ports: data.portsTotal.map(port => this.pointsListSystemPortsTotalMapper.toDto(port)),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystemTotal): EditPointsListSystemTotalDto {
    return {
      partTasksOverride: data.partTasksTotalHours
        .map(item => ({
          taskId: item.taskId,
          hoursOverride: item.hours.override,
        }))
        .filter((item): item is EditPointsListPartLaborTaskHoursDto => item.hoursOverride != null),
    };
  }
}
