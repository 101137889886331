/** Tree item. */
export abstract class TreeItem<TValue, TChild> {

  /** Value. */
  public readonly value: TValue;

  /** Title. */
  public readonly title: string;

  /** Children. */
  public readonly children: readonly TChild[];

  public constructor(data: TreeItemInitArgs<TValue, TChild>) {
    this.value = data.value;
    this.children = data.children;
    this.title = data.title;
  }
}

type TreeItemInitArgs<TValue, TChild> = TreeItem<TValue, TChild>;
