/**
 * Get previous sibling.
 * @param elem Element.
 * @param selector Selector.
 */
export function getPreviousSibling(elem: Element, selector: string): Element | null {

  // Get the next sibling element
  let sibling = elem.previousElementSibling;

  // If there's no selector, return the first sibling.
  if (!selector) {
    return sibling;
  }

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop.
  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }
    sibling = sibling.previousElementSibling;
  }

  return null;
}

/**
 * Get next sibling.
 * @param elem Element.
 * @param selector Selector.
 */
export function getNextSibling(elem: Element, selector: string): Element | null {

  // Get the next sibling element.
  let sibling = elem.nextElementSibling;

  // If there's no selector, return the first sibling.
  if (!selector) {
    return sibling;
  }

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop.
  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }
    sibling = sibling.nextElementSibling;
  }

  return null;
}

/**
 * Get all next siblings.
 * @param elem Element.
 * @param selector Selector.
 */
export function getAllNextSiblings(elem: Element, selector: string): Element[] {
  const sibling = getNextSibling(elem, selector);

  if (sibling != null) {
    return [sibling, ...getAllNextSiblings(sibling, selector)];
  }

  return [];
}

/**
 * Get all prev sibling.
 * @param elem Element.
 * @param selector Selector.
 */
export function getAllPreviousSiblings(elem: Element, selector: string): Element[] {
  const sibling = getPreviousSibling(elem, selector);

  if (sibling != null) {
    return [sibling, ...getAllPreviousSiblings(sibling, selector)];
  }

  return [];
}
