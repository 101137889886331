import { Injectable, inject } from '@angular/core';

import { BaseUnitPricing } from '@dartsales/common/core/models/pricing/base-pricing';

import { BaseUnitPricingDto } from '../dto/pricing/base-unit-pricing.dto';
import { IMapper } from '../mappers';

import { BasePricingMapper } from './base-pricing.mapper';

/** Base unit pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class BaseUnitPricingMapper implements IMapper<BaseUnitPricingDto, BaseUnitPricing> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  /** @inheritdoc */
  public toDto(data: BaseUnitPricing): BaseUnitPricingDto {
    return {
      ...this.basePricingMapper.toDto(data),
      unitCost: data.unitCost,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: BaseUnitPricingDto): BaseUnitPricing {
    return new BaseUnitPricing({
      ...this.basePricingMapper.fromDto(dto),
      unitCost: dto.unitCost,
    });
  }
}
