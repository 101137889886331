import { Observable } from 'rxjs';

import { AbstractCommand } from './abstract-command';

/** Points list empty command. */
export class PointsListEmptyCommand<TResult = void> implements AbstractCommand<TResult> {

  public constructor(protected readonly args: PointsListEmptyCommandInitArgs<TResult>) { }

  /** @inheritdoc */
  public execute(): Observable<TResult> {
    return this.args.action();
  }
}

type PointsListEmptyCommandInitArgs<TResult> = {

  /** Action. */
  readonly action: AbstractCommand<TResult>['execute'];
};
