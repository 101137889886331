import { Injectable, inject } from '@angular/core';

import { TermData } from '@dartsales/common/core/models/estimate-services/term-data';

import { TermDataDto } from '../dto/estimate-services/term-data.dto';
import { IMapperFromDto } from '../mappers';

import { TermSubcategoryMapper } from './term-subcategory.mapper';

/** Term data mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermDataMapper
implements IMapperFromDto<TermDataDto, TermData> {

  private readonly subcategoryMapper = inject(TermSubcategoryMapper);

  /** @inheritdoc */
  public fromDto(dto: TermDataDto): TermData {
    return new TermData({
      subCategories: dto.subCategories?.map(subCategory => this.subcategoryMapper.fromDto(subCategory)) ?? [],
    });
  }
}
