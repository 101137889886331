import { InjectionToken, Provider, Type, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { ModuleExportParams } from '@dartsales/common/core/models/exports/module-export-params';
import { ViewType } from '@dartsales/common/core/models/view-type';

/** Export module service. */
export type ModuleExportService = {

  /** Supported export formats. */
  readonly supportedExportFormats: readonly ExportFormat[];

  /** View type labels. */
  readonly viewTypeLabels: Readonly<Record<ViewType, string>>;

  /** Export module. */
  export(exportParams: ModuleExportParams): Observable<void>;
};

const MODULE_EXPORT_SERVICE_TOKEN = new InjectionToken<ModuleExportService>(
  'Token for inject module export service.',
);

/** Inject export module service. */
export function injectModuleExportService(): ModuleExportService {
  return inject<ModuleExportService>(MODULE_EXPORT_SERVICE_TOKEN);
}

/**
 * Provide export module service.
 * @param service Export module service.
 */
export function provideModuleExportService(service: Type<ModuleExportService>): Provider {
  return {
    provide: MODULE_EXPORT_SERVICE_TOKEN,
    useClass: service,
  };
}
