import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Component to display string value changes. */
@Component({
  selector: 'dartsalesw-string-value-changes',
  templateUrl: './string-value-changes.component.html',
  styleUrls: ['./string-value-changes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringValueChangesComponent {
  /** Label. */
  @Input()
  public label = '';

  /** Previous value. */
  @Input()
  public previousValue: string | null = null;

  /** Current value. */
  @Input()
  public currentValue: string | null = null;

  /** Placeholder to display if value is empty. */
  @Input()
  public placeholder = '-';
}
