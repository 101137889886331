import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';

import { UserPermission } from '../enums/user-permission';
import { UserRole } from '../enums/user-role';
import { OtherTeamMember } from '../models/project/other-team-member';
import { UserOrganization } from '../models/user-organization';

import { CurrentUserService } from './current-user.service';

/** Service for determining permissions. */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  private readonly userService = inject(CurrentUserService);

  /** Current user roles. */
  public readonly currentRoles$ = this.createCurrentRolesStream();

  /**
   * Whether user has permission in organization.
   * @param permissions Permissions.
   * @param organizationId Organization ID.
   */
  public hasPermissionsInOrganization(permissions: readonly UserPermission[], organizationId: UserOrganization['id']): Observable<boolean> {
    return combineLatest([
      this.userService.currentUser$,
      this.userService.userOrganizations$,
    ]).pipe(
      map(([currentUser, userOrganizations]) => {
        if (currentUser?.isGlobalAdmin) {
          return true;
        }
        const organization = userOrganizations.find(userOrganization => userOrganization.id === organizationId);
        const allowedRoles = permissions.flatMap(permission => UserPermission.getAllowedRoles(permission));
        const hasPermission = organization?.hasAllowedRoles(allowedRoles) ?? false;
        return hasPermission;
      }),
    );
  }

  /**
   * Checks whether current user has provided permissions.
   * Permissions are compared against user's roles.
   * @param permissions Permissions.
   */
  public hasPermission(permissions: readonly UserPermission[]): Observable<boolean> {
    const allowedRoles = permissions.flatMap(permission => UserPermission.getAllowedRoles(permission));
    return this.currentRoles$.pipe(
      map(roles => allowedRoles.some(allowedRole => roles.includes(allowedRole))),
    );
  }

  /**
   * Checks whether current user is team member.
   * @param teamMembers Project team members.
   */
  public checkIsTeamMember(teamMembers: readonly OtherTeamMember[]): Observable<boolean> {
    return this.userService.currentUser$
      .pipe(
        map(user => user != null ? teamMembers.some(member => member.memberId === user.id) : false),
      );
  }

  private createCurrentRolesStream(): Observable<UserRole[]> {
    return this.userService.currentUser$.pipe(
      map(user => {
        if (user?.isGlobalAdmin) {
          return UserRole.toArray();
        }
        const roles = user?.organizations.flatMap(org => org.roles) ?? [];
        const rolesWithoutDuplication = new Set(roles);
        return [...rolesWithoutDuplication];
      }),
    );
  }
}
