import { Pipe, PipeTransform } from '@angular/core';

import { pluralize } from '@dartsales/common/core/utils/pluralize';

/** Pipe to pluralize text according to count. */
@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {

  /**
   * Pluralize text according to count.
   * @param count Count.
   * @param singular Singular form.
   * @param plural Plural form.
   * @returns Number and text, e.g. '1 item' or '5 items'.
   */
  public transform(count: number, singular: string, plural: string): string {
    return pluralize({ count, singular, plural });
  }

}
