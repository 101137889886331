/** Module lockable percents status. */
export class ModuleLockablePercentsStatus {

  /** Whether contingency is locked. */
  public readonly isContingencyLocked: boolean;

  /** Whether gross margin is locked. */
  public readonly isGrossMarginLocked: boolean;

  /** Whether escalation is locked. */
  public readonly isEscalationLocked: boolean;

  public constructor(data: ModuleLockablePercentsStatusInitArgs) {
    this.isContingencyLocked = data.isContingencyLocked;
    this.isGrossMarginLocked = data.isGrossMarginLocked;
    this.isEscalationLocked = data.isEscalationLocked;
  }

  /** Get default module lockable percents status. */
  public static getDefaultStatus(): ModuleLockablePercentsStatus {
    return new ModuleLockablePercentsStatus({
      isContingencyLocked: false,
      isGrossMarginLocked: false,
      isEscalationLocked: false,
    });
  }

}

type ModuleLockablePercentsStatusInitArgs = ModuleLockablePercentsStatus;
