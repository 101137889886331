import { BehaviorSubject, map } from 'rxjs';

/**
 * Processing requests counter.
 * We use this utility to manage processing requests. For instance we can wait until all processing requests are finished.
 */
export class ProcessingRequestsCounter {
  private activeRequestCount = 0;

  private readonly trigger$ = new BehaviorSubject<void>(undefined);

  /** Processing save requests count. */
  public readonly requestsCount$ = this.trigger$.pipe(
    map(() => this.activeRequestCount),
  );

  /** Increment active requests counter. */
  public increment(): void {
    this.activeRequestCount += 1;
    this.trigger$.next();
  }

  /** Decrement active requests counter. */
  public decrement(): void {
    if (this.activeRequestCount > 0) {
      this.activeRequestCount -= 1;
    }
    this.trigger$.next();
  }

  /** Reset active requests counter. */
  public reset(): void {
    this.activeRequestCount = 0;
    this.trigger$.next();
  }
}
