import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { ModuleExportParamsFormControls } from '@dartsales/common/core/models/forms/export/module-export-params-form';

/** Export multiple module form error state matcher. */
export class ExportMultipleModuleFormErrorStateMatcher implements ErrorStateMatcher {

  private readonly defaultStateMatcher = new ErrorStateMatcher();

  public constructor(
    private readonly form: FormGroup<ModuleExportParamsFormControls>,
  ) {}

  /**
   * Is error state.
   * @param _control Control.
   */
  public isErrorState(): boolean {
    return (
      this.form.controls.isExportCustomerView.touched ||
        this.form.controls.isExportCustomerView.touched) &&
      this.defaultStateMatcher.isErrorState(this.form, null);
  }
}
