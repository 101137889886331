<dartsalesc-editable-table-cell
  #cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
  (cellBlur)="onBlur()"
>
  <ng-container
    *ngIf="numberControl.enabled"
    dartsalescTableCellEditMode
  >
    <input
      #inputElement
      dartsalescTableCellInput
      type="number"
      class="number-edit-mode"
      [placeholder]="placeholder"
      [formControl]="numberControl"
      [readonly]="isReadonly"
      [min]="min"
      [errorStateMatcher]="errorStateMatcher"
      (keydown.enter)="inputElement.blur()"
    />
  </ng-container>

  <div
    *ngIf="hideValue"
    dartsalescTableCellViewMode
  >
    <!-- Empty. -->
  </div>

  <div
    *ngIf="!hideValue"
    dartsalescTableCellViewMode
    class="number-view-mode"
    [class.number-view-mode_has-suffix]="suffix"
  >
    <span
      class="number-view-mode__content"
      [title]="numberControl.value"
    >
      {{ numberControl.value }}
    </span>
  </div>

  <ng-container
    *ngIf="suffix && (!hideValue || cell.isEditMode)"
    dartsalescTableCellSuffix
  >
    {{ suffix }}
  </ng-container>
</dartsalesc-editable-table-cell>
