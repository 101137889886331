<dartsalesw-base-filters [isIndicatorVisible]="isActive">
  <dartsalesw-sort-section
    [isActive]="isActive"
    [sortDirection]="sortDirection ?? sortDirections.ASC"
    (sortDirectionChange)="onSortDirectionChange($event)"
  />
  <mat-error
    *ngIf="form.errors"
    class="error"
  >
    <dartsalesc-form-control-validation-message [control]="form" />
  </mat-error>
  <div class="filter">
    <div
      *ngIf="currencyMask$ | async as currencyMask"
      class="inputs-filter-container"
    >
      <mat-form-field class="no-label">
        <mat-label>Min</mat-label>
        <input
          matInput
          [dartsalescInputMask]="currencyMask"
          [value]="form.controls.start.value"
          (change)="onChangeMinInput($event)"
        />
      </mat-form-field>
      <span>&mdash;</span>
      <mat-form-field class="no-label">
        <mat-label>Max</mat-label>
        <input
          matInput
          [dartsalescInputMask]="currencyMask"
          [value]="form.controls.end.value"
          (change)="onChangeMaxInput($event)"
        />
      </mat-form-field>
    </div>
    <div class="range-filter-container">
      <mat-slider
        class="range-filter"
        [min]="defaultMinValue"
        [max]="maxValue$ | async"
        [step]="defaultStep"
      >
        <input
          matSliderStartThumb
          [formControl]="form.controls.start"
        />
        <input
          matSliderEndThumb
          [formControl]="form.controls.end"
        />
      </mat-slider>
    </div>
  </div>
</dartsalesw-base-filters>
