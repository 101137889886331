import { CollapsibleRowData } from '@dartsales/common/shared/components/ag-grid-table/utils/collapse-rows-strategy';
import { MaterialTableRowType } from 'projects/web/src/app/features/shared/components/material-module/material-module-table/view-models/material-table-row-view-model';

import { ModuleSettingsLocalStorage } from './module-settings';

/** Material module to save in local storage. */
export class MaterialModuleLocalStorage extends ModuleSettingsLocalStorage {

  /** Whether WFS column is expended. */
  public readonly isExpandWFS: boolean;

  /** Whether model column is expended. */
  public readonly isExpandModel: boolean;

  /** Collapse row types. */
  public readonly collapsedRowTypes: readonly MaterialTableRowType[];

  /** Collapse rows data. */
  public readonly collapsibleRowData: readonly CollapsibleRowData<MaterialTableRowType>[];

  public constructor(data: MaterialModuleLocalStorageInitArgs) {
    super(data);
    this.isExpandWFS = data.isExpandWFS;
    this.isExpandModel = data.isExpandModel;
    this.collapsedRowTypes = data.collapsedRowTypes;
    this.collapsibleRowData = data.collapsibleRowData;
  }
}

type MaterialModuleLocalStorageInitArgs = MaterialModuleLocalStorage;
