/** User estimate. */
export class UserEstimate {

  /** Estimate ID. */
  public readonly id: number;

  /** Estimate name. */
  public readonly name: string;

  /** Whether user is creator of estimate. */
  public readonly isCreator: boolean;

  public constructor(data: UserEstimateInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.isCreator = data.isCreator;
  }
}

type UserEstimateInitArgs = UserEstimate;
