import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/errors/app-error';
import { Login } from '../../models/auth/login';

import { LoginDto } from './dto/auth/login.dto';
import { MappedValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './errors/extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Login data mapper. */
@Injectable({
  providedIn: 'root',
})
export class LoginDataMapper
implements
    IMapperToDto<LoginDto, Login>,
    IValidationErrorMapper<LoginDto, Login> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<LoginDto> | null | undefined,
  ): EntityValidationErrors<Login> {
    return {
      email: extractErrorMessage(errorDto?.email),
      password:
        extractErrorMessage(errorDto?.password) ??
        extractErrorMessage(errorDto?.non_field_errors),
    };
  }

  /** @inheritdoc */
  public toDto(data: Login): LoginDto {
    return {
      email: data.email,
      password: data.password,
      rememberMe: data.shouldStaySignedIn,
    };
  }
}
