/** Palette tag. */
export class PaletteTag {

  /** Name. This value is unique. */
  public readonly name: string;

  /** Whether the tag is selected by default. */
  public readonly isSelected: boolean;

  public constructor(data: PaletteTagInitArgs) {
    this.name = data.name;
    this.isSelected = data.isSelected;
  }
}

type PaletteTagInitArgs = PaletteTag;
