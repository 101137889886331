import { Module } from '../../module';

import { MaterialModuleData } from './data/material-module-data';
import { MaterialModuleProperties } from './properties/material-module-properties';

/** Material module data. */
export class MaterialModule extends Module<MaterialModuleProperties, MaterialModuleData> {
  public constructor(data: MaterialModuleInitArgs) {
    super(data);
  }
}

type MaterialModuleInitArgs = MaterialModule;
export type EditMaterialModule = Module<MaterialModuleProperties, MaterialModuleData>;
