import { ModuleProperties, ModulePropertiesInitArgs } from '../module-properties';

/** Labor module data. */
export class LaborModuleProperties extends ModuleProperties {

  /** Grand total hours. */
  public readonly grandTotalHours: number;

  /** Grand total cost. */
  public readonly grandTotalCost: number;

  public constructor(data: LaborModulePropertiesInitArgs) {
    super(data);
    this.grandTotalHours = data.grandTotalHours;
    this.grandTotalCost = data.grandTotalCost;
  }
}

type LaborModulePropertiesInitArgs = ModulePropertiesInitArgs & Pick<LaborModuleProperties,
  'grandTotalHours' | 'grandTotalCost'>;
