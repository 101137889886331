import { ShortEstimate } from './short-estimate';

/** Suggested merge estimate which includes change orders and alternates. */
export class SuggestedMergeEstimate extends ShortEstimate {

  /** Whether estimate is template. */
  public readonly isTemplate: boolean;

  /** Organization name. */
  public readonly organizationName: string;

  /** Estimate change orders. */
  public readonly changeOrders: readonly ShortEstimate[];

  /** Estimate alternates. */
  public readonly alternates: readonly ShortEstimate[];

  public constructor(data: CombineEstimateInitArgs) {
    super(data);
    this.isTemplate = data.isTemplate;
    this.organizationName = data.organizationName;
    this.changeOrders = data.changeOrders;
    this.alternates = data.alternates;
  }
}

type CombineEstimateInitArgs = SuggestedMergeEstimate;
