import { Injectable, Injector, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { ViewType } from '@dartsales/common/core/models/view-type';

import { ExportDialogComponent, ExportDialogData } from '../../../../shared/components/export-dialogs/export-dialog/export-dialog.component';
import { ExportMultipleEstimateDialogComponent, ExportMultipleEstimateDialogData } from '../../../../shared/components/export-dialogs/export-multiple-estimate-dialog/export-multiple-estimate-dialog.component';

import { injectEstimateExportService } from './estimate-export.service';

/** Estimate export dialog service. */
@Injectable()
export class EstimateExportDialogService {

  private readonly estimateExportService = injectEstimateExportService();

  private readonly dialogService = inject(MatDialog);

  private readonly injector = inject(Injector);

  /**
   * Open export dialog.
   * @param estimateId Estimate ID.
   * @param viewType View type.
   */
  public openExportDialog(
    estimateId: EstimateId,
    viewType: ViewType,
  ): MatDialogRef<ExportDialogComponent, void> {

    const data: ExportDialogData = {
      exportFn: exportFormat => this.estimateExportService.export(estimateId, {
        exportFormat,
        viewType,
      }),
    };

    return this.dialogService.open<ExportDialogComponent, ExportDialogData>(ExportDialogComponent, { data });
  }

  /**
   * Open export multiple dialog.
   * @param estimateId Estimate ID.
   */
  public openExportMultipleDialog(
    estimateId: EstimateId,
  ): MatDialogRef<ExportMultipleEstimateDialogComponent, void> {
    return this.dialogService.open<
      ExportMultipleEstimateDialogComponent, ExportMultipleEstimateDialogData>(
      ExportMultipleEstimateDialogComponent,
      {
        data: { estimateId },
        injector: this.injector,
      },
    );
  }
}
