import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Read-only project message. */
@Component({
  selector: 'dartsalesw-readonly-project-message',
  templateUrl: './readonly-project-message.component.html',
  styleUrls: [
    '../info-card.component.css',
    './readonly-project-message.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadonlyProjectMessageComponent {

  /** Info message. */
  protected readonly infoMessage = 'The project is in the read-only status';

}
