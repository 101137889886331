import { Directive, inject, ElementRef } from '@angular/core';

/** Cell renderer content directive. */
@Directive({
  selector: '[dartsalescRendererContent]',
  standalone: true,
  host: {

    // Necessary to be able to track selection.
    tabindex: '-1',
  },
})
export class CellRendererContentDirective {

  /** Element ref. */
  public readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
}
