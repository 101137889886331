import { InjectionToken, Provider } from '@angular/core';

import { PointsListModuleData } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-module-data';

import { PointListCopyPayload } from '../services/point-list-copy-payload.service';
import { ModuleDataBroadcastChannel } from '../../../../../utils/module-data-broadcast-channel';

/** Points list module sync token. */
export const POINTS_LIST_MODULE_SYNC = new InjectionToken<ModuleDataBroadcastChannel<PointsListModuleData>>(
  'Points list module sync token',
);

/** Points list copy payload sync token. */
export const POINTS_LIST_COPY_PAYLOAD_SYNC = new InjectionToken<ModuleDataBroadcastChannel<PointListCopyPayload>>(
  'Points list copy payload sync token',
);

/** Provide points list module sync. */
export function providePointsListModuleSync(): Provider[] {
  return [
    {
      provide: POINTS_LIST_MODULE_SYNC,

      // TODO (DART-1671): This might work incorrectly because `PointsListModuleData` class might have non serializable members.
      useFactory: () => new ModuleDataBroadcastChannel<PointsListModuleData>({
        name: 'PointsListModuleService',
        syncBetweenEstimates: false,
      }),
    },
  ];
}

/** Provide points list copy payload sync. */
export function providePointsListCopyPayloadSync(): Provider[] {
  return [
    {
      provide: POINTS_LIST_COPY_PAYLOAD_SYNC,
      useFactory: () => new ModuleDataBroadcastChannel<PointListCopyPayload>({
        name: 'PointListCopyPayloadService',
        syncBetweenEstimates: true,
      }),
    },
  ];
}
