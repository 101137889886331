import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MaterialTableRowExpansionService } from '../../material-module-table/services/material-table-row-expansion.service';

/** Material module table rows expansion actions. */
@Component({
  selector: 'dartsalesw-material-expansion-actions',
  templateUrl: './material-expansion-actions.component.html',
  styleUrls: ['./material-expansion-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialExpansionActionsComponent {

  /** Row expansion service. */
  protected readonly rowExpansionService = inject(MaterialTableRowExpansionService);
}
