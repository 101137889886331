import { Injectable } from '@angular/core';

import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';

import { AmountPercentValueDto } from '../dto/estimate/amount-percent-value.dto';
import { IMapperFromDto, IMapperToDto } from '../mappers';

/** Amount entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class AmountPercentMapper implements
  IMapperFromDto<AmountPercentValueDto, AmountPercent>,
  IMapperToDto<AmountPercentValueDto, AmountPercent> {

  /** @inheritdoc */
  public fromDto(dto: AmountPercentValueDto): AmountPercent {
    return new AmountPercent({
      amount: dto.amount ?? 0,
      percent: dto.percent ?? 0,
    });
  }

  /** @inheritdoc */
  public toDto(data: AmountPercent): AmountPercentValueDto {
    return {
      amount: data.amount,
      percent: data.percent,
    };
  }
}
