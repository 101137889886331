import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Module header layout component. */
@Component({
  selector: 'dartsalesw-module-header-layout',
  templateUrl: './module-header-layout.component.html',
  styleUrls: ['./module-header-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleHeaderLayoutComponent { }
