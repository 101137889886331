import { OverridableBasePricing, OverridableBasePricingInitArgs } from '@dartsales/common/core/models/pricing/overridable-base-pricing';

/** Estimate service pricing. */
export class EstimateServicePricing extends OverridableBasePricing {

  /** Quantity. */
  public quantity: number;

  public constructor(initArgs: EstimateServicePricingInitArgs) {
    super(initArgs);
    this.quantity = initArgs.quantity;
  }
}

type EstimateServicePricingInitArgs = OverridableBasePricingInitArgs &
  Pick<EstimateServicePricing, 'quantity'>;
