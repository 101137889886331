import { Injectable } from '@angular/core';

import { PointsListSystemClassification } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-classification';

import { PointsListSystemClassificationDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-classification.dto';
import { IMapper } from '../../../mappers';

/** Points list system classification mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemClassificationMapper implements IMapper<PointsListSystemClassificationDto, PointsListSystemClassification> {

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemClassificationDto): PointsListSystemClassification {
    return new PointsListSystemClassification({
      id: dto.id,
      name: dto.name,
      laborMultiplier: dto.laborMultiplier,
      isRemoved: dto.isRemoved,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemClassification): PointsListSystemClassificationDto {
    return {
      id: data.id,
      name: data.name,
      laborMultiplier: data.laborMultiplier,
      isRemoved: data.isRemoved,
    };
  }
}
