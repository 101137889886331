import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Breadcrumbs component. */
@Component({
  selector: 'dartsalesc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {}
