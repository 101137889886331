import { EstimateViewExportParams } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';

import { EstimateItemsMultipleExportTreeUtils as Utils } from './estimate-items-multiple-export-tree-utils';
import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';
import { EstimateTermsMultipleExportTreeMapper } from './estimate-terms-multiple-export-tree-mapper';

export namespace EstimateItemsMultipleExportTreeBuilder {
  const emptyParams: EstimateViewExportParams = new EstimateViewExportParams({
    moduleTypes: [],
    terms: [],
  });

  /**
   * Map to config.
   * @param sourceNestedNode Source nested node.
   * @param selectedNodes Nodes.
   */
  export function buildConfig(
    sourceNestedNode: Types.EstimateChildrenNestedNode,
    selectedNodes: readonly Types.EstimateChildrenFlatNode[],
  ): EstimateViewExportParams {
    const filteredNestedNode = Utils.filterNotSelectedChildren(
      [sourceNestedNode],
      selectedNodes,
    ).at(0);

    if (filteredNestedNode == null) {
      return emptyParams;
    }

    return createConfigFromEstimateNestedNode(filteredNestedNode);
  }

  /**
   * CreateConfigFromEstimateNestedNode.
   * @param node Node.
   */
  function createConfigFromEstimateNestedNode(
    node: Types.EstimateChildrenNestedNode,
  ): EstimateViewExportParams {
    if (node.value.type === Types.NodeType.Term) {
      const terms = EstimateTermsMultipleExportTreeMapper.mapTermNodes([node]);
      return new EstimateViewExportParams({
        ...emptyParams,
        terms,
      });
    }

    if (node.value.type === Types.NodeType.EstimateModuleType) {
      return new EstimateViewExportParams({
        ...emptyParams,
        moduleTypes: [node.value.item],
      });
    }

    return node.children.reduce((config, childNode) => {
        const configFromNode = createConfigFromEstimateNestedNode(childNode);
        return new EstimateViewExportParams({
          moduleTypes: configFromNode.moduleTypes.concat(config.moduleTypes),
          terms: configFromNode.terms.concat(config.terms),
        });
      }, emptyParams);
  }
}
