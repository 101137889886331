import { Injectable } from '@angular/core';

import { TermServiceTreeItem } from '@dartsales/common/core/models/estimate-services/term-service-tree-item';

import { IMapperToDto } from '../mappers';
import { EstimateServiceExportParamsDto } from '../dto/export/estimate-service-export-params.dto';

/** Estimate service export config mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateServiceExportConfigMapper implements IMapperToDto<EstimateServiceExportParamsDto, TermServiceTreeItem> {

  /** @inheritdoc */
  public toDto(data: TermServiceTreeItem): EstimateServiceExportParamsDto {
    return {
      serviceId: data.value.serviceId,
      moduleTypes: data.children,
    };
  }
}
