import { Injectable, inject } from '@angular/core';

import { SuggestedMergeEstimate } from '@dartsales/common/core/models/estimate/suggested-merge-estimate';

import { IMapperFromDto } from '../mappers';
import { SuggestedMergeEstimateDto } from '../dto/estimate/suggested-merge-estimate.dto';

import { EstimateMapper } from './estimate.mapper';

/** Suggested merge estimate mapper. */
@Injectable({
  providedIn: 'root',
})
export class SuggestedMergeEstimateMapper implements IMapperFromDto<SuggestedMergeEstimateDto, SuggestedMergeEstimate> {
  private readonly estimateMapper = inject(EstimateMapper);

  /** @inheritdoc */
  public fromDto(dto: SuggestedMergeEstimateDto): SuggestedMergeEstimate {
    return new SuggestedMergeEstimate({
      ...this.estimateMapper.fromShortDto(dto),
      isTemplate: dto.isTemplate,
      organizationName: dto.organizationName,
      changeOrders: dto.changeOrders.map(changeOrder => this.estimateMapper.fromShortDto(changeOrder)),
      alternates: dto.alternates.map(alternate => this.estimateMapper.fromShortDto(alternate)),
    });
  }

}
