import { Injectable } from '@angular/core';

import { PaletteSystem } from '@dartsales/common/core/models/components/palette/palette-system';
import { PaletteOrganization } from '@dartsales/common/core/models/components/palette/palette-organization';
import { LaborTaskHours } from '@dartsales/common/core/models/estimate/modules/points-list/labor-task-hours';

import { PaletteSystemDto } from '../../dto/components/palette/palette-system.dto';
import { IMapperFromDto } from '../../mappers';

/** Palette system mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteSystemMapper implements IMapperFromDto<PaletteSystemDto, PaletteSystem> {

  /** @inheritdoc */
  public fromDto(dto: PaletteSystemDto): PaletteSystem {
    return new PaletteSystem({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      organization: new PaletteOrganization({
        id: dto.organizationId,
        name: dto.organizationName,
      }),
      parts: dto.parts.map(part => ({
        id: part.id,
        name: part.name,
        description: part.description,
        manufacturer: part.manufacturer,
        modelNumber: part.modelNumber,
        organization: new PaletteOrganization({
          id: dto.organizationId,
          name: dto.organizationName,
        }),
        tags: part.tags ?? [],
        revision: part.majorMinorRevision,
        laborTaskHours: part.laborTaskHours?.map(laborTaskHours => new LaborTaskHours(laborTaskHours)) ?? [],
        partGroup: part.partGroup ? {
          id: part.partGroup.id,
          name: part.partGroup.name,
        } : null,
        supplier: part.supplier,
        unitCost: part.unitCost,
      })),
      tags: dto.tags ?? [],
    });
  }
}
