/** Pagination data init args. */
export type PaginationDataInitArgs = {

  /** Current Page. */
  readonly page: number;

  /** Page Size. */
  readonly pageSize: number;

  /** Total Count. */
  readonly totalCount: number;
};

/** Pagination data. */
export class PaginationData {

  /** Current Page. */
  public readonly page: number;

  /** Page Size. */
  public readonly pageSize: number;

  /** Total Count. */
  public readonly totalCount: number;

  private readonly defaultPageSize = 10;

  /** Get offset. */
  public get offset(): number {
    return this.pageSize * (this.page - 1);
  }

  /** Page length. */
  public get pagesCount(): number {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  /** Is last page. */
  public get isLastPage(): boolean {
    return this.page * this.pageSize >= this.totalCount;
  }

  /** Is first page. */
  public get isFirstPage(): boolean {
    return this.page === 1;
  }

  public constructor(options?: Partial<PaginationDataInitArgs>) {
    this.page = options?.page ?? 1;
    this.pageSize = options?.pageSize ?? this.defaultPageSize;
    this.totalCount = options?.totalCount ?? 1;
  }

  /**
   * Create new instance with applying changes.
   * @param changes Changes to apply during fork.
   */
  public fork(changes: Partial<PaginationData>): PaginationData {
    return new PaginationData({
      page: changes.page ?? this.page,
      pageSize: changes.pageSize ?? this.pageSize,
      totalCount: changes.totalCount ?? this.totalCount,
    });
  }

  /** Fork with reset. */
  public forkWithReset(): PaginationData {
    return this.fork({
      page: 1,
    });
  }
}
