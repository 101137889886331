import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable, map, filter, shareReplay, switchMap, first, repeat } from 'rxjs';

import { FinalEstimate } from '@dartsales/common/core/models/estimate/final-estimate/final-estimate';
import { Project } from '@dartsales/common/core/models/project/project';

import { PROJECT_ID_PARAM } from '../../../../../web/src/app/web-route-paths';
import { FinalEstimateStatus } from '../models/estimate/final-estimate/final-estimate-status';

import { FinalEstimateApiService } from './api/final-estimate-api.service';

/** Service with final estimates for the current project. */
@Injectable()
export class ProjectFinalEstimatesService {

  private readonly finalEstimateApiService = inject(FinalEstimateApiService);

  private readonly route = inject(ActivatedRoute);

  private readonly refreshSubject = new Subject<void>();

  private readonly projectId$ = this.createProjectIdStream();

  /** Final estimates. */
  public readonly estimates$ = this.createFinalEstimatesStream(this.projectId$);

  /** Final awarded estimate. */
  public readonly awardedEstimate$ = this.createFinalAwardedEstimateStream(this.estimates$);

  /** Refresh final estimates. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  private createProjectIdStream(): Observable<Project['id']> {
    return this.route.paramMap.pipe(
      map(params => Number(params.get(PROJECT_ID_PARAM))),
      filter(id => !Number.isNaN(id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createFinalEstimatesStream(projectId$: Observable<Project['id']>): Observable<FinalEstimate[]> {
    return projectId$.pipe(
      switchMap(id => this.finalEstimateApiService.getList(id)),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createFinalAwardedEstimateStream(finalEstimates$: Observable<FinalEstimate[]>): Observable<FinalEstimate | null> {
    return finalEstimates$.pipe(
      map(estimates => estimates.find(estimate => estimate.status === FinalEstimateStatus.Awarded) ?? null),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
