<dartsalesw-base-filters [isLoading]="isLoading$ | async">
  <dartsalesw-checkboxes-section
    *ngrxLet="preselectedOptions$ | async as preselectedOptions"
    class="checkboxes-section"
    [options]="(options$ | async) ?? []"
    [preselectedOptions]="preselectedOptions ?? []"
    [activeOptionsValues]="activeTags"
    (searchChange)="onOptionsSearchChange($event)"
    (selectedOptionsChange)="
      onSelectedOptionsChange($event, preselectedOptions ?? [])
    "
  />
</dartsalesw-base-filters>
