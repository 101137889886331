import { Injectable, inject } from '@angular/core';

import { PointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/points-list-tab';
import { EditPointsListTab, BatchEditPointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/edit-points-list-tab';
import { CreatePointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/create-points-list-tab';
import { ShortPointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/short-points-list-tab';

import { IMapper } from '../../../mappers';
import { BatchEditPointsListTabDto, CreatePointsListTabDto, EditPointsListTabDto, PointsListTabDto, ShortPointsListTabDto } from '../../../dto/estimate/modules/points-list/tab/points-list-tab.dto';

import { PointsListSystemGroupMapper } from './points-list-system-group.mapper';
import { PointsListTabPropertiesMapper } from './points-list-tab-properties.mapper';

/** Points list tab entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListTabMapper implements IMapper<PointsListTabDto, PointsListTab> {
  private readonly pointsListSystemGroupMapper = inject(PointsListSystemGroupMapper);

  private readonly pointsListTabPropertiesMapper = inject(PointsListTabPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListTabDto): PointsListTab {
    return new PointsListTab({
      ...this.fromShortDto(dto),
      id: dto.id,
      name: dto.name,
      description: dto.description,
      estimateId: dto.estimateId,
      order: dto.order,
      systemGroups: dto.systemGroups.map(group => this.pointsListSystemGroupMapper.fromDto(group)),
      properties: this.pointsListTabPropertiesMapper.fromDto(dto.properties),
    });
  }

  /**
   * Map points list tab data from short DTO.
   * @param dto Short DTO.
   */
  public fromShortDto(dto: ShortPointsListTabDto): ShortPointsListTab {
    return new ShortPointsListTab({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      estimateId: dto.estimateId,
      order: dto.order,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListTab): PointsListTabDto {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      estimateId: data.estimateId,
      order: data.order,
      systemGroups: data.systemGroups.map(group => this.pointsListSystemGroupMapper.toDto(group)),
      properties: this.pointsListTabPropertiesMapper.toDto(data.properties),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListTab): CreatePointsListTabDto {
    return {
      name: data.name,
      description: data.description,
      systemGroupIds: data.systemGroupIds,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListTab): EditPointsListTabDto {
    return {
      name: data.name,
      description: data.description,
      order: data.order ?? undefined,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditPointsListTab): BatchEditPointsListTabDto {
    return {
      tabId: data.id,
      tab: this.toEditDto(data.value),
    };
  }
}
