<span class="header-units-label">
  {{ shortMarginTypeLabel }}
</span>
<mat-select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="markupTypeControl"
  (selectionChange)="onOptionSelected($event.value)"
>
  <mat-option
    *ngFor="let item of markupTypeOptions$ | async; trackBy: trackByItem"
    class="header-units-select__option"
    [value]="item.value"
  >
    {{ item.label }}
  </mat-option>
</mat-select>

<div
  *ngIf="isEditMode; else viewMode"
  class="input-container"
  [class.input-container_display]="
    markupTypeControl.value.units === amountCalcUnits.Percent
  "
>
  <div
    *ngIf="cellPrefix"
    class="prefix"
  >
    <ng-content select="[dartsalescTableCellPrefix]" />
  </div>
  <input
    *ngrxLet="
      markupTypeControl.value.marginType ===
      marginType.GrossMargin as displayGrossMargin
    "
    #gmInput
    class="input-container__input"
    [class.cell__has-prefix]="cellPrefix && displayGrossMargin"
    [inputMask]="grossMarginMask"
    [class.input-container__input_display]="displayGrossMargin"
    [formControl]="marginPercentsControl.controls.grossMargin"
    [readonly]="isLockedValue"
    (blur)="onInputBlur($event)"
    (keydown.enter)="gmInput.blur(); $event.stopPropagation()"
  />
  <input
    *ngrxLet="
      markupTypeControl.value.marginType === marginType.Markup as displayMarkup
    "
    #markupInput
    class="input-container__input"
    [inputMask]="markupMask"
    [class.cell__has-prefix]="cellPrefix && displayMarkup"
    [class.input-container__input_display]="displayMarkup"
    [formControl]="marginPercentsControl.controls.markup"
    [readonly]="isLockedValue"
    (blur)="onInputBlur($event)"
    (keydown.enter)="markupInput.blur(); $event.stopPropagation()"
  />
</div>

<ng-template #viewMode>
  <dartsalesw-table-header-readonly-cell
    label="Average Markup percent"
    [value]="value"
  />
</ng-template>
