import { FilterData } from '../../filter-data';
import { isEmpty, textContains, textEquals } from '../../shared-filter-operators';
import { stringListSelect } from '../../shared-filter-select-operators';

/** Contracting team field for filter. */
export enum ContractingTeamField {
  ArchitectCompanyName = 'contractingTeamArchitectCompanyName',
  ConsultingEngineerCompanyName = 'contractingTeamConsultingEngineerCompanyName',
  PrimeContractorCompanyName = 'contractingTeamPrimeContractorCompanyName',
  ContractWithCompanyName = 'contractingTeamContractWithCompanyName',
}

export namespace ContractingTeamField {
  const architectCompanyName = {
    type: ContractingTeamField.ArchitectCompanyName,
    label: 'Architect Company Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ContractingTeamField>;

  const consultingEngineerCompanyName = {
    type: ContractingTeamField.ConsultingEngineerCompanyName,
    label: 'Consulting Engineer Company Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ContractingTeamField>;

  const primeContractorCompanyName = {
    type: ContractingTeamField.PrimeContractorCompanyName,
    label: 'Prime Contractor Company Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ContractingTeamField>;

  const contractWithCompanyName = {
    type: ContractingTeamField.ContractWithCompanyName,
    label: 'Contract With Company Name',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<ContractingTeamField>;

  const ALL_PROJECT_FIELDS = [
    architectCompanyName,
    consultingEngineerCompanyName,
    primeContractorCompanyName,
    contractWithCompanyName,
  ] as const;

  export const GROUP_NAME = 'Contracting Team';

  /** Get all fields. */
  export function getAllFields(): typeof ALL_PROJECT_FIELDS {
    return ALL_PROJECT_FIELDS;
  }
}
