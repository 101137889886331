import { ModuleProperties } from '@dartsales/common/core/models/estimate/modules/module-properties';

import { BaseModulePropertiesList } from '../estimate/module-properties-list';

/** Estimate service modules. */
export class EstimateServiceModules extends BaseModulePropertiesList {

  /** Totals module. */
  public readonly totals: {

    /** Properties. */
    readonly properties: ModuleProperties;
  };

  public constructor(initArgs: EstimateServiceModulesInitArgs) {
    super(initArgs);
    this.totals = initArgs.totals;
  }
}

type EstimateServiceModulesInitArgs = EstimateServiceModules;
