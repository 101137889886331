import { ChangeDetectionStrategy, Component } from '@angular/core';

import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { AbstractAmountPercentTableHeaderCellComponent } from './abstract-amount-percent-table-header';

/** Contingency table header cell component. */
@Component({
  selector: 'dartsalesw-contingency-table-header-cell',
  templateUrl: '../amount-percent-table-header-cell/amount-percent-table-header-cell.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    '../amount-percent-table-header-cell/amount-percent-table-header-cell.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => ContingencyTableHeaderCellComponent)],
})
export class ContingencyTableHeaderCellComponent extends AbstractAmountPercentTableHeaderCellComponent {

  /** Header label. */
  protected readonly headerLabel = 'Contg';

  /** @inheritdoc */
  protected readonly typeOptions$ = this.createOptionsStream(
    'Contingency $',
    'Contingency %',
  );
}
