import { Injectable } from '@angular/core';

import { BaseOrganization } from '../../models/organization';

import { IMapperFromDto } from './mappers';
import { BaseOrganizationDto } from './dto/organization.dto';

/** Base organization entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class BaseOrganizationMapper implements IMapperFromDto<BaseOrganizationDto, BaseOrganization> {

  /** @inheritdoc */
  public fromDto(dto: BaseOrganizationDto): BaseOrganization {
    return new BaseOrganization({
      id: dto.id,
      name: dto.name,
      logoUrl: dto.organizationLogoDownloadUrl?.url ?? null,
    });
  }
}
