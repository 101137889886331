import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TabLink } from '@dartsales/common/core/models/tab-link';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';

/** Navigation tabs component. */
@Component({
  selector: 'dartsalesw-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {
  /** Tab links. */
  @Input()
  public links: TabLink[] | null = [];

  /** Track by index. */
  public readonly trackByIndex = trackByIndex;

  /** Query params. */
  public readonly queryParams$ = this.route.queryParams;

  public constructor(
    private readonly route: ActivatedRoute,
  ) { }

}
