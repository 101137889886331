/** Estimate part custom property. */
export class EstimatePartCustomProperty {

  /** Key. */
  public readonly key: string;

  /** Value. */
  public readonly value: string;

  public constructor(data: EstimatePartCustomPropertyInitArgs) {
    this.key = data.key;
    this.value = data.value;
  }
}

type EstimatePartCustomPropertyInitArgs = EstimatePartCustomProperty;
