import { ModuleUnitsSettingsLocalStorage } from './module-units-settings';

/** Estimate module settings local storage. */
export class ModuleSettingsLocalStorage {

  /** Units. */
  public readonly units: ModuleUnitsSettingsLocalStorage;

  /** Whether escalation column is expended. */
  public readonly isExpandedEscalation: boolean;

  /** Whether pricing column is expended. */
  public readonly isExpandedPricing: boolean;

  public constructor(data: ModuleSettingsLocalStorageInitArgs) {
    this.units = data.units;
    this.isExpandedEscalation = data.isExpandedEscalation;
    this.isExpandedPricing = data.isExpandedPricing;
  }
}

type ModuleSettingsLocalStorageInitArgs = ModuleSettingsLocalStorage;
