import { Injectable } from '@angular/core';

import { TermMapDto } from '@dartsales/common/core/services/mappers/dto/estimate-services/term-map.dto';

import { TermMap } from '../../models/term-map';

import { IMapperFromDto } from './mappers';

/** Term map mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermMapMapper implements IMapperFromDto<TermMapDto, TermMap> {

  /** @inheritdoc */
  public fromDto(dto: TermMapDto): TermMap {
    return new TermMap({
      termId: dto.termId,
      alternateId: dto.alternateId,
    });
  }

}
