import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { CurrentUserService } from '../services/current-user.service';

export namespace AuthorizedGuard {

  /** Guard prevents a current user from accessing a route in case they are authorized. */
  @Injectable({ providedIn: 'root' })
  class AuthorizedGuardService {
    private readonly routePaths = injectWebAppRoutes();

    public constructor(
      private readonly userService: CurrentUserService,
      private readonly router: Router,
    ) {}

    /** Determine if /auth route can be activated. */
    public canActivate(): Observable<boolean | UrlTree> {
      return this.userService.isAuthorized$.pipe(
        map(isAuthorized => (isAuthorized ? this.router.parseUrl(this.routePaths.home.url) : true)),
      );
    }
  }

  export const canActivate: CanActivateFn = () => inject(AuthorizedGuardService).canActivate();
}
