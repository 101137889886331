import { Overridable } from '@dartsales/common/core/utils/types/overridable';

/** Points list material labor item totals. */
export class PointsListMaterialLaborItemTotals {

  /** ID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Direct cost. */
  public readonly directCost: Overridable<number>;

  public constructor(data: PointsListMaterialLaborItemTotalsInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.directCost = data.directCost;
  }
}

type PointsListMaterialLaborItemTotalsInitArgs = PointsListMaterialLaborItemTotals;

/** Points list material labor totals. */
export class PointsListMaterialLaborTotals<T extends PointsListMaterialLaborItemTotals> {

  /** Direct cost. */
  public readonly directCost: Overridable<number>;

  /** System items. */
  public readonly items: readonly T[];

  public constructor(data: PointsListMaterialLaborTotalsInitArgs<T>) {
    this.directCost = data.directCost;
    this.items = data.items;
  }
}

type PointsListMaterialLaborTotalsInitArgs<T extends PointsListMaterialLaborItemTotals> = PointsListMaterialLaborTotals<T>;
