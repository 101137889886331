import { CustomModulePricing } from './custom-pricing';

/** Custom module data. */
export class CustomModuleData {

  /** Custom items list data. */
  public readonly items: readonly CustomModulePricing[];

  public constructor(data: CustomModuleDataInitArgs) {
    this.items = data.items;
  }
}

type CustomModuleDataInitArgs = CustomModuleData;
