import { ExportFormat } from '../../enums/export-format';

/** Export params. */
export class ExportParams {

  /** Export format. */
  public readonly exportFormat: ExportFormat;

  public constructor(data: ExportParamsInitArgs) {
    this.exportFormat = data.exportFormat;
  }
}

type ExportParamsInitArgs = ExportParams;
