/**
 * Convert bytes to readable size.
 * @param bytes Bytes.
 */
export function convertBytesToReadableSize(bytes: number): string {
  if (bytes === 0) {
    return '-';
  }

  const bytesPerKb = 1024;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const powerNumber = Math.floor(Math.log(bytes) / Math.log(bytesPerKb));
  const powerToSizeMap = parseFloat((bytes / bytesPerKb ** powerNumber).toFixed(2));
  const units = sizes[powerNumber];

  if (units == null) {
    return '-';
  }

  return `${powerToSizeMap} ${units}`;
}
