import { InternalCustomerValues } from '../internal-customer-values';

/** Labor role. */
export class LaborRole {

  /** Id. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Nickname. */
  public readonly nickname: string;

  /** Description. */
  public readonly description: string;

  /** Rate. */
  public readonly rate: InternalCustomerValues<number>;

  /** Rate overtime one. */
  public readonly rateOvertimeOne: InternalCustomerValues<number>;

  /** Rate overtime two. */
  public readonly rateOvertimeTwo: InternalCustomerValues<number>;

  /** Created by id. */
  public readonly createdById: number;

  /** Created by. */
  public readonly createdBy: string;

  /** Remove at. */
  public readonly removedAt: Date | null;

  /** Update at. */
  public readonly updatedAt: Date | null;

  /** Created at. */
  public readonly createdAt: Date | null;

  /** Is default. */
  public readonly isDefault: boolean;

  /** Order. */
  public readonly order: number;

  /** Whether role has assigned tasks. */
  public readonly hasAssignedTasks: boolean;

  public constructor(data: LaborRoleInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.nickname = data.nickname;
    this.description = data.description;
    this.rate = data.rate;
    this.rateOvertimeOne = data.rateOvertimeOne;
    this.rateOvertimeTwo = data.rateOvertimeTwo;
    this.createdById = data.createdById;
    this.createdBy = data.createdBy;
    this.removedAt = data.removedAt;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
    this.isDefault = data.isDefault;
    this.order = data.order;
    this.hasAssignedTasks = data.hasAssignedTasks;
  }
}

type LaborRoleInitArgs = LaborRole;

export type LaborRoleEdit = Pick<LaborRole,
  | 'name'
  | 'nickname'
  | 'description'
  | 'rate'
  | 'rateOvertimeOne'
  | 'rateOvertimeTwo'
> & Partial<Pick<LaborRole, 'order'>>;

/** Labor task role. */
export type LaborTaskRole = {

  /** Id. */
  readonly id: number;

  /** Name. */
  readonly name: string;

  /** Nickname. */
  readonly nickname: string;

  /** Description. */
  readonly description: string;

  /** Internal rate. */
  readonly internalRate: number;

  /** Internal rate overtime one. */
  readonly internalRateOvertimeOne: number;

  /** Internal rate overtime two. */
  readonly internalRateOvertimeTwo: number;
};
