import { Injectable } from '@angular/core';

import { PaletteComponent } from '@dartsales/common/core/models/components/palette/palette-component';
import { PaletteOrganization } from '@dartsales/common/core/models/components/palette/palette-organization';

import { PaletteComponentDto } from '../../dto/components/palette/palette-component.dto';
import { IMapperFromDto } from '../../mappers';

/** Palette component mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteComponentMapper implements IMapperFromDto<PaletteComponentDto, PaletteComponent> {

  /** @inheritdoc */
  public fromDto(dto: PaletteComponentDto): PaletteComponent {
    return new PaletteComponent({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      manufacturer: dto.manufacturer,
      modelNumber: dto.modelNumber,
      organization: new PaletteOrganization({
        id: dto.organizationId,
        name: dto.organizationName,
      }),
      tags: dto.tags,
      parts: dto.parts?.map(part => this.fromDto(part)) ?? [],
      type: dto.type,
      unitCost: dto.unitCost,
      supplier: dto.supplier,
    });
  }
}
