import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, ViewChildren, ViewEncapsulation, inject } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';

import { TableColumnInfo } from '@dartsales/common/core/models/list-utilities/table-column-info';
import { createTrackByFunction, trackByIndex } from '@dartsales/common/core/utils/trackby';
import { EditableTableColumnConfig } from '@dartsales/common/core/models/list-utilities/editable-table-column-config';

import { EditableTableColDirective } from './directives/editable-table-column.directive';
import { EditableTableBodyRowComponent, EditableTableRowDragData } from './directives/editable-table-body-row.directive';
import { EditableTableHeaderRowComponent } from './directives/editable-table-header-row.directive';
import { EditableTableFooterRowComponent } from './directives/editable-table-footer-row.directive';
import { EditableTableStickyRowDirective } from './directives/editable-table-sticky-row.directive';
import { EditableTableStickyColumnCellDirective } from './directives/editable-table-sticky-column-cell.directive';

/**
 * Editable table component.
 * This is a wrapper component for complex table with editable cells.
 * @example
 * ```html
 * <dartsalesc-editable-table [columns]="tableColumns">
 *   <!-- Header row layout. -->
 *   <ng-container dartsalescEditableTableHeaderRow>
 *     <!-- Table columns definitions (header row cells). -->
 *   <ng-container>
 *
 *   <!-- Body rows layout. -->
 *   <ng-container
 *     *ngFor="let row of tableRows"
 *     dartsalescEditableTableBodyRow
 *   >
 *     <!-- Table columns definitions (body row cells). -->
 *   <ng-container>
 *
 *   <!-- Footer row layout. -->
 *   <ng-container dartsalescEditableTableFooterRow>
 *     <!-- Table columns definitions (footer row cells). -->
 *   <ng-container>
 * </dartsalesc-editable-table>
 * ```
 */
@Component({
  selector: 'dartsalesc-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EditableTableComponent {
  /** Loading indicator. */
  @Input()
  public loading: boolean | null = false;

  /** Loading indicator. */
  @Input()
  public virtualScrollItemSizePx = 40;

  /** Virtual scroll min items amount to store in buffer. */
  @Input()
  public virtualScrollMinBufferItems = 30;

  /** Columns config. */
  @Input()
  public set columns(value: readonly EditableTableColumnConfig[] | null) {
    this.updateColumns(value);
  }

  /** Columns config. */
  public get columns(): EditableTableColumnConfig[] {
    return [...this._columns];
  }

  /** Allow rows drag and drop. */
  @Input()
  public enableDraggableRows = false;

  /** Predicate function for drag'n'drop feature. */
  @Input()
  public dragSortPredicate: CdkDropListSortPredicateFunction = this.defaultSortPredicate;

  /** Sticky rows. */
  @ViewChildren(EditableTableStickyRowDirective)
  public readonly stickyRows?: QueryList<EditableTableStickyRowDirective>;

  /** Table header rows. */
  @ContentChildren(EditableTableHeaderRowComponent)
  protected readonly headerRows?: QueryList<EditableTableHeaderRowComponent>;

  /** Table body rows. */
  @ContentChildren(EditableTableBodyRowComponent)
  protected readonly bodyRows?: QueryList<EditableTableBodyRowComponent>;

  /** Table footer rows. */
  @ContentChildren(EditableTableFooterRowComponent)
  protected readonly footerRows?: QueryList<EditableTableFooterRowComponent>;

  @ViewChildren(EditableTableStickyColumnCellDirective)
  private readonly stickyCells?: QueryList<EditableTableStickyColumnCellDirective>;

  /** Columns. */
  protected _columns: readonly EditableTableColumnConfig[] = [];

  private readonly parentVirtualScrollableElement = inject(CdkVirtualScrollableElement, { self: true, optional: true });

  /** Whether virtual scroll should be used. */
  protected get useVirtualScroll(): boolean {
    return this.parentVirtualScrollableElement != null && this.bodyRows != null && this.bodyRows.length > 1;
  }

  private readonly cdr = inject(ChangeDetectorRef);

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** Row cells template context. */
  protected readonly rowCellsTemplateContext?: RowCellsTemplateContext;

  /** Track by name. */
  protected readonly trackByName = createTrackByFunction<TableColumnInfo>('name');

  /** Rows for virtual scroll. */
  protected get rowsForVirtualScroll(): EditableTableBodyRowComponent[] {
    return this.bodyRows?.filter(row => row.isExpanded) ?? [];
  }

  /**
   * Refresh editable table component view.
   * This method can be used to trigger change detection from table child components.
   */
  public refreshTableView(): void {
    this.cdr.markForCheck();
  }

  /**
   * Get cell template by the name.
   * @param columns Columns list.
   * @param name Column name.
   */
  protected getColumnByName(name: string, columns?: QueryList<EditableTableColDirective>): EditableTableColDirective | undefined {
    return columns?.find(column => column.name === name);
  }

  /**
   * Get column CSS class.
   * @param column Column config.
   */
  protected getColumnCssClasses(column: EditableTableColumnConfig): NgClass['ngClass'] {
    const alignment = column.align ?? 'left';
    const columnName = `column-${column.name}`;
    return [`${alignment}-align`, columnName];
  }

  /**
   * Get column alignment CSS class.
   * @param column Column config.
   */
  protected getColumnSizeStyles(column: EditableTableColumnConfig): NgStyle['ngStyle'] {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      'width.px': column.width,
      'min-width.px': column.width,
      'max-width.px': column.width,
    };
  }

  private defaultSortPredicate(
    index: number,
    _drag: CdkDrag<EditableTableRowDragData | null>,
    drop: CdkDropList<readonly EditableTableBodyRowComponent[]>,
  ): boolean {
    const component = drop.data.at(index);
    return component?.isDraggable ?? false;
  }

  /**
   * Adjust sticky columns size.
   * Necessary for correct resizing and joining cells of existing cells to sticky ones when adding and removing new columns.
   */
  private adjustStickyColumnsSize(): void {
    this.stickyCells?.forEach(cell => cell.triggerResize());
  }

  /**
   * Adjust sticky footer rows.
   * Necessary for the correct positioning of rows sticks.
   */
  private adjustStickyFooterRows(): void {
    this.stickyRows?.forEach(stickyRow => stickyRow.setTopBottomOffset());
  }

  private updateColumns(value: readonly EditableTableColumnConfig[] | null): void {
    // We have to use custom change detection to properly render dynamic columns.
    // Columns in the table config are updated alongside the template.
    // We need to detach 'cdr' to delay the change detection so config and template
    // are updated at the same time, so we can avoid noticeable flickering.
    this.cdr.detach();
    this._columns = value ?? [];

    setTimeout(() => {
      this.cdr.reattach();
      this.cdr.markForCheck();
      this.adjustStickyColumnsSize();
      this.adjustStickyFooterRows();
    }, 0);
  }
}

type RowCellsTemplateContext = {

  /** Implicit context. */
  readonly $implicit: QueryList<EditableTableColDirective>;
};

/** Cdk drop list sort predicate function. */
export type CdkDropListSortPredicateFunction = (
  index: number,
  drag: CdkDrag<EditableTableRowDragData | null>,
  drop: CdkDropList<readonly EditableTableBodyRowComponent[]>
) => boolean;
