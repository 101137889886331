import { Injectable } from '@angular/core';

import { PointsListSystemIoPortsTotal } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-io-ports-total';

import { PointsListSystemIoPortsTotalDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-io-ports-total.dto';
import { IMapper } from '../../../mappers';

/** Points list system IO ports total mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemIoPortsTotalMapper implements IMapper<PointsListSystemIoPortsTotalDto, PointsListSystemIoPortsTotal> {
  /** @inheritdoc */
  public fromDto(dto: PointsListSystemIoPortsTotalDto): PointsListSystemIoPortsTotal {
    return new PointsListSystemIoPortsTotal({
      categoryType: dto.categoryType,
      portType: dto.type,
      numerator: dto.numerator,
      denominator: dto.denominator,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemIoPortsTotal): PointsListSystemIoPortsTotalDto {
    return {
      categoryType: data.categoryType,
      type: data.portType,
      numerator: data.numerator,
      denominator: data.denominator,
    };
  }
}
