import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { DetailedFinalEstimate, FinalEstimate, FinalEstimateEditData, FinalEstimateSummaries } from '../../models/estimate/final-estimate/final-estimate';
import { FinalEstimateMapper } from '../mappers/estimate/final-estimate/final-estimate.mapper';
import { DetailedFinalEstimateDto, FinalEstimateDto, FinalEstimatePreviewDto } from '../mappers/dto/estimate/final-estimate/final-estimate.dto';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { FetchListOptionsParamsMapper } from '../mappers/http-params.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { Estimate, EstimateId } from '../../models/estimate/estimate';
import { buildHttpParams } from '../../utils/build-http-params';

import { AppUrlsConfig } from './app-urls.config';

/** Final Estimate API service. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateApiService {
  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly finalEstimateMapper = inject(FinalEstimateMapper);

  private readonly listMapper = inject(PagedListMapper);

  private readonly paramsMapper = inject(FetchListOptionsParamsMapper);

  /**
   * Create a new final estimate.
   * @param id Base estimate ID.
   * @param data Data required to create a new final estimate.
   */
  public create(id: EstimateId, data: FinalEstimateEditData): Observable<FinalEstimate['id']> {
    return this.http.post<FinalEstimateDto['id']>(
      this.apiUrls.finalEstimatesApi.create(id),
      this.finalEstimateMapper.toDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.finalEstimateMapper,
      ),
    );
  }

  /**
   * Update final estimate info.
   * @param id Final estimate ID.
   * @param data Data required to update final estimate.
   */
  public update(id: FinalEstimate['id'], data: FinalEstimateEditData): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.finalEstimatesApi.update(id),
      this.finalEstimateMapper.toDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.finalEstimateMapper,
      ),
    );
  }

  /**
   * Set awarded status for final estimate.
   * @param baseEstimateId Base estimate ID.
   * @param finalEstimateId Final estimate ID.
   */
  public award(baseEstimateId: EstimateId, finalEstimateId: FinalEstimate['id']): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.finalEstimatesApi.award(baseEstimateId, finalEstimateId), {},
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get a final estimate by ID.
   * @param id ID.
   */
  public getById(id: FinalEstimate['id']): Observable<DetailedFinalEstimate> {
    return this.http.get<DetailedFinalEstimateDto>(
      this.apiUrls.finalEstimatesApi.get(id),
    ).pipe(
      map(dto => this.finalEstimateMapper.fromDetailedDto(dto)),
    );
  }

  /**
   * Obtain preview information about result of merge between base and alternate estimates.
   * @param id Base estimate ID.
   * @param alternateIds A list of included alternate estimate IDs.
   */
  public getPreview(id: EstimateId, alternateIds: readonly EstimateId[]): Observable<FinalEstimateSummaries> {
    return this.http.get<FinalEstimatePreviewDto>(
      this.apiUrls.finalEstimatesApi.getPreview(id),
      { params: { alternateIds } },
    ).pipe(
      map(dto => this.finalEstimateMapper.mapSummariesFromDto(dto)),
    );
  }

  /**
   * Obtain a list of final estimates for provided base estimate.
   * @param id Base estimate ID.
   * @param options Fetch options.
   * We do not use project ID as param (we don't pass filter mapper) that we use simple number instead filter object for FetchListOptions.
   */
  public getList(id: Estimate['id'], options?: FetchListOptions<Estimate['id']>): Observable<PagedList<FinalEstimate>> {
    const params = options ? this.paramsMapper.toDto(options) : undefined;
    return this.http.get<PagedListDto<FinalEstimateDto>>(this.apiUrls.finalEstimatesApi.getList(id), { params }).pipe(
      map(page => this.listMapper.fromDto(page, this.finalEstimateMapper, options?.pagination)),
    );
  }

  /**
   * Delete final estimate by ID.
   * @param id ID.
   */
  public deleteById(id: FinalEstimate['id']): Observable<void> {
    return this.http.delete<void>(
      this.apiUrls.finalEstimatesApi.delete(id),
    );
  }

  /**
   * Get awarded final estimate by ID.
   * @param id ID.
   */
  public getAwarded(id: FinalEstimate['id']): Observable<DetailedFinalEstimate> {
    return this.http.get<DetailedFinalEstimateDto>(
      this.apiUrls.finalEstimatesApi.getAwarded(id),
    ).pipe(
      map(dto => this.finalEstimateMapper.fromDetailedDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update awarded final estimate.
   * @param baseId Base estimate ID.
   * @param finalId Final estimate ID.
   * @param isAwarded Awarded status.
   */
  public updateAwarded(baseId: Estimate['id'], finalId: FinalEstimate['id'], isAwarded: boolean): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.finalEstimatesApi.updateAwarded(baseId, finalId),
      null,
      { params: buildHttpParams({ isAwarded }) },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
