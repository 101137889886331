import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { debounceTime, map } from 'rxjs';

import { FlatNode } from '../models/flat-node';
import { NestedTreeSelectionService } from '../models/nested-tree-selection-service';

import { NestedTreeService } from './nested-tree.service';

// TODO (DART-69): Redesign the solution.
// At the moment we have to reduce all nodes to the same form
// which becomes a big problem when inferring types from nodes in a deep tree with a large number of nodes.
/** Abstract nested tree selection service. */
export abstract class AbstractNestedTreeSelectionService<T> extends NestedTreeService<T> implements NestedTreeSelectionService<T> {

  /** @inheritdoc */
  protected readonly selection = new SelectionModel<FlatNode<T>>(true);

  /** @inheritdoc */
  public readonly selectedNodes$ = this.selection.changed.pipe(
    debounceTime(0),
    map(() => this.selection.selected),
  );

  /** @inheritdoc */
  public abstract onNodeWithoutChildrenChange(node: FlatNode<T>): void;

  /** @inheritdoc */
  public abstract onNodeWithChildrenChange(node: FlatNode<T>, event: MatCheckboxChange): void;

  /** @inheritdoc */
  public abstract checkIsNodeWithChildrenChecked(node: FlatNode<T>): boolean;

  /** @inheritdoc */
  public abstract checkIsNodeWithChildrenIndeterminate(node: FlatNode<T>): boolean;

  /** @inheritdoc */
  public deselectAll(): void {
    this.selection.deselect(...this.treeControl.dataNodes);
  }

  /** @inheritdoc */
  public checkIsSelected(row: FlatNode<T>): boolean {
    return this.selection.isSelected(row);
  }

  /** @inheritdoc */
  protected checkIfAllDescendantsAreSelected(node: FlatNode<T>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.selection.isSelected(child));
  }

  /** @inheritdoc */
  protected checkIfDescendantsArePartiallySelected(node: FlatNode<T>): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.some(child => this.selection.isSelected(child)) && !this.checkIfAllDescendantsAreSelected(node);
  }
}
