<mat-tree
  [dataSource]="treeService.dataSource"
  [treeControl]="treeService.treeControl"
>
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    class="node"
    [class.node_last]="node.hasBorder"
    [class.node_no-expansion]="isAllExpanded"
    [style.--row-level]="node.level"
  >
    <mat-checkbox
      [checked]="treeService.checkIsSelected(node)"
      (change)="treeService.onNodeWithoutChildrenChange(node)"
    >
      {{ node.title }}
    </mat-checkbox>
  </mat-tree-node>

  <mat-tree-node
    *matTreeNodeDef="let node; when: treeService.checkHasChildren"
    class="node node_with-children"
    [class.node_expanded]="treeService.treeControl.isExpanded(node)"
    [style.--row-level]="node.level"
  >
    <button
      *ngIf="!isAllExpanded"
      mat-icon-button
      matTreeNodeToggle
      type="button"
      class="expand-button"
    >
      <dartsalesc-expand-collapse-icon
        [isExpanded]="treeService.treeControl.isExpanded(node)"
      />
    </button>
    <mat-checkbox
      [checked]="treeService.checkIsNodeWithChildrenChecked(node)"
      [indeterminate]="treeService.checkIsNodeWithChildrenIndeterminate(node)"
      (change)="treeService.onNodeWithChildrenChange(node, $event)"
    >
      {{ node.title }}
    </mat-checkbox>
  </mat-tree-node>
</mat-tree>
