<button
  *ngrxLet="{
    isArrowVisible: isArrowVisible$ | async,
    sortDirection: sortDirection$ | async
  } as data"
  mat-icon-button
  class="sort-button"
  type="button"
  [class.active]="data.isArrowVisible"
  [@arrowDirection]="getArrowAnimationState(data.sortDirection)"
  (click)="toggleSortDirection(data.sortDirection)"
>
  <mat-icon
    class="sort-button__icon"
    svgIcon="arrow-upward"
  />
</button>
