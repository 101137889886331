import { FinalEstimatePricing, FinalEstimatePricingInitArgs } from '../../../pricing/final-estimate-pricing';

/** Term representation for final estimate. */
export class FinalEstimateTerm extends FinalEstimatePricing {

  /** Term number. */
  public readonly number: number;

  /** Whether gross margin is override. */
  public readonly hasGrossMarginOverride: boolean;

  /** Whether total direct cost is override. */
  public readonly hasTotalDirectCostOverride: boolean;

  public constructor(data: FinalEstimateTermInitArgs) {
    super(data);
    this.number = data.number;
    this.hasGrossMarginOverride = data.hasGrossMarginOverride;
    this.hasTotalDirectCostOverride = data.hasTotalDirectCostOverride;
  }
}

type FinalEstimateTermInitArgs = FinalEstimatePricingInitArgs &
  Pick<FinalEstimateTerm, 'number' | 'hasGrossMarginOverride' | 'hasTotalDirectCostOverride'>;

/**
 * Abstract class for final estimate summaries term containers.
 *
 * The base class in the Final Estimates for entities that contain a breakdown of prices by terms and
 * the total values for these terms calculated by the backend, e.g. Category, Subcategory, Service.
 **/
export abstract class FinalEstimateTermContainer {

  /** Terms list for module item. */
  public readonly terms: readonly FinalEstimateTerm[];

  /** Total term values for module item. */
  public readonly totalTermValues: FinalEstimatePricing;

  /** Average term values for module item. */
  public readonly averageTermValues: FinalEstimatePricing;

  public constructor(data: FinalEstimateTermContainerInitArgs) {
    this.terms = data.terms;
    this.totalTermValues = data.totalTermValues;
    this.averageTermValues = data.averageTermValues;
  }
}

type FinalEstimateTermContainerInitArgs = FinalEstimateTermContainer;
