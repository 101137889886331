const pluralRules = new Intl.PluralRules('en-US');

type PluralizeArgs = {

  /** Count. */
  readonly count: number;

  /** Singular form. */
  readonly singular: string;

  /** Plural form. */
  readonly plural: string;

  /** Count formatting function . */
  readonly countFormatter?: (count: number) => string;

  /** Whether to show count. */
  readonly isCountVisible?: boolean;
};

const defaultFormatter = (count: number): string => String(count);

/**
 * Pluralize text according to count.
 * @param args Arguments.
 * @returns Number and text, e.g. '1 item' or '5 items'.
 */
export function pluralize({ count, singular, plural, countFormatter = defaultFormatter, isCountVisible = true }: PluralizeArgs): string {
  const formattedCount = isCountVisible ? countFormatter(count) : '';
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one':
      return `${formattedCount} ${singular}`;
    case 'other':
      return `${formattedCount} ${plural}`;
    default:
      return `${formattedCount}`;
  }
}
