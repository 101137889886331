import { PartDiagramType } from '@dartsales/common/core/enums/part-diagram-type';
import { PartPerformerType } from '@dartsales/common/core/enums/part-performer-type';
import { PartType } from '@dartsales/common/core/enums/part-type';
import { EstimatePartCustomProperty } from '@dartsales/common/core/models/components/estimate-part/estimate-part-custom-property';
import { OverridableEstimatePartPort } from '@dartsales/common/core/models/components/estimate-part/estimate-part-port';
import { OverridablePartStackedPort } from '@dartsales/common/core/models/components/estimate-part/estimate-part-stacked-port';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { ComponentAttachment } from '@dartsales/common/core/models/components/component-attachment';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { OverridableLaborTaskMinutes } from '../overridable-labor-task-minutes';

import { PointsListPartOverridableDetails } from './points-list-part-overridable-details';

/** Points list part details. */
export class PointsListPartDetails implements PointsListPartOverridableDetails {

  /** Labor tasks minutes. */
  public readonly taskMinutes: readonly OverridableLaborTaskMinutes[];

  /** Quantity. */
  public readonly quantity: number;

  /** @inheritdoc */
  public readonly name: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly description: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly manufacturer: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly pointDescription: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly modelNumber: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly location: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly supplier: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly salesNotes: ConfirmedOverridable<string>;

  /** @inheritdoc */
  public readonly unitCost: ConfirmedOverridable<number>;

  /** @inheritdoc */
  public readonly listPrice: ConfirmedOverridable<number>;

  /** @inheritdoc */
  public readonly weight: ConfirmedOverridable<number>;

  /** @inheritdoc */
  public readonly defaultPartType: ConfirmedOverridable<PartType>;

  /** @inheritdoc */
  public readonly providedBy: ConfirmedOverridable<PartPerformerType>;

  /** @inheritdoc */
  public readonly wiredBy: ConfirmedOverridable<PartPerformerType>;

  /** @inheritdoc */
  public readonly installedBy: ConfirmedOverridable<PartPerformerType>;

  /** @inheritdoc */
  public readonly applicableDiagrams: ConfirmedOverridable<readonly PartDiagramType[]>;

  /** @inheritdoc */
  public readonly customProperties: ConfirmedOverridable<readonly EstimatePartCustomProperty[]>;

  /** Ports. */
  public readonly ports: readonly OverridableEstimatePartPort[];

  /** Stacked ports. */
  public readonly stackedPorts: readonly OverridablePartStackedPort[];

  /** @inheritdoc */
  public readonly tags: ConfirmedOverridable<readonly string[]>;

  /** Component attachments. */
  public readonly attachments: readonly ComponentAttachment[];

  /** Whether bulk update is available. */
  public readonly isBulkUpdateAvailable: boolean;

  public constructor(data: PartDetailsInitArgs) {
    this.name = data.name;
    this.description = data.description;
    this.manufacturer = data.manufacturer;
    this.pointDescription = data.pointDescription;
    this.modelNumber = data.modelNumber;
    this.quantity = data.quantity;
    this.location = data.location;
    this.supplier = data.supplier;
    this.salesNotes = data.salesNotes;
    this.unitCost = data.unitCost;
    this.listPrice = data.listPrice;
    this.weight = data.weight;
    this.defaultPartType = data.defaultPartType;
    this.providedBy = data.providedBy;
    this.wiredBy = data.wiredBy;
    this.installedBy = data.installedBy;
    this.applicableDiagrams = data.applicableDiagrams;
    this.customProperties = data.customProperties;
    this.ports = data.ports;
    this.stackedPorts = data.stackedPorts;
    this.tags = data.tags;
    this.taskMinutes = data.taskMinutes;
    this.attachments = data.attachments;
    this.isBulkUpdateAvailable = this.checkIsBulkUpdateAvailable(data);
  }

  private checkIsBulkUpdateAvailable(data: PartDetailsInitArgs): boolean {
    const hasUnconfirmedTaskMinutes = ConfirmedOverridable.hasUnconfirmedOverride(data.taskMinutes.map(item => item.minutes));
    const hasUnconfirmedPorts = ConfirmedOverridable.hasUnconfirmedOverride(data.ports.map(item => item.quantity));
    const hasUnconfirmedStackedPorts = ConfirmedOverridable.hasUnconfirmedOverride(data.stackedPorts.map(item => item.activePortId));

    const hasUnconfirmedPartDetails = ConfirmedOverridable.hasUnconfirmedOverride([
      data.name,
      data.description,
      data.manufacturer,
      data.pointDescription,
      data.modelNumber,
      data.location,
      data.supplier,
      data.salesNotes,
      data.unitCost,
      data.listPrice,
      data.weight,
      data.defaultPartType,
      data.providedBy,
      data.wiredBy,
      data.installedBy,
      data.applicableDiagrams,
      data.customProperties,
      data.tags,
    ]);

    return hasUnconfirmedPartDetails || hasUnconfirmedTaskMinutes || hasUnconfirmedPorts || hasUnconfirmedStackedPorts;
  }
}

type PartDetailsInitArgs = StrictOmit<PointsListPartDetails, 'isBulkUpdateAvailable'>;
