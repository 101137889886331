import { Overridable, OverridableInitArgs } from '@dartsales/common/core/utils/types/overridable';

import { AmountPercent } from '../amount-percent';

/** Margin. */
export class Margin {

  /** Gross margin. */
  public readonly grossMargin: AmountPercent;

  /** Markup. */
  public readonly markup: AmountPercent;

  public constructor(initArgs: MarginInitArgs) {
    this.grossMargin = initArgs.grossMargin;
    this.markup = initArgs.markup;
  }

  /**
   * Create Margin object by specified values.
   * @param values Margin values.
   **/
  public static fromValues(values?: MarginValues): Margin {
    return new Margin({
      grossMargin: new AmountPercent({
        amount: values?.amount,
        percent: values?.grossMargin,
      }),
      markup: new AmountPercent({
        amount: values?.amount,
        percent: values?.markup,
      }),
    });
  }

  /** Create empty. */
  public static createEmpty(): Margin {
    return new Margin({
      grossMargin: new AmountPercent({
        amount: 0,
        percent: 0,
      }),
      markup: new AmountPercent({
        amount: 0,
        percent: 0,
      }),
    });
  }
}

type MarginInitArgs = Margin;

/** Flat structure for Margin related values. */
export type MarginValues = {

  /** Gross margin percent. */
  readonly grossMargin?: number;

  /** Markup percent. */
  readonly markup?: number;

  /** Gross margin / markup amount. */
  readonly amount?: number;
};

/** Overridable version of Margin. */
export class OverridableMargin {

  /** Gross margin. */
  public readonly grossMargin: Overridable<AmountPercent>;

  /** Markup. */
  public readonly markup: Overridable<AmountPercent>;

  public constructor(initArgs: OverridableMarginInitArgs) {
    this.grossMargin = initArgs.grossMargin;
    this.markup = initArgs.markup;
  }

  /**
   * Create overridable Margin object by specified values.
   * @param values Overridable margin values.
   **/
  public static fromValues(values?: OverridableMarginValues): OverridableMargin {
    return new OverridableMargin({
      grossMargin: new Overridable({
        initial: new AmountPercent({
          amount: values?.initial.amount,
          percent: values?.initial.grossMargin,
        }),
        override: values?.override ?
          new AmountPercent({
            amount: values.override.amount,
            percent: values.override.grossMargin,
          }) :
          null,
      }),
      markup: new Overridable({
        initial: new AmountPercent({
          amount: values?.initial.amount,
          percent: values?.initial.markup,
        }),
        override: values?.override ?
          new AmountPercent({
            amount: values.override.amount,
            percent: values.override.markup,
          }) :
          null,
      }),
    });
  }
}

type OverridableMarginInitArgs = OverridableMargin;

/** Flat structure for overridable Margin related values. */
export type OverridableMarginValues = OverridableInitArgs<MarginValues>;
