import { Injectable, inject } from '@angular/core';

import { ExpensesRole } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-role';
import { EditExpensesRole } from '@dartsales/common/core/models/estimate/modules/expenses/edit-expenses-role';

import { EditExpensesRoleDto, ExpensesRoleDto } from '../../../dto/estimate/modules/expenses/expenses-role.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

/** Expenses role entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExpensesRoleMapper implements
  IMapper<ExpensesRoleDto, ExpensesRole> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: ExpensesRoleDto): ExpensesRole {
    return new ExpensesRole({
      id: dto.id,
      laborRoleId: dto.laborRoleId,
      rate: dto.rate,
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
    });
  }

  /** @inheritdoc */
  public toDto(data: ExpensesRole): ExpensesRoleDto {
    return {
      id: data.id,
      laborRoleId: data.laborRoleId,
      rate: data.rate,
      name: this.confirmedOverridableMapper.toDto(data.name),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditExpensesRole): EditExpensesRoleDto {
    return {
      id: data.id,
      name: this.confirmedOverridableMapper.toDto(data.name),
      rate: data.rate,
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
    };
  }
}
