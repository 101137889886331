import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ProjectStatus } from '@dartsales/common/core/enums/project-status';
import { TableColumnInfo } from '@dartsales/common/core/models/list-utilities/table-column-info';
import { ExploreProjectsItem } from '@dartsales/common/core/models/project/explore-projects-item';
import { ExploreBidCategory } from '@dartsales/common/core/models/resources/bid-category';
import { ListManager } from '@dartsales/common/core/utils/list-manager';
import { trackById } from '@dartsales/common/core/utils/trackby';
import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

/** Projects shared table. */
@Component({
  selector: 'dartsalesw-projects-shared-table',
  templateUrl: './projects-shared-table.component.html',
  styleUrls: ['./projects-shared-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsSharedTableComponent {
  /** List manager. */
  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public listManager: ListManager<ExploreProjectsItem, any> | null = null;

  /** Columns. */
  @Input()
  public columns: TableColumnInfo[] | null = [];

  private readonly routePaths = injectWebAppRoutes();

  private readonly router = inject(Router);

  /** Project status. */
  protected readonly projectStatus = ProjectStatus;

  /** Track by id. */
  protected readonly trackById = trackById;

  /**
   * Table row click handler.
   * @param item Item.
   */
  protected onTableRowClick(item: ExploreProjectsItem): void {
    const redirectUrl = this.routePaths.project.url({ projectId: item.id });
    this.router.navigateByUrl(redirectUrl);
  }

  /**
   * Map bid categories to string.
   * @param bidCategories Bid categories.
   */
  protected mapBidCategoriesToString(bidCategories: readonly ExploreBidCategory[]): string[] {
    return bidCategories.map(bidCategory => bidCategory.name).sort((a, b) => a.localeCompare(b));
  }

  /**
   * Salesperson to readable.
   * @param salesperson Salesperson.
   */
  protected salespersonToReadable(salesperson: ExploreProjectsItem['salesperson']): string {
    if (salesperson === '' || salesperson == null) {
      return '-';
    }
    return salesperson;
  }
}
