import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

/** Filter operator mapper. */
export class FilterOperatorMapper<T> {

  /** Function to get operator value from string params. */
  public readonly parseValueFromString: (params: string[]) => T;

  /** Function to get params array from value. */
  public readonly getParamsArray: (value: T) => string[];

  /** Function to get readable params array from value. */
  public readonly getReadableParamsArray: (value: T) => string[];

  public constructor(data: FilterOperatorMapperInitArgs<T>) {
    this.parseValueFromString = data.parseValueFromString;
    this.getParamsArray = data.getParamsArray;
    this.getReadableParamsArray = data.getReadableParamsArray ?? data.getParamsArray;
  }
}

type FilterOperatorMapperInitArgs<T> = StrictOmit<FilterOperatorMapper<T>, 'getReadableParamsArray'> & {

  /** If not passed then 'getParamsArray' will be used. */
  readonly getReadableParamsArray?: FilterOperatorMapper<T>['getReadableParamsArray'];
};
