<mat-form-field class="no-label">
  <mat-date-range-input
    [formGroup]="dateRangeForm"
    [rangePicker]="picker"
  >
    <input
      matStartDate
      placeholder="From date"
      [errorStateMatcher]="matcher"
      [formControl]="dateRangeForm.controls.start"
    />
    <input
      matEndDate
      placeholder="To date"
      [errorStateMatcher]="matcher"
      [formControl]="dateRangeForm.controls.end"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
  />
  <mat-date-range-picker #picker />
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
