import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, inject } from '@angular/core';

import { ResizableContentContainerServices } from './services/resizable-content-container-services.service';

/** Container with two content sections and a resizable border between them. */
@Component({
  selector: 'dartsalesw-resizable-content-container',
  templateUrl: './resizable-content-container.component.html',
  styleUrls: ['./resizable-content-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResizableContentContainerServices],
})
export class ResizableContentContainerComponent implements OnInit, OnDestroy {

  /** Service for resizable content container. */
  protected readonly resizeContentContainerServices = inject(ResizableContentContainerServices);

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly resizeObserver = this.createResizeObserver();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private createResizeObserver(): ResizeObserver {
    return new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      this.resizeContentContainerServices.changeMaxWidth(width);
    });
  }
}
