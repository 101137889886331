import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NonNullableFormBuilder } from '@angular/forms';

import { PointsListConfirmDialogData, PointsListConfirmDialogInnerResult } from './points-list-confirm-dialog-data';

/** Points list confirm dialog component. */
@Component({
  selector: 'dartsalesw-points-list-confirm-dialog',
  templateUrl: './points-list-confirm-dialog.component.html',
  styleUrls: ['./points-list-confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsListConfirmDialogComponent {

  /** Dialog data. */
  private readonly data = inject<PointsListConfirmDialogData>(MAT_DIALOG_DATA);

  private readonly fb = inject(NonNullableFormBuilder);

  /** Mat dialog ref. */
  protected readonly dialogRef = inject<MatDialogRef<PointsListConfirmDialogInnerResult, PointsListConfirmDialogInnerResult>>(MatDialogRef);

  /** Title. */
  protected readonly title = this.data.title ?? 'Confirm your action';

  /** Text. */
  protected readonly text = this.data.text ?? 'Are you sure you want to proceed the action?';

  /** Skip dialog control. */
  protected readonly skipControl = this.fb.control(false);

  /** Handle confirm button click. */
  protected onConfirmClick(): void {
    this.dialogRef.close({
      value: true,
      shouldSkip: this.skipControl.value,
    });
  }

  /** Handle cancel button click. */
  protected onCancelClick(): void {
    this.dialogRef.close({
      value: false,
      shouldSkip: false,
    });
  }

}
