import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/** Collapsable section. */
@Component({
  selector: 'dartsalesw-collapsable-section',
  templateUrl: './collapsable-section.component.html',
  styleUrls: ['./collapsable-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableSectionComponent {

  /** Header title. */
  @Input()
  public headerTitle = '';

  /** Whether the section is in expanded state or not. */
  @Input()
  public isExpanded = true;

  /** Whether the open in new button is displayed. */
  @Input()
  public isOpenInNewButtonDisplayed = false;

  /** Expanded state change emitter. */
  @Output()
  public readonly expandChange = new EventEmitter<boolean>();

  /** 'Open in new' button click. */
  @Output()
  public readonly openInNewButtonClick = new EventEmitter<void>();

  /** Toggle expanded state. */
  protected toggle(): void {
    const newState = !this.isExpanded;
    this.isExpanded = newState;
    this.expandChange.emit(newState);
  }
}
