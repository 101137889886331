import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { memoizeDebounce } from '@dartsales/common/core/utils/memoizeDebounce';

const defaultSectionSize = 300;
const saveDelayMs = 50;

/** Service for resizable content container. */
@Injectable()
export class ResizableContentContainerServices {

  /** Min width of the left section in pixels. */
  public readonly leftSectionMinWidth = defaultSectionSize;

  /** Min width of the section on the right in pixels. */
  public readonly rightSectionMinWidth = defaultSectionSize;

  /** Max width of the left section in pixels. */
  public readonly leftSectionMaxWidth$: Observable<number>;

  /** Whether a section on the left is expanded or not. */
  public readonly isExpanded$ = new BehaviorSubject<boolean>(true);

  /** Change max width of the left section. */
  public readonly changeMaxWidth = memoizeDebounce(
    (newWidth: number) =>
      (this._leftSectionMaxWidth$.next(newWidth - this.rightSectionMinWidth)),
    saveDelayMs,
  );

  private readonly _leftSectionMaxWidth$ = new BehaviorSubject<number>(0);

  public constructor() {
    this.leftSectionMaxWidth$ = this._leftSectionMaxWidth$.asObservable();
  }
}
