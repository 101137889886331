import { NgModule, Type } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';

import { FormatDatePipe } from './format-date.pipe';
import { FileSizePipe } from './file-size.pipe';
import { InputMaskPipe } from './input-mask.pipe';
import { FormatCurrencyWithIndentPipe } from './format-currency.pipe';
import { JoinPipe } from './join.pipe';
import { DecimalPercentPipe } from './decimal-percent.pipe';
import { DecimalNumberPipe } from './decimal-number.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { StringPipe } from './string.pipe';
import { TruncateJoinPipe } from './truncate-and-join.pipe';

const EXPORTED_DECLARATIONS: Type<unknown>[] = [
  FormatDatePipe,
  FileSizePipe,
  InputMaskPipe,
  FormatCurrencyWithIndentPipe,
  JoinPipe,
  DecimalPercentPipe,
  DecimalNumberPipe,
  PluralizePipe,
  StringPipe,
  TruncateJoinPipe,
];

/** Shared pipes module. */
@NgModule({
  declarations: EXPORTED_DECLARATIONS,
  exports: EXPORTED_DECLARATIONS,
  providers: [DatePipe, CurrencyPipe, PercentPipe, DecimalPipe, JoinPipe],
})
export class SharedPipesModule { }
