import { inject, Injectable } from '@angular/core';
import {
  catchError, concat, first, forkJoin, ignoreElements, map, merge,
  Observable, OperatorFunction, pipe, switchMap, throwError,
} from 'rxjs';
import { Router } from '@angular/router';

import { webRoutePaths } from 'projects/web/src/app/web-route-paths';
import { ROW_EXPANSION_STORAGE_BASE_KEY } from 'projects/web/src/app/features/project-dashboard/services/abstract-rows-expansion-service.service';

import { AppError } from '../models/errors/app-error';
import { Login } from '../models/auth/login';
import { PasswordReset } from '../models/auth/password-reset';
import { UserSecret } from '../models/auth/user-secret';
import { ESTIMATE_STORAGE_KEYS } from '../utils/constants';

import { AuthApiService } from './api/auth-api.service';
import { CurrentUserService } from './current-user.service';
import { UserSecretStorageService } from './user-secret-storage.service';
import { StorageService } from './storage.service';
import { POINTS_LIST_CONFIRM_DIALOG_SKIP_STORAGE_KEY } from './local-storage/points-list-confirm-dialog.service';

const STORAGE_KEYS_TO_CLEAR_ON_LOGOUT = [
  POINTS_LIST_CONFIRM_DIALOG_SKIP_STORAGE_KEY,
  ROW_EXPANSION_STORAGE_BASE_KEY,
  ...Object.values(ESTIMATE_STORAGE_KEYS),
];

/** Stateful service for storing/managing information about the current user. */
@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  private readonly authService = inject(AuthApiService);

  private readonly userSecretStorage = inject(UserSecretStorageService);

  private readonly currentUserService = inject(CurrentUserService);

  private readonly router = inject(Router);

  private readonly storageService = inject(StorageService);

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<void> {
    return this.authService.login(loginData).pipe(
      this.saveSecretAndWaitForAuthorized(),
    );
  }

  /** Logout current user. */
  public logout(): Observable<void> {
    return forkJoin([
      this.userSecretStorage.removeSecret(),

      // If we'd need skip logic for other modules in the future, consider using common prefix for them.
      // You could create `createConfirmationDialogSkipKey(module)` to ensure it.
      // Also consider extracting `PointListConfirmDialogService` into generic `ConfirmDialogService` with skip logic.
      // More on this topic here: https://github.com/saritasa-nest/ats-dart-frontend-sales/pull/1145.
      this.storageService.removeByPredicate(key => STORAGE_KEYS_TO_CLEAR_ON_LOGOUT.some(keyToClear => key.startsWith(keyToClear))),
    ]).pipe(
      map(() => undefined),
    );
  }

  /** Attempts to refresh user secret, in case it is not possible logs out current user. */
  public refreshSecret(): Observable<void> {
    const refreshSecretIfPresent$ = this.userSecretStorage.currentSecret$.pipe(
      first(),
      switchMap(secret => {
        if (secret != null) {
          return this.authService.refreshSecret(secret);
        }
        return throwError(() => new AppError('Unauthorized'));
      }),
      switchMap(newSecret => this.userSecretStorage.saveSecret(newSecret)),
    );
    return refreshSecretIfPresent$.pipe(
      catchError((error: unknown) =>
        concat(
          this.logout().pipe(switchMap(() => {
            // We perform the same action as in UnauthorizedGuard.
            const { url } = this.router;
            return this.router.navigate([webRoutePaths.auth.children.login.url], {
              queryParams: url ? { next: url } : undefined,
            });
          })),
          throwError(() => error),
        )),
      map(() => undefined),
    );
  }

  /**
   * Requests to reset the password.
   * @param data Data for resetting the password.
   * @returns Message for the user.
   */
  public resetPassword(data: PasswordReset.Data): Observable<string> {
    return this.authService.resetPassword(data);
  }

  /**
   * Set new password and confirm resetting.
   * @param data Confirm password reset.
   * @returns Success message.
   */
  public confirmPasswordReset(data: PasswordReset.Confirmation): Observable<string> {
    return this.authService.confirmPasswordReset(data);
  }

  private saveSecretAndWaitForAuthorized(): OperatorFunction<UserSecret, void> {
    return pipe(
      switchMap(secret => {
        const saveUserSecretSideEffect$ = this.userSecretStorage.saveSecret(secret).pipe(ignoreElements());

        return merge(
          this.currentUserService.isAuthorized$,
          saveUserSecretSideEffect$,
        );
      }),
      first(isAuthorized => isAuthorized),
      map(() => undefined),
    );
  }
}
