import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

/** Password form field component. */
@Component({
  selector: 'dartsalesw-password-form-field',
  templateUrl: './password-form-field.component.html',
  styleUrls: ['./password-form-field.component.css'],

  // Needed to display errors after server validation
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PasswordFormFieldComponent {

  /** Autocomplete. */
  @Input()
  public autocomplete = '';

  /** Label. */
  @Input()
  public label = 'Password';

  /** Form control. */
  @Input()
  public control: FormControl<string> | null = null;

  /** Should show password. */
  protected shouldShowPassword = false;

  /** Handle toggle button click. */
  protected onToggleButtonClick(): void {
    this.shouldShowPassword = !this.shouldShowPassword;
  }
}
