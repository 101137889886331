import { Directive, TemplateRef, inject } from '@angular/core';

/** Directive to provide custom CDK drag preview while row is dragged. */
@Directive({
  selector: '[dartsalescEditableTableRowDragPreview]',
})
export class EditableTableRowDragPreviewDirective {
  /** Template. */
  public readonly template = inject<TemplateRef<unknown>>(TemplateRef);
}
