<dartsalesw-base-filters
  searchPlaceholder="Search by value"
  [isIndicatorVisible]="isActive"
>
  <mat-form-field class="filter-search no-padding">
    <mat-icon matPrefix>search</mat-icon>
    <dartsalesw-search-input
      placeholder="Search by value"
      [ngModel]="search"
      (ngModelChange)="searchChange.emit($event)"
    />
  </mat-form-field>
  <dartsalesw-sort-section
    [isActive]="isActive"
    [sortDirection]="sortDirection ?? sortDirections.ASC"
    (sortDirectionChange)="onSortDirectionChange($event)"
  />
</dartsalesw-base-filters>
