/** Points list total values for part. */
export class PointsListPartTotal {
  /** Direct cost. */
  public readonly directCost: number;

  /** Labor cost. */
  public readonly laborCost: number;

  /** Material cost. */
  public readonly materialCost: number;

  /** Labor hours. */
  public readonly laborHours: number;

  public constructor(data: PointsListPartTotalInitArgs) {
    this.directCost = data.directCost;
    this.laborCost = data.laborCost;
    this.materialCost = data.materialCost;
    this.laborHours = data.laborHours;
  }

}

type PointsListPartTotalInitArgs = PointsListPartTotal;
