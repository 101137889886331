import { inject, Injectable } from '@angular/core';

import { IMapper } from '@dartsales/common/core/services/mappers/mappers';
import { FinalEstimatePricing } from '@dartsales/common/core/models/pricing/final-estimate-pricing';

import { FinalEstimatePricingDto } from '../dto/pricing/final-estimate-pricing.dto';

import { BasePricingMapper } from './base-pricing.mapper';

/** Final estimate pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimatePricingMapper implements IMapper<FinalEstimatePricingDto, FinalEstimatePricing> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  /** @inheritdoc */
  public fromDto(dto: FinalEstimatePricingDto): FinalEstimatePricing {
    return new FinalEstimatePricing({
      ...this.basePricingMapper.fromDto(dto),
      laborHours: dto.laborHours,
    });
  }

  /** @inheritdoc */
  public toDto(model: FinalEstimatePricing): FinalEstimatePricingDto {
    return {
      ...this.basePricingMapper.toDto(model),
      laborHours: model.laborHours,
    };
  }
}
