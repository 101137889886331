import { inject, Pipe, PipeTransform } from '@angular/core';

import { JoinPipe } from './join.pipe';

type TruncateJoinPipeArguments = {

  /** Array of elements separator. */
  readonly separator?: string;

  /** Return emptyValue if array is empty. */
  readonly emptyValue?: string;

  /** End string separator. */
  readonly endStringSeparator?: string;

  /** Maximum count of array elements. */
  readonly maxCountElements?: number;
};

const DEFAULT_TRUNCATE_ARGS = {
  maxCountElements: 4,
  endStringSeparator: '\n...',
} as const satisfies TruncateJoinPipeArguments;

/** Truncate and join string. */
@Pipe({
  name: 'truncateJoin',
})
export class TruncateJoinPipe implements PipeTransform {

  private readonly joinPipe = inject(JoinPipe);

  /**
   * Join and truncate array elements.
   * @param value Array.
   * @param args Arguments.
   */
  public transform(
    value: readonly (string | number)[] | null | undefined,
    args: TruncateJoinPipeArguments,
  ): string {
    if (value == null) {
      return args?.emptyValue ?? '';
    }

    const maxCountElements = args.maxCountElements ?? DEFAULT_TRUNCATE_ARGS.maxCountElements;
    const endStringSeparator = args.endStringSeparator ?? DEFAULT_TRUNCATE_ARGS.endStringSeparator;

    const joinString = this.joinPipe.transform(value?.slice(0, maxCountElements), args);
    if (value.length > maxCountElements) {
      return `${joinString}${endStringSeparator}`;
    }

    return joinString;
  }

}
