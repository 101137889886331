import { Injectable, inject } from '@angular/core';

import { SubcontractorModule } from '@dartsales/common/core/models/estimate/modules/subcontractor/subcontractor-module';
import { SubcontractorModuleData } from '@dartsales/common/core/models/estimate/modules/subcontractor/subcontractor-module-data';

import { IMapper } from '../../../mappers';
import { SubcontractorModuleDto } from '../../../dto/estimate/modules/subcontractor/subcontractor-module-data.dto';

import { SubcontractorUnitPricingMapper } from './subcontractor-unit-pricing.mapper';
import { SubcontractorLumpSumPricingMapper } from './subcontractor-lump-sum-pricing.mapper';
import { SubcontractorModulePropertiesMapper } from './subcontractor-module-properties.mapper';

/** Subcontractor module entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubcontractorModuleMapper implements
  IMapper<SubcontractorModuleDto, SubcontractorModule> {

  private readonly propertiesMapper = inject(SubcontractorModulePropertiesMapper);

  private readonly lumpSumPricingMapper = inject(SubcontractorLumpSumPricingMapper);

  private readonly unitPricingMapper = inject(SubcontractorUnitPricingMapper);

  /** @inheritdoc */
  public fromDto(dto: SubcontractorModuleDto): SubcontractorModule {
    return new SubcontractorModule({
      properties: this.propertiesMapper.fromDto(dto.properties),
      data: new SubcontractorModuleData({
        lumpSumPricing: dto.data.lumpSumPricings.map(
          item => this.lumpSumPricingMapper.fromDto(item),
        ),
        unitPricing: dto.data.unitPricings?.map(
          item => this.unitPricingMapper.fromDto(item),
        ),
      }),
    });
  }

  /** @inheritdoc */
  public toDto(data: SubcontractorModule): SubcontractorModuleDto {
    return {
      properties: this.propertiesMapper.toDto(data.properties),
      data: {
        lumpSumPricings: data.data.lumpSumPricing.map(item => this.lumpSumPricingMapper.toDto(item)),
        unitPricings: data.data.unitPricing.map(item => this.unitPricingMapper.toDto(item)),
      },
    };
  }
}
