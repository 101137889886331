import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SalesAppGuard } from '@dartsales/common/core/guards/sales-app.guard';
import { AuthorizedGuard } from '@dartsales/common/core/guards/authorized.guard';
import { UnauthorizedGuard } from '@dartsales/common/core/guards/unauthorized.guard';
import { PermissionsGuard } from '@dartsales/common/core/guards/permissions.guard';
import { UserPermission } from '@dartsales/common/core/enums/user-permission';

import { AppAccessErrorPageComponent } from './features/shared/components/app-access-error-page/app-access-error-page.component';
import { ProjectLayoutComponent } from './features/shared/components/layouts/project-layout/project-layout.component';
import { WelcomeLayoutComponent } from './features/shared/components/layouts/welcome-layout/welcome-layout.component';
import { provideWebAppRoutes, webRoutePaths } from './web-route-paths';
import { AppLayoutComponent } from './features/shared/components/layouts/app-layout/app-layout.component';
import { AppPermissionErrorPageComponent } from './features/shared/components/app-permission-error-page/app-permission-error-page.component';
import { provideReadonlyProjectPermission } from './features/shared/components/layouts/project-layout/project-layout.providers';

const projectChildPaths = webRoutePaths.project.children({ projectId: '' });

const routes: Routes = [
  {
    path: '',
    canActivate: [UnauthorizedGuard.canActivate, SalesAppGuard.canActivate],
    component: AppLayoutComponent,
    children: [
      {
        path: webRoutePaths.welcome.path,
        component: WelcomeLayoutComponent,
        loadChildren: () => import('./features/welcome/welcome.module').then(m => m.WelcomeModule),
      },
      {
        path: webRoutePaths.project.path,
        component: ProjectLayoutComponent,
        data: { breadcrumb: { skip: true } },
        children: [
          { path: '', redirectTo: projectChildPaths.dashboard.path, pathMatch: 'full' },
          {
            path: projectChildPaths.dashboard.path,
            loadChildren: () => import('./features/project-dashboard/project-dashboard.module')
              .then(m => m.ProjectDashboardModule),
          },
          {
            path: projectChildPaths.proposals.path,
            loadChildren: () => import('./features/project-proposals/project-proposals.module')
              .then(m => m.ProjectProposalsModule),
          },
          {
            path: projectChildPaths.compare.path,
            loadChildren: () => import('./features/project-compare/project-compare.module')
              .then(m => m.ProjectCompareModule),
          },
          {
            path: projectChildPaths.files.path,
            loadChildren: () => import('./features/project-files/project-files.module')
              .then(m => m.ProjectFilesModule),
            providers: [provideReadonlyProjectPermission()],
          },
          {
            path: projectChildPaths.resources.path,
            loadChildren: () => import('./features/project-resources/project-resources.module')
              .then(m => m.ProjectResourcesModule),
            canActivate: [PermissionsGuard.canActivate],
            data: {
              permissionType: UserPermission.CRUDResource,
            },
          },
          { path: '**', redirectTo: projectChildPaths.dashboard.path },
        ],
      },
      { path: '', redirectTo: webRoutePaths.welcome.path, pathMatch: 'full' },
    ],
  },
  {
    path: webRoutePaths.auth.path,
    canActivate: [AuthorizedGuard.canActivate],
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: webRoutePaths.accessError.path,
    component: AppAccessErrorPageComponent,
  },
  {
    path: webRoutePaths.permissionError.path,
    component: AppPermissionErrorPageComponent,
  },
  { path: '**', redirectTo: webRoutePaths.welcome.path },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [provideWebAppRoutes()],
  exports: [RouterModule],
})
export class AppRoutingModule { }
