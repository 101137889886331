import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '@dartsales/common/core/models/errors/app-error';
import { CustomColumn, CustomColumnEditData } from '@dartsales/common/core/models/estimate/custom-column';

import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from '../mappers';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { CustomColumnDto, CustomColumnEditDto } from '../dto/estimate/custom-column.dto';

/** Custom column mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomColumnMapper implements
  IMapperFromDto<CustomColumnDto, CustomColumn>,
  IMapperToDto<CustomColumnEditDto, CustomColumnEditData>,
  IValidationErrorMapper<CustomColumnEditDto, CustomColumnEditData> {
  /** @inheritdoc */
  public fromDto(dto: CustomColumnDto): CustomColumn {
    return new CustomColumn({
      id: dto.id,
      name: dto.name,
    });
  }

  /** @inheritdoc */
  public toDto(data: CustomColumnEditData): CustomColumnEditDto {
    return {
      name: data.name,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<CustomColumnEditDto> | null | undefined,
  ): EntityValidationErrors<CustomColumnEditDto> {
    return {
      name: extractErrorMessage(errorDto?.name),
    };
  }
}
