<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
  (cellFocus)="cellFocus.emit()"
  (cellBlur)="cellBlur.emit()"
>
  <ng-container
    *ngIf="textControl.enabled"
    dartsalescTableCellEditMode
  >
    <input
      #inputElement
      dartsalescTableCellInput
      type="text"
      class="text_mode-edit"
      [placeholder]="isReadonly ? '' : placeholder"
      [formControl]="textControl"
      [errorStateMatcher]="errorStateMatcher"
      [readonly]="isReadonly"
      (keydown.enter)="inputElement.blur()"
    />
  </ng-container>
  <div
    dartsalescTableCellViewMode
    class="text text_mode-view"
  >
    <span
      *ngIf="textControl.value !== ''; else placeholderView"
      class="text__content"
      [title]="textControl.value"
    >
      {{ textControl.value }}
    </span>
    <ng-template #placeholderView>
      <span
        *ngIf="!isReadonly"
        class="text__placeholder"
      >
        {{ placeholder }}
      </span>
    </ng-template>
  </div>
  <ng-container
    *ngIf="cellSuffix"
    dartsalescTableCellSuffix
  >
    <ng-content select="[dartsalescTableCellSuffix]" />
  </ng-container>
</dartsalesc-editable-table-cell>
