<dartsalesc-base-dialog
  dialogTitle="Edit password"
  class="dialog"
>
  <form [formGroup]="passwordChangeDataForm">
    <dartsalesw-password-form-field
      autocomplete="current-password"
      label="Current Password"
      [control]="passwordChangeDataForm.controls.currentPassword"
    />
    <dartsalesw-password-form-field
      autocomplete="new-password"
      label="New Password"
      [control]="passwordChangeDataForm.controls.newPassword"
    />
    <dartsalesw-password-form-field
      autocomplete="new-password"
      label="Repeat Password"
      [control]="passwordChangeDataForm.controls.newPasswordConfirmation"
    />
  </form>

  <div
    dialogFooter
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      mat-dialog-close="false"
      type="button"
      class="dialog-actions__button"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      type="button"
      color="primary"
      class="dialog-actions__button"
      [dartsalescLoading]="isChangePasswordPending$ | async"
      (click)="onSubmit()"
    >
      Update
    </button>
  </div>
</dartsalesc-base-dialog>
