import { Injectable, inject } from '@angular/core';

import { TermSubmoduleProperties, TermSubmoduleTotals } from '@dartsales/common/core/models/estimate-services/term-properties';

import { IMapper } from '../mappers';
import { TermSubmoduleSummariesDto } from '../dto/estimate-services/term-submodule-summaries.dto';
import { TermSubmodulePricingMapper } from '../pricing/term-submodule-pricing.mapper';
import { AmountPercentMapper } from '../estimate/amount-percent.mapper';
import { OverridableMapper } from '../overridable.mapper';

/** Term submodule summary mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermSubmoduleSummaryMapper
implements IMapper<TermSubmoduleSummariesDto, TermSubmoduleTotals> {
  private readonly termSubmoduleSummaryMapper = inject(TermSubmodulePricingMapper);

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: TermSubmoduleSummariesDto): TermSubmoduleTotals {
    return new TermSubmoduleTotals({
      subcontractor: new TermSubmoduleProperties({
        ...this.termSubmoduleSummaryMapper.fromDto(dto.subcontractor),
        quantity: dto.subcontractor.quantity,
        aggregatedWEFS: this.overridableMapper.fromObjectDto(dto.subcontractor.aggregatedWEFS, this.amountPercentMapper),
      }),
      expenses: new TermSubmoduleProperties({
        ...this.termSubmoduleSummaryMapper.fromDto(dto.expenses),
        quantity: null,
        aggregatedWEFS: this.overridableMapper.fromObjectDto(dto.expenses.aggregatedWEFS, this.amountPercentMapper),
      }),
      custom: new TermSubmoduleProperties({
        ...this.termSubmoduleSummaryMapper.fromDto(dto.custom),
        quantity: null,
        aggregatedWEFS: this.overridableMapper.fromObjectDto(dto.custom.aggregatedWEFS, this.amountPercentMapper),
      }),
      labor: new TermSubmoduleProperties({
        ...this.termSubmoduleSummaryMapper.fromDto(dto.labor),
        quantity: dto.labor.grandTotalHours,
        aggregatedWEFS: this.overridableMapper.fromObjectDto(dto.labor.aggregatedWEFS, this.amountPercentMapper),
      }),
      material: new TermSubmoduleProperties({
        ...this.termSubmoduleSummaryMapper.fromDto(dto.material),
        quantity: dto.material.quantity,
        aggregatedWEFS: this.overridableMapper.fromObjectDto(dto.material.aggregatedWEFS, this.amountPercentMapper),
      }),
    });
  }

  /** @inheritdoc */
  public toDto(data: TermSubmoduleTotals): TermSubmoduleSummariesDto {
    return {
      subcontractor: {
        ...this.termSubmoduleSummaryMapper.toDto(data.subcontractor),
        quantity: data.subcontractor.quantity ?? 0,
      },
      expenses: this.termSubmoduleSummaryMapper.toDto(data.expenses),
      custom: this.termSubmoduleSummaryMapper.toDto(data.custom),
      labor: {
        ...this.termSubmoduleSummaryMapper.toDto(data.labor),
        grandTotalHours: data.labor.quantity ?? 0,
      },
      material: {
        ...this.termSubmoduleSummaryMapper.toDto(data.material),
        quantity: data.material.quantity ?? 0,
      },
    };
  }
}
