import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { expandAnimation } from '@dartsales/common/shared/animations/expand-animation';
import { rightAngleRotateAnimation } from '@dartsales/common/shared/animations/right-angle-rotate-animation';

/** Row with expandable content. */
@Component({
  selector: 'dartsalesw-expandable-row',
  templateUrl: './expandable-row.component.html',
  styleUrls: ['./expandable-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rightAngleRotateAnimation, expandAnimation],
})
export class ExpandableRowComponent {

  /** Template of a content displayed in the row. */
  @ContentChild('rowContent')
  protected readonly rowContentTemplate: TemplateRef<unknown> | null = null;

  /** Template of a content, which is displayed when the row is expanded. */
  @ContentChild('details')
  protected readonly detailsTemplate: TemplateRef<unknown> | null = null;

  /**
   * Is row expansion disabled.
   * TODO(DART-848): Remove when compound escalation recalculation for modules is implemented on BE(DART-1045).
   */
  @Input()
  public isExpansionDisabled = false;

  /** Whether the row should be expandable. */
  @Input()
  public isExpandable = true;

  private _isExpanded = false;

  /** Whether the row is in expanded state or not. */
  @Input()
  public set isExpanded(isExpanded: boolean) {
    this._isExpanded = isExpanded;
  }

  /** Whether the row is in expanded state or not. */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  /** Tooltip text on expansion button when row is expanded. */
  @Input()
  public expandedButtonTooltipText = 'Hide';

  /** Tooltip text on expansion button when row is collapsed. */
  @Input()
  public collapsedButtonTooltipText = 'Expand';

  /** Expand event. */
  @Output()
  public readonly expansionChange = new EventEmitter<boolean>();

  /** Handle click on the expand button. */
  protected onExpandButtonClick(): void {
    this.isExpanded = !this.isExpanded;
    this.expansionChange.emit(this._isExpanded);
  }

  /** Expand button tooltip.  */
  protected get buttonTooltip(): string {
    return this.isExpanded ? this.expandedButtonTooltipText : this.collapsedButtonTooltipText;
  }
}
