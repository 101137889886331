import { Injectable } from '@angular/core';

import { PointsListSystemPortsTotal } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-ports-total';

import { IMapper } from '../../../mappers';
import { PointsListSystemPortsTotalDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-ports-total.dto';

/** Points list system ports total mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemPortsTotalMapper implements IMapper<PointsListSystemPortsTotalDto, PointsListSystemPortsTotal> {
  /** @inheritdoc */
  public fromDto(dto: PointsListSystemPortsTotalDto): PointsListSystemPortsTotal {
    return new PointsListSystemPortsTotal({
      categoryType: dto.categoryType,
      portType: dto.type,
      customId: dto.customId,
      quantity: dto.quantity,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemPortsTotal): PointsListSystemPortsTotalDto {
    return {
      categoryType: data.categoryType,
      type: data.portType,
      customId: data.customId,
      quantity: data.quantity,
    };
  }
}
