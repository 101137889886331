import { StrictExtract } from '@dartsales/common/core/utils/types/strict-extract';

import { ComponentType } from '../../filters/component-type';

import { PaletteOrganization } from './palette-organization';

/** Palette component. */
export class PaletteComponent {

  /** ID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Manufacturer. */
  public readonly manufacturer: string;

  /** Model number. */
  public readonly modelNumber: string;

  /** Organization. */
  public readonly organization: PaletteOrganization;

  /** List of tags. */
  public readonly tags: readonly string[];

  /** List of parts. */
  public readonly parts: readonly PaletteComponent[];

  /** Component type. */
  public readonly type: StrictExtract<ComponentType, 'Part' | 'Symbol'>;

  /** Supplier.'null' if no value is specified. */
  public readonly supplier: string | null;

  /** Unit cost. */
  public readonly unitCost: number;

  public constructor(data: PaletteComponentInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.manufacturer = data.manufacturer;
    this.modelNumber = data.modelNumber;
    this.organization = data.organization;
    this.tags = data.tags;
    this.parts = data.parts;
    this.type = data.type;
    this.supplier = data.supplier;
    this.unitCost = data.unitCost;
  }
}

type PaletteComponentInitArgs = PaletteComponent;
