import { UserRole } from './user-role';

/**
 * List of permissions for sales app:
 * https://saritasa.atlassian.net/wiki/spaces/ATADEV/pages/2509144065/DART+Sales+-+user+roles#DART-Sales---API-Permissions%5BhardBreak%5D.
 */
/** Enum with user permissions. */
export enum UserPermission {

  /** User can create/read/update/delete any resource. */
  CRUDResource = 'CRUDResource',

  /** User can create blank project. */
  CreateBlankProject = 'CreateBlankProject',

  /** User can create project from template. */
  CreateProjectFromTemplate = 'CreateProjectFromTemplate',

  /** Create/delete/update project template. */
  ManageProjectTemplate = 'ManageProjectTemplate',

  /** Create/delete/update organization service. */
  ManageOrgService = 'ManageOrgService',

  /** Create/delete/update organization pallette components. */
  ManageOrgPallette = 'ManageOrgPallette',

  /** Read organization pallette components. */
  ReadOrgPallette = 'ReadOrgPallette',

  /** Edit project. */
  EditProject = 'EditProject',

  /** Reorder services. */
  ReorderServices = 'ReorderServices',

  /** Edit labor module rate prices. */
  EditLaborRatePrices = 'EditLaborRatePrices',
}

export namespace UserPermission {
  const USER_ROLES_WITH_ALL_PERMISSIONS = [UserRole.SalesManager, UserRole.OrganizationAdmin];
  const MAP_PERMISSION_WITH_ALLOWED_USER_ROLE: Record<UserPermission, UserRole[]> = {
    [UserPermission.CRUDResource]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.CreateBlankProject]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.CreateProjectFromTemplate]: [...USER_ROLES_WITH_ALL_PERMISSIONS, UserRole.SalesPerson],
    [UserPermission.ManageProjectTemplate]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.ManageOrgService]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.EditProject]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.ManageOrgPallette]: [
      UserRole.OrganizationAdmin,
      UserRole.Developer,
    ],
    [UserPermission.ReadOrgPallette]: [
      ...USER_ROLES_WITH_ALL_PERMISSIONS,
      UserRole.OrganizationAdmin,
      UserRole.Developer,
      UserRole.SalesPerson,
    ],
    [UserPermission.ReorderServices]: USER_ROLES_WITH_ALL_PERMISSIONS,
    [UserPermission.EditLaborRatePrices]: USER_ROLES_WITH_ALL_PERMISSIONS,
  };

  /**
   * Get allowed roles for permission.
   * @param permission Permission.
   */
  export function getAllowedRoles(permission: UserPermission): UserRole[] {
    return MAP_PERMISSION_WITH_ALLOWED_USER_ROLE[permission];
  }
}
