import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Selected item. */
export type CatalogSelectedItem<TId extends (string | number) = string> = {

  /** Item ID. */
  readonly id: TId;

  /** Item parent ID. Empty if item doesn't have parent. */
  readonly parentId?: TId;
};

/** Catalog items selection service. */
@Injectable()
export class CatalogItemsSelectionService<TItemType extends string = string, TIdType extends (string | number) = string> {
  private readonly _selectedItems$ = new BehaviorSubject<readonly CatalogSelectedItem<TIdType>[]>([]);

  private readonly _selectedItemType$ = new BehaviorSubject<TItemType | null>(null);

  /** Selected items. */
  public readonly selectedItems$ = this._selectedItems$.asObservable();

  /**
   * Select item.
   * @param newItem New item to select.
   * @param itemType Item type.
   */
  public selectItem(newItem: CatalogSelectedItem<TIdType>, itemType: TItemType): void {
    if (itemType !== this._selectedItemType$.value) {
      this.clearSelectedItems();
    }
    this._selectedItemType$.next(itemType);

    const selectedItems = this._selectedItems$.value;
    const selectedItemIds = selectedItems.map(item => item.id);
    if (selectedItemIds.includes(newItem.id)) {
      const updatedItems = selectedItems.filter(item => item.id !== newItem.id);
      this._selectedItems$.next(updatedItems);
    } else {
      this._selectedItems$.next(selectedItems.concat(newItem));
    }
  }

  /** Clear selected items. */
  public clearSelectedItems(): void {
    this._selectedItems$.next([]);
    this._selectedItemType$.next(null);
  }
}
