import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';

import { ResizableContentContainerServices } from '../../services/resizable-content-container-services.service';
import { ResizableContentContainerComponent } from '../../resizable-content-container.component';

/** Right section of the resizable content container. */
@Component({
  selector: 'dartsalesw-resizable-content-right-section',
  templateUrl: 'resizable-content-right-section.component.html',
  styleUrls: ['resizable-content-right-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableContentRightSectionComponent {

  private readonly resizeContentContainerServices = inject(ResizableContentContainerServices);

  private readonly contentContainer = inject(ResizableContentContainerComponent, { optional: true, host: true });

  /** Min-width. */
  @HostBinding('style.min-width.px')
  public get width(): number {
    return this.resizeContentContainerServices.rightSectionMinWidth;
  }

  public constructor() {
    if (this.contentContainer == null) {
      throw new Error('ResizableContentRightSectionComponent must be used inside ResizableContentContainerComponent.');
    }
  }
}
