import { FinalEstimateTermContainer } from './final-estimate-term';

/** Final estimate service by categories aggregation. */
export class FinalEstimateServiceByServices {

  /** Categories. */
  public readonly categories: readonly FinalEstimateServiceCategory[];

  /** Custom services. */
  public readonly customCategory: FinalEstimateServiceSubCategory | null;

  /** Grand total for all modules. */
  public readonly grandTotals: FinalEstimateTermContainer;

  public constructor(data: FinalEstimateServiceByServicesInitArgs) {
    this.categories = data.categories;
    this.customCategory = data.customCategory;
    this.grandTotals = data.grandTotals;
  }
}

/** Final estimate service totals category. */
export class FinalEstimateServiceCategory extends FinalEstimateTermContainer {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Subcategories. */
  public readonly subCategories: readonly FinalEstimateServiceSubCategory[];

  public constructor(data: FinalEstimateServiceCategoryInitArgs) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.subCategories = data.subCategories;
  }
}

/** Final estimate service totals subcategory. */
export class FinalEstimateServiceSubCategory extends FinalEstimateTermContainer {

  /** ID. */
  public readonly id: number | null;

  /** Name. */
  public readonly name: string;

  /** Services. */
  public readonly services: readonly FinalEstimateServiceService[];

  public constructor(data: FinalEstimateServiceSubCategoryInitArgs) {
    super(data);
    this.id = data.id;
    this.name = data.name;
    this.services = data.services;
  }
}

/** Final estimate subcategory service. */
export class FinalEstimateServiceService extends FinalEstimateTermContainer {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  public constructor(data: FinalEstimateServiceServiceInitArgs) {
    super(data);
    this.id = data.id;
    this.name = data.name;
  }
}

type FinalEstimateServiceByServicesInitArgs = FinalEstimateServiceByServices;
type FinalEstimateServiceCategoryInitArgs = FinalEstimateServiceCategory;
type FinalEstimateServiceSubCategoryInitArgs = FinalEstimateServiceSubCategory;
type FinalEstimateServiceServiceInitArgs = FinalEstimateServiceService;
