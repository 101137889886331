import { TreeItem } from '../tree-item';

import { TermSubCategoryTreeItem } from './term-subcategory-tree-item';

/** Term category tree item. */
export type TermCategoryItem = {

  /** Category ID. */
  readonly categoryId: number;
};

/** Term category tree item. */
export class TermCategoryTreeItem extends TreeItem<TermCategoryItem, TermSubCategoryTreeItem> {}
