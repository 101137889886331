import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, ContentChild, inject, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { CustomProjectPageSearchService } from '@dartsales/common/core/services/custom-project-page-search.service';

/**
 * Portal for custom project page search component.
 * @example
 * ```html
 * <dartsalesw-project-page-search-portal>
 *   <ng-template #searchTemplate>
 *     <dartsalesw-custom-project-page-search-component />
 *   </ng-template>
 * </dartsalesw-project-page-search-portal>
 * ```
 */
@Component({
  selector: 'dartsalesw-project-page-search-portal',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPageSearchPortalComponent implements OnDestroy, OnInit {

  private readonly vcr = inject(ViewContainerRef);

  private readonly customProjectPageSearchService = inject(CustomProjectPageSearchService);

  /** Search template. */
  @ContentChild('searchTemplate', { static: true })
  protected readonly searchTemplate: TemplateRef<unknown> | null = null;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.registerCustomPageSearch();
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.unregisterCustomPageSearch();
  }

  private registerCustomPageSearch(): void {
    if (this.searchTemplate != null) {
      const templatePortal = new TemplatePortal(this.searchTemplate, this.vcr);
      this.customProjectPageSearchService.customPageSearchTemplate$.next(templatePortal);
    }
  }

  private unregisterCustomPageSearch(): void {
    this.customProjectPageSearchService.customPageSearchTemplate$.next(null);
  }
}
