import { Injectable, inject } from '@angular/core';

import { EstimatePartPort, OverridableEstimatePartPort } from '@dartsales/common/core/models/components/estimate-part/estimate-part-port';

import { IMapper } from '../../mappers';
import { EstimatePartPortDto, OverridableEstimatePartPortDto } from '../../dto/components/estimate-part/estimate-part-port.dto';
import { ConfirmedOverridableMapper } from '../../confirmed-overridable.mapper';

import { EstimatePartPortPropertyMapper } from './estimate-part-port-property.mapper';

/** EstimatePartPort entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimatePartPortMapper implements IMapper<EstimatePartPortDto, EstimatePartPort> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly portPropertyMapper = inject(EstimatePartPortPropertyMapper);

  /** @inheritdoc */
  public fromDto(dto: EstimatePartPortDto): EstimatePartPort {
    return new EstimatePartPort({
      customId: dto.customId,
      type: dto.type,
      categoryType: dto.categoryType,
      name: dto.name,
      quantity: dto.quantity,
      properties: dto.properties.map(property => this.portPropertyMapper.fromDto(property)),
    });
  }

  /** @inheritdoc */
  public fromOverridableDto(dto: OverridableEstimatePartPortDto): OverridableEstimatePartPort {
    return new OverridableEstimatePartPort({
      customId: dto.customId,
      allNames: dto.allNames,
      type: dto.type,
      categoryType: dto.categoryType,
      name: dto.name,
      quantity: this.confirmedOverridableMapper.fromDto(dto.quantity),
      properties: dto.properties.map(property => this.portPropertyMapper.fromOverridableDto(property)),
      displayAsBinaryInput: dto.isConvertedDryContact,
    });
  }

  /** @inheritdoc */
  public toDto(data: EstimatePartPort): EstimatePartPortDto {
    return {
      customId: data.customId,
      type: data.type,
      categoryType: data.categoryType,
      name: data.name,
      quantity: data.quantity,
      properties: data.properties.map(property => this.portPropertyMapper.toDto(property)),
    };
  }

  /** @inheritdoc */
  public toOverridableDto(data: OverridableEstimatePartPort): OverridableEstimatePartPortDto {
    return {
      customId: data.customId,
      allNames: data.allNames,
      type: data.type,
      categoryType: data.categoryType,
      name: data.name,
      quantity: this.confirmedOverridableMapper.toDto(data.quantity),
      properties: data.properties.map(property => this.portPropertyMapper.toOverridableDto(property)),
      isConvertedDryContact: data.displayAsBinaryInput,
    };
  }
}
