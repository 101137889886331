import { Module } from '../../module';
import { ModuleProperties } from '../module-properties';

import { CustomModuleData } from './custom-module-data';

/** Custom module data. */
export class CustomModule extends Module<ModuleProperties, CustomModuleData> {

  public constructor(data: CustomModuleInitArgs) {
    super(data);
  }
}

type CustomModuleInitArgs = CustomModule;
