<dartsalesc-text-cell-renderer
  *ngIf="(isEditMode$ | async) === false; else editMode"
  [placeholder]="placeholder"
  [value]="controlValue ?? ''"
  [isFocusable]="(isFocusable$ | async) ?? false"
/>
<ng-template #editMode>
  <dartsalesc-text-cell-editor
    [placeholder]="placeholder"
    [value]="controlValue ?? ''"
    [errorStateMatcher]="errorStateMatcher"
    (valueChange)="controlValue = $event"
  />
</ng-template>
<ng-container
  *ngIf="cellSuffixTemplate"
  [ngTemplateOutlet]="cellSuffixTemplate"
/>
