/** Final estimate status. */
export enum FinalEstimateStatus {
  Pending = 'pending',
  Awarded = 'awarded',
}

const TO_TITLE_MAP: Record<FinalEstimateStatus, string> = {
  [FinalEstimateStatus.Pending]: 'Draft',
  [FinalEstimateStatus.Awarded]: 'Selected',
};

export namespace FinalEstimateStatus {

  /**
   * Converts final estimate status to readable equivalent.
   * @param value Status.
   */
  export function toReadable(value: FinalEstimateStatus): string {
    return TO_TITLE_MAP[value];
  }
}
