import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';

import { CustomProjectPageSearchService } from '@dartsales/common/core/services/custom-project-page-search.service';
import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

/** Project toolbar component. */
@Component({
  selector: 'dartsalesw-project-toolbar',
  templateUrl: './project-toolbar.component.html',
  styleUrls: ['../shared-toolbar-styles.css', './project-toolbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectToolbarComponent {

  /** Option button click handler. */
  @Output()
  public readonly toolbarButtonClick = new EventEmitter<void>();

  /** Route paths. */
  protected readonly routePaths = injectWebAppRoutes();

  /** Custom project page search service. */
  protected readonly customProjectPageSearchService = inject(CustomProjectPageSearchService);
}
