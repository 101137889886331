/** Project team. */
export class ProjectTeam {

  /** Project Manager. */
  public readonly projectManager: string;

  /** Project Engineer. */
  public readonly projectEngineer: string;

  /** Project Salesperson. */
  public readonly projectSalesPerson: string;

  /** Project Technician. */
  public readonly projectTechnician: string;

  /** Approved by. */
  public readonly approvedBy: string;

  /** Drawn by. */
  public readonly drawnBy: string;

  public constructor(data: ProjectTeamInitArgs) {
    this.projectManager = data.projectManager;
    this.projectEngineer = data.projectEngineer;
    this.projectSalesPerson = data.projectSalesPerson;
    this.projectTechnician = data.projectTechnician;
    this.approvedBy = data.approvedBy;
    this.drawnBy = data.drawnBy;
  }
}

type ProjectTeamInitArgs = ProjectTeam;
