import { Injectable, inject } from '@angular/core';

import { NonStandardMaterialItem } from '@dartsales/common/core/models/estimate/modules/material/data/non-standard-material-item';
import { MaterialPricingNoOverride } from '@dartsales/common/core/models/estimate/modules/material/material-pricing-no-override';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';
import { EditNonStandardMaterialItem } from '@dartsales/common/core/models/estimate/modules/material/data/edit-non-standard-material-item';

import { IMapperFromDto, IMapperToDto } from '../../../mappers';
import { BatchEditLockablePercentsStatusDto, EditNonStandardMaterialPricingDto, NonStandardMaterialItemDto } from '../../../dto/estimate/modules/material/data/non-standard-material-item.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';

import { MaterialLockablePercentsStatusMapper } from './material-lockable-percents-status.mapper';
import { AbstractMaterialPricingMapper } from './abstract-material-pricing.mapper';

/** Non standard material item entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class NonStandardMaterialItemMapper implements
  IMapperFromDto<NonStandardMaterialItemDto, NonStandardMaterialItem>,
  IMapperToDto<EditNonStandardMaterialPricingDto, NonStandardMaterialItem> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(MaterialLockablePercentsStatusMapper);

  private readonly abstractMaterialPricingMapper = inject(AbstractMaterialPricingMapper);

  /** @inheritdoc */
  public fromDto(dto: NonStandardMaterialItemDto): NonStandardMaterialItem {
    return new NonStandardMaterialItem({
      pricing: new MaterialPricingNoOverride({
        ...this.abstractMaterialPricingMapper.fromDto({
          ...dto,
          unitCost: dto.part.unitCost,
        }),
        quantity: dto.part.quantity,
      }),
      partId: dto.id,
      name: dto.part.name,
      description: dto.part.description,
      manufacturer: dto.part.manufacturer,
      modelNumber: dto.part.modelNumber,
      tags: dto.part.tags,
      supplier: dto.part.supplier ?? '',
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
    });
  }

  /** @inheritdoc */
  public toDto(data: EditNonStandardMaterialItem): EditNonStandardMaterialPricingDto {
    const pricingDto = this.basePricingMapper.toDto(data.pricing);

    return {
      ...pricingDto,
      unitCost: data.pricing.unitCost,
      directCost: pricingDto.directCost,
      warranty: data.pricing.warranty,
      escalation: data.pricing.escalation,
      freight: data.pricing.freight,
      salesTax: data.pricing.salesTax,
      part: {
        name: data.name,
        description: data.description,
        supplier: data.supplier,
        modelNumber: data.modelNumber,
        tags: data.tags,
        unitCost: data.pricing.unitCost,
        quantity: data.pricing.quantity,
        manufacturer: data.manufacturer,
      },
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Part ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: NonStandardMaterialItem['partId'],
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      nonStandardMaterials: [
        {
          nonStandardMaterialId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
