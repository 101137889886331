import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

/** Confirmed overridable value changes. */
@Component({
  selector: 'dartsalesw-confirmed-overridable-changes',
  templateUrl: './confirmed-overridable-changes.component.html',
  styleUrls: ['./confirmed-overridable-changes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmedOverridableChangesComponent<T> {

  /** Confirmed overridable value. */
  @Input({ required: true })
  public value: ConfirmedOverridable<T> | null = null;

  /** Label. */
  @Input({ required: true })
  public label = '';

  /**
   * Function to format value to string.
   * @param value Value.
   */
  @Input()
  public valueFormatter: (value: T | null) => string = value => this.defaultValueFormatter(value);

  private defaultValueFormatter(value: T | null): string {
    if (value == null) {
      return '-';
    }
    return String(value);
  }
}
