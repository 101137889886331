/** Short estimate. */
export class ShortEstimate {
  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  public constructor(data: ShortEstimateInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
  }
}
type ShortEstimateInitArgs = ShortEstimate;
