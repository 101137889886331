import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';
import { TermServiceTreeItem } from '@dartsales/common/core/models/estimate-services/term-service-tree-item';
import { TermSubCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-subcategory-tree-item';
import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';

import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';

export namespace EstimateItemsMultipleExportTreeMappersToNode {

  /**
   * Map estimate module type to nested node..
   * @param moduleType Module type.
   */
  export function mapEstimateModuleType(moduleType: ModuleType): Types.EstimateChildrenNestedNode {
    return {
      title: ModuleType.toReadable(moduleType),
      value: {
        item: moduleType,
        type: Types.NodeType.EstimateModuleType,
        key: `estimateModule ${moduleType}`,
      },
      children: [],
    };
  }

  /**
   * Map term to nested node.
   * @param term Term tree item.
   */
  export function mapTerm(term: TermTreeItem): Types.EstimateChildrenNestedNode {
    return {
      title: term.title,
      value: {
        type: Types.NodeType.Term,
        item: term.value,
        key: `Term ${term.value.termId}`,
      },
      children: term.children.map(category => mapCategory(category, `Term ${term.value.termId}`)),
    };
  }

  /**
   * Map category to nested node.
   * @param category Category.
   * @param parentPrefix Parent prefix.
   */
  function mapCategory(category: TermCategoryTreeItem, parentPrefix: string): Types.EstimateChildrenNestedNode {
    return {
      title: category.title,
      value: {
        type: Types.NodeType.Category,
        item: category.value,
        key: `${parentPrefix} | Category ${category.value.categoryId}`,
      },
      children: category.children.map(subCategory => mapSubCategory(subCategory, parentPrefix)),
    };
  }

  /**
   * Map sub-category to nested node.
   * @param subCategory Sub-category.
   * @param parentPrefix Parent prefix.
   */
  function mapSubCategory(subCategory: TermSubCategoryTreeItem, parentPrefix: string): Types.EstimateChildrenNestedNode {
    return {
      title: subCategory.title,
      value: {
        type: Types.NodeType.SubCategory,
        item: subCategory.value,
        key: `${parentPrefix} | SubCategory ${subCategory.value.subCategoryId}`,
      },
      children: subCategory.children.map(service => mapService(service, parentPrefix)),
    };
  }

  /**
   * Map service to nested node.
   * @param service Service.
   * @param parentPrefix Parent prefix.
   */
  function mapService(service: TermServiceTreeItem, parentPrefix: string): Types.EstimateChildrenNestedNode {
    return {
      title: service.title,
      value: {
        type: Types.NodeType.Service,
        item: service.value,
        key: `${parentPrefix} | Service ${service.value.serviceId}`,
      },
      children: service.children.map(moduleType =>
        mapServiceModuleType(moduleType, `${parentPrefix} | service ${service.value.serviceId}`)),
    };
  }

  /**
   * Map service module type to nested node.
   * @param moduleType Module type.
   * @param parentPrefix Parent prefix.
   */
  function mapServiceModuleType(moduleType: ModuleType, parentPrefix: string): Types.EstimateChildrenNestedNode {
    return {
      title: ModuleType.toReadable(moduleType),
      value: {
        type: Types.NodeType.ServiceModuleType,
        item: moduleType,
        key: `${parentPrefix} | Module ${moduleType}`,
      },
      children: [],
    };
  }
}
