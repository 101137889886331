/**
 * Transform date from server (without timezone) to client (with timezone).
 * To get the same time we should add timezone offset to server date.
 * @param date Date.
 */
export function transformToClientDate(date: Date): Date {
  // Gets timezone offset in milliseconds.
  const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() + userTimezoneOffset);
}

/**
 * Transform date from client (with timezone) to server (without timezone).
 * To get the same time we should subtract timezone offset from client date.
 * @param date Date.
 */
export function transformToServerDate(date: Date): Date {
  // Gets timezone offset in milliseconds.
  const userTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() - userTimezoneOffset);
}

/**
 * Whether Date is valid or not.
 * @param date Date.
 */
export function isInvalidDate(date?: Date | null): boolean {
  return isNaN(Number(date));
}
