import { Injectable } from '@angular/core';

import { InternalCustomerValues } from '@dartsales/common/core/models/internal-customer-values';

import { LaborRoleRateDto } from '../dto/resources/labor-role/labor-role-rate.dto';
import { IMapper } from '../mappers';

/** Labor role rate entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleRateMapper implements IMapper<LaborRoleRateDto, InternalCustomerValues<number>> {

  /** @inheritdoc */
  public fromDto(dto: LaborRoleRateDto): InternalCustomerValues<number> {
    return new InternalCustomerValues({
      customer: dto.customerRate,
      internal: dto.internalRate,
    });
  }

  /** @inheritdoc */
  public toDto(data: InternalCustomerValues<number>): LaborRoleRateDto {
    return {
      customerRate: data.customer,
      internalRate: data.internal,
    };
  }
}
