import { Overridable } from '@dartsales/common/core/utils/types/overridable';

/** Points list overridable total values for system/tab. */
export class PointsListOverridableItemTotal {
  /** Direct cost. */
  public readonly directCost: Overridable<number>;

  /** Labor cost. */
  public readonly laborCost: number;

  /** Material cost. */
  public readonly materialCost: Overridable<number>;

  /** Labor hours. */
  public readonly laborHours: number;

  public constructor(data: PointsListItemTotalInitArgs) {
    this.directCost = data.directCost;
    this.laborCost = data.laborCost;
    this.materialCost = data.materialCost;
    this.laborHours = data.laborHours;
  }

}

type PointsListItemTotalInitArgs = PointsListOverridableItemTotal;
