import { ChangeDetectionStrategy, Component, ContentChild, OnInit, ViewChild, inject, Input, EventEmitter, Output } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { TableCellFormControl } from '@dartsales/common/shared/components/editable-table-cell/components/table-cell-form-control.component';
import { TableCellInputDirective } from '@dartsales/common/shared/components/editable-table-cell/directives/table-cell-input.directive';

import { TableCellSuffixDirective } from '../../directives/table-cell-suffix.directive';

// TODO (Pavel Z.) This component looks too similar with `OverridableAmountInputComponent`, it needs to think about combining them.
/** Overridable number table cell. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-overridable-number-table-cell',
  templateUrl: './overridable-number-table-cell.component.html',
  styleUrls: ['./overridable-number-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverridableNumberTableCellComponent extends TableCellFormControl<Overridable<number>> implements OnInit {

  /** Whether cell is read-only. */
  @Input()
  public isReadonly = false;

  /**
   * Whether value in view mode should be hidden.
   * Suffix will be also hidden in view mode.
   */
  @Input()
  public hideValue = false;

  /** Cell blur event. */
  @Output()
  public readonly cellBlur = new EventEmitter<void>();

  /** Cell suffix template. */
  @ContentChild(TableCellSuffixDirective)
  protected readonly cellSuffix?: TableCellSuffixDirective;

  @ViewChild(TableCellInputDirective)
  private readonly cellInputElement?: TableCellInputDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Input control. */
  protected readonly inputControl = this.fb.control<number>(0);

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      !this.isReadonly &&
      this.controlValue?.override !== null
    );
  }

  /**
   * Convert value to display format.
   * @param value Native component value.
   */
  protected valueToInput(value: Overridable<number>): number {
    return value.combinedValue;
  }

  /**
   * Convert display format to native value.
   * @param valueVm Display value.
   */
  protected valueFromInput(valueVm: number): Overridable<number> | null {
    if (this.controlValue) {
      return new Overridable({ initial: this.controlValue.initial, override: valueVm });
    }

    return null;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: Overridable<number> | null): void {
    this.inputControl.setValue(value ? this.valueToInput(value) : 0, { emitEvent: false });
    super.writeValue(value);
  }

  /** Input blur handler. */
  protected onBlur(): void {
    this.cellBlur.emit();
    if (this.inputControl.value == null) {
      this.inputControl.setValue(0);
    }
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new Overridable({ initial: this.controlValue.initial, override: null });
      this.writeValue(clearedOverride);
      this.controlValue = clearedOverride;
      this.cellInputElement?.focus();
    }
  }

  private subscribeToControlChanges(): void {
    // The reason we don't use 'listenControlChanges' is described in TextTableCellComponent
    this.inputControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        const nativeValue = this.valueFromInput(value);
        if (nativeValue != null && this.controlValue != null) {
          this.controlValue = nativeValue;
        }
      });
  }
}
