<input
  matInput
  [dartsalescInputMask]="mask"
  [placeholder]="placeholder"
  [formControl]="formControl"
/>
<div
  matSuffix
  class="input-suffix"
  [class.input-suffix_disabled]="formControl.disabled"
>
  <button
    *ngIf="isResetAvailable"
    #resetButton
    mat-icon-button
    class="reset-button"
    type="button"
    title="Reset value"
    (click)="onResetButtonClick($event)"
  >
    <mat-icon>refresh</mat-icon>
  </button>
  <ng-content select="[suffix]" />
</div>
