import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CURRENCY_MASK } from '@dartsales/common/core/utils/constants';

import { BaseCellRendererComponent } from '../../../base-components/base-cell-renderer.component';

/** Masked number cell rendered component. */
@Component({
  selector: 'dartsalesc-masked-number-cell-renderer',
  templateUrl: './masked-number-cell-renderer.component.html',
  styleUrls: ['./masked-number-cell-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskedNumberCellRendererComponent extends BaseCellRendererComponent<number> {

  /** Placeholder. */
  @Input()
  public placeholder = '0';

  /** Mask. */
  @Input()
  public mask = CURRENCY_MASK;

  /** Should hide value. */
  @Input()
  public shouldHideValue = false;

  /**
   * Check should display value.
   * @param value Value.
   */
  protected checkShouldDisplayValue(value: number | null): value is number {
    return value != null;
  }
}
