import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { EstimatesHubService } from '@dartsales/common/core/services/api/hubs/estimates-hub.service';
import { NotificationsService } from '@dartsales/common/core/services/notifications.service';

/**
 * App layout component.
 * We need this component to provide shared services for both Welcome and Project layouts.
 * Template for the layout should be declared inside Welcome/Project layout components accordingly.
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-app-layout',
  templateUrl: './app-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationsService],
})
export class AppLayoutComponent implements OnInit {

  private readonly estimatesHubService = inject(EstimatesHubService);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.estimatesHubService.startConnection().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }
}
