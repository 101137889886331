<dartsalesc-editable-table-cell
  #cell
  class="labor-hours-cell"
  [class.labor-hours-cell_reset-available]="isResetAvailable"
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
  (cellBlur)="onBlur()"
>
  <ng-container
    *ngIf="inputControl.enabled"
    dartsalescTableCellEditMode
  >
    <input
      #inputElement
      dartsalescTableCellInput
      type="number"
      class="number-edit-mode"
      [readonly]="isReadonly"
      [formControl]="inputControl"
      [errorStateMatcher]="errorStateMatcher"
      (keydown.enter)="inputElement.blur()"
    />
  </ng-container>

  <div
    *ngIf="hideValue"
    dartsalescTableCellViewMode
  >
    <!-- Empty. -->
  </div>

  <div
    *ngIf="!hideValue"
    dartsalescTableCellViewMode
    class="number-view-mode"
  >
    <span
      class="number-view-mode__content"
      [title]="inputControl.value"
    >
      {{ inputControl.value | decimalNumber }}
    </span>
  </div>

  <ng-container dartsalescTableCellPrefix>
    <div class="cell-prefix">
      <button
        *ngIf="isResetAvailable"
        mat-icon-button
        class="cell-prefix__reset-button"
        type="button"
        matTooltip="Clear override"
        (click)="onResetButtonClick()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </ng-container>

  <ng-container dartsalescTableCellSuffix>
    <ng-container *ngIf="cellSuffix">
      <ng-content select="[dartsalescTableCellSuffix]" />
    </ng-container>
  </ng-container>
</dartsalesc-editable-table-cell>
