import { Injectable, inject } from '@angular/core';

import { PointsListPart } from '@dartsales/common/core/models/estimate/modules/points-list/part/points-list-part';
import { CreatePointsListPart, CreatePointsListPartFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/part/create-points-list-part';
import { BatchEditPointsListPart, EditPointsListPart } from '@dartsales/common/core/models/estimate/modules/points-list/part/edit-points-list-part';
import { BatchEditPointsListPartPerformer } from '@dartsales/common/core/models/estimate/modules/points-list/part/edit-points-list-part-performer';

import { BatchEditPointsListPartDto, BatchEditPointsListPartPerformerDto, CreatePointsListPartDto, CreatePointsListPartFromPaletteDto, EditPointsListPartDto, PointsListPartDto } from '../../../dto/estimate/modules/points-list/part/points-list-part.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

import { PointsListPartDetailsMapper } from './points-list-part-details.mapper';
import { PointsListPartPropertiesMapper } from './points-list-part-properties.mapper';

/** Points list part mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartMapper implements IMapper<PointsListPartDto, PointsListPart> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly pointsListPartDetailsMapper = inject(PointsListPartDetailsMapper);

  private readonly pointsListPartPropertiesMapper = inject(PointsListPartPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListPartDto): PointsListPart {
    return new PointsListPart({
      id: dto.id,
      partGroupId: dto.partGroupId,
      paletteComponentId: dto.paletteComponentId,
      order: dto.order,
      isExcluded: dto.isExcluded,
      data: this.pointsListPartDetailsMapper.fromDto(dto.data),
      properties: this.pointsListPartPropertiesMapper.fromDto(dto.properties),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListPart): PointsListPartDto {
    return {
      id: data.id,
      partGroupId: data.partGroupId,
      paletteComponentId: data.paletteComponentId,
      order: data.order,
      isExcluded: data.isExcluded,
      data: this.pointsListPartDetailsMapper.toDto(data.data),
      properties: this.pointsListPartPropertiesMapper.toDto(data.properties),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListPart): CreatePointsListPartDto {
    return {
      data: this.pointsListPartDetailsMapper.toCreateDto(data.data),
      order: data.order,
    };
  }

  /**
   * To create from palette DTO.
   * @param data Data.
   */
  public toCreateFromPaletteDto(data: CreatePointsListPartFromPalette): CreatePointsListPartFromPaletteDto {
    return {
      order: data.order,
      components: data.components.map(item => ({
        paletteComponentId: item.paletteComponentId,
        componentPartIds: item.childComponentIds,
      })),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListPart): EditPointsListPartDto {
    return {
      data: this.pointsListPartDetailsMapper.toEditDto(data.data),
      order: data.order,
      isExcluded: data.isExcluded,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditPointsListPart): BatchEditPointsListPartDto {
    return {
      partId: data.id,
      part: this.toEditDto(data.value),
      updatePaletteComponent: data.value.shouldUpdateInOrganizationPalette,
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditPartPerformerDto(data: BatchEditPointsListPartPerformer): BatchEditPointsListPartPerformerDto {
    return {
      partId: data.id,
      providedBy: data.value.providedBy ? this.confirmedOverridableMapper.toDto(data.value.providedBy) : undefined,
      installedBy: data.value.installedBy ? this.confirmedOverridableMapper.toDto(data.value.installedBy) : undefined,
      wiredBy: data.value.wiredBy ? this.confirmedOverridableMapper.toDto(data.value.wiredBy) : undefined,
    };
  }
}
