import {
  AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, Output, ViewEncapsulation, inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, Observable, debounceTime, defer, distinctUntilChanged, filter, fromEvent, map, merge, of, shareReplay, skip } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TableRowEventsService } from '../editable-table/services/table-row-events.service';

import { TableCellEditModeDirective } from './directives/table-cell-edit-mode.directive';
import { TableCellViewModeDirective } from './directives/table-cell-view-mode.directive';
import { TableCellSuffixDirective } from './directives/table-cell-suffix.directive';
import { TableCellPrefixDirective } from './directives/table-cell-prefix.directive';

/**
 * Editable table cell.
 * This component is wrapper for table cell element.
 * You can use special directives for inner elements layout:
 * - `TableCellEditModeDirective` - cell 'edit' mode.
 * - `TableCellViewModeDirective` - cell 'view' mode.
 *
 * Cell 'view' mode is optional. By default 'dartsalescTableCellInput' input value will be used.
 * See example below.
 * @example
 * ```html
 * <dartsalesc-editable-table-cell>
 *   <ng-container dartsalescTableCellEditMode>
 *     <input dartsalescTableCellInput />
 *   </ng-container>
 *   <ng-container dartsalescTableCellViewMode>
 *     <!-- View. -->
 *   </ng-container>
 * </dartsalesc-editable-table-cell>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-editable-table-cell',
  templateUrl: './editable-table-cell.component.html',
  styleUrls: ['./editable-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EditableTableCellComponent implements AfterContentInit {

  /** Show edit mode only. */
  @Input()
  public editModeOnly = false;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Cell focus event. */
  @Output()
  public readonly cellFocus = new EventEmitter<void>();

  /** Cell blur event. */
  @Output()
  public readonly cellBlur = new EventEmitter<void>();

  /** Edit mode. */
  @ContentChild(TableCellEditModeDirective)
  protected readonly editModeChild?: TableCellEditModeDirective;

  /** View mode. */
  @ContentChild(TableCellViewModeDirective)
  protected readonly viewModeChild?: TableCellViewModeDirective;

  /** Cell suffix. */
  @ContentChild(TableCellSuffixDirective)
  protected readonly cellSuffix?: TableCellSuffixDirective;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  /** Invalid. */
  @HostBinding('class.invalid')
  protected get isInvalid(): boolean {
    return this.editModeChild?.input?.errorState ?? false;
  }

  /** Readonly. */
  @HostBinding('class.readonly')
  protected get isReadonlyValue(): boolean {
    return this.isReadonly;
  }

  /** Has suffix. */
  @HostBinding('class.has-suffix')
  protected get hasSuffix(): boolean {
    return this.cellSuffix !== undefined;
  }

  /** Has prefix. */
  @HostBinding('class.has-prefix')
  protected get hasPrefix(): boolean {
    return this.cellPrefix !== undefined;
  }

  /** Title. */
  @HostBinding('attr.title')
  protected get title(): string {
    return this.editModeChild?.input?.value ?? '';
  }

  /** Is edit mode. */
  protected readonly isEditMode$ = new BehaviorSubject(false);

  /** Is cell in edit mode. */
  public get isEditMode(): boolean {
    return this.isEditMode$.value;
  }

  /** Is edit mode enabled. */
  protected get isEditModeEnabled(): boolean {
    return (this.isEditMode || this.editModeOnly) && this.editModeChild != null;
  }

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly document = inject(DOCUMENT);

  private readonly rowEventsService = inject(TableRowEventsService, { optional: true });

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    this.subscribeToFocusChange();
    this.initializeEditControl();
  }

  private subscribeToFocusChange(): void {
    this.createCellFocusStream().pipe(
      untilDestroyed(this),
    )
      .subscribe(isFocused => {
        if (isFocused) {
          this.handleFocus();
        } else {
          this.handleBlur();
        }
      });
  }

  private handleFocus(): void {
    this.isEditMode$.next(true);
    this.cellFocus.emit();
    this.cdr.markForCheck();
    this.rowEventsService?.triggerCellFocus();
  }

  private handleBlur(): void {
    this.isEditMode$.next(false);
    this.cellBlur.emit();
    this.cdr.markForCheck();
    this.editModeChild?.input?.ngControl.control?.markAsTouched();
    this.rowEventsService?.triggerCellBlur(this.editModeChild?.input?.tableRowElement ?? null);
  }

  private initializeEditControl(): void {
    const control = this.editModeChild?.input;

    if (control === undefined) {
      return;
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.cdr.markForCheck();
    });

    // Run change detection if the value changes.
    control.ngControl?.valueChanges?.pipe(
      untilDestroyed(this),
    )
      .subscribe(() => this.cdr.markForCheck());
  }

  private createCellFocusStream(): Observable<boolean> {
    const { nativeElement } = this.elementRef;
    return merge(
      defer(() => of(nativeElement.contains(this.document.activeElement))),
      fromEvent(nativeElement, 'focusin').pipe(
        filter(() => !this.isReadonly),
        map(() => true),
      ),
      fromEvent<FocusEvent>(nativeElement, 'focusout').pipe(
        filter(event => this.isFocusOut(event)),
        map(event => nativeElement.contains(event.relatedTarget as Node)),
      ),
    ).pipe(
      skip(1),
      debounceTime(0),
      distinctUntilChanged(),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private isFocusOut(event: FocusEvent): boolean {
    // Important!
    // We have to set 'tabindex="0"' attribute in '<mat-option>' element to make it focusable.
    // Otherwise, 'relatedTarget' will have incorrect value.
    // Options list wrapper element will be targeted instead of '<mat-option>' element.
    const relatedTarget = event.relatedTarget as HTMLElement | null;

    // 'mat-option' elements capture focus, we need to ignore such events.
    const isMatOptionFocused = relatedTarget?.tagName === 'MAT-OPTION';

    return event.target !== this.document.activeElement && !isMatOptionFocused;

  }
}
