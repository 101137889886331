import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { Router } from '@angular/router';

import { trackByIndex } from '@dartsales/common/core/utils/trackby';

import { NavItem } from './navigation-item';

/** Navigation list item component. */
@Component({
  selector: 'dartsalesw-nav-list-item',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListItemComponent implements OnInit, OnChanges {

  private readonly router = inject(Router);

  /** Navigation item. */
  @Input()
  public item: NavItem | null = null;

  /** Current url. This input is needed for triggering change detection. */
  @Input()
  public currentUrl: string | null = null;

  /** Navigation item depth. */
  @Input()
  public depth = 0;

  /** Is navigation menu collapsed. */
  @Input()
  public collapsed = false;

  /** Is group expanded. */
  protected isExpanded = false;

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** @inheritdoc */
  public ngOnInit(): void {
    const areChildrenActive = this.areChildrenActive(this.item);
    const isExpandedByDefault = this.item?.expandedByDefault ?? false;
    this.isExpanded = areChildrenActive || isExpandedByDefault;
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentUrl'] && this.areChildrenActive(this.item)) {
      this.isExpanded = true;
    }
  }

  /**
   * Toggle expand group.
   * @param event Mouse click event.
   */
  protected toggleExpand(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Check whether the item is a part of current route.
   * @param item Item.
   */
  protected isItemActive(item: NavItem | null): boolean {
    if (Boolean(item?.disableActiveIndicator) || item?.path == null) {
      return false;
    }
    return this.router.isActive(item.path, {
      paths: 'subset',
      queryParams: 'subset',
      matrixParams: 'ignored',
      fragment: 'ignored',
    });
  }

  /**
   * Is explicit child active.
   * @param item Item.
   */
  protected areChildrenActive(item: NavItem | null): boolean {
    if (item?.children == null || item.children.length === 0) {
      return false;
    }

    const areExplicitChildrenActive = item.children.some(child => this.isItemActive(child));
    const areDeepChildrenActive = item.children.some(child => this.areChildrenActive(child));

    return areExplicitChildrenActive || areDeepChildrenActive;
  }
}
