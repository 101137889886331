import { Injectable } from '@angular/core';

import { EstimatePartStackedPortChild } from '@dartsales/common/core/models/components/estimate-part/estimate-part-stacked-port-child';
import { PortTypeWithCategory } from '@dartsales/common/core/models/components/estimate-part/port-type-with-category';

import { EstimatePartStackedPortChildDto } from '../../dto/components/estimate-part/estimate-part-stacked-port-child.dto';
import { IMapper } from '../../mappers';

/** EstimatePartStackedPortChild entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimatePartStackedPortChildMapper implements IMapper<EstimatePartStackedPortChildDto, EstimatePartStackedPortChild> {

  /** @inheritdoc */
  public fromDto(dto: EstimatePartStackedPortChildDto): EstimatePartStackedPortChild {
    return new EstimatePartStackedPortChild({
      id: dto.id,
      name: dto.name ?? '',
      typeWithCategory: new PortTypeWithCategory({
        categoryType: dto.categoryType,
        type: dto.type,
      }),
    });
  }

  /** @inheritdoc */
  public toDto(data: EstimatePartStackedPortChild): EstimatePartStackedPortChildDto {
    return {
      id: data.id,
      name: data.name,
      type: data.typeWithCategory.type,
      categoryType: data.typeWithCategory.categoryType,
    };
  }
}
