<dartsalesc-base-dialog
  dialogTitle="Edit account data"
  class="dialog"
>
  <form [formGroup]="editAccountDataControlForm">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input
        matInput
        formControlName="email"
      />
      <mat-error>
        <dartsalesc-form-control-validation-message
          [control]="editAccountDataControlForm.controls.email"
        />
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input
        matInput
        formControlName="firstName"
      />
      <mat-error>
        <dartsalesc-form-control-validation-message
          [control]="editAccountDataControlForm.controls.firstName"
        />
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input
        matInput
        formControlName="lastName"
      />
      <mat-error>
        <dartsalesc-form-control-validation-message
          [control]="editAccountDataControlForm.controls.lastName"
        />
      </mat-error>
    </mat-form-field>
  </form>

  <div
    dialogFooter
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      mat-dialog-close="false"
      type="button"
      class="dialog-actions__button"
    >
      Cancel
    </button>

    <button
      mat-flat-button
      type="button"
      color="primary"
      class="dialog-actions__button"
      [dartsalescLoading]="isUpdateUserDataPending$ | async"
      (click)="onSubmit()"
    >
      Update
    </button>
  </div>
</dartsalesc-base-dialog>
