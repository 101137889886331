<div class="dialog">
  <div
    *ngIf="dialogTitle"
    class="dialog__header"
  >
    <hgroup class="header-container">
      <h2 class="headline-5">{{ dialogTitle }}</h2>
      <p
        *ngIf="subtitle"
        class="header-container__subtitle body-2"
      >
        {{ subtitle }}
      </p>
    </hgroup>
    <button
      mat-icon-button
      type="button"
      (click)="onClose()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog__content">
    <ng-content />
  </div>

  <footer class="dialog__footer">
    <ng-content select="[dialogFooter]" />
  </footer>
</div>
