import { BehaviorSubject } from 'rxjs';
import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { ModuleExportParamsFormMapper } from '@dartsales/common/core/services/mappers/forms/export/module-export-params-form.mapper';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { ValidationErrorCode } from '@dartsales/common/core/models/errors/validation-error-code';
import { ViewType } from '@dartsales/common/core/models/view-type';

import { injectModuleExportService } from '../../../../project-dashboard/services/export/module/module-export.service';

import { ExportMultipleModuleFormErrorStateMatcher } from './export-multiple-module-error-state-matcher';

/** Export multiple module component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-export-multiple-module',
  templateUrl: './export-multiple-module.component.html',
  styleUrls: ['../shared-export-dialog-styles.css', './export-multiple-module.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMultipleModuleComponent {

  /** Export module service. */
  protected readonly exportModuleService = injectModuleExportService();

  /** Export format. */
  protected readonly exportFormat = ExportFormat;

  /** Dialog ref. */
  protected readonly dialogRef = inject(DialogRef);

  /** View Type. */
  protected readonly viewType = ViewType;

  private readonly moduleExportParamsFormMapper = inject(ModuleExportParamsFormMapper);

  /** Are exported files loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  /** Config form. */
  protected readonly exportParamsForm = this.moduleExportParamsFormMapper.modelToForm();

  private readonly errorStateMatcher = new ExportMultipleModuleFormErrorStateMatcher(this.exportParamsForm);

  /** Form app error. */
  protected get formAppError(): string | null {
    return this.exportParamsForm.getError(ValidationErrorCode.AppError);
  }

  /** Should show form error. */
  protected get shouldShowFormError(): boolean {
    return this.errorStateMatcher.isErrorState();
  }

  /** Handle form submit. */
  protected onSubmit(): void {
    this.exportParamsForm.markAllAsTouched();

    if (this.exportParamsForm.invalid) {
      return;
    }

    const exportParams = this.moduleExportParamsFormMapper.formToModel(this.exportParamsForm.getRawValue());
    this.exportModuleService.export(exportParams).pipe(
      toggleExecutionState(this.isLoading$),
      untilDestroyed(this),
    )
      .subscribe(() => this.dialogRef.close());
  }
}
