import { EstimateModulesSettingsLocalStorage } from './estimate-modules-settings';
import { PointListLocalStorage } from './point-list-local-storage';

/** Estimate settings local storage. */
export class EstimateSettingsLocalStorage {

  /** Modules. */
  public readonly modules: EstimateModulesSettingsLocalStorage;

  /** Point list. */
  public readonly pointList: PointListLocalStorage;

  /** Metadata. */
  public readonly meta: {

    /** Timestamp. */
    readonly timestamp: number;
  };

  public constructor(data: EstimateSettingsLocalStorageInitArgs) {
    this.modules = data.modules;
    this.pointList = data.pointList;
    this.meta = data.meta;
  }
}

type EstimateSettingsLocalStorageInitArgs = EstimateSettingsLocalStorage;
