import { Injectable } from '@angular/core';

import { CustomPort } from '../../models/custom-port';

import { IMapperFromDto } from './mappers';
import { CustomPortDto } from './dto/custom-port.dto';

/** CustomPort entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomPortMapper implements IMapperFromDto<CustomPortDto, CustomPort> {

  /** @inheritdoc */
  public fromDto(dto: CustomPortDto): CustomPort {
    return new CustomPort({
      id: dto.id,
      name: dto.name,
      categoryName: dto.categoryName,
    });
  }

}
