import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Table header read-only cell component. */
@Component({
  selector: 'dartsalesw-table-header-readonly-cell',
  templateUrl: './table-header-readonly-cell.component.html',
  styleUrls: ['./table-header-readonly-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderReadonlyCellComponent {

  /** Label. */
  @Input()
  public label = '';

  /** Value. */
  @Input()
  public value: string | number | undefined | null = null;
}
