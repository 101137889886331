import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { SortMapper } from '../mappers/sort.mapper';
import { SortDirection } from '../../enums/sort-direction';
import { LaborRole, LaborRoleEdit } from '../../models/resources/labor-role';
import { LaborRoleMapper } from '../mappers/resources/labor-role.mapper';
import { LaborRoleDto } from '../mappers/dto/resources/labor-role/labor-role.dto';
import { LaborRoleFiltersDto } from '../mappers/dto/filters/labor-role-filters.dto';
import { buildHttpParams } from '../../utils/build-http-params';

import { AppUrlsConfig } from './app-urls.config';

/** Labor role API service. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleApiService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly http: HttpClient,
    private readonly sortMapper: SortMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly laborRoleMapper: LaborRoleMapper,
  ) { }

  /**
   * Get list of labor roles.
   * @param organizationId Organization id.
   * @param isRemoved Whether role is removed.
   */
  public getLaborRoles(organizationId: number, isRemoved: boolean | null = false): Observable<LaborRole[]> {
    const filters: LaborRoleFiltersDto = {
      organizationId,
      IsRemoved: isRemoved ?? undefined,
    };
    const params = buildHttpParams({
      ...filters,
      ...this.sortMapper.toDto({ field: 'order', direction: SortDirection.ASC }),
    });
    return this.http.get<PagedListDto<LaborRoleDto>>(this.apiUrls.laborRoleApi.getList, {
      params,
    }).pipe(
      map(result => result.items?.map(item => this.laborRoleMapper.fromDto(item)) ?? []),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create labor role.
   * @param organizationId Organization id.
   * @param data Labor role edit model.
   */
  public createLaborRole(organizationId: number, data: LaborRoleEdit): Observable<void> {
    const createdExpensesRoleDto = this.laborRoleMapper.toDto(data);

    return this.http.post<void>(this.apiUrls.laborRoleApi.create, createdExpensesRoleDto, {
      params: {
        organizationId,
      },
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.laborRoleMapper,
      ),
    );
  }

  /**
   * Update labor role.
   * @param id Labor role id.
   * @param data Labor role edit model.
   */
  public updateLaborRole(id: number, data: LaborRoleEdit): Observable<void> {
    const editDto = this.laborRoleMapper.toDto(data);

    return this.http.put<void>(this.apiUrls.laborRoleApi.update(id), editDto).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.laborRoleMapper,
      ),
    );
  }

  /**
   * Delete labor role.
   * @param id Labor role id.
   */
  public deleteLaborRole(id: number): Observable<void> {
    return this.http.delete<void>(this.apiUrls.laborRoleApi.delete(id)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update labor roles order.
   * @param values Labor roles.
   */
  public updateLaborRolesOrder(values: readonly LaborRole[]): Observable<void> {
    const dtos = values.map((value, index) => this.laborRoleMapper.mapLaborRoleOrderToDto(value, index));
    return this.http.post<void>(this.apiUrls.laborRoleApi.updateOrder, {
      resourceOrders: dtos,
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
