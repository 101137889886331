import { Injectable, inject } from '@angular/core';

import { PointsListModuleProperties } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-module-properties';

import { IMapper } from '../../../mappers';
import { PointsListModulePropertiesDto } from '../../../dto/estimate/modules/points-list/points-list-module.dto';

import { PointsListTotalsSystemMapper } from './points-list-totals-system.mapper';

/** PointsListModule entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListModuleMapper implements IMapper<PointsListModulePropertiesDto, PointsListModuleProperties> {
  private readonly pointsListTotalsSystemMapper = inject(PointsListTotalsSystemMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListModulePropertiesDto): PointsListModuleProperties {
    return new PointsListModuleProperties({
      systemsCount: dto.systemsCount,
      pointsCount: dto.pointsCount,
      subcontractorCost: dto.subcontractorCost,
      materialCost: dto.materialCost,
      laborCost: dto.laborCost,
      directCost: dto.directCost,
      laborHours: dto.laborHours,
      systemTotals: dto.systemsTotals?.map(item => this.pointsListTotalsSystemMapper.fromDto(item)) ?? [],
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListModuleProperties): PointsListModulePropertiesDto {
    return {
      systemsCount: data.systemsCount,
      pointsCount: data.pointsCount,
      subcontractorCost: data.subcontractorCost,
      materialCost: data.materialCost,
      laborCost: data.laborCost,
      directCost: data.directCost,
      laborHours: data.laborHours,
      systemsTotals: data.systemTotals.map(item => this.pointsListTotalsSystemMapper.toDto(item)),
    };
  }
}
