import { Overridable } from '../utils/types/overridable';
import { StrictOmit } from '../utils/types/strict-omit';

import { BulkUpdateOption } from './bulk-update-option';

/**
 * Confirmed overridable values model.
 * Use cases: https://github.com/saritasa-nest/ats-dart-backend/blob/develop/docs/sales/bulk-field-update.md#case-user-approves-new-value.
 */
export class ConfirmedOverridable<T> extends Overridable<T> {

  /** Whether user confirmed override value. */
  public readonly isConfirmed: boolean;

  /**
   * @inheritdoc
   * In case of ConfirmedOverridable override should not be reset on the frontend.
   */
  public override override: T | null;

  public constructor(data: ConfirmedOverridableInitArgs<T>) {
    super(data);
    this.isConfirmed = data.isConfirmed;
    this.override = data.override;
  }
}

type ConfirmedOverridableInitArgs<T> = StrictOmit<ConfirmedOverridable<T>, 'combinedValue'>;

export namespace ConfirmedOverridable {

  /** Create overridable object type from the provided one. */
  export type FromObject<T> = {
    [P in keyof T]: ConfirmedOverridable<T[P]>;
  };

  /**
   * Create confirmed value.
   * @param value Value.
   */
  export function createConfirmedValue<T>(value: T): ConfirmedOverridable<T> {
    return new ConfirmedOverridable({
      initial: value,
      override: null,
      isConfirmed: true,
    });
  }

  /**
   * Create confirmed override.
   * @param item Item.
   * @param override Override value.
   */
  export function createConfirmedOverride<T>(item: ConfirmedOverridable<T>, override: T | null): ConfirmedOverridable<T> {
    return new ConfirmedOverridable({
      initial: item.initial,
      override,
      isConfirmed: true,
    });
  }

  /**
   * Check whether any of passed items have unconfirmed override value.
   * @param items Items.
   */
  export function hasUnconfirmedOverride(items: readonly ConfirmedOverridable<unknown>[]): boolean {
    return items.some(item => !item.isConfirmed);
  }

  /**
   * Get value of overridable or default if the first argument is 'null' or 'undefined'.
   * @param value Overridable to get value from.
   * @param defaultValue Default value.
   */
  export function getValueOrDefault<T>(
    value: ConfirmedOverridable<T> | null | undefined,
    defaultValue: T,
  ): T {
    return value == null ? defaultValue : value.combinedValue;
  }

  /**
   * Get value overridable value with confirmation.
   * @param value Value.
   * @param shouldConfirmUpdates Should confirm updates.
   */
  export function getValueWithConfirmation<T>(
    value: ConfirmedOverridable<T>,
    shouldConfirmUpdates: boolean,
  ): ConfirmedOverridable<T> {
    return shouldConfirmUpdates ? approveUpdatedValue(value) : discardUpdatedValue(value);
  }

  /**
   * Get value overridable value with confirmation.
   * @param value Value.
   * @param option Should confirm updates.
   */
  export function getUpdateValue<T>(
    value: ConfirmedOverridable<T>,
    option: BulkUpdateOption,
  ): ConfirmedOverridable<T> {
    return option === BulkUpdateOption.ResourceValue ? approveUpdatedValue(value) : discardUpdatedValue(value);
  }

  /**
   * Approve updated overridable value.
   * If value is already confirmed then nothing will be changed.
   * @param item Item.
   */
  function approveUpdatedValue<T>(item: ConfirmedOverridable<T>): ConfirmedOverridable<T> {
    return item.isConfirmed ? item : new ConfirmedOverridable({
      initial: item.initial,
      override: null,
      isConfirmed: true,
    });
  }

  /**
   * Discard updated overridable value.
   * @param item Item.
   */
  function discardUpdatedValue<T>(item: ConfirmedOverridable<T>): ConfirmedOverridable<T> {
    return new ConfirmedOverridable({
      initial: item.initial,
      override: item.override,
      isConfirmed: true,
    });
  }
}
