import { PointsListPartTotal } from '../points-list-part-total';

/** Points list part properties. */
export class PointsListPartProperties {
  /** Total calculated values. */
  public readonly total: PointsListPartTotal;

  /** Labor minutes. */
  public readonly laborMinutes: number;

  public constructor(data: PointsListPartPropertiesInitArgs) {
    this.total = data.total;
    this.laborMinutes = data.laborMinutes;
  }
}

type PointsListPartPropertiesInitArgs = PointsListPartProperties;
