import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { ResizableContentContainerServices } from '../../services/resizable-content-container-services.service';
import { ResizableContentContainerComponent } from '../../resizable-content-container.component';

/** Left section of the resizable content container. */
@Component({
  selector: 'dartsalesw-resizable-content-left-section',
  templateUrl: 'resizable-content-left-section.component.html',
  styleUrls: ['resizable-content-left-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableContentLeftSectionComponent {

  /** Title of the section. */
  @Input()
  public sectionTitle = '';

  /** Whether section is expanded or nots. */
  @Input()
  public width: number | null = null;

  /** Section width in pixels. */
  @Input()
  public set isExpanded(value: boolean) {
    this.resizeContentContainerServices.isExpanded$.next(value);
  }

  /** Width change event. */
  @Output()
  public readonly changeWidth = new EventEmitter<number>();

  /** Expanded catalog event. */
  @Output()
  public readonly expandChange = new EventEmitter<boolean>();

  /** 'Open in new' button click. */
  @Output()
  public readonly openInNewButtonClick = new EventEmitter<void>();

  /** Whether the open in new button is displayed. */
  @Input()
  public isOpenInNewButtonDisplayed = false;

  /** Resize content container services. */
  protected readonly resizeContentContainerServices = inject(ResizableContentContainerServices);

  private readonly contentContainer = inject(ResizableContentContainerComponent, { optional: true, host: true });

  public constructor() {
    if (this.contentContainer == null) {
      throw new Error('ResizableContentLeftSectionComponent must be used inside ResizableContentContainerComponent.');
    }
  }

  /**
   * Handles change of the expanded state of the section.
   * @param isExpanded Whether the section is expanded or not.
   */
  protected onSectionExpandedChange(isExpanded: boolean): void {
    this.resizeContentContainerServices.isExpanded$.next(isExpanded);
    this.expandChange.emit(isExpanded);
  }

  /**
   * Get width of the section.
   * @param isExpanded Whether section is expanded or not.
   * @param initialWidth Initial width.
   */
  protected getSectionWidth(isExpanded: boolean, initialWidth: number | null): string {
    return isExpanded ? `${initialWidth ?? this.resizeContentContainerServices.leftSectionMinWidth}px` : 'fit-content';
  }
}
