<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  <ng-container
    *ngIf="percentControl.enabled"
    dartsalescTableCellEditMode
  >
    <input
      #inputElement
      dartsalescTableCellInput
      type="text"
      class="percent-edit-mode"
      [inputMask]="percentMask"
      [readonly]="isReadonly"
      [formControl]="percentControl"
      [errorStateMatcher]="errorStateMatcher"
      (keydown.enter)="inputElement.blur()"
    />
  </ng-container>

  <div
    *ngIf="hideValue"
    dartsalescTableCellViewMode
  >
    <!-- Empty. -->
  </div>

  <div
    *ngIf="!hideValue"
    dartsalescTableCellViewMode
    class="percent-view-mode"
  >
    <span
      class="percent-view-mode__content"
      [title]="percentControl.value | inputMask: percentMask"
    >
      {{ percentControl.value | inputMask: percentMask }}
    </span>
  </div>
  <ng-container
    *ngIf="cellPrefix"
    dartsalescTableCellPrefix
  >
    <ng-content select="[dartsalescTableCellPrefix]" />
  </ng-container>
</dartsalesc-editable-table-cell>
