<ng-container *ngIf="columns[0] as column">
  <ng-container [dartsalescEditableTableCol]="column.name">
    <ng-container
      *dartsalescEditableTableCell="let col; colSpan: columns.length"
    >
      <div class="placeholder">
        {{ placeholder }}
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngFor="let column of columns.slice(1); trackBy: trackByColumn">
  <ng-container [dartsalescEditableTableCol]="column.name">
    <ng-container *dartsalescEditableTableCell="let col; colSpan: 0" />
  </ng-container>
</ng-container>
