import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { createMask } from '@ngneat/input-mask';

import { MAX_GROSS_MARGIN_PERCENT_NUMBER, PERCENT_MASK } from '@dartsales/common/core/utils/constants';

import { CELL_PROVIDERS } from '../../services/cell.provider';

import { PercentNumberCellComponent } from './percent-number-cell.component';

/** Percent number cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-gross-margin-percent-number-cell',
  templateUrl: './percent-number-cell.component.html',
  styleUrls: ['./percent-number-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CELL_PROVIDERS],
})
export class GrossMarginPercentNumberCellComponent extends PercentNumberCellComponent {

  /** @inheritdoc */
  protected override readonly percentMask = createMask({
    ...PERCENT_MASK,
    max: MAX_GROSS_MARGIN_PERCENT_NUMBER,
  });
}
