import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { Observable, debounceTime, filter, tap, map, Subject } from 'rxjs';

/**
 * Debounce time for the cell blur.
 * This is necessary due to multiple renderings in the cells before the document can actual state activity of the element.
 */
const CELL_BLUR_DEBOUNCE_TIME = 50;

/**
 * Table row event service.
 * This service is used as message bus for events in editable table row elements.
 */
@Injectable()
export class TableRowEventsService {

  private readonly cellBlur$ = new Subject<HTMLTableRowElement | null>();

  private readonly cellFocus$ = new Subject<void>();

  /** Row blur. */
  public readonly rowBlur$ = this.createRowBlurStream();

  /** Row focus. */
  public readonly rowFocus$ = this.createRowFocusStream();

  private readonly document = inject(DOCUMENT);

  private readonly cdr = inject(ChangeDetectorRef);

  /**
   * Trigger cell blur.
   * @param cellRowElement Row in which the cell is located.
   */
  public triggerCellBlur(cellRowElement: HTMLTableRowElement | null): void {
    this.cellBlur$.next(cellRowElement);
  }

  /** Trigger cell focus. */
  public triggerCellFocus(): void {
    this.cellFocus$.next();
  }

  private createRowFocusStream(): Observable<void> {
    return this.cellFocus$.pipe(
      debounceTime(CELL_BLUR_DEBOUNCE_TIME),
    );
  }

  private createRowBlurStream(): Observable<void> {
    return this.cellBlur$.pipe(
      debounceTime(CELL_BLUR_DEBOUNCE_TIME),
      filter(tableRowEl => this.shouldEmitRowBlur(tableRowEl)),
      tap(() => this.cdr.detectChanges()),
      map(() => undefined),
    );
  }

  private shouldEmitRowBlur(rowElement: HTMLTableRowElement | null): boolean {
    if (rowElement == null) {
      return false;
    }

    const isRowIncludeActiveElement = rowElement.contains(this.document.activeElement);
    const isActiveElementInEditableCell = this.checkIsActiveElementInEditableCell();

    return !(isRowIncludeActiveElement && isActiveElementInEditableCell);
  }

  private checkIsActiveElementInEditableCell(): boolean {
    const { activeElement } = this.document;

    if (activeElement === null) {
      return false;
    }

    return activeElement.closest('.dartsalesc-cell-wrapper') != null ||
      activeElement.closest('.dartsalesc-cell') != null ||
      activeElement.closest('dartsalesc-editable-table-cell') != null;
  }
}
