import { Directive, HostBinding, Input } from '@angular/core';

/** Scroll anchor directive. */
@Directive({
  selector: '[dartsalescScrollAnchor]',
})
export class ScrollAnchorDirective {
  /** Key of the dataset object where anchor key is stored. */
  public static readonly datasetKey = 'scrollKey';

  /** CSS class of scroll anchor element. */
  public static readonly cssClass = 'scroll-anchor';

  /** Data attribute. */
  public static readonly dataAttribute = 'data-scroll-key';

  /** Key (identifier) of the element. We need it to be able to find element in DOM to scroll to it. */
  @Input()
  @HostBinding(`attr.${ScrollAnchorDirective.dataAttribute}`)
  public dartsalescScrollAnchor = '';

  /** Scroll anchor CSS class. */
  @HostBinding(`class.${ScrollAnchorDirective.cssClass}`)
  protected readonly cssClass = true;

  /**
   * Get query selector for scroll anchor element.
   * @param scrollAnchorKey Scroll anchor key.
   */
  public static getQuerySelector(scrollAnchorKey: string): string {
    return `.${ScrollAnchorDirective.cssClass}[${ScrollAnchorDirective.dataAttribute}="${scrollAnchorKey}"]`;
  }
}
