/** Interface for trackById function. */
type ObjectWithId = {

  /** Id. */
  readonly id: number | string;
};

/**
 * Factory for trackBy function that allows Angular track the value by provided prop name.
 * @param propName Property by which you want to track the item.
 */
export function createTrackByFunction<
  T extends unknown,
  P extends keyof T = keyof T,
>(propName: P): (i: number, obj: T) => T[P] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (_, obj) => (obj as any)[propName];
}

/**
 * Previous implementation of trackById function was lacking precise type inference in webstorm.
 */
function _trackById<T extends ObjectWithId>(): ReturnType<typeof createTrackByFunction<T>> {
  return createTrackByFunction<T>('id');
}

/**
 * Track by function for angular ngFor directive.
 * @returns Unique identifier.
 */
export const trackById = _trackById();

/**
 * Track by function for Angular `ngFor` directive if iterables don't have a unique key.
 * @param index Item index.
 */
export const trackByIndex = (index: number): number => index;

/**
 * Track by function for Angular `ngFor` directive if iterables don't have a unique key
 * and `trackByIndex` utility can't be used.
 * Important: Items should be unique entities which doesn't equal each other on shallow compare.
 * @param _index Item index.
 * @param item Item.
 */
export const trackByItem = <T>(_index: number, item: T): T => item;
