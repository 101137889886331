import { Injectable } from '@angular/core';

import { PaletteManufacturer } from '@dartsales/common/core/models/components/palette/palette-manufacturer';

import { PaletteManufacturerDto } from '../../dto/components/palette/palette-manufacturer.dto';
import { IMapperFromDto } from '../../mappers';

/** Palette manufacturer mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteManufacturerMapper implements IMapperFromDto<PaletteManufacturerDto, PaletteManufacturer> {

  /** @inheritdoc */
  public fromDto(dto: PaletteManufacturerDto): PaletteManufacturer {
    return new PaletteManufacturer({
      name: dto.name,
    });
  }
}
