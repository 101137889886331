import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { FilterOperatorType } from '../../models/filters/multi-condition-filters/filter-operator-type';
import { TabFilters } from '../../models/filters/tab-filter';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { PagedList } from '../../models/list-utilities/paged-list';
import { ExploreProjectsItem } from '../../models/project/explore-projects-item';
import { ExploreProjectsItemDto } from '../mappers/dto/explore-projects-item.dto';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { ExploreProjectsItemMapper } from '../mappers/explore-projects-item.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { QueryFilterParamsMapper } from '../mappers/query-filter-params.mapper';
import { Project } from '../../models/project/project';
import { ProjectMapper } from '../mappers/project/project.mapper';
import { ProjectDto } from '../mappers/dto/project/project.dto';
import { Organization } from '../../models/organization';
import { CloneProjectResultDto } from '../mappers/dto/clone-project-result.dto';
import { buildHttpParams } from '../../utils/build-http-params';
import { CreateTemplate } from '../../models/project/create-template';
import { TemplateMapper } from '../mappers/project/template.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Templates API service. */
@Injectable({
  providedIn: 'root',
})
export class TemplatesApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly exploreProjectsItemMapper = inject(ExploreProjectsItemMapper);

  private readonly listMapper = inject(PagedListMapper);

  private readonly queryFilterMapper = inject(QueryFilterParamsMapper);

  private readonly projectMapper = inject(ProjectMapper);

  private readonly templateMapper = inject(TemplateMapper);

  /**
   * Obtain a template list.
   * @param options Fetch options.
   */
  public getList(options: FetchListOptions<TabFilters>): Observable<PagedList<ExploreProjectsItem>> {
    const organizationIds = options.filter.organizationId;
    const filtersDto = this.queryFilterMapper.toDtoWithPaginationParams(options, organizationIds.length ? [
      {
        propertyName: 'projectOrganizationId',
        operatorType: FilterOperatorType.IsOneOf,
        values: organizationIds.map(id => String(id)),
      },
    ] : []);
    const params = buildHttpParams(filtersDto);

    return this.http.get<PagedListDto<ExploreProjectsItemDto>>(this.apiUrls.templatesApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.exploreProjectsItemMapper,
        options.pagination,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get projects by organization ID.
   * @param organizationId Organization ID.
   */
  public getProjectsByOrganizationId(organizationId: Organization['id']): Observable<ExploreProjectsItem[]> {
    const params = this.queryFilterMapper.toDto([
      {
        propertyName: 'projectOrganizationId',
        operatorType: FilterOperatorType.IsOneOf,
        values: [organizationId.toString()],
      },
    ]);

    return this.http.get<PagedListDto<ExploreProjectsItemDto>>(this.apiUrls.templatesApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.exploreProjectsItemMapper,
        undefined,
      )),
      map(result => [...result.items]),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Obtain a template by its ID.
   * @param id ID.
   */
  public getById(id: Project['id']): Observable<Project> {
    return this.http.get<ProjectDto>(this.apiUrls.templatesApi.getById(id)).pipe(
      map(dto => this.projectMapper.fromDto(dto, id)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Initiate a background operation for create new template from estimate.
   * @param id Project ID.
   * @param data Data for creating template.
   * @returns Background operation UUID.
   */
  public create(id: Project['id'], data: CreateTemplate): Observable<string> {
    const editDto = this.templateMapper.toCreateDto(data);

    return this.http.post<CloneProjectResultDto>(this.apiUrls.projectsApi.clone(id), editDto, {
      params: { id, isTemplate: true },
    }).pipe(
      map(result => result.duplicationJobId),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.projectMapper.mapShortValidationErrorFromDto.bind(this.projectMapper),
      ),
    );
  }

  /**
   * Delete a template.
   * @param id Template ID.
   */
  public delete(id: Project['id']): Observable<void> {
    return this.http.delete<void>(this.apiUrls.templatesApi.delete(id)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
