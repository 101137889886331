<input
  #inputElement
  dartsalescCellEditorInput
  type="text"
  class="input"
  [ngModel]="value"
  [placeholder]="placeholder"
  [errorStateMatcher]="errorStateMatcher"
  (ngModelChange)="valueChange.emit($event)"
  (keydown.enter)="inputElement.blur()"
/>
