import { Injectable, inject } from '@angular/core';

import { TermSubCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-subcategory-tree-item';

import { IMapperToDto } from '../mappers';
import { SubCategoryExportParamsDto } from '../dto/export/subcategory-export-params.dto';

import { EstimateServiceExportConfigMapper } from './service-export-config.mapper';

/** Sub-category export config mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubCategoryExportConfigMapper implements IMapperToDto<SubCategoryExportParamsDto, TermSubCategoryTreeItem> {

  private readonly servicesExportConfigMapper = inject(EstimateServiceExportConfigMapper);

  /** @inheritdoc */
  public toDto(data: TermSubCategoryTreeItem): SubCategoryExportParamsDto {
    return {
      subCategoryId: data.value.subCategoryId,
      services: data.children.map(service => this.servicesExportConfigMapper.toDto(service)),
    };
  }
}
