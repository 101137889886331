<ng-container *ngrxLet="notifications$ | async as messages">
  <button
    *ngrxLet="getMessagesCount(messages) as notificationsCount"
    mat-icon-button
    type="button"
    class="notifications-button"
    matBadgeSize="small"
    [matBadge]="notificationsCount"
    [matBadgeHidden]="!messages?.length"
    [matMenuTriggerFor]="notificationsMenu"
  >
    <mat-icon fontSet="material-icons-outlined">notifications</mat-icon>
  </button>
</ng-container>

<mat-menu
  #notificationsMenu
  class="notifications-menu"
  (closed)="onNotificationsPanelClose()"
>
  <div
    *ngIf="notifications$ | async as messages"
    class="notifications"
  >
    <ng-container *ngIf="messages.length; else emptyNotifications">
      <p
        *ngFor="let message of messages; trackBy: trackByIndex"
        class="notifications__message"
      >
        {{ message.message }}
      </p>
    </ng-container>
    <ng-template #emptyNotifications>
      <span>No new messages.</span>
    </ng-template>
  </div>
</mat-menu>
