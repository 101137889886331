import { Injectable, inject } from '@angular/core';

import { BaseModulePropertiesList, ModulePropertiesList } from '@dartsales/common/core/models/estimate/module-properties-list';

import { IMapper } from '../mappers';
import { BaseModulePropertiesListDto, ModulePropertiesListDto } from '../dto/estimate/modules-properties-list.dto';

import { ModulePropertiesMapper } from './modules/module-properties.mapper';
import { SubcontractorModulePropertiesMapper } from './modules/subcontractor/subcontractor-module-properties.mapper';
import { PointsListModuleMapper } from './modules/points-list/points-list-module.mapper';
import { LaborModulePropertiesMapper } from './modules/labor/labor-module-properties.mapper';
import { MaterialModulePropertiesMapper } from './modules/material/material-module-properties.mappert';

/** Modules properties list mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModulesPropertiesListMapper implements IMapper<ModulePropertiesListDto, ModulePropertiesList> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly subcontractorPropertiesMapper = inject(SubcontractorModulePropertiesMapper);

  private readonly laborPropertiesMapper = inject(LaborModulePropertiesMapper);

  private readonly materialPropertiesMapper = inject(MaterialModulePropertiesMapper);

  private readonly pointListPropertiesMapper = inject(PointsListModuleMapper);

  /** @inheritdoc */
  public fromDto(dto: ModulePropertiesListDto): ModulePropertiesList {
    return new ModulePropertiesList({
      ...this.fromBaseDto(dto),
      pointsList: this.pointListPropertiesMapper.fromDto(dto.pointListModule.properties),
    });
  }

  /** @inheritdoc */
  public toDto(data: ModulePropertiesList): ModulePropertiesListDto {
    return {
      ...this.toBaseDto(data),
      pointListModule: {
        properties: this.pointListPropertiesMapper.toDto(data.pointsList),
      },
    };
  }

  /**
   * Map from base module properties list DTO.
   * @param dto Base module properties list DTO.
   */
  public fromBaseDto(dto: BaseModulePropertiesListDto): BaseModulePropertiesList {
    return new BaseModulePropertiesList({
      custom: this.propertiesMapper.fromDto(dto.customModule.properties),
      expenses: this.propertiesMapper.fromDto(dto.perDiemModule.properties),
      labor: this.laborPropertiesMapper.fromDto(dto.laborModule.properties),
      material: this.materialPropertiesMapper.fromDto(dto.materialModule.properties),
      subcontractor: this.subcontractorPropertiesMapper.fromDto(dto.subcontractorModule.properties),
    });
  }

  /**
   * Map base module properties list to DTO.
   * @param data Base module properties list.
   */
  public toBaseDto(data: BaseModulePropertiesList): BaseModulePropertiesListDto {
    return {
      customModule: {
        properties: this.propertiesMapper.toDto(data.custom),
        data: undefined,
      },
      perDiemModule: {
        properties: this.propertiesMapper.toDto(data.expenses),
        data: undefined,
      },
      laborModule: {
        properties: this.laborPropertiesMapper.toDto(data.labor),
        data: undefined,
      },
      subcontractorModule: {
        properties: this.subcontractorPropertiesMapper.toDto(data.subcontractor),
        data: undefined,
      },
      materialModule: {
        properties: this.materialPropertiesMapper.toDto(data.material),
        data: undefined,
      },
    };
  }
}
