import { ModuleType } from '../../enums/module-type';
import { StrictOmit } from '../../utils/types/strict-omit';
import { TermTreeItem } from '../estimate-services/term-tree-item';
import { EstimateId } from '../estimate/estimate';

import { ExportParams } from './export-params';

/** Estimate view export params. */
export class EstimateViewExportParams {

  /** Module types. */
  public readonly moduleTypes: readonly ModuleType[];

  /** Terms. */
  public readonly terms: readonly TermTreeItem[];

  public constructor(data: EstimateViewExportParamsInitArgs) {
    this.moduleTypes = data.moduleTypes;
    this.terms = data.terms;
  }
}

type EstimateViewExportParamsInitArgs = EstimateViewExportParams;

/** Estimate type. */
export enum EstimateType {
  Base = 'Base Estimate',
  Final = 'Final Estimate',
  Alternate = 'Final Alternate',
  ChangeOrder = 'Change Order',
}

/** Estimate multiple export params. */
export class EstimateMultipleExportParams extends ExportParams {

  /** Estimate ID. */
  public readonly estimateId: EstimateId;

  /** Modules customer view. */
  public readonly customerView: EstimateViewExportParams | null;

  /** Modules internal view. */
  public readonly internalView: EstimateViewExportParams | null;

  public constructor(data: EstimateMultipleExportConfigInitArgs) {
    super(data);
    this.estimateId = data.estimateId;
    this.customerView = data.customerView;
    this.internalView = data.internalView;
  }
}

type EstimateMultipleExportConfigInitArgs = EstimateMultipleExportParams;
export type EstimateMultipleExportParamsWithoutEstimateId = StrictOmit<EstimateMultipleExportParams, 'estimateId'>;
