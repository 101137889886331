import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { NgControl, NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { TableCellControlErrorStateMatcher } from '@dartsales/common/shared/components/editable-table-cell/utils/table-cell-control-error-state-matcher';

/**
 * Tags table cell component.
 * Current implementation is a workaround.
 * Enter values splitted by commas is not convenient in terms of UX.
 * TODO (Danil K): https://github.com/saritasa-nest/ats-dart-frontend-sales/issues/489.
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-tags-table-cell',
  templateUrl: './tags-table-cell.component.html',
  styleUrls: ['./tags-table-cell.component.css'],

  // We have to use Default change detection strategy to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TagsTableCellComponent extends SimpleValueAccessor<string[]> implements OnInit {
  /** Is readonly. */
  @Input()
  public isReadonly = false;

  private readonly ngControl = inject(NgControl);

  private readonly fb = inject(NonNullableFormBuilder);

  /** Error state matcher. */
  protected readonly errorStateMatcher = new TableCellControlErrorStateMatcher(this.ngControl);

  /** Form control. */
  protected readonly formControl = this.fb.control<string>('');

  public constructor() {
    super();
    this.ngControl.valueAccessor = this;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: string[] | null): void {
    this.formControl.setValue(value?.join(', ') ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        const tags = value.split(',').map(item => item.trim());
        this.controlValue = tags;
      });
  }
}
