import { Injectable, inject } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, ignoreElements, share } from 'rxjs/operators';

import { SuccessNotificationComponent, SuccessNotificationData } from 'projects/web/src/app/features/shared/components/success-notification/success-notification.component';

/** Default messages of snackbar notifications service. */
export const SNACKBAR_DEFAULT_MESSAGES = {
  notImplemented: 'Not implemented yet.',
} as const;

export const DEFAULT_DURATION_MS = 3000;
export const LONG_DURATION_MS = 10000;

type SnackbarNotification = {

  /** Message. */
  readonly message: string;

  /** Duration of notification message. */
  readonly duration: number;

  /** Whenever snackbar is auto-dismissed after specified duration the callback is called. */
  readonly onDismiss: () => void;
};

/** Snackbar notification service. */
@Injectable({ providedIn: 'root' })
export class SnackbarNotificationsService {

  /** Whenever the stream is observed, snackbar messages are displayed. */
  public readonly notification$: Observable<never>;

  private readonly notificationSubject$ = new Subject<SnackbarNotification>();

  private readonly snackBar = inject(MatSnackBar);

  public constructor() {
    this.notification$ = this.initializeNotificationStream();
  }

  /**
   * Notify a user with a message.
   * @param message Human-readable message.
   * @param duration Duration of notification message.
   */
  public notify(message: string, duration: number = DEFAULT_DURATION_MS): Promise<void> {
    const shouldNotify = this.notificationSubject$.observed;
    if (shouldNotify) {
      return new Promise(resolve => {
        this.notificationSubject$.next({
          message,
          duration,
          onDismiss: resolve,
        });
      });
    }
    return Promise.reject(new Error('There is no active subscribers for notifications.'));
  }

  /**
   * Open a success notification.
   * @param config Config.
   * @param duration Duration in ms.
   */
  public openSuccessNotification(config: SuccessNotificationData, duration = DEFAULT_DURATION_MS): void {
    this.snackBar.openFromComponent<SuccessNotificationComponent, SuccessNotificationData>(
      SuccessNotificationComponent,
      {
        data: config,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration,
      },
    );
  }

  private initializeNotificationStream(): Observable<never> {
    return this.notificationSubject$.pipe(
      switchMap(({ message, duration, onDismiss }) => {
        const snackBarRef = this.snackBar.open(
          message,
          '',
          {
            duration,
            horizontalPosition: 'left',
          },
        );
        return snackBarRef.afterDismissed().pipe(finalize(onDismiss));
      }),
      share(),
      ignoreElements(),
    );
  }
}
