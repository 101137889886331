import { OptionSelect } from '../models/option-select';
import { enumToArray } from '../utils/enum-to-array';

/** Part diagram type. */
export enum PartDiagramType {
  Panel = 'Panel',
  Schematic = 'Schematic',
  FloorPlan = 'Floorplan',
  Riser = 'Riser',
  Wiring = 'Wiring',
}

/** Namespace related to PartDiagramType. */
export namespace PartDiagramType {
  const TO_TITLE_MAP: Readonly<Record<PartDiagramType, string>> = {
    [PartDiagramType.Riser]: 'Riser',
    [PartDiagramType.FloorPlan]: 'Floorplan',
    [PartDiagramType.Wiring]: 'Wiring',
    [PartDiagramType.Panel]: 'Panel',
    [PartDiagramType.Schematic]: 'Schematic',
  };

  /**
   * Converts a certain part diagram type to readable title.
   * @param value Part diagram type.
   */
  export function toReadable(value: PartDiagramType): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all part diagram types. */
  export function toArray(): PartDiagramType[] {
    return enumToArray(PartDiagramType);
  }

  /**
   * Gets list of part diagram types available for selection.
   * 'Wiring' is included inside part by default.
   * So we should not allow user to select it manually.
   */
  export function toSelectableTypesArray(): PartDiagramType[] {
    return enumToArray(PartDiagramType).filter(type => type !== PartDiagramType.Wiring);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: PartDiagramType): PartDiagramType {
    return item;
  }

  /** Get select options list. */
  export function toSelectOptions(): OptionSelect<PartDiagramType>[] {
    return toSelectableTypesArray()
      .map(status => ({
        label: PartDiagramType.toReadable(status),
        value: status,
      }));
  }
}
