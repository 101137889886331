import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/** Expand button component. */
@Component({
  selector: 'dartsalesw-horizontal-expand-button',
  templateUrl: './horizontal-expand-button.component.html',
  styleUrls: ['./horizontal-expand-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalExpandButtonComponent {

  /** Is expanded. */
  @Input()
  public isExpanded = false;

  /** Button click. */
  @Output()
  public readonly buttonClick = new EventEmitter<void>();
}
