import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewChild, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { NgClass } from '@angular/common';

import { NUMBER_MASK } from '@dartsales/common/core/utils/constants';
import { HorizontalAlignment } from '@dartsales/common/core/utils/types/horizontal-alignment';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '../../services/cell.provider';
import { TableRowEventsService } from '../../../editable-table/services/table-row-events.service';
import { TableCellInputDirective } from '../../../editable-table-cell/directives/table-cell-input.directive';
import { EditableTableColDirective } from '../../../editable-table/directives/editable-table-column.directive';
import { EditableTableComponent } from '../../../editable-table/editable-table.component';

/** Overridable number cell component. */
@Component({
  selector: 'dartsalesc-overridable-number-cell',
  templateUrl: './overridable-number-cell.component.html',
  styleUrls: ['./overridable-number-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...CELL_PROVIDERS],
})
export class OverridableNumberCellComponent extends AbstractCellFormControlComponent<ConfirmedOverridable<number>> implements OnInit {

  /** Input mask. */
  @Input()
  public mask = NUMBER_MASK;

  /** Placeholder. */
  @Input()
  public placeholder = '0';

  /** Is highlighted. */
  @HostBinding('class.highlighted')
  protected get isHighlighted(): boolean {
    return this.controlValue?.override != null && this.controlValue.isConfirmed;
  }

  /** Renderer element. */
  @ViewChild(TableCellInputDirective)
  protected readonly rendererElement?: TableCellInputDirective;

  /** Form control. */
  protected readonly formControl = inject(NonNullableFormBuilder).control<number>(0);

  /** Cell value position. */
  protected readonly cellAlignment = this.getCellAlignment();

  private readonly rowEventsService = inject(TableRowEventsService, { optional: true });

  /** @inheritdoc */
  public override writeValue(value: ConfirmedOverridable<number> | null): void {
    this.formControl.setValue(value?.combinedValue ?? 0, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }

    super.setDisabledState(isDisabled);
  }

  /** @inheritdoc */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(value => {
      if (value !== null && this.controlValue) {
        this.controlValue = new ConfirmedOverridable({
          initial: this.controlValue.initial,
          override: value,
          isConfirmed: true,
        });
      }
    });
  }

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      this.controlValue?.override !== null &&
      this.controlValue?.isConfirmed === true
    );
  }

  /** Tooltip message. */
  protected get tooltipMessage(): string {
    if (this.controlValue?.initial !== null && this.controlValue?.initial !== undefined) {
      return `Reset to value: ${this.controlValue.initial.toString()}`;
    }
    return `Reset value`;
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new ConfirmedOverridable({ initial: this.controlValue.initial, override: null, isConfirmed: true });
      this.controlValue = clearedOverride;
      this.rowEventsService?.triggerCellBlur(this.rendererElement?.tableRowElement ?? null);
    }
  }

  private getCellAlignment(): HorizontalAlignment {
    const tableColumn = inject(EditableTableColDirective, { optional: true });
    const cellAlign = inject(EditableTableComponent, { optional: true })?.columns?.find(column => column.name === tableColumn?.name)?.align;

    return cellAlign ?? 'left';
  }

  /** Get cell CSS class. */
  protected getCellCssClasses(): NgClass['ngClass'] {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      'cell_align-left': this.cellAlignment === 'left',
      'cell_align-right': this.cellAlignment === 'right',
      'cell_align-center': this.cellAlignment === 'center',
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  /** Get reset button CSS class. */
  protected getResetButtonCssClasses(): NgClass['ngClass'] {
    const prefix = this.cellAlignment === 'right';
    const suffix = this.cellAlignment === 'left' || this.cellAlignment === 'center';
    return {
      prefix,
      suffix,
    };
  }
}
