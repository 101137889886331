<div class="avatar">
  <img
    *ngIf="avatarUrl; else emptyAvatar"
    class="avatar__image"
    alt="organization logo"
    [src]="avatarUrl"
  />
</div>

<ng-template #emptyAvatar>
  <img
    class="avatar__empty-avatar"
    alt="organization logo"
    src="/assets/icons/acme.svg"
  />
</ng-template>
