import { AMOUNT_ROUND_FRACTION_DIGITS, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE } from '../../utils/constants';
import { compareRoundNumbers } from '../../utils/rounds';

/** Percent-amount link. */
export class AmountPercent {

  /** Amount in absolute units. */
  public readonly amount: number;

  /** Amount in percent. */
  public readonly percent: number;

  public constructor(initArgs?: AmountPercentValues) {
    this.amount = initArgs?.amount ?? 0;
    this.percent = initArgs?.percent ?? 0;
  }

  /**
   * Check if two amount percent values are equal after rounded.
   * @param a First amount percent value.
   * @param b Second amount percent value.
   * @param amountRoundFractionDigits Fraction digits for round amount.
   * @param percentRoundFractionDigits Fraction digits for round percents.
   */
  public static checkIsEqualWithRound(
    a: AmountPercent,
    b: AmountPercent,
    amountRoundFractionDigits = AMOUNT_ROUND_FRACTION_DIGITS,
    percentRoundFractionDigits = PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE,
  ): boolean {
    const isRoundAmountEqual = compareRoundNumbers(a.amount, b.amount, amountRoundFractionDigits);
    const isRoundPercentEqual = compareRoundNumbers(a.percent, b.percent, percentRoundFractionDigits);
    return isRoundAmountEqual && isRoundPercentEqual;
  }
}

/** Amount percent values. */
export type AmountPercentValues = Partial<AmountPercent>;
