<mat-toolbar class="toolbar">
  <div class="toolbar__left-section">
    <a
      class="app-logo"
      [routerLink]="routePaths.welcome.url"
    >
      <img
        class="app-logo__image"
        src="/assets/images/division25-logo.svg"
        alt="Logo"
      />
    </a>
  </div>
  <div></div>
  <div class="toolbar__right-section">
    <dartsalesw-notifications-button class="toolbar__notifications-button" />
    <dartsalesw-profile-button />
  </div>
</mat-toolbar>
