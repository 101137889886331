import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { EstimateMultipleExportParamsFormControls, EstimateMultipleExportParamsFormValues } from '@dartsales/common/core/models/forms/export/estimate-multiple-export-params-form';
import { EstimateMultipleExportParamsWithoutEstimateId } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';

import { IFormToModel } from '../form-mappers';

import { EstimateExportParamsFormValidators } from './estimate-multiple-export-params-form.validators';

/** Export estimate multiple params form mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateMultipleExportParamsFormMapper implements
  IFormToModel<EstimateMultipleExportParamsFormControls, EstimateMultipleExportParamsWithoutEstimateId> {

  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public modelToForm(): FormGroup<EstimateMultipleExportParamsFormControls> {
    return this.fb.group<EstimateMultipleExportParamsFormControls>({
      exportFormat: this.fb.control(ExportFormat.Csv, Validators.required),
      customerView: this.fb.control(null),
      internalView: this.fb.control(null),
    }, {
      validators: [EstimateExportParamsFormValidators.exportedDataSelected],
    });
  }

  /** @inheritdoc */
  public formToModel(formValues: EstimateMultipleExportParamsFormValues): EstimateMultipleExportParamsWithoutEstimateId {
    return formValues;
  }
}
