import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogData } from './confirm-dialog-data';

/** Confirmation dialog component. */
@Component({
  selector: 'dartsalesc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

  /** Dialog data. */
  private readonly data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);

  /** Title. */
  protected readonly title = this.data.title ?? 'Confirm your action';

  /** Text. */
  protected readonly text = this.data.text ?? 'Are you sure you want to proceed the action?';

  /** Confirm button text. */
  protected readonly confirmButtonText = this.data.confirmButtonText ?? 'Confirm';

  /** Cancel button text. */
  protected readonly cancelButtonText = this.data.cancelButtonText ?? 'Cancel';

  /** Confirm button palette. */
  protected readonly confirmButtonPalette = this.data.confirmButtonPalette ?? 'primary';
}
