import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { createTrackByFunction } from '@dartsales/common/core/utils/trackby';

/** Expansion action. */
export type ToggleableExpansionAction<TType = unknown> = {

  /** Label. */
  readonly label: string;

  /** Row type. */
  readonly type: TType;

  /** Click handler. */
  readonly click: () => void;
};

/**
 * Extension actions.
 * TODO (DART-1433): Rename to `ExpansionActionsComponent` when everything is rewritten from current `ExpansionActionsComponent`.
 */
@Component({
  selector: 'dartsalesw-toggleable-expansion-actions',
  templateUrl: './toggleable-expansion-actions.component.html',
  styleUrls: ['./toggleable-expansion-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleableExpansionActionsComponent<TType = unknown> {
  /** Actions. */
  @Input()
  public actions: readonly ToggleableExpansionAction<TType>[] = [];

  /** Active type. */
  @Input()
  public activeType: TType | null = null;

  /** Track by action. */
  protected readonly trackByAction = createTrackByFunction<ToggleableExpansionAction>('label');
}
