<a
  *ngIf="item"
  mat-list-item
  class="list-item"
  [class.active]="isItemActive(item)"
  [class.are-children-active]="areChildrenActive(item)"
  [class.has-children]="item.children?.length"
  [class.expanded]="isExpanded"
  [class.top-level]="depth === 0"
  [class.collapsed]="collapsed"
  [routerLink]="item.path"
>
  <div
    matListItemIcon
    class="list-item__offset"
    [class.no-icon]="!item.iconName && !item.svgIcon"
    [ngStyle]="{ 'margin-left': depth * 12 + 'px' }"
  ></div>
  <ng-container *ngIf="item.iconName || item.svgIcon">
    <ng-template
      [ngTemplateOutlet]="item.customNavItemTemplate || defaultNavItem"
      [ngTemplateOutletContext]="{
        $implicit: item
      }"
    ></ng-template>
    <ng-template #defaultNavItem>
      <mat-icon
        matListItemIcon
        fontSet="material-icons-outlined"
        class="list-item__icon"
        [svgIcon]="item.svgIcon ?? ''"
        [class.icon-with-offset]="depth > 0"
      >
        {{ item.iconName }}
      </mat-icon>
    </ng-template>
  </ng-container>

  <div class="list-item__info info">
    <div
      class="info__label"
      [title]="item.label"
    >
      {{ item.label }}
    </div>
    <div
      *ngIf="item.subTitle"
      class="info__subtitle"
    >
      {{ item.subTitle }}
    </div>
  </div>
  <button
    *ngIf="!collapsed && item?.children?.length"
    mat-icon-button
    type="button"
    (click)="toggleExpand($event)"
  >
    <span class="list-item__arrow">
      <mat-icon>expand_more</mat-icon>
    </span>
  </button>
</a>

<div
  class="list-item__children"
  [class.expanded]="isExpanded && !collapsed"
>
  <dartsalesw-nav-list-item
    *ngFor="let child of item?.children; trackBy: trackByIndex"
    [item]="child"
    [depth]="depth + 1"
    [currentUrl]="currentUrl"
  />
</div>
