import { Pipe, PipeTransform } from '@angular/core';

/** Input mask pipe. */
@Pipe({
  name: 'inputMask',
})
export class InputMaskPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(input: string | number, mask: Inputmask.Options): string {
    if (typeof input === 'number') {
      return Inputmask.format(input.toString(), mask);
    }
    return Inputmask.format(input, mask);
  }

}
