import { PortCategoryType } from '@dartsales/common/core/enums/port-category-type';
import { PortType } from '@dartsales/common/core/enums/port-type';
import { OverridableEstimatePartPort } from '@dartsales/common/core/models/components/estimate-part/estimate-part-port';

/** Points list system ports total. */
export class PointsListSystemPortsTotal {

  /** Port category type. */
  public readonly categoryType: PortCategoryType;

  /** Port type.  */
  public readonly portType: PortType;

  /** Custom ID (UUID). Only custom ports can have it, in other cases value is 'null'. */
  public readonly customId: OverridableEstimatePartPort['customId'];

  /** Port quantity. */
  public readonly quantity: number;

  public constructor(data: PointsListSystemPortsTotalInitArgs) {
    this.categoryType = data.categoryType;
    this.portType = data.portType;
    this.customId = data.customId;
    this.quantity = data.quantity;
  }
}

type PointsListSystemPortsTotalInitArgs = PointsListSystemPortsTotal;
