/** Component attachment. */
export class ComponentAttachment {

  /** ID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** File name. It's optional field. */
  public readonly fileName?: string;

  /** Mime type. It's optional field. */
  public readonly mimeType?: string;

  public constructor(data: ComponentAttachmentInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.fileName = data.fileName;
    this.mimeType = data.mimeType;
  }
}
type ComponentAttachmentInitArgs = ComponentAttachment;
