import { trigger, state, style, transition, animate } from '@angular/animations';
import { EXPANSION_PANEL_ANIMATION_TIMING } from '@angular/material/expansion';

/**
 * Rotate 90deg animation.
 * Https://github.com/angular/components/blob/main/src/material/expansion/expansion-animations.ts.
 */
export const rightAngleRotateAnimation = trigger('rightAngleIndicatorRotate', [
  state('collapsed, void', style({ transform: 'rotate(0deg)' })),
  state('expanded', style({ transform: 'rotate(90deg)' })),
  transition(
    'expanded <=> collapsed, void => collapsed',
    animate(EXPANSION_PANEL_ANIMATION_TIMING),
  ),
]);
