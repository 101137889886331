<div class="app-content">
  <dartsalesw-project-sidebar
    [isSidebarOpened]="(isSidebarOpened$ | async) ?? true"
  >
    <dartsalesw-project-toolbar (toolbarButtonClick)="toggleSidebar()" />
    <main
      cdkScrollable
      class="app-page-content"
    >
      <router-outlet />
    </main>
  </dartsalesw-project-sidebar>
</div>
