import { Injectable } from '@angular/core';

import { isInvalidDate, transformToClientDate, transformToServerDate } from '../../utils/date-time';

type IsDate<
  TInput extends (Date | string | null),
> = TInput extends Date
  ? Date
  : TInput extends string
    ? Date | null
    : null;

type IsString<
  TInput extends (Date | null | undefined),
> = TInput extends Date
  ? string
  : TInput;

/** Mapper for Date.*/
@Injectable({ providedIn: 'root' })
export class DateMapper {

  /** @inheritdoc */
  public fromDto<T extends(Date | string | null)>(dto: T): IsDate<T> {
    return DateMapper.parseDate(dto);
  }

  /**
   * Map date to date without time DTO.
   * @param date Input.
   * @returns Parsed date or 'null' if date can't be parsed.
   */
  public toDateDto<T extends(Date | null | undefined)>(date: T): IsString<T> {
    return DateMapper.getDateString(date);
  }

  /**
   * Map date to date with time DTO.
   * @param date Input.
   * @returns Parsed date or 'null' if date can't be parsed.
   */
  public toDateTimeDto<T extends(Date | null | undefined)>(date: T): IsString<T> {
    return DateMapper.getDateTimeString(date);
  }

  /**
   * Parse date from input.
   * @param input Input.
   * @param ignoreUserTimezone Whether to ignore user timezone or not.
   * @returns Parsed date or 'null' if date can't be parsed.
   */
  public static parseDate<T extends(Date | string | null)>(input: T, ignoreUserTimezone = false): IsDate<T> {
    if (input === null) {
      return null as IsDate<T>;
    }

    let result: Date;
    if (typeof input === 'string') {
      const date = new Date(input);
      if (isInvalidDate(date)) {
        return null as IsDate<T>;
      }
      result = date;
    } else {
      result = input;
    }

    if (ignoreUserTimezone) {
      return transformToClientDate(result) as IsDate<T>;
    }
    return result as IsDate<T>;
  }

  /**
   * Get date without time string from input.
   * @param date Input.
   * @returns Transformed date string or the input itself it it's empty (i.e. 'null/undefined').
   */
  public static getDateString<T extends(Date | null | undefined)>(date: T): IsString<T> {
    const result = DateMapper.getDateTimeString(date);

    if (result == null) {
      return result;
    }

    // Get only date information from ISO formatted string (in ISO format).
    return result.split('T')[0] as IsString<T>;
  }

  /**
   * Get date with time string from input (in ISO format).
   * @param date Input.
   * @param ignoreUserTimezone Whether to ignore user timezone or not.
   * @returns Transformed date string or the input itself it it's empty (i.e. 'null/undefined').
   */
  public static getDateTimeString<T extends(Date | null | undefined)>(date: T, ignoreUserTimezone = false): IsString<T> {
    if (date == null) {
      return date as IsString<T>;
    }

    if (ignoreUserTimezone) {
      return transformToServerDate(date).toISOString() as IsString<T>;
    }
    return date.toISOString() as IsString<T>;
  }
}
