import { Injectable, Provider } from '@angular/core';
import { BehaviorSubject, filter, skip } from 'rxjs';

import { BaseValueAccessor, controlProviderFor } from './base-value-accessor';

/** Provide value accessor decomposition. */
export function provideValueAccessorDecomposition(): Provider {
  return [
    ValueAccessorDecomposition,
    controlProviderFor(() => ValueAccessorDecomposition),
  ];
}

/** Value accessor changes. */
export type ValueAccessorChanges<T> = {

  /** Indicates whether the change was made by the user. */
  readonly isInner: boolean;

  /** Value. */
  readonly value: T | null;
};

/**
 * Decomposition implementation for ControlValueAccessor.
 * Has a synchronous `controlValue` property that contains a value.
 * Reduces boilerplate needed for implementing custom controls via
 * Angular's [ControlValueAccessor](https://angular.io/api/forms/ControlValueAccessor).
 * @see ControlValueAccessor.
 * @example
 * ```ts
 * ‌@Component({
 *   selector: 'dartsalesw-text-editor',
 *   templateUrl: './text-editor.component.html',
 *   providers: [provideValueAccessorDecomposition()],
 * })
 * export class TextEditorComponent {
 *   private readonly valueAccessor = inject<ValueAccessorDecomposition<string>>(
 *     ValueAccessorDecomposition
 *   );
 * }
 * ```
 */
@Injectable()
export class ValueAccessorDecomposition<T> extends BaseValueAccessor<T> {

  /** Get the synchronous value of the control. */
  public get controlValue(): T | null {
    return this.lastValueChanges$.value?.value ?? null;
  }

  /** Set control value. */
  public set controlValue(v: T | null) {
    this.lastValueChanges$.next({
      isInner: true,
      value: v,
    });
    this.emitChange(v);
  }

  private readonly _isDisabled$ = new BehaviorSubject(this.disabled);

  /**
   * Is control disabled.
   * You can use ValueAccessorDecomposition['disabled'] property for get synchronous value.
   */
  public readonly isDisabled$ = this._isDisabled$.asObservable();

  /**
   * The last control value change.
   * We do not use ReplaySubject to support synchronous value getting.
   */
  private readonly lastValueChanges$ = new BehaviorSubject<ValueAccessorChanges<T> | null>(null);

  /** Control value changes. */
  public readonly valueChanges$ = this.lastValueChanges$.asObservable().pipe(

    // It is necessary because we use initial argument in lastValueChanges$.
    // Angular by default call ControlValueAccessor['writeValue'] method with initial control value before work with formControl.
    skip(1),
  );

  /** The changes that were set by Angular via ControlValueAccessor['writeValue']. */
  public readonly outerChanges$ = this.valueChanges$.pipe(
    filter(changes => !changes?.isInner),
  );

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    this._isDisabled$.next(isDisabled);
    super.setDisabledState(isDisabled);
    this.changeDetectorRef.markForCheck();
  }

  /** @inheritdoc */
  public writeValue(value: T | null): void {
    this.lastValueChanges$.next({
      isInner: false,
      value,
    });
    this.changeDetectorRef.markForCheck();
  }
}
