import { inject, Injectable } from '@angular/core';

import { Estimate } from '@dartsales/common/core/models/estimate/estimate';
import { ShortEstimate } from '@dartsales/common/core/models/estimate/short-estimate';

import { EstimateDto } from '../dto/estimate/estimate.dto';
import { TermSummaryMapper } from '../estimate-services/term-summary.mapper';
import { IMapper } from '../mappers';
import { ShortEstimateDto } from '../dto/estimate/short-estimate.dto';

import { ModulePropertiesMapper } from './modules/module-properties.mapper';
import { ModulesPropertiesListMapper } from './modules-propterties-list.mapper';

/** Estimate mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateMapper implements IMapper<EstimateDto, Estimate> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly modulesListMapper = inject(ModulesPropertiesListMapper);

  private readonly termsMapper = inject(TermSummaryMapper);

  /** @inheritdoc */
  public fromDto(dto: EstimateDto): Estimate {
    return new Estimate({
      id: dto.id,
      name: dto.name,
      description: dto.description ?? '',
      modulesProperties: this.modulesListMapper.fromDto(dto),
      modulesTotals: this.propertiesMapper.fromDto(dto.totalsModule.properties),
      terms: dto.terms.map(term => this.termsMapper.fromDto(term)),
      compoundEscalation: {
        percent: dto.compoundEscalationPercent,
        isEnabled: dto.isCompoundEscalationEnabled,
      },
      sellPrice: dto.sellPrice,
    });
  }

  /**
   * Map short estimate DTO to model.
   * @param dto DTO.
   */
  public fromShortDto(dto: ShortEstimateDto): ShortEstimate {
    return new ShortEstimate({
      id: dto.id,
      name: dto.name,
      description: dto.description ?? '',
    });
  }

  /** @inheritdoc */
  public toDto(data: Estimate): EstimateDto {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      totalsModule: {
        properties: this.propertiesMapper.toDto(data.modulesTotals),
        data: undefined,
      },
      sellPrice: data.sellPrice,
      terms: data.terms.map(term => this.termsMapper.toDto(term)),
      compoundEscalationPercent: data.compoundEscalation.percent,
      isCompoundEscalationEnabled: data.compoundEscalation.isEnabled,
      ...this.modulesListMapper.toDto(data.modulesProperties),
    };
  }
}
