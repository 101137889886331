import { Injectable, inject } from '@angular/core';

import { UserOrganization } from '../../models/user-organization';

import { UserOrganizationDto } from './dto/user-organization.dto';
import { IMapperFromDto } from './mappers';
import { OrganizationUserRolesMapper } from './organization-user-roles.mapper';
import { BaseOrganizationMapper } from './base-organization.mapper';

/** User organization entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserOrganizationMapper implements IMapperFromDto<UserOrganizationDto, UserOrganization> {

  private readonly organizationUserRoleMapper = inject(OrganizationUserRolesMapper);

  private readonly organizationMapper = inject(BaseOrganizationMapper);

  /** @inheritdoc */
  public fromDto(dto: UserOrganizationDto): UserOrganization {
    return new UserOrganization({
      ...this.organizationMapper.fromDto(dto),
      roles: this.organizationUserRoleMapper.fromDto(dto),
    });
  }
}
