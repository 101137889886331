<ng-container *ngIf="selectedItemsCount === 0; else multiItemsDrag">
  <ng-container
    [ngTemplateOutlet]="singleItemDragTemplate ?? defaultSingleDragTemplate"
  />
</ng-container>
<ng-template #multiItemsDrag>
  <ng-container
    [ngTemplateOutlet]="
      multipleItemsDragTemplate ?? defaultMultipleItemsDragTemplate
    "
  />
</ng-template>

<ng-template #defaultSingleDragTemplate>Item</ng-template>

<ng-template #defaultMultipleItemsDragTemplate>
  {{ selectedItemsCount }} selected
</ng-template>
