import { Observable } from 'rxjs';
import { InjectionToken, Provider, Type, inject } from '@angular/core';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateExportParams } from '@dartsales/common/core/models/exports/estimate-export-params';
import { EstimateMultipleExportParams } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';

import { EstimateExportDialogService } from './estimate-export-dialog.service';

/** Estimate export service. */
export type EstimateExportService = {

  /**
   * Export estimate multiple.
   * @param exportParams Export params.
   */
  exportMultiple(exportParams: EstimateMultipleExportParams): Observable<void>;

  /**
   * Export estimate.
   * @param id Estimate ID.
   * @param exportParams Export params.
   */
  export(id: EstimateId, exportParams: EstimateExportParams): Observable<void>;
};

const ESTIMATE_EXPORT_SERVICE_TOKEN = new InjectionToken<EstimateExportService>('export-estimate-service');

/** Inject export estimate service. */
export function injectEstimateExportService(): EstimateExportService {
  return inject<EstimateExportService>(ESTIMATE_EXPORT_SERVICE_TOKEN);
}

/**
 * Provide estimate export service.
 * @param service Export estimate service.
 */
export function provideEstimateExportServices(service: Type<EstimateExportService>): Provider[] {
  return [
    {
      provide: ESTIMATE_EXPORT_SERVICE_TOKEN,
      useClass: service,
    },
    {
      provide: EstimateExportDialogService,
    },
  ];
}
