import { ChangeDetectionStrategy, Component } from '@angular/core';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

/** Welcome page toolbar component. */
@Component({
  selector: 'dartsalesw-welcome-toolbar',
  templateUrl: './welcome-toolbar.component.html',
  styleUrls: ['../shared-toolbar-styles.css', './welcome-toolbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeToolbarComponent {

  /** Route paths. */
  protected readonly routePaths = injectWebAppRoutes();
}
