import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Directive to mark ng-container as table-cell.
 * @example
 * ```html
 * <ng-container [dartsalescTableCell]="id">
 *   <ng-template #id let-row>
 *     {{ row.id }}
 *   </ng-template>
 * </ng-container>
 * ```
 *
 * Also we can use get some values from ngTemplateOutletContext:
 * @example
 * ```html
 * <ng-template #idColumn let-row let-index="index">
 *   ...
 * </ng-template>
 * ```
 */
@Directive({
  selector: '[dartsalescTableCell]',
})
export class TableCellDirective {

  /** Template input. */
  @Input('dartsalescTableCell')
  public template?: TemplateRef<unknown>;
}
