import { Injectable } from '@angular/core';

import { PaletteTag } from '@dartsales/common/core/models/components/palette/palette-tag';

import { PaletteTagDto } from '../../dto/components/palette/palette-tag.dto';
import { IMapperFromDto } from '../../mappers';

/** Palette tag mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteTagMapper implements IMapperFromDto<PaletteTagDto, PaletteTag> {

  /** @inheritdoc */
  public fromDto(dto: PaletteTagDto): PaletteTag {
    return new PaletteTag({
      name: dto.name,
      isSelected: dto.isSelected,
    });
  }
}
