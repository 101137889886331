<span class="header-units-label">
  {{ shortLabel }}
</span>
<mat-select
  class="header-units-select"
  panelClass="units-select-panel"
  [formControl]="unitsControl"
  (selectionChange)="unitsChange.emit($event.value)"
>
  <mat-option
    *ngFor="let item of typeOptions$ | async; trackBy: trackByValue"
    class="header-units-select__option"
    [value]="item.value"
  >
    {{ item.label }}
  </mat-option>
</mat-select>
