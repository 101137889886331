import { InjectionToken, inject, Type, Provider } from '@angular/core';

import { NestedTreeSelectionService } from './models/nested-tree-selection-service';

const NESTED_TREE_SELECTION_SERVICE_TOKEN = new InjectionToken<never>(
  'Token for inject nested tree service',
);

/** Inject nested tree selection service. */
export function injectNestedTreeSelectionService<T>(): NestedTreeSelectionService<T> {
  return inject<NestedTreeSelectionService<T>>(NESTED_TREE_SELECTION_SERVICE_TOKEN);
}

/**
 * Provide nested tree selection service.
 * @param service Nested tree selection service.
 */
export function provideNestedTreeSelectService(service: Type<NestedTreeSelectionService<never>>): Provider {
  return {
    provide: NESTED_TREE_SELECTION_SERVICE_TOKEN,
    useClass: service,
  };
}
