import { PortTypeWithCategory } from './port-type-with-category';

/** Estimate part stacked port child. */
export class EstimatePartStackedPortChild {

  /** UUID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Port type with category. */
  public readonly typeWithCategory: PortTypeWithCategory;

  public constructor(data: EstimatePartStackedPortChildInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.typeWithCategory = data.typeWithCategory;
  }
}

type EstimatePartStackedPortChildInitArgs = EstimatePartStackedPortChild;
