import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { ChangeOrderId } from '../models/estimate/change-order/change-order';
import { EstimateId } from '../models/estimate/estimate';
import { FinalEstimate } from '../models/estimate/final-estimate/final-estimate';

import { ProjectLayoutService } from './project-layout.service';

/** Project navigation service. */
@Injectable()
export class ProjectNavigationService {

  private readonly projectId$ = inject(ProjectLayoutService).projectId$;

  private readonly router = inject(Router);

  private readonly routePaths = injectWebAppRoutes();

  /** Navigate to project dashboard. */
  public navigateToDashboard(): void {
    this.projectId$.pipe(
      first(),
    ).subscribe(projectId => {
      const { url } = this.routePaths.project.children({ projectId }).dashboard;
      this.router.navigateByUrl(url);
    });
  }

  /**
   * Navigate to change order.
   * @param changeOrderId Change Order ID.
   */
  public navigateToChangeOrder(changeOrderId: ChangeOrderId): void {
    this.projectId$.pipe(
      first(),
    )
      .subscribe(projectId => {
        const url = this.routePaths.project.children({ projectId }).dashboard.children.changeOrders.children.details.url({ changeOrderId });
        this.router.navigateByUrl(url);
      });
  }

  /**
   * Navigate to alternate estimate.
   * @param estimateId Alternate estimate ID.
   */
  public navigateToAlternateEstimate(estimateId: EstimateId): void {
    this.projectId$.pipe(
      first(),
    )
      .subscribe(projectId => {
        const url = this.routePaths.project.children({ projectId }).dashboard
          .children.alternateEstimates.children.details.url({ estimateId });
        this.router.navigateByUrl(url);
      });
  }

  /**
   * Navigate to final estimate.
   * @param estimateId Alternate estimate ID.
   */
  public navigateToFinalEstimate(estimateId: FinalEstimate['id']): void {
    this.projectId$.pipe(
      first(),
    )
      .subscribe(projectId => {
        const url = this.routePaths.project.children({ projectId }).dashboard.children.finalEstimates.children.details.url({ estimateId });
        this.router.navigateByUrl(url);
      });
  }
}
