/** Collapsible row data. */
export type CollapsibleRowData<TRowType extends string> = {

  /** Row key. */
  readonly key: string;

  /** Row Type. */
  readonly type: TRowType;
};

/**
 * Collapse rows strategy.
 * By default, all rows are considered expanded.
 * If the row is not a collapsed row type, then the **inverted** row is considered **collapsed**.
 * If the row belongs to the collapsed type of rows, then the **inverted** row is considered **expanded**.
 */
export class CollapseRowsStrategy<TRowType extends string> {

  /**
   * Check is row collapsed.
   * @param row Row.
   * @param invertedRows Inverted rows.
   * @param collapsedRowTypes Collapsed row types.
   */
  public checkIsRowCollapsed(
    row: CollapsibleRowData<TRowType>,
    invertedRows: readonly CollapsibleRowData<TRowType>[],
    collapsedRowTypes: readonly TRowType[],
  ): boolean {
    const isRowInverted = this.checkIsRowInverted(row, invertedRows);

    if (this.checkIsRowTypeCollapsed(row, collapsedRowTypes)) {
      return !isRowInverted;
    }

    return isRowInverted;
  }

  /**
   * Get inverted rows with collapse specified row.
   * @param rowsForCollapse Rows for collapse.
   * @param invertedRows Inverted rows.
   * @param collapsedRowTypes Collapsed row types.
   */
  public getInvertedRowsWithCollapse(
    rowsForCollapse: readonly CollapsibleRowData<TRowType>[],
    invertedRows: readonly CollapsibleRowData<TRowType>[],
    collapsedRowTypes: readonly TRowType[],
  ): CollapsibleRowData<TRowType>[] {
    return this.getInvertedRowsByBehavior(
      'collapse',
      rowsForCollapse,
      invertedRows,
      collapsedRowTypes,
    );
  }

  /**
   * Get inverted rows with expand specified row.
   * @param rowsForExpand Rows for expand.
   * @param invertedRows Inverted rows.
   * @param collapsedRowTypes Collapsed row types.
   */
  public getInvertedRowsWithExpand(
    rowsForExpand: readonly CollapsibleRowData<TRowType>[],
    invertedRows: readonly CollapsibleRowData<TRowType>[],
    collapsedRowTypes: readonly TRowType[],
  ): CollapsibleRowData<TRowType>[] {
    return this.getInvertedRowsByBehavior(
      'expand',
      rowsForExpand,
      invertedRows,
      collapsedRowTypes,
    );
  }

  /**
   * Get inverted rows with toggled row collapse.
   * @param row Row for toggle.
   * @param invertedRows Inverted rows.
   * @param collapsedRowTypes Collapsed row types.
   */
  public getInvertedRowsWithToggledRowCollapse(
    row: CollapsibleRowData<TRowType>,
    invertedRows: readonly CollapsibleRowData<TRowType>[],
    collapsedRowTypes: readonly TRowType[],
  ): CollapsibleRowData<TRowType>[] {
    if (this.checkIsRowCollapsed(row, invertedRows, collapsedRowTypes)) {
      return this.getInvertedRowsWithExpand([row], invertedRows, collapsedRowTypes);
    }
    return this.getInvertedRowsWithCollapse([row], invertedRows, collapsedRowTypes);
  }

  private checkIsRowTypeCollapsed(
    row: CollapsibleRowData<TRowType>,
    collapsedRowTypes: readonly TRowType[],
  ): boolean {
    return collapsedRowTypes.includes(row.type);
  }

  private getInvertedRowsByBehavior(
    behavior: 'expand' | 'collapse',
    rows: readonly CollapsibleRowData<TRowType>[],
    invertedRows: readonly CollapsibleRowData<TRowType>[],
    collapsedRowTypes: readonly TRowType[],
  ): CollapsibleRowData<TRowType>[] {
    const invertedRowsMap = new Map(invertedRows.map(row => [row.key, row]));
    rows.forEach(row => {
      const isRowTypeCollapsed = this.checkIsRowTypeCollapsed(row, collapsedRowTypes);
      if (behavior === 'expand' && !isRowTypeCollapsed || behavior === 'collapse' && isRowTypeCollapsed) {
        invertedRowsMap.delete(row.key);
      } else {
        invertedRowsMap.set(row.key, row);
      }
    });
    return [...invertedRowsMap.values()];
  }

  private checkIsRowInverted(
    row: CollapsibleRowData<TRowType>,
    invertedRows: readonly CollapsibleRowData<TRowType>[],
  ): boolean {
    return invertedRows.some(invertedRow => invertedRow.key === row.key);
  }
}
