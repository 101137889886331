import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { TermTreeItem } from '../../models/estimate-services/term-tree-item';
import { EstimateId } from '../../models/estimate/estimate';
import { TermTreeItemDto } from '../mappers/dto/estimate-services/term-tree-item.dto';
import { ViewType } from '../../models/view-type';
import { TermTreeItemMapper } from '../mappers/export/term-tree-item.mapper';
import { buildHttpParams } from '../../utils/build-http-params';

import { AppUrlsConfig } from './app-urls.config';

/** Estimate terms tree API service. */
@Injectable({
  providedIn: 'root',
})
export class EstimateTermsTreeApiService {

  private readonly httpClient = inject(HttpClient);

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly termTreeItemMapper = inject(TermTreeItemMapper);

  /**
   * Get terms tree.
   * @param estimateId Estimate ID.
   * @param viewType Estimate view type.
   */
  public getTree(estimateId: EstimateId, viewType: ViewType): Observable<TermTreeItem[]> {
    const params = buildHttpParams({
      useCustomerView: viewType === ViewType.Customer,
    });
    return this.httpClient.get<TermTreeItemDto[]>(this.apiUrls.exportApi.getServicesTree(estimateId), { params })
      .pipe(
        map(dtos => dtos.map(dto => this.termTreeItemMapper.fromDto(dto))),
      );
  }
}
