<button
  *ngIf="user$ | async as user"
  mat-flat-button
  color="primary"
  type="button"
  class="profile-menu-btn"
  [matMenuTriggerFor]="menu"
>
  {{ getUserInitials(user) }}
</button>

<mat-menu
  #menu
  class="profile-menu"
>
  <div
    *ngFor="let userInfo of userInfoList$ | async; trackBy: trackByOptions"
    class="profile-menu__item user-info"
    (click)="onMenuInfoItemClick($event)"
  >
    <div class="user-info__label caption-1">
      {{ userInfo.label }}
    </div>
    <div>
      {{ userInfo.value }}
    </div>
  </div>

  <button
    *ngIf="user$ | async as user"
    mat-menu-item
    type="button"
    class="profile-menu__item profile-menu__item_action"
    (click)="onEditAccountSettingsButtonClick(user)"
  >
    Edit Account Settings
  </button>
  <button
    mat-menu-item
    class="profile-menu__item profile-menu__item_action"
    type="button"
    (click)="onUpdatePasswordButtonClick()"
  >
    Update Password
  </button>
  <button
    mat-menu-item
    class="profile-menu__item profile-menu__item_action"
    type="button"
    (click)="onSignOutButtonClick()"
  >
    Sign Out
  </button>
</mat-menu>
