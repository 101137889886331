import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { getValueLabelFromOptions } from '../utils/get-label-from-options';
import { BaseCellRendererComponent } from '../../../base-components/base-cell-renderer.component';

/** Single select cell renderer component. */
@Component({
  selector: 'dartsalesc-single-select-cell-renderer',
  templateUrl: './single-select-cell-renderer.component.html',
  styleUrls: ['./single-select-cell-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectCellRendererComponent<T> extends BaseCellRendererComponent<T> {

  /** Options. */
  @Input({ required: true })
  public options: readonly OptionSelect<T>[] = [];

  /** Placeholder. */
  @Input()
  public placeholder = 'Select value';

  /** Value label. */
  protected get valueLabel(): string | null {
    return this.value != null ? getValueLabelFromOptions(this.value, this.options) : null;
  }
}
