import { OptionSelect } from '@dartsales/common/core/models/option-select';

/**
 * Get value label from options.
 * @param value Value.
 * @param options Options.
 */
export function getValueLabelFromOptions<T>(value: T, options: readonly OptionSelect<T>[]): string | null {
  return options.find(v => v.value === value)?.label ?? null;
}
