import { ModuleProperties } from './modules/module-properties';

/** Estimate module. */
export class Module<TProperties = ModuleProperties, TData = void> {

  /** Properties. */
  public readonly properties: TProperties;

  /** Data. */
  public readonly data: TData;

  public constructor(data: ModuleInitArgs<TProperties, TData>) {
    this.properties = data.properties;
    this.data = data.data;
  }
}

type ModuleInitArgs<TProperties, TData> = Module<TProperties, TData>;
