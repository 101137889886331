import { inject, Injectable } from '@angular/core';

import { IMapper } from '@dartsales/common/core/services/mappers/mappers';
import {
  BasePricingDto,
  OverridableBasePricingDto,
} from '@dartsales/common/core/services/mappers/dto/pricing/base-pricing.dto';
import { BasePricing } from '@dartsales/common/core/models/pricing/base-pricing';
import { AmountPercentMapper } from '@dartsales/common/core/services/mappers/estimate/amount-percent.mapper';
import { OverridableBasePricing } from '@dartsales/common/core/models/pricing/overridable-base-pricing';
import { Margin, OverridableMargin } from '@dartsales/common/core/models/estimate/modules/margin';
import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';
import { EditBasePricing } from '@dartsales/common/core/models/pricing/edit-base-pricing';

import { OverridableMapper } from '../overridable.mapper';
import { EditBasePricingDto } from '../dto/pricing/edit-base-pricing.dto';

/** Base pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class BasePricingMapper implements IMapper<BasePricingDto, BasePricing> {

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: BasePricingDto): BasePricing {
    /**
     * Backend provides us just with part of base pricing, we must calculate all remaining values.
     */
    return new BasePricing({
      directCost: dto.directCost,
      contingency: this.amountPercentMapper.fromDto(dto.contingency),
      escalation: dto.escalation != null ? this.amountPercentMapper.fromDto(dto.escalation) : new AmountPercent(),
      margin: new Margin({
        grossMargin: this.amountPercentMapper.fromDto(dto.grossMargin),
        markup: this.amountPercentMapper.fromDto(dto.markup),
      }),
      sellPrice: dto.sellPrice,
      dc: dto.dcInfo != null ? this.amountPercentMapper.fromDto(dto.dcInfo) : null,
      tdc: dto.tdcInfo != null ? this.amountPercentMapper.fromDto(dto.tdcInfo) : null,
    });
  }

  /** @inheritdoc */
  public toDto(model: BasePricing): BasePricingDto {
    return {
      directCost: model.directCost,
      contingency: this.amountPercentMapper.toDto(model.contingency),
      markup: this.amountPercentMapper.toDto(model.margin.markup),
      escalation: this.amountPercentMapper.toDto(model.escalation),
      grossMargin: this.amountPercentMapper.toDto(model.margin.grossMargin),
      totalDirectCost: model.getTotalDirectCost(),
      sellPrice: model.sellPrice,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditBasePricing): EditBasePricingDto {
    return {
      directCost: data.directCost,
      contingencyPercent: data.contingencyPercent,
      markupPercent: data.markupPercent,
      sellPrice: data.sellPrice,
    };
  }

  /**
   * Maps overridable DTO to overridable model.
   * @param dto DTO.
   */
  public fromOverridableDto(dto: OverridableBasePricingDto): OverridableBasePricing {
    return new OverridableBasePricing({
      directCost: this.overridableMapper.fromDto(dto.directCost),
      contingency: this.overridableMapper.fromObjectDto(dto.contingency, this.amountPercentMapper),
      margin: new OverridableMargin({
        grossMargin: this.overridableMapper.fromObjectDto(dto.grossMargin, this.amountPercentMapper),
        markup: this.overridableMapper.fromObjectDto(dto.markup, this.amountPercentMapper),
      }),
      sellPrice: this.overridableMapper.fromDto(dto.sellPrice),
      escalation: this.overridableMapper.fromObjectDto(dto.escalation, this.amountPercentMapper),
      aggregatedWEFS: this.overridableMapper.fromDto(dto.aggregatedWEFS),
      dc: dto.dcInfo != null ? this.amountPercentMapper.fromDto(dto.dcInfo) : null,
      tdc: dto.tdcInfo != null ? this.amountPercentMapper.fromDto(dto.tdcInfo) : null,
    });
  }

  /**
   * Maps overridable mode to overridable DTO.
   * @param model Model.
   */
  public toOverridableDto(model: OverridableBasePricing): OverridableBasePricingDto {
    return {
      directCost: this.overridableMapper.toDto(model.directCost),
      contingency: this.overridableMapper.toObjectDto(model.contingency, this.amountPercentMapper),
      markup: this.overridableMapper.toObjectDto(model.margin.markup, this.amountPercentMapper),
      grossMargin: this.overridableMapper.toObjectDto(model.margin.grossMargin, this.amountPercentMapper),
      sellPrice: this.overridableMapper.toDto(model.sellPrice),
      escalation: this.overridableMapper.toDto(model.escalation),
      aggregatedWEFS: this.overridableMapper.toDto(model.aggregatedWEFS),
      dcInfo: model.dc != null ? this.amountPercentMapper.fromDto(model.dc) : undefined,
      tdcInfo: model.tdc != null ? this.amountPercentMapper.fromDto(model.tdc) : undefined,
    };
  }
}
