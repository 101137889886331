import { inject, Injectable } from '@angular/core';

import { TermSubCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-subcategory-tree-item';

import { TermSubCategoryTreeItemDto } from '../dto/estimate-services/term-tree-item.dto';
import { TermSubCategoryExportItemDto } from '../dto/export/term-export-items.dto';
import { IMapperFromDto } from '../mappers';

import { TermServiceTreeItemMapper } from './term-service-tree-item.mapper';

/** Term subcategory tree item mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermSubcategoryTreeItemMapper implements IMapperFromDto<TermSubCategoryTreeItemDto, TermSubCategoryTreeItem> {

  private readonly termServiceTreeItemsMapper = inject(TermServiceTreeItemMapper);

  /** @inheritdoc */
  public fromDto(dto: TermSubCategoryTreeItemDto): TermSubCategoryTreeItem {
    return new TermSubCategoryTreeItem({
      value: {
        subCategoryId: dto.subCategoryId,
      },
      title: dto.subCategoryName,
      children: dto.services.map(service => this.termServiceTreeItemsMapper.fromDto(service)),
    });
  }

  /** @inheritdoc */
  public toDto(data: TermSubCategoryTreeItem): TermSubCategoryExportItemDto {
    return {
      subCategoryId: data.value.subCategoryId,
      services: data.children.map(subCategory => this.termServiceTreeItemsMapper.toDto(subCategory)),
    };
  }
}
