import { Injectable, inject } from '@angular/core';

import { PointsListTabProperties } from '@dartsales/common/core/models/estimate/modules/points-list/tab/points-list-tab-properties';
import { PointsListTabLaborTask } from '@dartsales/common/core/models/estimate/modules/points-list/tab/points-list-tab-labor-task';

import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { PointsListTabPropertiesDto } from '../../../dto/estimate/modules/points-list/tab/points-list-tab-properties.dto';

import { PointsListOverridableItemTotalMapper } from './points-list-overridable-item-total.mapper';

/** Points list tab properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListTabPropertiesMapper implements IMapper<PointsListTabPropertiesDto, PointsListTabProperties> {
  private readonly pointsListItemTotalMapper = inject(PointsListOverridableItemTotalMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListTabPropertiesDto): PointsListTabProperties {
    return new PointsListTabProperties({
      total: this.pointsListItemTotalMapper.fromDto(dto),
      tasks: dto.tasks.map(task => new PointsListTabLaborTask({
        id: task.taskId,
        name: this.confirmedOverridableMapper.fromDto(task.name),
        isDefault: task.isDefault,
      })),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListTabProperties): PointsListTabPropertiesDto {
    return {
      ...this.pointsListItemTotalMapper.toDto(data.total),
      tasks: data.tasks.map(task => ({
        taskId: task.id,
        isDefault: task.isDefault,
        name: this.confirmedOverridableMapper.toDto(task.name),
      })),
    };
  }
}
