import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { buildHttpParams } from '@dartsales/common/core/utils/build-http-params';
import { CreatePointsListSystem, CreatePointsListSystemFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/system/create-points-list-system';
import { PointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system';
import { BatchEditPointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system';
import { EditPointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-task-rate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { DuplicatePointsListEntities } from '@dartsales/common/core/models/estimate/modules/points-list/duplicate-points-list-entities';
import { MovePointsListEntities } from '@dartsales/common/core/models/estimate/modules/points-list/move-points-list-entities';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { AppErrorMapper } from '../../mappers/errors/app-error.mapper';
import { PointsListSystemMapper } from '../../mappers/estimate/modules/points-list/points-list-system.mapper';
import { EstimateId } from '../../../models/estimate/estimate';
import { PointsListSystemDto } from '../../mappers/dto/estimate/modules/points-list/system/points-list-system.dto';
import { AppUrlsConfig } from '../app-urls.config';
import { PointsListSystemTaskRateMapper } from '../../mappers/estimate/modules/points-list/points-list-system-task-rate.mapper';

import { PointsListGroupsApiService } from './points-list-groups-api.service';

/** Points list system API service. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly pointsListSystemMapper = inject(PointsListSystemMapper);

  private readonly pointsListSystemTaskRateMapper = inject(PointsListSystemTaskRateMapper);

  private readonly pointsListGroupsApiService = inject(PointsListGroupsApiService);

  /**
   * Create new points list system.
   * @param estimateId Estimate ID.
   * @param data New system data.
   */
  public createSystem(estimateId: EstimateId, data: CreatePointsListSystem): Observable<PointsListSystem> {
    const newSystemDto = this.pointsListSystemMapper.toCreateDto(data);
    const params = buildHttpParams({
      systemGroupId: data.systemGroupId,
    });
    return this.http.post<PointsListSystemDto>(
      this.apiUrls.pointsListApi.systems.create(estimateId),
      newSystemDto,
      { params },
    ).pipe(
      map(dto => this.pointsListSystemMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create new points list system from palette system.
   * @param estimateId Estimate ID.
   * @param data New system data.
   */
  public createSystemFromPalette(estimateId: EstimateId, data: CreatePointsListSystemFromPalette): Observable<PointsListSystem[]> {
    const newSystemDto = this.pointsListSystemMapper.toCreateFromPaletteDto(data);
    const params = buildHttpParams({
      systemGroupId: data.systemGroupId,
    });
    return this.http.post<PointsListSystemDto[]>(
      this.apiUrls.pointsListApi.systems.createFromPalette(estimateId),
      newSystemDto,
      { params },
    ).pipe(
      map(response => response.map(dto => this.pointsListSystemMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update points list systems.
   * @param estimateId Estimate ID.
   * @param systems Systems data.
   */
  public updateSystems(estimateId: EstimateId, systems: readonly BatchEditPointsListSystem[]): Observable<PointsListSystem[]> {
    const updateDto = systems.map(system => this.pointsListSystemMapper.toBatchEditDto(system));

    return this.http.put<PointsListSystemDto[]>(this.apiUrls.pointsListApi.systems.update(estimateId), updateDto).pipe(
      map(response => response.map(dto => this.pointsListSystemMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update system task rate.
   * @param estimateId Estimate ID.
   * @param taskRates Task rate.
   */
  public updateSystemTaskRates(
    estimateId: EstimateId,
    taskRates: readonly EditPointsListSystemTaskRate[],
  ): Observable<void> {
    const batchUpdateDto = this.pointsListSystemTaskRateMapper.toBatchEditDto(taskRates);

    return this.http.put<void>(
      this.apiUrls.pointsListApi.systems.updateTaskRates(estimateId),
      batchUpdateDto,
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Move systems in points list.
   * @param estimateId Estimate ID.
   * @param payload Payload.
   */
  public moveSystems(estimateId: EstimateId, payload: MovePointsListEntities): Observable<PointsListSystem[]> {
    const params = buildHttpParams({
      destinationSystemGroupId: payload.parentEntityId,
    });

    return this.http.post<PointsListSystemDto[]>(
      this.apiUrls.pointsListApi.systems.move(estimateId),
      {
        systemIds: payload.entityIds,
        replaceSystemId: payload.replaceEntityId,
        order: payload.order,
      },
      { params },
    ).pipe(
      map(response => response.map(dto => this.pointsListSystemMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Duplicate systems.
   * @param estimateId Estimate ID.
   * @param payload Payload.
   */
  public duplicateSystems(estimateId: EstimateId, payload: DuplicatePointsListEntities): Observable<void> {
    return this.http.post<void>(this.apiUrls.pointsListApi.systems.duplicate(estimateId), {
      sourceSystemIds: payload.entityIds,
      destinationSystemGroupId: payload.parentEntityId,
      replaceSystemId: payload.replaceEntityId,
      order: payload.order,
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete systems.
   * @param estimateId Estimate ID.
   * @param systemIds System IDs to delete.
   */
  public deleteSystems(estimateId: EstimateId, systemIds: readonly PointsListSystem['id'][]): Observable<ApiTransactionId> {
    return this.pointsListGroupsApiService.delete(estimateId, new PointsListAllEntitiesIds({
      systemIds,
    }));
  }

}
