import { Injectable } from '@angular/core';

import { OtherTeamMember } from '../../models/project/other-team-member';
import { OptionSelect } from '../../models/option-select';
import { UserBase } from '../../models/user/user-base';

import { OrganizationUserDto } from './dto/user/organization-user.dto';
import { IMapperFromDto } from './mappers';

/** Other team member mapper. */
@Injectable({
  providedIn: 'root',
})
export class OtherTeamMemberMapper implements IMapperFromDto<OrganizationUserDto, OtherTeamMember> {

  /** @inheritdoc */
  public fromDto(dto: OrganizationUserDto): OtherTeamMember {
    return new OtherTeamMember({
      memberId: dto.id,
      memberEmail: dto.email,
      memberFirstName: dto.firstName,
      memberLastName: dto.lastName,
    });
  }

  /**
   * Map user to team member select option.
   * @param data User.
   */
  public userToOptionSelect(data: UserBase): OptionSelect<OtherTeamMember> {
    return this.toOptionSelect(this.fromUser(data));
  }

  private toOptionSelect(data: OtherTeamMember): OptionSelect<OtherTeamMember> {
    return {
      label: `${data.memberFirstName} ${data.memberLastName} <${data.memberEmail}>`,
      value: data,
    };
  }

  private fromUser(data: UserBase): OtherTeamMember {
    return new OtherTeamMember({
      memberId: data.id,
      memberEmail: data.email,
      memberFirstName: data.firstName,
      memberLastName: data.lastName,
    });
  }
}
