import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { BehaviorSubject, debounceTime, shareReplay } from 'rxjs';

/** Wrapper component to mark container as viewport for `ViewportRendererComponent`. */
@Directive({
  selector: '[dartsalescViewportRendererContainer]',
})
export class ViewportRendererContainerDirective {

  /** Element ref. */
  public readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  private readonly viewportScrolledInner$ = new BehaviorSubject<void>(undefined);

  /** Viewport scrolled event. */
  public readonly viewportScrolled$ = this.viewportScrolledInner$.pipe(
    debounceTime(1000),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /**
   * Handle 'scrollend' event.
   * @param _event Event.
   */
  @HostListener('scrollend', ['$event'])
  protected onScrollEnd(_event: Event): void {
    this.viewportScrolledInner$.next();
  }
}
