import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { UserProfileMapper } from '../mappers/user-profile.mapper';
import { EditUserProfile } from '../../models/user/user-profile';

import { AppUrlsConfig } from './app-urls.config';

/** Update personal info API service. */
@Injectable({ providedIn: 'root' })
export class UpdatePersonalInfoApiService {

  private readonly apiUrl = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly userProfileMapper = inject(UserProfileMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  /**
   * Update personal info.
   * @param updatedPersonalInfo Updated personal info.
   */
  public updatePersonalInfo(updatedPersonalInfo: EditUserProfile): Observable<void> {
    return this.http.put<void>(
      this.apiUrl.personalInfo.update,
      this.userProfileMapper.toEditDto(updatedPersonalInfo),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.userProfileMapper,
      ),
    );
  }
}
