import { Directive, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, skip } from 'rxjs';

import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { CURRENCY_MASK, INPUTS_FRACTION_DIGITS, PERCENT_MASK } from '@dartsales/common/core/utils/constants';
import { roundToFixed } from '@dartsales/common/core/utils/rounds';

/** Input delay in milliseconds. */
export const INPUT_DELAY_MS = 300;

/** Abstract input component. */
@UntilDestroy()
@Directive()
export abstract class AbstractInputComponent<T> extends SimpleValueAccessor<T> implements OnInit {

  /** Form builder. */
  protected readonly fb = inject(NonNullableFormBuilder);

  /** Mask options. */
  protected readonly maskOptions = createMask({ ...CURRENCY_MASK, prefix: CURRENCY_MASK.prefix?.concat(' ') });

  /** Input control. */
  protected readonly inputControl = this.fb.control('');

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /**
   * Convert value to display format.
   * @param value Native component value.
   */
  protected abstract valueToInput(value: T | null): string;

  /**
   * Convert display format to native value.
   * @param valueVm Display value.
   */
  protected abstract valueFromInput(valueVm: string): T | null;

  /**
   * Check is value equal.
   * @param prevValue Previous value.
   * @param valueFromInput Value from input.
   */
  protected abstract checkIsInputValuesChanged(prevValue: T, valueFromInput: T): boolean;

  /** Handle input blur. */
  protected onBlur(): void {
    const value = this.valueFromInput(this.inputControl.value);
    if (value == null) {
      this.inputControl.setValue(this.valueToInput(null));
    }
  }

  /** @inheritdoc */
  public override writeValue(value: T | null): void {
    this.fillForm(value);
    super.writeValue(value);
  }

  /**
   * Fill form.
   * @param value Form value.
   */
  protected fillForm(value: T | null): void {
    this.inputControl.setValue(this.valueToInput(value), { emitEvent: false });
  }

  private handleInputValueChange(value: string): void {
    const nativeValue = this.valueFromInput(value);
    if (
      nativeValue !== null &&
      this.controlValue != null &&
      this.checkIsInputValuesChanged(this.controlValue, nativeValue)
    ) {
      this.controlValue = nativeValue;
    }
  }

  private subscribeToControlChanges(): void {
    this.inputControl.valueChanges.pipe(

      // skip(1) - solves bug with input masks.
      // During initialization, valueChanges is triggered with initial value of control, which we don't need.
      skip(1),
      debounceTime(INPUT_DELAY_MS),
      untilDestroyed(this),
    ).subscribe(value => this.handleInputValueChange(value));
  }
}

export namespace AmountInputHelpers {

  /**
   * Helper method to convert primitive number to display format.
   * @param value Number value.
   */
  export function numberToInput(value: number): string {
    return value.toString();
  }

  /**
   * Helper method to convert percent value to display format.
   * @param value Number value.
   */
  export function percentToInput(value: number): string {
    return roundToFixed(mapDecimalToPercent(value), INPUTS_FRACTION_DIGITS).toString();
  }

  /**
   * Helper method to convert display format to primitive number.
   * @param valueVm Display value.
   * @param maskOptions Used input mask.
   */
  export function numberFromInput(valueVm: string, maskOptions = CURRENCY_MASK): number | null {
    const newValue = Number(Inputmask.unmask(valueVm.replaceAll(',', ''), maskOptions));
    if (valueVm !== '' && !Number.isNaN(newValue)) {
      return newValue;
    }
    return null;
  }

  /**
   * Helper method to convert display format to percent value.
   * @param valueVm Display value.
   * @param maskOptions Used input mask.
   */
  export function percentFromInput(valueVm: string, maskOptions = PERCENT_MASK): number | null {
    const numberValue = numberFromInput(valueVm, maskOptions);

    if (numberValue !== null) {
      return numberValue / 100;
    }

    return null;
  }
}

/**
 * Map decimal number (0.5) to percent (50 %).
 * @param decimal Decimal number.
 */
export function mapDecimalToPercent(decimal: number): number {
  return decimal * 100;
}
