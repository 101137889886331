import { ShortPointsListTab } from './tab/short-points-list-tab';

/** Points list module data. */
export class PointsListModuleData {

  /** Points list tabs. */
  public readonly tabs: readonly ShortPointsListTab[];

  public constructor(data: PointsListModuleDataInitArgs) {
    this.tabs = data.tabs;
  }
}

type PointsListModuleDataInitArgs = Pick<PointsListModuleData, 'tabs'>;
