import { Injectable, Injector, inject } from '@angular/core';
import { combineLatest, EMPTY, first, Observable, shareReplay, switchMap } from 'rxjs';

import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';
import { PointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system';
import { BatchEditPointsListSystem, EditPointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system';
import { PointsListSystemClassification } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-classification';
import { EditPointsListSystemDetails } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-details';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { EditPointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-task-rate';
import { CreatePointsListSystemFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/system/create-points-list-system';
import { DuplicatePointsListEntities } from '@dartsales/common/core/models/estimate/modules/points-list/duplicate-points-list-entities';
import { MovePointsListEntities } from '@dartsales/common/core/models/estimate/modules/points-list/move-points-list-entities';
import { LONG_DURATION_MS, SnackbarNotificationsService } from '@dartsales/common/core/services/snackbar-notifications.service';
import { catchAppError } from '@dartsales/common/core/utils/rxjs/catch-app-error';
import { PointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-task-rate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';
import { LaborRole } from '@dartsales/common/core/models/resources/labor-role';
import { LaborRoleApiService } from '@dartsales/common/core/services/api/labor-role-api.service';

import { PointsListModuleService } from '../../../../../services/points-list-module-page.service';
import { UpdateSystemsCommand } from '../commands/system/update-systems-command';
import { UpdateSystemTaskRatesCommand } from '../commands/system/update-system-task-rates-command';
import { CreateSystemFromPaletteCommand } from '../commands/system/create-system-from-palette-command';
import { DuplicateEntitiesCommand } from '../commands/shared/duplicate-entities-command';
import { RemoveEntitiesCommand } from '../commands/shared/remove-entities-command';

/** Points list system service. */
@Injectable()
export class PointsListSystemService {
  private readonly pointsListModuleService = inject(PointsListModuleService);

  private readonly pointsListSystemApiService = inject(PointsListSystemApiService);

  private readonly notificationService = inject(SnackbarNotificationsService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly laborRoleApiService = inject(LaborRoleApiService);

  private readonly injector = inject(Injector);

  /** Labor roles. */
  public readonly laborRoles$ = this.createLaborRoles();

  /**
   * Create new points list system from palette.
   * @param payload Payload.
   */
  public createSystemFromPalette(payload: CreatePointsListSystemFromPalette): Observable<void> {
    return this.pointsListModuleService.saveModuleWithCommand(estimateId => new CreateSystemFromPaletteCommand({
      estimateId,
      payload,
      injector: this.injector,
    }));
  }

  /**
   * Update points list system.
   * @param oldSystem System.
   * @param updatedSystem Updated system data.
   */
  public updateSystem(oldSystem: PointsListSystem, updatedSystem: EditPointsListSystem): Observable<void> {
    const batchEditEntity = new BatchEditPointsListSystem({
      id: oldSystem.id,
      value: updatedSystem,
    });

    return this.updateSystems([oldSystem], [batchEditEntity]);
  }

  /**
   * Update points list systems.
   * @param oldSystems Old systems.
   * @param updatedSystem Updated systems.
   */
  public updateSystems(
    oldSystems: readonly PointsListSystem[],
    updatedSystem: readonly BatchEditPointsListSystem[],
  ): Observable<void> {
    return this.pointsListModuleService.saveModuleWithCommand(estimateId => new UpdateSystemsCommand({
      estimateId,
      oldValue: oldSystems,
      newValue: updatedSystem,
      injector: this.injector,
    }));
  }

  /**
   * Update system task rates.
   * @param args Arguments.
   * @param args.oldTaskRates Old task rates.
   * @param args.taskRates Updated task rates.
   */
  public updateSystemTaskRates(args: {
    oldTaskRates: readonly PointsListSystemTaskRate[];
    taskRates: readonly EditPointsListSystemTaskRate[];
  }): Observable<void> {
    return this.pointsListModuleService.saveModuleWithCommand(estimateId => new UpdateSystemTaskRatesCommand({
      estimateId,
      oldValue: args.oldTaskRates,
      newValue: args.taskRates,
      injector: this.injector,
    }));
  }

  /**
   * Move systems in system group.
   * @param payload Selected items.
   */
  public moveSystemsInSystemGroup(payload: MovePointsListEntities): Observable<void> {
    return this.pointsListModuleService.saveModule(estimateId =>
      this.pointsListSystemApiService.moveSystems(estimateId, payload));
  }

  /**
   * Duplicate systems in points list.
   * @param selectedSystems Selected items.
   */
  public duplicateSystems(selectedSystems: readonly PointsListSystem[]): Observable<void> {
    const payload = new PointsListAllEntitiesIds({
      systemIds: selectedSystems.map(system => system.id),
    });

    return this.pointsListModuleService.saveModuleWithCommand(estimateId => new DuplicateEntitiesCommand({
      estimateId,
      payload,
      injector: this.injector,
    }));
  }

  /**
   * Duplicate systems in points list and move to another system group.
   * @param payload Selected items.
   */
  public duplicateSystemsAndMove(payload: DuplicatePointsListEntities): Observable<void> {
    return this.pointsListModuleService.saveModule(estimateId =>
      this.pointsListSystemApiService.duplicateSystems(estimateId, payload));
  }

  /**
   * Delete system in points list.
   * @param selectedSystems Selected items.
   */
  public deleteSystems(selectedSystems: readonly PointsListSystem[]): Observable<void> {
    const payload = new PointsListAllEntitiesIds({
      systemIds: selectedSystems.map(system => system.id),
    });

    return this.pointsListModuleService.saveModuleWithCommand(estimateId => new RemoveEntitiesCommand({
      estimateId,
      payload,
      injector: this.injector,
    }));
  }

  /**
   * Update points list system classification.
   * @param system System.
   * @param classification System classification.
   */
  public updateSystemClassification(system: PointsListSystem, classification: PointsListSystemClassification): Observable<void> {
    const updatedSystem = new EditPointsListSystem({
      ...system,
      data: new EditPointsListSystemDetails({
        ...system.data,
        classification: ConfirmedOverridable.createConfirmedOverride(system.data.classification, classification),
      }),
    });

    return this.updateSystem(system, updatedSystem);
  }

  /**
   * Save sales notes in system.
   * @param system System.
   * @param notes Sales notes.
   */
  public saveNotesInSystem(system: PointsListSystem, notes: readonly string[]): Observable<void> {
    const updatedSystem = new EditPointsListSystem({
      ...system,
      data: new EditPointsListSystemDetails({
        ...system.data,
        salesNotes: notes,
      }),
    });

    return this.updateSystem(system, updatedSystem).pipe(
      catchAppError(error => {
        this.notificationService.notify(error.message, LONG_DURATION_MS);
        return EMPTY;
      }),
    );
  }

  private createLaborRoles(): Observable<LaborRole[]> {
    return combineLatest([
      this.projectLayoutService.organizationId$.pipe(first()),
      this.pointsListModuleService.module$,
    ]).pipe(
      switchMap(([organizationId]) => this.laborRoleApiService.getLaborRoles(organizationId, null)),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }
}
