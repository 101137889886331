import { Injectable, inject } from '@angular/core';

import { PointsListSystemProperties } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-properties';
import { EditPointsListSystemProperties } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-properties';

import { IMapper } from '../../../mappers';
import { EditPointsListSystemPropertiesDto, PointsListSystemPropertiesDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-properties.dto';

import { PointsListSystemTaskRateMapper } from './points-list-system-task-rate.mapper';
import { PointsListSystemTotalMapper } from './points-list-system-total.mapper';
import { PointsListSystemIoPortsTotalMapper } from './points-list-system-io-ports-total.mapper';

/** Points list system properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemPropertiesMapper implements IMapper<PointsListSystemPropertiesDto, PointsListSystemProperties> {
  private readonly pointsListSystemTaskRateMapper = inject(PointsListSystemTaskRateMapper);

  private readonly pointsListSystemTotalMapper = inject(PointsListSystemTotalMapper);

  private readonly pointsListSystemIoPortsTotalMapper = inject(PointsListSystemIoPortsTotalMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemPropertiesDto): PointsListSystemProperties {
    return new PointsListSystemProperties({
      taskRates: dto.taskRates.map(item => this.pointsListSystemTaskRateMapper.fromDto(item)),
      systemTotal: this.pointsListSystemTotalMapper.fromDto(dto.systemTotal),
      allSystemsTotal: this.pointsListSystemTotalMapper.fromDto(dto.allSystemsTotal),
      ioPortsTotal: dto.portInputOutputTotals.map(item => this.pointsListSystemIoPortsTotalMapper.fromDto(item)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemProperties): PointsListSystemPropertiesDto {
    return {
      taskRates: data.taskRates.map(item => this.pointsListSystemTaskRateMapper.toDto(item)),
      systemTotal: this.pointsListSystemTotalMapper.toDto(data.systemTotal),
      allSystemsTotal: this.pointsListSystemTotalMapper.toDto(data.allSystemsTotal),
      portInputOutputTotals: data.ioPortsTotal.map(item => this.pointsListSystemIoPortsTotalMapper.toDto(item)),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystemProperties): EditPointsListSystemPropertiesDto {
    return {
      systemTotal: this.pointsListSystemTotalMapper.toEditDto(data.systemTotal),
      allSystemsTotal: this.pointsListSystemTotalMapper.toEditDto(data.allSystemsTotal),
    };
  }
}
