import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { BasePricing } from '@dartsales/common/core/models/pricing/base-pricing';

import { ModuleProperties, ModulePropertiesInitArgs } from '../../module-properties';

import { MaterialModuleLockablePercentsStatus } from './material-lockable-percents';

/** Material module properties. */
export class MaterialModuleProperties extends ModuleProperties {

  /** Standard material default percents. */
  public readonly standardMaterials: BasePricing;

  /** Non standard material default percents. */
  public readonly nonStandardMaterials: BasePricing;

  /** Warranty percent of material module. */
  public readonly warrantyPercent: number;

  /** Escalation percent of material module. */
  public readonly escalationPercent: number;

  /** Freight percent of material module. */
  public readonly freightPercent: number;

  /** Sales tax percent of material module. */
  public readonly salesTaxPercent: number;

  /** Grand total cost. */
  public readonly grandTotalCost: number;

  /** Total direct cost. */
  public readonly totalDirectCost: number;

  /** Quantity. */
  public readonly quantity: number;

  /** Lockable percents status. */
  public override readonly lockablePercentsStatus: MaterialModuleLockablePercentsStatus;

  public constructor(data: MaterialModulePropertiesInitArgs) {
    super(data);
    this.standardMaterials = data.standardMaterials;
    this.nonStandardMaterials = data.nonStandardMaterials;
    this.warrantyPercent = data.warrantyPercent;
    this.escalationPercent = data.escalationPercent;
    this.freightPercent = data.freightPercent;
    this.salesTaxPercent = data.salesTaxPercent;
    this.grandTotalCost = data.grandTotalCost;
    this.quantity = data.quantity;
    this.totalDirectCost = data.totalDirectCost;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }
}

type MaterialModulePropertiesInitArgs = StrictOmit<ModulePropertiesInitArgs, 'lockablePercentsStatus'> & Pick<MaterialModuleProperties,
  'standardMaterials'
  | 'nonStandardMaterials'
  | 'warrantyPercent'
  | 'escalationPercent'
  | 'freightPercent'
  | 'salesTaxPercent'
  | 'grandTotalCost'
  | 'totalDirectCost'
  | 'quantity'
  | 'lockablePercentsStatus'
>;
