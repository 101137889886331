import { Injectable, inject } from '@angular/core';
import { Subject, Observable, map, shareReplay, switchMap, first, repeat, tap } from 'rxjs';

import { CreateChangeOrder, ChangeOrderId, PreviewChangeOrder } from '../models/estimate/change-order/change-order';

import { ChangeOrdersApiService } from './api/change-orders-api.service';
import { ProjectLayoutService } from './project-layout.service';
import { ProjectNavigationService } from './project-navigation.service';

/** Service with change orders for the current project. */
@Injectable()
export class ProjectChangeOrdersService {

  private readonly changeOrdersApiService = inject(ChangeOrdersApiService);

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly projectNavigationService = inject(ProjectNavigationService);

  private readonly refreshSubject = new Subject<void>();

  /** List of change orders for the project. */
  public readonly changeOrders$ = this.createChangeOrdersStream();

  /** Whether the project has change orders. */
  public readonly hasChangeOrders$ = this.createHasChangeOrdersStream();

  /** Refresh change orders. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  /**
   * Add a new change order to the project.
   * @param id Change order ID.
   * @param data Data required to create a new change order.
   */
  public addToProject(id: ChangeOrderId, data: CreateChangeOrder): Observable<ChangeOrderId> {
    return this.changeOrdersApiService.create(id, data).pipe(
      tap(() => this.refreshSubject.next()),
    );
  }

  /**
   * Delete a change order.
   * @param id Change order ID.
   */
  public remove(id: ChangeOrderId): Observable<void> {
    return this.changeOrdersApiService.deleteById(id).pipe(
      tap(() => {
        this.projectNavigationService.navigateToDashboard();
        this.refreshSubject.next();
      }),
    );
  }

  private createChangeOrdersStream(): Observable<PreviewChangeOrder[]> {
    return this.projectLayoutService.projectId$.pipe(
      switchMap(id => this.changeOrdersApiService.getList(id)),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createHasChangeOrdersStream(): Observable<boolean> {
    return this.changeOrders$.pipe(
      map(changeOrders => changeOrders.length > 0),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
