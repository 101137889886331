import { Directive, ContentChildren, QueryList, Input, ViewChildren, ForwardRefFn, Provider, forwardRef } from '@angular/core';

import { EditableTableColDirective } from './editable-table-column.directive';

/**
 * In this file we have two alternative approaches for declaring footer row element of EditableTable component.
 * We can use either use a directive or create a new component.
 * There is no preferable way, each solution is appropriate in different cases.
 * So it's up to you to decide which approach to use.
 */

/**
 * Create provider for a EditableTableFooterRowComponent.
 * @param factory Factory providing a reference to component class.
 */
export function tableFooterRowProviderFor(factory: ForwardRefFn): Provider {
  return [
    {
      provide: EditableTableFooterRowComponent,
      useExisting: forwardRef(factory),
      multi: true,
    },
  ];
}

/**
 * Base class for table footer row component.
 * You have to extend this class to create your own table row component compatible with EditableTable component.
 * @example
 * ```ts
 * ‌@Component({
 *   selector: 'dartsalesw-subcontractor-table-footer-row',
 *   templateUrl: './subcontractor-table-footer-row.component.html',
 *   providers: [tableFooterRowProviderFor(() => SubcontractorTableFooterRowComponent)],
 * })
 * export class SubcontractorTableFooterRowComponent extends EditableTableFooterRowComponent {
 *   // Component implementation.
 * }
 * ```
 * You can use this component inside EditableTable like this:
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table footer rows. -->
 *   <dartsalesw-subcontractor-table-footer-row />
 * </dartsalesc-editable-table>
 * ```
 */
@Directive()
export abstract class EditableTableFooterRowComponent {
  /** Table columns. */
  @ViewChildren(EditableTableColDirective)
  public readonly tableColumns?: QueryList<EditableTableColDirective>;

  /** Is total row. */
  @Input()
  public isTotalRow = false;
}

/**
 * Editable table footer row directive.
 * You can apply this directive to mark element inside EditableTable component as a table footer row.
 * @example
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table footer rows. -->
 *   <ng-container dartsalescEditableTableFooterRow>
 *     <!-- Columns definitions. -->
 *   </ng-container>
 * </dartsalesc-editable-table>
 * ```
 */
@Directive({
  selector: '[dartsalescEditableTableFooterRow]',
  providers: [tableFooterRowProviderFor(() => EditableTableFooterRowDirective)],
})
export class EditableTableFooterRowDirective extends EditableTableFooterRowComponent {
  /** Table columns. */
  @ContentChildren(EditableTableColDirective)
  public override readonly tableColumns?: QueryList<EditableTableColDirective>;
}
