import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { merge, toPath } from 'lodash-es';

import { AnyObject } from '@dartsales/common/core/utils/types/any-object';

import { AppError, AppValidationError } from '../../../models/errors/app-error';
import { catchHttpErrorResponse } from '../../../utils/rxjs/catch-http-error-response';
import { ApiErrorDto, MappedValidationErrorDto } from '../dto/validation-error.dto';
import { IValidationErrorMapper } from '../mappers';

/**
 * Could be a simple function that transform errors from DTO to domain-level errors
 * or an implementation of `IMapper` with implemented `validationErrorFromDto` method.
 */
type ErrorMapper<TDto extends Record<string, unknown>, TEntity extends Record<string, unknown>> =
  | IValidationErrorMapper<TDto, TEntity>
  | IValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/** Errors mapper. */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {
  /**
   * Maps `HttpErrorResponse` to an application-level error.
   * @param httpError Http error response.
   */
  private fromDto(httpError: HttpErrorResponse): AppError {
    const { statusText, error } = httpError;
    return new AppError(error?.detail ?? error?.title ?? statusText);
  }

  /**
   * Maps `HttpErrorResponse` to either `AppError` or `AppValidationError`.
   * @param httpError Http error.
   * @param mapper Mapper for backend-provided validation data into domain validation data.
   */
  public fromDtoWithValidationSupport<TDto extends AnyObject, TEntity extends AnyObject>(
    httpError: HttpErrorResponse,
    mapper: ErrorMapper<TDto, TEntity>,
  ): AppValidationError<TEntity> {
    if (httpError.status !== HttpStatusCode.BadRequest) {
      return this.fromDto(httpError);
    }

    const { error }: { readonly error: ApiErrorDto<TDto> | undefined; } = httpError;
    if (error?.errors == null) {
      return this.fromDto(httpError);
    }

    const mappedErrors = this.mapErrorsFromServer(error);

    const validationData =
      typeof mapper === 'function' ?
        mapper(mappedErrors) :
        mapper.validationErrorFromDto(mappedErrors);
    return new AppValidationError<TEntity>(error.detail ?? error.title, validationData);
  }

  /** RxJS operator that catches `HttpErrorResponse` and maps it into application error. */
  public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
    return catchHttpErrorResponse(error => {
      const appError = this.fromDto(error);
      return throwError(() => appError);
    });
  }

  /**
   * RxJS operator that catches `HttpErrorResponse` and maps it into application error that may contain validation data.
   * @param mapper Mapper for backend-provided validation data into domain validation data.
   */
  public catchHttpErrorToAppErrorWithValidationSupport<
    T,
    TDto extends Record<string, unknown>,
    TEntity extends Record<string, unknown>,
  >(mapper: ErrorMapper<TDto, TEntity>): MonoTypeOperatorFunction<T> {
    return catchHttpErrorResponse(error => {
      const appError = this.fromDtoWithValidationSupport<TDto, TEntity>(
        error,
        mapper,
      );
      return throwError(() => appError);
    });
  }

  private mapErrorsFromServer<
    TDto extends Record<string, unknown>,
  >(error: ApiErrorDto<TDto>): MappedValidationErrorDto<TDto> {
    return error.errors?.reduce((mapErrors, err) => {
      // Field name should always start with lowercase letter for correct mapping.
      const tokens = toPath(err.field).map(item => item[0].toLocaleLowerCase() + item.slice(1));

      let nestedErrors: NestedError = { [tokens[0]]: err.messages as string[] };

      if (tokens.length > 1) {
        const reverseTokens = tokens.reverse();
        nestedErrors = reverseTokens.slice(1).reduce<NestedError>((prev, cur) => {
          if (!Number.isNaN(Number(cur))) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return [...Array(Number(cur))].map(() => ({})).concat(prev) as any;
          }
          return {
            [cur]: prev,
          };
        }, { [reverseTokens[0]]: err.messages });
      }

      return merge(mapErrors, nestedErrors);
    }, { non_field_errors: [error.title] } as MappedValidationErrorDto<TDto>) ?? {};
  }
}

type NestedError = {
  readonly [x: string]: readonly string[] | NestedError;
};
