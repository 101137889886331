<div
  *ngIf="cellPrefix"
  class="prefix"
>
  <ng-content select="[dartsalescTableCellPrefix]" />
</div>
<dartsalesc-masked-number-cell-editor
  *ngIf="isEditMode$ | async; else rendererMode"
  [class.cell__has-prefix]="cellPrefix"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [mask]="percentMask"
/>
<ng-template #rendererMode>
  <dartsalesc-masked-number-cell-renderer
    [class.cell__has-prefix]="cellPrefix"
    [mask]="percentMask"
    [isFocusable]="(isFocusable$ | async) ?? false"
    [value]="formControl.value"
  />
</ng-template>
