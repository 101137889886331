import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { Sort } from '@dartsales/common/core/models/list-utilities/sort';

/** Service for managing sorting in table header row. */
@Injectable()
export class TableHeaderRowSortingService {
  private readonly activeSortSubject$ = new ReplaySubject<Sort>(1);

  /** Active sort. */
  public readonly activeSort$ = this.activeSortSubject$.asObservable();

  /**
   * Change sort.
   * @param nextSort Next sort options.
   */
  public changeSort(nextSort: Sort): void {
    this.activeSortSubject$.next(nextSort);
  }
}
