import { EstimateId } from '../../../estimate';

/** Short model of points list tab. We need it to display tab data in a list where we don't need all information from the points list. */
export class ShortPointsListTab {
  /** Tab UUID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Order. */
  public readonly order: number;

  /** Estimate ID. */
  public readonly estimateId: EstimateId;

  public constructor(data: ShortPointsListTabInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.order = data.order;
    this.estimateId = data.estimateId;
  }

}

type ShortPointsListTabInitArgs = ShortPointsListTab;
