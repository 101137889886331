import { Injectable, inject } from '@angular/core';

import { ExpensesTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-travel-cost';
import { EditExpensesTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/edit-expenses-travel-cost';
import { CreateExpensesTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/create-expenses-travel-cost';

import { CreateExpensesTravelCostDto, ExpensesTravelCostDto, EditExpensesTravelCostDto } from '../../../dto/estimate/modules/expenses/expenses-travel-cost.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

/** Expenses travel cost mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExpensesTravelCostMapper implements
  IMapper<ExpensesTravelCostDto, ExpensesTravelCost> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: ExpensesTravelCostDto): ExpensesTravelCost {
    return new ExpensesTravelCost({
      id: dto.id,
      expenseTypeId: dto.expenseTypeId,
      title: this.confirmedOverridableMapper.fromDto(dto.title),
      rate: this.confirmedOverridableMapper.fromDto(dto.rate),
      rateType: this.confirmedOverridableMapper.fromDto(dto.type),
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
      isDefault: dto.isDefault,
    });
  }

  /** @inheritdoc */
  public toDto(data: ExpensesTravelCost): ExpensesTravelCostDto {
    return {
      id: data.id,
      expenseTypeId: data.expenseTypeId,
      title: this.confirmedOverridableMapper.toDto(data.title),
      rate: this.confirmedOverridableMapper.toDto(data.rate),
      type: this.confirmedOverridableMapper.toDto(data.rateType),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      isDefault: data.isDefault,
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreateExpensesTravelCost): CreateExpensesTravelCostDto {
    return {
      title: data.title,
      rate: data.rate,
      type: data.rateType,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditExpensesTravelCost): EditExpensesTravelCostDto {
    return {
      id: data.id,
      title: this.confirmedOverridableMapper.toDto(data.title),
      rate: this.confirmedOverridableMapper.toDto(data.rate),
      type: this.confirmedOverridableMapper.toDto(data.rateType),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
    };
  }
}
