<div
  #sentinel
  class="sentinel"
></div>
<ng-container *ngIf="isContentVisible$ | async; else placeholder">
  <ng-container
    *ngIf="template"
    [ngTemplateOutlet]="template"
  />
</ng-container>
<ng-template #placeholder>
  <ng-content select="[placeholder]" />
</ng-template>
