import { Injectable, inject } from '@angular/core';

import { PointsListOverridableItemTotal } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-overridable-item-total';

import { PointsListItemTotalDto } from '../../../dto/estimate/modules/points-list/points-list-item-total.dto';
import { IMapper } from '../../../mappers';
import { OverridableMapper } from '../../../overridable.mapper';

/** Points list overridable item total mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListOverridableItemTotalMapper implements IMapper<PointsListItemTotalDto, PointsListOverridableItemTotal> {

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListItemTotalDto): PointsListOverridableItemTotal {
    return new PointsListOverridableItemTotal({
      directCost: this.overridableMapper.fromDto(dto.directCost),
      materialCost: this.overridableMapper.fromDto(dto.materialCost),
      laborCost: dto.laborCost,
      laborHours: dto.laborHours,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListOverridableItemTotal): PointsListItemTotalDto {
    return {
      directCost: this.overridableMapper.toDto(data.directCost),
      materialCost: this.overridableMapper.toDto(data.materialCost),
      laborCost: data.laborCost,
      laborHours: data.laborHours,
    };
  }
}
