<form
  class="number-range-form"
  [formGroup]="rangeForm"
>
  <mat-form-field class="no-label">
    <input
      matInput
      type="number"
      placeholder="From"
      [formControl]="rangeForm.controls.start"
      [errorStateMatcher]="errorStateMatcher"
      (blur)="emitTouched()"
    />
  </mat-form-field>
  <div class="number-range-form__dash"></div>
  <mat-form-field class="no-label">
    <input
      matInput
      type="number"
      placeholder="To"
      [formControl]="rangeForm.controls.end"
      [errorStateMatcher]="errorStateMatcher"
      (blur)="emitTouched()"
    />
  </mat-form-field>
</form>
