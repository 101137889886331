import { Injectable } from '@angular/core';

import { IMapperToDto } from '../../../mappers';
import { LaborHoursRateDto } from '../../../dto/estimate/modules/labor/labor-hours-rate.dto';

/** Labor hours rate entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborHoursRateMapper implements IMapperToDto<LaborHoursRateDto, number> {

  /** @inheritdoc */
  public toDto(data: number): LaborHoursRateDto {
    return {
      hours: data,
    };
  }
}
