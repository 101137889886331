import { AmountPercent } from '../amount-percent';
import { OverridableBasePricing, OverridableBasePricingInitArgs } from '../../pricing/overridable-base-pricing';
import { ModuleLockablePercentsStatus } from '../module-lockable-percents';

/** Module properties. */
export class ModuleProperties extends OverridableBasePricing {

  /** Whether to disable escalation, because compound escalation is active. */
  public readonly disableEscalation: boolean;

  /** Customer markup. */
  public readonly customerMarkup: AmountPercent;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  /** Current module sell price. */
  public get currentSellPrice(): number {
    return this.sellPrice.combinedValue;
  }

  public constructor(data: ModulePropertiesInitArgs) {
    super(data);
    this.disableEscalation = data.disableEscalation;
    this.customerMarkup = data.customerMarkup;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }

  /**
   * Get properties with reset override properties.
   * @returns New instance of ModuleProperties.
   */
  public getPropertiesWithResetOverride(): ModuleProperties {
    return new ModuleProperties({
      ...this.resetPricingOverride(),
      customerMarkup: this.customerMarkup,
      disableEscalation: this.disableEscalation,
      lockablePercentsStatus: this.lockablePercentsStatus,
    });
  }
}

export type ModulePropertiesInitArgs = OverridableBasePricingInitArgs &
  Pick<ModuleProperties, 'customerMarkup' | 'disableEscalation' | 'lockablePercentsStatus'>;
