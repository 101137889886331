/** Points list part group properties. */
export class PointsListPartGroupProperties {
  /** Parts count. */
  public readonly partsCount: number;

  public constructor(data: PointsListPartGroupPropertiesInitArgs) {
    this.partsCount = data.partsCount;
  }
}

type PointsListPartGroupPropertiesInitArgs = PointsListPartGroupProperties;
