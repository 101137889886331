import { isEmpty, textContains, textEquals } from '../../shared-filter-operators';
import { FilterData } from '../../filter-data';
import { stringListSelect } from '../../shared-filter-select-operators';

/** Company field for filter. */
export enum CompanyField {
  StreetAddress = 'companyAddressStreet',
  City = 'companyAddressCity',
  State = 'companyAddressState',
  Country = 'companyAddressCountry',
  ZIP = 'companyAddressZip',
  PhoneNumber = 'companyPhoneNumber',
  FaxNumber = 'companyFaxNumber',
}

export namespace CompanyField {
  export const GROUP_NAME = 'Company';

  const streetAddress = {
    type: CompanyField.StreetAddress,
    label: 'Street Address (Company)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyCity = {
    type: CompanyField.City,
    label: 'City (Company)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyState = {
    type: CompanyField.State,
    label: 'State / Province (Company)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyCountry = {
    type: CompanyField.Country,
    label: 'Country (Company)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyZip = {
    type: CompanyField.ZIP,
    label: 'ZIP / Postal Code (Company)',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyPhoneNumber = {
    type: CompanyField.PhoneNumber,
    label: 'Phone Number',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const companyFaxNumber = {
    type: CompanyField.FaxNumber,
    label: 'Fax Number',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<CompanyField>;

  const ALL_COMPANY_FIELDS = [
    streetAddress,
    companyCity,
    companyState,
    companyCountry,
    companyZip,
    companyPhoneNumber,
    companyFaxNumber,
  ] as const;

  /** Get all fields. */
  export function getAllFields(): typeof ALL_COMPANY_FIELDS {
    return ALL_COMPANY_FIELDS;
  }
}
