<div
  *ngIf="{
    options: (selectableOptions$ | async) ?? [],
    preselectedOptions: (preselectedOptions$ | async) ?? []
  } as data"
  class="container"
>
  <mat-form-field
    *ngIf="!hideSearch"
    class="filter-search no-padding"
  >
    <mat-icon matPrefix>search</mat-icon>
    <dartsalesw-search-input
      placeholder="Search options"
      [formControl]="searchControl"
    />
  </mat-form-field>

  <div
    *ngIf="data.options.length > 0; else noOptionsBlock"
    class="options"
  >
    <mat-checkbox
      *ngFor="let option of data.preselectedOptions; trackBy: trackByOption"
      [checked]="true"
      [disabled]="true"
    >
      {{ option.label }}
    </mat-checkbox>
    <mat-checkbox
      [checked]="areAllOptionsSelected(data.options)"
      [indeterminate]="
        areSomeOptionsSelected && !areAllOptionsSelected(data.options)
      "
      (change)="toggleAll(data.options)"
    >
      Select All
    </mat-checkbox>
    <mat-checkbox
      *ngFor="let option of data.options; trackBy: trackByOption"
      class="child-option"
      [checked]="isOptionSelected(option)"
      (change)="changeOptionSelection(option, $event.checked)"
    >
      {{ option.label }}
    </mat-checkbox>
  </div>

  <ng-template #noOptionsBlock>
    <div class="no-options">No options found</div>
  </ng-template>
</div>
