import { LaborTask } from '@dartsales/common/core/models/resources/labor-task';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { LaborRole } from '@dartsales/common/core/models/resources/labor-role';

import { PointsListLaborRoleRate } from './points-list-labor-role-rate';
import { PointsListSystem } from './points-list-system';

/** Points list system task rate edit model. */
export class EditPointsListSystemTaskRate {
  /** System ID. */
  public readonly systemId: PointsListSystem['id'];

  /** Task ID. */
  public readonly taskId: LaborTask['id'];

  /** Labor role rate. */
  public readonly roleRate: PointsListLaborRoleRate;

  /** Labor role ID. */
  public readonly laborRoleId: ConfirmedOverridable<LaborRole['id']>;

  public constructor(data: EditPointsListSystemTaskRateInitArgs) {
    this.systemId = data.systemId;
    this.taskId = data.taskId;
    this.roleRate = data.roleRate;
    this.laborRoleId = data.laborRoleId;
  }
}

type EditPointsListSystemTaskRateInitArgs = EditPointsListSystemTaskRate;
