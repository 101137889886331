/** Project floor. */
export class ProjectFloor {

  /** Id. */
  public readonly id: string;

  /** Floor designation. */
  public readonly floorName: string;

  /** Nickname. */
  public readonly nickname: string;

  /** Approximate square (in sq. Ft.). */
  public readonly approximateSquare: number;

  // TODO: Add more fields according to requirements.

  public constructor(data: ProjectFloorInitArgs) {
    this.id = data.id;
    this.floorName = data.floorName;
    this.nickname = data.nickname;
    this.approximateSquare = data.approximateSquare;
  }
}

type ProjectFloorInitArgs = ProjectFloor;
