<div class="actions">
  <div class="actions__buttons">
    <span class="body-2">Expand:</span>

    <ng-container
      *ngFor="
        let action of actions.expand;
        let index = index;
        trackBy: trackByAction
      "
    >
      <mat-divider
        *ngIf="index !== 0"
        class="actions__divider"
        [vertical]="true"
      />
      <button
        class="actions__button"
        mat-button
        type="button"
        (click)="action.click()"
      >
        {{ action.label }}
      </button>
    </ng-container>
  </div>

  <div class="actions__buttons">
    <span class="body-2">Collapse:</span>
    <ng-container
      *ngFor="
        let action of actions.collapse;
        let index = index;
        trackBy: trackByAction
      "
    >
      <mat-divider
        *ngIf="index !== 0"
        class="actions__divider"
        [vertical]="true"
      />
      <button
        class="actions__button"
        mat-button
        type="button"
        (click)="action.click()"
      >
        {{ action.label }}
      </button>
    </ng-container>
  </div>
</div>
