import { Injectable, inject } from '@angular/core';
import { defer, finalize, Observable, shareReplay, Subject } from 'rxjs';

import { AppUrlsConfig } from '../app-urls.config';

import { AbstractHubService } from './abstract-hub.service';

const events = {
  messageReceived: 'client-receive-message',
} as const;

/** Web Socket API service. */
@Injectable({
  providedIn: 'root',
})
export class WebSocketApiService extends AbstractHubService {

  private readonly apiUrls = inject(AppUrlsConfig);

  /** @inheritdoc */
  protected readonly connection = this.createHubConnection(this.apiUrls.hubs.messages);

  private readonly messages$ = new Subject<string>();

  private isSubscribedToMessages = false;

  /** Get messages. */
  public getMessages(): Observable<string> {
    if (this.isSubscribedToMessages === false) {
      this.subscribeToMessages();
    }
    return defer(() => this.messages$).pipe(
      finalize(() => this.unsubscribeFromMessages()),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private subscribeToMessages(): void {
    this.isSubscribedToMessages = true;
    this.connection.start();

    this.connection.on(events.messageReceived, data => {
      this.messages$.next(data);
    });

    this.connection.onclose(() => {
      this.unsubscribeFromMessages();
    });
  }

  private unsubscribeFromMessages(): void {
    this.isSubscribedToMessages = false;
    this.connection.off(events.messageReceived);
    this.connection.stop();
  }
}
