import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Alert message. */
@Component({
  selector: 'dartsalesw-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageComponent {

  /** Message. */
  @Input()
  public message = '';
}
