import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map, of, switchMap, first, iif } from 'rxjs';

import { StorageService } from '@dartsales/common/core/services/storage.service';
import { PointsListConfirmDialogData, PointsListConfirmDialogResult, PointsListConfirmDialogInnerResult } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-confirm-dialog/points-list-confirm-dialog-data';
import { PointsListConfirmDialogComponent } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-confirm-dialog/points-list-confirm-dialog.component';

export const POINTS_LIST_CONFIRM_DIALOG_SKIP_STORAGE_KEY = 'confirm-dialog__points-list';

/** Points list table tab service. */
@Injectable({
  providedIn: 'root',
})
export class PointListConfirmDialogService {
  private readonly dialogService = inject(MatDialog);

  private readonly storageService = inject(StorageService);

  private readonly shouldSkip$ = this.storageService.get<boolean>(POINTS_LIST_CONFIRM_DIALOG_SKIP_STORAGE_KEY).pipe(
    first(),
    map(Boolean),
  );

  /**
   * Open dialog.
   * @param data Data dialog.
   */
  public openDialog(data: PointsListConfirmDialogData): Observable<PointsListConfirmDialogResult> {
    return this.shouldSkip$.pipe(
      switchMap(shouldSkip => {
        if (shouldSkip) {
          return of(true);
        }
        return this.openDialogModal(data);
      }),
    );
  }

  private openDialogModal(data: PointsListConfirmDialogData): Observable<PointsListConfirmDialogResult> {
    return this.dialogService.open<
      PointsListConfirmDialogComponent,
      PointsListConfirmDialogData,
      PointsListConfirmDialogInnerResult
    >(PointsListConfirmDialogComponent, {
      data,
      autoFocus: false,
    })
      .afterClosed()
      .pipe(
        switchMap(result => {
          if (result != null) {
            return iif(
              () => result.shouldSkip,
              this.enableSkip(),
              of(null),
            ).pipe(
              map(() => result.value),
            );
          }
          return of(undefined);
        }),
      );
  }

  /**
   * Enable skip for dialog.
   * @param key Key to differentiate dialogs.
   */
  private enableSkip(): Observable<void> {
    return this.storageService.save(POINTS_LIST_CONFIRM_DIALOG_SKIP_STORAGE_KEY, true);
  }
}
