<dartsalesc-base-dialog
  class="confirm-dialog"
  [dialogTitle]="title"
>
  <div class="dialog-body">
    <p class="body-1">{{ text }}</p>
    <mat-checkbox [formControl]="skipControl">Do not ask me again</mat-checkbox>
  </div>
  <div
    dialogFooter
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      type="button"
      class="dialog-actions__button"
      (click)="onCancelClick()"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      type="button"
      class="dialog-actions__button"
      color="primary"
      (click)="onConfirmClick()"
    >
      Confirm
    </button>
  </div>
</dartsalesc-base-dialog>
