import { Directive, Input, inject } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';

import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { PermissionsService } from '@dartsales/common/core/services/permissions.service';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';

import { AbstractPermissionDirective } from './permissions/abstract-permission.directive';

/** Directive regulating availability of content depending on user permissions. */
@Directive({
  selector: '[dartsalescUserPermissions]',
})
export class UserPermissionsDirective extends AbstractPermissionDirective {

  /** Permissions of which regulates availability of content. */
  @Input()
  public set dartsalescUserPermissions(value: readonly UserPermission[]) {
    this.userPermissions$.next([...value]);
  }

  private readonly permissionsService = inject(PermissionsService);

  private readonly userPermissions$ = new BehaviorSubject<UserPermission[]>([]);

  /** @inheritdoc */
  protected readonly hasPermissions$ = this.createHasPermissionStream();

  private createHasPermissionStream(): Observable<boolean> {
    return this.userPermissions$
      .pipe(
        filterNull(),
        switchMap(userPermissions => this.permissionsService.hasPermission(userPermissions)),
      );
  }
}
