import { Injectable } from '@angular/core';

import { FilterHelper } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-helper';
import { MultiConditionFilter } from '@dartsales/common/core/models/filters/multi-condition-filters/multi-condition-filter';

import { MultiConditionFilterDto } from '../dto/filters/multi-condition-filter.dto';

/** Mapper for multi condition filter. */
@Injectable({ providedIn: 'root' })
export class MultiConditionFilterMapper<T extends string> {
  /**
   * Map model to dto.
   * @param data Model.
   * @param filterHelper Filter helper class.
   */
  public toDto(data: MultiConditionFilter<T>, filterHelper: FilterHelper<T>): MultiConditionFilterDto<T> {
    const operator = filterHelper.getOperator(data.field, data.operator);
    const values = operator?.mapper.getParamsArray(data.value) ?? [];

    return {
      propertyName: data.field,
      operatorType: data.operator,
      values,
    };
  }

  /**
   * Map model from dto.
   * @param dto Dto.
   * @param filterHelper Filter helper class.
   */
  public fromDto(dto: MultiConditionFilterDto<T>, filterHelper: FilterHelper<T>): MultiConditionFilter<T> {
    const operator = filterHelper.getOperator(dto.propertyName, dto.operatorType);
    const value = operator?.mapper.parseValueFromString([...dto.values]) ?? null;
    return {
      field: dto.propertyName,
      operator: dto.operatorType,
      value,
    };
  }
}
