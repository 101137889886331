<dartsalesc-base-dialog
  class="confirm-dialog"
  [dialogTitle]="title"
>
  <p class="dialog-body">
    {{ text }}
  </p>
  <div
    dialogFooter
    class="dialog-actions"
  >
    <button
      mat-stroked-button
      type="button"
      class="dialog-actions__button"
      [mat-dialog-close]="false"
    >
      {{ cancelButtonText }}
    </button>
    <button
      mat-flat-button
      type="button"
      class="dialog-actions__button"
      [color]="confirmButtonPalette"
      [mat-dialog-close]="true"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</dartsalesc-base-dialog>
