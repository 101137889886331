<mat-form-field class="no-label">
  <dartsalesc-chips-list
    placeholder="Add items"
    [formControl]="chipsFormControl"
    [errorStateMatcher]="matcher"
  />
  <mat-error>
    <dartsalesc-validation-message
      [errors]="chipsFormControl.errors ?? formControl.errors"
    />
  </mat-error>
</mat-form-field>
