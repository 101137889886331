import { Observable, map } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';
import { EditPointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-task-rate';
import { PointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-task-rate';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';

import { PointsListAbstractCommand } from '../points-list-abstract-command';

/** Update points list system task rates command. */
export class UpdateSystemTaskRatesCommand extends PointsListAbstractCommand<UpdateSystemTaskRatesCommandInitArgs> {
  private readonly pointsListSystemApiService = this.injector.get(PointsListSystemApiService);

  /** @inheritdoc */
  public override execute(): Observable<void> {
    return this.updateSystemTaskRates(this.args.newValue);
  }

  /** @inheritdoc */
  public override undo(): Observable<void> {
    const oldTaskRates = this.args.oldValue.map(taskRate => {
      const editedTaskRate = this.args.newValue.find(editedRate => editedRate.taskId === taskRate.taskId);
      assertNonNull(editedTaskRate);
      return new EditPointsListSystemTaskRate({
        ...taskRate,
        systemId: editedTaskRate.systemId,
      });
    });

    return this.updateSystemTaskRates(oldTaskRates);
  }

  private updateSystemTaskRates(updatedItems: readonly EditPointsListSystemTaskRate[]): Observable<void> {
    return this.pointsListSystemApiService.updateSystemTaskRates(this.args.estimateId, updatedItems).pipe(
      map(() => undefined),
    );
  }
}

type UpdateSystemTaskRatesCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Old value. */
  readonly oldValue: readonly PointsListSystemTaskRate[];

  /** New value. */
  readonly newValue: readonly EditPointsListSystemTaskRate[];
};
