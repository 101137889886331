import { Injectable } from '@angular/core';

import { PointsListPartTotal } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-part-total';

import { IMapper } from '../../../mappers';
import { PointsListPartTotalDto } from '../../../dto/estimate/modules/points-list/points-list-item-total.dto';

/** Points list part total mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartTotalMapper implements IMapper<PointsListPartTotalDto, PointsListPartTotal> {

  /** @inheritdoc */
  public fromDto(dto: PointsListPartTotalDto): PointsListPartTotal {
    return new PointsListPartTotal({
      directCost: dto.directCost,
      materialCost: dto.materialCost,
      laborCost: dto.laborCost,
      laborHours: dto.laborHours,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListPartTotal): PointsListPartTotalDto {
    return {
      directCost: data.directCost,
      materialCost: data.materialCost,
      laborCost: data.laborCost,
      laborHours: data.laborHours,
    };
  }
}
