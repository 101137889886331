import { Directive, Input, inject } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';

import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';

import { AbstractPermissionDirective } from './permissions/abstract-permission.directive';

// TODO (DART-69): Move this service to project module, because it use project layout service
/** Directive regulating availability of content depending on current organization permissions. */
@Directive({
  selector: '[dartsalescCurrentOrganizationPermissions]',
})
export class CurrentOrganizationPermissionsDirective extends AbstractPermissionDirective {

  /** Permissions of which regulates availability of content. */
  @Input()
  public set dartsalescCurrentOrganizationPermissions(value: readonly UserPermission[]) {
    this.userPermissions$.next([...value]);
  }

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly userPermissions$ = new BehaviorSubject<UserPermission[]>([]);

  /** @inheritdoc */
  protected readonly hasPermissions$ = this.createHasPermissionStream();

  private createHasPermissionStream(): Observable<boolean> {
    return this.userPermissions$.pipe(filterNull())
      .pipe(
        switchMap(permissions =>
          this.projectLayoutService.hasPermissionInCurrentOrganization(permissions)),
      );
  }
}
