import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/** Footer for the base dialog. */
@Component({
  selector: 'dartsalesc-base-dialog-footer[dialogFooter]',
  templateUrl: './base-dialog-footer.component.html',
  styleUrls: ['./base-dialog-footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogFooterComponent {

  /** Flag indicating whether an action is being in process. */
  @Input()
  public isLoading: boolean | null = false;

  /** Text for the action button. */
  @Input()
  public actionButtonText = 'Save';

  /** Text for the cancel button. */
  @Input()
  public cancelButtonText = 'Cancel';

  /** Text for the action button. */
  @Input()
  public actionButtonColor: ThemePalette = 'primary';

  /** Action button click event. */
  @Output()
  public readonly actionButtonClick = new EventEmitter<void>();

  /** Cancel button click event. */
  @Output()
  public readonly cancelButtonClick = new EventEmitter<void>();
}
