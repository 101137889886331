import { BidLead } from '../../enums/bid-lead';
import { BidMarket } from '../../enums/bid-market';

/** Project Bid. */
export class ProjectBid {

  // TODO (DART-18): Probably use 'number' because markets and bidCategories will be ids.
  /** Markets. */
  public readonly markets: readonly BidMarket[];

  /** Bid categories. */
  public readonly categoryIds: readonly number[];

  /** Lead. */
  public readonly lead: BidLead | null;

  /** Competing bidders. */
  public readonly competingBidderNames: readonly string[];

  /** Bid recipients. */
  public readonly bidRecipientBidderNames: readonly string[];

  public constructor(data: ProjectBidInitArgs) {
    this.markets = data.markets;
    this.categoryIds = data.categoryIds;
    this.lead = data.lead;
    this.competingBidderNames = data.competingBidderNames;
    this.bidRecipientBidderNames = data.bidRecipientBidderNames;
  }
}

type ProjectBidInitArgs = ProjectBid;
