<ng-container *ngIf="isBulkUpdateAvailable">
  <dartsalesc-bulk-update-indicator />
  <div
    *ngIf="isPopoverOpened"
    class="border"
  >
    <!-- Empty. -->
  </div>
  <button
    type="button"
    class="indicator-button"
    matTooltipPosition="after"
    [style.--max-indicator-button-size.%]="maxWidth"
    [tp]="popover"
    [matTooltip]="getTooltipText()"
    [matTooltipDisabled]="isPopoverOpened"
    (click)="stopPropagation($event)"
  >
    <!-- Empty. -->
  </button>

  <ng-template
    #popover
    let-hide
  >
    <div
      class="popover"
      [style.--max-popover-width.px]="maxPopoverWidth"
    >
      <h6 class="headline-6 popover__header">
        {{ headerText }}
      </h6>
      <div class="popover__content">
        <ng-content />
      </div>

      <div class="popover__buttons">
        <button
          mat-flat-button
          type="button"
          color="warn"
          (click)="onButtonClick(bulkUpdateOption.ResourceValue)"
        >
          Apply Update
        </button>
        <button
          mat-flat-button
          type="button"
          (click)="onButtonClick(bulkUpdateOption.CurrentValue)"
        >
          Discard Update
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>
