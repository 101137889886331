import { AbstractControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/** Table cell control error state matcher. */
export class TableCellControlErrorStateMatcher implements ErrorStateMatcher {

  public constructor(
    public readonly ngControl: NgControl,
  ) { }

  /** @inheritdoc */
  public isErrorState(): boolean {
    return this.shouldDisplayError(this.ngControl.control) ?? false;
  }

  private shouldDisplayError(control: AbstractControl | null): boolean {
    return control !== null &&
    (control.touched || control.dirty) &&
    control.errors != null &&
    (control.parent?.pristine != null && !control.parent.pristine);
  }
}
