import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

import { PointsListSystemClassification } from './points-list-system-classification';

/** Points list system details. */
export class PointsListSystemDetails {

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Description. */
  public readonly description: ConfirmedOverridable<string>;

  /** Classification. */
  public readonly classification: ConfirmedOverridable<PointsListSystemClassification>;

  /** Sales notes. */
  public readonly salesNotes: readonly string[];

  /** Typical count. */
  public readonly typicalCount: number;

  /** Instance names. */
  public readonly instanceNames: readonly string[];

  /** Tags. */
  public readonly tags: readonly string[];

  public constructor(data: PointsListSystemDetailsInitArgs) {
    this.name = data.name;
    this.description = data.description;
    this.classification = data.classification;
    this.salesNotes = data.salesNotes;
    this.typicalCount = data.typicalCount;
    this.instanceNames = data.instanceNames;
    this.tags = data.tags;
  }
}

type PointsListSystemDetailsInitArgs = PointsListSystemDetails;
