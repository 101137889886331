import { Directive, Input, TemplateRef, inject } from '@angular/core';

import { EditableTableColumnConfig } from '@dartsales/common/core/models/list-utilities/editable-table-column-config';

type EditableTableCellDirectiveContext = {

  /** Table column info. */
  readonly $implicit: EditableTableColumnConfig;
};

/**
 * Editable table cell directive.
 * This directive allows you to define template for table cell inside a column.
 * This directive is designed to be used inside EditableTableColDirective.
 * @example
 * ```html
 * <ng-container dartsalescEditableTableCol="name">
 *   <ng-container *dartsalescEditableTableCell>
 *     <!-- Cell layout. -->
 *   </ng-container>
 * </ng-container>
 * ```
 * We can also get column config for the cell as well as
 * pass some inputs to the directive.
 * @example
 * ```html
 * <ng-container *dartsalescEditableTableCell="let col; colSpan: 2" />
 * ```
 */
@Directive({
  selector: '[dartsalescEditableTableCell]',
})
export class EditableTableCellDirective {
  /** Cell 'colspan'. Default value is 1. */
  @Input()
  public dartsalescEditableTableCellColSpan = 1;

  /** Template. */
  public readonly template = inject<TemplateRef<unknown>>(TemplateRef);

  /** @inheritdoc */
  public static ngTemplateContextGuard(
    _dir: EditableTableCellDirective,
    ctx: unknown,
  ): ctx is EditableTableCellDirectiveContext {
    return true;
  }
}
