import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { PointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/points-list-tab';
import { BatchEditPointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/edit-points-list-tab';
import { CreatePointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/create-points-list-tab';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { buildHttpParams } from '@dartsales/common/core/utils/build-http-params';
import { Sort } from '@dartsales/common/core/models/list-utilities/sort';
import { ShortPointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/short-points-list-tab';

import { AppErrorMapper } from '../../mappers/errors/app-error.mapper';
import { EstimateId } from '../../../models/estimate/estimate';
import { PointsListTabDto, ShortPointsListTabDto } from '../../mappers/dto/estimate/modules/points-list/tab/points-list-tab.dto';
import { PointsListTabMapper } from '../../mappers/estimate/modules/points-list/points-list-tab.mapper';
import { AppUrlsConfig } from '../app-urls.config';
import { SortMapper } from '../../mappers/sort.mapper';

import { PointsListGroupsApiService } from './points-list-groups-api.service';

/** Points list tabs API service. */
@Injectable({
  providedIn: 'root',
})
export class PointsListTabApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly pointsListTabsMapper = inject(PointsListTabMapper);

  private readonly pointsListGroupsApiService = inject(PointsListGroupsApiService);

  private readonly sortMapper = inject(SortMapper);

  /**
   * Get points list tab by ID.
   * @param estimateId Estimate ID.
   * @param tabId Tab ID.
   * @param sort Sort.
   */
  public getTabById(estimateId: EstimateId, tabId: PointsListTab['id'], sort?: Sort): Observable<PointsListTab> {
    const url = this.apiUrls.pointsListApi.tabs.getByTabId(estimateId, tabId);
    const params = buildHttpParams({
      ...this.sortMapper.toDto(sort),
    });

    return this.http.get<PointsListTabDto>(url, { params }).pipe(
      map(dto => this.pointsListTabsMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get points list tabs by estimate ID.
   * @param estimateId Estimate ID.
   * @param sort Sort.
   */
  public getTabs(estimateId: EstimateId, sort?: Sort): Observable<ShortPointsListTab[]> {
    const params = buildHttpParams({
      ...this.sortMapper.toDto(sort),
    });
    return this.http.get<ShortPointsListTabDto[]>(this.apiUrls.pointsListApi.tabs.get(estimateId), { params }).pipe(
      map(response => response.map(dto => this.pointsListTabsMapper.fromShortDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create new points list tab.
   * @param estimateId Estimate ID.
   * @param newTab New tab.
   */
  public createTab(estimateId: EstimateId, newTab: CreatePointsListTab): Observable<PointsListTab> {
    const newTabDto = this.pointsListTabsMapper.toCreateDto(newTab);
    return this.http.post<PointsListTabDto>(this.apiUrls.pointsListApi.tabs.create(estimateId), newTabDto).pipe(
      map(dto => this.pointsListTabsMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update points list tabs.
   * @param estimateId Estimate ID.
   * @param tabs Tabs to update.
   */
  public updateTabs(estimateId: EstimateId, tabs: readonly BatchEditPointsListTab[]): Observable<PointsListTab[]> {
    const updateDto = tabs.map(tab => this.pointsListTabsMapper.toBatchEditDto(tab));
    return this.http.put<PointsListTabDto[]>(this.apiUrls.pointsListApi.tabs.update(estimateId), updateDto).pipe(
      map(response => response.map(dto => this.pointsListTabsMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Duplicate tabs.
   * @param estimateId Estimate ID.
   * @param tabIds Tab IDs to duplicate.
   */
  public duplicateTabs(estimateId: EstimateId, tabIds: readonly PointsListTab['id'][]): Observable<void> {
    return this.http.post<void>(this.apiUrls.pointsListApi.tabs.duplicate(estimateId), tabIds).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete tabs.
   * @param estimateId Estimate ID.
   * @param tabIds Tab IDs to delete.
   */
  public deleteTabs(estimateId: EstimateId, tabIds: readonly PointsListTab['id'][]): Observable<void> {
    return this.pointsListGroupsApiService.delete(estimateId, new PointsListAllEntitiesIds({
      tabIds,
    })).pipe(
      map(() => undefined),
    );
  }
}
