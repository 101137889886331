import { PointsListMaterialLaborItemTotals, PointsListMaterialLaborTotals } from './points-list-material-labor-item-totals';

/** Points list labor totals. */
export class PointsListLaborTotals extends PointsListMaterialLaborTotals<PointsListLaborRoleTotals> {

  /** Hours. */
  public readonly hours: number;

  public constructor(data: PointsListLaborRolesTotalsInitArgs) {
    super(data);
    this.hours = data.hours;
  }
}

type PointsListLaborRolesTotalsInitArgs = PointsListLaborTotals;

/** Points list labor role totals. */
export class PointsListLaborRoleTotals extends PointsListMaterialLaborItemTotals {

  /** Hours. */
  public readonly hours: number;

  public constructor(data: PointsListLaborRoleTotalsInitArgs) {
    super(data);
    this.hours = data.hours;
  }
}

type PointsListLaborRoleTotalsInitArgs = PointsListLaborRoleTotals;
