<div class="error-card">
  <div class="error-card-logo">
    <img
      class="error-card-logo__image"
      src="/assets/images/division25-logo.svg"
      alt="Logo"
    />
  </div>
  <h1 class="error-card__header">Access Denied!</h1>
  <p class="error-card__body">
    You don't have permissions to access this page.
  </p>

  <footer class="error-card__footer">
    <a
      mat-flat-button
      color="primary"
      class="error-card__button"
      [routerLink]="routePaths.welcome.children.exploreProjects.url"
    >
      Go to the Welcome page
    </a>
  </footer>
</div>
