import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { AmountPercent, AmountPercentValues } from '@dartsales/common/core/models/estimate/amount-percent';
import { calculateFractionByPercent, calculatePercentByFraction } from '@dartsales/common/core/utils/percents';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { DomainCostCalculator } from './domain-cost-calculator';

/** Domain contingency calculator. */
export namespace DomainContingencyCalculator {

  type CalculateContingencyParams = {

    /** Value. */
    readonly value: AmountPercentValues;

    /** Units. */
    readonly units: AmountCalcUnits;
  } & DomainCostCalculator.DirectCostParams;

  type CalculateOverridableContingencyParams = StrictOmit<CalculateContingencyParams, 'value'> & {

    /** Value. */
    readonly value: Overridable<AmountPercentValues>;
  };

  /**
   * Calculate contingency.
   * @param args Arguments.
   */
  export function calculateContingency({
    directCost,
    value,
    units,
  }: CalculateContingencyParams): AmountPercent {
    const { amount, percent } = value;

    if (units === AmountCalcUnits.Amount) {
      if (amount === undefined) {
        throw new Error(`Amount isn't provided to contingency calculation by Amount units.`);
      }

      return new AmountPercent({ amount, percent: calculatePercentByFraction(directCost, amount) });
    }

    if (percent === undefined) {
      throw new Error(`Percent isn't provided to contingency calculation by Percent units.`);
    }

    return new AmountPercent({ amount: calculateFractionByPercent(directCost, percent), percent });
  }

  /**
   * Calculate contingency for overridable value.
   * @param args Arguments.
   */
  export function calculateOverridableContingency({
    directCost,
    value,
    units,
  }: CalculateOverridableContingencyParams): Overridable<AmountPercent> {
    return new Overridable({
      initial: DomainContingencyCalculator.calculateContingency({
        directCost,
        value: value.initial,
        units,
      }),
      override: value.override !== null ? DomainContingencyCalculator.calculateContingency({
        directCost,
        value: value.override,
        units,
      }) : null,
    }) ;
  }
}
