import { Injectable, inject } from '@angular/core';

import { CreatePointsListPartDetails } from '@dartsales/common/core/models/estimate/modules/points-list/part/create-points-list-part-details';
import { PointsListPartDetails } from '@dartsales/common/core/models/estimate/modules/points-list/part/points-list-part-details';
import { EditPointsListPartDetails } from '@dartsales/common/core/models/estimate/modules/points-list/part/edit-points-list-part-details';

import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { CreatePointsListPartDataDto, EditPointsListPartDataDto, PointsListPartDataDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-data.dto';
import { IMapper } from '../../../mappers';
import { EstimatePartPortMapper } from '../../../components/estimate-part/estimate-part-port.mapper';
import { EstimatePartStackedPortMapper } from '../../../components/estimate-part/estimate-part-stacked-port.mapper';
import { EstimatePartCustomPropertyMapper } from '../../../components/estimate-part/estimate-part-custom-property.mapper';
import { LaborTaskMinutesMapper } from '../../../components/detailed-organization-component/labor-task-minutes.mapper';
import { ComponentAttachmentMapper } from '../../../components/component-attachment.mapper';

import { OverridableLaborTaskMinutesMapper } from './overridable-labor-task-minutes.mapper';

/** Points list part details mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartDetailsMapper implements IMapper<PointsListPartDataDto, PointsListPartDetails> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly estimatePartPortMapper = inject(EstimatePartPortMapper);

  private readonly estimatePartStackedPortMapper = inject(EstimatePartStackedPortMapper);

  private readonly estimatePartCustomPropertyMapper = inject(EstimatePartCustomPropertyMapper);

  private readonly overridableLaborTaskMinutesMapper = inject(OverridableLaborTaskMinutesMapper);

  private readonly laborTaskMinutesMapper = inject(LaborTaskMinutesMapper);

  private readonly componentAttachmentMapper = inject(ComponentAttachmentMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListPartDataDto): PointsListPartDetails {
    return new PointsListPartDetails({
      quantity: dto.quantity,
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      description: this.confirmedOverridableMapper.fromDto(dto.description),
      unitCost: this.confirmedOverridableMapper.fromDto(dto.unitCost),
      manufacturer: this.confirmedOverridableMapper.fromDto(dto.manufacturer),
      pointDescription: this.confirmedOverridableMapper.fromDto(dto.pointDescription),
      modelNumber: this.confirmedOverridableMapper.fromDto(dto.modelNumber),
      listPrice: this.confirmedOverridableMapper.fromDto(dto.listPrice),
      weight: this.confirmedOverridableMapper.fromDto(dto.weight),
      location: this.confirmedOverridableMapper.fromDto(dto.location),
      supplier: this.confirmedOverridableMapper.fromDto(dto.supplier),
      salesNotes: this.confirmedOverridableMapper.fromDto(dto.salesNotes),
      defaultPartType: this.confirmedOverridableMapper.fromDto(dto.defaultPartType),
      providedBy: this.confirmedOverridableMapper.fromDto(dto.providedBy),
      installedBy: this.confirmedOverridableMapper.fromDto(dto.installedBy),
      wiredBy: this.confirmedOverridableMapper.fromDto(dto.wiredBy),
      applicableDiagrams: this.confirmedOverridableMapper.fromDto(dto.applicableDiagrams),
      tags: this.confirmedOverridableMapper.fromDto(dto.tags),
      ports: dto.ports.map(port => this.estimatePartPortMapper.fromOverridableDto(port)),
      stackedPorts: dto.stackedPorts.map(port => this.estimatePartStackedPortMapper.fromOverridableDto(port)),
      customProperties: this.confirmedOverridableMapper.fromDtoWithMapper(
        dto.customProperties,
        { fromDto: properties => properties.map(property => this.estimatePartCustomPropertyMapper.fromDto(property)) },
      ),
      taskMinutes: dto.taskMinutes.map(taskMinutes => this.overridableLaborTaskMinutesMapper.fromDto(taskMinutes)),
      attachments: dto.attachments.map(attachment => this.componentAttachmentMapper.fromDto(attachment)),
    });
  }

  /** @inheritdoc */
  public toDto(data: EditPointsListPartDetails): PointsListPartDataDto {
    return {
      quantity: data.quantity,
      name: this.confirmedOverridableMapper.toDto(data.name),
      description: this.confirmedOverridableMapper.toDto(data.description),
      unitCost: this.confirmedOverridableMapper.toDto(data.unitCost),
      manufacturer: this.confirmedOverridableMapper.toDto(data.manufacturer),
      pointDescription: this.confirmedOverridableMapper.toDto(data.pointDescription),
      modelNumber: this.confirmedOverridableMapper.toDto(data.modelNumber),
      listPrice: this.confirmedOverridableMapper.toDto(data.listPrice),
      weight: this.confirmedOverridableMapper.toDto(data.weight),
      location: this.confirmedOverridableMapper.toDto(data.location),
      supplier: this.confirmedOverridableMapper.toDto(data.supplier),
      salesNotes: this.confirmedOverridableMapper.toDto(data.salesNotes),
      defaultPartType: this.confirmedOverridableMapper.toDto(data.defaultPartType),
      providedBy: this.confirmedOverridableMapper.toDto(data.providedBy),
      installedBy: this.confirmedOverridableMapper.toDto(data.installedBy),
      wiredBy: this.confirmedOverridableMapper.toDto(data.wiredBy),
      applicableDiagrams: this.confirmedOverridableMapper.toDto(data.applicableDiagrams),
      tags: this.confirmedOverridableMapper.toDto(data.tags),
      ports: data.ports.map(port => this.estimatePartPortMapper.toOverridableDto(port)),
      stackedPorts: data.stackedPorts.map(port => this.estimatePartStackedPortMapper.toOverridableDto(port)),
      customProperties: this.confirmedOverridableMapper.toDtoWithMapper(
        data.customProperties,
        { toDto: properties => properties.map(property => this.estimatePartCustomPropertyMapper.toDto(property)) },
      ),
      taskMinutes: data.taskMinutes.map(taskMinutes => this.overridableLaborTaskMinutesMapper.toDto(taskMinutes)),
      attachments: data.attachments.map(attachment => this.componentAttachmentMapper.toDto(attachment)),
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListPartDetails): CreatePointsListPartDataDto {
    return {
      quantity: data.quantity,
      name: data.name,
      description: data.description,
      unitCost: data.unitCost,
      manufacturer: data.manufacturer,
      pointDescription: data.pointDescription,
      modelNumber: data.modelNumber,
      listPrice: data.listPrice,
      weight: data.weight,
      location: data.location,
      supplier: data.supplier,
      salesNotes: data.salesNotes,
      defaultPartType: data.defaultPartType,
      providedBy: data.providedBy,
      installedBy: data.installedBy,
      wiredBy: data.wiredBy,
      applicableDiagrams: data.applicableDiagrams,
      tags: data.tags,
      customProperties: data.customProperties.map(property => this.estimatePartCustomPropertyMapper.toDto(property)),
      taskMinutes: data.taskMinutes.map(taskMinutes => this.laborTaskMinutesMapper.toDto(taskMinutes)),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListPartDetails): EditPointsListPartDataDto {
    return {
      ...this.toDto(data),
      attachmentIds: data.attachments.map(attachment => attachment.id),
    };
  }
}
