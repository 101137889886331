import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatInput } from '@angular/material/input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorStateMatcher } from '@angular/material/core';

import { MatFormFieldValueAccessor, matFormFieldControlProviderFor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';

/** Input with chip list. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-chips-list',
  templateUrl: './chips-list.component.html',
  styleUrls: ['./chips-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => ChipsListComponent)],
})
export class ChipsListComponent extends MatFormFieldValueAccessor<string[]> implements OnInit {
  /** Error state matcher. */
  @Input()
  public override errorStateMatcher = new ErrorStateMatcher();

  /** MatInput. */
  @ViewChild(MatInput)
  private readonly matInput?: MatInput;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Chip items form control. */
  protected readonly chipItemsControl = this.fb.control<string[]>([]);

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: string[] | null): void {
    this.chipItemsControl.setValue(value ?? [], { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.chipItemsControl.disable();
    } else {
      this.chipItemsControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** @inheritdoc */
  protected focus(): void {
    this.matInput?.focus();
  }

  /**
   * Add chip item.
   * @param event Chip input event.
   */
  protected addChipItem(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const controlValue = this.chipItemsControl.value;
    if (value) {
      this.chipItemsControl.patchValue([...controlValue, value]);
    }

    event.chipInput?.clear();
  }

  /**
   * Remove chip item.
   * @param index Index of chip item to be removed.
   */
  protected removeChipItem(index: number): void {
    const controlValue = [...this.chipItemsControl.value];
    if (index >= 0) {
      controlValue.splice(index, 1);
      this.chipItemsControl.patchValue(controlValue);
    }
  }

  private subscribeToControlChanges(): void {
    this.chipItemsControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
