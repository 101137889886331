import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { CurrentUserService } from '../services/current-user.service';

export namespace UnauthorizedGuard {

  /** Guard prevents user from accessing if a user is not logged in. */
  @Injectable({ providedIn: 'root' })
  class UnauthorizedGuardService {
    private readonly routePaths = injectWebAppRoutes();

    public constructor(
      private readonly userService: CurrentUserService,
      private readonly router: Router,
    ) {}

    /** Determine if route could be matched. */
    public canMatch(): Observable<boolean | UrlTree> {
      return this.canNavigate();
    }

    /**
     * Determine if route could be achieved.
     * @param _route Route.
     * @param state State.
     */
    public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.canNavigate(state);
    }

    private canNavigate(state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.userService.isAuthorized$.pipe(
        map(isAuthorized => this.guard(isAuthorized, state?.url)),
      );
    }

    private guard(isAuthenticated: boolean, url?: string): boolean | UrlTree {
      if (isAuthenticated) {
        return true;
      }

      return this.router.createUrlTree([this.routePaths.auth.children.login.url], {
        queryParams: url ? { next: url } : undefined,
      });
    }
  }

  export const canActivate: CanActivateFn = (route, state) =>
    inject(UnauthorizedGuardService).canActivate(route, state);
  export const canMatch: CanMatchFn = () => inject(UnauthorizedGuardService).canMatch();
}
