import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { BaseCellRendererComponent } from '../../../base-components/base-cell-renderer.component';
import { getValueLabelFromOptions } from '../utils/get-label-from-options';

/** Multi select cell render component. */
@Component({
  selector: 'dartsalesc-multi-select-cell-renderer',
  templateUrl: './multi-select-cell-renderer.component.html',
  styleUrls: ['./multi-select-cell-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectCellRendererComponent<T> extends BaseCellRendererComponent<readonly T[]> {

  /** Options. */
  @Input({ required: true })
  public options: readonly OptionSelect<T>[] = [];

  /** Placeholder. */
  @Input()
  public placeholder = 'Select values';

  /** Value label. */
  protected get valueLabel(): string | null {
    if (this.value == null) {
      return null;
    }

    const labels = this.getValueLabelsFromOptions(this.value, this.options);
    return labels.length > 0 ? labels.join(', ') : null;
  }

  private getValueLabelsFromOptions(
    values: readonly T[],
    options: readonly OptionSelect<T>[],
  ): string[] {
    return values.reduce<string[]>((result, value) => {
      const label = getValueLabelFromOptions(value, options);

      if (label != null) {
        return result.concat(label);
      }

      return result;
    }, []);
  }
}
