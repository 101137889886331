import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Bulk update indicator component. */
@Component({
  selector: 'dartsalesc-bulk-update-indicator',
  templateUrl: './bulk-update-indicator.component.html',
  styleUrls: ['./bulk-update-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateIndicatorComponent {

}
