import { Injectable, Provider, inject } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, concatMap, first, map, merge, shareReplay, skip, switchMap, take, takeUntil, tap } from 'rxjs';

import { CustomPortsApiService } from '@dartsales/common/core/services/api/custom-ports-api.service';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';
import { CustomPort } from '@dartsales/common/core/models/custom-port';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { PointsListTabApiService } from '@dartsales/common/core/services/api/points-list/points-list-tab-api.service';
import { PointsListModuleProperties } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-module-properties';
import { Sort } from '@dartsales/common/core/models/list-utilities/sort';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { ShortPointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/short-points-list-tab';
import { PointsListTab } from '@dartsales/common/core/models/estimate/modules/points-list/tab/points-list-tab';
import { PointsListModuleData } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-module-data';
import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';

import { PointsListUndoRedoService } from '../pages/systems-page/components/points-list-table/services/points-list-undo-redo.service';
import { PointsListIrreversibleActionCommand } from '../pages/systems-page/components/points-list-table/commands/points-list-irreverisble-action-command';
import { AbstractCommand } from '../pages/systems-page/components/points-list-table/commands/abstract-command';
import { POINTS_LIST_MODULE_SYNC, providePointsListModuleSync } from '../pages/systems-page/components/points-list-table/utils/points-list-broadcast.providers';

import { injectEstimateService } from './estimate.service';
import { injectLaborModuleService } from './labor/labor-module.service';
import { AbstractModulePageService } from './abstract-module-page.service';

type GetSaveCommandFn = (estimateId: EstimateId) => AbstractCommand;

/** PointsList module page service. */
@Injectable()
export class PointsListModuleService extends AbstractModulePageService<PointsListModuleData> {

  private readonly projectLayoutService = inject(ProjectLayoutService);

  private readonly estimateService = injectEstimateService();

  private readonly pointsListTabApiService = inject(PointsListTabApiService);

  private readonly laborModuleService = injectLaborModuleService();

  private readonly customPortsService = inject(CustomPortsApiService);

  private readonly undoRedoService = inject(PointsListUndoRedoService, { optional: true });

  private readonly broadcastChannel = inject(POINTS_LIST_MODULE_SYNC);

  /** @inheritdoc */
  public override readonly parentId$ = this.estimateService.estimateId$;

  /**
   * Points list tab sort.
   * We don't override 'sort$' because we don't need sort for the whole module.
   */
  public readonly tabSort$ = new BehaviorSubject(new Sort({ field: '', direction: SortDirection.NONE }));

  /** Points list module properties. */
  public readonly moduleProperties$ = this.createModulePropertiesStream();

  /** Labor tasks. */
  public readonly laborTasks$ = this.laborModuleService.laborTasks$;

  /** Custom ports. */
  public readonly customPorts$ = this.createCustomPortsStream();

  private readonly isTabFetching$ = new BehaviorSubject(false);

  /** @inheritdoc */
  public readonly isLoading$ = this.createModuleLoadingStream([this.estimateService.isLoading$, this.isTabFetching$]);

  private readonly moduleSavingStarted$ = new Subject<void>();

  private readonly pointsListModule$ = this.createPointsListModuleStream();

  /** Points list tabs. */
  public readonly tabs$ = this.createTabsStream();

  private readonly saveHandler = this.createCreateSaveHandler();

  /**
   * Get points list tab by ID.
   * @param tabId Tab ID.
   */
  public getTabById(tabId: PointsListTab['id']): Observable<PointsListTab> {
    return combineLatest([
      this.parentId$.pipe(first()),
      this.tabSort$,
      this.pointsListModule$,
    ]).pipe(
      switchMap(([estimateId, sort]) => this.pointsListTabApiService.getTabById(estimateId, tabId, sort).pipe(
        toggleExecutionState(this.isTabFetching$),

        // We want to cancel request if new module update was started.
        takeUntil(this.moduleSavingStarted$),
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Save module data.
   * @param saveRequest Save request.
   */
  public saveModule<T>(saveRequest: (estimateId: EstimateId) => Observable<T>): Observable<void> {
    return this.saveModuleWithCommand(estimateId => new PointsListIrreversibleActionCommand({
      action: () => saveRequest(estimateId).pipe(map(() => undefined)),
    })).pipe(
      first(),
    );
  }

  /**
   * Save module data using command.
   * @param getSaveCommand Get save command.
   */
  public saveModuleWithCommand(getSaveCommand: GetSaveCommandFn): Observable<void> {
    this.moduleSavingStarted$.next();
    return this.saveHandler(getSaveCommand);
  }

  /** @inheritdoc */
  protected override fetchModuleData(parentId: number): Observable<PointsListModuleData> {
    return this.pointsListTabApiService.getTabs(parentId).pipe(
      map(tabs => new PointsListModuleData({
        tabs,
      })),
    );
  }

  private createCreateSaveHandler(): (getSaveCommand: GetSaveCommandFn) => Observable<void> {
    // We want to execute commands one by one in determined order.
    // That's why saving of points list module is different.
    const nextCommand$ = new ReplaySubject<GetSaveCommandFn>(1);
    const save$ = nextCommand$.pipe(
      filterNull(),
      concatMap(getCommand => this.saveModuleData(parentId => {
        const command = getCommand(parentId);
        return command.execute().pipe(
          tap(() => this.undoRedoService?.saveCommandInHistory(command)),
        );
      }, false)),
      tap(() => this.refreshModule()),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    return (getSaveCommand: GetSaveCommandFn) => {
      nextCommand$.next(getSaveCommand);
      return save$.pipe(

        // We want to save and then wait for module reload.
        switchMap(() => this.module$.pipe(
          skip(1),
          take(1),
          map(() => undefined),
        )),
      );
    };
  }

  private createModulePropertiesStream(): Observable<PointsListModuleProperties> {
    return this.estimateService.estimate$.pipe(
      map(estimate => estimate.modulesProperties.pointsList),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createTabsStream(): Observable<ShortPointsListTab[]> {
    return this.pointsListModule$.pipe(
      map(module => [...module.tabs]),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createCustomPortsStream(): Observable<CustomPort[]> {
    return this.projectLayoutService.organizationId$.pipe(
      switchMap(organizationId => this.customPortsService.getCustomPorts(organizationId)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createPointsListModuleStream(): Observable<PointsListModuleData> {
    const moduleFromServer$ = this.module$.pipe(switchMap(module => this.broadcastChannel.postMessage(module).pipe(
      map(() => module),
    )));
    const moduleFromAnotherTab$ = this.broadcastChannel.get().pipe(filterNull());

    return merge(
      moduleFromServer$,
      moduleFromAnotherTab$,
    ).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}

/** Provide points list module services. */
export function providePointsListModuleServices(): Provider[] {
  return [
    PointsListModuleService,
    providePointsListModuleSync(),
  ];
}
