import { BatchEditEntity } from '@dartsales/common/core/models/batch-edit-entity';

import { EditPointsListSystemDetails } from './edit-points-list-system-details';
import { PointsListSystem } from './points-list-system';
import { EditPointsListSystemProperties } from './edit-points-list-system-properties';

/** Points list system edit model. */
export class EditPointsListSystem {
  /** Order. */
  public readonly order?: number;

  /** System details. */
  public readonly data: EditPointsListSystemDetails;

  /** System properties. */
  public readonly properties: EditPointsListSystemProperties;

  public constructor(data: EditPointsListSystemInitArgs) {
    this.order = data.order;
    this.data = data.data;
    this.properties = data.properties;
  }
}

type EditPointsListSystemInitArgs = EditPointsListSystem;

/** Points list system batch edit model. */
export class BatchEditPointsListSystem extends BatchEditEntity<PointsListSystem['id'], EditPointsListSystem> { }
