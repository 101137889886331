<div
  *ngrxLet="
    units === amountCalcUnits.Amount
      ? (value?.amount | currency)
      : (value?.percent | decimalPercent) as displayedValue
  "
  class="ellipsis"
  [matTooltip]="displayedValue ?? ''"
>
  {{ displayedValue }}
</div>
