import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';

/** Base pricing overrides. */
export class BasePricingOverrides {

  /** Direct cost. */
  public readonly directCost: number | null;

  /** Contingency. */
  public readonly contingency: AmountPercent | null;

  /** Escalation. */
  public readonly escalation: AmountPercent | null;

  /** Gross margin. */
  public readonly grossMargin: AmountPercent | null;

  /** Escalation + WFS parameters from material module. */
  public readonly aggregatedWEFS: AmountPercent | null;

  /** Sell price. */
  public readonly sellPrice: number | null;

  public constructor(initArgs: BasePricingInitArgs) {
    this.directCost = initArgs.directCost;
    this.contingency = initArgs.contingency;
    this.escalation = initArgs.escalation;
    this.grossMargin = initArgs.grossMargin;
    this.sellPrice = initArgs.sellPrice;
    this.aggregatedWEFS = initArgs.aggregatedWEFS;
  }
}

export type BasePricingInitArgs = BasePricingOverrides;
