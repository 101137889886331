import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EditableTableColumnConfig } from '@dartsales/common/core/models/list-utilities/editable-table-column-config';
import { createTrackByFunction } from '@dartsales/common/core/utils/trackby';

import { EditableTableBodyRowComponent, tableBodyRowProviderFor } from '../../directives/editable-table-body-row.directive';

/** Text row placeholder component. */
@Component({
  selector: 'dartsalesc-placeholder-row',
  templateUrl: './placeholder-row.component.html',
  styleUrls: ['./placeholder-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [tableBodyRowProviderFor(() => PlaceholderRowComponent)],
})
export class PlaceholderRowComponent extends EditableTableBodyRowComponent {

  /** Placeholder. */
  @Input()
  public placeholder = '';

  /** Columns configs. */
  @Input()
  public columns: readonly EditableTableColumnConfig[] = [];

  /** Track by column. */
  protected readonly trackByColumn = createTrackByFunction<EditableTableColumnConfig, 'name'>('name');
}
