import { Directive, inject, Output, EventEmitter, OnInit, Input, HostBinding, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { OnTouchedFn } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { TableCellControlErrorStateMatcher } from '../../editable-table-cell/utils/table-cell-control-error-state-matcher';
import { getErrorMessage } from '../../validation-message/get-error-message';
import { CellEditorService } from '../services/cell-editor.service';
import { CellRendererService } from '../services/cell-renderer.service';

/**
 * Base class for cell form control component.
 * This class is responsible for switching between view/edit modes and saving the state.
 *
 * Cells workflow:
 *
 * We have to cell mode:
 * - Renderer - is a lightweight component representing the control value.
 * - Editor - is a component editor for control value.
 *
 * When cell is focused Renderer component sends information about focus
 * then Renderer component is replaced with Editor component (Renderer component is destroyed in this case).
 *
 * When exiting the editing mode, this class destroy Editor component and replace with Renderer component.
 *
 * Template example.
 * @example
 * ```html
 * <dartsalesc-text-cell-renderer
 *   *ngIf="(isEditMode$ | async) === false; else editMode"
 *   [value]="controlValue ?? ''"
 *   [isFocusable]="(isFocusable$ | async) ?? false"
 * />
 * <ng-template #editMode>
 *   <dartsalesc-text-cell-editor
 *     [value]="controlValue ?? ''"
 *     [errorStateMatcher]="errorStateMatcher"
 *     (valueChange)="controlValue = $event"
 *   />
 * </ng-template>
 * ```
 */
@UntilDestroy()
@Directive({
  host: {
    class: 'dartsalesc-cell-wrapper',
  },
})
export abstract class AbstractCellFormControlComponent<T> extends SimpleValueAccessor<T> implements OnInit {

  /** Is cell readonly. */
  @Input()
  public set isReadonly(shouldBeReadonly: boolean) {
    this.isReadonly$.next(shouldBeReadonly);
  }

  /** Control touch event. */
  @Output()
  public readonly touched = new EventEmitter<void>();

  private readonly isDisabled$ = new BehaviorSubject(this.disabled);

  private readonly errorTooltip = inject(MatTooltip);

  /** Is readonly. */
  protected readonly isReadonly$ = new BehaviorSubject(false);

  /** Is cell readonly. */
  @HostBinding('$.class.readonly')
  @HostListener('$.class.readonly')
  protected readonly shouldBeReadonly$ = combineLatest([
      this.isDisabled$,
      this.isReadonly$,
    ]).pipe(
      map(indicators => indicators.some(Boolean)),
    );

  /** Is focusable. */
  protected readonly isFocusable$ = this.shouldBeReadonly$.pipe(map(shouldBeReadonly => !shouldBeReadonly));

  private readonly ngControl = inject(NgControl);

  /** Error state matcher. */
  public readonly errorStateMatcher = new TableCellControlErrorStateMatcher(this.ngControl);

  /** Should display error message. */
  @HostBinding('class.invalid')
  protected get shouldDisplayError(): boolean {
    return this.errorStateMatcher.isErrorState();
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    this.isDisabled$.next(isDisabled);
    this.isEditMode$.next(false);
  }

  /** Handle mouse enter. */
  @HostListener('mouseenter')
  protected handleMouseEnter(): void {
    const errorMessage = this.getErrorMessage();
    if (errorMessage != null) {
      this.errorTooltip.message = errorMessage;
      this.errorTooltip.show();
    }
  }

  /** Handle mouse leave. */
  @HostListener('mouseleave')
  protected handleMouseLeave(): void {
    this.errorTooltip.hide();
  }

  /** Is edit mode. */
  protected readonly isEditMode$ = new BehaviorSubject(false);

  private readonly editableCellService = inject(CellEditorService);

  private readonly viewCellService = inject(CellRendererService);

  /** Error message as a string. */
  protected getErrorMessage(): string | null {
    if (this.errorStateMatcher.isErrorState()) {
      const control = this.errorStateMatcher.ngControl;
      return control.errors ? getErrorMessage(control.errors) : null;
    }
    return null;
  }

  public constructor() {
    super();
    this.ngControl.valueAccessor = this;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToReadonlyToggle();
    this.subscribeToViewCellFocus();
    this.subscribeToEditableCellBlur();
  }

  private subscribeToReadonlyToggle(): void {
    this.isReadonly$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      untilDestroyed(this),
    ).subscribe(() => {
      this.isEditMode$.next(false);
    });
  }

  private subscribeToEditableCellBlur(): void {
    this.editableCellService.blur$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.isEditMode$.next(false);
      this.emitTouched();
      this.changeDetectorRef.markForCheck();
    });
  }

  private subscribeToViewCellFocus(): void {
    this.viewCellService.focus$.pipe(
      untilDestroyed(this),
    ).subscribe(params => {
      this.isEditMode$.next(true);

      // Required to render the component before focus.
      setTimeout(() => {
        this.editableCellService.focusRequest$.next(params);
      }, 0);
    });
  }

  /** @inheritdoc */
  public override registerOnTouched(fn: OnTouchedFn): void {
    const newFn: OnTouchedFn = () => {
      this.touched.emit();
      fn();
    };
    super.registerOnTouched(newFn);
  }
}
