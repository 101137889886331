import { Injectable, inject } from '@angular/core';

import { DefaultLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';
import { EditDefaultLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-task';

import { IMapperFromDto } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { EditLaborRoleDefaultTaskDto, DefaultLaborRoleTaskDto } from '../../../dto/estimate/modules/labor/labor-role-task.dto';

import { LaborRoleHoursMapper } from './labor-role-hours.mapper';
import { CommonLaborRoleTaskPropertiesMapper } from './common-labor-role-task-properties.mapper';

/** Labor role default task entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleDefaultTaskMapper implements IMapperFromDto<DefaultLaborRoleTaskDto, DefaultLaborRoleTask> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly laborRoleHoursMapper = inject(LaborRoleHoursMapper);

  private readonly commonLaborRoleTaskPropertiesMapper = inject(CommonLaborRoleTaskPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: DefaultLaborRoleTaskDto): DefaultLaborRoleTask {
    return new DefaultLaborRoleTask({
      ...this.commonLaborRoleTaskPropertiesMapper.fromDto(dto),
      originalTaskId: dto.originalTaskId,
      hours: this.laborRoleHoursMapper.fromDto(dto),
    });
  }

  /**
   * Map to edit default task DTO.
   * @param data Data.
   */
  public toEditDto(data: EditDefaultLaborRoleTask): EditLaborRoleDefaultTaskDto {
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      code: this.confirmedOverridableMapper.toDto(data.code),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.originalLaborRoleId),
    };
  }
}
