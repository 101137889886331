<div
  *ngIf="columnConfig"
  class="header-cell"
  [ngClass]="{
    'header-cell_align-center': columnConfig.align === 'center',
    'header-cell_align-right': columnConfig.align === 'right'
  }"
>
  <ng-content select="[prefix]"></ng-content>

  <span
    class="header-cell__text ellipsis"
    [class.header-cell__text_active]="isSortActive"
    [title]="columnConfig.headerText"
  >
    {{ columnConfig.headerText }}
  </span>

  <ng-content select="[bulkUpdate]"></ng-content>

  <dartsalesc-table-header-sorting-button
    *ngIf="columnConfig.sortField as sortField"
    class="header-cell__sorting-button"
    [sortFieldName]="sortField"
  />
</div>
