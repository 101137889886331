import { Observable, tap, map, EMPTY } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { PointsListGroupsApiService } from '@dartsales/common/core/services/api/points-list/points-list-groups-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListTransactionRedoCommand } from '../points-list-transaction-redo-command';

/** Duplicate points list entities command. */
export class DuplicateEntitiesCommand extends PointsListTransactionRedoCommand<DuplicateEntitiesCommandInitArgs> {
  private readonly pointsListGroupsApiService = this.injector.get(PointsListGroupsApiService);

  private duplicatedEntityIds: PointsListAllEntitiesIds | null = null;

  /** @inheritdoc */
  public override execute(): Observable<void> {
    return this.pointsListGroupsApiService.duplicate(this.args.estimateId, this.args.payload).pipe(
      tap(ids => {
        this.duplicatedEntityIds = ids;
      }),
      map(() => undefined),
    );
  }

  /** @inheritdoc */
  protected override undoAction(): Observable<ApiTransactionId> {
    if (this.duplicatedEntityIds == null) {
      return EMPTY;
    }
    return this.pointsListGroupsApiService.delete(this.args.estimateId, this.duplicatedEntityIds);
  }
}

type DuplicateEntitiesCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Payload. */
  readonly payload: PointsListAllEntitiesIds;
};
