import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';

/** Text filter input. */
@Component({
  selector: 'dartsalesc-text-filter-input',
  templateUrl: './text-filter-input.component.html',
  styleUrls: ['./text-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextFilterInputComponent implements FilterComponentType<string> {

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<string>();
}
