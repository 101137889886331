import { inject, Injectable } from '@angular/core';

import { PointsListLaborRoleTotals, PointsListLaborTotals } from '@dartsales/common/core/models/estimate/modules/points-list/system-totals/points-list-labor-totals';

import { IMapper } from '../../../mappers';
import { PointsListLaborTotalsDto } from '../../../dto/estimate/modules/points-list/system-totals/points-list-labor-totals.dto';
import { OverridableMapper } from '../../../overridable.mapper';

/** PointsListLaborTotals entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListLaborTotalsMapper implements IMapper<PointsListLaborTotalsDto, PointsListLaborTotals> {

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListLaborTotalsDto): PointsListLaborTotals {
    return new PointsListLaborTotals({
      directCost: this.overridableMapper.fromDto(dto.directCost),
      hours: dto.hours,
      items: dto.laborRolesTotals.map(item => new PointsListLaborRoleTotals({
        id: item.id,
        name: item.name,
        directCost: this.overridableMapper.fromDto(item.directCost),
        hours: item.hours,
      })),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListLaborTotals): PointsListLaborTotalsDto {
    return {
      directCost: this.overridableMapper.toDto(data.directCost),
      hours: data.hours,
      laborRolesTotals: data.items.map(item => ({
        id: item.id,
        name: item.name,
        directCost: this.overridableMapper.toDto(item.directCost),
        hours: item.hours,
      })),
    };
  }
}
