import { NgClass } from '@angular/common';
import { Directive, ContentChildren, QueryList, Output, ViewChildren, inject, forwardRef, Provider, ForwardRefFn, Input, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Observable, distinctUntilChanged, map, merge, startWith } from 'rxjs';

import { TableRowEventsService } from '../services/table-row-events.service';
import { EditableTableComponent } from '../editable-table.component';

import { EditableTableColDirective } from './editable-table-column.directive';
import { StickyRowSide } from './editable-table-sticky-row.directive';
import { EditableTableRowDragPreviewDirective } from './editable-table-row-drag-preview.directive';

/**
 * In this file we have two alternative approaches for declaring body row element of EditableTable component.
 * We can use either use a directive or create a new component.
 * There is no preferable way, each solution is appropriate in different cases.
 * So it's up to you to decide which approach to use.
 */

/**
 * Create provider for a EditableTableBodyRowComponent.
 * @param factory Factory providing a reference to component class.
 */
export function tableBodyRowProviderFor(factory: ForwardRefFn): Provider {
  return [
    {
      provide: EditableTableBodyRowComponent,
      useExisting: forwardRef(factory),
      multi: true,
    },
    TableRowEventsService,
  ];
}

/**
 * Base class for table body row component.
 * You have to extend this class to create your own table row component compatible with EditableTable component.
 * @example
 * ```ts
 * ‌@Component({
 *   selector: 'dartsalesw-subcontractor-table-body-row',
 *   templateUrl: './subcontractor-table-body-row.component.html',
 *   providers: [tableBodyRowProviderFor(() => SubcontractorTableBodyRowComponent)],
 * })
 * export class SubcontractorTableBodyRowComponent extends EditableTableBodyRowComponent {
 *   // Component implementation.
 * }
 * ```
 * You can use this component inside EditableTable like this:
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table body rows. -->
 *   <dartsalesw-subcontractor-table-body-row />
 * </dartsalesc-editable-table>
 * ```
 */
@Directive()
export abstract class EditableTableBodyRowComponent {
  private readonly rowEventService = inject(TableRowEventsService);

  /** Sticky row side. */
  @Input()
  public stickySide?: StickyRowSide;

  /** Whether row is read-only. */
  @Input()
  public isReadonly = false;

  /** Is draggable row. */
  @Input()
  public isDraggable = false;

  /** CSS classes for row. These classes are not encapsulated by Angular. */
  @Input()
  public rowClasses: NgClass['ngClass'];

  /** Is expanded row. */
  @Input()
  public set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.tableComponent.refreshTableView();
  }

  /** Is expanded row. */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  private _isExpanded = true;

  /** Row blur event. */
  @Output()
  // eslint-disable-next-line rxjs/finnish
  public readonly rowBlur = this.rowEventService.rowBlur$;

  /** Row focus event. */
  @Output()
  // eslint-disable-next-line rxjs/finnish
  public readonly rowFocus = this.rowEventService.rowFocus$;

  /** CDK drag dropped event. */
  @Output()
  public readonly rowDragDropped = new EventEmitter<TableBodyRowDragEvent>();

  /** Table columns. */
  @ViewChildren(EditableTableColDirective)
  public readonly tableColumns?: QueryList<EditableTableColDirective>;

  /** Row CDK drag preview. */
  @ViewChild(EditableTableRowDragPreviewDirective)
  public readonly rowDragPreview?: EditableTableRowDragPreviewDirective;

  /** Is row highlighted. */
  public get isHighlighted(): boolean {
    return false;
  }

  /** Is row focused. */
  protected readonly isRowFocused$ = this.createIsRowFocusedStream();

  /** Change detector ref. */
  protected readonly cdr = inject(ChangeDetectorRef);

  private readonly tableComponent = inject(EditableTableComponent);

  private createIsRowFocusedStream(): Observable<boolean> {
    return merge(
      this.rowBlur.pipe(map(() => false)),
      this.rowFocus.pipe(map(() => true)),
    ).pipe(
      startWith(false),
      distinctUntilChanged(),
    );
  }
}

/**
 * Editable table body row directive.
 * You can apply this directive to mark element inside EditableTable component as a table body row.
 * @example
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table body rows. -->
 *   <ng-container dartsalescEditableTableBodyRow>
 *     <!-- Columns definitions. -->
 *   </ng-container>
 * </dartsalesc-editable-table>
 * ```
 */
@Directive({
  selector: '[dartsalescEditableTableBodyRow]',
  providers: [tableBodyRowProviderFor(() => EditableTableBodyRowDirective)],
})
export class EditableTableBodyRowDirective extends EditableTableBodyRowComponent {
  /** Table columns. */
  @ContentChildren(EditableTableColDirective)
  public override readonly tableColumns?: QueryList<EditableTableColDirective>;

  /** Is row highlighted. */
  @Input()
  public isHighlightedRow = false;

  /** @inheritdoc */
  public override get isHighlighted(): boolean {
    return this.isHighlightedRow;
  }
}

/** Editable table row drag data. */
export type EditableTableRowDragData = {

  /** Row. */
  readonly row: EditableTableBodyRowComponent;

  /** Index. */
  readonly index: number;
};

/** Table body row drag event. */
export type TableBodyRowDragEvent = CdkDragDrop<
  readonly EditableTableBodyRowComponent[],
  readonly EditableTableBodyRowComponent[],
  EditableTableRowDragData
>;
