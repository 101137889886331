import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

/** Convert value to value with indented currency sign. */
@Pipe({
  name: 'indentedCurrency',
})
export class FormatCurrencyWithIndentPipe implements PipeTransform {
  private readonly currencyPipe = inject(CurrencyPipe);

  /**
   * Transform value.
   * @param value Value.
   */
  public transform(value: number | string | null): string {
    if (value == null) {
      return '';
    }

    return this.currencyPipe.transform(value, '$ ') ?? '';
  }
}
