import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/errors/app-error';
import { PasswordChangeData } from '../../models/auth/password-change';

import { PasswordChangeDataDto } from './dto/auth/password-change.dto';
import { MappedValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './errors/extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Mapper for change password process. */
@Injectable({ providedIn: 'root' })
export class PasswordChangeDataMapper implements
  IMapperToDto<PasswordChangeDataDto, PasswordChangeData>,
  IValidationErrorMapper<PasswordChangeDataDto, PasswordChangeData> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<PasswordChangeDataDto>,
  ): EntityValidationErrors<PasswordChangeData> {
    return {
      currentPassword: extractErrorMessage(errorDto.currentPassword) ?? extractErrorMessage(errorDto.non_field_errors),
      newPassword: extractErrorMessage(errorDto.newPassword),
    };
  }

  /** @inheritdoc */
  public toDto(data: PasswordChangeData): PasswordChangeDataDto {
    return {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };
  }
}
