import { AnyObject } from './types/any-object';

type NonNull<T> = T extends null ? never : T;

type RemoveNull<T> = {
  [K in keyof T]: NonNull<T[K]>
};

/**
 * Remove undefined and empty string values from object.
 * @param object Object.
 */
export function removeEmptyValues<T extends AnyObject, R = Partial<RemoveNull<T>>>(object: T): R {
  return Object.entries(object)
    .reduce((acc, [key, value]) => {
      if (value === undefined || value === '' || value === null) {
        return acc;
      }
      return {
        ...acc,
        [key]: value,
      };
    }, {} as R);
}
