import { Injectable, inject } from '@angular/core';

import { StandardMaterialItem } from '@dartsales/common/core/models/estimate/modules/material/data/standard-material-item';
import { MaterialPricingWithOverride } from '@dartsales/common/core/models/estimate/modules/material/material-pricing-with-override';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../../../mappers';
import { OverridableMapper } from '../../../overridable.mapper';
import { BatchEditLockablePercentsStatusDto, EditStandardMaterialPartsGroupDto, StandardMaterialItemsGroupDto } from '../../../dto/estimate/modules/material/data/standard-material-items-group.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';

import { PartsGroupCommonPropertiesMapper } from './parts-group-common-properties.mapper';
import { MaterialLockablePercentsStatusMapper } from './material-lockable-percents-status.mapper';
import { AbstractMaterialPricingMapper } from './abstract-material-pricing.mapper';

/** Standard material items group entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class StandardMaterialItemsGroupMapper implements IMapper<StandardMaterialItemsGroupDto, StandardMaterialItem | null> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly overridableMapper = inject(OverridableMapper);

  private readonly partsGroupCommonPropertiesMapper = inject(PartsGroupCommonPropertiesMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(MaterialLockablePercentsStatusMapper);

  private readonly abstractMaterialPricingMapper = inject(AbstractMaterialPricingMapper);

  /** @inheritdoc */
  public fromDto(dto: StandardMaterialItemsGroupDto): StandardMaterialItem {
    const quantity = this.overridableMapper.fromDto(dto.quantity);
    return new StandardMaterialItem({
      partsGroupId: dto.partGroupId,
      partIds: dto.partIds,
      pricing: new MaterialPricingWithOverride({
        ...this.abstractMaterialPricingMapper.fromDto({
          ...dto,
          unitCost: dto.partGroupCommonProperties.unitCost,
        }),
        quantity,
      }),
      ...this.partsGroupCommonPropertiesMapper.fromDto(dto.partGroupCommonProperties),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
    });
  }

  /** @inheritdoc */
  public toDto(data: StandardMaterialItem): StandardMaterialItemsGroupDto {
    const pricingDto = this.basePricingMapper.toDto(data.pricing);

    return {
      ...pricingDto,
      totalDirectCost: data.pricing.totalDirectCost,
      unitCost: data.pricing.unitCost,
      directCost: pricingDto.directCost,
      warranty: data.pricing.warranty,
      escalation: data.pricing.escalation,
      freight: data.pricing.freight,
      salesTax: data.pricing.salesTax,
      partGroupId: data.partsGroupId,
      partGroupCommonProperties: this.partsGroupCommonPropertiesMapper.toDto({ ...data, ...data.pricing }),
      partIds: data.partIds,
      quantity: this.overridableMapper.toDto(data.pricing.quantity),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
    };
  }

  /**
   * Map to edit standard parts group dto.
   * @param data Standard part pricing.
   */
  public toEditStandardPartsGroupDto(data: MaterialPricingWithOverride): EditStandardMaterialPartsGroupDto {
    return {
      quantityOverride: data.quantity.override ?? undefined,
      unitCost: data.unitCost ?? undefined,
      warranty: data.warranty.percent,
      escalation: data.escalation.percent,
      freight: data.freight.percent,
      salesTax: data.salesTax.percent,
      contingency: data.contingency.percent,
      grossMargin: data.margin.grossMargin.percent,
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Group ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: StandardMaterialItem['partsGroupId'],
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      partGroups: [
        {
          partGroupId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
