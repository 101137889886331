import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';

/** Export format select dialog data. */
export type ExportDialogData = {

  /**
   * Export function.
   * @param exportFormat Export format.
   */
  exportFn(exportFormat: ExportFormat): Observable<void>;

  /** Supported export formats. */
  readonly supportedExportFormats?: readonly ExportFormat[];
};

/** Export format select dialog component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportDialogComponent {

  private readonly formBuilder = inject(NonNullableFormBuilder);

  /** Export format. */
  protected readonly exportFormat = ExportFormat;

  private readonly data = inject<ExportDialogData>(MAT_DIALOG_DATA);

  /** Supported export formats. */
  protected readonly supportedExportFormats = this.data.supportedExportFormats ?? ExportFormat.toArray();

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

  /** Dialog ref. */
  protected readonly dialogRef = inject<MatDialogRef<ExportDialogComponent, void>>(MatDialogRef);

  /** Format control. */
  protected readonly formatControl = this.formBuilder.control(ExportFormat.Csv);

  /** Handle Submit. */
  protected onSubmit(): void {
    this.data.exportFn(this.formatControl.value).pipe(
      toggleExecutionState(this.isLoading$),
      untilDestroyed(this),
    )
      .subscribe(() => this.dialogRef.close());
  }
}
