import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PaletteComponent } from '@dartsales/common/core/models/components/palette/palette-component';

type AllowedComponentType = PaletteComponent['type'];

const PREFIX_ICONS_MAP: Record<AllowedComponentType, string> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  Part: 'part',
  Symbol: 'symbol',
};

/** Component type icon. */
@Component({
  selector: 'dartsalesw-component-type-icon',
  templateUrl: './component-type-icon.component.html',
  styleUrls: ['./component-type-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentTypeIconComponent {

  /** Component type. */
  @Input()
  public componentType: AllowedComponentType | null = null;

  /** Name of prefix icon. */
  protected get prefixIcon(): string | null {
    if (this.componentType == null) {
      return null;
    }
    return PREFIX_ICONS_MAP[this.componentType] ?? null;
  }
}
