import { Injectable } from '@angular/core';

import { TagFilters } from '@dartsales/common/core/models/filters/tag-filters';

import { TagFiltersDto } from '../dto/filters/tag-filters.dto';
import { IMapperToDto } from '../mappers';

/** Mapper for Tag filters. */
@Injectable({ providedIn: 'root' })
export class TagFiltersMapper implements IMapperToDto<TagFiltersDto, TagFilters> {
  /** @inheritdoc */
  public toDto(data?: TagFilters): TagFiltersDto {
    return {
      OrganizationId: data?.organizationId,
      NameContains: data?.nameContains,
    };
  }
}
