import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { CustomPortCategoryDto } from '../mappers/dto/custom-port.dto';
import { CustomPortMapper } from '../mappers/custom-port.mapper';
import { CustomPort } from '../../models/custom-port';

import { AppUrlsConfig } from './app-urls.config';

/** Custom ports API service. */
@Injectable({
  providedIn: 'root',
})
export class CustomPortsApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly customPortMapper = inject(CustomPortMapper);

  /**
   * Get custom ports for organization.
   * @param organizationId Organization ID.
   */
  public getCustomPorts(organizationId: number): Observable<CustomPort[]> {
    return this.http.get<PagedListDto<CustomPortCategoryDto>>(this.apiUrls.customPortsApi.getList, {
      params: {
        organizationId,
      },
    }).pipe(
      map(dto => dto.items?.flatMap(item => item.customPorts?.map(
        port => this.customPortMapper.fromDto(port),
      ) ?? []) ?? []),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
