import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { LaborTask } from '@dartsales/common/core/models/resources/labor-task';

/** Points list tab labor task. */
export class PointsListTabLaborTask {
  /** Labor task ID. */
  public readonly id: LaborTask['id'];

  /** Is default. */
  public readonly isDefault: LaborTask['isDefault'];

  /** Task name. */
  public readonly name: ConfirmedOverridable<LaborTask['name']>;

  public constructor(data: PointsListTabLaborTaskInitArgs) {
    this.id = data.id;
    this.isDefault = data.isDefault;
    this.name = data.name;
  }
}

type PointsListTabLaborTaskInitArgs = PointsListTabLaborTask;
