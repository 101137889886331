import { StrictOmit } from '../../utils/types/strict-omit';

import { TermProperties } from './term-properties';

/** Summary information of term. */
export class TermSummary {

  /** ID. */
  public readonly id: number;

  /** Term number. */
  public readonly number: number;

  /** Term order. */
  public readonly order: number;

  /** Term properties. */
  public readonly properties: TermProperties;

  /** Term name. */
  public readonly name: string;

  public constructor(data: TermSummaryInitArgs) {
    this.id = data.id;
    this.number = data.number;
    this.order = data.order;
    this.properties = data.properties;
    this.name = `Term ${this.number}`;
  }
}

type TermSummaryInitArgs = StrictOmit<TermSummary, 'name'>;
