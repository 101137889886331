import { Injectable, inject } from '@angular/core';

import { TermSubcategory } from '@dartsales/common/core/models/estimate-services/term-subcategory';

import { IMapperFromDto } from '../mappers';
import { TermSubcategoryDto } from '../dto/estimate-services/term-subcategory.dto';
import { ModulePropertiesMapper } from '../estimate/modules/module-properties.mapper';

import { EstimateServiceMapper } from './estimate-service.mapper';

/** Estimate service subcategory mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermSubcategoryMapper
implements IMapperFromDto<TermSubcategoryDto, TermSubcategory> {

  private readonly estimateServiceMapper = inject(EstimateServiceMapper);

  private readonly modulePropertiesMapper = inject(ModulePropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: TermSubcategoryDto): TermSubcategory {
    return new TermSubcategory({
      ...this.modulePropertiesMapper.fromDto(dto),
      id: dto.id,
      categoryId: dto.category?.id ?? null,
      name: dto.name ?? '',
      services: dto.services?.map(service => this.estimateServiceMapper.fromDto(service)) ?? [],
    });
  }
}
