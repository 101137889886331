import { first, map, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { UserBase } from '@dartsales/common/core/models/user/user-base';
import { UserProfile } from '@dartsales/common/core/models/user/user-profile';
import { CurrentUserService } from '@dartsales/common/core/services/current-user.service';
import { UserAuthService } from '@dartsales/common/core/services/user-auth.service';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';
import { createTrackByFunction } from '@dartsales/common/core/utils/trackby';
import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { ChangePasswordDialogComponent } from '../../change-password-dialog/change-password-dialog.component';
import {
  EditAccountSettingsDialogComponent,
  EditAccountSettingsDialogData,
} from '../../edit-account-settings-dialog/edit-account-settings-dialog.component';

/** Profile button component. */
@Component({
  selector: 'dartsalesw-profile-button',
  templateUrl: './profile-button.component.html',
  styleUrls: ['./profile-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileButtonComponent {
  private readonly dialogService = inject(MatDialog);

  private readonly router = inject(Router);

  private readonly userService = inject(UserAuthService);

  private readonly currentUserService = inject(CurrentUserService);

  /** Route paths. */
  protected readonly routePaths = injectWebAppRoutes();

  /** Current user. */
  protected readonly user$ = this.currentUserService.currentUser$;

  /** User info list. */
  protected readonly userInfoList$ = this.createUserInfoStream();

  /** Track by option. */
  protected readonly trackByOptions = createTrackByFunction<OptionSelect<string>>('label');

  /** Handle click on logout button. */
  protected onSignOutButtonClick(): void {
    this.userService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigateByUrl(this.routePaths.auth.children.login.url);
      });
  }

  /** Handle update password button click. */
  protected onUpdatePasswordButtonClick(): void {
    this.dialogService.open(ChangePasswordDialogComponent);
  }

  /**
   * Handle edit account setting button click.
   * @param userData User data.
   */
  protected onEditAccountSettingsButtonClick(userData: UserBase): void {
    this.dialogService.open<
      EditAccountSettingsDialogComponent,
      EditAccountSettingsDialogData
    >(EditAccountSettingsDialogComponent, {
      data: {
        userData,
      },
    });
  }

  /**
   * Handle menu info item click.
   * @param event Click event.
   */
  protected onMenuInfoItemClick(event: MouseEvent): void {
    this.preventMatMenuClose(event);
  }

  /**
   * Get user initials. (e.g. John Doe -> JD).
   * @param user User.
   */
  protected getUserInitials(user: UserProfile): string {
    return `${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`;
  }

  private createUserInfoStream(): Observable<readonly OptionSelect<string>[]> {
    return this.user$.pipe(
      filterNull(),
      map(user => [
        {
          label: 'Email',
          value: user?.email,
        },
        {
          label: 'Username',
          value: `${user.firstName} ${user.lastName}`,
        },
      ]),
    );
  }

  private preventMatMenuClose(event: MouseEvent): void {
    event.stopPropagation();
  }
}
