import { LaborTask } from '@dartsales/common/core/models/resources/labor-task';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { LaborRole } from '@dartsales/common/core/models/resources/labor-role';

import { PointsListLaborRoleRate } from './points-list-labor-role-rate';

/** Points list system labor task rate. */
export class PointsListSystemTaskRate {

  /** Task ID. */
  public readonly taskId: LaborTask['id'];

  /** Task name. */
  public readonly taskName: LaborTask['name'];

  /** Task labor role rate. */
  public readonly roleRate: PointsListLaborRoleRate;

  /** Labor role ID. */
  public readonly laborRoleId: ConfirmedOverridable<LaborRole['id']>;

  public constructor(data: PointsListSystemTaskRateInitArgs) {
    this.taskId = data.taskId;
    this.taskName = data.taskName;
    this.roleRate = data.roleRate;
    this.laborRoleId = data.laborRoleId;
  }
}

type PointsListSystemTaskRateInitArgs = PointsListSystemTaskRate;
