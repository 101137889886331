import { PointsListSystemIoPortsTotal } from './points-list-system-io-ports-total';
import { PointsListSystemTaskRate } from './points-list-system-task-rate';
import { PointsListSystemTotal } from './points-list-system-total';

/** Points list system properties. */
export class PointsListSystemProperties {
  /** Task rates. */
  public readonly taskRates: readonly PointsListSystemTaskRate[];

  /** Total calculated values of a single system. */
  public readonly systemTotal: PointsListSystemTotal;

  /** Total calculated values of system with 'typical of' multiplier. */
  public readonly allSystemsTotal: PointsListSystemTotal;

  /** IO ports total. */
  public readonly ioPortsTotal: readonly PointsListSystemIoPortsTotal[];

  public constructor(data: PointsListSystemPropertiesInitArgs) {
    this.taskRates = data.taskRates;
    this.systemTotal = data.systemTotal;
    this.allSystemsTotal = data.allSystemsTotal;
    this.ioPortsTotal = data.ioPortsTotal;
  }
}

type PointsListSystemPropertiesInitArgs = PointsListSystemProperties;
