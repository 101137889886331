import { ChangeDetectionStrategy, Component } from '@angular/core';
import { createMask } from '@ngneat/input-mask';

import { MAX_GROSS_MARGIN_PERCENT_NUMBER, NUMBER_MASK } from '@dartsales/common/core/utils/constants';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { OverridableAmountToggledInputComponent } from './amount-overridable-toggled-input.component';

/** Gross margin amount toggle input component. */
@Component({
  selector: 'dartsalesw-gross-margin-amount-toggled-input',
  templateUrl: 'amount-overridable-toggled-input.component.html',
  styleUrls: ['./amount-overridable-toggled-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => GrossMarginAmountToggledInputComponent)],
})
export class GrossMarginAmountToggledInputComponent extends OverridableAmountToggledInputComponent {

  /** @inheritdoc */
  protected override readonly percentInputMask = createMask({
    ...NUMBER_MASK,
    max: MAX_GROSS_MARGIN_PERCENT_NUMBER,
  });
}
