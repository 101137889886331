import { EMPTY, map, Observable, tap } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { CreatePointsListSystemFromPalette } from '@dartsales/common/core/models/estimate/modules/points-list/system/create-points-list-system';
import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListTransactionRedoCommand } from '../points-list-transaction-redo-command';

/** Create system from palette. */
export class CreateSystemFromPaletteCommand extends PointsListTransactionRedoCommand<CreateSystemFromPaletteCommandInitArgs> {
  private readonly pointsListSystemApiService = this.injector.get(PointsListSystemApiService);

  private createdSystemIds: readonly string[] = [];

  /** @inheritdoc */
  public override execute(): Observable<void> {
    return this.pointsListSystemApiService.createSystemFromPalette(this.args.estimateId, this.args.payload).pipe(
      tap(systems => {
        this.createdSystemIds = systems.map(system => system.id);
      }),
      map(() => undefined),
    );
  }

  /** @inheritdoc */
  protected override undoAction(): Observable<ApiTransactionId> {
    if (this.createdSystemIds.length === 0) {
      return EMPTY;
    }
    return this.pointsListSystemApiService.deleteSystems(this.args.estimateId, this.createdSystemIds);
  }
}

type CreateSystemFromPaletteCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Payload. */
  readonly payload: CreatePointsListSystemFromPalette;
};
