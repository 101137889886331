import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { getErrorMessage } from './get-error-message';

/**
 * Validation error renderer component.
 * Renders first error from control errors.
 */
@Component({
  selector: 'dartsalesc-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationMessageComponent {
  /**
   * Validation errors.
   */
  @Input()
  public errors: ValidationErrors | null = null;

  /**
   * Error message as a string.
   */
  protected get errorMessage(): string | null {
    if (this.errors == null) {
      return null;
    }
    return getErrorMessage(this.errors);
  }
}
