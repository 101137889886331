import { ObservableInput, MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError } from '../../models/errors/app-error';

/**
 * Catch any application error (instance of AppError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
export function catchAppError<T, V>(
  selector: (error: AppError) => ObservableInput<V>,
): MonoTypeOperatorFunction<T | V> {
  return catchError((error: unknown) => {
    if (error instanceof AppError) {
      return selector(error);
    }
    return throwError(() => error);
  });
}
