import { Injectable, inject } from '@angular/core';

import { SubcontractorInfo } from '../../models/estimate/modules/subcontractor/subcontractor-info';
import { SubcontractorCategory } from '../../enums/subcontractor-category';
import { CreateSubcontractorInfo } from '../../models/estimate/modules/subcontractor/create-subcontractor-info';

import { IMapper } from './mappers';
import { CreateSubcontractorInfoDto, SubcontractorInfoDto } from './dto/estimate/modules/subcontractor/subcontractor-info.dto';
import { ConfirmedOverridableMapper } from './confirmed-overridable.mapper';

/** Subcontractor info mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubcontractorInfoMapper implements IMapper<SubcontractorInfoDto, SubcontractorInfo> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: SubcontractorInfoDto): SubcontractorInfo {
    return new SubcontractorInfo({
      id: dto.contractorId,
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      contact: this.confirmedOverridableMapper.fromDto(dto.contact),
      description: this.confirmedOverridableMapper.fromDto(dto.description),
      category: this.confirmedOverridableMapper.fromDto(dto.category ?? SubcontractorCategory.Other),
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
    });
  }

  /**
   * Map to DTO for create.
   * @param data Data.
   */
  public toCreateDto(data: CreateSubcontractorInfo): CreateSubcontractorInfoDto {
    return {
      category: data.category,
      contact: data.contact,
      contractorId: data.contractorId,
      description: data.description,
      name: data.name,
    };
  }

  /** @inheritdoc */
  public toDto(data: SubcontractorInfo): SubcontractorInfoDto {
    return {
      contractorId: data.id,
      name: this.confirmedOverridableMapper.toDto(data.name),
      contact: this.confirmedOverridableMapper.toDto(data.contact),
      description: this.confirmedOverridableMapper.toDto(data.description),
      category: this.confirmedOverridableMapper.toDto(data.category),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
    };
  }
}
