<mat-select
  class="estimate-select select caption-1"
  placeholder="Select"
  [(ngModel)]="controlValue"
>
  <mat-option
    *ngFor="let option of options; trackBy: trackByIndex"
    [value]="option.value"
  >
    {{ option.label }}
  </mat-option>
</mat-select>
