import { ChangeDetectionStrategy, Component } from '@angular/core';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

/** App permission error page. */
@Component({
  selector: 'dartsalesw-app-permission-error-page',
  templateUrl: './app-permission-error-page.component.html',
  styleUrls: ['./app-permission-error-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPermissionErrorPageComponent {
  /** Route paths. */
  protected readonly routePaths = injectWebAppRoutes();
}
