/** Custom port. */
export class CustomPort {
  /** Port ID (UUID). */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Category name. */
  public readonly categoryName: string;

  public constructor(data: CustomPortInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.categoryName = data.categoryName;
  }
}

type CustomPortInitArgs = CustomPort;
