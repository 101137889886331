import { BasePricingOverrides } from '../pricing/base-pricing-overrides';

/** Modules overrides. */
export class ModulesOverrides {

  /** Subcontractor module overrides. */
  public readonly subcontractor: BasePricingOverrides | null;

  /** Expenses module overrides. */
  public readonly expenses: BasePricingOverrides | null;

  /** Custom module overrides. */
  public readonly custom: BasePricingOverrides | null;

  /** Labor module overrides. */
  public readonly labor: BasePricingOverrides | null;

  /** Material module overrides. */
  public readonly material: BasePricingOverrides | null;

  /** Totals module overrides. */
  public readonly totals: BasePricingOverrides | null;

  public constructor(data: EstimateInitArgs) {
    this.subcontractor = data.subcontractor;
    this.expenses = data.expenses;
    this.custom = data.custom;
    this.labor = data.labor;
    this.material = data.material;
    this.totals = data.totals;
  }
}

type EstimateInitArgs = ModulesOverrides;
