import { Directive, Input, ContentChild } from '@angular/core';

import { TableCellDirective } from './table-cell.directive';
import { TableHeaderDirective } from './table-header.directive';

/** Horizontal alignment. */
export enum HorizontalAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

/**
 * Directive to specify how to render column in the table.
 * @example
 * ```html
 * <ng-container dartsalescTableColumn="name">
 *   <ng-container *dartsalescTableHeader>
 *     Name
 *   </ng-container>
 *   <ng-container [dartsalescTableCell]="nameCell">
 *     <ng-template #nameCell let-row>
 *       {{ row.name }}
 *     </ng-template>
 *   </ng-container>
 * </ng-container>
 *
 * <ng-container
 *   dartsalescTableColumn="name"
 *   headerText="Name">
 *   <ng-container *dartsalescTableCell="let row">
 *     {{row.name}}
 *   </ng-container>
 * </ng-container>
 * ```
 */
@Directive({
  selector: '[dartsalescTableColumn]',
})
export class TableColumnDirective {
  /** Name of the column. */
  @Input('dartsalescTableColumn')
  public name?: string;

  /** Text for the header if TableHeaderDirective not provided. */
  @Input()
  public headerText?: string;

  /** Max column width in pixels. */
  @Input()
  public maxWidth?: number;

  /**
   * Is fixed horizontal column.
   * This property isn't supported in BaseTable.
   */
  @Input()
  public stickyHorizontal?: 'left' | 'right';

  /**
   * Is expanded column.
   * This property isn't supported in BaseTable.
   */
  @Input()
  public isExpanded?: boolean;

  /**
   * Is custom background-color column.
   * This property isn't supported in BaseTable.
   */
  @Input()
  public isCustomBackgroundColor?: boolean;

  /**
   * Column align.
   * This property isn't supported in BaseTable.
   */
  @Input()
  public horizontalAlignment = HorizontalAlignment.Left;

  /**
   * Whether cells of column use for handle cdkDrag or not.
   * This property isn't supported in BaseTable.
   */
  @Input()
  public isCdkDragHandle = false;

  /** Get cell template. */
  @ContentChild(TableCellDirective)
  public readonly cell?: TableCellDirective;

  /** Get header template. */
  @ContentChild(TableHeaderDirective)
  public readonly header?: TableHeaderDirective;
}
