import { Injectable, inject } from '@angular/core';

import { LaborRoleRate } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-hours-pricing';

import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { ConfirmedOverridableLaborRoleRateDto } from '../../../dto/resources/labor-role/labor-role-rate.dto';

/** Labor role rate entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleRateMapper implements IMapper<ConfirmedOverridableLaborRoleRateDto, LaborRoleRate> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: ConfirmedOverridableLaborRoleRateDto): LaborRoleRate {
    return {
      customer: this.confirmedOverridableMapper.fromDto(dto.customerRate),
      internal: this.confirmedOverridableMapper.fromDto(dto.internalRate),
    };
  }

  /** @inheritdoc */
  public toDto(data: LaborRoleRate): ConfirmedOverridableLaborRoleRateDto {
    return {
      customerRate: this.confirmedOverridableMapper.toDto(data.customer),
      internalRate: this.confirmedOverridableMapper.toDto(data.internal),
    };
  }
}
