import { Injectable } from '@angular/core';

import { CustomField } from '@dartsales/common/core/models/project/custom-field';

import { CustomFieldDto } from '../dto/project/custom-field.dto';
import { IMapperFromDto, IMapperToDto } from '../mappers';

/** Custom field entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomFieldMapper implements
  IMapperFromDto<CustomFieldDto, CustomField>,
  IMapperToDto<CustomFieldDto, CustomField> {
  /** @inheritdoc */
  public fromDto(dto: CustomFieldDto): CustomField {
    return new CustomField({
      fieldName: dto.key,
      fieldValue: dto.value,
    });
  }

  /** @inheritdoc */
  public toDto(data: CustomField): CustomFieldDto {
    return {
      key: data.fieldName,
      value: data.fieldValue,
    };
  }
}
