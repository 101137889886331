// TODO (Pavel Z.) This function has typing problems because the second parameter is not controlled by TypeScript;
// after reviewing the approach to creating objects, it is recommended to rework or abandon this function.
/**
 * Clone an object, saving the prototype of its class and setting new values for the specified properties.
 * @param src Source object to clone.
 * @param newProps New values for object properties.
 * @returns Cloned object with updated properties.
 */
export function cloneWithAssign<T extends object>(src: T, newProps: unknown): T {
  return Object.assign<T, T, unknown>(Object.create(src), src, newProps);
}
