import { Injectable, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';

import { TermMultipleExportParamsFormControls, TermMultipleExportParamsFormValues } from '@dartsales/common/core/models/forms/export/term-multiple-export-params-form';
import { TermMultipleExportParams } from '@dartsales/common/core/models/exports/term-multiple-export-params';
import { ExportFormat } from '@dartsales/common/core/enums/export-format';

import { IFormToModel, IModelToForm } from '../form-mappers';

/** Export term config form mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermMultipleExportConfigFormMapper implements
  IModelToForm<void, TermMultipleExportParamsFormControls>,
  IFormToModel<TermMultipleExportParamsFormControls, TermMultipleExportParams> {

  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public formToModel(formValues: TermMultipleExportParamsFormValues): TermMultipleExportParams {
    return formValues;
  }

  /** @inheritdoc */
  public modelToForm(): FormGroup<TermMultipleExportParamsFormControls> {
    return this.fb.group<TermMultipleExportParamsFormControls>({
      exportFormat: this.fb.control(ExportFormat.Csv, Validators.required),
      exportedCategories: this.fb.control([]),
    });
  }
}
