import { Injectable, inject } from '@angular/core';

import {
  FinalEstimateServiceByModules,
  FinalEstimateServiceModuleItem,
  FinalEstimateServiceModuleSummary,
} from '@dartsales/common/core/models/estimate/modules/service-totals/final-estimate-service-by-modules';

import {
  FinalEstimateServiceByModuleSummariesDto,
  FinalEstimateServiceTotalsModuleSummaryDto,
  FinalEstimateServiceTotalsModuleItemDto,
} from '../../../../dto/estimate/final-estimate/modules/service-totals/final-estimate-service-by-module-summaries.dto';
import { IMapperFromDto } from '../../../../mappers';

import { FinalEstimateServiceTotalsTermMapper } from './service-totals-term.mapper';

/** Final estimate service totals module data mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateServiceByModulesMapper
implements IMapperFromDto<FinalEstimateServiceByModuleSummariesDto, FinalEstimateServiceByModules> {

  private readonly termMapper = inject(FinalEstimateServiceTotalsTermMapper);

  /** @inheritdoc */
  public fromDto(data: FinalEstimateServiceByModuleSummariesDto): FinalEstimateServiceByModules {
    return new FinalEstimateServiceByModules({
      custom: this.moduleSummaryFromDto(data.custom),
      expenses: this.moduleSummaryFromDto(data.expenses),
      labor: this.moduleSummaryFromDto(data.labor),
      material: this.moduleSummaryFromDto(data.material),
      subcontractor: this.moduleSummaryFromDto(data.subcontractor),
      grandTotals: this.moduleSummaryFromDto(data.grandTotals),
    });
  }

  /**
   * Maps module summary DTO to Domain model.
   * @param moduleDto Module summary DTO.
   **/
  public moduleSummaryFromDto(
    moduleDto: FinalEstimateServiceTotalsModuleSummaryDto,
  ): FinalEstimateServiceModuleSummary {
    return new FinalEstimateServiceModuleSummary({
      ...this.termMapper.termContainerFromDto(moduleDto),
      items: moduleDto.items?.map(item => this.mapItemFromDto(item)) ?? [],
    });
  }

  private mapItemFromDto(
    item: FinalEstimateServiceTotalsModuleItemDto,
  ): FinalEstimateServiceModuleItem {
    return new FinalEstimateServiceModuleItem({
      ...this.termMapper.termContainerFromDto(item),
      name: item.name,
      childItems: item.childItems.map(child => this.mapItemFromDto(child)),
    });
  }
}
