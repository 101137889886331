import { Injectable, inject } from '@angular/core';

import { EditMaterialModule, MaterialModule } from '@dartsales/common/core/models/estimate/modules/material/material-module';
import { MaterialModuleData } from '@dartsales/common/core/models/estimate/modules/material/data/material-module-data';

import { IMapperFromDto, IMapperToDto } from '../../../mappers';
import { EditMaterialModuleDto, MaterialModuleDto } from '../../../dto/estimate/modules/material/material-module.dto';

import { StandardMaterialGroupMapper } from './standard-material-group.mapper';
import { NonStandardMaterialItemMapper } from './non-standard-material-item.mapper';
import { MaterialModulePropertiesMapper } from './material-module-properties.mappert';

/** Material module entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class MaterialModuleMapper implements
  IMapperFromDto<MaterialModuleDto, MaterialModule>,
  IMapperToDto<EditMaterialModuleDto, EditMaterialModule> {

  private readonly propertiesMapper = inject(MaterialModulePropertiesMapper);

  private readonly standardMaterialGroupMapper = inject(StandardMaterialGroupMapper);

  private readonly nonStandardMaterialItemMapper = inject(NonStandardMaterialItemMapper);

  /** @inheritdoc */
  public fromDto(dto: MaterialModuleDto): MaterialModule {
    return new MaterialModule({
      properties: this.propertiesMapper.fromDto(dto.properties),
      data: new MaterialModuleData({
        defaultStandardMaterialsGroup: this.standardMaterialGroupMapper.fromDto(dto.data.defaultStandardMaterials),
        standardMaterialsGroups: dto.data.standardMaterials.map(group => this.standardMaterialGroupMapper.fromDto(group)),
        nonStandardMaterials: dto.data.nonStandardMaterials.map(value => this.nonStandardMaterialItemMapper.fromDto(value)),
      }),
    });
  }

  /** @inheritdoc */
  public toDto(data: EditMaterialModule): EditMaterialModuleDto {
    return {
      properties: this.propertiesMapper.toDto(data.properties),
      data: {
        defaultStandardMaterials: this.standardMaterialGroupMapper.toDto(data.data.defaultStandardMaterialsGroup),
        standardMaterials: data.data.standardMaterialsGroups.map(group => this.standardMaterialGroupMapper.toDto(group)),
        nonStandardMaterials: data.data.nonStandardMaterials.map(
          material => this.nonStandardMaterialItemMapper.toDto(material),
        ),
      },
    };
  }
}
