import { Injectable } from '@angular/core';

import { ContractingTeam } from '@dartsales/common/core/models/project/contracting-team';
import { ProjectFloor } from '@dartsales/common/core/models/project/project-floor';
import { CustomField } from '@dartsales/common/core/models/project/custom-field';

import { EntityValidationErrors } from '../../../models/errors/app-error';
import { ProjectEditFormValues } from '../../../models/forms/project-edit/project-edit-form';
import { Project, ProjectEdit } from '../../../models/project/project';
import { ProjectBid } from '../../../models/project/project-bid';
import { ProjectCompany } from '../../../models/project/project-company';
import { ProjectDetails } from '../../../models/project/project-details';
import { ProjectInfo } from '../../../models/project/project-info';
import { ProjectTeam } from '../../../models/project/project-team';
import { AddressMapper } from '../address.mapper';
import { ContractorMapper } from '../contractor.mapper';
import { IMapperToDto, IValidationErrorMapper } from '../mappers';
import { ProjectCreateDto, ProjectDto, ProjectEditDto } from '../dto/project/project.dto';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { DateMapper } from '../date-mapper';
import { OtherTeamMemberMapper } from '../other-team-member.mapper';

import { CustomFieldMapper } from './custom-field.mapper';
import { ProjectFloorMapper } from './project-floor.mapper';

type ShortValidationError = {

  /** Estimate name. */
  readonly name: string;
};

/** Project mapper. */
@Injectable({
  providedIn: 'root',
})
export class ProjectMapper implements
  IMapperToDto<ProjectEditDto, ProjectEdit>,
  IValidationErrorMapper<ProjectEditDto, ProjectEditFormValues> {

  public constructor(
    private readonly addressMapper: AddressMapper,
    private readonly projectFloorMapper: ProjectFloorMapper,
    private readonly contractorMapper: ContractorMapper,
    private readonly customFieldMapper: CustomFieldMapper,
    private readonly dateMapper: DateMapper,
    private readonly otherTeamMemberMapper: OtherTeamMemberMapper,
  ) { }

  /**
   * Mapper of DTO to domain model.
   * @param dto Project DTO.
   * @param id Project id.
   */
  public fromDto(dto: ProjectDto, id: number): Project {
    return new Project({
      id,
      status: dto.status,
      projectInfo: new ProjectInfo({
        details: new ProjectDetails({
          name: dto.name,
          code: dto.number,
          projectNumber: dto.organizationProjectNumber,
          organizationId: dto.organizationId,
          description: dto.data.projectInfo.description,
          startDate: this.dateMapper.fromDto(dto.data.projectInfo.startDate),
          endDate: this.dateMapper.fromDto(dto.data.projectInfo.endDate),
          totalBuildingSquare: dto.data.projectInfo.totalBuildingSqFt,
          bidDueDateTime: this.dateMapper.fromDto(dto.data.projectInfo.bidDue),
          productTypes: dto.data.projectInfo.productTypes,
          tags: dto.data.projectInfo.tags,
          address: this.addressMapper.fromDto(dto.data.projectInfo.address),
          organizationLogoUrl: dto.organizationLogoDownloadUrl?.url ?? null,
        }),
        bidInfo: new ProjectBid({
          markets: dto.data.bidInfo.markets,
          categoryIds: dto.data.bidInfo.categoryIds,
          lead: dto.data.bidInfo.lead,
          competingBidderNames: dto.data.bidInfo.competingBidders,
          bidRecipientBidderNames: dto.data.bidInfo.bidRecipients,
        }),
        company: new ProjectCompany({
          phoneNumber: dto.data.companyInfo.phoneNumber,
          faxNumber: dto.data.companyInfo.faxNumber,
          address: this.addressMapper.fromDto(dto.data.companyInfo.address),
        }),
        team: new ProjectTeam({
          projectManager: dto.data.teamInfo.projectManager,
          projectEngineer: dto.data.teamInfo.projectEngineer,
          projectSalesPerson: dto.data.teamInfo.projectSalesperson,
          projectTechnician: dto.data.teamInfo.projectTechnician,
          approvedBy: dto.data.teamInfo.approvedBy,
          drawnBy: dto.data.teamInfo.drawnBy,
        }),
        otherTeamMembers: dto.estimateUsers.map(userDto => this.otherTeamMemberMapper.fromDto(userDto)),
      }),
      floorInfo: dto.data.floorsInfo.floors.map(floor => this.projectFloorMapper.fromDto(floor)),
      contractingTeam: new ContractingTeam({
        architect: this.contractorMapper.fromDto(dto.data.contractingTeam.architect),
        consultingEngineer: this.contractorMapper.fromDto(dto.data.contractingTeam.consultingEngineer),
        primeContractor: this.contractorMapper.fromDto(dto.data.contractingTeam.primeContractor),
        contractWith: this.contractorMapper.fromDto(dto.data.contractingTeam.contractWith),
      }),
      customInfo: dto.data.customFields.map(field => this.customFieldMapper.fromDto(field)),
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      createdById: dto.createdById,
    });
  }

  /** @inheritdoc */
  public toDto(data: ProjectEdit): ProjectEditDto {
    return {
      name: data.projectInfo.details.name,
      number: data.projectInfo.details.code,
      data: {
        projectInfo: {
          description: data.projectInfo.details.description,
          startDate: this.dateMapper.toDateTimeDto(data.projectInfo.details.startDate),
          endDate: this.dateMapper.toDateTimeDto(data.projectInfo.details.endDate),
          totalBuildingSqFt: data.projectInfo.details.totalBuildingSquare,
          address: this.addressMapper.toDto(data.projectInfo.details.address),
          bidDue: this.dateMapper.toDateTimeDto(data.projectInfo.details.bidDueDateTime),
          productTypes: data.projectInfo.details.productTypes,
          tags: data.projectInfo.details.tags,
        },
        bidInfo: {
          markets: data.projectInfo.bidInfo.markets,
          categoryIds: data.projectInfo.bidInfo.categoryIds,
          lead: data.projectInfo.bidInfo.lead,
          competingBidders: data.projectInfo.bidInfo.competingBidderNames,
          bidRecipients: data.projectInfo.bidInfo.bidRecipientBidderNames,
        },
        companyInfo: {
          phoneNumber: data.projectInfo.company.phoneNumber,
          faxNumber: data.projectInfo.company.faxNumber,
          address: this.addressMapper.toDto(data.projectInfo.company.address),
        },
        teamInfo: {
          projectManager: data.projectInfo.team.projectManager,
          projectEngineer: data.projectInfo.team.projectEngineer,
          projectSalesperson: data.projectInfo.team.projectSalesPerson,
          projectTechnician: data.projectInfo.team.projectTechnician,
          approvedBy: data.projectInfo.team.approvedBy,
          drawnBy: data.projectInfo.team.drawnBy,
        },
        floorsInfo: {
          floors: data.floorInfo.map(floor => this.projectFloorMapper.toDto(floor)),
        },
        contractingTeam: {
          architect: this.contractorMapper.toDto(data.contractingTeam.architect),
          consultingEngineer: this.contractorMapper.toDto(data.contractingTeam.consultingEngineer),
          primeContractor: this.contractorMapper.toDto(data.contractingTeam.primeContractor),
          contractWith: this.contractorMapper.toDto(data.contractingTeam.contractWith),
        },
        customFields: data.customInfo.map(field => this.customFieldMapper.toDto(field)),
      },
      estimateUserIds: data.projectInfo.otherTeamMembers.map(member => member.memberId),
    };
  }

  /**
   * Mapper of domain model to DTO.
   * @param data Project edit model.
   */
  public toCreateDto(data: ProjectEdit): ProjectCreateDto {
    return {
      ...this.toDto(data),
      estimateUserIds: data.projectInfo.otherTeamMembers.map(member => member.memberId),
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<ProjectEditDto>,
  ): EntityValidationErrors<ProjectEditFormValues> {
    return {
      projectInfo: {
        detailsInfo: {
          name: extractErrorMessage(errorDto.name) ??
            extractErrorMessage(errorDto?.non_field_errors),
          code: extractErrorMessage(errorDto.number),
          description: extractErrorMessage(errorDto.data?.projectInfo?.description),
          startDate: extractErrorMessage(errorDto.data?.projectInfo?.startDate),
          endDate: extractErrorMessage(errorDto.data?.projectInfo?.endDate),
          totalBuildingSquare: extractErrorMessage(errorDto.data?.projectInfo?.totalBuildingSqFt),
          bidDueDate: extractErrorMessage(errorDto.data?.projectInfo?.bidDue),

          // TODO (DART-104): add handle errors for 'tags' after change EntityValidationErrors.
          // productTypes: ...,
          // tags: ...,
          address: {
            address: extractErrorMessage(errorDto.data?.projectInfo?.address?.line),
            country: extractErrorMessage(errorDto.data?.projectInfo?.address?.country),
            state: extractErrorMessage(errorDto.data?.projectInfo?.address?.state),
            city: extractErrorMessage(errorDto.data?.projectInfo?.address?.city),
            zip: extractErrorMessage(errorDto.data?.projectInfo?.address?.zip),
          },
        },
        bidInfo: {
          markets: extractErrorMessage(errorDto.data?.bidInfo?.markets?.flatMap(arr => arr ?? [])),
          categoryIds: extractErrorMessage(errorDto.data?.bidInfo?.categoryIds?.flatMap(arr => arr ?? [])),
          lead: extractErrorMessage(errorDto.data?.bidInfo?.lead),
        },
        companyInfo: {
          phoneNumber: extractErrorMessage(errorDto.data?.companyInfo?.phoneNumber),
          faxNumber: extractErrorMessage(errorDto.data?.companyInfo?.faxNumber),
          address: {
            address: extractErrorMessage(errorDto.data?.companyInfo?.address?.line),
            country: extractErrorMessage(errorDto.data?.companyInfo?.address?.country),
            state: extractErrorMessage(errorDto.data?.companyInfo?.address?.state),
            city: extractErrorMessage(errorDto.data?.companyInfo?.address?.city),
            zip: extractErrorMessage(errorDto.data?.companyInfo?.address?.zip),
          },
        },
        teamInfo: {
          projectManager: extractErrorMessage(errorDto.data?.teamInfo?.projectManager),
          projectEngineer: extractErrorMessage(errorDto.data?.teamInfo?.projectEngineer),
          projectSalesPerson: extractErrorMessage(errorDto.data?.teamInfo?.projectSalesperson),
          projectTechnician: extractErrorMessage(errorDto.data?.teamInfo?.projectTechnician),
          approvedBy: extractErrorMessage(errorDto.data?.teamInfo?.approvedBy),
          drawnBy: extractErrorMessage(errorDto.data?.teamInfo?.drawnBy),
        },

        // TODO (DART-104): add handle errors for 'otherTeamInfo' after change EntityValidationErrors.
        // otherTeamInfo: ...,
      },
      floorInfo: errorDto.data?.floorsInfo?.floors?.map<EntityValidationErrors<ProjectFloor>>(floor => ({
        floorName: extractErrorMessage(floor.number),
        nickName: extractErrorMessage(floor.alias),
        approximateSquare: extractErrorMessage(floor.approximateSqFt),
      })),
      contractingTeam: {
        architect: {
          companyName: extractErrorMessage(errorDto.data?.contractingTeam?.architect?.companyName),
          contact: extractErrorMessage(errorDto.data?.contractingTeam?.architect?.contact),
          phoneNumber: extractErrorMessage(errorDto.data?.contractingTeam?.architect?.phoneNumber),
        },
        consultingEngineer: {
          companyName: extractErrorMessage(errorDto.data?.contractingTeam?.consultingEngineer?.companyName),
          contact: extractErrorMessage(errorDto.data?.contractingTeam?.consultingEngineer?.contact),
          phoneNumber: extractErrorMessage(errorDto.data?.contractingTeam?.consultingEngineer?.phoneNumber),
        },
        primeContractor: {
          companyName: extractErrorMessage(errorDto.data?.contractingTeam?.primeContractor?.companyName),
          contact: extractErrorMessage(errorDto.data?.contractingTeam?.primeContractor?.contact),
          phoneNumber: extractErrorMessage(errorDto.data?.contractingTeam?.primeContractor?.phoneNumber),
        },
        contractWith: {
          companyName: extractErrorMessage(errorDto.data?.contractingTeam?.contractWith?.companyName),
          contact: extractErrorMessage(errorDto.data?.contractingTeam?.contractWith?.contact),
          phoneNumber: extractErrorMessage(errorDto.data?.contractingTeam?.contractWith?.phoneNumber),
        },
      },
      customInfo: errorDto.data?.customFields?.map<EntityValidationErrors<CustomField>>(field => ({
        fieldName: extractErrorMessage(field.key),
        fieldValue: extractErrorMessage(field.value),
      })),
    };
  }

  /**
   * Map a short version of validation error to DTO.
   * @param errorDto DTO.
   */
  public mapShortValidationErrorFromDto(
    errorDto: MappedValidationErrorDto<ShortValidationError>,
  ): EntityValidationErrors<ShortValidationError> {
    return ({
      name: extractErrorMessage(errorDto?.name),
    });
  }
}
