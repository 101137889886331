<div class="dialog-actions">
  <button
    mat-flat-button
    type="button"
    color="accent"
    class="dialog-actions__button caption-button"
    (click)="cancelButtonClick.emit()"
  >
    {{ cancelButtonText }}
  </button>

  <button
    mat-flat-button
    type="button"
    class="dialog-actions__button caption-button"
    [color]="actionButtonColor"
    [dartsalescLoading]="isLoading"
    (click)="actionButtonClick.emit()"
  >
    {{ actionButtonText }}
  </button>
</div>
