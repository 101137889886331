import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';

/** Filters with sort. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-sort-filters',
  templateUrl: './sort-filters.component.html',
  styleUrls: ['./sort-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortFiltersComponent {
  /** Whether current filter is active. */
  @Input()
  public isActive = false;

  /** Current sort direction. */
  @Input()
  public sortDirection: SortDirection | null = SortDirection.ASC;

  /** Search query. */
  @Input()
  public search = '';

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  /** Search query change. */
  @Output()
  public readonly searchChange = new EventEmitter<string>();

  /** Sort directions. */
  protected readonly sortDirections = SortDirection;

  /**
   * Sort direction change handler.
   * @param direction Sort direction.
   */
  public onSortDirectionChange(direction: SortDirection): void {
    this.sortDirection = direction;
    this.sortDirectionChange.emit(direction);
  }
}
