import { Directive, HostBinding, Input, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { MatFormFieldValueAccessor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';

/** Abstract overridable mat-form-field. */
@Directive()
export abstract class AbstractOverridableMatFormField<T> extends MatFormFieldValueAccessor<Overridable<T>> {
  /**
   * Disable override display.
   * This property is a workaround for complex cases where we have single form component where inputs are
   * overridable in one case and non-overridable in another (for instance points list part edit form).
   */
  @Input()
  public disableOverrideDisplay = false;

  /** MatInput. */
  @ViewChild(MatInput)
  private readonly matInput?: MatInput;

  /** Whether input has reset button or not. */
  @HostBinding('class.input_reset-available')
  protected get isResetAvailable(): boolean {
    return (
      !this.disableOverrideDisplay &&
      !this.disabled &&
      this.controlValue?.override != null
    );
  }

  /** @inheritdoc */
  protected focus(): void {
    this.matInput?.focus();
  }

  /**
   * Update control.
   * @param newValue New value.
   */
  protected updateControl(newValue: T | null): void {
    if (this.controlValue != null) {
      this.controlValue = new Overridable({
        ...this.controlValue,
        override: newValue,
      });
    }
  }

  /**
   * Handle reset button click.
   * @param event Click event.
   */
  protected onResetButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    if (this.controlValue !== null) {
      const clearedOverride = new Overridable({ initial: this.controlValue.initial, override: null });
      this.writeValue(clearedOverride);
    }
  }
}
