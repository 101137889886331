import { AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationErrorCode } from '@dartsales/common/core/models/errors/validation-error-code';
import { ModuleExportParamsFormValues } from '@dartsales/common/core/models/forms/export/module-export-params-form';

export namespace ModuleExportParamsFormValidators {
  const EXPORTED_VIEW_NOT_SELECTED_MESSAGE = 'Please select at least one View Type';

  /**
   * Exported data selected validator.
   * @param control Control.
   */
  export function exportedDataSelected(control: AbstractControl<ModuleExportParamsFormValues>): ValidationErrors | null {
    if (!control.value.isExportCustomerView && !control.value.isExportInternalView) {
      return {
        [ValidationErrorCode.AppError]: EXPORTED_VIEW_NOT_SELECTED_MESSAGE,
      };
    }
    return null;
  }
}
