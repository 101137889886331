<dartsalesc-table-cell-bulk-update
  [tooltipText]="tooltipText"
  [isUpdateConfirmed]="areAllRowsConfirmed(rows)"
  [maxWidth]="maxWidth"
  (applyUpdateClick)="bulkUpdate.emit(true)"
  (discardUpdateClick)="bulkUpdate.emit(false)"
>
  <p *ngIf="hasRemoveMessage(rows)">
    {{ getRemoveMessage(rows) }}
  </p>
  <p *ngIf="hasUpdateMessage(rows)">
    {{ getUpdateMessage(rows) }}
  </p>
  <p>Do you want to apply update for all changes in this table?</p>
</dartsalesc-table-cell-bulk-update>
