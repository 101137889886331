import { DestroyRef, Directive, inject, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

/** Abstract class for permission directive. */
@Directive()
export abstract class AbstractPermissionDirective implements OnInit {

  /** Stream of user permissions changes. */
  protected abstract readonly hasPermissions$: Observable<boolean>;

  private readonly destroyRef = inject(DestroyRef);

  private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);

  private readonly viewContainer = inject(ViewContainerRef);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToPermissionsChangesStream();
  }

  private subscribeToPermissionsChangesStream(): void {
    this.hasPermissions$
      .subscribe(hasPermission => {
        if (hasPermission) {
          this.showTemplate();
          return;
        }

        this.hideTemplate();
      });
  }

  private showTemplate(): void {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hideTemplate(): void {
    this.viewContainer.clear();
  }
}
