import { AbstractControl, FormControl } from '@angular/forms';
import { defer, Observable } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

/** Options for listenControlChanges() operator. */
type ListenControlChangesOptions<T> = {

  /** Compare function. */
  readonly compare?: (x: T, y: T) => boolean;

  /** Debounce time in ms. */
  readonly debounceTime?: number;

  /** Skip initial value (`false` by default). */
  readonly skipInitial?: boolean;
};

export const DEFAULT_DEBOUNCE_TIME = 300;

const DEFAULT_OPTIONS: ListenControlChangesOptions<unknown> = {
  debounceTime: DEFAULT_DEBOUNCE_TIME,
  skipInitial: false,
};

/**
 * Listens control's `valueChanges` field.
 * Immediately starts with default value of the control.
 * Adds delay and emits value only if it was changed.
 * @param control Control.
 * @param options Options.
 */
export function listenControlChanges<T>(
  control: FormControl<T> | AbstractControl<T, T>,
  options: ListenControlChangesOptions<T> = DEFAULT_OPTIONS,
): Observable<T> {
  return defer(() => control.valueChanges.pipe(
    options.skipInitial ? tap() : startWith(control.value),
    debounceTime(options.debounceTime ?? DEFAULT_DEBOUNCE_TIME),
    distinctUntilChanged(options.compare),
  ));
}

/**
 * Listen form raw values changes.
 * Immediately starts with default value of the form.
 * Adds delay and emits value only if it was changed.
 * @param form Form.
 * @param options Options.
 */
export function listenFormRawValueChanges<T, R extends T>(
  form: AbstractControl<T, R>,
  options: ListenControlChangesOptions<R> = DEFAULT_OPTIONS,
): Observable<R> {
  return defer(() => form.valueChanges.pipe(
    options.skipInitial ? tap() : startWith(null),
    map(() => form.getRawValue()),
    debounceTime(options.debounceTime ?? DEFAULT_DEBOUNCE_TIME),
    distinctUntilChanged(options.compare),
  ));
}
