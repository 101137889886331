<dartsalesc-base-dialog
  class="dialog"
  dialogTitle="Export as:"
>
  <mat-radio-group
    class="format-select"
    [formControl]="formatControl"
  >
    <mat-radio-button
      *ngFor="
        let option of supportedExportFormats;
        trackBy: exportFormat.trackBy
      "
      [value]="option"
    >
      {{ exportFormat.toReadable(option) }}
    </mat-radio-button>
  </mat-radio-group>

  <dartsalesc-base-dialog-footer
    dialogFooter
    actionButtonText="Export"
    [isLoading]="this.isLoading$ | async"
    (actionButtonClick)="onSubmit()"
    (cancelButtonClick)="dialogRef.close()"
  />
</dartsalesc-base-dialog>
