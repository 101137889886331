import { ModuleSettingsLocalStorage } from './module-settings';

/** Expenses module to save in local storage. */
export class ExpensesModuleLocalStorage {

  /** Calculation data section. */
  public readonly calculationDataSection: {

    /** Is calculation section expanded. */
    readonly isExpanded: boolean;

    /** Is resource section expanded. */
    readonly isExpandResourceSection: boolean;

    /** Is labor roles section expanded. */
    readonly isExpandLaborRolesSection: boolean;
  };

  /**  Summary section. */
  public readonly summarySection: {

    /** Is summary section expanded. */
    readonly isExpanded: boolean;

    /** Expenses summary table settings. */
    readonly table: ModuleSettingsLocalStorage;
  };

  public constructor(data: ExpensesModuleLocalStorageInitArgs) {
    this.calculationDataSection = data.calculationDataSection;
    this.summarySection = data.summarySection;
  }
}

type ExpensesModuleLocalStorageInitArgs = ExpensesModuleLocalStorage;
