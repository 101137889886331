/** Labor task minutes. */
export class LaborTaskMinutes {

  /** Task ID. */
  public readonly taskId: number;

  /** Minutes. */
  public readonly minutes: number;

  public constructor(data: LaborTaskMinutesInitArgs) {
    this.taskId = data.taskId;
    this.minutes = data.minutes;
  }
}

type LaborTaskMinutesInitArgs = LaborTaskMinutes;
