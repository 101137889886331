import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseCellRendererComponent } from '../../../base-components/base-cell-renderer.component';

/** Text cell renderer component. */
@Component({
  selector: 'dartsalesc-text-cell-renderer',
  templateUrl: './text-cell-renderer.component.html',
  styleUrls: ['./text-cell-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextCellRendererComponent extends BaseCellRendererComponent<string> {

  /** Placeholder. */
  @Input()
  public placeholder = '';
}
