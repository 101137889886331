import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

import { PaletteSystem } from '../../models/components/palette/palette-system';
import { PalettePartFilters } from '../../models/filters/palette-part-filters';
import { PaletteSystemFilters } from '../../models/filters/palette-system-filters';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PaletteSystemDto } from '../mappers/dto/components/palette/palette-system.dto';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { PaletteSystemMapper } from '../mappers/components/palette/palette-system.mapper';
import { FetchListOptionsParamsMapper } from '../mappers/http-params.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { PaletteTagFilters } from '../../models/filters/palette-tag-filters';
import { PaletteTag } from '../../models/components/palette/palette-tag';
import { PaletteTagDto } from '../mappers/dto/components/palette/palette-tag.dto';
import { PaletteTagMapper } from '../mappers/components/palette/palette-tag.mapper';
import { PaletteOrganizationFilters } from '../../models/filters/palette-organization-filters';
import { PaletteOrganization } from '../../models/components/palette/palette-organization';
import { PaletteOrganizationDto } from '../mappers/dto/components/palette/palette-organization.dto';
import { PaletteOrganizationMapper } from '../mappers/components/palette/palette-organization.mapper';
import { PaletteComponentDto } from '../mappers/dto/components/palette/palette-component.dto';
import { PaletteComponentMapper } from '../mappers/components/palette/palette-component.mapper';
import { PaletteComponent } from '../../models/components/palette/palette-component';
import { PaletteManufacturerFilters } from '../../models/filters/palette-manufacturer-filters';
import { PaletteManufacturerMapper } from '../mappers/components/palette/palette-manufacturer.mapper';
import { PaletteManufacturer } from '../../models/components/palette/palette-manufacturer';
import { PaletteManufacturerDto } from '../mappers/dto/components/palette/palette-manufacturer.dto';
import { BaseOrganization } from '../../models/organization';
import { buildHttpParams } from '../../utils/build-http-params';
import { PaletteSupplierFilters } from '../../models/filters/palette-supplier-filters';
import { PaletteSupplierDto } from '../mappers/dto/components/palette/palette-supplier.dto';
import { PaletteSupplierMapper } from '../mappers/components/palette/palette-supplier.mapper';
import { PaletteSupplier } from '../../models/components/palette/palette-supplier';
import { PalettePartFiltersMapper } from '../mappers/filters/palette-part-filters.mapper';
import { PaletteSharedFiltersMapper } from '../mappers/filters/palette-shared-filters.mapper';
import { PaginationData } from '../../models/list-utilities/pagination-data';
import { Sort } from '../../models/list-utilities/sort';
import { SortDirection } from '../../enums/sort-direction';

import { AppUrlsConfig } from './app-urls.config';

/** Palette components API service. */
@Injectable({
  providedIn: 'root',
})
export class PaletteComponentsApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly pagedListMapper = inject(PagedListMapper);

  private readonly palettePartParamsMapper = inject<FetchListOptionsParamsMapper<PalettePartFilters>>(FetchListOptionsParamsMapper);

  private readonly paletteSystemParamsMapper = inject<FetchListOptionsParamsMapper<PaletteSystemFilters>>(FetchListOptionsParamsMapper);

  private readonly paletteSystemMapper = inject(PaletteSystemMapper);

  private readonly paletteComponentMapper = inject(PaletteComponentMapper);

  private readonly paletteTagMapper = inject(PaletteTagMapper);

  private readonly paletteManufacturerMapper = inject(PaletteManufacturerMapper);

  private readonly paletteOrganizationMapper = inject(PaletteOrganizationMapper);

  private readonly paletteSupplierMapper = inject(PaletteSupplierMapper);

  private readonly palettePartFiltersMapper = inject(PalettePartFiltersMapper);

  private readonly paletteSharedFiltersMapper = inject(PaletteSharedFiltersMapper);

  /**
   * Get list of systems from palette.
   * @param options Search options.
   */
  public getSystemsPalette(
    options: FetchListOptions<PaletteSystemFilters>,
  ): Observable<PagedList<PaletteSystem>> {
    const params = this.paletteSystemParamsMapper.toDto(
      options,
      this.paletteSharedFiltersMapper,
    );

    return this.http.get<PagedListDto<PaletteSystemDto>>(
      this.apiUrls.paletteComponentsApi.getSystemsFromPalette,
      { params },
    ).pipe(
      map(page => this.pagedListMapper.fromDto(page, this.paletteSystemMapper, options.pagination)),
    );
  }

  /**
   * Get list of parts and symbols from palette.
   * @param options Search options.
   */
  public getPartsSymbolsPalette(
    options: FetchListOptions<PalettePartFilters>,
  ): Observable<PagedList<PaletteComponent>> {
    const params = this.palettePartParamsMapper.toDto(
      options,
      this.palettePartFiltersMapper,
    );

    return this.http.get<PagedListDto<PaletteComponentDto>>(
      this.apiUrls.paletteComponentsApi.getPartsSymbolsFromPalette,
      { params },
    ).pipe(
      map(page => this.pagedListMapper.fromDto(page, this.paletteComponentMapper, options.pagination)),
    );
  }

  /**
   * Get most expansive palette part/symbol.
   * @param organizationId Organization ID.
   */
  public getMostExpensivePalettePartSymbol(
    organizationId: BaseOrganization['id'],
  ): Observable<PaletteComponent | null> {
    const options: FetchListOptions<PalettePartFilters> = {
      pagination: new PaginationData(),
      filter: { organizationId },
      sort: [new Sort({ field: 'cost', direction: SortDirection.DESC })],
    };

    const params = this.palettePartParamsMapper.toDto(
      options,
      this.palettePartFiltersMapper,
    );

    return this.http.get<PagedListDto<PaletteComponentDto>>(
      this.apiUrls.paletteComponentsApi.getPartsSymbolsFromPalette,
      { params },
    ).pipe(
      map(page => page.items?.map(item => this.paletteComponentMapper.fromDto(item)) ?? []),
      map(data => data.sort((part1, part2) => part2.unitCost - part1.unitCost).at(0) ?? null),
    );
  }

  /**
   * Get list of tags from palette.
   * @param organizationId Organization ID.
   * @param filters Tag filters.
   */
  public getTagsPalette(
    organizationId: BaseOrganization['id'],
    filters: PaletteTagFilters,
  ): Observable<PaletteTag[]> {
    const params = buildHttpParams({
      OrganizationId: organizationId,
      NameContains: filters.name,
      ExcludeOwnerOrganizationIds: filters.excludeOwnerOrganizationIds,
      ComponentType: filters.componentType,
    });

    return this.http.get<PaletteTagDto[]>(
      this.apiUrls.paletteComponentsApi.getTagsFromPalette,
      { params },
    ).pipe(
      map(tagDtos => tagDtos.map(tagDto => this.paletteTagMapper.fromDto(tagDto))),
    );
  }

  /**
   * Get list of manufacturers from palette.
   * @param organizationId Organization ID.
   * @param filters Manufacturers filters.
   */
  public getManufacturersPalette(
    organizationId: BaseOrganization['id'],
    filters: PaletteManufacturerFilters,
  ): Observable<PaletteManufacturer[]> {
    const params = buildHttpParams({
      OrganizationId: organizationId,
      NameContains: filters.name,
      ExcludeOwnerOrganizationIds: filters.excludeOwnerOrganizationIds,
    });

    return this.http.get<PaletteManufacturerDto[]>(
      this.apiUrls.paletteComponentsApi.getManufacturersFromPalette,
      { params },
    ).pipe(
      map(dtos => dtos.map(dto => this.paletteManufacturerMapper.fromDto(dto))),
    );
  }

  /**
   * Get list of organizations from palette.
   * @param organizationId Organization ID.
   * @param filters Search options.
   */
  public getOrganizationsPalette(
    organizationId: BaseOrganization['id'],
    filters: PaletteOrganizationFilters,
  ): Observable<PaletteOrganization[]> {
    const params = buildHttpParams({
      OrganizationId: organizationId,
      ExcludeOwnerOrganizationIds: filters.excludeOwnerOrganizationIds,
      ComponentType: filters.componentType,
      NameContains: filters.name,
    });

    return this.http.get<PaletteOrganizationDto[]>(
      this.apiUrls.paletteComponentsApi.getOrganizationsFromPalette,
      { params },
    ).pipe(
      map(dtos => dtos.map(dto => this.paletteOrganizationMapper.fromDto(dto))),
    );
  }

  /**
   * Get list of suppliers from palette.
   * @param organizationId Organization ID.
   * @param filters Suppliers filters.
   */
  public getSuppliersPalette(
    organizationId: BaseOrganization['id'],
    filters: PaletteSupplierFilters,
  ): Observable<PaletteSupplier[]> {
    const params = buildHttpParams({
      OrganizationId: organizationId,
      NameContains: filters.name,
      HasSystemsRequested: filters.isSystemComponent,
    });

    return this.http.get<PaletteSupplierDto[]>(
      this.apiUrls.paletteComponentsApi.getSuppliersFromPalette,
      { params },
    ).pipe(
      map(dtos => dtos.map(dto => this.paletteSupplierMapper.fromDto(dto))),
    );
  }
}
