import { AmountCalcUnits } from '../enums/amount-calc-units';
import { MarginType } from '../enums/margin-type';

/**
 * Margin parameters.
 * When we work with margin in calculations we usually have to select both units and type for it.
 */
export type MarginParams = {

  /** Margin type. */
  readonly marginType: MarginType;

  /** Calculation units. */
  readonly units: AmountCalcUnits;
};

export const DEFAULT_MARGIN_PARAMS: MarginParams = {
  marginType: MarginType.DEFAULT,
  units: AmountCalcUnits.DEFAULT,
};
