import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, first, map, shareReplay, switchMap, tap } from 'rxjs';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { ComponentType } from '@dartsales/common/core/models/filters/component-type';
import { PaletteOrganizationFilters } from '@dartsales/common/core/models/filters/palette-organization-filters';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { BaseOrganization } from '@dartsales/common/core/models/organization';
import { PaletteComponentsApiService } from '@dartsales/common/core/services/api/palette-components-api.service';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { PaletteSharedFilters } from '@dartsales/common/core/models/filters/palette-shared-filters';

/** Organization filters. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-organization-filters',
  templateUrl: './organization-filters.component.html',
  styleUrls: ['./organization-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationFiltersComponent {

  /** Organization ID. */
  @Input()
  public set organizationId(organizationId: BaseOrganization['id'] | null) {
    if (organizationId != null) {
      this.organizationId$.next(organizationId);
    }
  }

  /** Current sort direction. */
  @Input()
  public sortDirection: SortDirection | null = SortDirection.ASC;

  /** Organization ID. */
  private readonly organizationId$ = new ReplaySubject<BaseOrganization['id']>(1);

  /** Component type. */
  @Input()
  public set componentType(type: ComponentType | null) {
    if (type != null) {
      this.componentType$.next(type);
    }
  }

  /** Active organizations IDs. */
  @Input()
  public activeOrganizationsIds: readonly BaseOrganization['id'][] = [];

  /** Component type. */
  private readonly componentType$ = new ReplaySubject<ComponentType>(1);

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  /** Organization IDs change. */
  @Output()
  public readonly organizationIdsChange = new EventEmitter<
    Pick<PaletteSharedFilters, 'excludeOrganizationIds' | 'includeOrganizationIds'>>();

  /** Sort directions. */
  protected readonly sortDirections = SortDirection;

  private readonly paletteComponentsApiService = inject(PaletteComponentsApiService);

  private readonly search$ = new BehaviorSubject('');

  private readonly allOptions$ = this.createAllOptionsStream();

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  /** Filtered options. */
  protected readonly filteredOptions$ = this.createFilteredOptionsStream();

  /** Whether sort field is active. */
  protected get isActive(): boolean {
    return this.sortDirection != null;
  }

  /**
   * Sort direction change handler.
   * @param direction Sort direction.
   */
  public onSortDirectionChange(direction: SortDirection): void {
    this.sortDirection = direction;
    this.sortDirectionChange.emit(direction);
  }

  /**
   * Options change handler.
   * @param selectedValues Selected values.
   */
  protected onSelectedOptionsChange(selectedValues: readonly BaseOrganization['id'][]): void {
    this.allOptions$.pipe(
      first(),
      tap(allOptions => {
        if (selectedValues.length === 0) {
          this.organizationIdsChange.emit({
            includeOrganizationIds: [],
            excludeOrganizationIds: [],
          });
          return;
        }

        const deselectedValues = allOptions
          .map(option => option.value)
          .filter(value => !selectedValues.includes(value));

        this.organizationIdsChange.emit({
          includeOrganizationIds: [...selectedValues],
          excludeOrganizationIds: deselectedValues,
        });
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  /**
   * Search change handler.
   * @param query Search query.
   */
  protected onOptionsSearchChange(query: string): void {
    this.search$.next(query);
  }

  private createFilteredOptionsStream(): Observable<OptionSelect<BaseOrganization['id']>[]> {
    return combineLatest([
      this.organizationId$,
      this.componentType$,
      this.search$,
    ]).pipe(
      switchMap(([organizationId, componentType, name]) => this.getOrganizationsPalette(
        organizationId,
        { componentType, name },
      ).pipe(
        toggleExecutionState(this.isLoading$),
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createAllOptionsStream(): Observable<OptionSelect<BaseOrganization['id']>[]> {
    return combineLatest([
      this.organizationId$,
      this.componentType$,
    ]).pipe(
      switchMap(([organizationId, componentType]) => this.getOrganizationsPalette(
        organizationId,
        { componentType },
      )),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private getOrganizationsPalette(
    organizationId: BaseOrganization['id'],
    filters: PaletteOrganizationFilters,
  ): Observable<OptionSelect<BaseOrganization['id']>[]> {
    return this.paletteComponentsApiService.getOrganizationsPalette(
      organizationId, filters,
    ).pipe(
      map(
        organizations => organizations
          .map(organization => ({
            value: organization.id,
            label: organization.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
    );
  }
}
