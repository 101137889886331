import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';

import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';
import { EstimateItemsMultipleExportTreeMapperStrategies as Strategies } from './estimate-items-multiple-export-tree-mapper-strategies';
import { EstimateItemsMultipleExportTreeUtils as Utils } from './estimate-items-multiple-export-tree-utils';

export namespace EstimateTermsMultipleExportTreeMapper {

  /**
   * Map term nodes to term tree items.
   * @param nodes Term tree nodes.
   */
  export function mapTermNodes(nodes: readonly Types.EstimateChildrenNestedNode[]): TermTreeItem[] {
    const strategy = new Strategies.TermMapperStrategy();
    return Utils.mapItems(nodes, strategy);
  }

  /**
   * Build category trees from selected nodes and the original categories node tree.
   * @param sourceNestedNodeTrees Category nodes from which the tree was drawn.
   * @param selectedFlatNodes Selected flat nodes.
   */
  export function buildTreeFromCategoryNestedNodes(
    sourceNestedNodeTrees: readonly Types.EstimateChildrenNestedNode[],
    selectedFlatNodes: readonly Types.EstimateChildrenFlatNode[],
  ): TermCategoryTreeItem[] {
    const filteredNestedNodes = Utils.filterNotSelectedChildren(
      sourceNestedNodeTrees,
      selectedFlatNodes,
    );
    const strategy = new Strategies.TermCategoryMapperStrategy();
    return Utils.mapItems(filteredNestedNodes, strategy);
  }
}
