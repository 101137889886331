import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { BidCategory, FilterBidCategory } from '@dartsales/common/core/models/resources/bid-category';
import { BidCategoryApiService } from '@dartsales/common/core/services/api/bid-category-api.service';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';
import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';

/** Bid category input component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-bid-category-filter-input',
  templateUrl: './bid-category-filter-input.component.html',
  styleUrls: ['./bid-category-filter-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidCategoryFilterInputComponent implements FilterComponentType<FilterBidCategory[]>, OnInit {

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<BidCategory[]>();

  /** @inheritdoc */
  protected readonly trackByIndex = trackByIndex;

  /** Inner form control. */
  protected readonly innerFormControl = this.fb.control<readonly number[]>([]);

  /** Bid categories.  */
  private readonly bidCategories$ = this.createBidCategoryStream();

  /** Bid category ids options. */
  protected readonly bidCategoryIdsOptions$ = this.createBidCategoryIdsOptionsStream(this.bidCategories$);

  public constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly bidCategoryService: BidCategoryApiService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const categoryIds = this.formControl.value.map(bidCategory => bidCategory.id);
    this.innerFormControl.setValue(categoryIds, {
      emitEvent: false,
    });
    this.subscribeToInnerControlChanges();
  }

  private getOptionValue(bidCategory: BidCategory, categoriesForMatchName: readonly BidCategory[]): string {
    const isBidCategoryNotUniq = categoriesForMatchName.some(category => bidCategory !== category && category.name === bidCategory.name);
    if (isBidCategoryNotUniq) {
      return `${bidCategory.name} (${bidCategory.organizationName})`;
    }
    return bidCategory.name;
  }

  private createBidCategoryIdsOptionsStream(bidCategories$: Observable<BidCategory[]>): Observable<OptionSelect<number>[]> {
    return bidCategories$.pipe(
      map(bidCategories => bidCategories.map(
        bidCategory => ({
          label: this.getOptionValue(bidCategory, bidCategories),
          value: bidCategory.id,
        }),
      )),
    );
  }

  private createBidCategoryStream(): Observable<BidCategory[]> {
    return this.bidCategoryService.getUserBidCategories().pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private subscribeToInnerControlChanges(): void {
    combineLatest([
      listenControlChanges(this.innerFormControl),
      this.bidCategories$,
    ])
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(([selectedIds, bidCategories]) => {
        const selectedBidCategories = bidCategories.filter(bidCategory => selectedIds.some(id => bidCategory.id === id));
        this.formControl.setValue(selectedBidCategories);
      });
  }
}
