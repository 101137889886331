<button
  mat-icon-button
  type="button"
  class="lock-button"
  (click)="lockButtonClick.emit(!isLockedValue)"
>
  <mat-icon
    class="lock-button__icon"
    [class.lock-button__icon_close]="isLockedValue"
    [class.lock-button__icon_open]="!isLockedValue"
  >
    {{ isLockedValue ? "lock" : "lock_open" }}
  </mat-icon>
</button>
