import { inject, Injectable } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

import { AbstractCollapseRowsService, CollapseData } from '@dartsales/common/shared/components/ag-grid-table/services/abstract-table-collapse-rows.service';
import { LocalStorageManagerService } from 'projects/web/src/app/features/project-dashboard/services/local-storage-manager.service';

import { MaterialTableRowType } from '../view-models/material-table-row-view-model';

/** Material module collapse rows service. */
@Injectable()
export class MaterialTableCollapseRowsService extends AbstractCollapseRowsService<MaterialTableRowType> {

  private readonly localStorageManagerService = inject(LocalStorageManagerService);

  private readonly startCollapseData$ = this.createStartCollapseDataStream();

  /** @inheritdoc */
  public override readonly collapseChanged$ = this.createCollapseChangedStream(this.startCollapseData$);

  /** @inheritdoc */
  public readonly collapsibleRowTypes: readonly MaterialTableRowType[] = [
    'standardMaterialGroup',
    'materialsType',
  ];

  private createStartCollapseDataStream(): Observable<CollapseData<MaterialTableRowType>> {
    const collapsedRowTypes$ = toObservable(this.localStorageManagerService.getSettings(
      form => form.controls.material.controls.collapsedRowTypes,
    ).value).pipe(
      distinctUntilChanged(),
    );
    const collapsibleRowData$ = toObservable(this.localStorageManagerService.getSettings(
      form => form.controls.material.controls.collapsibleRowData,
    ).value).pipe(
      distinctUntilChanged(),
    );

    return combineLatest([
      collapsedRowTypes$,
      collapsibleRowData$,
    ]).pipe(
      map(([collapsedRowTypes, collapsibleRowData]) => ({ collapsedRowTypes, invertedRows: collapsibleRowData })),
    );
  }
}
