import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';

import { MarginParams } from '../../margin-params';

/** Estimate module units settings local storage. */
export class ModuleUnitsSettingsLocalStorage {

  /** Contingency units. */
  public readonly contingencyUnits: AmountCalcUnits;

  /** Escalation units. */
  public readonly escalationUnits: AmountCalcUnits;

  /** Margin params. */
  public readonly marginParams: MarginParams;

  /** DC units. */
  public readonly dcUnits: AmountCalcUnits;

  public constructor(data: ModuleUnitsSettingsLocalStorageInitArgs) {
    this.contingencyUnits = data.contingencyUnits;
    this.escalationUnits = data.escalationUnits;
    this.marginParams = data.marginParams;
    this.dcUnits = data.dcUnits;
  }
}

type ModuleUnitsSettingsLocalStorageInitArgs = ModuleUnitsSettingsLocalStorage;
