import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';
import { AmountPercentInputValues } from '@dartsales/common/core/models/forms/amount-percent-form';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { AmountInputHelpers } from '../../abstract-input';
import { AbstractAmountToggledInputComponent } from '../abstract-toggled-input';

/** Amount toggled input. */
@Component({
  selector: 'dartsalesw-amount-toggled-input',
  templateUrl: './amount-toggled-input.component.html',
  styleUrls: ['./amount-toggled-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => AmountToggledInputComponent)],
})
export class AmountToggledInputComponent extends AbstractAmountToggledInputComponent<AmountPercent> {

  /** @inheritdoc */
  protected valueToInput(value: AmountPercent): AmountPercentInputValues {
    return {
      amount: AmountInputHelpers.numberToInput(value.amount),
      percent: AmountInputHelpers.percentToInput(value.percent),
    };
  }

  /** @inheritdoc */
  protected valueFromInput(valueVm: AmountPercentInputValues): AmountPercent | null {
    const amount = AmountInputHelpers.numberFromInput(valueVm.amount, this.amountInputMask);
    const percent = AmountInputHelpers.percentFromInput(valueVm.percent, this.percentInputMask);
    if (amount !== null && percent !== null && this.controlValue !== null) {
      return new AmountPercent({ amount, percent });
    }
    return null;
  }

  /** @inheritdoc */
  protected checkShouldSetValue(value: AmountPercent | null): boolean {
    return value != null;
  }
}
