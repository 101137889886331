import { ChangeDetectionStrategy, Component } from '@angular/core';

import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { INPUTS_FRACTION_DIGITS, PERCENT_MASK } from '@dartsales/common/core/utils/constants';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';

import { AbstractInputComponent, AmountInputHelpers } from '../abstract-input';

/** Percent input component. */
@Component({
  selector: 'dartsalesw-percent-input',
  templateUrl: '../amount-input/amount-input.component.html',
  styleUrls: ['../amount-input/amount-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => PercentInputComponent)],
})
export class PercentInputComponent extends AbstractInputComponent<number> {

  /** @inheritdoc */
  protected override readonly maskOptions = PERCENT_MASK;

  /** @inheritdoc */
  protected checkIsInputValuesChanged(a: number, b: number): boolean {
    return !compareRoundNumbers(a, b, INPUTS_FRACTION_DIGITS);
  }

  /** @inheritdoc */
  protected override valueToInput(value: number | null): string {
    return AmountInputHelpers.percentToInput(value ?? 0);
  }

  /** @inheritdoc */
  protected override valueFromInput(valueVm: string): number | null {
    return AmountInputHelpers.percentFromInput(valueVm);
  }
}
