<!-- View mode layout. -->
<div
  class="view-mode"
  [class.view-mode_hidden]="isEditModeEnabled"
>
  <ng-container *ngIf="viewModeChild; else defaultViewMode">
    <ng-content select="[dartsalescTableCellViewMode]" />
  </ng-container>
</div>

<!-- Edit mode layout. -->
<div
  class="edit-mode"
  [class.edit-mode_visible]="isEditModeEnabled"
>
  <ng-content select="[dartsalescTableCellEditMode]" />
</div>

<ng-template #defaultViewMode>
  <!-- If we have edit mode child then we can try to display it's value. -->
  <div
    *ngIf="editModeChild"
    class="default-view-mode"
  >
    <ng-container *ngIf="editModeChild.input?.value; else placeholderViewMode">
      <span class="default-view-mode__text">
        {{ editModeChild.input?.value }}
      </span>
    </ng-container>
    <ng-template #placeholderViewMode>
      <div class="view-mode__placeholder">
        {{ editModeChild.input?.placeholder }}
      </div>
    </ng-template>
  </div>
</ng-template>

<!-- Prefix element layout. -->
<div
  *ngIf="cellPrefix"
  class="prefix"
>
  <ng-content select="[dartsalescTableCellPrefix]" />
</div>

<!-- Suffix element layout. -->
<div
  *ngIf="cellSuffix"
  class="suffix"
>
  <ng-content select="[dartsalescTableCellSuffix]" />
</div>
