import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Project layout header. */
@Component({
  selector: 'dartsalesw-project-layout-header',
  templateUrl: './project-layout-header.component.html',
  styleUrls: ['./project-layout-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectLayoutHeaderComponent {}
