<dartsalesc-base-table
  *ngIf="listManager"
  class="project-shared-table"
  [loading]="listManager.isLoading$ | async"
  [rows]="listManager.items$ | async"
  [columns]="columns"
  [trackBy]="trackById"
  [pagination]="listManager.pagePagination$ | async"
  [clickableRows]="true"
  [sort]="(listManager.sort$ | async)?.at(0)"
  (sortChange)="listManager.sortChanged($event ? [$event] : [])"
  (paginationChange)="listManager.paginationChanged($event, true)"
  (itemClick)="onTableRowClick($event)"
>
  <!-- Project name column for explore project. -->
  <ng-container
    dartsalescTableColumn="name"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectNameCol">
      <ng-template
        #projectNameCol
        let-row
      >
        <div class="project-name">
          <mat-icon
            color="primary"
            fontSet="material-icons-outlined"
            class="project-name__icon"
          >
            insert_drive_file
          </mat-icon>
          <span
            class="project-name__text"
            [title]="row.name"
          >
            {{ row.name }}
          </span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Awarded estaimte sell price column. -->
  <ng-container
    dartsalescTableColumn="awardedSellPrice"
    [maxWidth]="250"
  >
    <ng-container [dartsalescTableCell]="awardedSellPriceCol">
      <ng-template
        #awardedSellPriceCol
        let-row
      >
        {{
          row.awardedSellPrice !== null
            ? (row.awardedSellPrice | indentedCurrency)
            : "-"
        }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Base estimate sellprice column. -->
  <ng-container
    dartsalescTableColumn="baseSellPrice"
    [maxWidth]="250"
  >
    <ng-container [dartsalescTableCell]="baseSellPriceCol">
      <ng-template
        #baseSellPriceCol
        let-row
      >
        {{ row.baseEstimateSellPrice | currency }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project description column. -->
  <ng-container
    dartsalescTableColumn="description"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectDescriptionCol">
      <ng-template
        #projectDescriptionCol
        let-row
      >
        <span
          class="project-description"
          [title]="row.description"
        >
          {{ row.description }}
        </span>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project organization column. -->
  <ng-container
    dartsalescTableColumn="organizationName"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectOrganizationCol">
      <ng-template
        #projectOrganizationCol
        let-row
      >
        <div class="organization-cell">
          <dartsalesw-organization-avatar
            class="organization-avatar"
            [avatarUrl]="row.logoUrl"
          />
          {{ row.organizationName }}
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project code column. -->
  <ng-container
    dartsalescTableColumn="code"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectCodeCol">
      <ng-template
        #projectCodeCol
        let-row
      >
        {{ row.code }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project number. -->
  <ng-container
    dartsalescTableColumn="projectNumber"
    [maxWidth]="100"
  />

  <!-- Project status column. -->
  <ng-container
    dartsalescTableColumn="status"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectStatusCol">
      <ng-template
        #projectStatusCol
        let-row
      >
        {{ projectStatus.toReadable(row.status) }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project salesperson column. -->
  <ng-container
    dartsalescTableColumn="salesperson"
    [maxWidth]="300"
  >
    <ng-container [dartsalescTableCell]="projectSalespersonCol">
      <ng-template
        #projectSalespersonCol
        let-row
      >
        {{ salespersonToReadable(row.salesperson) }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project update at column. -->
  <ng-container
    dartsalescTableColumn="updatedAt"
    [maxWidth]="200"
  >
    <ng-container [dartsalescTableCell]="lastModifiedCol">
      <ng-template
        #lastModifiedCol
        let-row
      >
        {{ row.updatedAt | formatDate }}
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project bid categories column. -->
  <ng-container
    dartsalescTableColumn="bidCategories"
    [maxWidth]="200"
  >
    <ng-container [dartsalescTableCell]="bidCategories">
      <ng-template
        #bidCategories
        let-row
      >
        <div
          *ngrxLet="
            mapBidCategoriesToString(row.bidCategories)
              | join: { emptyValue: '-' } as categories
          "
          class="bid-categories"
          [title]="categories"
        >
          {{ categories }}
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Project location column. -->
  <ng-container
    dartsalescTableColumn="location"
    [maxWidth]="200"
  >
    <ng-container [dartsalescTableCell]="locationCol">
      <ng-template
        #locationCol
        let-row
      >
        <div
          class="location"
          [title]="row.location?.addressString"
        >
          {{ row.location?.addressString }}
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</dartsalesc-base-table>
