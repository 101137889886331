<div
  *ngIf="cellPrefix"
  class="prefix"
>
  <ng-content select="[dartsalescTableCellPrefix]" />
</div>
<dartsalesc-masked-number-cell-renderer
  *ngIf="(isEditMode$ | async) === false; else editMode"
  [class.cell__has-prefix]="cellPrefix"
  [shouldHideValue]="hideValue"
  [value]="controlValue"
  [mask]="mask"
  [placeholder]="placeholder"
  [isFocusable]="(isFocusable$ | async) ?? false"
/>
<ng-template #editMode>
  <dartsalesc-masked-number-cell-editor
    *ngIf="controlValue !== null"
    [class.cell__has-prefix]="cellPrefix"
    [errorStateMatcher]="errorStateMatcher"
    [mask]="mask"
    [formControl]="formControl"
    [placeholder]="placeholder"
  />
</ng-template>
