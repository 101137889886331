import { Injectable } from '@angular/core';

import { PaginationData } from '../../models/list-utilities/pagination-data';

import { PaginationParamsDto } from './dto/pagination-params.dto';
import { IMapperToDto } from './mappers';

/** Mapper for Pagination Data. */
@Injectable({ providedIn: 'root' })
export class PaginationDataMapper implements IMapperToDto<PaginationParamsDto | undefined, PaginationData | undefined> {
  /** @inheritdoc */
  public toDto(pagination?: PaginationData): PaginationParamsDto | undefined {
    if (!pagination) {
      return undefined;
    }

    return {
      Page: pagination.page,
      PageSize: pagination.pageSize,
    };
  }
}
