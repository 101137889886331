/* eslint-disable @typescript-eslint/no-explicit-any */
// The implementation was taken from here:
// https://github.com/lodash/lodash/issues/2403#issuecomment-1158234729

import { debounce, memoize } from 'lodash-es';

type MemoizeDebouncedFunction<F extends (...args: any[]) => any> = {

  /** Parameters of a function type. */
  (...args: Parameters<F>): void;

  /** Flush. */
  flush: (...args: Parameters<F>) => void;
};

/**
 * Memoize debounce.
 * @param func Function for memoization.
 * @param wait The number of milliseconds to delay.
 * @param options Debounce settings.
 * @param resolver The function to resolve the cache key.
 */
export function memoizeDebounce<F extends(...args: any[]) => any>(
  func: F,
  wait = 0,
  options: _.DebounceSettings = {},
  resolver?: (...args: Parameters<F>) => unknown): MemoizeDebouncedFunction<F> {
  const debounceMemo = memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
    () => debounce(func, wait, options), resolver);

  /**
   * Wrapped function.
   * @param args Parameters of a function type.
   */
  function wrappedFunction(...args: Parameters<F>): ReturnType<F> | undefined {
    return debounceMemo(...args)(...args);
  }

  wrappedFunction.flush = (...args: Parameters<F>): void => {
    debounceMemo(...args).flush();
  };

  return wrappedFunction as unknown as MemoizeDebouncedFunction<F>;
}
