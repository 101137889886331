import { SortDirection } from '../../enums/sort-direction';

type SortInitArgs = Sort;

/** Sort setting for table. */
export class Sort {
  /** Fiend name. */
  public readonly field: string;

  /** Direction. */
  public readonly direction: SortDirection;

  public constructor(data: SortInitArgs) {
    this.field = data.field;
    this.direction = data.direction;
  }
}
