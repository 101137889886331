import { EditModuleLockablePercentsStatusDto } from '@dartsales/common/core/services/mappers/dto/estimate/module-lockable-percents-status.dto';

import { BasePricing, BasePricingInitArgs } from '../../../pricing/base-pricing';
import { ModuleLockablePercentsStatus } from '../../module-lockable-percents';

import { ExpensesRoleCost } from './expenses-role-cost';
import { ExpensesTravelCost } from './expenses-travel-cost';

/** Expenses pricing. */
export class ExpensesPricing extends BasePricing {

  /**  Travel cost. */
  public readonly travelCost: ExpensesTravelCost;

  /** Role costs. */
  public readonly roleCosts: readonly ExpensesRoleCost[];

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(data: ExpensesCalcInitArgs) {
    super(data);
    this.travelCost = data.travelCost;
    this.roleCosts = data.roleCosts;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }
}

type ExpensesCalcInitArgs = BasePricingInitArgs & Pick<ExpensesPricing, 'travelCost' | 'roleCosts' | 'lockablePercentsStatus'>;

/** Batch edit lockable percents status DTO. */
export type BatchEditLockablePercentsStatusDto = {

  /** Expanses items. */
  readonly summaries: readonly {

    /** Expanses item ID. */
    readonly expensesTravelCostId: ExpensesPricing['travelCost']['id'];

    /** Lockable percents status. */
    readonly lockablePercentsStatus: EditModuleLockablePercentsStatusDto;
  }[];
};
