import { AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationErrorCode } from '@dartsales/common/core/models/errors/validation-error-code';
import { EstimateViewExportParams } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';
import { EstimateMultipleExportParamsFormValues } from '@dartsales/common/core/models/forms/export/estimate-multiple-export-params-form';

export namespace EstimateExportParamsFormValidators {
  const EXPORTED_MODULES_NOT_SELECTED_MESSAGE = 'Please select at least one element';

  /**
   * Exported data selected validator.
   * @param control Control.
   */
  export function exportedDataSelected(control: AbstractControl<EstimateMultipleExportParamsFormValues>): ValidationErrors | null {
    const haveSelectedInternalChild = checkHaveSelectedChild(control.value.internalView);
    const haveSelectedCustomerChild = checkHaveSelectedChild(control.value.customerView);
    if (!haveSelectedInternalChild && !haveSelectedCustomerChild) {
      return {
        [ValidationErrorCode.AppError]: EXPORTED_MODULES_NOT_SELECTED_MESSAGE,
      };
    }
    return null;
  }

  /**
   * Check have selected child.
   * @param values Values.
   */
  function checkHaveSelectedChild(values: EstimateViewExportParams | null): boolean {
    if (values == null) {
      return false;
    }
    const haveSelectedModule = values.moduleTypes.length > 0;
    const haveSelectedTerm = values.terms.length > 0;
    return haveSelectedModule || haveSelectedTerm;
  }
}
