import { StrictOmit } from '../../utils/types/strict-omit';
import { DomainEstimateTotalsCalculator } from '../calculators/domain/domain-estimate-totals-calculator';
import { CompoundEscalationPercentOptions } from '../compound-escalation-options';
import { TermSummary } from '../estimate-services/term-summary';
import { BasePricing } from '../pricing/base-pricing';

import { EstimateSummary } from './estimate-summary';
import { ModulePropertiesList } from './module-properties-list';
import { ModuleProperties } from './modules/module-properties';
import { ShortEstimate } from './short-estimate';

/** Estimate. */
export class Estimate extends ShortEstimate {

  /** Modules properties. */
  public readonly modulesProperties: ModulePropertiesList;

  /** Totals by subcontractors, expenses, custom, labor, material. */
  public readonly modulesTotals: ModuleProperties;

  /** Totals by modules and services. */
  public readonly totals: EstimateSummary;

  /** Term summaries. */
  public readonly terms: readonly TermSummary[];

  /** Compound escalation options. */
  public readonly compoundEscalation: CompoundEscalationPercentOptions;

  /** Sell price. */
  public readonly sellPrice: BasePricing['sellPrice'];

  public constructor(data: EstimateInitArgs) {
    super(data);
    this.modulesProperties = data.modulesProperties;
    this.terms = data.terms;
    this.compoundEscalation = data.compoundEscalation;
    this.sellPrice = data.sellPrice;

    this.modulesTotals = data.modulesTotals;
    this.totals = DomainEstimateTotalsCalculator.calculateTotals(this.modulesTotals, this.terms);
  }
}

type EstimateInitArgs = StrictOmit<Estimate, 'totals'> & Pick<BasePricing, 'sellPrice'>;

/** Estimate ID. */
export type EstimateId = Estimate['id'];

/** Data required to edit estimate details. */
export type EditEstimateDetails = Pick<Estimate, 'name' | 'description'>;
