import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ReplaySubject, BehaviorSubject, Observable, combineLatest, switchMap, shareReplay, map } from 'rxjs';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { PaletteManufacturer } from '@dartsales/common/core/models/components/palette/palette-manufacturer';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { BaseOrganization } from '@dartsales/common/core/models/organization';
import { PaletteComponentsApiService } from '@dartsales/common/core/services/api/palette-components-api.service';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';

/** Manufacturer filters. */
@Component({
  selector: 'dartsalesw-manufacturer-filters',
  templateUrl: './manufacturer-filters.component.html',
  styleUrls: ['./manufacturer-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerFiltersComponent {

  /** Current sort direction. */
  @Input()
  public sortDirection: SortDirection | null = SortDirection.ASC;

  /** Active manufacturers. */
  @Input()
  public activeManufacturers: readonly string[] = [];

  /** Organization ID. */
  @Input()
  public set organizationId(organizationId: BaseOrganization['id'] | null) {
    if (organizationId != null) {
      this.organizationId$.next(organizationId);
    }
  }

  /** Organization ID. */
  private readonly organizationId$ = new ReplaySubject<BaseOrganization['id']>(1);

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  /** Manufacturers change. */
  @Output()
  public readonly manufacturersChange = new EventEmitter<string[]>();

  private readonly paletteComponentsApiService = inject(PaletteComponentsApiService);

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  private readonly search$ = new BehaviorSubject('');

  /** Options. */
  protected readonly options$ = this.createOptionsStream();

  /** Current sort direction. */
  protected readonly sortDirections = SortDirection;

  /** Whether sort field is active. */
  protected get isActive(): boolean {
    return this.sortDirection != null;
  }

  /**
   * Sort direction change handler.
   * @param direction Sort direction.
   */
  public onSortDirectionChange(direction: SortDirection): void {
    this.sortDirectionChange.emit(direction);
  }

  /**
   * Options change handler.
   * @param selectedValues Selected values.
   */
  protected onSelectedOptionsChange(selectedValues: readonly PaletteManufacturer['name'][]): void {
    this.manufacturersChange.emit([...selectedValues]);
  }

  /**
   * Search change handler.
   * @param query Search query.
   */
  protected onOptionsSearchChange(query: string): void {
    this.search$.next(query);
  }

  private createManufacturersStream(): Observable<PaletteManufacturer[]> {
    return combineLatest([
      this.organizationId$,
      this.search$,
    ]).pipe(
      switchMap(([organizationId, name]) => this.paletteComponentsApiService.getManufacturersPalette(
        organizationId,
        { name },
      ).pipe(
        toggleExecutionState(this.isLoading$),
      )),
    );
  }

  private createOptionsStream(): Observable<OptionSelect<PaletteManufacturer['name']>[]> {
    const manufacturers$ = this.createManufacturersStream();
    return manufacturers$.pipe(
      map(
        manufacturers => manufacturers
          .map<OptionSelect<PaletteManufacturer['name']>>(manufacturer => ({
            value: manufacturer.name,
            label: manufacturer.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
