import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { COMMON_MIME_TYPE } from 'projects/web/src/app/features/project-files/utils/get-mime-type';

import { IMapperFromDto } from './mappers';

const filenameRegExp = new RegExp('filename="(.*)"');

/** Blob with name mapper. */
@Injectable({ providedIn: 'root' })
export class BlobResponseMapper implements IMapperFromDto<HttpResponse<Blob>, File> {

  /** @inheritdoc */
  public fromDto(dto: HttpResponse<Blob>): File {
    const name = this.getNameFromHeaders(dto.headers) ?? this.generateRandomName();
    const type = dto.headers.get('content-type') ?? COMMON_MIME_TYPE;

    return new File(
      [dto.body ?? new Blob()],
      name,
      { type },
    );
  }

  private getNameFromHeaders(headers: HttpHeaders): string | undefined {
    const headerString = headers.get('content-disposition');
    return filenameRegExp.exec(headerString ?? '')?.at(1);
  }

  private generateRandomName(): string {
    return Date.now().toString();
  }
}
