import { Injectable, inject } from '@angular/core';

import { LaborModuleProperties } from '@dartsales/common/core/models/estimate/modules/labor/labor-module-properties';

import { ModulePropertiesMapper } from '../module-properties.mapper';
import { IMapper } from '../../../mappers';
import { LaborModulePropertiesDto } from '../../../dto/estimate/modules/labor/labor-module.dto';

/** Labor module properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborModulePropertiesMapper implements IMapper<LaborModulePropertiesDto, LaborModuleProperties> {
  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborModulePropertiesDto): LaborModuleProperties {
    return new LaborModuleProperties({
      ...this.propertiesMapper.fromDto(dto),
      grandTotalCost: dto.grandTotalCost,
      grandTotalHours: dto.grandTotalHours,
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborModuleProperties): LaborModulePropertiesDto {
    return {
      ...this.propertiesMapper.toDto(data),
      grandTotalCost: data.grandTotalCost,
      grandTotalHours: data.grandTotalHours,
    };
  }
}
