import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { ViewType } from '@dartsales/common/core/models/view-type';
import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';

import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';
import { EstimateItemsMultipleExportTreeMappersToNode as MapperToNestedNode } from './estimate-items-multiple-export-tree-mappers-to-node';
export namespace EstimateItemsMultipleExportNestedNodeBuilder {
  const estimateModulesNode: Types.EstimateChildrenNestedNode = {
    title: 'Modules',
    value: {
      item: null,
      type: Types.NodeType.Null,
      key: 'Estimate Modules',
    },
    children: ModuleType.getBaseModules().map(moduleType => MapperToNestedNode.mapEstimateModuleType(moduleType)),
  };

  /**
   * Create point lists node.
   * @param viewType View type.
   */
  function createPointListsNode(
    viewType: ViewType,
  ): Types.EstimateChildrenNestedNode {
    return {
      title: viewType === ViewType.Customer ? 'Points List w/out Cost' : 'Points List w/ Cost',
      children: [],
      value: {
        item: ModuleType.PointsList,
        type: Types.NodeType.EstimateModuleType,
        key: 'Points List',
      },
    };
  }

  /**
   * Create estimate nested tree.
   * @param viewType Estimate view type.
   * @param terms Terms.
   */
  export function createEstimateNestedTree(
    viewType: ViewType,
    terms: readonly TermTreeItem[],
  ): Types.EstimateChildrenNestedNode {
    const servicesNode = createServicesNode(terms);
    const shouldDisplayServicesNode = servicesNode.children.length > 0;
    const pointListsNode = createPointListsNode(viewType);
    return {
      title: ViewType.toReadable(viewType),
      value: {
        item: null,
        type: Types.NodeType.Null,
        key: 'View Type',
      },
      children: [
        estimateModulesNode,
        pointListsNode,
      ].concat(
        shouldDisplayServicesNode ? [servicesNode] : [],
      ),
    };
  }

  /**
   * Create estimate services nested node.
   * @param terms Term.
   */
  function createServicesNode(
    terms: readonly TermTreeItem[],
  ): Types.EstimateChildrenNestedNode {
    return {
      title: 'Services',
      value: {
        type: Types.NodeType.Null,
        item: null,
        key: 'Estimate Services',
      },
      children: terms.map(term => MapperToNestedNode.mapTerm(term)),
    };
  }
}
