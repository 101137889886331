<dartsalesc-base-dialog
  class="dialog"
  dialogTitle="Export Multiple"
>
  <form
    class="config-form"
    [formGroup]="configForm"
  >
    <div class="modules-selects">
      <dartsalesw-select-estimate-children-control
        [estimateId]="estimateId"
        [viewType]="viewType.Internal"
        (paramsSelected)="configForm.controls.internalView.setValue($event)"
      />
      <dartsalesw-select-estimate-children-control
        [estimateId]="estimateId"
        [viewType]="viewType.Customer"
        (paramsSelected)="configForm.controls.customerView.setValue($event)"
      />
    </div>

    <section class="config-form__bottom-section">
      <div
        *ngIf="shouldShowFormError"
        class="config-form__error body-2"
      >
        {{ formAppError }}
      </div>

      <dartsalesw-select-format-control
        [formControl]="configForm.controls.exportFormat"
      />
    </section>
  </form>

  <dartsalesc-base-dialog-footer
    dialogFooter
    actionButtonText="Export"
    [isLoading]="isLoading$ | async"
    (actionButtonClick)="onSubmit()"
    (cancelButtonClick)="dialogRef.close()"
  />
</dartsalesc-base-dialog>
