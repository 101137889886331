import { Injectable, inject } from '@angular/core';

import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';

import { CategoryExportConfigDto } from '../dto/export/category-export-config.dto';
import { IMapperToDto } from '../mappers';

import { SubCategoryExportConfigMapper } from './sub-category-export-config.mapper';

/** Category export config mapper. */
@Injectable({
  providedIn: 'root',
})
export class CategoryExportConfigMapper implements IMapperToDto<CategoryExportConfigDto, TermCategoryTreeItem> {

  private readonly subCategoryExportConfigMapper = inject(SubCategoryExportConfigMapper);

  /** @inheritdoc */
  public toDto(data: TermCategoryTreeItem): CategoryExportConfigDto {
    return {
      categoryId: data.value.categoryId,
      subCategories: data.children.map(subCategory => this.subCategoryExportConfigMapper.toDto(subCategory)),
    };
  }
}
