import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

/** Points list system classification. */
export class PointsListSystemClassification {
  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Labor multiplier. */
  public readonly laborMultiplier: number;

  /**
   * Is system classification removed.
   * This property is used for 'bulk update' feature.
   */
  public readonly isRemoved: boolean;

  public constructor(data: PointsListSystemClassificationInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.laborMultiplier = data.laborMultiplier;
    this.isRemoved = data.isRemoved ?? false;
  }
}

type PointsListSystemClassificationInitArgs = StrictOmit<PointsListSystemClassification, 'isRemoved'> &
{

  /**
   * Is system classification removed.
   * This property will be 'false' if not passed.
   */
   readonly isRemoved?: boolean;
};
