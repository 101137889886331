
import { Injectable, inject } from '@angular/core';

import { BatchEditLockablePercentsStatusDto, ExpensesPricing } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-pricing';
import { ExpensesTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-travel-cost';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';
import { ExpensesRoleCost } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-role-cost';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { ExpensesPricingDto } from '../../../dto/estimate/modules/expenses/expenses-pricing.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';

/** Expenses pricing entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExpensesPricingMapper {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(
    pricingDto: ExpensesPricingDto,
    joinData: {
      readonly travelCosts: readonly ExpensesTravelCost[];
    },
  ): ExpensesPricing {
    // expenses uses `directCost` into DTO instead of `cost`
    const adaptedDto = {
      ...pricingDto,
      cost: pricingDto.directCost,
    };

    return new ExpensesPricing({
      ...this.basePricingMapper.fromDto(adaptedDto),
      travelCost: this.getTravelCost(pricingDto, joinData.travelCosts),
      roleCosts: this.getRoleCosts(pricingDto),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(pricingDto.lockablePercentsStatus),
    });
  }

  private getTravelCost(pricingDto: ExpensesPricingDto, travelCosts: readonly ExpensesTravelCost[]): ExpensesTravelCost {
    const travelCost = travelCosts.find(cost => cost.id === pricingDto.expensesTravelCostId);
    assertNonNull(travelCost);
    return travelCost;
  }

  private getRoleCosts(pricingDto: ExpensesPricingDto): readonly ExpensesRoleCost[] {
    return pricingDto.roles.map(roleDto => new ExpensesRoleCost({
      cost: roleDto.costs.reduce((sum, cost) => sum + cost.directCost, 0),
      role: {
        id: roleDto.id,
        name: this.confirmedOverridableMapper.fromDto(roleDto.name),
      },
    }));
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Expanses module pricing ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: string,
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      summaries: [
        {
          expensesTravelCostId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
