import { DestroyRef, Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';

/** Auroresize textarea directive. */
@Directive({
  selector: 'textarea[dartsalescAutoresizeTextarea]',
})
export class AutoresizeTextareaDirective implements OnInit {

  /** Min number of rows to display in textarea. */
  @Input()
  public textareaMinRows = 2;

  /** Max number of rows to display in textarea. */
  @Input()
  public textareaMaxRows = 15;

  /** Average text height in pixels. */
  @Input()
  public averageTextHeight = 20;

  private readonly textareaRef = inject<ElementRef<HTMLTextAreaElement>>(ElementRef);

  private readonly ngControl = inject(NgControl, { optional: true });

  private readonly destroyRef = inject(DestroyRef);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.resizeTextarea();
    this.subscribeToDescriptionChange();
  }

  private subscribeToDescriptionChange(): void {
    this.ngControl?.control?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(() => this.resizeTextarea());
  }

  private resizeTextarea(): void {
    if (this.textareaRef == null) {
      return;
    }

    const { nativeElement } = this.textareaRef;
    if (nativeElement.scrollHeight === nativeElement.clientHeight) {
      nativeElement.rows = this.textareaMinRows;
    }

    if (nativeElement.scrollHeight > nativeElement.clientHeight) {
      nativeElement.rows = this.getUpdatedTextareaCountRows(nativeElement.scrollHeight);
    }
  }

  private getUpdatedTextareaCountRows(scrollHeight: number): number {
    const updatedHeight = scrollHeight / this.averageTextHeight;

    if (updatedHeight < this.textareaMinRows) {
      return this.textareaMinRows;
    }

    if (updatedHeight > this.textareaMaxRows) {
      return this.textareaMaxRows;
    }
    return updatedHeight;
  }
}
