import { Injectable, inject } from '@angular/core';

import { FinalEstimateServiceTotals } from '@dartsales/common/core/models/estimate/modules/service-totals/final-estimate-service-totals';

import { IMapperFromDto } from '../../../../mappers';
import { FinalEstimateServiceTotalsDto } from '../../../../dto/estimate/final-estimate/modules/service-totals/final-estimate-service-totals.dto';
import { FinalEstimatePricingMapper } from '../../../../pricing/final-estimate-pricing.mapper';

import { FinalEstimateServiceByServicesMapper } from './service-totals-services.mapper';
import { FinalEstimateServiceByModulesMapper } from './service-totals-modules.mapper';

/** Final estimate service totals mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateServiceTotalsMapper implements
  IMapperFromDto<FinalEstimateServiceTotalsDto, FinalEstimateServiceTotals> {

  private readonly termFinalEstimatePricingMapper = inject(FinalEstimatePricingMapper);

  private readonly modulesMapper = inject(FinalEstimateServiceByModulesMapper);

  private readonly servicesMapper = inject(FinalEstimateServiceByServicesMapper);

  /** @inheritdoc */
  public fromDto(dto: FinalEstimateServiceTotalsDto): FinalEstimateServiceTotals {
    return new FinalEstimateServiceTotals({
      ...this.termFinalEstimatePricingMapper.fromDto(dto),
      moduleSummaries: this.modulesMapper.fromDto(dto.moduleSummaries),
      serviceSummaries: this.servicesMapper.fromDto(dto.serviceSummaries),
    });
  }
}
