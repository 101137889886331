import { AfterViewInit, Directive, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { OnTouchedFn } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { TableCellControlErrorStateMatcher } from '../utils/table-cell-control-error-state-matcher';
import { getErrorMessage } from '../../validation-message/get-error-message';
import { EditableTableCellComponent } from '../editable-table-cell.component';

// Angular requires some decorator so that DI would get things right,
// Directive is added to avoid specifying Component's metadata.
/** Table cell base component. */
@UntilDestroy()
@Directive()
export abstract class TableCellFormControl<T> extends SimpleValueAccessor<T> implements AfterViewInit {

  private readonly ngControl = inject(NgControl);

  /** Error state matcher. */
  @Input()
  public errorStateMatcher = new TableCellControlErrorStateMatcher(this.ngControl);

  /** Control touch event. */
  @Output()
  public readonly touched = new EventEmitter<void>();

  @ViewChild(EditableTableCellComponent)
  private cellComponent?: EditableTableCellComponent;

  /** Error message as a string. */
  protected get errorMessage(): string | null {
    if (this.errorStateMatcher.isErrorState()) {
      const control = this.errorStateMatcher.ngControl;
      return control.errors ? getErrorMessage(control.errors) : null;
    }
    return null;
  }

  public constructor() {
    super();
    this.ngControl.valueAccessor = this;
  }

  /** @inheritdoc */
  public override registerOnTouched(fn: OnTouchedFn): void {
    const newFn: OnTouchedFn = () => {
      this.touched.emit();
      fn();
    };
    super.registerOnTouched(newFn);
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.cellComponent?.cellBlur.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.emitTouched();
      this.changeDetectorRef.markForCheck();
    });
  }
}
