import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { FetchListOptionsParamsMapper } from '../mappers/http-params.mapper';
import { TagFilters } from '../../models/filters/tag-filters';
import { TagFiltersMapper } from '../mappers/filters/tag-filters.mapper';
import { TagDto } from '../mappers/dto/project/tag.dto';

import { AppUrlsConfig } from './app-urls.config';

/** Tags API service. */
@Injectable({
  providedIn: 'root',
})
export class TagsApiService {
  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly http: HttpClient,
    private readonly paramsMapper: FetchListOptionsParamsMapper<TagFilters>,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly listMapper: PagedListMapper,
    private readonly tagFiltersMapper: TagFiltersMapper,
  ) { }

  /**
   * Get list of tags.
   * @param options Request options.
   */
  public getTagsList(options: FetchListOptions<TagFilters>): Observable<PagedList<string>> {
    const params = this.paramsMapper.toDto(options, this.tagFiltersMapper);
    return this.http.get<PagedListDto<TagDto>>(this.apiUrls.tagsApi.getTags, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        { fromDto: dto => dto.name },
        options.pagination,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
