import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PERCENT_MASK, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE } from '@dartsales/common/core/utils/constants';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { OverridableAmountInputComponent } from '../overridable-amount-input/overridable-amount-input.component';
import { AmountInputHelpers } from '../abstract-input';

/** Percent input component. */
@Component({
  selector: 'dartsalesw-overridable-percent-input',
  templateUrl: '../overridable-amount-input/overridable-amount-input.component.html',
  styleUrls: ['../overridable-amount-input/overridable-amount-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => OverridablePercentInputComponent)],
})
export class OverridablePercentInputComponent extends OverridableAmountInputComponent {

  /** Mask options. */
  protected override readonly maskOptions = PERCENT_MASK;

  /** @inheritdoc */
  protected override checkIsInputValuesChanged(initialValue: Overridable<number>, valueForCompare: Overridable<number>): boolean {
    return this.checkIsInputValuesChangedWithRound(initialValue, valueForCompare, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE);
  }

  /** @inheritdoc */
  protected override valueToInput(value: Overridable<number>): string {
    return value != null ? AmountInputHelpers.percentToInput(value.combinedValue) : '';
  }

  /** @inheritdoc */
  protected override valueFromInput(valueVm: string): Overridable<number> | null {
    const value = AmountInputHelpers.percentFromInput(valueVm);

    if (value !== null && this.controlValue) {
      return new Overridable({ initial: this.controlValue.initial, override: value });
    }

    return null;
  }
}
