import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/** Custom project page search service.  */
@Injectable()
export class CustomProjectPageSearchService {

  /** Search portal template. */
  public readonly customPageSearchTemplate$ = new BehaviorSubject<TemplatePortal | null>(null);
}
