import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { PagedList } from '../../models/list-utilities/paged-list';
import { ExploreProjectsItem } from '../../models/project/explore-projects-item';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { ExploreProjectsItemDto } from '../mappers/dto/explore-projects-item.dto';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { ExploreProjectsItemMapper } from '../mappers/explore-projects-item.mapper';
import { TabFilters } from '../../models/filters/tab-filter';
import { QueryFilterParamsMapper } from '../mappers/query-filter-params.mapper';
import { Project, ProjectEdit } from '../../models/project/project';
import { ProjectDto } from '../mappers/dto/project/project.dto';
import { ProjectMapper } from '../mappers/project/project.mapper';
import { ProjectStatus } from '../../enums/project-status';
import { FilterOperatorType } from '../../models/filters/multi-condition-filters/filter-operator-type';
import { ProjectFilters } from '../../models/filters/multi-condition-filters/project-search-filters/project-filters';
import { ProjectFilterFieldType, projectsFilterHelper } from '../../models/filters/multi-condition-filters/project-search-filters/all-filter-fields';
import { RecentProject } from '../../models/project/recent-project';
import { RecentProjectDto } from '../mappers/dto/project/recent-project.dto';
import { RecentProjectMapper } from '../mappers/project/recent-project.mapper';
import { MultiConditionFilterMapper } from '../mappers/filters/multi-condition-filter.mapper';
import { BaseOrganization } from '../../models/organization';
import { buildHttpParams } from '../../utils/build-http-params';
import { CloneProjectResultDto } from '../mappers/dto/clone-project-result.dto';

import { AppUrlsConfig } from './app-urls.config';

/** Projects API service . */
@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly exploreProjectsItemMapper = inject(ExploreProjectsItemMapper);

  private readonly listMapper = inject(PagedListMapper);

  private readonly queryFilterMapper = inject(QueryFilterParamsMapper);

  private readonly projectMapper = inject(ProjectMapper);

  private readonly multiConditionFilterMapper = inject(MultiConditionFilterMapper<ProjectFilterFieldType>);

  private readonly recentProjectMapper = inject(RecentProjectMapper);

  /** Get recent projects list. */
  public getRecentProjects(): Observable<RecentProject[]> {
    return this.http.get<RecentProjectDto[]>(this.apiUrls.projectsApi.getRecent).pipe(
      map(response => response.map(dto => this.recentProjectMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get recent projects list.
   * @param options Fetch options.
   */
  public getExploreProjectsList(options: FetchListOptions<TabFilters>): Observable<PagedList<ExploreProjectsItem>> {
    const organizationIds = options.filter.organizationId;
    const filtersDto = this.queryFilterMapper.toDtoWithPaginationParams(options, organizationIds.length ? [
      {
        propertyName: 'projectOrganizationId',
        operatorType: FilterOperatorType.IsOneOf,
        values: organizationIds.map(id => String(id)),
      },
    ] : []);
    const params = buildHttpParams(filtersDto);

    return this.http.get<PagedListDto<ExploreProjectsItemDto>>(this.apiUrls.projectsApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.exploreProjectsItemMapper,
        options.pagination,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get projects list.
   * @param options Fetch options.
   * @param filtersHelper Filters helper class.
   */
  public getProjectsList(
    options: FetchListOptions<ProjectFilters>,
  ): Observable<PagedList<ExploreProjectsItem>> {
    const fields = options.filter.filters.map(filter => this.multiConditionFilterMapper.toDto(filter, projectsFilterHelper));
    const fieldsDto = this.queryFilterMapper.toDtoWithPaginationParams(options, fields);

    const params = buildHttpParams({
      ...fieldsDto,
      FieldsContain: options.filter.search,
    });

    return this.http.get<PagedListDto<ExploreProjectsItemDto>>(this.apiUrls.projectsApi.getList, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.exploreProjectsItemMapper,
        options.pagination,
      )),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get project by id.
   * @param projectId Project id.
   */
  public getProjectById(projectId: Project['id']): Observable<Project> {
    const url = this.apiUrls.projectsApi.getById(projectId);
    return this.http.get<ProjectDto>(url).pipe(
      map(dto => this.projectMapper.fromDto(dto, projectId)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete project.
   * @param projectId Project id.
   */
  public deleteById(projectId: Project['id']): Observable<void> {
    return this.http.delete<void>(this.apiUrls.projectsApi.delete(projectId)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create project.
   * @param project Project edit model.
   * @param organizationId Organization id.
   * @returns Create project id.
   */
  public createProject(project: ProjectEdit, organizationId: BaseOrganization['id']): Observable<Project['id']> {
    const editDto = this.projectMapper.toCreateDto(project);

    return this.http.post<number>(this.apiUrls.projectsApi.create, editDto, {
      params: {
        organizationId,
      },
    }).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.projectMapper,
      ),
    );
  }

  /**
   * Initiate a background operation for create new base estimate from template.
   * @param id Template ID.
   * @param data Data required to create a new base estimate.
   * @returns Background operation UUID.
   */
  public createFromTemplate(id: Project['id'], data: ProjectEdit): Observable<string> {
    const editDto = this.projectMapper.toCreateDto(data);

    return this.http.post<CloneProjectResultDto>(this.apiUrls.projectsApi.clone(id), editDto, {
      params: { id, isTemplate: false },
    }).pipe(
      map(result => result.duplicationJobId),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.projectMapper,
      ),
    );
  }

  /**
   * Update project.
   * @param id Project id.
   * @param project Project edit model.
   */
  public updateProject(id: Project['id'], project: ProjectEdit): Observable<Project['id']> {
    const editDto = this.projectMapper.toDto(project);

    return this.http.put<ProjectDto>(this.apiUrls.projectsApi.update(id), editDto)
      .pipe(
        map(() => id),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.projectMapper,
        ),
      );
  }

  /**
   * Update project status.
   * @param id Project id.
   * @param status Project status.
   */
  public updateProjectStatus(id: Project['id'], status: ProjectStatus): Observable<void> {
    const editDto: Pick<ProjectDto, 'status'> = {
      status,
    };

    return this.http.put<void>(this.apiUrls.projectsApi.updateStatus(id), editDto).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
