import { InjectionToken, Provider, Type, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { EstimateCalculatorPreferences } from '@dartsales/common/core/models/calculators/estimate/estimate-calculator-preferences';
import { EstimateLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-local-storage';
import { Estimate } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateBaseModuleKey, EstimateModuleKey } from '@dartsales/common/core/models/estimate/modules-list';
import { ViewType } from '@dartsales/common/core/models/view-type';
import { ModulesOverrides } from '@dartsales/common/core/models/estimate/modules-overrides';
import { EstimateType } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';
import { TermSummary } from '@dartsales/common/core/models/estimate-services/term-summary';
import { ModuleProperties } from '@dartsales/common/core/models/estimate/modules/module-properties';
import { TermProperties } from '@dartsales/common/core/models/estimate-services/term-properties';
import { BaseModulesTypes } from '@dartsales/common/core/enums/module-type';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';

/** Service module URLs. */
export type ServiceModulesUrls = Record<EstimateBaseModuleKey, string>;

/** Estimate URLs. */
export class EstimateUrls {

  /** Estimate URL. */
  public readonly estimate: string;

  /** URLs for modules. */
  public readonly modules: Readonly<Record<EstimateModuleKey, string>>;

  /** Term URL builder. */
  public readonly buildTermUrl: (termId: number) => string;

  /** Build service modules urls. */
  public readonly buildServiceModulesUrls: (termId: number, serviceId: number) => ServiceModulesUrls;

  public constructor(data: EstimateUrls) {
    this.estimate = data.estimate;
    this.modules = data.modules;
    this.buildTermUrl = data.buildTermUrl;
    this.buildServiceModulesUrls = data.buildServiceModulesUrls;
  }
}

/** Estimate preferences. */
export type EstimatePreferences = {

  /** Estimate data from local storage. */
  readonly savedEstimate: EstimateLocalStorage;

  /** Estimate calculator preferences. */
  readonly preferences: EstimateCalculatorPreferences | null;
};

/** General interface for a service working with an estimate and its modules. */
export type EstimateService = {

  /** Estimate type. */
  readonly type: EstimateType;

  /** Estimate view type. */
  readonly estimateViewType$: Observable<ViewType>;

  /** Key for storage. */
  readonly storageKey: string;

  /** Estimate. */
  readonly estimate$: Observable<Estimate>;

  /** Estimate ID. */
  readonly estimateId$: Observable<Estimate['id']>;

  /** Estimate preferences. */
  readonly estimatePreferences$: Observable<EstimatePreferences>;

  /** Estimate settings from local storage stream. */
  readonly estimateSettings$: Observable<EstimateSettingsLocalStorage>;

  /** Modules and terms properties. */
  readonly properties$: Observable<{

    /** Modules properties. */
    readonly modules: Record<BaseModulesTypes, ModuleProperties>;

    /** Term properties. */
    readonly terms: readonly TermProperties[];
  }>;

  /** Estimate URLs. */
  readonly urls$: Observable<EstimateUrls>;

  /** Terms. */
  readonly terms$: Observable<TermSummary[]>;

  /** Is saving in process. */
  readonly isSaving$: Observable<boolean>;

  /** Whether an estimate is loading or not. */
  readonly isLoading$: Observable<boolean>;

  /**
   * Update estimate overrides.
   * @param moduleOverrides Updated estimate overrides.
   */
  updateOverrides(moduleOverrides: ModulesOverrides): Observable<void>;

  /**
   * Update estimate preferences.
   * @param preferences Estimate calculator preferences.
   */
  updateEstimatePreferences(preferences: EstimateCalculatorPreferences): Observable<void>;

  /** Refresh estimate. */
  refreshEstimate(): void;

  /** Refresh estimate. */
  refreshTerms(): void;

  /**
   * Change estimate view type.
   * @param estimateViewType Estimate view type.
   */
  changeEstimateViewType(estimateViewType: ViewType): void;
};

const ESTIMATE_SERVICE_TOKEN = new InjectionToken<EstimateService>('estimateService');

/** Inject estimate service to a component. */
export function injectEstimateService<T extends EstimateService = EstimateService>(): T {
  return inject<T>(ESTIMATE_SERVICE_TOKEN);
}

/**
 * Provide estimate service.
 * @param service Estimate service.
 */
export function provideEstimateService(service: Type<EstimateService>): Provider {
  return {
    provide: ESTIMATE_SERVICE_TOKEN,
    useClass: service,
  };
}
