import { ValidationErrors } from '@angular/forms';

import {
  ValidationErrorCode, MatchErrorData, LengthErrorData,
  AppErrorData, MinValueErrorData, MaxValueErrorData,
  ValuesRangeErrorData, GreaterValueErrorData,
} from '@dartsales/common/core/models/errors/validation-error-code';

const DEFAULT_ERROR_MESSAGE = 'Value is not valid';

const VALIDATION_ERROR_MESSAGE_FACTORIES = {
  [ValidationErrorCode.Email]: () => 'Email is not valid',
  [ValidationErrorCode.Required]: () => 'This field is required',
  [ValidationErrorCode.Match]: ({ controlTitle }: MatchErrorData) => `Value does not match with "${controlTitle}"`,
  [ValidationErrorCode.MinLength]: ({ requiredLength }: LengthErrorData) => `Minimal length is ${requiredLength}`,
  [ValidationErrorCode.MaxLength]: ({ requiredLength }: LengthErrorData) => `Maximum length is ${requiredLength} characters`,
  [ValidationErrorCode.Pattern]: () => 'Value does not satisfy the pattern',
  [ValidationErrorCode.AppError]: ({ message }: AppErrorData) => message,
  [ValidationErrorCode.Min]: ({ min }: MinValueErrorData) => `Minimum value is ${min}`,
  [ValidationErrorCode.Max]: ({ max }: MaxValueErrorData) => `Maximum value is ${max}`,
  [ValidationErrorCode.Greater]: ({ controlTitle }: GreaterValueErrorData) => `The value should be greater than ${controlTitle}`,
  [ValidationErrorCode.PasswordPattern]: () => 'Password must be at least 1 letter, 1 capital letter, 1 number and 1 symbol',
  [ValidationErrorCode.ValuesRangeError]: ({ message }: ValuesRangeErrorData) => message,
};

/**
 *  Get error message for specific validation error.
 * @param errors Validation errors.
 */
export function getErrorMessage(errors: ValidationErrors): string {
  /** Error code (minlength, required and etc.). */
  const errorCode = Object.keys(errors)[0] as ValidationErrorCode;

  /** Data of error. See details of HTTP validation errors or implementation of custom. */
  const errorData = errors[errorCode];

  const factory = VALIDATION_ERROR_MESSAGE_FACTORIES[errorCode];
  if (factory == null) {
    return DEFAULT_ERROR_MESSAGE;
  }
  return factory(errorData);
}
