import { map, Observable } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system';
import { BatchEditPointsListSystem } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system';
import { PointsListSystemApiService } from '@dartsales/common/core/services/api/points-list/points-list-system-api.service';

import { PointsListAbstractCommand } from '../points-list-abstract-command';

/** Update points list systems command. */
export class UpdateSystemsCommand extends PointsListAbstractCommand<UpdateSystemsCommandInitArgs> {
  private readonly pointsListSystemApiService = this.injector.get(PointsListSystemApiService);

  /** @inheritdoc */
  public override execute(): Observable<void> {
    return this.updateSystems(this.args.newValue);
  }

  /** @inheritdoc */
  public override undo(): Observable<void> {
    const value = this.args.oldValue.map(system => new BatchEditPointsListSystem({
      id: system.id,
      value: system,
    }));

    return this.updateSystems(value);
  }

  private updateSystems(updatedItems: readonly BatchEditPointsListSystem[]): Observable<void> {
    return this.pointsListSystemApiService.updateSystems(this.args.estimateId, updatedItems).pipe(
      map(() => undefined),
    );
  }
}

type UpdateSystemsCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Old value. */
  readonly oldValue: readonly PointsListSystem[];

  /** New value. */
  readonly newValue: readonly BatchEditPointsListSystem[];
};
