import { Injectable, inject } from '@angular/core';

import { BaseModulesList, ModulesList } from '@dartsales/common/core/models/estimate/modules-list';

import { IMapperFromDto, IMapperToDto } from '../mappers';
import { EditModulesListDto, ModulesListDto } from '../dto/estimate/modules-list.dto';
import { FinalModulesListDto } from '../dto/estimate/final-estimate/final-estimate.dto';

import { CustomModuleMapper } from './modules/custom/custom-module.mapper';
import { LaborModuleMapper } from './modules/labor/labor-module.mapper';
import { MaterialModuleMapper } from './modules/material/material-module.mapper';
import { ExpensesModuleMapper } from './modules/expenses/expenses-module.mapper';
import { PointsListModuleMapper } from './modules/points-list/points-list-module.mapper';
import { SubcontractorModuleMapper } from './modules/subcontractor/subcontractor-module.mapper';

/** Modules list mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModulesListMapper implements
  IMapperFromDto<ModulesListDto, ModulesList>,
  IMapperToDto<EditModulesListDto, ModulesList> {

  private readonly subcontractorModuleMapper = inject(SubcontractorModuleMapper);

  private readonly laborModuleMapper = inject(LaborModuleMapper);

  private readonly materialModuleMapper = inject(MaterialModuleMapper);

  private readonly expensesModuleMapper = inject(ExpensesModuleMapper);

  private readonly customModuleMapper = inject(CustomModuleMapper);

  private readonly pointsListModuleMapper = inject(PointsListModuleMapper);

  /** @inheritdoc */
  public fromDto(dto: ModulesListDto): ModulesList {
    return {
      subcontractor: this.subcontractorModuleMapper.fromDto(dto.subcontractorModule),
      labor: this.laborModuleMapper.fromDto(dto.laborModule),
      expenses: this.expensesModuleMapper.fromDto(dto.perDiemModule),
      custom: this.customModuleMapper.fromDto(dto.customModule),
      material: this.materialModuleMapper.fromDto(dto.materialModule),
      pointsList: this.pointsListModuleMapper.fromDto(dto.pointListModule.properties),
    };
  }

  /** @inheritdoc */
  public fromBaseModulesDto(dto: ModulesListDto): BaseModulesList {
    return {
      subcontractor: this.subcontractorModuleMapper.fromDto(dto.subcontractorModule),
      labor: this.laborModuleMapper.fromDto(dto.laborModule),
      expenses: this.expensesModuleMapper.fromDto(dto.perDiemModule),
      custom: this.customModuleMapper.fromDto(dto.customModule),
      material: this.materialModuleMapper.fromDto(dto.materialModule),
    };
  }

  /** @inheritdoc */
  public toDto(data: ModulesList): EditModulesListDto {
    return {
      subcontractorModule: this.subcontractorModuleMapper.toDto(data.subcontractor),
      perDiemModule: this.expensesModuleMapper.toDto(data.expenses),
      customModule: this.customModuleMapper.toDto(data.custom),
      laborModule: this.laborModuleMapper.toDto(data.labor),
      materialModule: this.materialModuleMapper.toDto(data.material),
      pointListModule: {
        properties: this.pointsListModuleMapper.toDto(data.pointsList),
      },
    };
  }

  /**
   * Map to final module list.
   * @param dto Final module list dto.
   */
  public mapFromFinalModuleListDto(dto: FinalModulesListDto): ModulesList {
    return {
      subcontractor: this.subcontractorModuleMapper.fromDto(dto.subcontractorModule),
      labor: this.laborModuleMapper.fromDto(dto.laborModule),
      expenses: this.expensesModuleMapper.fromDto(dto.perDiemModule),
      custom: this.customModuleMapper.fromDto(dto.customModule),
      material: this.materialModuleMapper.fromDto(dto.materialModule),
      pointsList: this.pointsListModuleMapper.fromDto(dto.pointListModule.properties),
    };
  }
}
