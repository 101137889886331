import { Injectable, inject } from '@angular/core';

import { SubcontractorLumpSumPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/lump-sum-pricing/lump-sum-pricing';
import { CreateSubcontractorLumpSumPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/lump-sum-pricing/create-lump-sum-pricing';
import { EditSubcontractorLumpSumPricing, BatchEditSubcontractorLumpSumPricing } from '@dartsales/common/core/models/estimate/modules/subcontractor/lump-sum-pricing/edit-lump-sum-pricing';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../../../mappers';
import { BatchEditLockablePercentsStatusDto, BatchEditSubcontractorLumpSumPricingDto, CreateSubcontractorLumpSumPricingDto, EditSubcontractorLumpSumPricingDto, SubcontractorLumpSumPricingDto } from '../../../dto/estimate/modules/subcontractor/subcontractor-lump-sum-pricing.dto';
import { SubcontractorInfoMapper } from '../../../subcontractor-info.mapper';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';

/** Subcontractor lump sum pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubcontractorLumpSumPricingMapper implements IMapper<SubcontractorLumpSumPricingDto, SubcontractorLumpSumPricing> {

  private readonly subcontractorInfoMapper = inject(SubcontractorInfoMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: SubcontractorLumpSumPricingDto): SubcontractorLumpSumPricing {
    return new SubcontractorLumpSumPricing({
      ...this.basePricingMapper.fromDto(dto),
      contractorInfo: this.subcontractorInfoMapper.fromDto(dto),
      id: dto.id,
      isChecked: dto.isChecked,
      parentId: dto.estimateId,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
    });
  }

  /**
   * Map to create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreateSubcontractorLumpSumPricing): CreateSubcontractorLumpSumPricingDto {
    return {
      ...this.basePricingMapper.toDto(data),
      ...this.subcontractorInfoMapper.toCreateDto(data.contractorInfo),
      isChecked: data.isChecked,
    };
  }

  /**
   * Map to edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditSubcontractorLumpSumPricing): EditSubcontractorLumpSumPricingDto {
    return {
      ...this.basePricingMapper.toDto(data),
      ...this.subcontractorInfoMapper.toDto(data.contractorInfo),
      isChecked: data.isChecked,
    };
  }

  /**
   * Map to batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: BatchEditSubcontractorLumpSumPricing): BatchEditSubcontractorLumpSumPricingDto {
    return {
      lumpSumPricingId: data.id,
      lumpSumPricing: this.toEditDto(data.value),
    };
  }

  /** @inheritdoc */
  public toDto(data: SubcontractorLumpSumPricing): SubcontractorLumpSumPricingDto {
    return {
      ...this.basePricingMapper.toDto(data),
      ...this.subcontractorInfoMapper.toDto(data.contractorInfo),
      isChecked: data.isChecked,
      id: data.id,
      estimateId: data.parentId,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Subcontractor module pricing ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: string,
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      lumpSumPricings: [
        {
          subcontractorId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
