import { Injectable } from '@angular/core';

import { Overridable } from '../../utils/types/overridable';

import { OverridableDto } from './dto/overridable.dto';
import { IMapper, IMapperFromDto, IMapperToDto } from './mappers';

/** Overridable mapper. */
@Injectable({
  providedIn: 'root',
})
export class OverridableMapper implements
  IMapper<OverridableDto<unknown>, Overridable<unknown>> {

  /** @inheritdoc */
  public fromDto<T>(dto: OverridableDto<T>): Overridable<T> {
    return new Overridable({
      initial: dto.value,
      override: dto.override,
    });
  }

  /**
   * Maps complex object Overridable (like AmountPercent) from DTO to Domain model.
   * @param dto DTO.
   * @param valueMapper Native mapper for complex object.
   * @returns Model.
   */
  public fromObjectDto<TModel, TDto>(dto: OverridableDto<TDto>, valueMapper: IMapperFromDto<TDto, TModel>): Overridable<TModel> {
    return new Overridable({
      initial: valueMapper.fromDto(dto.value),
      override: dto.override !== null ? valueMapper.fromDto(dto.override) : null,
    });
  }

  /** @inheritdoc */
  public toDto<T>(data: Overridable<T>): OverridableDto<T> {
    return {
      value: data.initial,
      override: data.override,
    };
  }

  /**
   * Maps complex object Overridable (like AmountPercent) from Domain model to DTO.
   * @param data Model.
   * @param valueMapper Native mapper for complex object.
   * @returns DTO.
   */
  public toObjectDto<TModel, TDto>(data: Overridable<TModel>, valueMapper: IMapperToDto<TDto, TModel>): OverridableDto<TDto> {
    return {
      value: valueMapper.toDto(data.initial),
      override: data.override ? valueMapper.toDto(data.override) : null,
    };
  }
}
