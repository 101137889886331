import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { StrictOmit } from '../../utils/types/strict-omit';
import { buildHttpParams } from '../../utils/build-http-params';

import { IMapperToDto } from './mappers';
import { PaginationDataMapper } from './pagination-data.mapper';
import { SortMapper } from './sort.mapper';
import { QueryFilterParamsDto } from './dto/query-filter-params.dto';
import { MultiConditionFilterDto } from './dto/filters/multi-condition-filter.dto';

type QueryParamsFetchListOptions = StrictOmit<FetchListOptions, 'filter'>;

/** Mapper for QueryFilter params. */
@Injectable({ providedIn: 'root' })
export class QueryFilterParamsMapper implements IMapperToDto<HttpParams, MultiConditionFilterDto[]> {
  public constructor(
    private readonly paginationMapper: PaginationDataMapper,
    private readonly sortMapper: SortMapper,
  ) { }

  /**
   * Map filter fields to request params.
   * @param options Query params options.
   * @param fields Filter fields.
   */
  public toDtoWithPaginationParams(
    options: QueryParamsFetchListOptions,
    fields: readonly MultiConditionFilterDto[] = [],
  ): QueryFilterParamsDto {
    const paginationDto = this.paginationMapper.toDto(options.pagination);
    return {
      'QueryFilter.OrderBy': this.sortMapper.toDto(options.sort)?.OrderBy ?? '',
      'QueryFilter.Page': paginationDto?.Page ?? 1,
      'QueryFilter.PageSize': paginationDto?.PageSize ?? 10,
      'Fields': fields.map(field => this.createFieldParams(field)),
    };
  }

  /** @inheritDoc */
  public toDto(fields: readonly MultiConditionFilterDto[] = []): HttpParams {
    const filter = {
      Fields: fields.map(field => this.createFieldParams(field)),
    };

    return buildHttpParams(filter);
  }

  private createFieldParams(field: MultiConditionFilterDto): string {
    return [field.propertyName, field.operatorType, ...field.values].join('|');
  }
}
