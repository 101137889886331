import { AbstractMaterialPricing, AbstractMaterialPricingInitArgs } from './abstract-material-pricing';

/** Material pricing without override properties. */
export class MaterialPricingNoOverride extends AbstractMaterialPricing {

  /** Quantity. */
  public readonly quantity: number;

  public constructor(data: MaterialPricingNoOverrideInitArgs) {
    super(data);
    this.quantity = data.quantity;
  }
}

type MaterialPricingNoOverrideInitArgs = Pick<MaterialPricingNoOverride, 'quantity'> & AbstractMaterialPricingInitArgs;
