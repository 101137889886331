import { FinalEstimateTermContainer } from './final-estimate-term';

/** Final estimate services submodules aggregation. */
export class FinalEstimateServiceByModules {
  /** Subcontractor module summary. */
  public readonly subcontractor: FinalEstimateServiceModuleSummary;

  /** Expenses module summary. */
  public readonly expenses: FinalEstimateServiceModuleSummary;

  /** Custom module term summary. */
  public readonly custom: FinalEstimateServiceModuleSummary;

  /** Labor module summary. */
  public readonly labor: FinalEstimateServiceModuleSummary;

  /** Material module summary. */
  public readonly material: FinalEstimateServiceModuleSummary;

  /** Grand total of all modules. */
  public readonly grandTotals: FinalEstimateServiceModuleSummary;

  public constructor(data: FinalEstimateServiceByModulesInitArgs) {
    this.subcontractor = data.subcontractor;
    this.expenses = data.expenses;
    this.custom = data.custom;
    this.labor = data.labor;
    this.material = data.material;
    this.grandTotals = data.grandTotals;
  }
}

/** Final estimate service item contained by kind of module. */
export class FinalEstimateServiceModuleItem extends FinalEstimateTermContainer {

  /** Item name. */
  public readonly name: string;

  /** Child items. */
  public readonly childItems: readonly FinalEstimateServiceModuleItem[];

  public constructor(data: FinalEstimateServiceModuleItemInitArgs) {
    super(data);
    this.name = data.name;
    this.childItems = data.childItems;
  }
}

/** Final estimate services submodule summary. */
export class FinalEstimateServiceModuleSummary extends FinalEstimateTermContainer {

  /** List of module items. */
  public readonly items: readonly FinalEstimateServiceModuleItem[];

  public constructor(data: FinalEstimateServiceModuleSummaryInitArgs) {
    super(data);
    this.items = data.items;
  }
}

type FinalEstimateServiceByModulesInitArgs = FinalEstimateServiceByModules;
type FinalEstimateServiceModuleItemInitArgs = FinalEstimateServiceModuleItem;
type FinalEstimateServiceModuleSummaryInitArgs = FinalEstimateServiceModuleSummary;
