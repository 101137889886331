import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';

import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { matFormFieldControlProviderFor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';

import { AbstractOverridableMatFormField } from '../abstract-overridable-mat-form-field';

/** Overridable text input. */
@Component({
  selector: 'dartsalesw-overridable-text-input',
  templateUrl: './overridable-text-input.component.html',
  styleUrls: ['./overridable-text-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => OverridableTextInputComponent)],
})
export class OverridableTextInputComponent extends AbstractOverridableMatFormField<string> implements OnInit {

  /** If `true` then text input will use <textarea> element. It allows to enter large text. */
  @Input()
  public useTextarea = false;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  /** Form control. */
  protected readonly formControl = this.fb.control<string>('');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: Overridable<string> | null): void {
    this.formControl.patchValue(value?.combinedValue ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  private subscribeToControlChanges(): void {
    this.formControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(value => {
        this.updateControl(value);
      });
  }
}
