import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EXPANSION_PANEL_ANIMATION_TIMING, matExpansionAnimations } from '@angular/material/expansion';

const indicator90Rotate = trigger('indicator90Rotate', [
  state('collapsed, void', style({ transform: 'rotate(0deg)' })),
  state('expanded', style({ transform: 'rotate(90deg)' })),
  transition('expanded <=> collapsed, void => collapsed',
    animate(EXPANSION_PANEL_ANIMATION_TIMING)),
]);

/** Vertical expand button component. */
@Component({
  selector: 'dartsalesw-vertical-expand-button',
  templateUrl: './vertical-expand-button.component.html',
  styleUrls: ['./vertical-expand-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    matExpansionAnimations.indicatorRotate,
    indicator90Rotate,
  ],
})
export class VerticalExpandButtonComponent {

  /** Whether rotation is 90 degrees instead of 180. */
  @Input()
  public isHalfRotation = false;

  /** Is expanded. */
  @Input()
  public isExpanded = false;

  /** Button click. */
  @Output()
  public readonly buttonClick = new EventEmitter<void>();
}
