import { inject, Injectable } from '@angular/core';

import { CreateTemplate } from '@dartsales/common/core/models/project/create-template';

import { CreateTemplateDto } from '../dto/project/create-template.dto';

import { ProjectMapper } from './project.mapper';

/** Template mapper. */
@Injectable({
  providedIn: 'root',
})
export class TemplateMapper {

  private readonly projectMapper = inject(ProjectMapper);

  /** @inheritdoc */
  public toCreateDto(data: CreateTemplate): CreateTemplateDto {
    return {
      ...this.projectMapper.toCreateDto(data),
      templateDescription: data.templateDescription,
    };
  }
}
