<span class="header-units-label ellipsis">
  {{ select.triggerValue }}
</span>
<mat-select
  #select
  #trigger="matMenuTrigger"
  class="header-units-select"
  panelClass="units-select-panel"
  [matMenuTriggerFor]="menu"
  [title]="tooltip$ | async"
  [formControl]="rateTypeControl"
  [compareWith]="compareFunction"
  (openedChange)="$event ? trigger.openMenu() : trigger.closeMenu()"
  (selectionChange)="rateTypeChange.emit($event.value)"
>
  <mat-menu
    #menu
    (closed)="select.close()"
  >
    <button
      *ngFor="let role of laborRateTypeOptions$ | async; trackBy: trackByIndex"
      mat-menu-item
      type="button"
      class="labor-role-type-option"
      [title]="role.label"
      [matMenuTriggerFor]="subMenu"
    >
      <mat-menu #subMenu>
        <div
          *ngFor="let taskRate of role.tasksRateOptions; trackBy: trackByItem"
          class="labor-role-type-option labor-role-type-option_nested"
        >
          <button
            mat-menu-item
            type="button"
            class="labor-role-type-option"
            [title]="taskRate.label"
          >
            <mat-option [value]="taskRate.value">
              {{ taskRate.label }}
            </mat-option>
          </button>
        </div>
      </mat-menu>
      {{ role.label }}
    </button>
  </mat-menu>
</mat-select>
<ng-container *ngIf="isBulkUpdateAvailable$ | async">
  <dartsalesc-table-cell-bulk-update
    *ngIf="(taskRate$ | async)?.roleRate as roleRate"
    tooltipText="Labor role rates update is available. Click here to apply changes."
    [isUpdateConfirmed]="roleRate.rates.isConfirmed"
    (applyUpdateClick)="bulkUpdateSave.emit(true)"
    (discardUpdateClick)="bulkUpdateSave.emit(false)"
  >
    <ng-template #removeMessage>
      <p>Labor role was removed. Do you want to apply this change?</p>
    </ng-template>

    <ng-container *ngIf="roleRate.rates.override">
      <dartsalesw-string-value-changes
        *ngIf="roleRate.rates.initial.rate !== roleRate.rates.override.rate"
        [label]="laborRateType.toReadable(laborRateType.Regular)"
        [currentValue]="roleRate.rates.initial.rate | inputMask: currencyMask"
        [previousValue]="roleRate.rates.override.rate | inputMask: currencyMask"
      />
      <dartsalesw-string-value-changes
        *ngIf="
          roleRate.rates.initial.rateOvertimeOne !==
          roleRate.rates.override.rateOvertimeOne
        "
        [label]="laborRateType.toReadable(laborRateType.OvertimeOne)"
        [currentValue]="
          roleRate.rates.initial.rateOvertimeOne | inputMask: currencyMask
        "
        [previousValue]="
          roleRate.rates.override.rateOvertimeOne | inputMask: currencyMask
        "
      />
      <dartsalesw-string-value-changes
        *ngIf="
          roleRate.rates.initial.rateOvertimeTwo !==
          roleRate.rates.override.rateOvertimeTwo
        "
        [label]="laborRateType.toReadable(laborRateType.OvertimeTwo)"
        [currentValue]="
          roleRate.rates.initial.rateOvertimeTwo | inputMask: currencyMask
        "
        [previousValue]="
          roleRate.rates.override.rateOvertimeTwo | inputMask: currencyMask
        "
      />
    </ng-container>
  </dartsalesc-table-cell-bulk-update>
</ng-container>
