<div class="error-card">
  <div class="error-card-logo">
    <img
      class="error-card-logo__image"
      src="/assets/images/division25-logo.svg"
      alt="Logo"
    />
  </div>
  <h1 class="error-card__header">Access Denied!</h1>
  <p class="error-card__body">
    You have insufficient permissions to access the Div 25 Sales & Estimating
    application.
  </p>

  <footer class="error-card__footer">
    <button
      mat-flat-button
      class="error-card__button"
      color="primary"
      type="button"
      (click)="onLogoutClick()"
    >
      Logout
    </button>
  </footer>
</div>
