import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ContentChild, EmbeddedViewRef, EventEmitter, inject, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';

/** Draggable item wrapper component. */
@Component({
  selector: 'dartsalesw-draggable-item-wrapper',
  templateUrl: './draggable-item-wrapper.component.html',
  styleUrls: ['./draggable-item-wrapper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableItemWrapperComponent implements OnDestroy {
  /** Drag payload type. */
  @Input({ required: true })
  public dragPayloadType = '';

  // TODO (Danil K.): We can make 'dragPayload' strongly typed. See PointsListDragPayload for details.
  /** Drag payload. Payload value should be serializable. */
  @Input({ required: true })
  public dragPayload: unknown = {};

  /** Is drag and drop disabled. */
  @Input()
  public isDisabled = false;

  /** Selected items count. */
  @Input()
  public selectedItemsCount = 0;

  /** Drag started event emitter. */
  @Output()
  public readonly dragStarted = new EventEmitter<DragEvent>();

  /** Drag end event emitter. */
  @Output()
  public readonly dragEnded = new EventEmitter<DragEvent>();

  /** Single item drag template. */
  @ContentChild('singleItemDragTemplate')
  protected readonly singleItemDragTemplate?: TemplateRef<unknown>;

  /** Multiple items drag template. */
  @ContentChild('multipleItemsDragTemplate')
  protected readonly multipleItemsDragTemplate?: TemplateRef<unknown>;

  @ViewChild('dragPreview')
  private readonly dragPreviewTemplate?: TemplateRef<unknown>;

  private readonly document = inject(DOCUMENT);

  private dndPreviewElement?: EmbeddedViewRef<unknown>;

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.hideDragPreview();
  }

  /**
   * Handle drag start.
   * @param dragEvent Drag event.
   */
  protected onDragStart(dragEvent: DragEvent): void {
    this.showDragPreview(dragEvent);
    this.dragStarted.emit(dragEvent);
  }

  /**
   * Handle drag end.
   * @param dragEvent Drag event.
   */
  protected onDragEnd(dragEvent: DragEvent): void {
    this.hideDragPreview();
    this.dragEnded.emit(dragEvent);
  }

  private showDragPreview(dragEvent: DragEvent): void {
    if (this.dragPreviewTemplate == null) {
      return;
    }

    this.dndPreviewElement = this.dragPreviewTemplate.createEmbeddedView(null);
    this.dndPreviewElement.detectChanges();
    const preview = this.dndPreviewElement.rootNodes[0] as HTMLElement;
    this.document.body.appendChild(preview);
    dragEvent.dataTransfer?.setDragImage(preview, 0, 0);
  }

  private hideDragPreview(): void {
    const element = this.dndPreviewElement?.rootNodes[0] as HTMLElement | null;
    element?.remove();
    this.dndPreviewElement?.destroy();
  }
}
