<dartsalesw-base-filters
  [isLoading]="isLoading$ | async"
  [isIndicatorVisible]="isActive"
>
  <dartsalesw-sort-section
    [isActive]="isActive"
    [sortDirection]="sortDirection ?? sortDirections.ASC"
    (sortDirectionChange)="onSortDirectionChange($event)"
  />
  <dartsalesw-checkboxes-section
    class="checkboxes-section"
    [options]="(options$ | async) ?? []"
    [activeOptionsValues]="activeSuppliers"
    (searchChange)="onOptionsSearchChange($event)"
    (selectedOptionsChange)="onSelectedOptionsChange($event)"
  />
</dartsalesw-base-filters>
