import { NonNullableFormBuilder } from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';

import { CURRENCY_MASK } from '@dartsales/common/core/utils/constants';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { ValuesRange } from '@dartsales/common/core/models/values-range';
import { ControlsOf } from '@dartsales/common/core/utils/types/form';
import { AppValidators } from '@dartsales/common/core/utils/validators';
import { PalettePartFilters } from '@dartsales/common/core/models/filters/palette-part-filters';

import { AmountInputHelpers } from '../../inputs/abstract-input';

/** Range filter. */
@Component({
  selector: 'dartsalesw-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeFilterComponent implements OnInit {

  /** Current sort direction. */
  @Input()
  public sortDirection: SortDirection | null = SortDirection.ASC;

  /** Maxim value of filter. */
  @Input()
  public set maxValue(value: number) {
    this.maxValue$.next(value);
    this.patchFilter({ end: value });
  }

  /** Active range filter. */
  @Input()
  public set activeRangeFilter(value: PalettePartFilters['unitCost']) {
    this.updateForm(value);
  }

  /** Range filter change. */
  @Output()
  public readonly changeFilter = new EventEmitter<ValuesRange<number>>();

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Default minimum value of filter. */
  protected readonly defaultMinValue = 0;

  /** Default step of range filter. */
  protected readonly defaultStep = 0.01;

  private readonly staticMask: Inputmask.Options = {
    ...CURRENCY_MASK,
    allowMinus: false,
  };

  /** Maximum value of filter. */
  protected readonly maxValue$ = new BehaviorSubject(0);

  /** Mask options. */
  protected readonly currencyMask$ = this.createCurrencyMaskStream();

  /** Range filter form. */
  protected readonly form = this.fb.group<ControlsOf<ValuesRange<number>>>({
    start: this.fb.control(this.defaultMinValue),
    end: this.fb.control(this.defaultMinValue),
  }, { validators: AppValidators.minMaxValidator() });

  /** Sort direction. */
  protected readonly sortDirections = SortDirection;

  /** Whether sort field is active. */
  protected get isActive(): boolean {
    return this.sortDirection != null;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToFormChange();
  }

  /**
   * Sort direction change handler.
   * @param direction Sort direction.
   */
  protected onSortDirectionChange(direction: SortDirection): void {
    this.sortDirectionChange.emit(direction);
  }

  /**
   * Handle change min input.
   * @param event Event.
   */
  protected onChangeMinInput(event: Event): void {
    this.patchFilter({
      start: this.valueFromInput(event),
    }, true);
  }

  /**
   * Handle change max input.
   * @param event Event.
   */
  protected onChangeMaxInput(event: Event): void {
    this.patchFilter({
      end: this.valueFromInput(event),
    }, true);
  }

  private onFinishChangeFilter(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.changeFilter.emit({
      start: this.form.value.start ?? 0,
      end: this.form.value.end ?? 0,
    });
  }

  private patchFilter(filter: Partial<ValuesRange<number>>, emitChanges = false): void {
    this.form.patchValue({
      ...this.form.value,
      ...filter,
    }, { emitEvent: emitChanges });
  }

  private createCurrencyMaskStream(): Observable<Inputmask.Options> {
    return this.maxValue$.pipe(
      map(maxValue => ({
        ...this.staticMask,
        min: this.defaultMinValue,
        max: maxValue,
      })),
    );
  }

  private subscribeToFormChange(): void {
    this.form.valueChanges.pipe(
      tap(() => {
        this.onFinishChangeFilter();
      }),
    )
      .subscribe();
  }

  private updateForm(value: PalettePartFilters['unitCost']): void {
    if (value == null) {
      this.patchFilter({
        start: this.defaultMinValue,
        end: this.maxValue$.value,
      });
      return;
    }

    this.patchFilter(value);
  }

  private valueFromInput(event: Event): number {
    const eventTarget = event.target as HTMLInputElement | null;
    const value = eventTarget?.value ?? '';

    return AmountInputHelpers.numberFromInput(value, this.staticMask) ?? 0;
  }
}
