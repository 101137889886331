import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AMOUNT_ROUND_FRACTION_DIGITS, CURRENCY_MASK } from '@dartsales/common/core/utils/constants';
import { roundToFixed } from '@dartsales/common/core/utils/rounds';
import { parseMaskedNumber } from '@dartsales/common/core/utils/parse-masked-number';

import { TableCellFormControl } from '../table-cell-form-control.component';
import { TableCellPrefixDirective } from '../../directives/table-cell-prefix.directive';

/**
 * Masked number table cell (cell with number value with specific mask).
 * Uses currency mask by default.
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-masked-number-table-cell',
  templateUrl: './masked-number-table-cell.component.html',
  styleUrls: ['./masked-number-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MaskedNumberTableCellComponent extends TableCellFormControl<number> implements OnInit {
  /** Placeholder. */
  @Input()
  public placeholder = '0';

  /** Input mask, currency mask is used by default. */
  @Input()
  public mask = CURRENCY_MASK;

  /**
   * Whether value in view mode should be hidden.
   * Suffix will be also hidden in view mode.
   */
  @Input()
  public hideValue = false;

  /** Show initial form control value as placeholder. */
  @Input()
  public showInitialValueAsPlaceholder = false;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Number form control. */
  protected readonly maskedNumberControl = this.fb.control<string>('');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    this.maskedNumberControl.setValue(value?.toString() ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.maskedNumberControl.disable();
    } else {
      this.maskedNumberControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  private subscribeToControlChanges(): void {

    // The reason we don't use 'listenControlChanges' is described in TextTableCellComponent
    this.maskedNumberControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        const oldValue = roundToFixed(this.controlValue ?? 0, AMOUNT_ROUND_FRACTION_DIGITS);
        const newNumberValue = roundToFixed(parseMaskedNumber(value, this.mask), AMOUNT_ROUND_FRACTION_DIGITS);
        if (oldValue !== newNumberValue) {
          this.controlValue = newNumberValue;
        }
      });
  }
}
