import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { Margin } from '../estimate/modules/margin';
import { DeepPartial } from '../../utils/types/deep-partial';
import { DomainCostCalculator } from '../calculators/domain/domain-cost-calculator';
import { ClassProperties } from '../../utils/types/class-properties';

// TODO (Pavel Z.) check all inherited classed for possibility to replace it for BasePricing.
/** Base pricing. */
export class BasePricing {

  /** Direct cost. */
  public readonly directCost: number;

  /** Contingency. */
  public readonly contingency: AmountPercent;

  /** Escalation. */
  public readonly escalation: AmountPercent;

  // TODO (Pavel Z.) We can remove markup this separate property.
  /**
   * Markup.
   * @deprecated
   **/
  public readonly markup: AmountPercent;

  /** Gross margin with markup. */
  public readonly margin: Margin;

  /** Sell price. */
  public readonly sellPrice: number;

  /**
   * DC info.
   * @param percent Ratio percentage of pricing direct cost to parent direct cost.
   * @param amount Total direct cost.
   */
  public readonly dc: AmountPercent | null;

  /**
   * TDC info.
   * @param percent Ratio percentage of pricing direct cost to estimate direct cost.
   * @param amount Total direct cost.
   */
  public readonly tdc: AmountPercent | null;

  public constructor(initArgs: BasePricingInitArgs) {
    this.directCost = initArgs.directCost;
    this.contingency = initArgs.contingency;
    this.margin = initArgs.margin;
    this.markup = initArgs.margin.markup;
    this.sellPrice = initArgs.sellPrice;
    this.escalation = initArgs.escalation;
    this.dc = initArgs.dc ?? null;
    this.tdc = initArgs.tdc ?? null;
  }

  /**
   * Total direct cost, according to specification
   * https://docs.google.com/spreadsheets/d/1VzxlBUTNTsKc2SRX-DNj0MGar7zejjTqUkTlMQooJso/edit#gid=241181340.
   */
  public getTotalDirectCost(): number {
    return DomainCostCalculator.calculateTotalDirectCost({
      directCost: this.directCost,
      contingencyAmount: this.contingency.amount,
      escalationAmount: this.escalation.amount,
    });
  }
}

/** Base pricing init args. */
export type BasePricingInitArgs = ClassProperties<StrictOmit<BasePricing,
  | 'markup'
  | 'dc'
  | 'tdc'
>
> & Partial<Pick<BasePricing,
  | 'markup'
  | 'dc'
  | 'tdc'
>>;

/** Set of basic amounts for BasePricing calculation. */
export type BasePricingAmounts = {

  /** Direct cost. */
  readonly directCost: number;

  /** Contingency amount. */
  readonly contingencyAmount: number;

  /** Margin amount. */
  readonly marginAmount: number;

  /** Escalation amount. */
  readonly escalationAmount: number;
};

// TODO (Pavel Z.) Needs rework solution to unified approach to work with BasePricing.
/**
 * A temporary solution for objects that use `BasicPricing` as a composition, such as `ExpensesSummaryItem`.
 * The project needs to be reworked to use a unified approach, either inheritance everywhere or composition everywhere.
 **/
export type BasePricingComposition = {

  /** Pricing. */
  readonly pricing: BasePricing;
};

/**
 * Type guard for `BasePricingComposition` instances.
 * @param item Item for check.
 **/
export function isBasePricingComposition(item: BasePricing | BasePricingComposition): item is BasePricingComposition {
  return (item as BasePricingComposition).pricing !== undefined;
}

/**
 * Partial base price used for calculations.
 * It's used when it is necessary to redefine only part of the initial parameters and re-calculate
 * all calculated values.
 **/
export type CalculationBasePricing = Pick<BasePricing, 'directCost'> & {

  /** Contingency. */
  readonly contingency: Partial<BasePricing['contingency']>;

  /** Margin. */
  readonly margin: DeepPartial<BasePricing['margin']>;

  /** Escalation. */
  readonly escalation: Partial<BasePricing['escalation']>;
};

/** TODO (Pavel Z.) Think about naming and correct placement and how reuse implementation. */
export class BaseUnitPricing extends BasePricing {

  /** Cost for single unit. */
  public readonly unitCost: number;

  public constructor(initArgs: BaseUnitPricingInitArgs) {
    super(initArgs);
    this.unitCost = initArgs.unitCost;
  }
}

type BaseUnitPricingInitArgs = BasePricingInitArgs & Pick<BaseUnitPricing, 'unitCost'>;
