/** Other team member. */
export class OtherTeamMember {

  /** Team member's id. */
  public readonly memberId: number;

  /** Team member's first name. */
  public readonly memberFirstName: string;

  /** Team member's last name. */
  public readonly memberLastName: string;

  /** Team member's email. */
  public readonly memberEmail: string;

  public constructor(data: OtherTeamMemberInitArgs) {
    this.memberId = data.memberId;
    this.memberFirstName = data.memberFirstName;
    this.memberLastName = data.memberLastName;
    this.memberEmail = data.memberEmail;
  }
}

type OtherTeamMemberInitArgs = OtherTeamMember;
