import { Injectable, inject } from '@angular/core';

import { TermProperties } from '@dartsales/common/core/models/estimate-services/term-properties';

import { ModulePropertiesMapper } from '../estimate/modules/module-properties.mapper';
import { TermPropertiesDto } from '../dto/estimate-services/term-properties.dto';

import { TermSubmoduleSummaryMapper } from './term-submodule-summary.mapper';

/** Term properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermPropertiesMapper {

  private readonly modulePropertiesMapper = inject(ModulePropertiesMapper);

  private readonly termSummaryMapper = inject(TermSubmoduleSummaryMapper);

  /** @inheritdoc */
  public fromDto(properties: TermPropertiesDto, order: number): TermProperties {
    const { moduleSummaries } = properties;
    return new TermProperties({
      ...this.modulePropertiesMapper.fromDto(properties),
      submoduleTotals: this.termSummaryMapper.fromDto(moduleSummaries),
      compoundEscalation: {
        effectivePercent: properties.compoundEscalation.effectiveEscalationPercent,
        isEnabled: properties.compoundEscalation.hasEffectiveEscalation,
      },
      order,
    });
  }

  /** @inheritdoc */
  public toDto(properties: TermProperties): TermPropertiesDto {
    const { submoduleTotals: termSummaries } = properties;
    return {
      ...this.modulePropertiesMapper.toDto(properties),
      moduleSummaries: this.termSummaryMapper.toDto(termSummaries),
      compoundEscalation: {
        hasEffectiveEscalation: properties.compoundEscalation.isEnabled,
        effectiveEscalationPercent: properties.compoundEscalation.effectivePercent,
      },
    };
  }
}
