<div class="progress-bar-container">
  <dartsalesc-progress-bar [loading]="loading" />
</div>
<div
  *ngIf="pagination"
  #tableHolder
  infiniteScroll
  dartsalescScrollToTop
  class="table-holder"
  [scrollWindow]="false"
  (scrolled)="paginationChanged(pagination)"
>
  <table
    mat-table
    matSort
    class="base-table"
    [trackBy]="trackBy"
    [dataSource]="dataSource"
    [matSortDirection]="sort?.direction || ''"
    [matSortActive]="sort?.field || ''"
    (matSortChange)="onSortChange($event)"
  >
    <ng-container
      *ngFor="
        let col of columns;
        trackBy: trackByColumnName;
        let isLastColumn = last
      "
      [matColumnDef]="col.name"
      [stickyEnd]="isLastColumn && isStickyLastColumn"
    >
      <ng-container *ngrxLet="getColumnByName(col.name) as column">
        <!-- HEADER FOR THE CELL -->
        <th
          *matHeaderCellDef
          mat-header-cell
          [disabled]="!col.sort"
          [mat-sort-header]="col.sort || ''"
          [class.default-header]="!column?.header?.template"
          [style.width.px]="column?.maxWidth"
        >
          <ng-container
            *ngIf="column; else defaultCellBlock"
            [ngTemplateOutletContext]="{
              $implicit: col,
              headerText: column.headerText
            }"
            [ngTemplateOutlet]="
              column.header?.template || defaultHeaderTemplate
            "
          />
          <!-- If not found column template -->
          <ng-template #defaultCellBlock>
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: col }"
              [ngTemplateOutlet]="defaultHeaderTemplate"
            />
          </ng-template>
        </th>

        <!-- BODY FOR THE CELL -->
        <td
          *matCellDef="let element; let index = index"
          mat-cell
          [style.width.px]="column?.maxWidth"
          [style.maxWidth.px]="column?.maxWidth"
        >
          <!-- If found column template -->
          <ng-container
            *ngIf="column; else elseCellBlock"
            [ngTemplateOutletContext]="{
              $implicit: element,
              col: col.name,
              index: index
            }"
            [ngTemplateOutlet]="column.cell?.template || defaultCellTemplate"
          />
          <!-- If not found column template -->
          <ng-template #elseCellBlock>
            <ng-container
              [ngTemplateOutletContext]="{
                $implicit: element,
                col: col.name,
                index: index
              }"
              [ngTemplateOutlet]="defaultCellTemplate"
            />
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <tr
      *matHeaderRowDef="columnNames; sticky: true"
      mat-header-row
    ></tr>

    <tr
      *matRowDef="let row; columns: columnNames"
      mat-row
      [class.clickable-row]="clickableRows"
      [class.selected-row]="row.id === selectedItemId"
      (click)="onItemClick(row)"
    ></tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td
        *matFooterCellDef
        mat-cell
        class="empty-table-message"
        [attr.colspan]="columns?.length"
      >
        {{ loading ? "Loading..." : emptyMessage }}
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>

  <div
    class="pagination"
    [class.hidden]="dataSource.data.length === 0 || pagination.isLastPage"
  >
    <button
      mat-flat-button
      class="pagination-button"
      color="primary"
      type="button"
      [dartsalescLoading]="loading"
      (click)="paginationChanged(pagination)"
    >
      Load more...
    </button>
  </div>

  <!-- If not found cell template -->
  <ng-template
    #defaultCellTemplate
    let-row
    let-col="col"
  >
    <div
      class="cell-container"
      [title]="row[col] ?? ''"
    >
      {{ getValue(row, col) ?? "" }}
    </div>
  </ng-template>

  <!-- If not found header template -->
  <ng-template
    #defaultHeaderTemplate
    let-col
    let-headerText="headerText"
  >
    {{ getTableHeaderText(col, headerText) }}
  </ng-template>
</div>

<!-- Tables in the app use infinite scroll for pagination but it's also possible to add table pagination here. -->
