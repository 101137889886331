import { inject, Injectable } from '@angular/core';

import { FilterConditionBlock } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-condition-block';
import { FilterHelper } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-helper';

import { FilterConditionBlockDto } from '../dto/filters/filter-condition-block.dto';

import { MultiConditionFilterMapper } from './multi-condition-filter.mapper';

/** Mapper for filter condition block. */
@Injectable({ providedIn: 'root' })
export class FilterConditionBlockMapper<TFilter extends string> {

  private readonly multiConditionFilterMapper = inject<MultiConditionFilterMapper<TFilter>>(MultiConditionFilterMapper);

  /**
   * Map model to dto.
   * @param data Model.
   * @param filterHelper Filter helper class.
   */
  public toDto(data: FilterConditionBlock<TFilter>, filterHelper: FilterHelper<TFilter>): FilterConditionBlockDto<TFilter> {
    return {
      conditionType: data.conditionType,
      conditions: data.conditions.map(condition => this.multiConditionFilterMapper.toDto(condition, filterHelper)),
    };
  }

  /**
   * Map model from dto.
   * @param dto Dto.
   * @param filterHelper Filter helper class.
   */
  public fromDto(dto: FilterConditionBlockDto<TFilter>, filterHelper: FilterHelper<TFilter>): FilterConditionBlock<TFilter> {
    return {
      conditionType: dto.conditionType,
      conditions: dto.conditions.map(condition => this.multiConditionFilterMapper.fromDto(condition, filterHelper)),
    };
  }
}
