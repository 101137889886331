import { StandardMaterialGroup } from './standard-material-group';
import { NonStandardMaterialItem } from './non-standard-material-item';

/** Material module data. */
export class MaterialModuleData {

  /** Default standard materials group. */
  public readonly defaultStandardMaterialsGroup: StandardMaterialGroup;

  /** Non standard materials list. */
  public readonly nonStandardMaterials: readonly NonStandardMaterialItem[];

  /** Standard materials list. */
  public readonly standardMaterialsGroups: readonly StandardMaterialGroup[];

  public constructor(data: MaterialModuleDataInitArgs) {
    this.defaultStandardMaterialsGroup = data.defaultStandardMaterialsGroup;
    this.nonStandardMaterials = data.nonStandardMaterials;
    this.standardMaterialsGroups = data.standardMaterialsGroups;
  }
}

type MaterialModuleDataInitArgs = MaterialModuleData;
