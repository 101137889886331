import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TableCellFormControl } from '../table-cell-form-control.component';

/**
 * Number table cell component.
 * @deprecated Use MaskedNumberTableCellComponent instead.
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-number-table-cell',
  templateUrl: './number-table-cell.component.html',
  styleUrls: ['./number-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NumberTableCellComponent extends TableCellFormControl<number> implements OnInit {
  /** Placeholder. */
  @Input()
  public placeholder = 'Enter value';

  /** Suffix. */
  @Input()
  public suffix: string | null = null;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /**
   * Whether value in view mode should be hidden.
   * Suffix will be also hidden in view mode.
   */
  @Input()
  public hideValue = false;

  /** Whether to default to zero if input is empty. */
  @Input()
  public isDefaultToZero = false;

  /** Min value. */
  @Input()
  public min: number | null = null;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Number form control. */
  protected readonly numberControl = this.fb.control<number>(0);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    this.numberControl.setValue(value ?? 0, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable();
    } else {
      this.numberControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** Input blur handler. */
  protected onBlur(): void {
    if (this.isDefaultToZero && this.numberControl.value == null) {
      this.numberControl.setValue(0);
    }
  }

  private subscribeToControlChanges(): void {

    // The reason we don't use 'listenControlChanges' is described in TextTableCellComponent
    this.numberControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }
}
