import { HttpParams } from '@angular/common/http';

import { AnyObject } from '@dartsales/common/core/utils/types/any-object';

import { removeEmptyValues } from './remove-empty-values';

/**
 * Build HttpParams from object.
 * @param args Object with params.
 */
export function buildHttpParams(args: AnyObject): HttpParams {
  return new HttpParams({
    fromObject: removeEmptyValues(args),
  });
}
