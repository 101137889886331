import { inject, Injectable } from '@angular/core';

import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';

import { TermCategoryTreeItemDto } from '../dto/estimate-services/term-tree-item.dto';
import { TermCategoryExportItemDto } from '../dto/export/term-export-items.dto';
import { IMapperFromDto } from '../mappers';

import { TermSubcategoryTreeItemMapper } from './term-subcategory-tree-item.mapper';

/** Term category tree item mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermCategoryTreeItemMapper implements IMapperFromDto<TermCategoryTreeItemDto, TermCategoryTreeItem> {

  private readonly termSubCategoryTreeItemMapper = inject(TermSubcategoryTreeItemMapper);

  /** @inheritdoc */
  public fromDto(dto: TermCategoryTreeItemDto): TermCategoryTreeItem {
    return new TermCategoryTreeItem({
      value: {
        categoryId: dto.categoryId,
      },
      title: dto.categoryName,
      children: dto.subCategories.map(subCategory => this.termSubCategoryTreeItemMapper.fromDto(subCategory)),
    });
  }

  /** @inheritdoc */
  public toDto(data: TermCategoryTreeItem): TermCategoryExportItemDto {
    return {
      categoryId: data.value.categoryId,
      subCategories: data.children.map(subCategory => this.termSubCategoryTreeItemMapper.toDto(subCategory)),
    };
  }
}
