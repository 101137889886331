import { Pipe, PipeTransform } from '@angular/core';

/** Convert to string.  */
@Pipe({
  name: 'string',
})
export class StringPipe implements PipeTransform {

  /**
   * Convert to string.
   * @param value Value.
   * @returns Value as a string, for null or undefined returns empty string.
   */
  public transform(value: unknown): string {
    return value != null ? String(value) : '';
  }
}
