import { ChangeDetectorRef, Directive, forwardRef, ForwardRefFn, inject, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Create provider for a control.
 * @param factory Factory providing a reference to control component class.
 */
export function controlProviderFor(factory: ForwardRefFn): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(factory),
    multi: true,
  };
}

/**
 * Create validator provider for a control.
 * @param factory Factory providing a reference to control component class.
 */
export function validatorProviderFor(factory: ForwardRefFn): Provider {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(factory),
    multi: true,
  };
}

export type OnChangeFn<V> = ((opt: V | null) => void);
export type OnTouchedFn = () => void;

/** Base implementation of Angular's control value accessor. */
// Angular requires some decorator so that DI would get things right, Directive is added to avoid specifying Component's metadata.
@Directive()
export abstract class BaseValueAccessor<T> implements ControlValueAccessor {

  /** Whether the control should be disabled. */
  public get disabled(): boolean {
    return this._disabled;
  }

  /** Change detector. */
  protected readonly changeDetectorRef = inject(ChangeDetectorRef);

  private _disabled = false;

  private isTouched = false;

  private onChangeFn: OnChangeFn<T> = () => undefined;

  private onTouchedFn: OnTouchedFn = () => undefined;

  /** @inheritdoc */
  public abstract writeValue(value: T | null): void;

  /**
   * Saves the Angular's onChange callbacks so to call them whenever the value is changed.
   * @param fn Callback.
   */
  public registerOnChange(fn: OnChangeFn<T>): void {
    this.onChangeFn = fn;
  }

  /**
   * Saves onTouched callback which will be called once the value is changed.
   * @param fn Callback.
   */
  public registerOnTouched(fn: OnTouchedFn): void {
    this.onTouchedFn = fn;
  }

  /**
   * Called whenever the Angular's FormControl is set to be disabled.
   * @param isDisabled Disabled state.
   */
  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  /**
   * Emit change callbacks.
   * @param value Value to emit.
   */
  protected emitChange(value: T | null): void {
    if (!this.isTouched) {
      this.emitTouched();
    }
    this.onChangeFn(value);
    this.changeDetectorRef.markForCheck();
  }

  /** Emit touched. */
  protected emitTouched(): void {
    this.isTouched = true;
    this.onTouchedFn();
  }
}
