type Item = {

  /** Is default. */
  readonly isDefault: boolean;

  /** Order. */
  readonly order: number;
};

/**
 * Get biggest default order.
 * @param items Items that have `order` and `isDefault` properties.
 */
export function getBiggestDefaultOrder(items: readonly Item[]): number {
  const defaultItemOrders = items.filter(item => item.isDefault).map(item => item.order);
  const biggestDefaultOrder = defaultItemOrders.length > 0 ?
    (Math.max(...defaultItemOrders) + 1) :
    0;
  return biggestDefaultOrder;
}
