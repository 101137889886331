import { Injectable, inject } from '@angular/core';

import { CustomModulePricing } from '@dartsales/common/core/models/estimate/modules/custom/custom-pricing';
import { BatchEditCustomPricing, EditCustomModulePricing } from '@dartsales/common/core/models/estimate/modules/custom/edit-custom-pricing';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { BatchEditCustomPricingDto, CustomPricingDto, EditCustomPricingOrderDto, EditCustomPricingDataDto, BatchEditLockablePercentsStatusDto } from '../../../dto/estimate/modules/custom/custom-pricing.dto';
import { IMapper } from '../../../mappers';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';

/** Custom module's pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class CustomModulePricingMapper implements IMapper<CustomPricingDto, CustomModulePricing> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: CustomPricingDto): CustomModulePricing {
    return new CustomModulePricing({
      ...this.basePricingMapper.fromDto(dto),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
      description: dto.description,
      id: dto.id,
    });
  }

  /** @inheritdoc */
  public toDto(data: CustomModulePricing): CustomPricingDto {
    return {
      ...this.basePricingMapper.toDto(data),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
      id: data.id,
      description: data.description,
    };
  }

  /**
   * Map batch edit custom pricing to DTO.
   * @param data Batch edit custom pricing.
   */
  public toBatchEditDto(data: BatchEditCustomPricing): BatchEditCustomPricingDto {
    return {
      customItemId: data.id,
      customItem: this.toEditDto(data.value),
    };
  }

  /**
   * Map Custom module pricing to order DTO.
   * @param data Custom module pricing.
   * @param order Custom module pricing order.
   */
  public mapCustomPricingToOrderDto(data: CustomModulePricing, order: number): EditCustomPricingOrderDto {
    return {
      estimateCustomItemId: data.id,
      order,
    };
  }

  /**
   * Map model for edit custom module pricing to DTO.
   * @param data Data for create custom module pricing.
   */
  public toEditDto(data: EditCustomModulePricing): EditCustomPricingDataDto {
    return {
      ...this.basePricingMapper.toDto(data),
      description: data.description,
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Custom module pricing ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: string,
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      customItems: [
        {
          customItemId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
