import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/** View cell service. */
@Injectable()
export class CellRendererService {

  /** Focus emitter. */
  public readonly focus$ = new Subject<FocusParams | null>();
}

/** Focus params. */
export type FocusParams = {

  /** Selection params. */
  readonly selection: CursorPositionIndex | CursorPositionRelativeToContent | AllContentSelection;
};

/** Cursor position index. */
export type CursorPositionIndex = {

  /** Type. */
  readonly type: 'index';

  /** Index. */
  readonly index: number;
};

/** Cursor position relative to content. */
export type CursorPositionRelativeToContent = {

  /** Type. */
  readonly type: 'relative';

  /** Cursor position relative to content. */
  readonly position: RelativePosition;
};

/** All content selection param. */
type AllContentSelection = {

  /** Type. */
  readonly type: 'all-content';
};

/** Cursor position. */
export enum RelativePosition {
  Before = 'before',
  After = 'after',
}
