import { Injectable, inject } from '@angular/core';

import { ConfirmedOverridable } from '../../models/confirmed-overridable';

import { ConfirmedOverridableDto } from './dto/confirmed-overridable.dto';
import { IMapper, IMapperFromDto, IMapperToDto } from './mappers';
import { OverridableMapper } from './overridable.mapper';

/** Confirmed overridable mapper. */
@Injectable({
  providedIn: 'root',
})
export class ConfirmedOverridableMapper implements
IMapper<ConfirmedOverridableDto<unknown>, ConfirmedOverridable<unknown>> {

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto<T>(dto: ConfirmedOverridableDto<T>): ConfirmedOverridable<T> {
    return new ConfirmedOverridable({
      ...this.overridableMapper.fromDto(dto),
      override: dto.override,
      isConfirmed: dto.isApproved,
    });
  }

  /** @inheritdoc */
  public toDto<T>(data: ConfirmedOverridable<T>): ConfirmedOverridableDto<T> {
    return {
      ...this.overridableMapper.toDto(data),
      isApproved: data.isConfirmed,
    };
  }

  /**
   * Convert ConfirmedOverridableDto to model with custom mapper for value.
   * @param dto Dto.
   * @param mapper Mapper.
   */
  public fromDtoWithMapper<TDto, TModel>(
    dto: ConfirmedOverridableDto<TDto>,
    mapper: IMapperFromDto<TDto, TModel>,
  ): ConfirmedOverridable<TModel> {
    return new ConfirmedOverridable({
      initial: mapper.fromDto(dto.value),
      override: dto.override == null ? null : mapper.fromDto(dto.override),
      isConfirmed: dto.isApproved,
    });
  }

  /**
   * Convert ConfirmedOverridable to DTO with custom mapper for value.
   * @param data Data.
   * @param mapper Mapper.
   */
  public toDtoWithMapper<TDto, TModel>(
    data: ConfirmedOverridable<TModel>,
    mapper: IMapperToDto<TDto, TModel>,
  ): ConfirmedOverridableDto<TDto> {
    return {
      value: mapper.toDto(data.initial),
      override: data.override == null ? null : mapper.toDto(data.override),
      isApproved: data.isConfirmed,
    };
  }
}
