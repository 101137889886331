import { Injectable } from '@angular/core';

import { TermServiceTreeItem } from '@dartsales/common/core/models/estimate-services/term-service-tree-item';

import { TermServiceTreeItemDto } from '../dto/estimate-services/term-tree-item.dto';
import { TermServiceExportItemDto } from '../dto/export/term-export-items.dto';
import { IMapperFromDto } from '../mappers';

/** Term service tree item mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermServiceTreeItemMapper implements IMapperFromDto<TermServiceTreeItemDto, TermServiceTreeItem> {

  /** @inheritdoc */
  public fromDto(dto: TermServiceTreeItemDto): TermServiceTreeItem {
    return new TermServiceTreeItem({
      children: dto.moduleTypes,
      value: {
        serviceId: dto.serviceId,
      },
      title: dto.serviceName,
    });
  }

  /** @inheritdoc */
  public toDto(data: TermServiceTreeItem): TermServiceExportItemDto {
    return {
      serviceId: data.value.serviceId,
      moduleTypes: data.children,
    };
  }
}
