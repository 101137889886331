import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';
import { ValuesRange } from '@dartsales/common/core/models/values-range';
import { ValuesRangeStateMatcher } from '@dartsales/common/core/utils/values-range-state-matcher';

/** Number range filter input component. */
@Component({
  selector: 'dartsalesc-number-range-filter-input',
  templateUrl: './number-range-filter-input.component.html',
  styleUrls: ['./number-range-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NumberRangeFilterInputComponent implements FilterComponentType<ValuesRange<number | null>> {

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<ValuesRange<number | null>>();

  /** Error state matcher. */
  protected readonly matcher = new ValuesRangeStateMatcher(this.formControl);
}
