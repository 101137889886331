/**
 * Extract errors message from error data.
 * @param errorData Error data.
 * @returns The first item if error data is a array of error messages.
 * Error message from non_field_errors if it presented.
 * Error message of the first key if error data is error for composite object like City: { id, name }.
 */
export function extractErrorMessage(errorData?: string[]): string | undefined {
  if (errorData == null || errorData?.length === 0) {
    return undefined;
  }

  return errorData.join('\n');
}
