import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { DomainCostCalculator } from './domain-cost-calculator';

/** Domain sell price calculator. */
export namespace DomainSellPriceCalculator {

  type CalculateSellPriceParams = {

    /** Margin amount. */
    readonly marginAmount: number;
  } & DomainCostCalculator.TotalDirectCostParams;

  type CalculateOverridableSellPriceParams = CalculateSellPriceParams & {

    /** Sell price amount. */
    readonly sellPrice?: Overridable<number>;
  };

  /**
   * Calculates sell price.
   * @param param0 Params.
   */
  export function calculateSellPrice({
    directCost,
    contingencyAmount,
    marginAmount,
    escalationAmount,
  }: CalculateSellPriceParams): number {
    const totalDirectCost = DomainCostCalculator.calculateTotalDirectCost({
      directCost,
      contingencyAmount,
      escalationAmount,
    });
    return totalDirectCost + marginAmount;
  }

  /**
   * Calculate sell price for overridable value.
   * @param args Arguments.
   */
  export function calculateOverridableSellPrice({
    directCost,
    contingencyAmount,
    marginAmount,
    sellPrice,
    escalationAmount,
  }: CalculateOverridableSellPriceParams): Overridable<number> {
    return new Overridable({
      initial: DomainSellPriceCalculator.calculateSellPrice({
        directCost,
        contingencyAmount,
        marginAmount,
        escalationAmount,
      }),
      override: sellPrice?.override ?? null,
    }) ;
  }
}
