import { PaginationData } from './pagination-data';

type PagedListInitArgs<T> = PagedList<T>;

/** Paged list. */
export class PagedList<T> {
  /** Pagination. */
  public readonly pagination: PaginationData;

  /** Items. */
  public readonly items: readonly T[];

  public constructor(data: PagedListInitArgs<T>) {
    this.pagination = data.pagination;
    this.items = data.items;
  }
}
