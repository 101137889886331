// This naming might be adjusted according to context.
// We were not sure about usage context. Adjust this entity's name or remove this comment.

import { ProjectStatus } from '../../enums/project-status';
import { Address } from '../address';
import { BidCategory } from '../resources/bid-category';

type ExploreBidCategory = Pick<BidCategory, 'id' | 'name'>;

/** Explore Projects list item model. */
export class ExploreProjectsItem {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Id initialized by user. */
  public readonly code: string | null;

  /** Incremental project number in the organization. */
  public readonly projectNumber: number;

  /** Project status. */
  public readonly status: ProjectStatus;

  /**
   * Final estimate sell price.
   * `null` if final estimate not selected.
   */
  public readonly awardedSellPrice: number | null;

  /** Base estimate sell price. */
  public readonly baseEstimateSellPrice: number;

  /** Logo url. */
  public readonly logoUrl: string | null;

  /** Project sales manager name. */
  public readonly salesperson: string | null;

  /** Project organization id. */
  public readonly organizationId: number;

  /** Project organization name. */
  public readonly organizationName: string;

  /** Date of the last project changing. */
  public readonly updatedAt: Date | null;

  /** Project organization description. */
  public readonly description: string | null;

  /** Project location. */
  public readonly location: Address | null;

  /** Bid categories. */
  public readonly bidCategories: readonly ExploreBidCategory[];

  public constructor(data: ExploreProjectsItemInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.status = data.status;
    this.salesperson = data.salesperson;
    this.organizationId = data.organizationId;
    this.organizationName = data.organizationName;
    this.updatedAt = data.updatedAt;
    this.description = data.description;
    this.location = data.location;
    this.bidCategories = data.bidCategories;
    this.awardedSellPrice = data.awardedSellPrice;
    this.baseEstimateSellPrice = data.baseEstimateSellPrice;
    this.logoUrl = data.logoUrl;
    this.projectNumber = data.projectNumber;
  }
}

type ExploreProjectsItemInitArgs = ExploreProjectsItem;
