import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs';
import { InputmaskOptions } from '@ngneat/input-mask';

import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { parseMaskedNumber } from '@dartsales/common/core/utils/parse-masked-number';

// TODO (Pavel Z.) Too similar with AmountInput, could we combine them?
/** Masked number input component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-masked-number-input',
  templateUrl: './masked-number-input.component.html',
  styleUrls: ['./masked-number-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => MaskedNumberInputComponent)],
})
export class MaskedNumberInputComponent extends SimpleValueAccessor<number> implements OnInit {
  /** Suffix. */
  @Input()
  public suffix: string | null = null;

  /** Input mask. */
  @Input()
  public mask: InputmaskOptions<unknown> | null = null;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Number form control. */
  protected readonly maskedNumberControl = this.fb.control<string>('');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    this.maskedNumberControl.setValue(value?.toString() ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.maskedNumberControl.disable();
    } else {
      this.maskedNumberControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.maskedNumberControl).pipe(
      skip(1),
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.controlValue = parseMaskedNumber(value, this.mask);
      });
  }
}
