import { TreeItem } from '../tree-item';

import { TermServiceTreeItem } from './term-service-tree-item';

/** Term sub-category item.*/
export type TermSubCategoryItem = {

  /** Sub-category ID. */
  readonly subCategoryId: number;
};

/** Term subcategory tree item. */
export class TermSubCategoryTreeItem extends TreeItem<TermSubCategoryItem, TermServiceTreeItem> { }
