import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

/** Filters button. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-base-filters',
  templateUrl: './base-filters.component.html',
  styleUrls: ['./base-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent {

  /** Whether indicator is visible. */
  @Input()
  public isIndicatorVisible = false;

  /** Is loading. */
  @Input()
  public isLoading: boolean | null = false;
}
