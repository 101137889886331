import { Injectable } from '@angular/core';

import { SortDirection } from '../../enums/sort-direction';
import { Sort } from '../../models/list-utilities/sort';

import { SortDto } from './dto/sort.dto';
import { IMapperToDto } from './mappers';

/** Mapper for Sort. */
@Injectable({ providedIn: 'root' })
export class SortMapper implements IMapperToDto<SortDto | undefined, Sort | undefined> {
  /** @inheritdoc */
  public toDto(sort: Sort | readonly Sort[] | undefined): SortDto | undefined {
    if (sort == null) {
      return;
    }

    const sortArray: readonly Sort[] = Array.isArray(sort) ? sort : [sort];

    // For descending sort use field name with direction (e.g. 'field_name:desc').
    return {
      OrderBy: sortArray
        .map(s => this.toOrderByString(s))
        .filter(Boolean)
        .join(','),
    };
  }

  private toOrderByString(sort: Sort): string | null {
    if (sort.direction === SortDirection.NONE) {
      return null;
    }
    return sort.direction === SortDirection.ASC ? `${sort.field}:asc` : `${sort.field}:desc`;
  }
}
