import { Injectable } from '@angular/core';

import { ExportParams } from '@dartsales/common/core/models/exports/export-params';

import { ExportParamsDto } from '../dto/export/export-params.dto';
import { IMapperToDto } from '../mappers';

/** Export config mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExportParamsMapper implements IMapperToDto<ExportParams, ExportParamsDto> {

  /** @inheritdoc */
  public toDto(data: ExportParams): ExportParamsDto {
    return {
      exportFormat: data.exportFormat,
    };
  }
}
