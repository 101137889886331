import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiTransactionId } from '../../models/api-transaction-id';

import { AppUrlsConfig } from './app-urls.config';

/** Transaction API service. */
@Injectable({
  providedIn: 'root',
})
export class TransactionsApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  /**
   * Undo transaction. Reverts changes caused by transaction.
   * @param transaction Transaction.
   */
  public undoTransaction(transaction: ApiTransactionId): Observable<void> {
    const url = this.apiUrls.transactionsApi.undo(transaction.id);
    return this.http.post<void>(url, null);
  }

  /**
   * Redo transaction. Applies changes that transaction contains.
   * @param transaction Transaction.
   */
  public redoTransaction(transaction: ApiTransactionId): Observable<void> {
    const url = this.apiUrls.transactionsApi.redo(transaction.id);
    return this.http.post<void>(url, null);
  }
}
