import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TermMultipleExportConfigFormMapper } from '@dartsales/common/core/services/mappers/forms/export/term-multiple-export-config-form.mapper';
import { ExportParams } from '@dartsales/common/core/models/exports/export-params';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';
import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';
import { EstimateTermsTreeService } from '@dartsales/common/core/services/estimate-terms-tree.service';

import { TermExportService } from '../../../../project-dashboard/services/export/term/term-export.service';

/** Term multiple export dialog data. */
export type TermMultipleExportDialogData = {

  /** Term ID. */
  readonly termId: number;

  /** Estimate ID. */
  readonly estimateId: number;
};

/** Term multiple export dialog component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-term-multiple-export-dialog',
  templateUrl: './term-multiple-export-dialog.component.html',
  styleUrls: ['../shared-export-dialog-styles.css', './term-multiple-export-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermMultipleExportDialogComponent {

  /** Dialog data. */
  protected readonly dialogData = inject<TermMultipleExportDialogData>(MAT_DIALOG_DATA);

  /** Dialog ref. */
  protected readonly dialogRef = inject<MatDialogRef<TermMultipleExportDialogComponent, void>>(MatDialogRef);

  private readonly formMapper = inject(TermMultipleExportConfigFormMapper);

  private readonly termExportService = inject(TermExportService);

  /** Config form. */
  protected readonly configForm = this.formMapper.modelToForm();

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  private readonly estimateTermsTreeService = inject(EstimateTermsTreeService);

  /** Is term tree loading. */
  protected readonly isTermTreeLoading$ = new BehaviorSubject(false);

  /** Term tree. */
  protected readonly termTree$ = this.createTermTreeStream();

  /**
   * Handle categories selection changes.
   * @param selectedCategories Selected categories.
   */
  protected handleCategoriesSelectionChanges(selectedCategories: readonly TermCategoryTreeItem[]): void {
    this.configForm.patchValue({
      exportedCategories: selectedCategories,
    });
  }

  /** Handle submit form. */
  protected onSubmit(): void {
    this.configForm.markAllAsTouched();

    if (this.configForm.invalid) {
      return;
    }

    const { exportFormat, exportedCategories } = this.configForm.getRawValue();
    const { termId } = this.dialogData;

    this.termExportService.exportMultiple(
      termId,
      new ExportParams({
        exportFormat,
      }),
      exportedCategories,
    ).pipe(
      toggleExecutionState(this.isLoading$),
      untilDestroyed(this),
    )
      .subscribe(() => this.dialogRef.close());
  }

  private createTermTreeStream(): Observable<TermTreeItem | null> {
    const { estimateId, termId } = this.dialogData;
    return this.estimateTermsTreeService.getTermTreeFromInternalView(estimateId, termId).pipe(
      toggleExecutionState(this.isTermTreeLoading$),
    );
  }
}
