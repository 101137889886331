import { PalettePart } from '@dartsales/common/core/models/components/palette/palette-part';

import { PointsListPartGroup } from '../part-group/points-list-part-group';

import { PointsListPartDetails } from './points-list-part-details';
import { PointsListPartProperties } from './points-list-part-properties';

/** Points list part. */
export class PointsListPart {
  /** Part UUID. */
  public readonly id: string;

  /** Part group UUID. */
  public readonly partGroupId: PointsListPartGroup['id'];

  /** Palette component UUID ('null' if part was not created from palette). */
  public readonly paletteComponentId: PalettePart['id'] | null;

  /** Order. */
  public readonly order: number;

  /** Is part excluded. */
  public readonly isExcluded: boolean;

  /** Part details. */
  public readonly data: PointsListPartDetails;

  /** Part properties. */
  public readonly properties: PointsListPartProperties;

  public constructor(data: PointsListPartInitArgs) {
    this.id = data.id;
    this.partGroupId = data.partGroupId;
    this.paletteComponentId = data.paletteComponentId;
    this.order = data.order;
    this.isExcluded = data.isExcluded;
    this.data = data.data;
    this.properties = data.properties;
  }
}

type PointsListPartInitArgs = PointsListPart;
