import { map, Observable, of, tap } from 'rxjs';

import { TransactionsApiService } from '@dartsales/common/core/services/api/transactions-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { PointsListAbstractCommand } from './points-list-abstract-command';

/** Points list transaction redo command. */
export abstract class PointsListTransactionRedoCommand<TArgs = unknown> extends PointsListAbstractCommand<TArgs> {
  private readonly transactionsApiService = this.injector.get(TransactionsApiService);

  /** Undo transaction ID. */
  private undoTransactionId: ApiTransactionId | null = null;

  /** We use 'undoAction' instead of 'undo' because we have to perform additional side effect on 'undo'. */
  protected abstract undoAction(): Observable<ApiTransactionId>;

  /** Don't override this method in subclasses. Use `undoAction` method instead. */
  public override undo(): Observable<void> {
    return this.undoAction().pipe(
      tap(transaction => {
        this.undoTransactionId = transaction;
      }),
      map(() => undefined),
    );
  }

  /** @inheritdoc */
  public override redo(): Observable<void> {
    if (this.undoTransactionId == null) {
      return of(undefined);
    }

    return this.transactionsApiService.undoTransaction(this.undoTransactionId);
  }
}
