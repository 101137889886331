import { Injectable } from '@angular/core';

import { MaterialModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/modules/material/properties/material-lockable-percents';

import { MaterialLockablePercentsStatusDto } from '../../../dto/estimate/modules/material/material-module.dto';
import { IMapper } from '../../../mappers';

/** Material module lockable percents status mapper. */
@Injectable({
  providedIn: 'root',
})
export class MaterialLockablePercentsStatusMapper implements
IMapper<MaterialLockablePercentsStatusDto, MaterialModuleLockablePercentsStatus> {
  /** @inheritdoc */
  public fromDto(dto: MaterialLockablePercentsStatusDto): MaterialModuleLockablePercentsStatus {
    return new MaterialModuleLockablePercentsStatus({
      isContingencyLocked: dto.isContingencyLocked,
      isGrossMarginLocked: dto.isGrossMarginLocked,
      isEscalationLocked: dto.isEscalationLocked,
      isFreightLocked: dto.isFreightLocked,
      isWarrantyLocked: dto.isWarrantyLocked,
      isSalesTaxLocked: dto.isSalesTaxLocked,
    });
  }

  /** @inheritdoc */
  public toDto(data: MaterialModuleLockablePercentsStatus): MaterialLockablePercentsStatusDto {
    return {
      isContingencyLocked: data.isContingencyLocked,
      isGrossMarginLocked: data.isGrossMarginLocked,
      isEscalationLocked: data.isEscalationLocked,
      isFreightLocked: data.isFreightLocked,
      isWarrantyLocked: data.isWarrantyLocked,
      isSalesTaxLocked: data.isSalesTaxLocked,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: Partial<MaterialModuleLockablePercentsStatus>): Partial<MaterialLockablePercentsStatusDto> {
    return {
      isContingencyLocked: data?.isContingencyLocked,
      isGrossMarginLocked: data?.isGrossMarginLocked,
      isEscalationLocked: data?.isEscalationLocked,
      isFreightLocked: data?.isFreightLocked,
      isWarrantyLocked: data?.isWarrantyLocked,
      isSalesTaxLocked: data?.isSalesTaxLocked,
    };
  }
}
