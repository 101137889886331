import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ExportParams } from '../../models/exports/export-params';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { BlobResponseMapper } from '../mappers/blob-response.mapper';
import { ExportParamsMapper } from '../mappers/export/export-params.mapper';
import { TermCategoryTreeItem } from '../../models/estimate-services/term-category-tree-item';
import { CategoryExportConfigMapper } from '../mappers/export/category-export-config.mapper';
import { buildHttpParams } from '../../utils/build-http-params';

import { AppUrlsConfig } from './app-urls.config';

/** Term export API service. */
@Injectable({
  providedIn: 'root',
})
export class TermExportApiService {
  private readonly http = inject(HttpClient);

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly blobResponseMapper = inject(BlobResponseMapper);

  private readonly exportParamsMapper = inject(ExportParamsMapper);

  private readonly exportedItemsMapper = inject(CategoryExportConfigMapper);

  /**
   * Export term.
   * @param termId Term ID.
   * @param exportParams Export params.
   * @param exportedCategories Exported categories.
   */
  public export(
    termId: number,
    exportParams: ExportParams,
    exportedCategories: readonly TermCategoryTreeItem[],
  ): Observable<File> {
    const paramsDto = this.exportParamsMapper.toDto(exportParams);
    const params = buildHttpParams(paramsDto);
    const exportedItemDtos = exportedCategories.map(item => this.exportedItemsMapper.toDto(item));
    return this.http.post(
      this.apiUrls.exportApi.term(termId),
      exportedItemDtos,
      {
        params,
        responseType: 'blob',
        observe: 'response',
      },
    ).pipe(
      map(response => this.blobResponseMapper.fromDto(response)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
