import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '../../services/cell.provider';

/** Text cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-text-cell',
  templateUrl: './text-cell.component.html',
  styleUrls: ['./text-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...CELL_PROVIDERS],
})
export class TextCellComponent extends AbstractCellFormControlComponent<string> {

  /** Cell suffix template. */
  @ContentChild('cellSuffixTemplate')
  protected readonly cellSuffixTemplate?: TemplateRef<unknown>;

  /** Placeholder. */
  @Input()
  public placeholder = 'Enter value';
}
