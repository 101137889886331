import { Injectable, inject } from '@angular/core';

import { PointsListPartProperties } from '@dartsales/common/core/models/estimate/modules/points-list/part/points-list-part-properties';

import { PointsListPartPropertiesDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-properties.dto';
import { IMapper } from '../../../mappers';

import { PointsListPartTotalMapper } from './points-list-part-total.mappe';

/** Points list part properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartPropertiesMapper implements IMapper<PointsListPartPropertiesDto, PointsListPartProperties> {
  private readonly pointsListPartTotalMapper = inject(PointsListPartTotalMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListPartPropertiesDto): PointsListPartProperties {
    return new PointsListPartProperties({
      total: this.pointsListPartTotalMapper.fromDto(dto),
      laborMinutes: dto.laborMinutes,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListPartProperties): PointsListPartPropertiesDto {
    return {
      ...this.pointsListPartTotalMapper.toDto(data.total),
      laborMinutes: data.laborMinutes,
    };
  }
}
