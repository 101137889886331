import { AbstractControl } from '@angular/forms';

import { ValidationErrorCode } from '../models/errors/validation-error-code';

/**
 * Remove app error from control.
 * @param control Form control.
 * @param emitEvent Emit event to controls.
 */
export function removeAppErrorFromControl(control: AbstractControl, emitEvent = true): void {
  if (control.errors === null || !control.hasError(ValidationErrorCode.AppError)) {
    return;
  }

  if (Object.keys(control.errors).length === 1) {
    control.setErrors(null, { emitEvent });
    return;
  }

  const errors = { ...control.errors };
  delete errors[ValidationErrorCode.AppError];
  control.setErrors(errors, { emitEvent });
}
