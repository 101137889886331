import { Injectable, inject } from '@angular/core';

import { ExpansionActions } from '../../../expansion-actions/expansion-actions.component';

import { MaterialTableCollapseRowsService } from './material-module-table-collapse-rows.service';

/** Material table row expansion service. */
@Injectable()
export class MaterialTableRowExpansionService {
  private readonly tableCollapseRowsService = inject(MaterialTableCollapseRowsService);

  /** @inheritdoc */
  public readonly expansionActions: ExpansionActions = {
    expand: [
      {
        label: 'All',
        click: () => this.tableCollapseRowsService.setExpandRowsWithType(['standardMaterialGroup']),
      },
    ],
    collapse: [
      {
        label: 'All',
        click: () => this.tableCollapseRowsService.setCollapseRowsWithType(['standardMaterialGroup']),
      },
    ],
  };
}
