import { Injectable } from '@angular/core';

import { PaletteSupplier } from '@dartsales/common/core/models/components/palette/palette-supplier';

import { IMapperFromDto } from '../../mappers';
import { PaletteSupplierDto } from '../../dto/components/palette/palette-supplier.dto';

/** Palette supplier mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteSupplierMapper implements IMapperFromDto<PaletteSupplierDto, PaletteSupplier> {

  /** @inheritdoc */
  public fromDto(dto: PaletteSupplierDto): PaletteSupplier {
    return new PaletteSupplier({
      name: dto.name,
    });
  }
}
