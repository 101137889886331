import { Injectable } from '@angular/core';

import { TermDuplicationDto } from '../dto/estimate-services/term-duplication.dto';
import { IMapperToDto } from '../mappers';

/** Service term mapper for duplicate operation. */
@Injectable({
  providedIn: 'root',
})
export class TermDuplicationMapper implements IMapperToDto<TermDuplicationDto, number> {

  /** @inheritdoc */
  public toDto(quantity: number): TermDuplicationDto {
    return {
      quantity,
    };
  }
}
