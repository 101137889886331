<dartsalesc-editable-table-cell
  [matTooltip]="errorMessage ?? ''"
  [editModeOnly]="true"
  [isReadonly]="isReadonly"
  (cellBlur)="trigger.closePanel()"
>
  <ng-container dartsalescTableCellEditMode>
    <input
      #trigger="matAutocompleteTrigger"
      dartsalescTableCellInput
      type="text"
      class="autocomplete-input"
      [readonly]="isReadonly"
      [errorStateMatcher]="errorStateMatcher"
      [placeholder]="placeholder"
      [formControl]="autocompleteControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto
      [hideSingleSelectionIndicator]="true"
      [displayWith]="displayFn"
    >
      <ng-container
        *ngIf="(filteredOptions$ | async)?.length === 0; else filteredOptions"
      >
        <mat-option
          tabindex="0"
          [value]="autocompleteControl.value"
          (onSelectionChange)="onNewOptionSelected($event)"
        >
          <span class="create-option__label">Create:</span>
          <span class="create-option__value">
            {{ displayFn(autocompleteControl.value) }}
          </span>
        </mat-option>
      </ng-container>

      <ng-template #filteredOptions>
        <mat-option
          *ngFor="let option of filteredOptions$ | async; trackBy: trackByItem"
          tabindex="0"
          [value]="option"
          (onSelectionChange)="onOptionSelected($event)"
        >
          {{ option.label }}
        </mat-option>
      </ng-template>
    </mat-autocomplete>

    <div class="suffix">
      <button
        *ngIf="isResetAvailable && !isReadonly"
        mat-icon-button
        type="button"
        class="reset-button"
        [matTooltip]="tooltipMessage"
        (click)="onResetButtonClick()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </ng-container>
</dartsalesc-editable-table-cell>
