/** Internal and customer values. */
export class InternalCustomerValues<T> {

  /** Customer value. */
  public readonly customer: T;

  /** Internal value. */
  public readonly internal: T;

  public constructor(data: InternalCustomerValuesInitArgs<T>) {
    this.customer = data.customer;
    this.internal = data.internal;
  }
}

type InternalCustomerValuesInitArgs<T> = InternalCustomerValues<T>;
