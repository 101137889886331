import { Injectable } from '@angular/core';

import { EstimatePartCustomProperty } from '@dartsales/common/core/models/components/estimate-part/estimate-part-custom-property';

import { EstimatePartCustomPropertyDto } from '../../dto/components/estimate-part/estimate-part-custom-property.dto';
import { IMapper } from '../../mappers';

/** EstimatePartCustomProperty entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimatePartCustomPropertyMapper implements IMapper<EstimatePartCustomPropertyDto, EstimatePartCustomProperty> {
  /** @inheritdoc */
  public fromDto(dto: EstimatePartCustomPropertyDto): EstimatePartCustomProperty {
    return new EstimatePartCustomProperty({
      key: dto.key,
      value: dto.value,
    });
  }

  /** @inheritdoc */
  public toDto(data: EstimatePartCustomProperty): EstimatePartCustomPropertyDto {
    return {
      key: data.key,
      value: data.value,
    };
  }
}
