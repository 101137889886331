import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { UserDataFormValues } from '@dartsales/common/core/models/forms/user-data-form';
import { UserBase } from '@dartsales/common/core/models/user/user-base';
import { UpdatePersonalInfoApiService } from '@dartsales/common/core/services/api/update-personal-info.service';
import { CurrentUserService } from '@dartsales/common/core/services/current-user.service';
import { UserDataFormMapper } from '@dartsales/common/core/services/mappers/forms/user-data-form.mapper';
import { SnackbarNotificationsService } from '@dartsales/common/core/services/snackbar-notifications.service';
import { catchValidationData } from '@dartsales/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';

/** Edit account settings dialog data. */
export type EditAccountSettingsDialogData = {

  /** User data. */
  readonly userData: UserBase;
};

/** Edit account settings dialog component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-edit-account-settings-dialog',
  templateUrl: './edit-account-settings-dialog.component.html',
  styleUrls: ['./edit-account-settings-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAccountSettingsDialogComponent {

  private readonly snackbarNotificationsService = inject(SnackbarNotificationsService);

  private readonly dialogRef = inject(MatDialogRef);

  private readonly initialUserData = inject<EditAccountSettingsDialogData>(MAT_DIALOG_DATA).userData;

  private readonly formService = inject(UserDataFormMapper);

  private readonly updatePersonalInfoService = inject(UpdatePersonalInfoApiService);

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly currentUserService = inject(CurrentUserService);

  /** Is update user data pending. */
  protected readonly isUpdateUserDataPending$ = new BehaviorSubject(false);

  /** Edit account data control form. */
  protected readonly editAccountDataControlForm = this.formService.modelToForm(this.initialUserData);

  /** Handle dialog form submit. */
  protected onSubmit(): void {
    this.editAccountDataControlForm.markAllAsTouched();

    if (this.editAccountDataControlForm.invalid) {
      return;
    }

    const controlValues = this.editAccountDataControlForm.getRawValue();

    if (!this.isUserDataChanged(controlValues)) {
      this.dialogRef.close();
      return;
    }

    const updatedPersonalInfo = this.formService.formToModel(controlValues);

    this.updatePersonalInfoService.updatePersonalInfo(updatedPersonalInfo).pipe(
      toggleExecutionState(this.isUpdateUserDataPending$),
      catchValidationData(this.editAccountDataControlForm, this.cdr),
      untilDestroyed(this),
    )
      .subscribe({
        next: () => {
          this.snackbarNotificationsService.notify(this.generateNotification(updatedPersonalInfo));
          this.currentUserService.reloadUserData();
          this.dialogRef.close();
        },
      });
  }

  private generateNotification(data: UserDataFormValues): string {
    const messageWithChangePersonalData = 'Personal info changed successfully';
    const messageWithChangeEmail = 'Confirm email change in mail sent to your email';

    if (this.isEmailChanged(data) && this.isUsernameChanged(data)) {
      return `${messageWithChangePersonalData}. ${messageWithChangeEmail}.`;
    }

    if (this.isEmailChanged(data)) {
      return messageWithChangeEmail;
    }

    return messageWithChangePersonalData;
  }

  private isUserDataChanged(data: UserDataFormValues): boolean {
    return this.isEmailChanged(data) || this.isUsernameChanged(data);
  }

  private isEmailChanged(data: UserDataFormValues): boolean {
    return data.email !== this.initialUserData.email;
  }

  private isUsernameChanged(data: UserDataFormValues): boolean {
    return data.firstName !== this.initialUserData.firstName || data.lastName !== this.initialUserData.lastName;
  }
}
