import { Overridable } from '@dartsales/common/core/utils/types/overridable';

/** Overridable labor task hours. */
export class OverridableLaborTaskHours {

  /** Task ID. */
  public readonly taskId: number;

  /** Hours. */
  public readonly hours: Overridable<number>;

  public constructor(data: OverridableLaborTaskHoursInitArgs) {
    this.taskId = data.taskId;
    this.hours = data.hours;
  }
}

type OverridableLaborTaskHoursInitArgs = OverridableLaborTaskHours;
