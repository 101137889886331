<div class="header-select">
  <span class="header-units-label">
    {{ shortLabel }}
  </span>
  <mat-select
    class="header-units-select"
    panelClass="units-select-panel"
    [formControl]="unitsControl"
    (selectionChange)="unitsChange.emit($event.value)"
  >
    <mat-option
      *ngFor="let item of typeOptions$ | async; trackBy: trackByValue"
      class="header-units-select__option"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>

  <div
    *ngIf="isEditMode; else viewMode"
    class="input-container"
  >
    <div
      *ngIf="cellPrefix"
      class="prefix"
    >
      <ng-content select="[dartsalescTableCellPrefix]" />
    </div>
    <input
      #contingencyInput
      class="input-container__input"
      [class.cell__has-prefix]="cellPrefix"
      [inputMask]="inputMask"
      [formControl]="percentControl"
      [readonly]="isLockedValue"
      (blur)="onInputBlur($event)"
      (keydown.enter)="contingencyInput.blur(); $event.stopPropagation()"
    />
  </div>
</div>

<ng-template #viewMode>
  <dartsalesw-table-header-readonly-cell
    label="Average percent"
    [value]="controlValue"
  />
</ng-template>

<dartsalesw-horizontal-expand-button
  *ngIf="shouldDisplayExpandButton"
  class="expand-button"
  [isExpanded]="isExpanded"
  (buttonClick)="expandChange.emit()"
/>
