import { ResizableColumnsWidth } from '@dartsales/common/core/utils/catalog-table/catalog-table-columns-resize-manager';

/** Default system tab columns. */
export const DEFAULT_SYSTEM_TAB_COLUMNS: ResizableColumnsWidth = {
  name: 270,
  description: 140,
  manufacturer: 100,
  model: 100,
  tags: 200,
  cost: 100,
  supplier: 150,
  organization: 200,
};

/** Default part tab columns. */
export const DEFAULT_PART_TAB_COLUMNS: ResizableColumnsWidth = {
  type: 16,
  name: 200,
  description: 250,
  manufacturer: 150,
  model: 150,
  tags: 125,
  cost: 125,
  supplier: 125,
  organization: 150,
};

/** Default project tab columns. */
export const DEFAULT_PROJECT_TAB_COLUMNS: ResizableColumnsWidth = {
  name: 300,
  source: 100,
  projectNumber: 100,
  status: 100,
  lastModified: 100,
  salesperson: 100,
};
