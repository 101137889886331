import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { rightAngleRotateAnimation } from '../../animations/right-angle-rotate-animation';

/** Expand collapse icon comoponent. */
@Component({
  selector: 'dartsalesc-expand-collapse-icon',
  templateUrl: './expand-collapse-icon.component.html',
  styleUrls: ['./expand-collapse-icon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rightAngleRotateAnimation],
})
export class ExpandCollapseIconComponent {

  /** Is expanded. */
  @Input()
  public isExpanded = false;
}
