import { inject, InjectionToken, Provider } from '@angular/core';

import { buildRoutePaths } from '@dartsales/common/core/utils/route-paths/build-route-paths';

/** Injection token that provide object with route web app paths. */
const WEB_ROUTE_PATHS_TOKEN = new InjectionToken<WebRoutePaths>('Provide object with web route paths');

export const PROJECT_ID_PARAM = 'projectId';
export const ESTIMATE_ID_PARAM = 'estimateId';
export const CHANGE_ORDER_ID_PARAM = 'changeOrderId';
export const TERM_ID_PARAM = 'termId';
export const SERVICE_ID_PARAM = 'serviceId';

/**
 * Transform param name into the route param format.
 * @param param Param name.
 */
function createRouteParam<T extends string>(param: T): `:${T}` {
  return `:${param}`;
}

const MODULES_ROUTES = {
  subcontractor: { path: 'subcontractor' },
  expenses: { path: 'expenses' },
  custom: { path: 'custom' },
  labor: { path: 'labor' },
  material: { path: 'material' },
} as const;

const ESTIMATE_SUB_ROUTES = {
  modules: {
    path: 'modules',
    children: {
      ...MODULES_ROUTES,
    },
  },
  terms: {
    path: `terms/${createRouteParam(TERM_ID_PARAM)}`,
    children: {
      servicesCatalog: {
        path: 'catalog',
      },
      materialCatalog: {
        path: 'material-catalog',
      },
      service: {
        path: `service/${createRouteParam(SERVICE_ID_PARAM)}`,
        children: {
          ...MODULES_ROUTES,
        },
      },
    },
  },
  systems: {
    path: 'systems',
  },
  systemsCatalog: {
    path: 'systems-catalog',
  },
} as const;

/**
 * Web route paths object. \
 * It's intended to be used only in Routing modules.
 * So don't import this object directly into components. \
 * Prefer to use `injectWebAppRoutes` instead.
 * It's necessary to make our component more flexible for unit tests.
 * @example
 * ```ts
 * const routes: Routes = [
 *   { path: webRoutePaths.home, component: HomePageComponent },
 *   // Usage for dynamic child paths.
 *   {
 *     path: webRoutePaths.project.children({ id: '' }).edit.path,
 *     component: ProjectEditComponent
 *   },
 *   // ...
 * ];
 * ```
 */
export const webRoutePaths = buildRoutePaths({
  home: { path: '' },
  auth: {
    path: 'auth',
    children: {
      login: { path: 'login' },
      forgotPassword: { path: 'forgot-password' },
      confirmPassword: { path: 'confirm-password' },
      register: { path: 'register' },
    },
  },
  welcome: {
    path: 'welcome',
    children: {
      exploreProjects: {
        path: 'explore-projects',
      },
      searchProjects: {
        path: 'search-projects',
      },
      templates: { path: 'templates' },
      tutorials: { path: 'tutorials' },
    },
  },
  project: {
    path: `project/${createRouteParam(PROJECT_ID_PARAM)}`,
    children: {
      dashboard: {
        path: 'dashboard',
        children: {
          summary: {
            path: 'summary',
            children: {
              alternateEstimates: { path: 'alternate-estimates' },
              finalEstimates: { path: 'final-estimates' },
              changeOrders: { path: 'change-orders' },
            },
          },
          baseEstimate: {
            path: 'base-estimate',
            children: ESTIMATE_SUB_ROUTES,
          },
          alternateEstimates: {
            path: 'alternate-estimates',
            children: {
              details: {
                path: createRouteParam(ESTIMATE_ID_PARAM),
                children: ESTIMATE_SUB_ROUTES,
              },
              merge: {
                path: 'merge',
              },
            },
          },
          finalEstimates: {
            path: 'final-estimates',
            children: {
              details: { path: createRouteParam(ESTIMATE_ID_PARAM) },
            },
          },
          changeOrders: {
            path: 'change-orders',
            children: {
              details: {
                path: createRouteParam(CHANGE_ORDER_ID_PARAM),
                children: ESTIMATE_SUB_ROUTES,
              },
              merge: {
                path: 'merge',
              },
            },
          },
        },
      },
      proposals: { path: 'proposals' },
      compare: { path: 'compare' },
      files: { path: 'files' },
      resources: {
        path: 'resources',
        children: {
          bidCategories: {
            path: 'bid-categories',
          },
          laborRoles: {
            path: 'labor-roles',
          },
          expenseTypes: {
            path: 'expense-types',
          },
          laborTasks: {
            path: 'labor-tasks',
          },
          subcontractors: {
            path: 'subcontractors',
          },
          systemClassification: {
            path: 'system-classifications',
          },
          serviceCategories: {
            path: 'service-categories',
          },
        },
      },
    },
  },

  // This page is displayed if the user doesn't have access to the Sales app.
  accessError: { path: 'access-error' },

  // This page is displayed if the user has access to the Sales app but doesn't have permissions to any pages.
  permissionError: { path: 'permission-error' },
} as const);

export type WebRoutePaths = typeof webRoutePaths;

/** Create provider for a web route paths. */
export function provideWebAppRoutes(): Provider {
  return {
    provide: WEB_ROUTE_PATHS_TOKEN,
    useValue: webRoutePaths,
  };
}

/**
 * Inject web app route paths to component.
 * Warning: Method should be called in the constructor phase to avoid runtime error because of `inject()`.
 * @example
 * ```ts
 * class SomeComponent {
 *   // ...
 *   protected readonly routePaths = injectWebAppRoutes();
 *   public constructor() { };
 * }
 * ```
 */
export function injectWebAppRoutes(): WebRoutePaths {
  return inject(WEB_ROUTE_PATHS_TOKEN);
}
