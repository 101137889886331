import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, finalize } from 'rxjs';

import { DownloadDialogComponent, DownloadDialogData } from '../components/download-dialog-component/download-dialog.component';

/** Download service. */
@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private readonly matDialogService = inject(MatDialog);

  /**
   * Download blob.
   * @param file Blob.
   */
  public downloadFile(file: File): Observable<void> {
    const url = URL.createObjectURL(file);

    return this.openDownloadDialog(url, file.name).pipe(
      finalize(() => URL.revokeObjectURL(url)),
    );
  }

  /**
   * Open preview file.
   * @param file Blob.
   */
  public openPreviewFile(file: File): Observable<void> {
    const url = URL.createObjectURL(file);

    // Revoke isn't needed here because we won't be able to download this file.
    return this.openDownloadDialog(url, file.name, true);
  }

  /**
   * Download file from URL.
   * @param url URL.
   * @param shouldOpenFilePreview URL.
   */
  public downloadFileFromUrl(url: string, shouldOpenFilePreview: boolean): Observable<void> {
    return this.openDownloadDialog(url, undefined, shouldOpenFilePreview);
  }

  private openDownloadDialog(url: string, filename?: string, shouldOpenFilePreview = false): Observable<void> {
    return this.matDialogService.open<DownloadDialogComponent, DownloadDialogData>(DownloadDialogComponent, {
      data: { url, filename, shouldOpenFilePreview },
    })
      .afterClosed();
  }
}
