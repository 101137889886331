import { PointsListLaborTotals } from './points-list-labor-totals';
import { PointsListMaterialTotals } from './points-list-material-totals';

/** Points list totals system. */
export class PointsListTotalsSystem {
  /** System ID (UUID). */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Number of parts used in the points list. */
  public readonly pointsCount: number;

  /** Quantity. */
  public readonly quantity: number;

  /** Material cost. */
  public readonly materialCost: number;

  /** Labor cost. */
  public readonly laborCost: number;

  /** Total labor hours. */
  public readonly laborHours: number;

  /** Unit price. */
  public readonly unitPrice: number;

  /** Direct cost. */
  public readonly directCost: number;

  /** Labor roles totals. */
  public readonly laborTotals: PointsListLaborTotals;

  /** Labor roles unit totals (per 1 system). */
  public readonly laborUnitTotals: PointsListLaborTotals;

  /** Material groups totals. */
  public readonly materialTotals: PointsListMaterialTotals;

  /** Material groups totals (per 1 system). */
  public readonly materialUnitTotals: PointsListMaterialTotals;

  /** Determines if the point list item is excluded or not. */
  public readonly isExcluded: boolean;

  public constructor(data: PointsListTotalsSystemInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.pointsCount = data.pointsCount;
    this.quantity = data.quantity;
    this.materialCost = data.materialCost;
    this.laborCost = data.laborCost;
    this.unitPrice = data.unitPrice;
    this.directCost = data.directCost;
    this.laborHours = data.laborHours;
    this.laborTotals = data.laborTotals;
    this.laborUnitTotals = data.laborUnitTotals;
    this.materialTotals = data.materialTotals;
    this.materialUnitTotals = data.materialUnitTotals;
    this.isExcluded = data.isExcluded;
  }
}

type PointsListTotalsSystemInitArgs = PointsListTotalsSystem;
