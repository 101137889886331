/** Custom field. */
export class CustomField {

  /** Field name. */
  public readonly fieldName: string;

  /** Field value. */
  public readonly fieldValue: string;

  public constructor(data: CustomFieldInitArgs) {
    this.fieldName = data.fieldName;
    this.fieldValue = data.fieldValue;
  }
}

type CustomFieldInitArgs = CustomField;
