import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseCellEditorComponent } from '../../../base-components/base-cell-editor.component';

/** Text cell editor component. */
@Component({
  selector: 'dartsalesc-text-cell-editor',
  templateUrl: './text-cell-editor.component.html',
  styleUrls: ['./text-cell-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextCellEditorComponent extends BaseCellEditorComponent<string> {

  /** Placeholder. */
  @Input()
  public placeholder = '';
}
