import { Injectable } from '@angular/core';

import { PaletteOrganization } from '@dartsales/common/core/models/components/palette/palette-organization';

import { PaletteOrganizationDto } from '../../dto/components/palette/palette-organization.dto';
import { IMapperFromDto } from '../../mappers';

/** Palette organization mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaletteOrganizationMapper implements IMapperFromDto<PaletteOrganizationDto, PaletteOrganization> {

  /** @inheritdoc */
  public fromDto(dto: PaletteOrganizationDto): PaletteOrganization {
    return new PaletteOrganization({
      id: dto.id,
      name: dto.name,
    });
  }
}
