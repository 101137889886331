import { DestroyRef, Directive, ElementRef, OnInit, inject } from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputMaskDirective } from '@ngneat/input-mask';
import { Key } from 'ts-key-enum';
import { combineLatest, fromEvent, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';

/**
 * Handler for input with mask.
 * This directive solves the problem with changing
 * formControl when pressing 'Backspace' or 'Delete'.
 * Issue: https://github.com/RobinHerbots/Inputmask/issues/2630.
 */
@Directive({

  /** A library selector is used to override it. */
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[inputMask]',
})
export class InputHandlerWithMaskDirective implements OnInit {

  private readonly inputElement = inject<ElementRef<HTMLInputElement>>(ElementRef).nativeElement;

  private readonly ngControl = inject(NgControl, { optional: true });

  private readonly inputMaskDirective = inject(InputMaskDirective);

  private readonly destroyRef = inject(DestroyRef);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToInputEventChanges();
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (event.key === Key.Backspace || event.key === Key.Delete) {
      this.onInputFocus();
    }
  }

  private onInputFocus(): void {
    const start = this.inputElement.selectionStart ?? this.inputElement.value.length;
    const end = this.inputElement.selectionEnd ?? this.inputElement.value.length;

    const inputValue = this.inputMaskDirective.inputMaskPlugin?.unmaskedvalue().toString();

    if (inputValue === '') {
      this.setDefaultInputValue();
    } else if (inputValue != null) {
      this.ngControl?.control?.patchValue(inputValue);
    }

    this.inputElement.setSelectionRange(start, end);
  }

  // Resolves input mask bug: when selecting entire value and deleting it, user cannot enter value in the future.
  private setDefaultInputValue(): void {
    assertNonNull(this.inputMaskDirective.inputMaskPlugin, `Input mask isn't provided.`);
    this.ngControl?.control?.patchValue(this.inputMaskDirective.inputMaskPlugin.getemptymask());
  }

  private subscribeToInputEventChanges(): void {
    const focusEventSideEffects$ = fromEvent<FocusEvent>(this.inputElement, 'focus').pipe(
      tap(() => this.onInputFocus()),
    );
    const keyDownEventSideEffects$ = fromEvent<KeyboardEvent>(this.inputElement, 'keydown').pipe(
      tap(event => this.onKeyDown(event)),
    );

    combineLatest([focusEventSideEffects$, keyDownEventSideEffects$]).pipe(
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe();
  }
}
