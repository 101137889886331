import { inject } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import { firstValueFrom, map, tap } from 'rxjs';

import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';

import { UserSecretStorageService } from '../../user-secret-storage.service';

/** Abstract hub service. */
export abstract class AbstractHubService {

  /** Secret storage. */
  protected readonly secretStorage = inject(UserSecretStorageService);

  /** Current access token. */
  protected currentToken: string | null = null;

  /** Connection. */
  protected abstract readonly connection: HubConnection;

  /**
   * Create a websocket connection instance.
   * @param hubUrl Hub URL.
   */
  protected createHubConnection(hubUrl: string): HubConnection {
    const connection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(hubUrl, {
        transport: HttpTransportType.WebSockets,
        withCredentials: false,
        skipNegotiation: true,
        logger: LogLevel.Warning,
        accessTokenFactory: () => firstValueFrom(this.secretStorage.currentSecret$.pipe(
          filterNull(),
          map(({ token }) => token),
          tap(token => (this.currentToken = token)),
        )),
      })
      .build();

    return connection;
  }
}
