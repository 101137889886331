import { Injectable } from '@angular/core';

import { PaletteSharedFilters } from '@dartsales/common/core/models/filters/palette-shared-filters';

import { IMapperToDto } from '../mappers';
import { PaletteSharedFiltersDto } from '../dto/filters/palette-shared-filters.dto';

/** Mapper for palette shared filters. */
@Injectable({ providedIn: 'root' })
export class PaletteSharedFiltersMapper implements IMapperToDto<PaletteSharedFiltersDto, PaletteSharedFilters> {
  /** @inheritdoc */
  public toDto(data: PaletteSharedFilters): PaletteSharedFiltersDto {
    return {
      OrganizationId: data.organizationId,
      NameContains: data.name,
      DescriptionContains: data.description,
      HasTags: data.defaultTags?.concat(data.tags ?? []),
      ExcludeOwnerOrganizationIds: data.excludeOrganizationIds,
      AnyFieldContains: data.anyField,
      SuppliedBy: data.suppliers,
    };
  }
}
