import { ModuleSettingsLocalStorage } from './module-settings';

/** Subcontractor module to save in local storage. */
export class SubcontractorModuleLocalStorage {

  /** Whether subcontractor calculator is expanded. */
  public readonly isExpandCalculator: boolean;

  /** Lump sum pricing section. */
  public readonly lumpSumPricing: ModuleSettingsLocalStorage;

  /** Unit pricing section. */
  public readonly unitPricing: ModuleSettingsLocalStorage;

  public constructor(data: SubcontractorModuleLocalStorageInitArgs) {
    this.isExpandCalculator = data.isExpandCalculator;
    this.lumpSumPricing = data.lumpSumPricing;
    this.unitPricing = data.unitPricing;
  }
}

type SubcontractorModuleLocalStorageInitArgs = SubcontractorModuleLocalStorage;
