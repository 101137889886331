import { Sort as MatSort } from '@angular/material/sort';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { Sort } from '@dartsales/common/core/models/list-utilities/sort';

/**
 * Convert material Sort model to internal Sort model.
 * @param matSort Material Sort.
 */
export function matSortToInternalSort(matSort: MatSort): Sort | undefined {
  if (!matSort.direction) {
    return undefined;
  }

  return new Sort({
    field: matSort.active,
    direction: SortDirection.parseMatSortDirection(matSort.direction),
  });
}
