import { PointsListTotalsSystem } from './system-totals/points-list-totals-system';

/** PointsList module properties. */
export class PointsListModuleProperties {

  /** Total number of systems used in the points list. */
  public readonly systemsCount: number;

  /** Total number of parts used in the points list. */
  public readonly pointsCount: number;

  /** Cost of unit pricing from in the subcontractor module. */
  public readonly subcontractorCost: number;

  /** The total price of all self provided parts in the material module. */
  public readonly materialCost: number;

  /** The total price of all self-install and self-wired plus all other custom tasks in the labor module. */
  public readonly laborCost: number;

  /** Total labor hours. */
  public readonly laborHours: number;

  /** Direct Cost. Sum of subcontractor, material and labor costs. */
  public readonly directCost: number;

  /** System totals items. */
  public readonly systemTotals: readonly PointsListTotalsSystem[];

  public constructor(data: PointsListModulePropertiesInitArgs) {
    this.systemsCount = data.systemsCount;
    this.pointsCount = data.pointsCount;
    this.subcontractorCost = data.subcontractorCost;
    this.materialCost = data.materialCost;
    this.laborCost = data.laborCost;
    this.laborHours = data.laborHours;
    this.directCost = data.directCost;
    this.systemTotals = data.systemTotals;
  }
}

type PointsListModulePropertiesInitArgs = PointsListModuleProperties;
