import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, first, map, shareReplay, tap } from 'rxjs';

import { StorageService } from '@dartsales/common/core/services/storage.service';
import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';

import { ExpansionActions } from '../../shared/components/expansion-actions/expansion-actions.component';

/** Row expansion storage key. */
export const ROW_EXPANSION_STORAGE_BASE_KEY = 'row-expansion';

const EXPANDED_ROW_TYPES_STORAGE_KEY = `${ROW_EXPANSION_STORAGE_BASE_KEY}_expanded-row-types`;

/** Abstract service for rows expansion. */
@Injectable()
export abstract class AbstractRowsExpansionService<TRowType> {

  private readonly storageService = inject(StorageService);

  /** Expanded row types. */
  protected readonly expandedRowTypes$: BehaviorSubject<readonly TRowType[]>;

  public constructor(
    private readonly name: string,
    private readonly allRows: readonly TRowType[],
    defaultRows: readonly TRowType[] = [],
  ) {
    this.expandedRowTypes$ = new BehaviorSubject<readonly TRowType[]>(defaultRows);
    this.loadSavedState();
  }

  /** Expansion actions. */
  public readonly abstract expansionActions: ExpansionActions;

  /** Storage key. */
  private get storageKey(): string {
    return `${EXPANDED_ROW_TYPES_STORAGE_KEY}_${this.name}`;
  }

  /**
   * Expand row and collapse all nested rows.
   * @param row Row type.
   */
  public expandRow(row: TRowType): void {
    const rowIndex = this.allRows.indexOf(row);
    this.expandRows(this.allRows.slice(0, rowIndex + 1));
    this.collapseRows(this.allRows.slice(rowIndex + 1));
  }

  /**
   * Collapse row and all nested rows.
   * @param row Row type.
   */
  public collapseRow(row: TRowType): void {
    const rowIndex = this.allRows.length - this.allRows.indexOf(row) - 1;
    this.collapseRows(this.allRows.slice(rowIndex));
  }

  /**
   * Check if row is expanded.
   * @param row Row.
   */
  protected isRowExpanded(row: TRowType): Observable<boolean> {
    return this.expandedRowTypes$.pipe(
      map(expandedRows => expandedRows.includes(row)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Expand rows.
   * @param rowTypesToExpand Row types to collapse.
   */
  protected expandRows(rowTypesToExpand: readonly TRowType[]): void {
    const uniquesRowTypesToExpand = [...new Set(this.expandedRowTypes$.value.concat(rowTypesToExpand))];
    this.expandedRowTypes$.next(uniquesRowTypesToExpand);
    this.storageService.save(this.storageKey, uniquesRowTypesToExpand)
      .pipe(first())
      .subscribe();
  }

  /**
   * Collapse rows.
   * @param rowTypesToCollapse Row types to collapse.
   */
  protected collapseRows(rowTypesToCollapse: readonly TRowType[]): void {
    const rowTypesToExpand = this.expandedRowTypes$.value.filter(expandedRow => !rowTypesToCollapse.includes(expandedRow));
    this.expandedRowTypes$.next(rowTypesToExpand);
    this.storageService.save(this.storageKey, rowTypesToExpand)
      .pipe(first())
      .subscribe();
  }

  private loadSavedState(): void {
    this.storageService.get<readonly TRowType[]>(this.storageKey)
      .pipe(
        first(),
        filterNull(),
        tap(expandedRowTypes => this.expandedRowTypes$.next(expandedRowTypes)),
      )
      .subscribe();
  }
}
