import { Module } from '../../module';

import { SubcontractorModuleData } from './subcontractor-module-data';
import { SubcontractorModuleProperties } from './subcontractor-module-properties';

/** Subcontractor module data. */
export class SubcontractorModule extends Module<SubcontractorModuleProperties, SubcontractorModuleData> {

  public constructor(data: SubcontractorModuleInitArgs) {
    super(data);
  }
}

type SubcontractorModuleInitArgs = SubcontractorModule;
