import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { PagedListMapper } from '../mappers/paged-list.mapper';
import { PagedList } from '../../models/list-utilities/paged-list';
import { PagedListDto } from '../mappers/dto/paged-list.dto';
import { CreateChangeOrder, EditChangeOrder, ChangeOrderId, DetailedChangeOrder, PreviewChangeOrder } from '../../models/estimate/change-order/change-order';
import { ChangeOrderMapper } from '../mappers/estimate/change-order.mapper';
import { DetailedChangeOrderDto, PreviewChangeOrderDto } from '../mappers/dto/estimate/change-order/change-order.dto';
import { FetchListOptions } from '../../models/list-utilities/fetch-list-options';
import { Project } from '../../models/project/project';
import { FetchListOptionsParamsMapper } from '../mappers/http-params.mapper';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { CustomColumn, CustomColumnEditData } from '../../models/estimate/custom-column';
import { CustomColumnDto } from '../mappers/dto/estimate/custom-column.dto';
import { CustomColumnMapper } from '../mappers/estimate/custom-column.mapper';
import { FinalEstimate } from '../../models/estimate/final-estimate/final-estimate';

import { AppUrlsConfig } from './app-urls.config';

/** Change orders api service. */
@Injectable({
  providedIn: 'root',
})
export class ChangeOrdersApiService {

  private readonly paramsMapper = inject(FetchListOptionsParamsMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly listMapper = inject(PagedListMapper);

  private readonly changeOrderMapper = inject(ChangeOrderMapper);

  private readonly customColumnMapper = inject(CustomColumnMapper);

  /**
   * Get a list of change orders estimates.
   * @param id Project ID.
   * @param options Fetch options.
   * We do not use project ID as param (we don't pass filter mapper) that we use simple number instead filter object for FetchListOptions.
   */
  public getList(id: Project['id'], options?: FetchListOptions<Project['id']>): Observable<PagedList<PreviewChangeOrder>> {
    const params = options ? this.paramsMapper.toDto(options) : undefined;
    return this.http.get<PagedListDto<PreviewChangeOrderDto>>(this.apiUrls.changeOrdersApi.getList(id), { params }).pipe(
      map(page => this.listMapper.fromDto(
        page,
        { fromDto: dto => this.changeOrderMapper.fromPreviewDto(dto) },
        options?.pagination,
      )),
    );
  }

  /**
   * Obtain a change order by its ID.
   * @param id Change order ID.
   */
  public getById(id: ChangeOrderId): Observable<DetailedChangeOrder> {
    return this.http.get<DetailedChangeOrderDto>(this.apiUrls.changeOrdersApi.getById(id)).pipe(
      map(dto => this.changeOrderMapper.fromDetailedDto(dto)),
    );
  }

  /**
   * Create a new change order.
   * @param id Final estimate ID.
   * @param data Data for change order creation.
   */
  public create(id: FinalEstimate['id'], data: CreateChangeOrder): Observable<ChangeOrderId> {
    return this.http.post<DetailedChangeOrderDto>(
      this.apiUrls.changeOrdersApi.create(id),
      this.changeOrderMapper.toCreateDto(data),
    ).pipe(
      map(({ id: changeOrderId }) => changeOrderId),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        dto => this.changeOrderMapper.validationErrorFromCreateDto(dto),
      ),
    );
  }

  /**
   * Update change order.
   * @param id Change order ID.
   * @param data Updated change order data.
   */
  public update(id: ChangeOrderId, data: EditChangeOrder): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.changeOrdersApi.update(id),
      this.changeOrderMapper.toDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.changeOrderMapper,
      ),
    );
  }

  /**
   * Get change orders custom columns.
   * @param estimateId Base estimate ID.
   */
  public getChangeOrderCustomColumns(estimateId: Project['id']): Observable<CustomColumn[]> {
    return this.http.get<CustomColumnDto[]>(this.apiUrls.baseEstimateApi.getChangeOrderCustomColumns(estimateId)).pipe(
      map(dto => dto.map(item => this.customColumnMapper.fromDto(item))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create change orders custom column.
   * @param estimateId Base estimate ID.
   * @param data Change order custom column.
   */
  public createChangeOrderCustomColumn(estimateId: Project['id'], data: CustomColumnEditData): Observable<CustomColumn['id']> {
    const editDto = this.customColumnMapper.toDto(data);

    return this.http.post<CustomColumn['id']>(this.apiUrls.baseEstimateApi.createChangeOrderCustomColumn(estimateId), editDto).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.customColumnMapper,
      ),
    );
  }

  /**
   * Update change orders custom column.
   * @param estimateId Base estimate ID.
   * @param columnId Change order custom column ID.
   * @param data Change order custom column.
   */
  public updateChangeOrderCustomColumn(
    estimateId: Project['id'],
    columnId: CustomColumn['id'],
    data: CustomColumnEditData,
  ): Observable<CustomColumn['id']> {
    const editDto = this.customColumnMapper.toDto(data);

    return this.http.put<CustomColumn['id']>(
      this.apiUrls.baseEstimateApi.updateChangeOrderCustomColumn(estimateId, columnId),
      editDto,
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.customColumnMapper,
      ),
    );
  }

  /**
   * Delete change orders custom column.
   * @param estimateId Base estimate ID.
   * @param columnId Custom column ID.
   */
  public deleteChangeOrderCustomColumns(estimateId: Project['id'], columnId: CustomColumn['id']): Observable<void> {
    return this.http.delete<void>(this.apiUrls.baseEstimateApi.deleteChangeOrderCustomColumns(estimateId, columnId)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete a change order by its ID.
   * @param id ID.
   */
  public deleteById(id: ChangeOrderId): Observable<void> {
    return this.http.delete<void>(this.apiUrls.changeOrdersApi.delete(id));
  }
}
