import { AMOUNT_ROUND_FRACTION_DIGITS } from './constants';
import { roundToFixed } from './rounds';
import { toFiniteNumber } from './to-finite-number';

/**
 * Find the percentage of a number.
 * @param originalNumber Original number.
 * @param percent Required percentage.
 * @returns Part of the number according to the desired percentage.
 */
export function calculateFractionByPercent(originalNumber: number, percent: number): number {
  return roundToFixed(originalNumber * percent, AMOUNT_ROUND_FRACTION_DIGITS);
}

/**
 * What percentage is a fraction of the original number.
 * @param originalNumber Original number.
 * @param fraction Part of original number.
 * @returns Percentage corresponding to fraction.
 */
export function calculatePercentByFraction(originalNumber: number, fraction: number): number {
  const percent = fraction / originalNumber;
  return toFiniteNumber(percent);
}
