/** Labor task hours. */
export class LaborTaskHours {

  /** Task ID. */
  public readonly taskId: number;

  /** Hours. */
  public readonly hours: number;

  public constructor(data: LaborTaskHoursInitArgs) {
    this.taskId = data.taskId;
    this.hours = data.hours;
  }
}

type LaborTaskHoursInitArgs = LaborTaskHours;
