import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';

import { CalculationOverridableBasePricing, OverridableBasePricing } from '../../pricing/overridable-base-pricing';
import { OverridableMargin } from '../../estimate/modules/margin';
import { AmountPercent } from '../../estimate/amount-percent';

import { DomainBasePricingCalculator } from './domain-base-pricing-calculator';
import { DomainContingencyCalculator } from './domain-contingency-calculator';
import { DomainMarginCalculator } from './domain-margin-calculator';
import { DomainSellPriceCalculator } from './domain-estimate-sell-price-calculator';
import { DomainEscalationCalculator } from './domain-escalation-calculator';

/**
 * Domain overridable base pricing calculator.
 * Contain utility functions for any calculation of overridable base pricing values.
 **/
export namespace DomainOverridableBasePricingCalculator {

  /**
   * Arguments for calculation values of BasePricing models.
   */
  export type CalculationArgs = {

    /** Object with original pricing values. */
    readonly originalPricing: CalculationOverridableBasePricing;
  } & DomainBasePricingCalculator.CalculationUnitsArgs;

  /**
   * Calculate all values of OverridableBasePricing models.
   * @param args Calculation arguments.
   * @returns New values for OverridableBasePricing model.
   */
  export function calculatePricing(args: CalculationArgs): OverridableBasePricing {
    const { originalPricing } = args;

    // Default calculation params.
    const contingencyUnits = args.contingencyUnits ?? AmountCalcUnits.DEFAULT;
    const marginUnits = args.marginUnits ?? AmountCalcUnits.DEFAULT;
    const marginType = args.marginType ?? MarginType.DEFAULT;
    const escalationUnits = args.escalationUnits ?? AmountCalcUnits.DEFAULT;

    const directCost = originalPricing.directCost.combinedValue;

    const contingency = DomainContingencyCalculator.calculateOverridableContingency({
      directCost,
      value: originalPricing.contingency,
      units: contingencyUnits,
    });

    const escalation = DomainEscalationCalculator.calculateOverridableEscalation({
      directCost,
      contingencyAmount: contingency.combinedValue.amount,
      escalationValue: originalPricing.escalation,
      escalationUnits,
    });

    const margin = DomainMarginCalculator.calculateOverridableMargin({
      directCost,
      contingencyAmount: contingency.combinedValue.amount,
      escalationAmount: escalation.combinedValue.amount,
      source: originalPricing.margin,
      marginParams: { marginType, units: marginUnits },
    });

    const sellPrice = DomainSellPriceCalculator.calculateOverridableSellPrice({
      directCost,
      contingencyAmount: contingency.combinedValue.amount,
      marginAmount: margin.grossMargin.combinedValue.amount,
      sellPrice: originalPricing.sellPrice,
      escalationAmount: escalation.combinedValue.amount,
    });

    return new OverridableBasePricing({
      directCost: originalPricing.directCost,
      contingency,
      margin,
      sellPrice,
      escalation,
      aggregatedWEFS: originalPricing.aggregatedWEFS,
    });
  }

  /*
    TODO (Pavel Z.) We use several approaches to transferring calculation parameters for different calculators, for example,
    we transfer values separately and units separately, and vice versa, we transfer values together with units; as part of
    the refactoring of calculators, we need to bring this to a single approach.
  */
  /**
   * Calculate summary pricing for list of BasePricing items.
   * @param items List of sub-items.
   * @param srcPricing Source pricing values.
   * @param units Units configuration for calculation values of pricing models.
   **/
  export function calculateItemsSummaryPricing(
    items: readonly OverridableBasePricing[],
    srcPricing?: CalculationOverridableBasePricing,
    units?: DomainBasePricingCalculator.CalculationUnitsArgs,
  ): OverridableBasePricing {
    const calculatedValues = DomainBasePricingCalculator.calculateItemsSummaryPricing(
      items.map(item => item.toBasePricing()),
    );

    return calculatePricing({
      originalPricing: new OverridableBasePricing({
        directCost: new Overridable({
          initial: calculatedValues.directCost,
          override: srcPricing?.directCost.override ?? null,
        }),
        contingency: new Overridable({
          initial: calculatedValues.contingency,
          override: srcPricing?.contingency.override ?? null,
        }),
        margin: OverridableMargin.fromValues({
          initial: {
            grossMargin: calculatedValues.margin.grossMargin.percent,
            markup: calculatedValues.margin.markup.percent,
            amount: calculatedValues.margin.grossMargin.amount,
          },
          override: srcPricing?.margin.grossMargin.override ?
            {
              grossMargin: srcPricing?.margin.grossMargin.override?.percent,
              markup: srcPricing?.margin.markup.override?.percent,
              amount: srcPricing?.margin.grossMargin.override?.amount,
            } :
            null,
        }),
        sellPrice: new Overridable({
          initial: calculatedValues.sellPrice,
          override: srcPricing?.sellPrice?.override ?? null,
        }),
        escalation: new Overridable({
          initial: calculatedValues.escalation,
          override: srcPricing?.escalation.override ?? null,
        }),
        aggregatedWEFS: srcPricing?.aggregatedWEFS ?? new Overridable({ initial: new AmountPercent() }),
      }),
      contingencyUnits: units?.contingencyUnits ?? AmountCalcUnits.DEFAULT,
      marginType: units?.marginType ?? MarginType.DEFAULT,
      marginUnits: units?.marginUnits ?? AmountCalcUnits.DEFAULT,
      escalationUnits: units?.escalationUnits ?? AmountCalcUnits.DEFAULT,
    });
  }
}
