import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CURRENCY_MASK } from '@dartsales/common/core/utils/constants';

import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '../../services/cell.provider';
import { TableCellPrefixDirective } from '../../../editable-table-cell/directives/table-cell-prefix.directive';

/** Masked number cell. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-masked-number-cell',
  templateUrl: './masked-number-cell.component.html',
  styleUrls: ['./masked-number-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...CELL_PROVIDERS],
})
export class MaskedNumberCellComponent extends AbstractCellFormControlComponent<number> implements OnInit {

  /** Placeholder. */
  @Input()
  public placeholder = '0';

  /** Input mask, currency mask is used by default. */
  @Input()
  public mask = CURRENCY_MASK;

  /** Whether value in view mode should be hidden. */
  @Input()
  public hideValue = false;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  /** Form control. */
  protected readonly formControl = inject(NonNullableFormBuilder).control<number>(0);

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    super.writeValue(value ?? 0);
    this.formControl.setValue(value ?? 0, { emitEvent: false });
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    } else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  /** @inheritdoc */
  public override ngOnInit(): void {
    super.ngOnInit();
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    ).subscribe(value => {
      this.controlValue = value;
    });
  }
}
