<input
  #input
  dartsalescCellEditorInput
  type="text"
  class="input ellipsis"
  [formControl]="formControl"
  [placeholder]="placeholder"
  [inputMask]="mask"
  [errorStateMatcher]="errorStateMatcher"
  (keydown.enter)="input.blur()"
/>
