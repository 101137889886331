import { InjectionToken, inject, Type, ClassProvider } from '@angular/core';
import { Observable } from 'rxjs';

import { Module } from '@dartsales/common/core/models/estimate/module';
import { LaborModuleProperties } from '@dartsales/common/core/models/estimate/modules/labor/labor-module-properties';
import { LaborTask } from '@dartsales/common/core/models/resources/labor-task';
import { LaborRoleHoursPricing } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-hours-pricing';
import { BatchEditLaborRoleHoursPricing } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-hours-pricing';
import { LaborModule } from '@dartsales/common/core/models/estimate/modules/labor/labor-module';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { MarginValues } from '@dartsales/common/core/models/estimate/modules/margin';
import { LaborRole } from '@dartsales/common/core/models/resources/labor-role';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';
import { ModuleParent } from '@dartsales/common/core/models/module-parent';

/** Labor module edit model. */
export type EditLaborModule = Partial<Module<Partial<LaborModuleProperties>, readonly LaborRoleHoursPricing[]>>;

/** Labor module service. */
export type LaborModuleService = {

  /** Is loading. */
  readonly isLoading$: Observable<boolean>;

  /** Parent ID. */
  readonly parentId$: Observable<number>;

  /** Labor module. */
  readonly module$: Observable<LaborModule>;

  /** Labor roles hours pricing. */
  readonly laborRolesHoursPricing$: Observable<readonly LaborRoleHoursPricing[]>;

  /** Labor tasks. */
  readonly laborTasks$: Observable<LaborTask[]>;

  /** Labor roles from resources. */
  readonly laborRoles$: Observable<LaborRole[]>;

  /** Labor module properties. */
  readonly properties$: Observable<LaborModuleProperties>;

  /** Module parent. */
  readonly moduleParent: ModuleParent;

  /**
   * Update contingency percent for all items.
   * @param percent Percent.
   */
  updateItemsContingencyPercent(
    percent: number,
  ): Observable<void>;

  /**
   * Update escalation percent for all items.
   * @param percent Percent.
   */
  updateItemsEscalationPercent(
    percent: number,
  ): Observable<void>;

  /**
   * Update margin percent for all items.
   * @param marginValues Margin values.
   * @param marginType Margin type.
   */
  updateItemsMarginPercent(marginValues: MarginValues, marginType: MarginType): Observable<void>;

  /**
   * Update labor role hours pricing.
   * @param laborRoleId Labor role ID.
   * @param updatedLaborRoleHoursPricing Updated labor role hours pricing.
   */
  updateLaborHoursPricings(
    items: readonly BatchEditLaborRoleHoursPricing[],
  ): Observable<void>;

  /**
   * Update module lock percents status.
   * @param properties Lockable percents with status.
   */
  updateModuleLockPercentsStatus(properties: Partial<ModuleLockablePercentsStatus>): Observable<void>;

  /**
   * Update role lock percents status.
   * @param itemId Labor module pricing ID.
   * @param properties Lockable percents with status.
   */
  updateRoleLockPercentsStatus(itemId: string, properties: Partial<ModuleLockablePercentsStatus>): Observable<void>;

  /**
   * Save module data.
   * @param saveRequest Save request.
   */
  saveItems<T>(saveRequest: (parentId: number) => Observable<T>): Observable<void>;

  /** Refresh module. */
  refreshModule(): void;
};

const LABOR_SERVICE_TOKEN = new InjectionToken<LaborModuleService>('Labor module service token');

/** Inject labor module service. */
export function injectLaborModuleService(): LaborModuleService {
  return inject(LABOR_SERVICE_TOKEN);
}

/**
 * Provide labor module service.
 * @param service Labor module service.
 */
export function provideLaborModuleService(service: Type<LaborModuleService>): ClassProvider {
  return {
    provide: LABOR_SERVICE_TOKEN,
    useClass: service,
  };
}
