import { Injectable, inject } from '@angular/core';

import { MaterialModuleProperties } from '@dartsales/common/core/models/estimate/modules/material/properties/material-module-properties';

import { IMapper } from '../../../mappers';
import { MaterialModulePropertiesDto } from '../../../dto/estimate/modules/material/material-module.dto';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ModulePropertiesMapper } from '../module-properties.mapper';

import { MaterialLockablePercentsStatusMapper } from './material-lockable-percents-status.mapper';

/** Material module properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class MaterialModulePropertiesMapper implements IMapper<MaterialModulePropertiesDto, MaterialModuleProperties> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(MaterialLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: MaterialModulePropertiesDto): MaterialModuleProperties {
    return new MaterialModuleProperties({
      ...this.propertiesMapper.fromDto(dto),
      standardMaterials: this.basePricingMapper.fromDto(dto.standardMaterials),
      nonStandardMaterials: this.basePricingMapper.fromDto(dto.nonStandardMaterials),
      warrantyPercent: dto.warrantyPercent,
      escalationPercent: dto.escalationPercent,
      freightPercent: dto.freightPercent,
      salesTaxPercent: dto.salesTaxPercent,
      grandTotalCost: dto.grandTotalCost,
      totalDirectCost: dto.totalDirectCost,
      quantity: dto.quantity,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
    });
  }

  /** @inheritdoc */
  public toDto(data: MaterialModuleProperties): MaterialModulePropertiesDto {
    return {
      ...this.propertiesMapper.toDto(data),
      standardMaterials: this.basePricingMapper.toDto(data.standardMaterials),
      nonStandardMaterials: this.basePricingMapper.toDto(data.nonStandardMaterials),
      warrantyPercent: data.warrantyPercent,
      escalationPercent: data.escalationPercent,
      freightPercent: data.freightPercent,
      salesTaxPercent: data.salesTaxPercent,
      totalDirectCost: data.getTotalDirectCost(),
      grandTotalCost: data.grandTotalCost,
      quantity: data.quantity,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
    };
  }
}
