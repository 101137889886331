import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';
import { calculateFractionByPercent, calculatePercentByFraction } from '@dartsales/common/core/utils/percents';

import { AmountPercent, AmountPercentValues } from '../../estimate/amount-percent';

import { DomainCostCalculator } from './domain-cost-calculator';

export namespace DomainEscalationCalculator {

  /** Domain escalation calculator params. */
  export type EscalationCalculatorParams = {

    /** Escalation value. */
    readonly escalationValue: AmountPercentValues;

    /** Escalation units. */
    readonly escalationUnits: AmountCalcUnits;

    /** Contingency amount. */
    readonly contingencyAmount: number;
  } & DomainCostCalculator.DirectCostParams;

  type CalculateEscalationCalculatorParams = StrictOmit<EscalationCalculatorParams, 'escalationValue'> & {

    /** Escalation value. */
    readonly escalationValue: Overridable<AmountPercentValues>;
  };

  /**
   * Calculate escalation.
   * @param args Arguments.
   */
  export function calculateEscalation({
    contingencyAmount,
    directCost,
    escalationUnits,
    escalationValue,
  }: EscalationCalculatorParams): AmountPercent {
    const { amount, percent } = escalationValue;
    const fraction = directCost + contingencyAmount;

    if (escalationUnits === AmountCalcUnits.Amount) {
      assertNonNull(amount, `Amount isn't provided to escalate calculation by Amount units.`);
      return new AmountPercent({
        amount,
        percent: calculatePercentByFraction(fraction, amount),
      });
    }

    assertNonNull(percent, `Percent isn't provided to escalate calculation by Percent units.`);
    return new AmountPercent({
      amount: calculateFractionByPercent(fraction, percent),
      percent,
    });
  }

  /**
   * Calculate contingency for overridable value.
   * @param args Arguments.
   */
  export function calculateOverridableEscalation({
    directCost,
    escalationValue,
    escalationUnits,
    contingencyAmount,
  }: CalculateEscalationCalculatorParams): Overridable<AmountPercent> {
    return new Overridable({
      initial: calculateEscalation({
        directCost,
        escalationValue: escalationValue.initial,
        escalationUnits,
        contingencyAmount,
      }),
      override: escalationValue.override !== null ? calculateEscalation({
        directCost,
        escalationValue: escalationValue.override,
        escalationUnits,
        contingencyAmount,
      }) : null,
    }) ;
  }
}
