import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { BulkUpdateOption } from '@dartsales/common/core/models/bulk-update-option';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';
import { createTrackByFunction } from '@dartsales/common/core/utils/trackby';

type ValuesWithConfirmation<T> = {
  readonly [Key in keyof T]: ConfirmedOverridable<T[Key]>
};

type Labels<T> = Partial<Readonly<Record<keyof T, string>>>;

type CustomTemplates<T> = Partial<Readonly<Record<keyof T, TemplateRef<unknown>>>>;

type KeyValuePair<T> = KeyValue<keyof T, ConfirmedOverridable<unknown>>;

/** Bulk update changes with confirmation cell component. */
@Component({
  selector: 'dartsalesw-bulk-update-changes-confirmation-cell',
  templateUrl: './bulk-update-changes-confirmation-cell.component.html',
  styleUrls: ['./bulk-update-changes-confirmation-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateChangesConfirmationCellComponent<TValue, TLabel extends TValue> {
  /** Is cell readonly. */
  @Input()
  public isReadonly = false;

  /** Values which require confirmation. */
  @Input({ required: true })
  public values: ValuesWithConfirmation<TValue> | null = null;

  /** Label. */
  @Input()
  public labels: Labels<TLabel> = {};

  /** Custom templates. */
  @Input()
  public customTemplates: CustomTemplates<TLabel> = {};

  /** Event for clicking on 'bulk update' button inside the cell. */
  @Output()
  public readonly bulkUpdateConfirm = new EventEmitter<ValuesWithConfirmation<TValue>>();

  /** Track by key. */
  protected readonly trackByKey = createTrackByFunction<KeyValuePair<ValuesWithConfirmation<TValue>>>('key');

  /** Key value pairs. */
  protected get keyValuePairs(): KeyValuePair<ValuesWithConfirmation<TValue>>[] {
    assertNonNull(this.values);
    return Object.entries<ConfirmedOverridable<unknown>>(this.values).map(([key, value]) => ({
      key: key as keyof TValue,
      value,
    }));
  }

  /** Whether bulk update name property is available.  */
  protected get hasUnconfirmedUpdates(): boolean {
    return ConfirmedOverridable.hasUnconfirmedOverride(this.keyValuePairs.map(item => item.value));
  }

  /**
   * Handle bulk update confirmation.
   * @param bulkUpdateOption Selected bulk update option.
   */
  protected onBulkUpdateConfirm(bulkUpdateOption: BulkUpdateOption): void {
    const result = this.keyValuePairs.reduce((acc, { key, value }) => {
      const updatedValue = ConfirmedOverridable.getUpdateValue(value, bulkUpdateOption);
      return {
        ...acc,
        [key]: updatedValue,
      };

    // There is no other way to provide type definition here.
    }, {} as ValuesWithConfirmation<TValue>);

    this.bulkUpdateConfirm.emit(result);
  }

}
