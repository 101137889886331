import { ProjectStatus } from '../../enums/project-status';

import { ContractingTeam } from './contracting-team';
import { CustomField } from './custom-field';
import { ProjectFloor } from './project-floor';
import { ProjectInfo, ProjectInfoEdit } from './project-info';

/** Project. */
export class Project {
  /** Id. */
  public readonly id: number;

  /** Project status. */
  public readonly status: ProjectStatus;

  /** Project info. */
  public readonly projectInfo: ProjectInfo;

  /** Floor info. */
  public readonly floorInfo: readonly ProjectFloor[];

  /** Contracting team. */
  public readonly contractingTeam: ContractingTeam;

  /** Custom info. */
  public readonly customInfo: readonly CustomField[];

  /**
   * Project createdAt.
   * "null" is possible if we get data from api that can't transformed to Date object.
   */
  public readonly createdAt: Date | null;

  /** Project updatedAt. */
  public readonly updatedAt: Date | null;

  /** Project createdBy id. */
  public readonly createdById: number;

  public constructor(data: ProjectInitArgs) {
    this.id = data.id;
    this.status = data.status;
    this.projectInfo = data.projectInfo;
    this.floorInfo = data.floorInfo;
    this.contractingTeam = data.contractingTeam;
    this.customInfo = data.customInfo;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.createdById = data.createdById;
  }
}

type ProjectInitArgs = Project;

export type ProjectEdit = Pick<Project,
  | 'floorInfo'
  | 'contractingTeam'
  | 'customInfo'
> & {

  /** Project info. */
  readonly projectInfo: ProjectInfoEdit;
};
