import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';
import { PERCENT_MASK, PERCENT_ROUND_FRACTION_DIGITS } from '@dartsales/common/core/utils/constants';
import { mapDecimalToPercent } from 'projects/web/src/app/features/shared/components/inputs/abstract-input';

import { CELL_PROVIDERS } from '../../services/cell.provider';
import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { TableCellPrefixDirective } from '../../../editable-table-cell/directives/table-cell-prefix.directive';

/** Percent number cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-percent-number-cell',
  templateUrl: './percent-number-cell.component.html',
  styleUrls: ['./percent-number-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CELL_PROVIDERS],
})
export class PercentNumberCellComponent extends AbstractCellFormControlComponent<number> implements OnInit {
  /** Whether value in view mode should be hidden. */
  @Input()
  public hideValue = false;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Percent mask. */
  protected readonly percentMask = PERCENT_MASK;

  /** Form control. */
  protected readonly formControl = this.fb.control(0);

  /** @inheritdoc */
  public override ngOnInit(): void {
    this.subscribeToControlChanges();
    super.ngOnInit();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    const percent = this.roundPercent(mapDecimalToPercent(value ?? 0));
    this.formControl.setValue(percent, { emitEvent: false });
    super.writeValue(value);
  }

  private roundPercent(value: number): number {
    return Number(value.toFixed(PERCENT_ROUND_FRACTION_DIGITS));
  }

  private subscribeToControlChanges(): void {
    this.formControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        const oldValue = this.controlValue ?? 0;
        const newPercentValue = this.roundPercent(value / 100);

        if (!compareRoundNumbers(oldValue, newPercentValue)) {
          this.controlValue = newPercentValue;
        }
      });
  }
}
