import { Injectable } from '@angular/core';

import { ComponentAttachment } from '@dartsales/common/core/models/components/component-attachment';
import { CreateComponentAttachment } from '@dartsales/common/core/models/components/create-component-attachment';
import { getMimeType } from 'projects/web/src/app/features/project-files/utils/get-mime-type';

import { ComponentAttachmentDto, CreateComponentAttachmentDto } from '../dto/components/attachment/component-attachment.dto';
import { IMapper } from '../mappers';

/** Component attachment property mapper. */
@Injectable({
  providedIn: 'root',
})
export class ComponentAttachmentMapper
implements
  IMapper<ComponentAttachmentDto, ComponentAttachment> {

  /** @inheritdoc */
  public fromDto(dto: ComponentAttachmentDto): ComponentAttachment {
    return new ComponentAttachment({
      id: dto.id,
      name: dto.name,
      fileName: dto.fileName,
      mimeType: dto.mimeType ?? getMimeType(dto.fileName ?? ''),
    });
  }

  /** @inheritdoc */
  public toDto(data: ComponentAttachment): ComponentAttachmentDto {
    return {
      id: data.id,
      name: data.name,
      fileName: data.fileName,
      mimeType: data.mimeType,
    };
  }

  /** @inheritdoc */
  public toCreateDto(data: CreateComponentAttachment): CreateComponentAttachmentDto {
    return {
      name: data.name,
      fileName: data.file?.name,
      mimeType: data.file?.type,
      size: data.file?.size,
    };
  }
}
