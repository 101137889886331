import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { filter, fromEvent, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';
import { ErrorStateMatcher } from '@angular/material/core';

import { CellEditorService } from '../services/cell-editor.service';
import { FocusParams } from '../services/cell-renderer.service';
import { TableRowEventsService } from '../../editable-table/services/table-row-events.service';
import { CellDirective } from '../directives/cell.directive';
import { CellEditorInputDirective } from '../directives/cell-editor-input.directive';

/**
 * Base class for Editor cell component.
 *
 * BaseCellEditorComponent is a part of cell workflow described in: AbstractCellFormControlComponent.
 * Its task is to render data editor component and set focus by focus params.
 *
 * Warning: Due to the design features, the component will not function properly if it's not destroyed after 'focusout' event.
 *
 * Works in conjunction with {@link CellEditorInputDirective} for set correct focus position.
 */
@UntilDestroy()
@Directive({
  host: {
    class: 'dartsalesc-cell_editor',
  },
})
export class BaseCellEditorComponent<T> extends CellDirective<T> implements OnInit {

  /** Error state matcher. */
  @Input({ required: true })
  public errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  /** Input element. */
  @ViewChild(CellEditorInputDirective)
  protected readonly inputElement?: CellEditorInputDirective;

  private readonly service = inject(CellEditorService);

  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  /** Document. */
  protected readonly document = inject(DOCUMENT);

  private readonly rowEventsService = inject(TableRowEventsService, { optional: true });

  /**
   * Set focus.
   * @param params Focus params.
   */
  protected setFocus(params: FocusParams | null): void {
    if (this.inputElement == null) {
      return;
    }

    this.rowEventsService?.triggerCellFocus();
    if (params != null) {
      this.inputElement.focusWithPosition(params);
      return;
    }

    this.inputElement.focus();
  }

  /**
   * Check is focus out.
   * @param _event Focus event.
   */
  protected checkIsFocusOut(_event: FocusEvent): boolean {
    // By default we allow any value.
    return true;
  }

  /** Value change. */
  @Output()
  public readonly valueChange = new EventEmitter<T>();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToFocusRequest();
    this.subscribeToBlur();
  }

  private subscribeToBlur(): void {
    const { nativeElement } = this.elementRef;
    fromEvent<FocusEvent>(nativeElement, 'focusout').pipe(
      filter(event => this.checkIsFocusOut(event)),
      map(event => !nativeElement.contains(event.relatedTarget as Node)),
      untilDestroyed(this),
    )
      .subscribe(() => {
        this.service.blur$.next();
        this.rowEventsService?.triggerCellBlur(this.inputElement?.tableRowElement ?? null);
      });
  }

  private subscribeToFocusRequest(): void {
    this.service.focusRequest$.pipe(
      untilDestroyed(this),
    ).subscribe(params => {
      this.setFocus(params);
    });
  }
}
