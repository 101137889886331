<input
  *ngIf="!useTextarea; else textareaInput"
  matInput
  type="text"
  [formControl]="formControl"
  [placeholder]="placeholder"
/>
<ng-template #textareaInput>
  <textarea
    matInput
    dartsalescAutoresizeTextarea
    class="textarea"
    [placeholder]="placeholder"
    [formControl]="formControl"
  ></textarea>
</ng-template>
<button
  *ngIf="isResetAvailable"
  #resetButton
  matSuffix
  mat-icon-button
  class="reset-button"
  type="button"
  title="Reset value"
  (click)="onResetButtonClick($event)"
>
  <mat-icon>refresh</mat-icon>
</button>
