import { Injectable } from '@angular/core';

import { LaborTaskMinutes } from '@dartsales/common/core/models/estimate/modules/points-list/labor-task-minutes';

import { IMapper } from '../../mappers';
import { LaborTaskMinutesDto } from '../../dto/components/detailed-organization-component/labor-task-minutes.dto';

/**  Labor task minutes mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborTaskMinutesMapper implements IMapper<LaborTaskMinutesDto, LaborTaskMinutes> {

  /** @inheritdoc */
  public fromDto(dto: LaborTaskMinutesDto): LaborTaskMinutes {
    return new LaborTaskMinutes({
      minutes: dto.minutes,
      taskId: dto.taskId,
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborTaskMinutes): LaborTaskMinutesDto {
    return {
      minutes: data.minutes,
      taskId: data.taskId,
    };
  }
}
