import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { ValidationErrorCode } from '../models/errors/validation-error-code';
import { ValuesRange } from '../models/values-range';

import { PickByValueType } from './types/pick-by-value-type';
import { AnyObject } from './types/any-object';
import { removeAppErrorFromControl } from './remove-app-error-from-control';
import { Overridable } from './types/overridable';

/** Email valid format. */
export const EMAIL_VALID_FORMAT = /^[\w-]+(?:[a-zA-Z0-9._%+-])*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;

/** Password valid format. */
export const PASSWORD_VALID_FORMAT = /^(?=\D*\d)(?=.*?[A-Z]).*[\W_].*$/;

export namespace AppValidators {

  /**
   * Checks whether the current control matches another.
   * @param controlName Control name to check matching with.
   * @param controlTitle Control title to display for a user.
   */
  export function matchControl(
    controlName: string,
    controlTitle = controlName,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.parent &&
        control.parent.get(controlName)?.value !== control.value
      ) {
        return {
          [ValidationErrorCode.Match]: {
            controlName,
            controlTitle,
          },
        };
      }
      return null;
    };
  }

  /**
   * Create validation error from a message.
   * @param message Message to create an error from.
   */
  export function buildAppError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }

  /**
   * Checks whether the email control don't matches pattern.
   * @param control Control.
   */
  export function email(control: AbstractControl<string | null>): ValidationErrors | null {
    if (control.value?.match(EMAIL_VALID_FORMAT) === null) {
      return {
        [ValidationErrorCode.Email]: true,
      };
    }
    return null;
  }

  /**
   * Checks whether the text control does not consist only of whitespaces.
   * @param control Control.
   */
  export function notWhiteSpacesOnly(control: AbstractControl<string | null>): ValidationErrors | null {
    if (control.value?.trim().length === 0) {
      return {
        [ValidationErrorCode.Required]: true,
      };
    }
    return null;
  }

  /** Checks whether the password control don't matches pattern. */
  export function passwordPatternControl(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value.match(PASSWORD_VALID_FORMAT) === null) {
        return {
          [ValidationErrorCode.PasswordPattern]: true,
        };
      }
      return null;
    };
  }

  /**
   * Validator for applying validations to overridable value of form control.
   * @param validators Validators.
   */
  export function overridableValueValidator(validators: readonly ValidatorFn[]): ValidatorFn {
    const validator = Validators.compose([...validators]);
    return (control: AbstractControl<Overridable<unknown>>): ValidationErrors | null => {
      const { initial, override } = control.value;
      if (override === null && initial != null) {
        return null;
      }

      const formControl = new FormControl(override);
      return validator != null ? validator(formControl) : null;
    };
  }

  /**
   * Validator for applying validations to overridable value of create form control.
   * @param validators Validators.
   */
  export function combinedValueValidator(validators: readonly ValidatorFn[]): ValidatorFn {
    const validator = Validators.compose([...validators]);
    return (control: AbstractControl<Overridable<unknown>>): ValidationErrors | null => {
      const { combinedValue } = control.value;

      const formControl = new FormControl(combinedValue);
      return validator != null ? validator(formControl) : null;
    };
  }

  /** Value range validator. */
  export function valueRangeRequiredValidator(): ValidatorFn {
    return (control: AbstractControl<ValuesRange<unknown>>): ValidationErrors | null => {
      const { start, end } = control.value;
      const errorFields: string[] = [];
      if (start == null) {
        errorFields.push('start');
      }

      if (end == null) {
        errorFields.push('end');
      }

      if (errorFields.length) {
        return {
          [ValidationErrorCode.ValuesRangeError]: {
            message: 'Both values should be provided.',
            errorFields,
          },
        };
      }
      return null;
    };
  }

  /** Min/max range validator. */
  export function minMaxValidator(): ValidatorFn {
    return (form: AbstractControl<ValuesRange<number>>): ValidationErrors | null => {
      const { start, end } = form.value;

      if (start > end) {
        return {
          [ValidationErrorCode.ValuesRangeError]: {
            message: 'Min value should be less max value.',
          },
        };
      }
      if (end < start) {
        return {
          [ValidationErrorCode.ValuesRangeError]: {
            message: 'Max value should be greater min value.',
          },
        };
      }
      return null;
    };
  }

  /**
   * Checks whether the control includes only number or punctuation symbol.
   * @param control Control.
   */
  export function numberAndSymbolValidator(control: AbstractControl<string>): ValidationErrors | null {
    if (control.value === '') {
      return null;
    }

    const VALID_FORMAT = /^[~`!@#$%^&*()_+=[\]{}|;':",./<>?0-9]+$/;
    if (control.value.match(VALID_FORMAT) === null) {
      return {
        [ValidationErrorCode.AppError]: {
          message: 'This field can contain only numbers and special characters.',
        },
      };
    }

    return null;
  }

  /**
   * Checks whether string filed in form control value is not empty.
   * @param key Key.
   */
  export function nonEmptyStringField<T>(key: keyof PickByValueType<T, string>): ValidatorFn {
    return (control: AbstractControl<{ [k in keyof PickByValueType<T, string>]: string }>): ValidationErrors | null => {
      const value = control.value?.[key].trim();
      if (value == null || value === '') {
        return {
          [ValidationErrorCode.Required]: true,
        };
      }

      return null;
    };
  }

  /**
   * Check whether control is not empty and length is less than maxLength.
   * Combination of several validators.
   * @param maxLength Max length.
   */
  export function nonEmptyWithMaxLength(maxLength: number): ValidatorFn {
    return Validators.compose([
      Validators.required,
      AppValidators.notWhiteSpacesOnly,
      Validators.maxLength(maxLength),
    ]) ?? (() => null);
  }

  /**
   * Checks whether all form controls inside form array are unique.
   * This validator can be used only inside `FormArray` validator.
   * @example
   * ```ts
   * this.fb.array(array, AppValidators.formArrayUniqueFormControlValues(form => form.controls.name));
   * ```
   * @param selectControlFn Function to select from control in form group.
   */
  export function formArrayUniqueFormControlValues<T extends AnyObject>(
    selectControlFn: (formGroup: FormGroup<T>) => FormControl<string>,
  ): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors | null => {
      const array = abstractControl as FormArray<FormGroup<T>>;

      const fieldNameControls = array.controls
        .map(control => selectControlFn(control))
        .filter(control => control.value !== '');

      fieldNameControls.forEach(fieldNameControl => {
        const isValueUniq = !fieldNameControls.some(
          control =>
            fieldNameControl.value.toLowerCase() === control.value.toLowerCase() &&
            control !== fieldNameControl,
        );

        if (isValueUniq) {
          removeAppErrorFromControl(fieldNameControl, false);
          return;
        }

        fieldNameControl.setErrors({
          [ValidationErrorCode.AppError]: {
            message: 'This field should be unique',
          },
        });
        fieldNameControl.markAsTouched();

      });
      return null;
    };
  }
}
