import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Welcome layout component. */
@Component({
  selector: 'dartsalesw-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeLayoutComponent {}
