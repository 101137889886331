<mat-form-field class="no-label">
  <dartsalesc-chips-list-with-suggested
    *ngIf="listManager.pagePagination$ | async as pagination"
    placeholder="Add Tags"
    suggestedText="Suggested Tags:"
    [optionsList]="options$ | async"
    [formControl]="formControl"
    [isLoading]="listManager.isLoading$ | async"
    (searchChange)="onSearchChange($event)"
    (goToNextPage)="listManager.nextPage()"
  />
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
