import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/** Directive to enable fixed scroll for autocomplete.  */
@UntilDestroy()
@Directive({
  selector: 'mat-autocomplete[dartsalescFixedScroll]',
})
export class AutocompleteFixedScrollDirective implements OnInit {
  private readonly wrapperCssClassName = 'no-scroll-wrapper';

  private get scrollableParent(): HTMLElement | null {
    return this.elementRef.nativeElement.closest('[cdkscrollable]');
  }

  public constructor(
    @Inject(DOCUMENT)
    private readonly document: Document,
    private readonly matAutocomplete: MatAutocomplete,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.matAutocomplete.opened.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      const div = this.document.createElement('div');
      div.classList.add('cdk-overlay-backdrop', this.wrapperCssClassName);
      this.scrollableParent?.prepend(div);
    });

    this.matAutocomplete.closed.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.scrollableParent?.querySelector(`.${this.wrapperCssClassName}`)?.remove();
    });
  }
}
