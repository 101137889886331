import { Validators } from '@angular/forms';

import { BidLead } from '@dartsales/common/core/enums/bid-lead';
import { BidMarket } from '@dartsales/common/core/enums/bid-market';
import { ProjectStatus } from '@dartsales/common/core/enums/project-status';
import { EnumFilterMultiSelectInputComponent, EnumFilterMultiSelectInputData } from '@dartsales/common/shared/components/search-filters/enum-filter-multi-select-input/enum-filter-multi-select-input.component';
import { EnumFilterSingleSelectInputComponent } from '@dartsales/common/shared/components/search-filters/enum-filter-single-select-input/enum-filter-single-select-input.component';
import { OrganizationNameFilterInputComponent } from '@dartsales/common/shared/components/search-filters/organization-name-filter-input/organization-name-filter-input.component';
import { StringArrayFilterInputComponent } from '@dartsales/common/shared/components/search-filters/string-array-filter-input/string-array-filter-input.component';
import { PartType } from '@dartsales/common/core/enums/part-type';
import { PartPerformerType } from '@dartsales/common/core/enums/part-performer-type';
import { PartDiagramType } from '@dartsales/common/core/enums/part-diagram-type';
import { TagFilterInputComponent } from '@dartsales/common/shared/components/search-filters/tag-filter-input/tag-filter-input.component';
import { BidCategoryFilterInputComponent } from '@dartsales/common/shared/components/search-project-filters/bid-category-filter-input/bid-category-filter-input.component';
import { NumberArrayFilterInputComponent } from '@dartsales/common/shared/components/search-filters/number-array-filter-input/number-array-filter-input.component';

import { FilterBidCategory } from '../../resources/bid-category';

import { FilterOperator } from './filter-operator';
import { FilterOperatorMapper } from './filter-operator-mapper';
import { FilterOperatorType } from './filter-operator-type';

const stringListSelectMapper = new FilterOperatorMapper<string[]>({
  getParamsArray: value => value,
  parseValueFromString: params => params,
});

const bidCategoryListSelectMapper = new FilterOperatorMapper<FilterBidCategory[]>({
  getParamsArray: bidCategories => bidCategories.map(bidCategory => String(bidCategory.id)),
  getReadableParamsArray: bidCategories => bidCategories.map(bidCategory => bidCategory.name),
  parseValueFromString(_bidCategories) {
    // TODO: Implement mapping to string and from string to object.
    console.warn('Restoring objects from strings is not implemented.');
    return [];
  },
});

export const stringListSelect = [
  new FilterOperator<string[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: StringArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
  new FilterOperator<string[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: StringArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
];

const numberListSelectMapper = new FilterOperatorMapper<number[]>({
  getParamsArray: value => value.map(item => String(item)),
  parseValueFromString: params => params.map(item => Number(item)),
});

export const numberListSelect = [
  new FilterOperator<number[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: NumberArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: numberListSelectMapper,
  }),
  new FilterOperator<number[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: NumberArrayFilterInputComponent,
    validators: [Validators.required],
    mapper: numberListSelectMapper,
  }),
];

const projectStatusSelectPayload: EnumFilterMultiSelectInputData = {
  options: ProjectStatus.toArray().map(item => ({
    value: item,
    label: ProjectStatus.toReadable(item),
  })),
};

const projectStatusSelectMapper = new FilterOperatorMapper<ProjectStatus[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => ProjectStatus.toReadable(v)),
  parseValueFromString: params => params as ProjectStatus[],
});

export const projectStatusSelect = [
  new FilterOperator({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<ProjectStatus>,
    componentPayload: projectStatusSelectPayload,
    validators: [Validators.required],
    mapper: projectStatusSelectMapper,
  }),
  new FilterOperator<ProjectStatus[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<ProjectStatus>,
    componentPayload: projectStatusSelectPayload,
    validators: [Validators.required],
    mapper: projectStatusSelectMapper,
  }),
];

const bidMarketsSelectPayload: EnumFilterMultiSelectInputData = {
  options: BidMarket.toArray().map(item => ({
    value: item,
    label: BidMarket.toReadable(item),
  })),
};

const bidMarketSelectMapper = new FilterOperatorMapper<BidMarket | null>({
  getParamsArray: value => [value ?? ''],
  getReadableParamsArray: value => value ? [BidMarket.toReadable(value)] : [],
  parseValueFromString: params => params[0] as BidMarket,
});

export const bidMarketSelect = [
  new FilterOperator({
    type: FilterOperatorType.Contains,
    label: 'Contains',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<BidMarket | null>,
    componentPayload: bidMarketsSelectPayload,
    validators: [Validators.required],
    mapper: bidMarketSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotContain,
    label: 'Doesn\'t Contain',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<BidMarket | null>,
    componentPayload: bidMarketsSelectPayload,
    validators: [Validators.required],
    mapper: bidMarketSelectMapper,
  }),
];

const bidLeadsSelectPayload: EnumFilterMultiSelectInputData = {
  options: BidLead.toArray().map(item => ({
    value: item,
    label: BidLead.toReadable(item),
  })),
};

const bidLeadsSelectMapper = new FilterOperatorMapper<BidLead[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => BidLead.toReadable(v)),
  parseValueFromString: params => params as BidLead[],
});

export const bidLeadsSelect = [
  new FilterOperator({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<BidLead>,
    componentPayload: bidLeadsSelectPayload,
    validators: [Validators.required],
    mapper: bidLeadsSelectMapper,
  }),
  new FilterOperator<BidLead[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<BidLead>,
    componentPayload: bidLeadsSelectPayload,
    validators: [Validators.required],
    mapper: bidLeadsSelectMapper,
  }),
];

export const bidCategoriesSelect = [
  new FilterOperator({
    type: FilterOperatorType.Equals,
    label: 'Equal',
    defaultValue: [],
    component: BidCategoryFilterInputComponent,
    validators: [Validators.required],
    mapper: bidCategoryListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotEqual,
    label: 'Not equal',
    defaultValue: [],
    component: BidCategoryFilterInputComponent,
    validators: [Validators.required],
    mapper: bidCategoryListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.Contains,
    label: 'Contains',
    defaultValue: [],
    component: BidCategoryFilterInputComponent,
    validators: [Validators.required],
    mapper: bidCategoryListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotContain,
    label: 'Doesn\'t Contain',
    defaultValue: [],
    component: BidCategoryFilterInputComponent,
    validators: [Validators.required],
    mapper: bidCategoryListSelectMapper,
  }),
];

export const organizationNameSelect = [
  new FilterOperator({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: OrganizationNameFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: OrganizationNameFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
];

const partTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartType.toArray().map(item => ({
    value: item,
    label: PartType.toReadable(item),
  })),
};

const partTypesSelectMapper = new FilterOperatorMapper<PartType[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => PartType.toReadable(v)),
  parseValueFromString: params => params as PartType[],
});

export const partTypesSelect = [
  new FilterOperator<PartType[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartType>,
    componentPayload: partTypesSelectPayload,
    validators: [Validators.required],
    mapper: partTypesSelectMapper,
  }),
  new FilterOperator<PartType[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartType>,
    componentPayload: partTypesSelectPayload,
    validators: [Validators.required],
    mapper: partTypesSelectMapper,
  }),
];

const partPerformerTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartPerformerType.toArray().map(item => ({
    value: item,
    label: PartPerformerType.toReadable(item),
  })),
};

const partPerformerTypesSelectMapper = new FilterOperatorMapper<PartPerformerType[]>({
  getParamsArray: value => value,
  getReadableParamsArray: values => values.map(v => PartPerformerType.toReadable(v)),
  parseValueFromString: params => params as PartPerformerType[],
});

export const partPerformerTypesSelect = [
  new FilterOperator<PartPerformerType[]>({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartPerformerType>,
    componentPayload: partPerformerTypesSelectPayload,
    validators: [Validators.required],
    mapper: partPerformerTypesSelectMapper,
  }),
  new FilterOperator<PartPerformerType[]>({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: EnumFilterMultiSelectInputComponent<PartPerformerType>,
    componentPayload: partPerformerTypesSelectPayload,
    validators: [Validators.required],
    mapper: partPerformerTypesSelectMapper,
  }),
];

const partDiagramTypesSelectPayload: EnumFilterMultiSelectInputData = {
  options: PartDiagramType.toArray().map(item => ({
    value: item,
    label: PartDiagramType.toReadable(item),
  })),
};

const partDiagramTypesSelectMapper = new FilterOperatorMapper<PartDiagramType | null>({
  getParamsArray: value => [value ?? ''],
  getReadableParamsArray: value => value ? [PartDiagramType.toReadable(value)] : [],
  parseValueFromString: params => params[0] as PartDiagramType,
});

export const partDiagramTypesSelect = [
  new FilterOperator({
    type: FilterOperatorType.Contains,
    label: 'Contains',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<PartDiagramType | null>,
    componentPayload: partDiagramTypesSelectPayload,
    validators: [Validators.required],
    mapper: partDiagramTypesSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotContain,
    label: 'Doesn\'t Contain',
    defaultValue: null,
    component: EnumFilterSingleSelectInputComponent<PartDiagramType | null>,
    componentPayload: partDiagramTypesSelectPayload,
    validators: [Validators.required],
    mapper: partDiagramTypesSelectMapper,
  }),
];

export const tagSelect = [
  new FilterOperator({
    type: FilterOperatorType.IsOneOf,
    label: 'Is one of',
    defaultValue: [],
    component: TagFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.IsNotOneOf,
    label: 'Is not one of',
    defaultValue: [],
    component: TagFilterInputComponent,
    validators: [Validators.required],
    mapper: stringListSelectMapper,
  }),
];
