<dartsalesc-editable-table-cell
  #cell
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  <ng-container
    *ngIf="maskedNumberControl.enabled"
    dartsalescTableCellEditMode
  >
    <input
      #inputElement
      dartsalescTableCellInput
      type="text"
      class="masked-number-edit-mode"
      [placeholder]="placeholder"
      [formControl]="maskedNumberControl"
      [inputMask]="mask"
      [readonly]="isReadonly"
      [errorStateMatcher]="errorStateMatcher"
      (keydown.enter)="inputElement.blur()"
    />
  </ng-container>

  <div
    *ngIf="hideValue"
    dartsalescTableCellViewMode
  >
    <!-- Empty. -->
  </div>

  <div
    *ngIf="!hideValue"
    dartsalescTableCellViewMode
    class="masked-number-view-mode"
  >
    <span
      *ngIf="maskedNumberControl.value === ''; else maskedValue"
      class="masked-number-view-mode__content"
      [title]="placeholder"
    >
      {{ placeholder }}
    </span>
    <ng-template #maskedValue>
      <span
        *ngrxLet="
          mask
            ? (maskedNumberControl.value | inputMask: mask)
            : maskedNumberControl.value as value
        "
        class="masked-number-view-mode__content"
        [class.masked-number-view-mode__content_placeholder]="
          showInitialValueAsPlaceholder && maskedNumberControl.pristine
        "
        [title]="value"
      >
        {{ value }}
      </span>
    </ng-template>
  </div>
  <ng-container
    *ngIf="cellPrefix"
    dartsalescTableCellPrefix
  >
    <ng-content select="[dartsalescTableCellPrefix]" />
  </ng-container>
</dartsalesc-editable-table-cell>
