import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ModuleType } from '@dartsales/common/core/enums/module-type';

/** Visible modules select. */
@Component({
  selector: 'dartsalesw-visible-module-select',
  templateUrl: './visible-module-select.component.html',
  styleUrls: ['./visible-module-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibleModuleSelectComponent {

  /** Visible modules. */
  @Input()
  public visibleModules: readonly ModuleType[] = [];

  /** Visible modules change. */
  @Output()
  public readonly visibleModulesChange = new EventEmitter<readonly ModuleType[]>();

  /** Module type. */
  protected readonly moduleType = ModuleType;

  /** Visible modules text. */
  protected get visibleModulesText(): string {
    if (this.visibleModules.length === ModuleType.getBaseModules().length) {
      return 'Show all modules';
    }
    return this.visibleModules.map(module => ModuleType.toReadable(module)).join(', ');
  }
}
