/** Domain cost calculator. */
export namespace DomainCostCalculator {

  // TODO (Pavel Z.) We can rework this to native types of directCost and Contingency, to not pass just primitive value.
  /** Direct cost params. */
  export type DirectCostParams = {

    /** Direct cost. */
    readonly directCost: number;
  };

  /** Total direct cost calculation params. */
  export type TotalDirectCostParams = {

    /** Contingency amount. */
    readonly contingencyAmount?: number;

    /** Escalation amount. */
    readonly escalationAmount?: number;
  } & DirectCostParams;

  type QuantityCostParams = {

    /** Unit cost. */
    readonly unitCost: number;

    /** Quantity. */
    readonly quantity: number;
  };

  /**
   * Calculates total direct cost
   * https://docs.google.com/spreadsheets/d/1VzxlBUTNTsKc2SRX-DNj0MGar7zejjTqUkTlMQooJso/edit#gid=0.
   * @param param0 Params.
   */
  export function calculateTotalDirectCost({ directCost, contingencyAmount, escalationAmount }: TotalDirectCostParams): number {
    return directCost + (contingencyAmount ?? 0) + (escalationAmount ?? 0);
  }

  /**
   * Calculates quantity cost.
   * @param param0 Params.
   */
  export function calculateQuantityCost({
    unitCost,
    quantity,
  }: QuantityCostParams): number {
    return unitCost * quantity;
  }
}
