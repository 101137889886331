import { ResizableColumnsWidth } from '@dartsales/common/core/utils/catalog-table/catalog-table-columns-resize-manager';
import { PointsListTabInitialValues } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-table/services/points-list-initial-values.service';
import { PointsListExpandedColumnsState } from 'projects/web/src/app/features/project-dashboard/pages/systems-page/components/points-list-table/services/points-list-table-columns.service';

import { PalettePartFilters } from '../../filters/palette-part-filters';
import { PaletteSystemFilters } from '../../filters/palette-system-filters';
import { PaletteSharedFilters } from '../../filters/palette-shared-filters';
import { Sort } from '../../list-utilities/sort';
import { ShortPointsListTab } from '../modules/points-list/tab/short-points-list-tab';

/** Point list to save in local storage. */
export class PointListLocalStorage {

  /** Whether tab is active.'null' is needed for initial rendering when we don’t yet know the ID of the active tab. */
  public readonly activeTabId: ShortPointsListTab['id'] | null;

  /** Collapse rows by tabs. */
  public readonly collapseRowsByTabs: Readonly<Record<ShortPointsListTab['id'], Partial<PointsListTabInitialValues>>>;

  /** Columns width by tabs. */
  public readonly columnsWidthByTabs: Readonly<Record<ShortPointsListTab['id'], ResizableColumnsWidth>>;

  /** Expand columns. */
  public readonly expandColumns: Readonly<PointsListExpandedColumnsState>;

  /** Catalog section. */
  public readonly catalog: {

    /** Catalog width. */
    readonly width: number;

    /** Whether catalog is expanded. */
    readonly isExpanded: boolean;

    /** System tab. */
    readonly systemTab: CatalogTab<PaletteSystemFilters>;

    /** Part tab. */
    readonly partTab: CatalogTab<PalettePartFilters>;

    /** Project tab. */
    readonly projectTab: CatalogTab<PaletteSharedFilters>;
  };

  public constructor(data: PointListLocalStorageInitArgs) {
    this.activeTabId = data.activeTabId;
    this.collapseRowsByTabs = data.collapseRowsByTabs;
    this.columnsWidthByTabs = data.columnsWidthByTabs;
    this.expandColumns = data.expandColumns;
    this.catalog = data.catalog;
  }
}

type PointListLocalStorageInitArgs = PointListLocalStorage;

/** Catalog tab. */
export type CatalogTab<TFilter extends PaletteSharedFilters> = {

  /** Columns width. */
  readonly columnsWidth: ResizableColumnsWidth;

  /** Filters. */
  readonly filterParams: CatalogTabFilters<TFilter>;
};

/** Catalog tab filters. */
export type CatalogTabFilters<TFilter extends PaletteSharedFilters> = {

  /** Filter. */
  readonly filter: TFilter;

  /** Sort. */
  readonly sort: readonly Sort[];
};
