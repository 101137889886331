import { enumToArray } from '../utils/enum-to-array';

/** Bid market. */
export enum BidMarket {
  AgricultureFoodService = 'AgricultureAndFoodService',
  College = 'College',
  Commercial = 'Commercial',
  CorrectionsFacilities = 'CorrectionsFacilities',
  CriticalInfrastructure = 'CriticalInfrastructure',
  DataCenter = 'DataCenter',
  Education = 'Education',
  Financial = 'Financial',
  FoodServices = 'FoodServices',
  Government = 'Government',
  Healthcare = 'Healthcare',
  HigherEd = 'HigherEd',
  Hospitality = 'Hospitality',
  Housing = 'Housing',
  Industrial = 'Industrial',
  Lab = 'Lab',
  LabOffice = 'LabOrOffice',
  Logistics = 'Logistics',
  Military = 'Military',
  Mining = 'Mining',
  NativeCorporation = 'NativeCorporation',
  NonProfit = 'NonProfit',
  Office = 'Office',
  OilGas = 'OilAndGas',
  Other = 'Other',
  Ports = 'Ports',
  PublicSafety = 'PublicSafety',
  ReligiousAssembly = 'ReligiousAssembly',
  Residential = 'Residential',
  ResortsEntertainment = 'ResortsAndEntertainment',
  Retail = 'Retail',
  Utilities = 'Utilities',
}

/** Namespace related to BidMarket. */
export namespace BidMarket {
  const TO_TITLE_MAP: Record<BidMarket, string> = {
    [BidMarket.AgricultureFoodService]: 'Agriculture & Food Service',
    [BidMarket.College]: 'College',
    [BidMarket.Commercial]: 'Commercial',
    [BidMarket.CorrectionsFacilities]: 'Corrections Facilities',
    [BidMarket.CriticalInfrastructure]: 'Critical Infrastructure',
    [BidMarket.DataCenter]: 'Data Center',
    [BidMarket.Education]: 'Education',
    [BidMarket.Financial]: 'Financial',
    [BidMarket.FoodServices]: 'Food Services',
    [BidMarket.Government]: 'Government',
    [BidMarket.Healthcare]: 'Healthcare',
    [BidMarket.HigherEd]: 'Higher Ed',
    [BidMarket.Hospitality]: 'Hospitality',
    [BidMarket.Housing]: 'Housing',
    [BidMarket.Industrial]: 'Industrial',
    [BidMarket.Lab]: 'Lab',
    [BidMarket.LabOffice]: 'Lab/Office',
    [BidMarket.Logistics]: 'Logistics',
    [BidMarket.Military]: 'Military',
    [BidMarket.Mining]: 'Mining',
    [BidMarket.NativeCorporation]: 'Native Corporation',
    [BidMarket.NonProfit]: 'Non-Profit',
    [BidMarket.Office]: 'Office',
    [BidMarket.OilGas]: 'Oil & Gas',
    [BidMarket.Other]: 'Other',
    [BidMarket.Ports]: 'Ports',
    [BidMarket.PublicSafety]: 'Public Safety',
    [BidMarket.ReligiousAssembly]: 'Religious Assembly',
    [BidMarket.Residential]: 'Residential',
    [BidMarket.ResortsEntertainment]: 'Resorts & Entertainment',
    [BidMarket.Retail]: 'Retail',
    [BidMarket.Utilities]: 'Utilities',
  };

  /**
   * Converts a certain bid market to readable title.
   * @param value Bid market.
   */
  export function toReadable(value: BidMarket): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all bid categories. */
  export function toArray(): BidMarket[] {
    return enumToArray(BidMarket);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: BidMarket): BidMarket {
    return item;
  }
}
