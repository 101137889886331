import { ConfirmedOverridable } from '../../confirmed-overridable';

import { EstimatePartStackedPortChild } from './estimate-part-stacked-port-child';

/** Abstract estimate part stacked port. */
abstract class AbstractPartStackedPort {
  /** UUID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Active port UUID. */
  public abstract readonly activePortId: string | ConfirmedOverridable<string>;

  /** Children ports. */
  public readonly ports: readonly EstimatePartStackedPortChild[];

  public constructor(data: AbstractPartStackedPortInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.ports = data.ports;
  }
}

type AbstractPartStackedPortInitArgs = AbstractPartStackedPort;

/** Overridable estimate part stacked port. */
export class OverridablePartStackedPort extends AbstractPartStackedPort {

  /** @inheritdoc */
  public readonly activePortId: ConfirmedOverridable<string>;

  public constructor(data: OverridablePartStackedPortInitArgs) {
    super(data);
    this.activePortId = data.activePortId;
  }
}

type OverridablePartStackedPortInitArgs = OverridablePartStackedPort;

/** Estimate part stacked port. */
export class PartStackedPort extends AbstractPartStackedPort {

  /** @inheritdoc */
  public readonly activePortId: string;

  public constructor(data: PartStackedPortInitArgs) {
    super(data);
    this.activePortId = data.activePortId;
  }
}

type PartStackedPortInitArgs = PartStackedPort;
