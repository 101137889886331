import { Injectable } from '@angular/core';

import { ProjectFloor } from '@dartsales/common/core/models/project/project-floor';

import { FloorInfoDto } from '../dto/project/floor-info.dto';
import { IMapperFromDto, IMapperToDto } from '../mappers';

/** Project floor entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class ProjectFloorMapper implements
  IMapperFromDto<FloorInfoDto, ProjectFloor>,
  IMapperToDto<FloorInfoDto, ProjectFloor> {
  /** @inheritdoc */
  public fromDto(dto: FloorInfoDto): ProjectFloor {
    return new ProjectFloor({
      id: dto.id,
      floorName: dto.number,
      nickname: dto.alias,
      approximateSquare: dto.approximateSqFt,
    });
  }

  /** @inheritdoc */
  public toDto(data: ProjectFloor): FloorInfoDto {
    return {
      id: data.id,
      number: data.floorName,
      alias: data.nickname,
      approximateSqFt: data.approximateSquare,
    };
  }
}
