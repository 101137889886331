import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

/** Overridable labor task minutes. */
export class OverridableLaborTaskMinutes {

  /** Task ID. */
  public readonly taskId: number;

  /** Minutes. */
  public readonly minutes: ConfirmedOverridable<number>;

  public constructor(data: OverridableLaborTaskMinutesInitArgs) {
    this.taskId = data.taskId;
    this.minutes = data.minutes;
  }
}

type OverridableLaborTaskMinutesInitArgs = OverridableLaborTaskMinutes;
