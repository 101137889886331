import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { BulkUpdateOption } from '@dartsales/common/core/models/bulk-update-option';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';

/** Bulk update remove confirmation table cell component. */
@Component({
  selector: 'dartsalesw-bulk-update-remove-confirmation-cell',
  templateUrl: './bulk-update-remove-confirmation-cell.component.html',
  styleUrls: ['./bulk-update-remove-confirmation-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateRemoveConfirmationCellComponent {
  /** Is cell readonly. */
  @Input()
  public isReadonly = false;

  /** Is item removed. */
  @Input({ required: true })
  public isRemoved: ConfirmedOverridable<boolean> | null = null;

  /** Text. */
  @Input()
  public text = 'This item was deleted. Do you want to apply this change?';

  /** Event for clicking on 'bulk update' button inside the cell. */
  @Output()
  public readonly bulkUpdateConfirm = new EventEmitter<ConfirmedOverridable<boolean>>();

  /**
   * Handle bulk update confirmation.
   * @param bulkUpdateOption Selected bulk update option.
   */
  protected onBulkUpdateConfirm(bulkUpdateOption: BulkUpdateOption): void {
    assertNonNull(this.isRemoved);
    const updatedValue = ConfirmedOverridable.getUpdateValue(this.isRemoved, bulkUpdateOption);
    this.bulkUpdateConfirm.emit(updatedValue);
  }
}
