import { Injectable, inject } from '@angular/core';

import { OverridableLaborTaskMinutes } from '@dartsales/common/core/models/estimate/modules/points-list/overridable-labor-task-minutes';

import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { IMapper } from '../../../mappers';
import { OverridableLaborTaskMinutesDto } from '../../../dto/estimate/modules/points-list/part/overridable-labor-task-minutes.dto';

/** Overridable labor task minutes mapper. */
@Injectable({
  providedIn: 'root',
})
export class OverridableLaborTaskMinutesMapper implements
  IMapper<OverridableLaborTaskMinutesDto, OverridableLaborTaskMinutes> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: OverridableLaborTaskMinutesDto): OverridableLaborTaskMinutes {
    return new OverridableLaborTaskMinutes({
      taskId: dto.taskId,
      minutes: this.confirmedOverridableMapper.fromDto(dto.minutes),
    });
  }

  /** @inheritdoc */
  public toDto(data: OverridableLaborTaskMinutes): OverridableLaborTaskMinutesDto {
    return {
      taskId: data.taskId,
      minutes: this.confirmedOverridableMapper.toDto(data.minutes),
    };
  }
}
