import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AbstractTableCellBulkUpdateComponent } from '../abstract-table-cell-bulk-update/abstract-table-cell-bulk-update.component';

/** Table cell bulk update remove component. */
@Component({
  selector: 'dartsalesc-table-cell-bulk-update-remove',
  templateUrl: './table-cell-bulk-update-remove.component.html',
  styleUrls: [
    '../abstract-table-cell-bulk-update/abstract-table-cell-bulk-update.component.css',
    './table-cell-bulk-update-remove.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellBulkUpdateRemoveComponent extends AbstractTableCellBulkUpdateComponent {

  /** Is remove confirmed. */
  @Input()
  public isRemoveConfirmed = true;

  /** Is bulk update available. */
  protected get isBulkUpdateAvailable(): boolean {
    return !this.isRemoveConfirmed;
  }

  /** Get tooltip text. */
  protected getTooltipText(): string {
    return this.tooltipText ?? `Item was removed. Click here to apply changes.`;
  }
}
