import { OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';

import { TermSummary } from './estimate-services/term-summary';

/** Term map. */
export class TermMap {

  /** Term number. */
  public termId: TermSummary['id'];

  /** Alternate estimate ID. */
  public alternateId: OverviewAlternateEstimate['id'];

  public constructor(data: TermMapInitArgs) {
    this.termId = data.termId;
    this.alternateId = data.alternateId;
  }
}

type TermMapInitArgs = TermMap;
