import { Injectable } from '@angular/core';

import { PartsGroupCommonProperties } from '@dartsales/common/core/models/estimate/modules/material/data/standard-material-item';

import { IMapper } from '../../../mappers';
import { PartsGroupCommonPropertiesDto } from '../../../dto/estimate/modules/material/data/standard-material-items-group.dto';

/** Parts group common properties entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PartsGroupCommonPropertiesMapper implements IMapper<PartsGroupCommonPropertiesDto, PartsGroupCommonProperties | null> {

  /** @inheritdoc */
  public fromDto(dto: PartsGroupCommonPropertiesDto): PartsGroupCommonProperties {
    return new PartsGroupCommonProperties({
      name: dto.name,
      description: dto.description,
      manufacturer: dto.manufacturer,
      modelNumber: dto.modelNumber,
      tags: dto.tags,
      unitCost: dto.unitCost,
      supplier: dto.supplier ?? '',
    });
  }

  /** @inheritdoc */
  public toDto(data: PartsGroupCommonProperties): PartsGroupCommonPropertiesDto {
    return {
      name: data.name,
      description: data.description,
      manufacturer: data.manufacturer,
      modelNumber: data.modelNumber,
      tags: data.tags,
      unitCost: data.unitCost,
      supplier: data.supplier,
    };
  }
}
