import { inject, Injectable } from '@angular/core';

import { FinalEstimateServiceCategory, FinalEstimateServiceByServices, FinalEstimateServiceSubCategory, FinalEstimateServiceService } from '@dartsales/common/core/models/estimate/modules/service-totals/final-estimate-service-by-services';

import { FinalEstimateServiceCategoryDto, FinalEstimateServiceByServiceSummariesDto, FinalEstimateServiceServiceDto, FinalEstimateServiceSubCategoryDto } from '../../../../dto/estimate/final-estimate/modules/service-totals/final-estimate-service-by-service-summaries.dto';
import { IMapperFromDto } from '../../../../mappers';

import { FinalEstimateServiceTotalsTermMapper } from './service-totals-term.mapper';

/** Final estimate service summaries by services mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateServiceByServicesMapper
implements IMapperFromDto<FinalEstimateServiceByServiceSummariesDto, FinalEstimateServiceByServices> {

  private readonly termMapper = inject(FinalEstimateServiceTotalsTermMapper);

  /** @inheritdoc */
  public fromDto(dto: FinalEstimateServiceByServiceSummariesDto): FinalEstimateServiceByServices {
    return new FinalEstimateServiceByServices({
      categories: dto.categories.map(category => this.mapServiceTotalsModuleCategoryFromDto(category)) ?? [],
      customCategory: dto.customCategory ? this.mapServiceTotalsModuleSubcategoryFromDto(dto.customCategory) : null,
      grandTotals: this.termMapper.termContainerFromDto(dto.grandTotals),
    });
  }

  private mapServiceTotalsModuleCategoryFromDto(
    category: FinalEstimateServiceCategoryDto,
  ): FinalEstimateServiceCategory {
    return new FinalEstimateServiceCategory({
      ...this.termMapper.termContainerFromDto(category),
      id: category.id,
      name: category.name,
      subCategories: category.subCategories?.map(
        subcategory => this.mapServiceTotalsModuleSubcategoryFromDto(subcategory),
      ) ?? [],
    });
  }

  private mapServiceTotalsModuleSubcategoryFromDto(
    subcategory: FinalEstimateServiceSubCategoryDto,
  ): FinalEstimateServiceSubCategory {
    return new FinalEstimateServiceSubCategory({
      ...this.termMapper.termContainerFromDto(subcategory),
      id: subcategory.id ?? null,
      name: subcategory.name,
      services: subcategory.services.map(service => this.mapFinalEstimateService(service)),
    });
  }

  private mapFinalEstimateService(service: FinalEstimateServiceServiceDto): FinalEstimateServiceService {
    return new FinalEstimateServiceService({
      ...this.termMapper.termContainerFromDto(service),
      id: service.id,
      name: service.name,
    });
  }
}
