import { EstimateSummary } from '../../estimate/estimate-summary';
import { Estimate } from '../../estimate/estimate';
import { TermProperties } from '../../estimate-services/term-properties';
import { BasePricing } from '../../pricing/base-pricing';

import { DomainBasePricingCalculator } from './domain-base-pricing-calculator';
import { DomainOverridableBasePricingCalculator } from './domain-overridable-base-pricing-calculator';

/** Domain estimate totals calculator. */
export namespace DomainEstimateTotalsCalculator {

  /**
   * Calculate totals by estimate.
   * @param modulesTotals Modules totals.
   * @param terms Terms total.
   */
  export function calculateTotals(
    modulesTotals: Estimate['modulesTotals'],
    terms: Estimate['terms'],
  ): EstimateSummary {
    const termsPricing = terms.map(term => new TermProperties({
      ...term.properties,
      escalation: term.properties.aggregatedWEFS,
    }));
    const termSummary = DomainOverridableBasePricingCalculator.calculateItemsSummaryPricing(termsPricing);

    const totalPricing = DomainBasePricingCalculator.calculateItemsSummaryPricing([
      termSummary.toBasePricing(),
      new BasePricing({
        ...modulesTotals.toBasePricing(),
        escalation: modulesTotals.aggregatedWEFS.combinedValue,
      }),
    ]);

    return totalPricing;
  }
}
