import { Injectable } from '@angular/core';

import { PaletteComponent } from '@dartsales/common/core/models/components/palette/palette-component';

import { CreateServicePartFromPaletteDto } from '../dto/estimate-services/create-service-part-from-palette.dto';

/** Estimate service part mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateServicePartMapper {
  /**
   * To create DTO from palette part or symbol.
   * @param componentIds Data.
   */
  public toCreateFromPaletteDto(componentIds: readonly PaletteComponent['id'][]): CreateServicePartFromPaletteDto {
    return {
      components: componentIds.map(componentId => ({
        paletteComponentId: componentId,
      })),
    };
  }
}
