import { enumToArray } from '../utils/enum-to-array';

/** Part type. */
export enum PartType {
  Controller = 'Controller',
  InputOutput = 'InputOutput',
}

/** Namespace related to PartType. */
export namespace PartType {
  const TO_TITLE_MAP: Readonly<Record<PartType, string>> = {
    [PartType.Controller]: 'Controller',
    [PartType.InputOutput]: 'Input/Output',
  };

  /**
   * Converts a certain part type to readable title.
   * @param value Part type.
   */
  export function toReadable(value: PartType): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all part types. */
  export function toArray(): PartType[] {
    return enumToArray(PartType);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: PartType): PartType {
    return item;
  }
}
