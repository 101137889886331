import { FilterData } from '../../filter-data';
import { isEmpty, textContains, textEquals } from '../../shared-filter-operators';
import { stringListSelect } from '../../shared-filter-select-operators';

/** Team field for filter. */
export enum TeamField {
  ProjectManager = 'teamProjectManager',
  ProjectEngineer = 'teamProjectEngineer',
  ApprovedBy = 'teamApprovedBy',
  ProjectSalesperson = 'teamProjectSalesperson',
  DrawnBy = 'teamDrawnBy',
  ProjectTechnician = 'teamProjectTechnician',
}

export namespace TeamField {
  export const GROUP_NAME = 'Team';

  const projectManager = {
    type: TeamField.ProjectManager,
    label: 'Project Manager',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const projectEngineer = {
    type: TeamField.ProjectEngineer,
    label: 'Project Engineer',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const teamApprovedBy = {
    type: TeamField.ApprovedBy,
    label: 'Approved by',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const projectSalesperson = {
    type: TeamField.ProjectSalesperson,
    label: 'Project Salesperson',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const teamDrawnBy = {
    type: TeamField.DrawnBy,
    label: 'Drawn by',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const projectTechnician = {
    type: TeamField.ProjectTechnician,
    label: 'Project Technician',
    operators: [
      ...textContains,
      ...textEquals,
      ...stringListSelect,
      ...isEmpty,
    ] as const,
  } satisfies FilterData<TeamField>;

  const ALL_TEAM_INFO_FIELDS = [
    projectManager,
    projectEngineer,
    teamApprovedBy,
    projectSalesperson,
    teamDrawnBy,
    projectTechnician,
  ] as const;

  /** Get all fields. */
  export function getAllFields(): typeof ALL_TEAM_INFO_FIELDS {
    return ALL_TEAM_INFO_FIELDS;
  }
}
