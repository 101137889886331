<dartsalesc-table-cell-bulk-update
  *ngIf="!isReadonly"
  tooltipText="Bulk update is available. Click here to apply changes."
  [isUpdateConfirmed]="!hasUnconfirmedUpdates"
  (confirmUpdate)="onBulkUpdateConfirm($event)"
>
  <ng-container *ngFor="let item of keyValuePairs; trackBy: trackByKey">
    <ng-container *ngIf="!item.value.isConfirmed">
      <ng-container
        [ngTemplateOutlet]="customTemplates[item.key] ?? defaultTemplate"
        [ngTemplateOutletContext]="{ $implicit: item.value }"
      />
      <ng-template #defaultTemplate>
        <dartsalesw-confirmed-overridable-changes
          [label]="labels[item.key] ?? 'Value'"
          [value]="item.value"
        />
      </ng-template>
    </ng-container>
  </ng-container>
</dartsalesc-table-cell-bulk-update>
