import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, ReplaySubject, shareReplay, startWith } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NonNullableFormBuilder } from '@angular/forms';

import { SortDirection } from '@dartsales/common/core/enums/sort-direction';
import { PaginationData } from '@dartsales/common/core/models/list-utilities/pagination-data';
import { InfiniteScrollListStrategy, ListManager } from '@dartsales/common/core/utils/list-manager';
import { TagFilters } from '@dartsales/common/core/models/filters/tag-filters';
import { TagsApiService } from '@dartsales/common/core/services/api/tags-api.service';
import { MatFormFieldValueAccessor, matFormFieldControlProviderFor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { ChipsListWithSuggestedComponent } from '@dartsales/common/shared/components/chips-list-with-suggested/chips-list-with-suggested.component';

/** Tags select with chips. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => TagsSelectComponent)],
})
export class TagsSelectComponent extends MatFormFieldValueAccessor<string[]> implements OnInit {

  /** Organization ID. */
  @Input()
  public set organizationId(value: number | null) {
    this.organizationId$.next(value);
  }

  @ViewChild(ChipsListWithSuggestedComponent)
  private readonly innerComponent?: ChipsListWithSuggestedComponent;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly tagsApiService = inject(TagsApiService);

  private readonly organizationId$ = new ReplaySubject<number | null>(1);

  private readonly search$ = new BehaviorSubject<string>('');

  /** Form control. */
  protected readonly formControl = this.fb.control<string[]>([]);

  /** Select options list manager. */
  protected readonly listManager = this.createOptionsListManager();

  /** Select options list. */
  protected readonly options$ = this.createOptionsStream();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: string[] | null): void {
    this.formControl.setValue(value ? [...value] : []);
    super.writeValue(value);
  }

  /** @inheritdoc */
  protected focus(): void {
    this.innerComponent?.onContainerClick();
  }

  /**
   * Handle search value change.
   * @param searchValue Search value.
   */
  protected onSearchChange(searchValue: string): void {
    this.search$.next(searchValue);
  }

  private createOptionsListManager(): ListManager<string, TagFilters> {
    return new ListManager<string, TagFilters>({
      strategy: new InfiniteScrollListStrategy(),
      pagination: new PaginationData({
        pageSize: 20,
      }),
      filter$: this.createFilters(),
      initialSort: [{ field: 'name', direction: SortDirection.ASC }],
      request: options => this.tagsApiService.getTagsList(options),
    });
  }

  private createFilters(): Observable<TagFilters> {
    return combineLatest([
      this.organizationId$,
      this.search$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([organizationId, search]) => ({
        organizationId: organizationId ?? undefined,
        nameContains: search,
      })),
    );
  }

  private createOptionsStream(): Observable<string[]> {
    return combineLatest([
      this.listManager.items$.pipe(startWith([])),
      listenControlChanges(this.formControl),
    ]).pipe(
      map(([tags, selectedTags]) => tags
        .filter(tag => !selectedTags.includes(tag))),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private updateControl(newValue: readonly string[]): void {
    this.controlValue = [...newValue];
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.formControl).pipe(untilDestroyed(this))
      .subscribe(value => {
        this.updateControl([...value]);
      });
  }
}
