/** Palette supplier. */
export class PaletteSupplier {

  /** Name. */
  public readonly name: string;

  public constructor(data: PaletteSupplierInitArgs) {
    this.name = data.name;
  }
}

type PaletteSupplierInitArgs = PaletteSupplier;
