import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { createMask } from '@ngneat/input-mask';

import { AMOUNT_ROUND_FRACTION_DIGITS, CURRENCY_MASK } from '@dartsales/common/core/utils/constants';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { compareRoundNumbers, roundToFixed } from '@dartsales/common/core/utils/rounds';

import { AbstractInputComponent, AmountInputHelpers } from '../abstract-input';

/** Amount input for Overridable numbers. */
@Component({
  selector: 'dartsalesw-overridable-amount-input',
  templateUrl: './overridable-amount-input.component.html',
  styleUrls: ['./overridable-amount-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => OverridableAmountInputComponent)],
})
export class OverridableAmountInputComponent extends AbstractInputComponent<Overridable<number>> implements OnInit {

  @ViewChild('resetButton', { read: ElementRef })
  private readonly resetButtonElement?: ElementRef<HTMLButtonElement>;

  /** Mask options. */
  protected override readonly maskOptions = createMask({ ...CURRENCY_MASK });

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      !this.disabled &&
      this.controlValue?.override !== null
    );
  }

  /** @inheritdoc */
  protected override fillForm(value: Overridable<number> | null): void {
    if (!compareRoundNumbers(value?.combinedValue, this.controlValue?.combinedValue)) {
      super.fillForm(value);
    }
  }

  /** @inheritdoc */
  protected checkIsInputValuesChanged(
    prevValue: Overridable<number>,
    valueFromInput: Overridable<number>,
  ): boolean {
    return this.checkIsInputValuesChangedWithRound(prevValue, valueFromInput, AMOUNT_ROUND_FRACTION_DIGITS);
  }

  /**
   * Check is input value changed with round.
   * @param prevValue Initial value.
   * @param valueFromInput Value for compare.
   * @param compareFractionDigits Fraction digits for round.
   */
  protected checkIsInputValuesChangedWithRound(
    prevValue: Overridable<number>,
    valueFromInput: Overridable<number>,
    compareFractionDigits: number,
  ): boolean {
    const roundedPrevInitialValue = roundToFixed(prevValue.initial, compareFractionDigits);
    const roundedPrevOverrideValue = roundToFixed(prevValue.override ?? 0, compareFractionDigits);

    if (valueFromInput.override == null) {
      return false;
    }

    const roundedOverrideValueFromInput = roundToFixed(valueFromInput.override, compareFractionDigits);
    return (
      roundedPrevInitialValue !== roundedOverrideValueFromInput &&
      roundedPrevOverrideValue !== roundedOverrideValueFromInput
    );
  }

  /** @inheritdoc */
  protected override valueToInput(value: Overridable<number> | null): string {
    return value != null ? AmountInputHelpers.numberToInput(value.combinedValue) : '';
  }

  /** @inheritdoc */
  protected override valueFromInput(valueVm: string): Overridable<number> | null {
    const numberValue = AmountInputHelpers.numberFromInput(valueVm, this.maskOptions);

    if (numberValue !== null && this.controlValue) {
      return new Overridable({ initial: this.controlValue.initial, override: numberValue });
    }

    return null;
  }

  /**
   * Handle input blur.
   * @param event Focus event.
   */
  protected onInputBlur(event: FocusEvent): void {
    if (event.relatedTarget === this.resetButtonElement?.nativeElement) {
      return;
    }

    const valueFromInput = this.valueFromInput(this.inputControl.value);
    const normalizedValue = valueFromInput ??
      new Overridable({ initial: this.controlValue?.initial ?? 0, override: 0 });
    if (this.controlValue && valueFromInput && this.checkIsInputValuesChanged(this.controlValue, valueFromInput)) {
      this.inputControl.setValue(this.valueToInput(normalizedValue));
    }
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new Overridable({ initial: this.controlValue.initial, override: null });
      this.writeValue(clearedOverride);
      this.controlValue = clearedOverride;
    }
  }
}
