import { Injectable } from '@angular/core';

import { Contractor } from '../../models/contractor';

import { ContractorDto } from './dto/contractor.dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** Contractor entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class ContractorMapper implements
  IMapperFromDto<ContractorDto, Contractor>,
  IMapperToDto<ContractorDto, Contractor> {
  /** @inheritdoc */
  public fromDto(dto: ContractorDto): Contractor {
    return new Contractor({
      companyName: dto.companyName,
      contact: dto.contact,
      phoneNumber: dto.phoneNumber,
    });
  }

  /** @inheritdoc */
  public toDto(data: Contractor): ContractorDto {
    return {
      companyName: data.companyName,
      contact: data.contact,
      phoneNumber: data.phoneNumber,
    };
  }
}
