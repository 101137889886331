import { PagedList } from '../../models/list-utilities/paged-list';

/** Provide API to handle new items in the PagedList. */
export type IListHandleStrategy<T> = {

  /** Handle paginated list according to some rules. */
  readonly handle: (paginatedList: PagedList<T>) => T[];
};

/**
 * Handler for "Infinite scroll" functionality.
 * The point is to concatenate items from new PagedList with the previous one
 * until "PagedList.pagination.page" does not equal 1.
 */
export class InfiniteScrollListStrategy<T> implements IListHandleStrategy<T> {

  private list: readonly T[] = [];

  /** @inheritdoc */
  public handle(paginatedList: PagedList<T>): T[] {
    if (paginatedList.pagination.page === 1) {
      this.list = paginatedList.items;
    } else {
      this.list = this.list.concat(paginatedList.items);
    }
    return [...this.list];
  }
}

/**
 * Handler for "Table" functionality.
 * Common strategy that just return items from received "PagedList" instance.
 */
export class TableListStrategy<T> implements IListHandleStrategy<T> {

  /** @inheritdoc */
  public handle(paginatedList: PagedList<T>): T[] {
    return [...paginatedList.items];
  }
}
