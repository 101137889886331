import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { PointsListTab } from '../tab/points-list-tab';
import { PointsListPart } from '../part/points-list-part';
import { PointsListSystem } from '../system/points-list-system';

/** Points list system group. */
export class PointsListSystemGroup {
  /** Group UUID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Is default group. */
  public readonly isDefault: boolean;

  /** Order. */
  public readonly order: number;

  /** Tab ID. */
  public readonly tabId: PointsListTab['id'];

  /** Systems. */
  public readonly systems: readonly PointsListSystem[];

  public constructor(data: PointsListSystemGroupInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.isDefault = data.isDefault;
    this.order = data.order;
    this.tabId = data.tabId;
    this.systems = data.systems;
  }

  /** Get system group parts. */
  public getParts(): PointsListPart[] {
    return this.systems.flatMap(system => system.getParts());
  }
}

type PointsListSystemGroupInitArgs = StrictOmit<PointsListSystemGroup, 'getParts'>;
