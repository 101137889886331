<div class="select">
  <mat-select
    placeholder="Hide all modules"
    multiple
    [value]="visibleModules"
    (valueChange)="visibleModulesChange.emit($event)"
  >
    <mat-select-trigger>{{ visibleModulesText }}</mat-select-trigger>
    <mat-option
      *ngFor="
        let type of moduleType.getBaseModules();
        trackBy: moduleType.trackBy
      "
      [value]="type"
    >
      {{ moduleType.toReadable(type) }}
    </mat-option>
  </mat-select>
</div>
