import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatInput } from '@angular/material/input';

import { MatFormFieldValueAccessor, matFormFieldControlProviderFor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';

/** Search input. */
@Component({
  selector: 'dartsalesw-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => SearchInputComponent)],
})
export class SearchInputComponent extends MatFormFieldValueAccessor<string> implements OnInit {

  /** Input keydown event emitter. */
  @Output()
  public readonly inputKeydown = new EventEmitter<KeyboardEvent>();

  /** Clear input event emitter. */
  @Output()
  public readonly clearInput = new EventEmitter<void>();

  @ViewChild(MatInput)
  private readonly matInput?: MatInput;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  /** Search control. */
  protected readonly searchControl = this.fb.control('');

  /** @inheritdoc */
  public override writeValue(value: string | null): void {
    this.searchControl.patchValue(value ?? '', { emitEvent: false });
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSearchChange();
  }

  /** @inheritdoc */
  public override focus(): void {
    this.matInput?.focus();
  }

  /** Clear input. */
  protected onClear(): void {
    this.searchControl.reset();
    this.clearInput.emit();
  }

  private subscribeToSearchChange(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(value => {
        this.controlValue = value;
      });
  }

}
