<button
  #menuTriggerButton="matMenuTrigger"
  mat-button
  type="button"
  class="estimate-select-button"
  [matMenuTriggerFor]="menu"
  (menuOpened)="searchInput.focus()"
>
  <div
    *ngIf="selectedEstimateOption$ | async as selectedEstimateOption"
    class="estimate-select-button_container"
  >
    <div>
      <span class="estimate-select-button__text subtitle-2">
        {{ selectedEstimateOption.name }}
      </span>
      <p
        *ngIf="selectedEstimateOption.description"
        class="estimate-select-button__text body-2"
      >
        {{ selectedEstimateOption.description }}
      </p>
    </div>
    <mat-icon class="estimate-select-button__icon">
      keyboard_double_arrow_right
    </mat-icon>
  </div>
</button>
<mat-menu
  #menu
  class="estimate-select-menu"
  yPosition="above"
  (closed)="resetSearchControl()"
>
  <dartsalesc-progress-bar [loading]="isLoading$ | async" />
  <ng-container *ngrxLet="selectedEstimateId$ | async as selectedEstimateId">
    <div
      *ngIf="options$ | async as options"
      class="estimate-select-menu-options"
      (click)="$event.stopPropagation()"
    >
      <ng-container
        *ngFor="let option of options.nonTemplateOptions; trackBy: trackByIndex"
      >
        <button
          mat-menu-item
          class="estimate-preview estimate-option"
          type="button"
          [class.estimate-option__selected]="selectedEstimateId === option.id"
          (click)="selectEstimateOption(option)"
        >
          <p
            class="subtitle-2 ellipsis"
            [title]="option.name"
          >
            {{ option.name }}
          </p>
          <p
            class="body-2 ellipsis"
            [title]="option.description"
          >
            {{ option.description }}
          </p>
        </button>
      </ng-container>
      <ng-container
        *ngFor="let template of options.templateOptions; trackBy: trackById"
      >
        <div class="estimate-preview">
          <dartsalesw-vertical-expand-button
            [isHalfRotation]="true"
            [isExpanded]="template.areChildrenExpanded"
            (buttonClick)="
              template.areChildrenExpanded = !template.areChildrenExpanded
            "
          />
          <mat-icon
            class="estimate-preview__icon"
            svgIcon="template"
          />
          <div class="template-info">
            <p
              class="subtitle-2 ellipsis"
              [title]="template.templateName"
            >
              {{ template.templateName }}
            </p>
            <p
              class="body-2 ellipsis"
              [title]="template.organizationName"
            >
              {{ template.organizationName }}
            </p>
          </div>
        </div>
        <div
          *ngIf="template.areChildrenExpanded"
          class="estimate-template-options"
        >
          <ng-container
            *ngFor="let option of template.options; trackBy: trackByIndex"
          >
            <button
              mat-menu-item
              class="estimate-option"
              type="button"
              [class.estimate-option__selected]="
                selectedEstimateId === option.id
              "
              (click)="selectEstimateOption(option)"
            >
              <p
                class="subtitle-2 ellipsis"
                [title]="option.name"
              >
                {{ option.name }}
              </p>
              <p
                class="body-2 ellipsis"
                [title]="option.description"
              >
                {{ option.description }}
              </p>
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="estimate-select-menu-search search-filed">
    <!-- 'keydown' event handling is a workaround to avoid search control focus issues. -->
    <input
      #searchInput
      matInput
      class="estimate-select-menu-search__input search-input"
      placeholder="Search"
      [formControl]="searchControl"
      (keydown)="$event.stopPropagation()"
      (click)="$event.stopPropagation()"
    />
    <button
      *ngIf="searchControl.value"
      mat-icon-button
      type="button"
      class="clear-button"
      (click)="onClear($event)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</mat-menu>
