import { PortCategoryType } from '@dartsales/common/core/enums/port-category-type';
import { PortType } from '@dartsales/common/core/enums/port-type';

/** Points list system IO ports total. */
export class PointsListSystemIoPortsTotal {

  /** Port category type. */
  public readonly categoryType: PortCategoryType;

  /** Port type.  */
  public readonly portType: PortType;

  /** Numerator. */
  public readonly numerator: number;

  /** Denominator. */
  public readonly denominator: number;

  public constructor(data: PointsListSystemIoPortsTotalInitArgs) {
    this.categoryType = data.categoryType;
    this.portType = data.portType;
    this.numerator = data.numerator;
    this.denominator = data.denominator;
  }
}

type PointsListSystemIoPortsTotalInitArgs = PointsListSystemIoPortsTotal;
