import { Injectable, inject } from '@angular/core';

import { PointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-task-rate';
import { EditPointsListSystemTaskRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-task-rate';

import { BatchEditPointsListSystemTaskRateDto, EditPointsListSystemTaskRateDto, PointsListSystemTaskRateDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-task-rate.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

import { PointsListLaborRoleRateMapper } from './points-list-labor-role-rate.mapper';

/** Points list system labor task rates mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemTaskRateMapper implements IMapper<PointsListSystemTaskRateDto, PointsListSystemTaskRate> {
  private readonly pointsListLaborRoleRateMapper = inject(PointsListLaborRoleRateMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemTaskRateDto): PointsListSystemTaskRate {
    return new PointsListSystemTaskRate({
      taskId: dto.taskId,
      taskName: dto.taskName,
      roleRate: this.pointsListLaborRoleRateMapper.fromDto(dto.taskRate),
      laborRoleId: this.confirmedOverridableMapper.fromDto(dto.originalLaborRoleId),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemTaskRate): PointsListSystemTaskRateDto {
    return {
      taskId: data.taskId,
      taskName: data.taskName,
      taskRate: this.pointsListLaborRoleRateMapper.toDto(data.roleRate),
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.laborRoleId),
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystemTaskRate): EditPointsListSystemTaskRateDto {
    return {
      taskId: data.taskId,
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.laborRoleId),
      ...this.pointsListLaborRoleRateMapper.toDto(data.roleRate),
    };
  }

  /**
   * To batch edit DTO.
   * @param data Data.
   */
  public toBatchEditDto(data: readonly EditPointsListSystemTaskRate[]): BatchEditPointsListSystemTaskRateDto[] {
    const systemIds = new Set(data.map(item => item.systemId));
    return [...systemIds].map(systemId => ({
      systemId,
      taskRates: data.filter(rate => rate.systemId === systemId).map(rate => this.toEditDto(rate)),
    }));
  }
}
