<button
  mat-icon-button
  type="button"
  aria-label="expand row"
  class="button"
  (click)="buttonClick.emit()"
>
  <ng-container *ngIf="isHalfRotation; else defaultRotation">
    <div [@indicator90Rotate]="isExpanded ? 'expanded' : 'collapsed'">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
  </ng-container>
</button>

<ng-template #defaultRotation>
  <div [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'">
    <mat-icon>keyboard_arrow_up</mat-icon>
  </div>
</ng-template>
