import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

import { AuthApiService } from '@dartsales/common/core/services/api/auth-api.service';
import { CurrentUserService } from '@dartsales/common/core/services/current-user.service';
import { ChangePasswordDataFormMapper } from '@dartsales/common/core/services/mappers/forms/change-password-data-form.mapper';
import { SnackbarNotificationsService } from '@dartsales/common/core/services/snackbar-notifications.service';
import { catchValidationData } from '@dartsales/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';

/** Change password dialog component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordDialogComponent {

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly authService = inject(AuthApiService);

  private readonly snackbarNotificationsService = inject(SnackbarNotificationsService);

  private readonly currentUserService = inject(CurrentUserService);

  private readonly dialogRef = inject(DialogRef);

  private readonly formService = inject(ChangePasswordDataFormMapper);

  /** Is change password pending. */
  protected readonly isChangePasswordPending$ = new BehaviorSubject(false);

  /** Password change data form. */
  protected readonly passwordChangeDataForm = this.formService.modelToForm();

  /** Handle dialog data submit. */
  protected onSubmit(): void {
    this.passwordChangeDataForm.markAllAsTouched();

    if (this.passwordChangeDataForm.invalid) {
      return;
    }

    const controlValues = this.passwordChangeDataForm.getRawValue();

    if (controlValues.newPassword === controlValues.currentPassword) {
      this.dialogRef.close();
      return;
    }
    const changePasswordData = this.formService.formToModel(controlValues);

    this.authService.changePassword(changePasswordData).pipe(
      toggleExecutionState(this.isChangePasswordPending$),
      catchValidationData(this.passwordChangeDataForm, this.cdr),
      untilDestroyed(this),
    )
      .subscribe({
        next: () => {
          this.currentUserService.reloadUserData();
          this.dialogRef.close();
          this.snackbarNotificationsService.notify('Password changed successfully');
        },
      });
  }
}
