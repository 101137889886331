import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { UserProfile } from '../models/user/user-profile';
import { filterNull } from '../utils/rxjs/filter-null';
import { ALLOWED_ROLES } from '../utils/constants';
import { UserOrganization } from '../models/user-organization';

import { CurrentUserApiService } from './api/current-user-api.service';
import { UserSecretStorageService } from './user-secret-storage.service';

/** Current user service. */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {

  private readonly userSecretStorage = inject(UserSecretStorageService);

  private readonly userApiService = inject(CurrentUserApiService);

  private readonly reloadUserData$ = new BehaviorSubject<void>(undefined);

  /** Current user. `null` when a user is not logged in. */
  public readonly currentUser$ = this.initCurrentUserStream();

  /** Current user organizations. */
  public readonly userOrganizations$ = this.createOrganizationsStream();

  /** Whether the current user is authorized. */
  public readonly isAuthorized$ = this.currentUser$.pipe(map(user => user != null));

  /** Reload user data. */
  public reloadUserData(): void {
    this.reloadUserData$.next();
  }

  private initCurrentUserStream(): Observable<UserProfile | null> {
    return combineLatest([this.userSecretStorage.currentSecret$, this.reloadUserData$]).pipe(
      switchMap(([secret]) => secret ? this.userApiService.getCurrentUser() : of(null)),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private createOrganizationsStream(): Observable<UserOrganization[]> {
    return this.currentUser$.pipe(
      filterNull(),
      map(user => {
        if (user.isGlobalAdmin) {
          return [...user.organizations];
        }
        return user.organizations.filter(org => ALLOWED_ROLES.some(role => org.roles.includes(role)));
      }),
      map(list => list.sort((a, b) => a.name.localeCompare(b.name))),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
