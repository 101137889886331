import { LaborTaskHours } from '../../estimate/modules/points-list/labor-task-hours';

import { PaletteOrganization } from './palette-organization';
import { PalettePart } from './palette-part';

/** Palette system part info. */
export type PaletteSystemPart = {

  /** Revision. */
  readonly revision: number;

  /** Labor task hours. */
  readonly laborTaskHours: readonly LaborTaskHours[];

  /** Part group. 'null' if part doesn't belong to any group. */
  readonly partGroup: SystemPartGroup | null;

  /** Supplier.'null' if no value is specified. */
  readonly supplier: string | null;

  /** Unit cost. */
  readonly unitCost: number;

} & PalettePart;

/** Palette system part group. */
export type PaletteSystemPartGroup = {

  /** List of parts. */
  readonly parts: readonly PaletteSystemPart[];
} & SystemPartGroup;

/** System part group. */
export type SystemPartGroup = {

  /** Groups UUID. */
  readonly id: string;

  /** Name. */
  readonly name: string;
};

/** Palette system. */
export class PaletteSystem {

  /** ID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string | null;

  /** Description. */
  public readonly description: string | null;

  /** Organization. */
  public readonly organization: PaletteOrganization;

  /** List of part groups. */
  public readonly groups: readonly PaletteSystemPartGroup[];

  /** List of part without group. */
  public readonly parts: readonly PaletteSystemPart[];

  /** List of tags. */
  public readonly tags: readonly string[];

  public constructor(data: PaletteSystemInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.organization = data.organization;
    this.tags = data.tags;
    this.groups = this.createGroups(data.parts);
    this.parts = this.createParts(data.parts);
  }

  private createGroups(parts: readonly PaletteSystemPart[]): PaletteSystemPartGroup[] {
    const groupsMap = parts.reduce((acc, part) => {
      if (part.partGroup != null) {
        const groupParts = acc.get(part.partGroup.id)?.parts ?? [];
        acc.set(part.partGroup.id, {
          ...part.partGroup,
          parts: groupParts.concat(part),
        });
      }
      return acc;
    }, new Map<PaletteSystemPartGroup['id'], PaletteSystemPartGroup>());
    return [...groupsMap.values()];
  }

  private createParts(parts: readonly PaletteSystemPart[]): readonly PaletteSystemPart[] {
    return parts.filter(part => part.partGroup == null);
  }
}

type PaletteSystemInitArgs = Pick<
  PaletteSystem,
  | 'id'
  | 'name'
  | 'description'
  | 'organization'
  | 'parts'
  | 'tags'
>;
