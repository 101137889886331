import { PointsListPartGroup } from './part-group/points-list-part-group';
import { PointsListPart } from './part/points-list-part';
import { PointsListSystemGroup } from './system-group/points-list-system-group';
import { PointsListSystem } from './system/points-list-system';
import { PointsListTab } from './tab/points-list-tab';

/** Combination of all points list entities IDs. */
export class PointsListAllEntitiesIds {

  /** Part IDs. */
  public readonly partIds: readonly PointsListPart['id'][];

  /** Part group IDs. */
  public readonly partGroupIds: readonly PointsListPartGroup['id'][];

  /** System IDs. */
  public readonly systemIds: readonly PointsListSystem['id'][];

  /** System group IDs. */
  public readonly systemGroupIds: readonly PointsListSystemGroup['id'][];

  /** Tab IDs. */
  public readonly tabIds: readonly PointsListTab['id'][];

  public constructor(data: PointsListAllEntitiesIdsInitArgs) {
    this.partIds = data.partIds ?? [];
    this.partGroupIds = data.partGroupIds ?? [];
    this.systemIds = data.systemIds ?? [];
    this.systemGroupIds = data.systemGroupIds ?? [];
    this.tabIds = data.tabIds ?? [];
  }
}

type PointsListAllEntitiesIdsInitArgs = Partial<PointsListAllEntitiesIds>;
