import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

/** Directive that allows to change width of the provided element. */
@Directive({
  selector: '[dartsaleswResize]',
})
export class ResizeDirective {

  /** Element to resize. */
  @Input()
  public target: HTMLElement | null = null;

  /** Alternative to 'target' input for cases where it's not possible to pass parent HTML element. */
  @Input()
  public targetWidth: number | null = null;

  /** Minimum width of the target element in pixels. */
  @Input()
  public minWidth?: number | null = null;

  /** Maximum width of the target element in pixels. */
  @Input()
  public maxWidth: number | null = null;

  /** Width change event. */
  @Output()
  public readonly changeWidth = new EventEmitter<number>();

  /** Resize element event emitter. This event is emitted when user moves mouse during element resizing. */
  @Output()
  public readonly resizeElement = new EventEmitter<number>();

  private isGrabbed = false;

  private width = 0;

  private currentClientX = 0;

  /**
   * Handle mouse move event.
   * @param event Mouse event.
   */
  @HostListener('document:mousemove', ['$event'])
  protected onMouseMove(event: MouseEvent): void {
    if (!this.isGrabbed) {
      return;
    }

    this.resize(event.clientX - this.currentClientX);
    this.currentClientX = event.clientX;
    event.preventDefault();

    this.resizeElement.emit(this.width);
  }

  /** Handle mouse up event. */
  @HostListener('document:mouseup', ['$event'])
  protected onMouseUp(): void {
    if (!this.isGrabbed) {
      return;
    }

    this.isGrabbed = false;

    if (this.target) {
      this.target.style.maxWidth = `${this.width}px`;
      this.target.style.minWidth = `${this.width}px`;
    }

    this.changeWidth.emit(this.width);
  }

  /**
   * Handle mouse down event.
   * @param event Mouse event.
   */
  @HostListener('mousedown', ['$event'])
  protected onMouseDown(event: MouseEvent): void {
    this.isGrabbed = true;
    if (this.width === 0) {
      this.width = this.target?.clientWidth ?? this.targetWidth ?? this.minWidth ?? 0;
    }
    this.currentClientX = event.clientX;
    event.preventDefault();
  }

  private resize(offsetX: number): void {
    // 'requestAnimationFrame' is a performance optimization.
    requestAnimationFrame(() => {
      this.width = this.calculateNewWidth(offsetX);

      if (this.target != null) {
        this.target.style.minWidth = `${this.width}px`;
        this.target.style.maxWidth = `${this.width}px`;
      }
    });
  }

  private calculateNewWidth(offsetX: number): number {
    const widthWithOffset = this.width + offsetX;

    if (this.minWidth && widthWithOffset < this.minWidth) {
      return this.minWidth;
    }

    if (this.maxWidth && widthWithOffset > this.maxWidth) {
      return this.maxWidth;
    }

    return widthWithOffset;
  }
}
