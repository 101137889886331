// TODO (Pavel Z.) May be move this to models.

/**
 * Object with possibility to override original value.
 * The `override` value is set only if it is different from the `initial`. At the same time, in order to
 * compare complex types, for example, such as `AmountPercent`, a comparison of values reduced to a string
 * is used.
 **/
export class Overridable<T> {

  /** Native (original) value, usually calculated. */
  public readonly initial: T;

  /** Overridden value. */
  public readonly override: T | null;

  public constructor(data: OverridableInitArgs<T>) {
    this.initial = data.initial;
    this.override = data.override ?? null;
  }

  /** Gets combined value of overridable object. */
  public get combinedValue(): T {
    return this.override ?? this.initial;
  }

  // TODO (Pavel Z.) Replace usage of this function to `combinedValue`.
  /**
   * Gets value of overridable.
   * @param src Overridable to get value from.
   * @deprecated
   */
  public static getValue<T>(src: Overridable<T>): T {
    return src.combinedValue;
  }
}

export type OverridableInitArgs<T> = Pick<Overridable<T>, 'initial'> &
  Partial<Pick<Overridable<T>, 'override'>>;
