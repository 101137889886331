import { Injectable, inject } from '@angular/core';

import { ModulesOverrides } from '@dartsales/common/core/models/estimate/modules-overrides';
import { EstimateServiceModules } from '@dartsales/common/core/models/estimate-services/estimate-service-modules';
import { BaseModulePropertiesList } from '@dartsales/common/core/models/estimate/module-properties-list';

import { IMapperToDto } from '../mappers';
import { ModulesOverridesDto } from '../dto/estimate/modules-overrides.dto';
import { BasePricingOverridesMapper } from '../pricing/base-pricing-overrides.mapper';

/** Modules overrides mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModulesOverridesMapper implements
  IMapperToDto<ModulesOverridesDto, ModulesOverrides> {

  private readonly basePricingOverridesMapper = inject(BasePricingOverridesMapper);

  /** @inheritdoc */
  public toDto(data: ModulesOverrides): ModulesOverridesDto {
    return {
      subcontractor: data.subcontractor ? this.basePricingOverridesMapper.toDto(data.subcontractor) : undefined,
      expenses: data.expenses ? this.basePricingOverridesMapper.toDto(data.expenses) : undefined,
      custom: data.custom ? this.basePricingOverridesMapper.toDto(data.custom) : undefined,
      labor: data.labor ? this.basePricingOverridesMapper.toDto(data.labor) : undefined,
      material: data.material ? this.basePricingOverridesMapper.toDto(data.material) : undefined,
      totals: data.totals ? this.basePricingOverridesMapper.toDto(data.totals) : undefined,
    };
  }

  /**
   * Map modules to modules overrides.
   * @param modulesProperties Modules.
   */
  public fromModules(modulesProperties: BaseModulePropertiesList): ModulesOverrides {
    return new ModulesOverrides({
      subcontractor: this.basePricingOverridesMapper.fromModuleProperties(modulesProperties.subcontractor),
      expenses: this.basePricingOverridesMapper.fromModuleProperties(modulesProperties.expenses),
      custom: this.basePricingOverridesMapper.fromModuleProperties(modulesProperties.custom),
      labor: this.basePricingOverridesMapper.fromModuleProperties(modulesProperties.labor),
      material: this.basePricingOverridesMapper.fromModuleProperties(modulesProperties.material),
      totals: null,
    });
  }

  /**
   * Map service modules to modules overrides.
   * @param modules Modules.
   */
  public fromServiceModules(modules: EstimateServiceModules): ModulesOverrides {
    return new ModulesOverrides({
      ...this.fromModules(modules),
      totals: this.basePricingOverridesMapper.fromModuleProperties(modules.totals.properties),
    });
  }
}
