import { BaseOrganization } from '../../organization';

/** Palette organization. */
export class PaletteOrganization {

  /** ID. */
  public readonly id: BaseOrganization['id'];

  /** Name. */
  public readonly name: string;

  public constructor(data: PaletteOrganizationInitArgs) {
    this.id = data.id;
    this.name = data.name;
  }
}

type PaletteOrganizationInitArgs = PaletteOrganization;
