import { Injectable } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { FlatNode } from '../nested-tree-select/models/flat-node';
import { AbstractNestedTreeSelectionService } from '../nested-tree-select/utils/abstract-nested-tree-select.service';

/**
 * Estimate nested tree selection service.
 * The choice of tree element does not depend on children.
 */
@Injectable()
export class EstimateNestedTreeSelectionService<T> extends AbstractNestedTreeSelectionService<T> {

  /** @inheritdoc */
  public onNodeWithoutChildrenChange(node: FlatNode<T>): void {
    this.selection.toggle(node);
    this.updateParentNodesSelection(node);
  }

  /** @inheritdoc */
  public onNodeWithChildrenChange(node: FlatNode<T>, event: MatCheckboxChange): void {
    const isNodeSelected = this.checkIsSelected(node);
    const descendants = this.treeControl.getDescendants(node);

    if (isNodeSelected) {
      this.selection.deselect(node, ...descendants);

      // MatCheckbox is always selected after a click from any state except the selected one.
      // https://github.com/angular/components/issues/27150
      if (event != null) {
        event.source.checked = false;
      }
    } else {
      this.selection.select(node, ...descendants);
    }
    this.updateParentNodesSelection(node);
  }

  /** @inheritdoc */
  public checkIsNodeWithChildrenChecked(node: FlatNode<T>): boolean {
    return this.checkIsSelected(node) && this.checkIfAllDescendantsAreSelected(node);
  }

  /** @inheritdoc */
  public checkIsNodeWithChildrenIndeterminate(node: FlatNode<T>): boolean {
    return this.checkIfDescendantsArePartiallySelected(node);
  }

  private updateParentNodesSelection(node: FlatNode<T>): void {
    const isNodeSelected = this.selection.isSelected(node);
    const parentNode = this.getParentNode(node);

    if (parentNode == null) {
      return;
    }

    if (isNodeSelected) {
      this.selection.select(parentNode);
      this.updateParentNodesSelection(parentNode);
    }

    if (!isNodeSelected && !this.checkIfDescendantsArePartiallySelected(parentNode)) {
      this.selection.deselect(parentNode);
      this.updateParentNodesSelection(parentNode);
    }
  }
}
