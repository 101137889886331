import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/** Table lock button. */
@Component({
  selector: 'dartsalesw-table-lock-button',
  templateUrl: './table-lock-button.component.html',
  styleUrls: ['./table-lock-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableLockButtonComponent {

  /** Whether value is locked. */
  @Input()
  public isLockedValue = false;

  /** Lock button click event. */
  @Output()
  public readonly lockButtonClick = new EventEmitter<boolean>();

}
