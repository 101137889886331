import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractCommand } from './abstract-command';

type DefaultArgs = {

  /** Injector. */
  readonly injector: Injector;
};

/** Points list abstract command. */
export abstract class PointsListAbstractCommand<TArgs = unknown, TResult = void> implements AbstractCommand<TResult> {

  /** Injector. */
  protected readonly injector: Injector;

  public constructor(protected readonly args: TArgs & DefaultArgs) {
    this.injector = this.args.injector;
  }

  /** @inheritdoc */
  public abstract execute(): Observable<TResult>;

  /** Undo command. */
  public abstract undo(): Observable<TResult>;

  /**
   * Redo command.
   * By default it's the same as 'execute'. We can override it in subclasses.
   */
  public redo(): Observable<TResult> {
    return this.execute();
  }
}
