import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';

import { AppErrorMapper } from '../../mappers/errors/app-error.mapper';
import { AppUrlsConfig } from '../app-urls.config';
import { PointsListAllEntitiesIdsMapper } from '../../mappers/estimate/modules/points-list/points-list-all-entities-ids.mapper';
import { PointsListDuplicateEntitiesDto } from '../../mappers/dto/estimate/modules/points-list/points-list-all-entities-ids.dto';
import { ApiTransactionMapper } from '../../mappers/api-tansaction.mapper';
import { ApiTransactionDto } from '../../mappers/dto/api-transaction.dto';

/** Points list groups API service. */
@Injectable({
  providedIn: 'root',
})
export class PointsListGroupsApiService {
  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly pointsListAllEntitiesIdsMapper = inject(PointsListAllEntitiesIdsMapper);

  private readonly apiTransactionMapper = inject(ApiTransactionMapper);

  /**
   * Duplicate points list entities.
   * @param estimateId Estimate ID.
   * @param payload Payload.
   */
  public duplicate(
    estimateId: EstimateId,
    payload: PointsListAllEntitiesIds,
  ): Observable<PointsListAllEntitiesIds> {
    const dto = this.pointsListAllEntitiesIdsMapper.toDto(payload);
    return this.http.post<PointsListDuplicateEntitiesDto>(
      this.apiUrls.pointsListApi.duplicate(estimateId),
      dto,
    ).pipe(
      map(responseDto => this.pointsListAllEntitiesIdsMapper.fromDuplicateDto(responseDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Remove points list entities.
   * @param estimateId Estimate ID.
   * @param payload Payload.
   */
  public delete(
    estimateId: EstimateId,
    payload: PointsListAllEntitiesIds,
  ): Observable<ApiTransactionId> {
    const dto = this.pointsListAllEntitiesIdsMapper.toDto(payload);
    return this.http.delete<ApiTransactionDto>(
      this.apiUrls.pointsListApi.delete(estimateId),
      { body: dto },
    ).pipe(
      map(transactionDto => this.apiTransactionMapper.fromDto(transactionDto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Ungroup points list groups.
   * @param estimateId Estimate ID.
   * @param payload Payload.
   */
  public ungroup(
    estimateId: EstimateId,
    payload: PointsListAllEntitiesIds,
  ): Observable<void> {
    const dto = this.pointsListAllEntitiesIdsMapper.toUngroupDto(payload);
    return this.http.post<void>(this.apiUrls.pointsListApi.ungroup(estimateId), dto).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
