import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject, OnInit, ContentChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, skip } from 'rxjs';

import { NUMBER_MASK, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE } from '@dartsales/common/core/utils/constants';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { TableCellPrefixDirective } from '@dartsales/common/shared/components/editable-table-cell/directives/table-cell-prefix.directive';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';

import { AmountInputHelpers } from '../../inputs/abstract-input';

/** Percent table header cell component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-percent-table-header-cell',
  templateUrl: './percent-table-header-cell.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    './percent-table-header-cell.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => PercentTableHeaderCellComponent)],
})
export class PercentTableHeaderCellComponent extends SimpleValueAccessor<number> implements OnInit {

  /** Input label. */
  @Input()
  public label = '';

  /** Whether input is read-only. */
  @Input()
  public isReadonly = false;

  /** Whether value is locked. */
  @Input()
  public isLockedValue = false;

  /** Apply value. */
  @Output()
  public readonly applyValue = new EventEmitter<number>();

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Input mask. */
  protected readonly inputMask = NUMBER_MASK;

  /** Value. */
  protected value = 0;

  /** Input value control. */
  protected readonly inputValueControl = this.fb.control<string>('');

  private readonly inputBlurTrigger$ = new BehaviorSubject<void>(undefined);

  private readonly valueChangeSaveTrigger$ = new BehaviorSubject<void>(undefined);

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    const percent = this.valueToInput(value ?? 0);
    this.value = value ?? 0;
    this.inputValueControl.setValue(percent, { emitEvent: false });
    super.writeValue(value);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToValueControlChanges();
    this.subscribeToInputTriggerChanges();
  }

  /** Handle input blur. */
  protected onInputBlur(): void {
    this.inputBlurTrigger$.next();
  }

  /**
   * Convert display format to native value.
   * @param valueVm Display value.
   */
  private valueFromInput(valueVm: string): number {
    return AmountInputHelpers.percentFromInput(valueVm, this.inputMask) ?? 0;
  }

  /**
   * Convert value to display format.
   * @param value Native component value.
   */
  private valueToInput(value: number): string {
    return AmountInputHelpers.percentToInput(value ?? 0);
  }

  private subscribeToValueControlChanges(): void {
    const debounceTimeMs = 2000;
    this.inputValueControl.valueChanges.pipe(

      // skip(1) - solves bug with input masks.
      // During initialization, valueChanges is triggered with initial value of control, which we don't need.
      skip(1),
      debounceTime(debounceTimeMs),
      untilDestroyed(this),
    ).subscribe(() => this.valueChangeSaveTrigger$.next());
  }

  private subscribeToInputTriggerChanges(): void {
    combineLatest([
      this.inputBlurTrigger$,
      this.valueChangeSaveTrigger$,
    ]).pipe(
      untilDestroyed(this),
    )
      .subscribe(() => {
        const valueFromInput = this.valueFromInput(this.inputValueControl.value);
        const isInputValueChanged = this.controlValue != null &&
          !compareRoundNumbers(valueFromInput, this.controlValue, PERCENT_VALUES_ROUND_FRACTION_DIGITS_FOR_COMPARE);
        if (isInputValueChanged) {
          this.controlValue = valueFromInput;
          this.applyValue.emit(valueFromInput);
        }
      });
  }
}
