import { ModuleType } from '../../enums/module-type';
import { TreeItem } from '../tree-item';

/** Term service item. */
export type TermServiceItem = {

  /** Service ID. */
  readonly serviceId: number;
};

/** Term service tree item. */
export class TermServiceTreeItem extends TreeItem<TermServiceItem, ModuleType> { }
