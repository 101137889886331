import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ReplaySubject, map, Observable, combineLatest, switchMap, BehaviorSubject, shareReplay, first, tap } from 'rxjs';

import { PaletteComponentsApiService } from '@dartsales/common/core/services/api/palette-components-api.service';
import { BaseOrganization } from '@dartsales/common/core/models/organization';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { ComponentType } from '@dartsales/common/core/models/filters/component-type';
import { PaletteTag } from '@dartsales/common/core/models/components/palette/palette-tag';
import { PaletteSharedTagsFilter } from '@dartsales/common/core/models/filters/palette-shared-filters';

/** Tag filters. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-tag-filters',
  templateUrl: './tag-filters.component.html',
  styleUrls: ['./tag-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFiltersComponent {
  /** Organization ID. */
  @Input()
  public set organizationId(organizationId: BaseOrganization['id'] | null) {
    if (organizationId != null) {
      this.organizationId$.next(organizationId);
    }
  }

  /** Organization ID. */
  private readonly organizationId$ = new ReplaySubject<BaseOrganization['id']>(1);

  /** Component type. */
  @Input()
  public set componentType(type: ComponentType | null) {
    if (type != null) {
      this.componentType$.next(type);
    }
  }

  /** Active tags. */
  @Input()
  public activeTags: readonly string[] = [];

  /** Component type. */
  private readonly componentType$ = new ReplaySubject<ComponentType>(1);

  /** Tags change. */
  @Output()
  public readonly tagsChange = new EventEmitter<PaletteSharedTagsFilter>();

  private readonly paletteComponentsApiService = inject(PaletteComponentsApiService);

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  private readonly search$ = new BehaviorSubject('');

  private readonly tags$ = this.createTagsStream();

  /** Options. */
  protected readonly options$ = this.createOptionsStream();

  /** Initially selected options. */
  protected readonly preselectedOptions$ = this.createPreselectedOptionsStream();

  /**
   * Options change handler.
   * @param selectedValues Selected values.
   * @param defaultTagsOptions Default tags options.
   */
  protected onSelectedOptionsChange(selectedValues: readonly string[], defaultTagsOptions: readonly OptionSelect<string>[]): void {
    this.tagsChange.emit({
      tags: selectedValues,
      defaultTags: defaultTagsOptions.map(option => option.value),
    });
  }

  /**
   * Search change handler.
   * @param query Search query.
   */
  protected onOptionsSearchChange(query: string): void {
    this.search$.next(query);
  }

  private createTagsStream(): Observable<PaletteTag[]> {
    return combineLatest([
      this.organizationId$,
      this.componentType$,
      this.search$,
    ]).pipe(
      switchMap(([organizationId, componentType, name]) => this.paletteComponentsApiService.getTagsPalette(
        organizationId,
        { componentType, name },
      ).pipe(
        toggleExecutionState(this.isLoading$),
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createOptionsStream(): Observable<OptionSelect<PaletteTag['name']>[]> {
    return this.tags$.pipe(
      map(
        tags => tags
          .map(tag => ({
            value: tag.name,
            label: tag.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createPreselectedOptionsStream(): Observable<OptionSelect<PaletteTag['name']>[]> {
    return this.tags$.pipe(
      first(),
      map(
        tags => tags.filter(tag => tag.isSelected).map(tag => ({
          value: tag.name,
          label: tag.name,
        })),
      ),
      tap(options => this.tagsChange.emit({
        defaultTags: options.map(option => option.value),
      })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
