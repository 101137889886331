import { Country } from '../enums/country';
import { State } from '../enums/state';

/** Base organization. */
export class BaseOrganization {

  /** Organization id. */
  public readonly id: number;

  /** Organization name. */
  public readonly name: string;

  /** Logo URL. */
  public readonly logoUrl: string | null;

  public constructor(data: BaseOrganizationInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.logoUrl = data.logoUrl;
  }
}

/** Organization. */
export class Organization extends BaseOrganization {

  /** Country. */
  public readonly country: Country;

  /** State / Province. */
  public readonly state: State;

  /** City. */
  public readonly city: string;

  /** Zip / postal code. */
  public readonly zip: string;

  /** Fax. */
  public readonly faxNumber: string;

  /** Phone number. */
  public readonly phoneNumber: string;

  /** Adress. */
  public readonly address: string;

  public constructor(data: OrganizationInitArgs) {
    super(data);
    this.country = data.country;
    this.state = data.state;
    this.city = data.city;
    this.zip = data.zip;
    this.faxNumber = data.faxNumber;
    this.phoneNumber = data.phoneNumber;
    this.address = data.address;
  }
}

type BaseOrganizationInitArgs = BaseOrganization;
type OrganizationInitArgs = Organization;
