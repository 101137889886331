import { OptionSelect } from '../models/option-select';

/** Margin type. */
export enum MarginType {
  Markup = 'Markup',
  GrossMargin = 'GrossMargin',
}

/** Namespace related to MarginType. */
export namespace MarginType {
  const TO_TITLE_MAP: Record<MarginType, string> = {
    [MarginType.Markup]: 'Markup',
    [MarginType.GrossMargin]: 'Gross Margin',
  };

  const TO_SHORT_TITLE_MAP: Record<MarginType, string> = {
    [MarginType.Markup]: 'Markup',
    [MarginType.GrossMargin]: 'GM',
  };

  /** Default margin type. */
  export const DEFAULT = MarginType.GrossMargin;

  /**
   * Converts a certain margin type to readable title.
   * @param value Margin.
   */
  export function toReadable(value: MarginType): string {
    return TO_TITLE_MAP[value];
  }

  /**
   * Converts a certain margin type to short readable title.
   * @param value Margin.
   */
  export function toShortReadable(value: MarginType): string {
    return TO_SHORT_TITLE_MAP[value];
  }

  /** Gets list of all margin types. */
  export function toArray(): MarginType[] {
    return [MarginType.GrossMargin, MarginType.Markup];
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: MarginType): MarginType {
    return item;
  }

  /** Gets list of select options. */
  export function toSelectOptions(): OptionSelect<MarginType>[] {
    return MarginType.toArray().map(
      type => ({
        value: type,
        label: MarginType.toReadable(type),
      }),
    );
  }
}
