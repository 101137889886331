<mat-form-field class="no-label">
  <input
    matInput
    placeholder="Select date"
    [formControl]="formControl"
    [matDatepicker]="picker"
  />
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
  />
  <mat-datepicker #picker />
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
