import { LaborRoleRateType } from '@dartsales/common/core/enums/labor-role-rate-type';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';
import { LaborRoleInternalRates } from '@dartsales/common/core/models/resources/labor-role-internal-rates';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

/** Points list labor role rate. */
export class PointsListLaborRoleRate {
  /** Labor rate type. */
  public readonly rateType: LaborRoleRateType;

  /** Labor role rates. */
  public readonly rates: ConfirmedOverridable<LaborRoleInternalRates>;

  /** Whether bulk update is available. */
  public readonly isBulkUpdateAvailable: boolean;

  public constructor(data: PointsListLaborRoleRateInitArgs) {
    this.rateType = data.rateType;
    this.rates = data.rates;
    this.isBulkUpdateAvailable = this.getIsBulkUpdateAvailable();
  }

  /**
   * Get rate value by rate type.
   * @param rateType Rate type.
   */
  public getRateByRateType(rateType: LaborRoleRateType): number {
    const rates = this.rates.combinedValue;

    if (rateType === LaborRoleRateType.Regular) {
      return rates.rate;
    }
    if (rateType === LaborRoleRateType.OvertimeOne) {
      return rates.rateOvertimeOne;
    }
    if (rateType === LaborRoleRateType.OvertimeTwo) {
      return rates.rateOvertimeTwo;
    }

    return rates.rate;
  }

  private getIsBulkUpdateAvailable(): boolean {
    return ConfirmedOverridable.hasUnconfirmedOverride([this.rates]);
  }
}

type PointsListLaborRoleRateInitArgs = StrictOmit<PointsListLaborRoleRate, 'getRateByRateType' | 'isBulkUpdateAvailable'>;
