import { AnyObject } from '../../utils/types/any-object';

/**
 * Entity validation errors type.
 * Describes validation items for target entity.
 */
export type EntityValidationErrors<T extends AnyObject> = {

  /** Error message for certain entity property. */
  readonly [P in keyof T]?: PropValidationMessage<T[P]>;
};

/**
 * Validation message type for specific property type.
 * Could be a just error message for simple field or nested validation error for composite fields.
 */
export type PropValidationMessage<T> = T extends readonly (infer K extends AnyObject)[]
  ? EntityValidationErrors<K>[]
  : T extends Record<string, unknown>
    ? EntityValidationErrors<T>
    : string;

/**
 * An error that was raised by the application intentionally.
 */
export class AppError extends Error {
  /** Error message. */
  public override readonly message: string;

  public constructor(message: string) {
    super(message);
    this.message = message;
  }
}

/** Application validation error for certain Entity. */
export class AppValidationError<TEntity extends AnyObject = Record<string, unknown>> extends AppError {
  /** Validation errors for entity fields. */
  public readonly validationData?: EntityValidationErrors<TEntity>;

  public constructor(
    message: string,
    validationData: EntityValidationErrors<TEntity>,
  ) {
    super(message);
    this.validationData = validationData;
  }
}
