import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { FocusParams } from './cell-renderer.service';

/** Cell editor service. */
@Injectable()
export class CellEditorService {

  /** Emit value when want to focus on a cell. */
  public readonly focusRequest$ = new Subject<FocusParams | null>();

  /** Blur. */
  public readonly blur$ = new Subject<void>();
}
