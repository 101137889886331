/** Custom column. */
export class CustomColumn {
  /** ID (uuid). */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  public constructor(data: CustomColumnInitArgs) {
    this.id = data.id;
    this.name = data.name;
  }
}

type CustomColumnInitArgs = CustomColumn;

export type CustomColumnEditData = Pick<CustomColumn, 'name'>;
