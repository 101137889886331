import { ConfirmedOverridable } from '../../../confirmed-overridable';

/** Expenses role. */
export class ExpensesRole {
  /** ID. */
  public readonly id: string;

  /** Labor role ID from resources table. */
  public readonly laborRoleId: number;

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Rate. */
  public readonly rate: number;

  /** Is removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  public constructor(data: ExpensesRoleInitArgs) {
    this.id = data.id;
    this.laborRoleId = data.laborRoleId;
    this.rate = data.rate;
    this.name = data.name;
    this.isRemoved = data.isRemoved;
  }
}

type ExpensesRoleInitArgs = ExpensesRole;
