import { BasePricing, BasePricingInitArgs } from '@dartsales/common/core/models/pricing/base-pricing';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { ModuleLockablePercentsStatus } from '../../module-lockable-percents';

/** Custom module's pricing. */
export class CustomModulePricing extends BasePricing {

  /** ID. */
  public readonly id: string;

  /** Description. */
  public readonly description: string;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(data: CustomModulePricingInitArgs) {
    super(data);
    this.id = data.id;
    this.description = data.description;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }
}

type CustomModulePricingInitArgs = StrictOmit<CustomModulePricing,
  'getTotalDirectCost'
> & BasePricingInitArgs;
