import { RateType } from '@dartsales/common/core/enums/rate-type';

import { ConfirmedOverridable } from '../../../confirmed-overridable';

/** Expenses travel cost. */
export class ExpensesTravelCost {

  /** ID. */
  public readonly id: string;

  /**
   * Expense type ID.
   * "Null" is possible if travel cost is custom.
   */
  public readonly expenseTypeId: number | null;

  /** Title. */
  public readonly title: ConfirmedOverridable<string>;

  /** Rate. */
  public readonly rate: ConfirmedOverridable<number>;

  /** Rate type. */
  public readonly rateType: ConfirmedOverridable<RateType>;

  /** Is removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  /** Is default. */
  public readonly isDefault: boolean;

  public constructor(data: ExpensesTravelCostInitArgs) {
    this.id = data.id;
    this.expenseTypeId = data.expenseTypeId;
    this.title = data.title;
    this.rate = data.rate;
    this.rateType = data.rateType;
    this.isRemoved = data.isRemoved;
    this.isDefault = data.isDefault;
  }

  /** Whether is bulk update confirmed. */
  public get isBulkUpdateConfirmed(): boolean {
    return !ConfirmedOverridable.hasUnconfirmedOverride([
      this.rate,
      this.rateType,
      this.title,
    ]);
  }
}

type ExpensesTravelCostInitArgs = Pick<
ExpensesTravelCost,
| 'id'
| 'expenseTypeId'
| 'title'
| 'rate'
| 'rateType'
| 'isRemoved'
| 'isDefault'
>;
