<ng-container *ngIf="isExpandable; else row">
  <div class="container">
    <ng-container *ngTemplateOutlet="row" />

    <div
      *ngIf="isExpanded"
      class="details"
      @expand
    >
      <ng-container *ngTemplateOutlet="detailsTemplate" />
    </div>
  </div>
</ng-container>

<ng-template #row>
  <div
    class="row"
    [class.row_expanded]="isExpanded"
  >
    <ng-content select="beforeExpandButton" />
    <button
      *ngIf="isExpandable"
      mat-icon-button
      type="button"
      class="row__button"
      matTooltipPosition="above"
      [matTooltip]="buttonTooltip"
      [disabled]="isExpansionDisabled"
      (click)="onExpandButtonClick()"
    >
      <dartsalesc-expand-collapse-icon [isExpanded]="isExpanded" />
    </button>

    <div class="row__content">
      <ng-container *ngTemplateOutlet="rowContentTemplate" />
    </div>
  </div>
</ng-template>
