import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';

import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { CURRENCY_MASK, PERCENT_MASK } from '@dartsales/common/core/utils/constants';
import { AmountPercentFormControls, AmountPercentInputValues } from '@dartsales/common/core/models/forms/amount-percent-form';

import { INPUT_DELAY_MS } from '../abstract-input';

/** Amount toggled input. */
@UntilDestroy()
@Directive()
export abstract class AbstractAmountToggledInputComponent<T> extends SimpleValueAccessor<T> implements OnInit {

  /** Units. */
  @Input()
  public units = AmountCalcUnits.DEFAULT;

  /** Units change event. */
  @Output()
  public readonly unitsChange = new EventEmitter<AmountCalcUnits>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Form. */
  protected readonly form = this.createFrom();

  // TODO (DART-69): This is a temporary solution.
  // In new designs we can use simple currency mask without modifications.
  /** Mask options. */
  protected readonly amountInputMask = createMask({ ...CURRENCY_MASK, prefix: '' });

  /** Percent input mask. */
  protected readonly percentInputMask = createMask({ ...PERCENT_MASK, suffix: '' });

  /** Amount calc units. */
  protected readonly amountCalcUnits = AmountCalcUnits;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  protected abstract valueToInput(value: T): AmountPercentInputValues;

  /** @inheritdoc */
  protected abstract valueFromInput(inputValue: AmountPercentInputValues): T | null;

  /** @inheritdoc */
  protected abstract checkShouldSetValue(value: T | null): boolean;

  /** @inheritdoc */
  public override writeValue(value: T | null): void {
    this.fillForm(value);
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
    super.setDisabledState(isDisabled);
  }

  /**
   * Handle user input.
   * @param valueVm Form value.
   */
  protected onUserInput(valueVm: Partial<AmountPercentInputValues>): void {
    const preparedValue: AmountPercentInputValues = {
      amount: valueVm.amount ?? '',
      percent: valueVm.percent ?? '',
    };

    const valueFromInput = this.valueFromInput(preparedValue);
    if (this.checkShouldSetValue(valueFromInput)) {
      this.controlValue = valueFromInput;
    }
  }

  /**
   * Fill form.
   * @param value Form value.
   */
  protected fillForm(value: T | null): void {
    this.form.patchValue(value !== null ? this.valueToInput(value) : ({ amount: '', percent: '' }), { emitEvent: false });
  }

  private subscribeToControlChanges(): void {
    this.form.valueChanges.pipe(
      debounceTime(INPUT_DELAY_MS),
      untilDestroyed(this),
    ).subscribe(value => this.onUserInput(value));
  }

  private createFrom(): FormGroup<AmountPercentFormControls> {
    const form = this.fb.group<AmountPercentFormControls>({
      amount: this.fb.control(''),
      percent: this.fb.control(''),
    });
    return form;
  }
}
