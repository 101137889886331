import { BasePricing, BasePricingInitArgs } from '@dartsales/common/core/models/pricing/base-pricing';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

import { SubcontractorInfo } from '../subcontractor-info';
import { ModuleLockablePercentsStatus } from '../../../module-lockable-percents';

/** Subcontractor lump sum pricing. */
export class SubcontractorLumpSumPricing extends BasePricing {

  /** ID. */
  public readonly id: string;

  /** Contractor info. */
  public readonly contractorInfo: SubcontractorInfo;

  /** Whether subcontractor is checked or not. */
  public readonly isChecked: boolean;

  /** ID of the estimate or service to which the pricing relates. */
  public readonly parentId: number;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(data: SubcontractorLumpSumPricingInitArgs) {
    super(data);
    this.id = data.id;
    this.contractorInfo = data.contractorInfo;
    this.isChecked = data.isChecked;
    this.parentId = data.parentId;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }

  /** Whether is bulk update confirmed. */
  public get isBulkUpdateConfirmed(): boolean {
    return !ConfirmedOverridable.hasUnconfirmedOverride([
      this.contractorInfo.name,
      this.contractorInfo.contact,
      this.contractorInfo.description,
      this.contractorInfo.category,
    ]);
  }
}

/** Lump sum pricing init args. */
export type SubcontractorLumpSumPricingInitArgs = BasePricingInitArgs & Pick<SubcontractorLumpSumPricing,
  'id'
  | 'contractorInfo'
  | 'isChecked'
  | 'parentId'
  | 'lockablePercentsStatus'
>;
