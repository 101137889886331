import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { assertNonNullWithReturn } from '@dartsales/common/core/utils/assert-non-null';
import { CatalogTableColumnConfig } from '@dartsales/common/core/utils/catalog-table/catalog-table-column-config';

/** Catalog table header resize component. */
@Component({
  selector: 'dartsalesw-catalog-table-header-resize',
  templateUrl: './catalog-table-header-resize.component.html',
  styleUrls: ['./catalog-table-header-resize.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogTableHeaderResizeComponent {
  /** Column config. */
  @Input({ required: true })
  public columnsConfig: readonly CatalogTableColumnConfig[] = [];

  /** Column name. */
  @Input({ required: true })
  public columnName = '';

  /** Width change event emitter. */
  @Output()
  public readonly widthChanged = new EventEmitter<CatalogTableColumnConfig>();

  /** Column config. */
  protected get config(): CatalogTableColumnConfig {
    const column = this.columnsConfig.find(col => col.name === this.columnName);
    return assertNonNullWithReturn(column, `Table doesn't have column with '${this.columnName}' name.`);
  }

  /**
   * Update column width.
   * @param width Width.
   */
  protected updateColumnWidth(width: number): void {
    if (this.config != null) {
      this.widthChanged.emit({
        ...this.config,
        width,
      });
    }
  }
}
