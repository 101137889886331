import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, TemplateRef, ViewChild, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ViewportRendererContainerDirective } from './viewport-renderer-container.directive';

/**
 * **Important!** This solution is temporary, it was designed to work in points list table.
 * Be careful if you want to use it somewhere else.
 * Consider Angular deferrable views as an alternative: https://angular.dev/guide/defer#triggers \
 * Viewport renderer component. \
 * Placeholder will be in DOM tree until element is visible in the viewport. \
 * Implementation is based on ideas from this article:
 * https://lukaonik.medium.com/unlock-the-power-of-on-demand-rendering-in-angular-e826abc7a3fa.
 * @example
 * ```html
 * <div dartsalescViewportRendererContainer>
 *   <div *ngFor="let item of items">
 *     <dartsalesc-viewport-renderer>
 *       <div placeholder>Placeholder</div>
 *       <ng-template>{{ item }}</ng-template>
 *     </dartsalesc-viewport-renderer>
 *   </div>
 * </div>
 * ```
 */
@Component({
  selector: 'dartsalesc-viewport-renderer',
  templateUrl: './viewport-renderer.component.html',
  styleUrls: ['./viewport-renderer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewportRendererComponent {
  @ViewChild('sentinel', { static: true })
  private readonly sentinel?: ElementRef<HTMLElement>;

  /** Content template. */
  @ContentChild(TemplateRef, { static: true })
  protected readonly template?: TemplateRef<unknown>;

  private readonly viewportContainer = inject(ViewportRendererContainerDirective);

  /** Whether content is visible. */
  protected readonly isContentVisible$ = this.createIsContentVisibleStream();

  private createIsContentVisibleStream(): Observable<boolean> {
    return this.viewportContainer.viewportScrolled$.pipe(
      map(() => this.isVisible()),
    );
  }

  private isVisible(): boolean {
    if (this.sentinel == null) {
      return false;
    }

    const containerRect = this.viewportContainer.elementRef.nativeElement.getBoundingClientRect();
    const { bottom, height, top } = this.sentinel.nativeElement.getBoundingClientRect();
    const renderPadding = 300;
    const containerTop = containerRect.top - renderPadding;
    const containerBottom = containerRect.bottom + renderPadding;

    return top <= containerTop ? containerTop - top <= height : bottom - containerBottom <= height;
  }
}
