import { Injectable, inject } from '@angular/core';
import { Observable, first, map, of, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { PermissionsService } from '../services/permissions.service';
import { CurrentUserService } from '../services/current-user.service';
import { UserPermission } from '../enums/user-permission';

export namespace PermissionsGuard {

  /**
   * Guard that checks user role.
   * Available roles should be in data.permissionType field. If none userRoles specified, no users can enter except Global Admin.
   * @example
   * {
   *   path: 'properties',
   *   canActivate: [PermissionsGuard.canActivate],
   *   data: {
   *     permissionType: Permission.CRUDResource,
   *   },
   *   ...
   * }
   */
  @Injectable({ providedIn: 'root' })
  class PermissionsGuardService {
    private readonly routePaths = injectWebAppRoutes();

    private readonly permissionsService = inject(PermissionsService);

    private readonly userService = inject(CurrentUserService);

    private readonly router = inject(Router);

    /**
     * Determine if route could be achieved.
     * @param route Activated route.
     */
    public canActivate(route: ActivatedRouteSnapshot): ReturnType<CanActivateFn> {
      return this.checkRole(route).pipe(
        map(value => value ? true : this.getRedirectUrl()),
      );
    }

    private checkRole(route: ActivatedRouteSnapshot): Observable<boolean> {
      return this.userService.currentUser$.pipe(
        first(),
        switchMap(user => {
          const permissionType: UserPermission | undefined = route.data?.['permissionType'];

          if (user?.isGlobalAdmin) {
            return of(true);
          }

          if (user == null || permissionType == null) {
            return of(false);
          }

          return this.permissionsService.hasPermission([permissionType]);
        }),
      );
    }

    private getRedirectUrl(): UrlTree {
      return this.router.createUrlTree([this.routePaths.permissionError.url]);
    }
  }

  export const canActivate: CanActivateFn = route =>
    inject(PermissionsGuardService).canActivate(route);
}
