import { StrictOmit } from '../utils/types/strict-omit';

/** Address. */
export class Address {
  /** Address. */
  public readonly address: string;

  /** Country. */
  public readonly country: string;

  /** State / province. */
  public readonly state: string;

  /** City. */
  public readonly city: string;

  /** Zip / postal code. */
  public readonly zip: string;

  /** Get address string. */
  public get addressString(): string {
    const address = [this.country, this.state, this.city, this.zip, this.address].filter(i => i !== '');

    if (address.length) {
      return address.join(', ');
    }

    return '';
  }

  public constructor(data: AddressInitArgs) {
    this.address = data.address;
    this.country = data.country;
    this.state = data.state;
    this.city = data.city;
    this.zip = data.zip;
  }
}

type AddressInitArgs = StrictOmit<Address, 'addressString'>;
