<dartsalesc-editable-table-cell
  class="single-select-table-cell"
  [editModeOnly]="true"
  [matTooltip]="errorMessage ?? ''"
  [isReadonly]="isReadonly"
>
  <ng-container
    *ngIf="!isReadonly"
    dartsalescTableCellEditMode
  >
    <mat-select
      #select
      dartsalescTableCellInput
      class="single-select-table-cell__select"
      [placeholder]="placeholder"
      [formControl]="selectControl"
      [compareWith]="compareWith"
      [errorStateMatcher]="errorStateMatcher"
      (selectionChange)="onSelectionChange()"
    >
      <mat-select-trigger>
        <span [title]="select.triggerValue">
          {{ select.triggerValue }}
        </span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of options; trackBy: trackByIndex"
        tabindex="0"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
  </ng-container>

  <div
    dartsalescTableCellViewMode
    class="select select_mode-view"
  >
    <span
      *ngIf="currentOption !== null; else placeholderView"
      class="select__content"
      [title]="currentOption.label"
    >
      {{ currentOption.label }}
    </span>
    <ng-template #placeholderView>
      <span
        *ngIf="!isReadonly"
        class="select__placeholder"
      >
        {{ placeholder }}
      </span>
    </ng-template>
  </div>
</dartsalesc-editable-table-cell>
