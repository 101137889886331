import { SortDirection as MatSortDirection } from '@angular/material/sort';

/** Sort direction. */
export enum SortDirection {

  /** Sort ascending (A -> Z). */
  ASC = 'asc',

  /** Sort descending (Z -> A). */
  DESC = 'desc',

  /** No sorting. */
  NONE = '',
}

export namespace SortDirection {
  const SORT_DIRECTIONS_MAP: Record<MatSortDirection, SortDirection> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '': SortDirection.NONE,
    'asc': SortDirection.ASC,
    'desc': SortDirection.DESC,
  };

  /**
   * Parse Angular Material sort direction.
   * @param direction Direction.
   */
  export function parseMatSortDirection(direction: MatSortDirection): SortDirection {
    return SORT_DIRECTIONS_MAP[direction];
  }

  /**
   * Get the next sort direction.
   * @example
   * NONE -> ASC -> DESC
   * @param direction Current sort direction.
   */
  export function getNextDirection(direction: SortDirection): SortDirection {
    if (direction === SortDirection.ASC) {
      return SortDirection.DESC;
    }
    if (direction === SortDirection.DESC) {
      return SortDirection.NONE;
    }

    return SortDirection.ASC;
  }
}
