import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';

import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';

/** Search panel component. */
@Component({
  selector: 'dartsalesw-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => SearchPanelComponent)],
})
export class SearchPanelComponent extends SimpleValueAccessor<string> implements OnInit {

  /** Handle submit. */
  @Output()
  public readonly handleSubmit = new EventEmitter<void>();

  /** Handle prev button click. */
  @Output()
  public readonly handlePrevButtonClick = new EventEmitter<MouseEvent>();

  /** Handle next button click. */
  @Output()
  public readonly handleNextButtonClick = new EventEmitter<MouseEvent>();

  /** Is search active. */
  @Input()
  public isSearchActive = false;

  /** Current number. */
  @Input()
  public currentNumber = 0;

  /** Total. */
  @Input()
  public total = 0;

  /** Are 'next' and 'prev' buttons disabled. */
  protected get areNextPrevButtonsDisabled(): boolean {
    return this.total === 0;
  }

  /** Search control. */
  protected readonly searchControl = inject(NonNullableFormBuilder).control('');

  private readonly destroyRef = inject(DestroyRef);

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: string | null): void {
    this.searchControl.setValue(value ?? '', { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    listenControlChanges(this.searchControl, { debounceTime: 0 }).pipe(
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe(value => {
        this.controlValue = value;

        if (value === '') {
          this.handleSubmit.emit();
        }
      });
  }
}
