import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ComponentType } from '@dartsales/common/core/models/filters/component-type';
import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { trackByItem } from '@dartsales/common/core/utils/trackby';

const ALL_COMPONENT_TYPES = ['Part', 'Symbol'] as const satisfies readonly ComponentType[];

/** Component type filters. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-component-type-filters',
  templateUrl: './component-type-filters.component.html',
  styleUrls: ['./component-type-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentTypeFiltersComponent {

  /** Active component type. */
  @Input()
  public activeComponentType: readonly ComponentType[] = [];

  /** Selected component type change. */
  @Output()
  public readonly componentTypeChange = new EventEmitter<ComponentType[]>();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Component type options. */
  protected readonly componentTypeOptions = this.createComponentTypeOptionsStream();

  /** Track by function for component type. */
  protected readonly trackByComponentType = trackByItem;

  /** Component type control. */
  protected readonly componentTypeControl = this.fb.control<ComponentType | null>(null);

  /**
   * Handle selected options change.
   * @param selectedValues Selected values.
   */
  protected onSelectedOptionsChange(selectedValues: readonly ComponentType[]): void {
    this.componentTypeChange.emit([...selectedValues]);
  }

  private createComponentTypeOptionsStream(): OptionSelect<ComponentType>[] {
    return ALL_COMPONENT_TYPES.map(
      type => ({
        value: type,
        label: type,
      }),
    );
  }
}
