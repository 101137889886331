/**
 * Rounds a number using fixed-point notation.
 * @param number Rounded number.
 * @param digits Number of digits to appear after the decimal point.
 */
export function roundToFixed(number: number, digits: number): number {
  return Number(number.toFixed(digits));
}

/**
 * Compare two numbers for equality but round them before comparison.
 * If any of input numbers is empty ('null' or 'undefined') then `false` will be returned.
 * @param a First number.
 * @param b Second number.
 * @param digits Digits after decimal point.
 * @returns `false` if any of input numbers is empty ('null' or 'undefined'),
 *   `true` if numbers are equal,
 *   `false` otherwise.
 */
export function compareRoundNumbers(a?: number | null, b?: number | null, digits = 4): boolean {
  if (a == null || b == null) {
    return false;
  }
  return roundToFixed(a, digits) === roundToFixed(b, digits);
}
