import { EditProjectDetails } from './edit-project-details';

/** Project details. */
export class ProjectDetails extends EditProjectDetails {

  /** Incremental project number in the organization. */
  public readonly projectNumber: number;

  /** Organization logo URL. */
  public readonly organizationLogoUrl: string | null;

  public constructor(data: ProjectDetailsInitArgs) {
    super(data);
    this.projectNumber = data.projectNumber;
    this.organizationLogoUrl = data.organizationLogoUrl;
  }
}

type ProjectDetailsInitArgs = ProjectDetails;
