import { Injectable, inject } from '@angular/core';

import { OverridableLaborTaskHours } from '@dartsales/common/core/models/estimate/modules/points-list/overridable-labor-task-hours';

import { IMapper } from '../../../mappers';
import { PointsListPartLaborTaskHoursDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-labor-task-hours.dto';
import { OverridableMapper } from '../../../overridable.mapper';

/** Points list part labor task hours mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartLaborTaskHoursMapper implements
  IMapper<PointsListPartLaborTaskHoursDto, OverridableLaborTaskHours> {

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListPartLaborTaskHoursDto): OverridableLaborTaskHours {
    return new OverridableLaborTaskHours({
      taskId: dto.taskId,
      hours: this.overridableMapper.fromDto(dto.hours),
    });
  }

  /** @inheritdoc */
  public toDto(data: OverridableLaborTaskHours): PointsListPartLaborTaskHoursDto {
    return {
      taskId: data.taskId,
      hours: this.overridableMapper.toDto(data.hours),
    };
  }
}
