/** Palette manufacturer. */
export class PaletteManufacturer {

  /** Name. */
  public readonly name: string;

  public constructor(data: PaletteManufacturerInitArgs) {
    this.name = data.name;
  }
}

type PaletteManufacturerInitArgs = PaletteManufacturer;
