import { Injectable, inject } from '@angular/core';
import { Observable, Subject, filter, first, map, repeat, shareReplay, switchMap, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { CreateAlternateEstimateData, OverviewAlternateEstimate } from '@dartsales/common/core/models/estimate/alternate-estimate';
import { TermMap } from '@dartsales/common/core/models/term-map';

import { PROJECT_ID_PARAM } from '../../../../../web/src/app/web-route-paths';
import { EstimateService } from '../models/estimate-services/estimate-service';
import { Estimate, EstimateId } from '../models/estimate/estimate';
import { TermSummary } from '../models/estimate-services/term-summary';

import { AlternateEstimateApiService } from './api/alternate-estimate-api.service';
import { BaseEstimateApiService } from './api/base-estimate-api.service';
import { EstimateServicesApiService } from './api/estimate-services-api.service';
import { ProjectNavigationService } from './project-navigation.service';

/** Service with alternate estimates for the current project. */
@Injectable()
export class ProjectAlternateEstimatesService {

  private readonly alternateEstimateApiService = inject(AlternateEstimateApiService);

  private readonly baseEstimateApiService = inject(BaseEstimateApiService);

  private readonly estimateServicesApiService = inject(EstimateServicesApiService);

  private readonly projectNavigationService = inject(ProjectNavigationService);

  private readonly route = inject(ActivatedRoute);

  private readonly refreshSubject = new Subject<void>();

  private readonly projectId$ = this.createProjectIdStream();

  /** Alternate estimates. */
  public readonly estimates$ = this.createAlternateEstimatesStream(this.projectId$);

  /** Number of project's alternate estimates. */
  public readonly alternateEstimatesCounter$ = this.createAlternateEstimatesCounterStream(this.projectId$);

  /**
   * Add a new alternate estimate to project.
   * @param data Data required to create a new alternate estimate.
   */
  public addToProject(data: CreateAlternateEstimateData): Observable<Estimate['id']> {
    return this.projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.create(id, data)),
      tap(() => this.refresh()),
    );
  }

  /** Get new alternate name. */
  public getNewAlternateName(): Observable<string> {
    return this.projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.getNewAlternateName(id)),
    );
  }

  /**
   * Delete an alternate estimate.
   * @param id Alternate estimate ID.
   */
  public remove(id: Estimate['id']): Observable<void> {
    return this.alternateEstimateApiService.deleteById(id).pipe(
      tap(() => {
        this.refreshSubject.next();
        this.projectNavigationService.navigateToDashboard();
      }),
    );
  }

  /**
   * Convert term to a new alternate estimate.
   * @param termId Term id.
   */
  public convertFromTerm(termId: EstimateService['termId']): Observable<OverviewAlternateEstimate['id']> {
    return this.estimateServicesApiService.createAlternateEstimateFromTerm(termId).pipe(
      tap(() => this.refresh()),
    );
  }

  /**
   * Convert all terms to alternate estimates.
   * @param estimateId Estimte ID.
   * @param termIds Term ids.
   */
  public convertFromAllTerms(
    estimateId: EstimateId,
    termIds: readonly TermSummary['id'][],
  ): Observable<TermMap[]> {
    return this.estimateServicesApiService.createAlternateEstimatesFromAllTerms(estimateId, termIds).pipe(
      tap(() => this.refresh()),
    );
  }

  /** Refresh alternate estimates. */
  public refresh(): void {
    this.refreshSubject.next();
  }

  private createProjectIdStream(): Observable<Estimate['id']> {
    return this.route.paramMap.pipe(
      map(params => Number(params.get(PROJECT_ID_PARAM))),
      filter(id => !Number.isNaN(id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createAlternateEstimatesStream(projectId$: Observable<Estimate['id']>): Observable<OverviewAlternateEstimate[]> {
    return projectId$.pipe(
      switchMap(id => this.alternateEstimateApiService.getList(id)),
      first(),
      map(page => [...page.items]),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createAlternateEstimatesCounterStream(projectId$: Observable<Estimate['id']>): Observable<number> {
    return projectId$.pipe(
      switchMap(id => this.baseEstimateApiService.getAlternatesCounter(id)),
      first(),
      repeat({ delay: () => this.refreshSubject }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }
}
