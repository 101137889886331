import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '@dartsales/common/core/models/errors/app-error';
import { LaborRole, LaborRoleEdit, LaborTaskRole } from '@dartsales/common/core/models/resources/labor-role';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { ExpensesRoleFormControls } from '@dartsales/common/core/models/forms/expenses-role-form';

import { DateMapper } from '../date-mapper';
import { EditLaborRoleOrderDto, LaborRoleDto, LaborRoleEditDto, LaborTaskRoleDto } from '../dto/resources/labor-role/labor-role.dto';
import { MappedValidationErrorDto } from '../dto/validation-error.dto';
import { extractErrorMessage } from '../errors/extract-error-message';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from '../mappers';

import { LaborRoleRateMapper } from './labor-role-rate.mapper';

/** Labor role entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleMapper implements
  IMapperFromDto<LaborRoleDto, LaborRole>,
  IMapperToDto<LaborRoleEditDto, LaborRoleEdit>,
  IValidationErrorMapper<LaborRoleEditDto, RawFormValues<ExpensesRoleFormControls>> {

  private readonly dateMapper = inject(DateMapper);

  private readonly laborRoleRateMapper = inject(LaborRoleRateMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborRoleDto): LaborRole {
    return new LaborRole({
      id: dto.id,
      name: dto.name,
      nickname: dto.nickname,
      description: dto.description,
      rate: this.laborRoleRateMapper.fromDto(dto.rate),
      rateOvertimeOne: this.laborRoleRateMapper.fromDto(dto.rateOvertimeOne),
      rateOvertimeTwo: this.laborRoleRateMapper.fromDto(dto.rateOvertimeTwo),
      createdById: dto.createdById,
      createdBy: dto.createdBy,
      removedAt: this.dateMapper.fromDto(dto.removedAt),
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      createdAt: this.dateMapper.fromDto(dto.createdAt),
      isDefault: dto.isDefault,
      order: dto.order,
      hasAssignedTasks: dto.hasAssignedTasks,
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborRoleEdit): LaborRoleEditDto {
    return {
      name: data.name,
      nickname: data.nickname,
      description: data.description,
      rate: this.laborRoleRateMapper.toDto(data.rate),
      rateOvertimeOne: this.laborRoleRateMapper.toDto(data.rateOvertimeOne),
      rateOvertimeTwo: this.laborRoleRateMapper.toDto(data.rateOvertimeTwo),
      order: data.order,
    };
  }

  /** @inheritdoc */
  public fromLaborTaskRoleDto(dto: LaborTaskRoleDto): LaborTaskRole {
    return {
      id: dto.id,
      name: dto.name,
      nickname: dto.nickname,
      description: dto.description,
      internalRate: dto.rate.internalRate,
      internalRateOvertimeOne: dto.rateOvertimeOne.internalRate,
      internalRateOvertimeTwo: dto.rateOvertimeTwo.internalRate,
    };
  }

  /**
   * Map labor role order to dto.
   * @param data Labor role.
   * @param order Labor role order.
   */
  public mapLaborRoleOrderToDto(data: LaborRole, order: number): EditLaborRoleOrderDto {
    return {
      id: data.id,
      order,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<LaborRoleEditDto>,
  ): EntityValidationErrors<RawFormValues<ExpensesRoleFormControls>> {
    return {
      name: extractErrorMessage(errorDto.name),
      nickname: extractErrorMessage(errorDto.nickname),
      description: extractErrorMessage(errorDto.description),
      customerRate: extractErrorMessage(errorDto.rate?.customerRate),
      internalRate: extractErrorMessage(errorDto.rate?.internalRate),
      customerRateOvertimeOne: extractErrorMessage(errorDto.rateOvertimeOne?.customerRate),
      customerRateOvertimeTwo: extractErrorMessage(errorDto.rateOvertimeTwo?.customerRate),
      internalRateOvertimeOne: extractErrorMessage(errorDto.rateOvertimeOne?.internalRate),
      internalRateOvertimeTwo: extractErrorMessage(errorDto.rateOvertimeTwo?.internalRate),
    };
  }
}
