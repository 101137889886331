import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { Project } from '../../models/project/project';
import { Estimate } from '../../models/estimate/estimate';
import { EstimateDto } from '../mappers/dto/estimate/estimate.dto';
import { EstimateMapper } from '../mappers/estimate/estimate.mapper';
import { buildHttpParams } from '../../utils/build-http-params';
import { SuggestedMergeEstimate } from '../../models/estimate/suggested-merge-estimate';
import { SuggestedMergeEstimateDto } from '../mappers/dto/estimate/suggested-merge-estimate.dto';
import { SuggestedMergeEstimateMapper } from '../mappers/estimate/suggested-merge-estimate.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Base Estimate API service. */
@Injectable({
  providedIn: 'root',
})
export class BaseEstimateApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly estimateMapper = inject(EstimateMapper);

  private readonly suggestedMergeEstimateMapper = inject(SuggestedMergeEstimateMapper);

  /**
   * Get base estimate.
   * @param projectId Project id.
   */
  public getBaseEstimate(projectId: number): Observable<Estimate> {
    return this.http.get<EstimateDto>(this.apiUrls.baseEstimateApi.getByEstimateId(projectId)).pipe(
      map(dto => this.estimateMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Obtain number of existing alternates for a particular base estimate.
   * @param estimateId Estimate ID.
   */
  public getAlternatesCounter(estimateId: Project['id']): Observable<number> {
    return this.http.get<number>(this.apiUrls.baseEstimateApi.getAlternatesCounter(estimateId));
  }

  /**
   * Get estimates to merge.
   * @param estimateId Estimate ID.
   * @param searchName Search name.
   */
  public getEstimatesToMerge(estimateId: Project['id'], searchName: string): Observable<SuggestedMergeEstimate[]> {
    const params = buildHttpParams({
      NameContains: searchName,
      IncludeRemoved: false,
    });
    return this.http.get<SuggestedMergeEstimateDto[]>(this.apiUrls.baseEstimateApi.getEstimatesToMerge(estimateId), { params }).pipe(
      map(dtos => dtos.map(dto => this.suggestedMergeEstimateMapper.fromDto(dto))),
    );
  }

  /**
   * Merge estimate modules.
   * @param targetEstimateId Target estimate ID.
   * @param estimateToMergeIds IDs of estimates to merge.
   * @param removeSources Whether to remove merged estimates.
   */
  public mergeEstimateModules(
    targetEstimateId: Estimate['id'],
    estimateToMergeIds: readonly Estimate['id'][],
    removeSources = false,
  ): Observable<void> {
    return this.http.post<void>(
      this.apiUrls.estimateModulesApi.merge(targetEstimateId),
      { estimateToMergeIds, removeSources },
    );
  }
}
