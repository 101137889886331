import { Validators } from '@angular/forms';

import { ValuesRange } from '@dartsales/common/core/models/values-range';
import { DateMapper } from '@dartsales/common/core/services/mappers/date-mapper';
import { AppValidators } from '@dartsales/common/core/utils/validators';
import { DateFilterInputComponent } from '@dartsales/common/shared/components/search-filters/date-filter-input/date-filter-input.component';
import { DateRangeFilterInputComponent } from '@dartsales/common/shared/components/search-filters/date-range-filter-input/date-range-filter-input.component';
import { NumberFilterInputComponent } from '@dartsales/common/shared/components/search-filters/number-filter-input/number-filter-input.component';
import { NumberRangeFilterInputComponent } from '@dartsales/common/shared/components/search-filters/number-range-filter-input/number-range-filter-input.component';
import { TextFilterInputComponent } from '@dartsales/common/shared/components/search-filters/text-filter-input/text-filter-input.component';

import { FilterOperator } from './filter-operator';
import { FilterOperatorMapper } from './filter-operator-mapper';
import { FilterOperatorType } from './filter-operator-type';

const stringMapper = new FilterOperatorMapper<string>({
  getParamsArray: value => [value],
  parseValueFromString: params => params[0],
});

export const textContains = [
  new FilterOperator({
    type: FilterOperatorType.Contains,
    label: 'Contains',
    defaultValue: '',
    component: TextFilterInputComponent,
    validators: [Validators.required],
    mapper: stringMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotContain,
    label: 'Doesn\'t Contain',
    defaultValue: '',
    component: TextFilterInputComponent,
    validators: [Validators.required],
    mapper: stringMapper,
  }),
] as const;

export const textEquals = [
  new FilterOperator({
    type: FilterOperatorType.Equals,
    label: 'Equals',
    defaultValue: '',
    component: TextFilterInputComponent,
    validators: [Validators.required],
    mapper: stringMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotEqual,
    label: 'Doesn\'t Equal',
    defaultValue: '',
    component: TextFilterInputComponent,
    validators: [Validators.required],
    mapper: stringMapper,
  }),
] as const;

const emptyMapper = new FilterOperatorMapper<null>({
  getParamsArray: () => [],
  parseValueFromString: () => null,
});

export const isEmpty = [
  new FilterOperator({
    type: FilterOperatorType.IsEmpty,
    label: 'Empty',
    defaultValue: null,
    mapper: emptyMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.IsNotEmpty,
    label: 'Is not Empty',
    defaultValue: null,
    mapper: emptyMapper,
  }),
] as const;

const dateMapper = new FilterOperatorMapper<Date>({
  getParamsArray: value => [DateMapper.getDateString(value)],
  parseValueFromString: params => DateMapper.parseDate(params[0]) ?? new Date(),
});

const dateRangeMapper = new FilterOperatorMapper<ValuesRange<Date | null>>({
  getParamsArray: value => [
    DateMapper.getDateString(value.start) ?? '',
    DateMapper.getDateString(value.end) ?? '',
  ],
  parseValueFromString: params => ({
    start: DateMapper.parseDate(params[0]),
    end: DateMapper.parseDate(params[1]),
  }),
});

export const dateComparison = [
  new FilterOperator<ValuesRange<Date | null>>({
    type: FilterOperatorType.BetweenAndIncluding,
    label: 'Between and Including',
    defaultValue: {
      start: null,
      end: null,
    },
    component: DateRangeFilterInputComponent,
    validators: [AppValidators.valueRangeRequiredValidator()],
    mapper: dateRangeMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.Equals,
    label: 'Is',
    defaultValue: new Date(),
    component: DateFilterInputComponent,
    validators: [Validators.required],
    mapper: dateMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.EarlierThan,
    label: 'Earlier than',
    defaultValue: new Date(),
    component: DateFilterInputComponent,
    validators: [Validators.required],
    mapper: dateMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.LaterThan,
    label: 'Later than',
    defaultValue: new Date(),
    component: DateFilterInputComponent,
    validators: [Validators.required],
    mapper: dateMapper,
  }),
] as const;

const numberMapper = new FilterOperatorMapper<number>({
  getParamsArray: value => [String(value)],
  parseValueFromString: params => Number(params[0]),
});

const numberRangeMapper = new FilterOperatorMapper<ValuesRange<number | null>>({
  getParamsArray: value => [String(value.start), String(value.end)],
  parseValueFromString: params => ({
    start: Number(params[0]),
    end: Number(params[1]),
  }),
});

export const numberEquals = [
  new FilterOperator({
    type: FilterOperatorType.Equals,
    label: 'Equals',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.NotEqual,
    label: 'Doesn\'t Equal',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
] as const;

export const numberComparison = [
  new FilterOperator({
    type: FilterOperatorType.GreaterThan,
    label: 'Greater than',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.GreaterThanOrEqual,
    label: 'Greater than or Equal to',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.LessThan,
    label: 'Less than',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
  new FilterOperator({
    type: FilterOperatorType.LessThanOrEqual,
    label: 'Less than or Equal to',
    defaultValue: 0,
    component: NumberFilterInputComponent,
    validators: [Validators.required],
    mapper: numberMapper,
  }),
  new FilterOperator<ValuesRange<number | null>>({
    type: FilterOperatorType.BetweenAndIncluding,
    label: 'Between and Including',
    defaultValue: {
      start: null,
      end: null,
    },
    validators: [AppValidators.valueRangeRequiredValidator()],
    component: NumberRangeFilterInputComponent,
    mapper: numberRangeMapper,
  }),
] as const;
