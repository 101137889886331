import { Injectable } from '@angular/core';

import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';

import { PointsListAllEntitiesIdsDto, PointsListDuplicateEntitiesDto, PointsListUngroupEntitiesDto } from '../../../dto/estimate/modules/points-list/points-list-all-entities-ids.dto';
import { IMapperToDto } from '../../../mappers';

/** Points list entities IDs mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListAllEntitiesIdsMapper implements IMapperToDto<PointsListAllEntitiesIdsDto, PointsListAllEntitiesIds> {
  /** @inheritdoc */
  public toDto(data: PointsListAllEntitiesIds): PointsListAllEntitiesIdsDto {
    return {
      tabIds: data.tabIds,
      systemGroupIds: data.systemGroupIds,
      systemIds: data.systemIds,
      partGroupIds: data.partGroupIds,
      partIds: data.partIds,
    };
  }

  /**
   * Transform entities IDs DTO to model.
   * @param dto DTO.
   */
  public fromDuplicateDto(dto: PointsListDuplicateEntitiesDto): PointsListAllEntitiesIds {
    return new PointsListAllEntitiesIds({
      tabIds: dto.tabIds,
      systemGroupIds: dto.systemGroupIds,
      systemIds: dto.systemIds,
      partGroupIds: dto.partGroupIds,
      partIds: dto.partIds,
    });
  }

  /**
   * Create group entities IDs DTO for ungrouping.
   * @param data Data.
   */
  public toUngroupDto(data: PointsListAllEntitiesIds): PointsListUngroupEntitiesDto {
    return {
      systemGroupIds: data.systemGroupIds,
      partGroupIds: data.partGroupIds,
    };
  }
}
