import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';

import { TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';
import { TermCategoryTreeItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';

import { provideNestedTreeSelectService } from '../../../nested-tree-select/nested-tree-selection-service.provider';
import { EstimateNestedTreeSelectionService } from '../../estimate-nested-tree-selection.service';
import { EstimateItemsMultipleExportTreeTypes } from '../../export-multiple-utils/estimate-items-multiple-export-tree-types';
import { EstimateItemsMultipleExportTreeMappersToNode } from '../../export-multiple-utils/estimate-items-multiple-export-tree-mappers-to-node';
import { EstimateTermsMultipleExportTreeMapper } from '../../export-multiple-utils/estimate-terms-multiple-export-tree-mapper';

/** Select term categories tree component. */
@Component({
  selector: 'dartsalesw-select-term-categories-tree',
  templateUrl: './select-term-categories-tree.component.html',
  styleUrls: ['./select-term-categories-tree.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNestedTreeSelectService(EstimateNestedTreeSelectionService)],
})
export class SelectTermCategoriesTreeComponent {

  /** Term tree. */
  @Input()
  public set termTree(tree: TermTreeItem) {
    this.termTree$.next(tree);
  }

  private readonly termTree$ = new ReplaySubject<TermTreeItem>(1);

  /** Selected. */
  @Output()
  public readonly selected = new EventEmitter<readonly TermCategoryTreeItem[]>();

  /** Nested nodes. */
  protected readonly nestedNodes$ = this.createCategoriesNestedNodesStream();

  /**
   * Handle selections changes.
   * @param selectedNodes Selected nodes.
   * @param categoriesNestedNodes Nested nodes.
   */
  protected handleSelectionChanges(
    selectedNodes: readonly EstimateItemsMultipleExportTreeTypes.EstimateChildrenFlatNode[],
    categoriesNestedNodes: readonly EstimateItemsMultipleExportTreeTypes.EstimateChildrenNestedNode[],
  ): void {
    const selectedCategories = EstimateTermsMultipleExportTreeMapper.buildTreeFromCategoryNestedNodes(categoriesNestedNodes, selectedNodes);
    this.selected.emit(selectedCategories);
  }

  private createCategoriesNestedNodesStream(): Observable<EstimateItemsMultipleExportTreeTypes.EstimateChildrenNestedNode[]> {
    return this.termTree$.pipe(
      map(term => term != null ? [...EstimateItemsMultipleExportTreeMappersToNode.mapTerm(term).children] : []),
    );
  }
}
