import { Injectable, inject } from '@angular/core';
import { first, map, shareReplay, switchMap } from 'rxjs';

import { StorageService } from '@dartsales/common/core/services/storage.service';

const SIDEBAR_OPENED_STATE_STORAGE_KEY = 'is-sidebar-opened';

/** Project sidebar service. */
@Injectable({
  providedIn: 'root',
})
export class ProjectSidebarService {

  private readonly storageService = inject(StorageService);

  /** Is sidebar opened. */
  public readonly isSidebarOpened$ = this.storageService
    .get<boolean>(SIDEBAR_OPENED_STATE_STORAGE_KEY)
    .pipe(
      map(isSidebarOpened => isSidebarOpened ?? true),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  /** Toggle sidebar. */
  public toggleSidebar(): void {
    this.isSidebarOpened$.pipe(
      first(),
      switchMap(isSidebarOpened => this.storageService.save(SIDEBAR_OPENED_STATE_STORAGE_KEY, !isSidebarOpened)),
      first(),
    ).subscribe();
  }
}
