<mat-form-field class="no-label">
  <mat-select
    placeholder="Select"
    [multiple]="true"
    [formControl]="innerFormControl"
  >
    <mat-option
      *ngFor="let item of bidCategoryIdsOptions$ | async; trackBy: trackByIndex"
      [value]="item.value"
    >
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
