import { Injectable, inject } from '@angular/core';

import { OverridablePartStackedPort, PartStackedPort } from '@dartsales/common/core/models/components/estimate-part/estimate-part-stacked-port';

import { OverridablePartStackedPortDto, PartStackedPortDto } from '../../dto/components/estimate-part/estimate-part-stacked-port.dto';
import { IMapper } from '../../mappers';
import { ConfirmedOverridableMapper } from '../../confirmed-overridable.mapper';

import { EstimatePartStackedPortChildMapper } from './estimate-part-stacked-port-child.mapper';

/** EstimatePartStackedPort entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimatePartStackedPortMapper implements IMapper<PartStackedPortDto, PartStackedPort> {

  private readonly estimatePartStackedPortChildMapper = inject(EstimatePartStackedPortChildMapper);

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PartStackedPortDto): PartStackedPort {
    return new PartStackedPort({
      id: dto.id,
      name: dto.name,
      activePortId: dto.activePortId,
      ports: dto.ports.map(port => this.estimatePartStackedPortChildMapper.fromDto(port)),
    });
  }

  /** @inheritdoc */
  public fromOverridableDto(dto: OverridablePartStackedPortDto): OverridablePartStackedPort {
    return new OverridablePartStackedPort({
      id: dto.id,
      name: dto.name,
      activePortId: this.confirmedOverridableMapper.fromDto(dto.activePortId),
      ports: dto.ports.map(port => this.estimatePartStackedPortChildMapper.fromDto(port)),
    });
  }

  /** @inheritdoc */
  public toDto(data: PartStackedPort): PartStackedPortDto {
    return {
      id: data.id,
      name: data.name,
      activePortId: data.activePortId,
      ports: data.ports.map(port => this.estimatePartStackedPortChildMapper.toDto(port)),
    };
  }

  /** @inheritdoc */
  public toOverridableDto(data: OverridablePartStackedPort): OverridablePartStackedPortDto {
    return {
      id: data.id,
      name: data.name,
      activePortId: this.confirmedOverridableMapper.toDto(data.activePortId),
      ports: data.ports.map(port => this.estimatePartStackedPortChildMapper.toDto(port)),
    };
  }
}
