import { ChangeDetectionStrategy, Component } from '@angular/core';

import { expandAnimation } from '@dartsales/common/shared/animations/expand-animation';

import { ExpandableRowComponent } from '../expandable-row.component';

/**
 * Flat row with expandable content.
 * This component is required to fit in current designs for rows that are not rounded
 * like in most of the cases.
 * Here is an example of such row - https://projects.invisionapp.com/d/main#/console/22262762/473009093/preview
 * You can see that top level rows have different styles than default expandable.
 */
@Component({
  selector: 'dartsalesw-flat-expandable-row',
  templateUrl: '../../expandable-row/expandable-row.component.html',
  styleUrls: ['./flat-expandable-row.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandAnimation],
})
export class FlatExpandableRowComponent extends ExpandableRowComponent {}
