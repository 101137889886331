import { FormControl, FormGroupDirective, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { ValidationErrorCode, ValuesRangeErrorData } from '../models/errors/validation-error-code';
import { ValuesRange } from '../models/values-range';

import { ControlsOf } from './types/form';

/** Error state matcher for values range. */
export class ValuesRangeStateMatcher<T> implements ErrorStateMatcher {
  public constructor(
    private readonly control: FormControl<ValuesRange<T>>,
  ) { }

  /** @inheritdoc */
  public isErrorState(control: FormControl<T>, form: FormGroupDirective): boolean {
    const parentForm = form.control as FormGroup<ControlsOf<ValuesRange<T | null>>>;
    const valuesRangeError = this.control.errors?.[ValidationErrorCode.ValuesRangeError] as ValuesRangeErrorData | null;

    if (this.control.untouched || !valuesRangeError || !parentForm) {
      return false;
    }

    if (parentForm.controls.start === control && valuesRangeError.errorFields.includes('start')) {
      return true;
    }

    if (parentForm.controls.end === control && valuesRangeError.errorFields.includes('end')) {
      return true;
    }

    return false;
  }
}
