/** Change order custom value. */
export class ChangeOrderCustomValue {

  /** Change order custom column ID (UUID). */
  public readonly id: string;

  /** Value. */
  public readonly value: string;

  public constructor(data: ChangeOrderCustomValueInitArgs) {
    this.id = data.id;
    this.value = data.value;
  }
}

type ChangeOrderCustomValueInitArgs = ChangeOrderCustomValue;
