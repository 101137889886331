/** All Filter field operators type. */
export enum FilterOperatorType {
  Contains = 'Contains',
  NotContain = 'NotContains',

  Equals = 'Equals',
  NotEqual = 'NotEquals',

  IsOneOf = 'IsOneOf',
  IsNotOneOf = 'IsNotOneOf',

  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',

  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqualTo',

  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqualTo',

  EarlierThan = 'EarlierThan',
  LaterThan = 'LaterThan',

  BetweenAndIncluding = 'BetweenAndIncluding',
}
