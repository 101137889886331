<div class="amount-input-container container">
  <input
    class="amount-input body-2"
    placeholder="0"
    [class.amount-input_reset-available]="isResetAvailable"
    [inputMask]="maskOptions"
    [formControl]="inputControl"
    (blur)="onInputBlur($event)"
  />
  <button
    *ngIf="isResetAvailable"
    #resetButton
    mat-icon-button
    class="reset-button"
    type="button"
    title="Reset value"
    (click)="onResetButtonClick()"
  >
    <mat-icon>refresh</mat-icon>
  </button>
</div>
