import { Injectable, inject } from '@angular/core';

import { TermService } from '@dartsales/common/core/models/estimate-services/term-service';

import { TermServiceDto } from '../dto/estimate/term-service-modules.dto';
import { IMapperFromDto } from '../mappers';
import { ModulesListMapper } from '../estimate/modules-list.mapper';

/** Term service mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermServiceMapper implements IMapperFromDto<TermServiceDto, TermService> {
  private readonly modulesListMapper = inject(ModulesListMapper);

  /** @inheritdoc */
  public fromDto(dto: TermServiceDto): TermService {
    return new TermService({
      serviceId: dto.id,
      termId: dto.termId,
      name: dto.name,
      description: dto.description,
      modules: this.modulesListMapper.fromBaseModulesDto(dto),
    });
  }
}
