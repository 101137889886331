import { Injectable, inject } from '@angular/core';

import { TermSummary } from '@dartsales/common/core/models/estimate-services/term-summary';

import { TermSummaryDto } from '../dto/estimate-services/term-summary.dto';
import { IMapper } from '../mappers';

import { TermPropertiesMapper } from './term-properties.mapper';

/** Service term summary mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermSummaryMapper implements IMapper<TermSummaryDto, TermSummary> {

  private readonly termPropertiesMapper = inject(TermPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: TermSummaryDto): TermSummary {
    return new TermSummary({
      id: dto.id,
      number: dto.number,
      order: dto.order,
      properties: this.termPropertiesMapper.fromDto(dto.properties, dto.order),
    });
  }

  /** @inheritdoc */
  public toDto(model: TermSummary): TermSummaryDto {
    return {
      id: model.id,
      number: model.number,
      order: model.order,
      properties: this.termPropertiesMapper.toDto(model.properties),
    };
  }
}
