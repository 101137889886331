import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Observable, ReplaySubject, combineLatest, map, switchMap } from 'rxjs';

import { ViewType } from '@dartsales/common/core/models/view-type';
import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateTermsTreeService } from '@dartsales/common/core/services/estimate-terms-tree.service';
import { EstimateViewExportParams } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';

import { provideNestedTreeSelectService } from '../../../../nested-tree-select/nested-tree-selection-service.provider';
import { EstimateItemsMultipleExportTreeTypes } from '../../../export-multiple-utils/estimate-items-multiple-export-tree-types';
import { EstimateNestedTreeSelectionService } from '../../../estimate-nested-tree-selection.service';
import { EstimateItemsMultipleExportNestedNodeBuilder } from '../../../export-multiple-utils/estimate-items-multiple-export-tree-nested-node-builder';
import { EstimateItemsMultipleExportTreeBuilder } from '../../../export-multiple-utils/estimate-items-multiple-export-tree-builder';

/** Select estimate children control component. */
@Component({
  selector: 'dartsalesw-select-estimate-children-control',
  templateUrl: './select-estimate-children-control.component.html',
  styleUrls: ['./select-estimate-children-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideNestedTreeSelectService(EstimateNestedTreeSelectionService)],
})
export class SelectEstimateChildrenControlComponent {

  /** Estimate view type. */
  @Input()
  public set viewType(view: ViewType) {
    this.viewType$.next(view);
  }

  /** Estimate ID. */
  @Input()
  public set estimateId(estimateId: EstimateId) {
    this.estimateId$.next(estimateId);
  }

  /** Params selected. */
  @Output()
  public readonly paramsSelected = new EventEmitter<EstimateViewExportParams>();

  private readonly viewType$ = new ReplaySubject<ViewType>(1);

  private readonly estimateId$ = new ReplaySubject<EstimateId>(1);

  private readonly estimateTermsTreeService = inject(EstimateTermsTreeService);

  /** Nested nodes. */
  protected readonly nestedNodes$ = this.createNestedNodesStream();

  /**
   * Handle nodes select.
   * @param selectedNodes Selected nodes.
   * @param sourceNode Source estimate node.
   */
  protected handleNodesSelect(
    selectedNodes: readonly EstimateItemsMultipleExportTreeTypes.EstimateChildrenFlatNode[],
    sourceNode: EstimateItemsMultipleExportTreeTypes.EstimateChildrenNestedNode,
  ): void {
    const params = EstimateItemsMultipleExportTreeBuilder.buildConfig(sourceNode, selectedNodes);
    this.paramsSelected.emit(params);
  }

  private createNestedNodesStream(): Observable<EstimateItemsMultipleExportTreeTypes.EstimateChildrenNestedNode> {
    return combineLatest([
      this.viewType$,
      this.estimateId$,
    ]).pipe(
      switchMap(([viewType, estimateId]) =>
        this.estimateTermsTreeService.getEstimateTermTrees(estimateId, viewType).pipe(
          map(terms => EstimateItemsMultipleExportNestedNodeBuilder.createEstimateNestedTree(viewType, terms)),
        )),
    );
  }
}
