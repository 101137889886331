import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { cloneWithAssign } from '@dartsales/common/core/utils/clone-with-assign';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { BasePricing, BasePricingAmounts, BasePricingComposition, CalculationBasePricing, isBasePricingComposition } from '../../pricing/base-pricing';
import { MarginValues } from '../../estimate/modules/margin';
import { SubcontractorUnitPricing } from '../../estimate/modules/subcontractor/unit-pricing/unit-pricing';

import { DomainSellPriceCalculator } from './domain-estimate-sell-price-calculator';
import { DomainMarginCalculator } from './domain-margin-calculator';
import { DomainContingencyCalculator } from './domain-contingency-calculator';
import { DomainCostCalculator } from './domain-cost-calculator';
import { DomainEscalationCalculator } from './domain-escalation-calculator';

/**
 * Domain base pricing calculator.
 * Contain utility functions for any calculation of base pricing values.
 **/
export namespace DomainBasePricingCalculator {

  const DEFAULT_SUMMARY_VALUES: BasePricingAmounts = {
    directCost: 0,
    contingencyAmount: 0,
    marginAmount: 0,
    escalationAmount: 0,
  };

  /**
   * Units configuration for calculation values of pricing models.
   */
  export type CalculationUnitsArgs = {

    /** Which units use to calculate contingency. */
    readonly contingencyUnits?: AmountCalcUnits;

    /** Which units use to calculate margin. */
    readonly marginUnits?: AmountCalcUnits;

    /** Which margin type (gross margin or markup) is based for calculation. */
    readonly marginType?: MarginType;

    /** Which units use to calculate escalation. */
    readonly escalationUnits?: AmountCalcUnits;

  };

  /**
   * Arguments for calculation values of BasePricing models.
   */
  export type CalculationArgs = {

    /** Pricing elements for calculation. */
    readonly originalPricing: CalculationBasePricing;
  } & CalculationUnitsArgs;

  /**
   * Arguments for bulk update `contingency`, `gross margin`, and `escalation` percents for item list.
   */
  export type BulkUpdatePercentArgs = {

    /** New percent for contingency value. */
    readonly contingencyPercent?: number;

    /** Escalation percent. */
    readonly escalationPercent?: number;

    /** Gross margin percent. */
    readonly grossMarginPercent?: number;

    /**
     * New percents for margin/markup values.
     * @deprecated Use `grossMarginPercent` instead.
     */
    readonly marginPercents?: Partial<MarginValues>;

    /**
     * New percent for markup, used for backward compatibility.
     * @deprecated
     **/
    readonly markupPercent?: number;
  } & StrictOmit<CalculationUnitsArgs, 'contingencyUnits' | 'escalationUnits' | 'marginUnits'>;

  /**
   * Calculate all values of BasePricing models.
   * @param calcParams Calculation arguments.
   * @returns New values for BasePricing model.
   */
  export function calculatePricing(calcParams: CalculationArgs): BasePricing {
    const {
      directCost,
      contingency: originalContingency,
      margin: originalMargin,
      escalation: originalEscalation,
    } = calcParams.originalPricing;

    // Default calculation params.
    const contingencyUnits = calcParams.contingencyUnits ?? AmountCalcUnits.DEFAULT;
    const escalationUnits = calcParams.escalationUnits ?? AmountCalcUnits.DEFAULT;
    const marginUnits = calcParams.marginUnits ?? AmountCalcUnits.DEFAULT;
    const marginType = calcParams.marginType ?? MarginType.DEFAULT;

    const contingency = DomainContingencyCalculator.calculateContingency({
      directCost,
      value: originalContingency,
      units: contingencyUnits,
    });

    const escalation = DomainEscalationCalculator.calculateEscalation({
      directCost,
      contingencyAmount: contingency.amount,
      escalationValue: originalEscalation,
      escalationUnits,
    });

    const margin = DomainMarginCalculator.calculateMargin({
      directCost,
      contingencyAmount: contingency.amount,
      escalationAmount: escalation.amount,
      values: {
        amount: originalMargin.grossMargin?.amount,
        grossMargin: originalMargin.grossMargin?.percent,
        markup: originalMargin.markup?.percent,
      },
      marginParams: { marginType, units: marginUnits },
    });

    const sellPrice = DomainSellPriceCalculator.calculateSellPrice({
      directCost,
      contingencyAmount: contingency.amount,
      marginAmount: margin.grossMargin.amount,
      escalationAmount: escalation.amount,
    });

    return new BasePricing({
      directCost,
      contingency,
      margin,
      markup: margin.markup,
      sellPrice,
      escalation,
    });
  }

  /**
   * Calculate summary pricing for list of BasePricing items.
   * @param items List of sub-items.
   **/
  export function calculateItemsSummaryPricing(items: readonly BasePricing[]): BasePricing {
    const summaries = items.reduce((acc, item) => ({
      directCost: acc.directCost + item.directCost,
      contingencyAmount: acc.contingencyAmount + item.contingency.amount,
      marginAmount: acc.marginAmount + item.markup.amount,
      escalationAmount: acc.escalationAmount + item.escalation.amount,
    }), DEFAULT_SUMMARY_VALUES);

    return calculatePricing({
      originalPricing: {
        directCost: summaries.directCost,
        contingency: {
          amount: summaries.contingencyAmount,
        },
        margin: {
          grossMargin: {
            amount: summaries.marginAmount,
          },
        },
        escalation: {
          amount: summaries.escalationAmount,
        },
      },
      contingencyUnits: AmountCalcUnits.Amount,
      marginType: MarginType.GrossMargin,
      marginUnits: AmountCalcUnits.Amount,
      escalationUnits: AmountCalcUnits.Amount,
    });
  }

  /**
   * Update pricing for single item by new `contingency` and `margin` percents.
   * @param item Item for updates.
   * @param calcParams Calculation arguments with new `contingency` and `margin` percents for update.
   * @returns List of updated sub-items.
   **/
  export function updateItemPercents<T extends BasePricing | BasePricingComposition>(
    item: T,
    calcParams: BulkUpdatePercentArgs,
  ): T {
    const itemPricing: BasePricing = isBasePricingComposition(item) ? item.pricing : item;

    const newPricing = DomainBasePricingCalculator.calculatePricing({
      originalPricing: {
        ...itemPricing,
        contingency: {
          percent: calcParams.contingencyPercent ?? itemPricing.contingency.percent,
          amount: itemPricing.contingency.amount,
        },
        margin: {
          grossMargin: {
            percent: calcParams.marginPercents?.grossMargin ?? itemPricing.margin.grossMargin.percent,
            amount: itemPricing.margin.grossMargin.amount,
          },
          markup: {
            percent: calcParams.marginPercents?.markup ?? calcParams.marginPercents?.markup ?? itemPricing.margin.markup.percent,
            amount: itemPricing.margin.markup.amount,
          },
        },
        escalation: {
          percent: calcParams.escalationPercent ?? itemPricing.escalation.percent,
          amount: itemPricing.escalation.amount,
        },
      },
      contingencyUnits: AmountCalcUnits.Percent,
      escalationUnits: AmountCalcUnits.Percent,
      marginType: calcParams.marginType,
      marginUnits: AmountCalcUnits.Percent,
    });

    return cloneWithAssign(item,
      isBasePricingComposition(item) ?
        {
          pricing: cloneWithAssign(item.pricing, newPricing),
        } :
        {
          ...newPricing,
        });
  }

  /*
    TODO (Pavel Z.) We have approval from PM that markups are always calculated from direct cost, not unit cost,
    so we can rework the calculators by getting rid of this method.
  */
  /**
   * Calculate summary pricing for list of BaseUnitPricing items with contingency and margin set for unit level.
   * @param items List of sub-items.
   */
  export function calculateUnitItemsSummaryPricing(items: readonly SubcontractorUnitPricing[]): BasePricing {
    const summaries = items.reduce((acc, item) => ({
      directCost: acc.directCost + DomainCostCalculator.calculateQuantityCost({ ...item, quantity: item.quantity.combinedValue }),
      contingencyAmount: acc.contingencyAmount + (item.contingency.amount * item.quantity.combinedValue),
      marginAmount: acc.marginAmount + (item.markup.amount * item.quantity.combinedValue),
      escalationAmount: acc.escalationAmount + (item.escalation.amount * item.quantity.combinedValue),
    }), DEFAULT_SUMMARY_VALUES);

    return calculatePricing({
      originalPricing: {
        directCost: summaries.directCost,
        contingency: {
          amount: summaries.contingencyAmount,
        },
        margin: {
          grossMargin: {
            amount: summaries.marginAmount,
          },
        },
        escalation: {
          amount: summaries.escalationAmount,
        },
      },
      contingencyUnits: AmountCalcUnits.Amount,
      marginType: MarginType.GrossMargin,
      marginUnits: AmountCalcUnits.Amount,
      escalationUnits: AmountCalcUnits.Amount,
    });
  }
}
