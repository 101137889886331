import { Directive, Input, inject, HostBinding, ViewChild, ElementRef, OnInit, DestroyRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, fromEvent, startWith } from 'rxjs';

import { WINDOW } from '@dartsales/common/core/utils/window-token';

import { CellRendererService, RelativePosition } from '../services/cell-renderer.service';
import { CellDirective } from '../directives/cell.directive';
import { CellRendererContentDirective } from '../directives/cell-renderer-content.directive';

/**
 * Base class for Renderer cell component.
 *
 * BaseCellRendererComponent is a part of cell workflow described in: AbstractCellFormControlComponent.
 * Its task is to display the data and inform the parent about the focus parameters.
 *
 * Warning: Due to the design features, the component will not function properly if it is not destroy after focus.
 *
 * Works in conjunction with {@link CellRendererContentDirective} for send focus cursor parameters.
 */
@Directive({
  standalone: true,
  host: {
    class: 'dartsalesc-cell_renderer',
  },
})
export class BaseCellRendererComponent<T> extends CellDirective<T> implements OnInit {

  /** Content. */
  @ViewChild(CellRendererContentDirective)
  private readonly content?: CellRendererContentDirective;

  private readonly document = inject(DOCUMENT);

  private readonly elementRef = inject(ElementRef);

  private readonly destroyRef = inject(DestroyRef);

  private readonly window = inject(WINDOW);

  /**
   * Is cell focusable.
   * Warning: Affects the completion of online subscriptions.
   */
  @Input()
  public isFocusable = true;

  private readonly viewCellService = inject(CellRendererService);

  /** Tab index. */
  @HostBinding('attr.tabindex')
  public get tabIndex(): number | undefined {
    return this.isFocusable ? 0 : undefined;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToCellFocus();
  }

  private subscribeToCellFocus(): void {
    if (!this.isFocusable) {
      return;
    }

    const mousedownEvent$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mousedown');
    const focusInEvent$ = fromEvent<FocusEvent>(this.elementRef.nativeElement, 'focusin');

    combineLatest([
      mousedownEvent$.pipe(startWith(null)),
      focusInEvent$,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(0),
    )
      .subscribe(([mousedownEvent]) => {
        const isFocusByKeyboardClick = mousedownEvent === null;
        if (isFocusByKeyboardClick) {
          this.emitFocusWithAllContentSelection();
        }

        if (this.checkIsCursorInContent()) {
          this.emitFocusWithCursorPositionIndex();
          return;
        }

        this.emitFocusWithRelativeToContentPosition(mousedownEvent);
    });
  }

  private checkIsCursorInContent(): boolean {
    return this.document.activeElement === this.content?.elementRef.nativeElement;
  }

  private emitFocusWithCursorPositionIndex(): void {
    const cursorPositionIndex = this.getCursorPositionIndexInContent();
    this.viewCellService.focus$.next(
      cursorPositionIndex != null ? { selection: { type: 'index', index: cursorPositionIndex } } : null,
    );
  }

  private emitFocusWithRelativeToContentPosition(mousedownEvent: MouseEvent | null): void {
    const cursorPositionRelativeToContent = this.getCursorPositionRelativeToContent(mousedownEvent);
    this.viewCellService.focus$.next(
      cursorPositionRelativeToContent != null ? {
        selection: { type: 'relative', position: cursorPositionRelativeToContent },
      } : null,
    );
  }

  private emitFocusWithAllContentSelection(): void {
    this.viewCellService.focus$.next({ selection: { type: 'all-content' } });
  }

  // Please note that the check must occur after the focus render, otherwise the selector will be missing.
  private getCursorPositionIndexInContent(): number | null {
    const selection = this.window.getSelection();
    if (selection == null || selection.rangeCount <= 0) {
      return null;
    }
    return selection.getRangeAt(0).endOffset - 1;
  }

  private getCursorPositionRelativeToContent(mousedownEvent: MouseEvent | null): RelativePosition | null {
    const contentElement = this.content?.elementRef.nativeElement;
    if (contentElement == null || mousedownEvent == null) {
      return null;
    }

    const clickX = mousedownEvent.clientX;
    const contentElementX = contentElement.getBoundingClientRect().x;
    const isClickBeforeContent = clickX < contentElementX;

    return isClickBeforeContent ? RelativePosition.Before : RelativePosition.After;
  }
}
