import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DefaultTableColumnConfig } from '../../types/column-config';

/** Header cell content component. */
@Component({
  selector: 'dartsalesc-header-cell-content',
  templateUrl: './header-cell-content.component.html',
  styleUrls: ['./header-cell-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCellContentComponent {

  /** Is sort active. */
  @Input({ required: true })
  public isSortActive = false;

  /** Column config. */
  @Input({ required: true })
  public columnConfig: DefaultTableColumnConfig | null = null;
}
