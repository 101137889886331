import { Injectable, inject } from '@angular/core';

import { FinalEstimateTerm, FinalEstimateTermContainer } from '@dartsales/common/core/models/estimate/modules/service-totals/final-estimate-term';

import { FinalEstimatePricingMapper } from '../../../../pricing/final-estimate-pricing.mapper';
import { IMapperFromDto } from '../../../../mappers';
import { FinalEstimateTermContainerDto, FinalEstimateTermDto } from '../../../../dto/estimate/final-estimate/modules/service-totals/final-estimate-term.dto';

/** Final estimate service term mapper. */
@Injectable({
  providedIn: 'root',
})
export class FinalEstimateServiceTotalsTermMapper implements
  IMapperFromDto<FinalEstimateTermDto, FinalEstimateTerm> {

  private readonly finalEstimatePricingMapper = inject(FinalEstimatePricingMapper);

  /** @inheritdoc */
  public fromDto(
    dto: FinalEstimateTermDto,
  ): FinalEstimateTerm {
    return new FinalEstimateTerm({
      ...this.finalEstimatePricingMapper.fromDto(dto),
      number: dto.number,
      hasGrossMarginOverride: dto.hasGrossMarginOverride,
      hasTotalDirectCostOverride: dto.hasTotalDirectCostOverride,
    });
  }

  /**
   * Maps term container DTO to Domain model.
   * @param dto Container DTO.
   **/
  public termContainerFromDto(
    dto: FinalEstimateTermContainerDto,
  ): FinalEstimateTermContainer {
    return {
      terms: dto.terms.map(term => this.fromDto(term)) ?? [],
      totalTermValues: this.finalEstimatePricingMapper.fromDto(dto.totalTermValues),
      averageTermValues: this.finalEstimatePricingMapper.fromDto(dto.averageTermValues),
    };
  }
}
