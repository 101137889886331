import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';

import { PointsListAbstractCommand } from '../commands/points-list-abstract-command';
import { AbstractCommand } from '../commands/abstract-command';
import { PointsListEmptyCommand } from '../commands/empty-command';
import { PointsListIrreversibleActionCommand } from '../commands/points-list-irreverisble-action-command';

/** Points list undo/redo management service. */
@Injectable()
export class PointsListUndoRedoService {
  private readonly undoStack: PointsListAbstractCommand[] = [];

  private readonly redoStack: PointsListAbstractCommand[] = [];

  private readonly updateTrigger$ = new BehaviorSubject<void>(undefined);

  /** Is undo action available. */
  public readonly isUndoAvailable$ = this.updateTrigger$.pipe(
    map(() => this.undoStack.length > 0),
  );

  /** Is redo action available. */
  public readonly isRedoAvailable$ = this.updateTrigger$.pipe(
    map(() => this.redoStack.length > 0),
  );

  /** Get undo command. */
  public getUndoCommand(): PointsListEmptyCommand | null {
    const command = this.undoStack.pop();
    if (command == null) {
      return null;
    }

    // We have to wrap 'undo' action with command to perform additional actions after it.
    return new PointsListEmptyCommand({
      action: () => command.undo().pipe(
        tap(() => {
          this.redoStack.push(command);
          this.updateTrigger$.next();
        }),
      ),
    });
  }

  /** Get redo command. */
  public getRedoCommand(): PointsListEmptyCommand | null {
    const command = this.redoStack.pop();
    if (command == null) {
      return null;
    }

    // We have to wrap 'redo' action with command to perform additional actions after it.
    return new PointsListEmptyCommand({
      action: () => command.redo().pipe(
        tap(() => {
          this.undoStack.push(command);
          this.updateTrigger$.next();
        }),
      ),
    });
  }

  /**
   * Save command in history.
   * @param command Command.
   */
  public saveCommandInHistory(command: AbstractCommand): void {
    if (command instanceof PointsListIrreversibleActionCommand) {
      this.resetUndoRedoState();
      return;
    }

    if (command instanceof PointsListAbstractCommand) {
      this.undoStack.push(command);
      this.redoStack.splice(0, this.redoStack.length);
      this.updateTrigger$.next();
    }
  }

  /** Reset undo/redo state. */
  public resetUndoRedoState(): void {
    this.undoStack.splice(0, this.undoStack.length);
    this.redoStack.splice(0, this.redoStack.length);
    this.updateTrigger$.next();
  }
}
