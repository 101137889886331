<table class="editable-table">
  <thead class="editable-table__header">
    <dartsalesc-progress-bar [loading]="loading" />
    <tr
      *ngFor="let headerRow of headerRows; trackBy: trackByIndex"
      class="table-row"
      dartsalescEditableTableStickyRow="top"
    >
      <td
        *ngIf="enableDraggableRows"
        class="table-cell"
        dartsalescEditableTableStickyColumnCell="left"
      >
        <!-- Empty. -->
      </td>
      <ng-container
        [ngTemplateOutlet]="rowCellsTemplate"
        [ngTemplateOutletContext]="{ $implicit: headerRow.tableColumns }"
      />
    </tr>
  </thead>
  <ng-container *ngIf="useVirtualScroll; else withoutVirtualScroll">
    <!-- We have to add this invisible fake row to display cells with colspan correctly. -->
    <tr
      *ngIf="bodyRows?.get(0) as row"
      class="table-row virtual-scroll-fake-row"
      [ngClass]="row.rowClasses"
    >
      <td
        *ngIf="enableDraggableRows"
        dartsalescEditableTableStickyColumnCell="left"
        class="table-cell drag-cell"
      ></td>
      <ng-container
        [ngTemplateOutlet]="rowCellsTemplate"
        [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
      />
    </tr>
    <cdk-virtual-scroll-viewport
      *ngrxLet="bodyRows?.changes | async"
      #scrollViewPort
      [itemSize]="virtualScrollItemSizePx"
      [minBufferPx]="virtualScrollItemSizePx * virtualScrollMinBufferItems"
      [maxBufferPx]="
        virtualScrollItemSizePx * (virtualScrollMinBufferItems + 10)
      "
    >
      <div
        *ngrxLet="scrollViewPort.renderedRangeStream | async as listRange"
        cdkDropList
        class="editable-table__body"
        [cdkDropListSortPredicate]="dragSortPredicate"
        [cdkDropListData]="
          rowsForVirtualScroll.slice(listRange?.start ?? 0, listRange?.end)
        "
        [cdkDropListDisabled]="!enableDraggableRows"
      >
        <tr
          *cdkVirtualFor="
            let row of rowsForVirtualScroll;
            templateCacheSize: 0;
            trackBy: trackByIndex;
            let index = index
          "
          class="table-row"
          cdkDrag
          [cdkDragDisabled]="!row.isDraggable"
          [cdkDragData]="{ row, index }"
          [dartsalescEditableTableStickyRow]="row.stickySide"
          [class.table-row_highlighted]="row.isHighlighted"
          [class.table-row_collapsed]="!row.isExpanded"
          [ngClass]="row.rowClasses"
          (cdkDragDropped)="row.rowDragDropped.emit($event)"
        >
          <td
            *ngIf="enableDraggableRows"
            dartsalescEditableTableStickyColumnCell="left"
            class="table-cell drag-cell"
          >
            <div
              *ngIf="row.isDraggable"
              cdkDragHandle
              class="drag-handle"
            >
              <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
            </div>
          </td>

          <ng-container
            [ngTemplateOutlet]="rowCellsTemplate"
            [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
          />

          <div *cdkDragPreview>
            <ng-container
              *ngIf="row.rowDragPreview; else defaultPreview"
              [ngTemplateOutlet]="row.rowDragPreview.template"
            />
            <ng-template #defaultPreview>
              <ng-container
                *ngIf="
                  row.tableColumns?.get(0)?.tableCell?.template as template
                "
                [ngTemplateOutlet]="template"
                [ngTemplateOutletContext]="{
                  $implicit: row.tableColumns?.get(0)
                }"
              />
            </ng-template>
          </div>

          <div *cdkDragPlaceholder>
            <td [attr.colspan]="_columns.length">
              <!-- Empty. -->
            </td>
          </div>
        </tr>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-template #withoutVirtualScroll>
    <tbody
      *ngrxLet="bodyRows?.changes | async"
      cdkDropList
      class="editable-table__body"
      [cdkDropListData]="bodyRows?.toArray()"
      [cdkDropListSortPredicate]="dragSortPredicate"
      [cdkDropListDisabled]="!enableDraggableRows"
    >
      <tr
        *ngFor="let row of bodyRows; trackBy: trackByIndex; let index = index"
        class="table-row"
        cdkDrag
        cdkDragPreviewContainer="parent"
        [cdkDragDisabled]="!row.isDraggable"
        [cdkDragData]="{ row, index }"
        [dartsalescEditableTableStickyRow]="row.stickySide"
        [class.table-row_highlighted]="row.isHighlighted"
        [class.table-row_collapsed]="!row.isExpanded"
        [ngClass]="row.rowClasses"
        (cdkDragDropped)="row.rowDragDropped.emit($event)"
      >
        <td
          *ngIf="enableDraggableRows"
          dartsalescEditableTableStickyColumnCell="left"
          class="table-cell drag-cell"
        >
          <div
            *ngIf="row.isDraggable"
            cdkDragHandle
            class="drag-handle"
          >
            <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
          </div>
        </td>
        <ng-container
          [ngTemplateOutlet]="rowCellsTemplate"
          [ngTemplateOutletContext]="{ $implicit: row.tableColumns }"
        />

        <div *cdkDragPreview>
          <ng-container
            *ngIf="row.rowDragPreview; else defaultPreview"
            [ngTemplateOutlet]="row.rowDragPreview.template"
          />
          <ng-template #defaultPreview>
            <div class="default-drag-preview">
              <div class="drag-handle">
                <mat-icon class="drag-handle__icon">drag_indicator</mat-icon>
              </div>
              <ng-container
                *ngIf="
                  row.tableColumns?.get(0)?.tableCell?.template as template
                "
                [ngTemplateOutlet]="template"
                [ngTemplateOutletContext]="{
                  $implicit: row.tableColumns?.get(0)
                }"
              />
            </div>
          </ng-template>
        </div>

        <div *cdkDragPlaceholder>
          <td [attr.colspan]="_columns.length">
            <!-- Empty. -->
          </td>
        </div>
      </tr>
    </tbody>
  </ng-template>
  <tfoot class="editable-table__footer">
    <tr
      *ngFor="let footerRow of footerRows; trackBy: trackByIndex"
      class="table-row"
      dartsalescEditableTableStickyRow="bottom"
      [class.total-footer-row]="footerRow.isTotalRow"
    >
      <td
        *ngIf="enableDraggableRows"
        dartsalescEditableTableStickyColumnCell="left"
        class="table-cell"
      >
        <!-- Empty. -->
      </td>
      <ng-container
        [ngTemplateOutlet]="rowCellsTemplate"
        [ngTemplateOutletContext]="{ $implicit: footerRow.tableColumns }"
      />
    </tr>
  </tfoot>
</table>

<ng-template
  #rowCellsTemplate
  let-tableColumns
  [dartsalescTemplateContext]="rowCellsTemplateContext"
>
  <ng-container *ngFor="let column of _columns; trackBy: trackByName">
    <ng-container
      *ngrxLet="getColumnByName(column.name, tableColumns) as columnTemplate"
    >
      <!-- The template for the sticky column and for the regular column must be identical except for the `dartsalescEditableTableStickyColumnCell` directive -->
      <td
        *ngIf="column.stickySide === undefined; else stickyCellTemplate"
        class="table-cell"
        [attr.colspan]="
          columnTemplate?.tableCell?.dartsalescEditableTableCellColSpan ?? 1
        "
        [class.table-cell_expanded]="column.isExpandable"
        [ngClass]="getColumnCssClasses(column)"
        [ngStyle]="getColumnSizeStyles(column)"
      >
        <ng-container
          *ngIf="
            columnTemplate?.tableCell?.template as template;
            else defaultCellTemplate
          "
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{
            $implicit: column
          }"
        />
      </td>
      <ng-template #stickyCellTemplate>
        <!-- The template for the sticky column and for the regular column must be identical except for the `dartsalescEditableTableStickyColumnCell` directive -->
        <td
          *ngIf="column.stickySide !== undefined"
          class="table-cell"
          [dartsalescEditableTableStickyColumnCell]="column.stickySide"
          [attr.colspan]="
            columnTemplate?.tableCell?.dartsalescEditableTableCellColSpan ?? 1
          "
          [class.table-cell_expanded]="column.isExpandable"
          [ngClass]="getColumnCssClasses(column)"
          [ngStyle]="getColumnSizeStyles(column)"
        >
          <ng-container
            *ngIf="
              columnTemplate?.tableCell?.template as template;
              else defaultCellTemplate
            "
            [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{
              $implicit: column
            }"
          />
        </td>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #defaultCellTemplate />
</ng-template>
