import { TermSubcategory } from './term-subcategory';

/** Term totals data. */
export class TermData {

  /** Subcategories. */
  public readonly subCategories: readonly TermSubcategory[];

  public constructor(data: TermDataInitArgs) {
    this.subCategories = data.subCategories;
  }
}

type TermDataInitArgs = TermData;
