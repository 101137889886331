import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Loading content. */
@Component({
  selector: 'dartsalesw-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {

  /** Set loading. */
  @Input()
  public isLoading: boolean | null = false;

  /** Progress spinner diameter. */
  @Input()
  public diameter = 60;

}
