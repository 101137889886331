import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, inject, Input, Output, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';
import { ConfirmedOverridable } from '@dartsales/common/core/models/confirmed-overridable';

import { TableCellFormControl } from '../table-cell-form-control.component';
import { TableRowEventsService } from '../../../editable-table/services/table-row-events.service';
import { TableCellInputDirective } from '../../directives/table-cell-input.directive';

/** Override single select table cell component. */
@Component({
  selector: 'dartsalesc-override-single-select-table-cell',
  templateUrl: './override-single-select-table-cell.component.html',
  styleUrls: ['./override-single-select-table-cell.component.css'],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverrideSingleSelectTableCellComponent<T> extends TableCellFormControl<ConfirmedOverridable<T> | null> {
  /** Select options. */
  @Input()
  public options: readonly OptionSelect<T>[] = [];

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  @Input()
  public compareWith = (first?: T, second?: T): boolean => first === second;

  /**
   * Get tooltip message.
   * @param _ Initial value.
   */
  @Input()
  public getTooltipMessage = (_: T): string => '';

  /** Whether cell is read-only. */
  @Input()
  public isReadonly = false;

  /** Placeholder. */
  @Input()
  public placeholder = 'Select value';

  /** Whether cell has unconfirmed override. Override is considered 'unconfirmed' when it was set automatically due to bulk update.  */
  @Input()
  public hasUnconfirmedOverride = false;

  /** Select element. */
  @ViewChild(MatSelect)
  protected readonly selectElement?: MatSelect;

  /** Renderer element. */
  @ViewChild(TableCellInputDirective)
  private readonly rendererElement?: TableCellInputDirective;

  /** Selection change. */
  @Output()
  public readonly selectionChange = new EventEmitter<ConfirmedOverridable<T> | null>();

  /** Is highlighted. */
  @HostBinding('class.highlighted')
  protected get isHighlighted(): boolean {
    return this.controlValue?.override != null && this.hasUnconfirmedOverride;
  }

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly rowEventsService = inject(TableRowEventsService, { optional: true });

  /** Select form control. */
  protected readonly selectControl = this.fb.control<T | null>(null);

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** Whether input has reset button or not. */
  protected get isResetAvailable(): boolean {
    return (
      this.hasUnconfirmedOverride &&
      !this.disabled &&
      this.controlValue?.override !== null
    );
  }

  /** Tooltip message. */
  protected get tooltipMessage(): string {
    if (this.controlValue?.initial !== null && this.controlValue?.initial !== undefined) {
      return `Reset to value: ${this.getTooltipMessage(this.controlValue.initial)}`;
    }
    return `Reset value`;
  }

  /** Currently selected option. */
  protected get currentOption(): OptionSelect<T> | null {
    return this.options.find(option => option.value === this.controlValue) ?? null;
  }

  /** @inheritdoc */
  public override writeValue(value: ConfirmedOverridable<T> | null): void {
    this.selectControl.setValue(value?.combinedValue ?? null);
    super.writeValue(value);
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** Handle selection change. */
  protected onSelectionChange(): void {
    if (!this.selectControl.enabled) {
      return;
    }

    if (this.controlValue?.initial) {
      this.controlValue = new ConfirmedOverridable({
        isConfirmed: true,
        initial: this.controlValue.initial,
        override: this.selectControl.value,
      });
    } else if (this.selectControl.value) {
      this.controlValue = new ConfirmedOverridable({
        isConfirmed: true,
        initial: this.selectControl.value,
        override: null,
      });
    }

    this.selectionChange.emit(this.controlValue);
  }

  /** Handle reset button click. */
  protected onResetButtonClick(): void {
    if (this.controlValue !== null) {
      const clearedOverride = new ConfirmedOverridable({
        isConfirmed: true,
        initial: this.controlValue.initial,
        override: null,
      });
      this.writeValue(clearedOverride);
      this.controlValue = clearedOverride;
      this.rowEventsService?.triggerCellBlur(this.rendererElement?.tableRowElement ?? null);
    }
  }
}
