/** View type. */
export enum ViewType {
  Internal = 'Internal',
  Customer = 'Customer',
}

export namespace ViewType {
  const TO_READABLE_MAP: Record<ViewType, string> = {
    [ViewType.Customer]: 'Customer View',
    [ViewType.Internal]: 'Internal View',
  };

  /**
   * Gets view type human-readable representation.
   * @param viewType View type.
   */
  export function toReadable(viewType: ViewType): string {
    return TO_READABLE_MAP[viewType];
  }
}
