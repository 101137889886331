import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { NonNullableFormBuilder, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { skip } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ValuesRange } from '@dartsales/common/core/models/values-range';
import { listenFormRawValueChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { ControlsOf } from '@dartsales/common/core/utils/types/form';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';

/** Number range input component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-number-range-form-control',
  templateUrl: './number-range-form-control.component.html',
  styleUrls: ['./number-range-form-control.component.css'],
  providers: [controlProviderFor(() => NumberRangeFormControlComponent)],

  // We have ErrorStateMatcher so we need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NumberRangeFormControlComponent extends SimpleValueAccessor<ValuesRange<number | null>> implements OnInit {

  /** Error state matcher. */
  @Input()
  public errorStateMatcher = new ErrorStateMatcher();

  private readonly fb = inject(NonNullableFormBuilder);

  /** Range form. */
  protected readonly rangeForm = this.createRangeFormGroup();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: ValuesRange<number | null> | null): void {
    const defaultValue: ValuesRange<number | null> = {
      start: null,
      end: null,
    };
    this.rangeForm.setValue(value ?? defaultValue, { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    listenFormRawValueChanges(this.rangeForm).pipe(
      skip(1),
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.controlValue = value;
      });
  }

  private createRangeFormGroup(): FormGroup<ControlsOf<ValuesRange<number | null>>> {
    return this.fb.group<ControlsOf<ValuesRange<number | null>>>({
      start: this.fb.control(null),
      end: this.fb.control(null),
    });
  }
}
