import { PointsListSystem } from '../system/points-list-system';
import { PointsListPart } from '../part/points-list-part';

import { PointsListPartGroupProperties } from './points-list-part-group-properties';

/** Points list part group. */
export class PointsListPartGroup {
  /** Group UUID. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Is default group. */
  public readonly isDefault: boolean;

  /** Order. */
  public readonly order: number;

  /** System ID. */
  public readonly systemId: PointsListSystem['id'];

  /** Parts. */
  public readonly parts: readonly PointsListPart[];

  /** Group properties. */
  public readonly properties: PointsListPartGroupProperties;

  public constructor(data: PointsListPartGroupInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.isDefault = data.isDefault;
    this.order = data.order;
    this.systemId = data.systemId;
    this.parts = data.parts;
    this.properties = data.properties;
  }
}

type PointsListPartGroupInitArgs = PointsListPartGroup;
