import { enumToArray } from '../utils/enum-to-array';

/** Export format. */
export enum ExportFormat {
  Csv = 0,
  Pdf = 1,
  Xlsx = 2,
}

export namespace ExportFormat {

  const TO_READABLE_MODULE_MAP: Readonly<Record<ExportFormat, string>> = {
    [ExportFormat.Csv]: 'csv',
    [ExportFormat.Pdf]: 'pdf',
    [ExportFormat.Xlsx]: 'xlsx',
  };

  /**
   * Gets export format human-readable representation.
   * @param format Export format.
   */
  export function toReadable(format: ExportFormat): string {
    return TO_READABLE_MODULE_MAP[format];
  }

  /** Returns all variants of export format. */
  export function toArray(): ExportFormat[] {
    return enumToArray(ExportFormat);
  }

  /**
   * Track by function.
   * @param _index Index of element.
   * @param item Item.
   */
  export function trackBy(_index: number, item: ExportFormat): ExportFormat {
    return item;
  }
}
