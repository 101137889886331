import { ChangeDetectionStrategy, Component } from '@angular/core';

import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';

import { AbstractAmountPercentTableHeaderCellComponent } from './abstract-amount-percent-table-header';

/** Escalation table header cell component. */
@Component({
  selector: 'dartsalesw-escalation-table-header-cell',
  templateUrl: '../amount-percent-table-header-cell/amount-percent-table-header-cell.component.html',
  styleUrls: [
    '../table-header-select-shared.css',
    '../amount-percent-table-header-cell/amount-percent-table-header-cell.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => EscalationTableHeaderCellComponent)],
})
export class EscalationTableHeaderCellComponent extends AbstractAmountPercentTableHeaderCellComponent {

  /** Header label. */
  protected readonly headerLabel = 'Escal';

  /** @inheritdoc */
  protected readonly typeOptions$ = this.createOptionsStream(
    'Escalation $',
    'Escalation %',
  );
}
