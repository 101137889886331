<mat-toolbar class="toolbar">
  <div class="toolbar__left-section">
    <button
      mat-icon-button
      type="button"
      class="option-button"
      (click)="toolbarButtonClick.emit()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <a
      class="app-logo"
      [routerLink]="routePaths.welcome.url"
    >
      <img
        class="app-logo__image"
        src="/assets/images/division25-logo.svg"
        alt="Logo"
      />
    </a>
  </div>
  <div class="toolbar__center-section">
    <ng-template
      *ngIf="
        customProjectPageSearchService.customPageSearchTemplate$
          | async as customSearch;
        else defaultSearch
      "
      [cdkPortalOutlet]="customSearch"
    />
    <ng-template #defaultSearch>
      <dartsalesw-project-page-search class="search-field" />
    </ng-template>
  </div>
  <div class="toolbar__right-section">
    <dartsalesw-notifications-button class="toolbar__notifications-button" />
    <dartsalesw-profile-button />
  </div>
</mat-toolbar>
