<div
  class="draggable-row"
  [dndType]="dragPayloadType"
  [dndDraggable]="dragPayload"
  [dndDisableDragIf]="isDisabled"
  (dndStart)="onDragStart($event)"
  (dndEnd)="onDragEnd($event)"
>
  <ng-content />

  <ng-template #dragPreview>
    <div class="drag-preview">
      <dartsalesw-draggable-item-preview
        [selectedItemsCount]="selectedItemsCount"
      >
        <ng-template
          *ngIf="singleItemDragTemplate as template"
          #singleItemDragTemplate
        >
          <ng-container [ngTemplateOutlet]="template" />
        </ng-template>
        <ng-template
          *ngIf="multipleItemsDragTemplate as template"
          #multipleItemsDragTemplate
        >
          <ng-container [ngTemplateOutlet]="template" />
        </ng-template>
      </dartsalesw-draggable-item-preview>
    </div>
  </ng-template>
</div>
