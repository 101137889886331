import { ModuleLockablePercentsStatus } from '../../../module-lockable-percents';

/** Material module lockable percents status. */
export class MaterialModuleLockablePercentsStatus extends ModuleLockablePercentsStatus {

  /** Whether warranty is locked. */
  public readonly isWarrantyLocked: boolean;

  /** Whether freight is locked. */
  public readonly isFreightLocked: boolean;

  /** Whether sales tax is locked. */
  public readonly isSalesTaxLocked: boolean;

  public constructor(data: MaterialModuleLockablePercentsStatusInitArgs) {
    super(data);
    this.isWarrantyLocked = data.isWarrantyLocked;
    this.isFreightLocked = data.isFreightLocked;
    this.isSalesTaxLocked = data.isSalesTaxLocked;
  }

  /** Get default module lockable percents status. */
  public static override getDefaultStatus(): MaterialModuleLockablePercentsStatus {
    return new MaterialModuleLockablePercentsStatus({
      ...super.getDefaultStatus(),
      isWarrantyLocked: false,
      isFreightLocked: false,
      isSalesTaxLocked: false,
    });
  }
}

type MaterialModuleLockablePercentsStatusInitArgs = MaterialModuleLockablePercentsStatus & ModuleLockablePercentsStatus;
