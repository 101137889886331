import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonSharedModule } from '../../common-shared.module';

import { TextCellRendererComponent } from './components/shared/text-cell-renderer/text-cell-renderer.component';
import { TextCellEditorComponent } from './components/shared/text-cell-editor/text-cell-editor.component';
import { TextCellComponent } from './components/text-cell/text-cell.component';
import { MaskedNumberCellComponent } from './components/masked-number-cell/masked-number-cell.component';
import { MaskedNumberCellEditorComponent } from './components/shared/masked-number-cell-editor/masked-number-cell-editor.component';
import { MaskedNumberCellRendererComponent } from './components/shared/masked-number-cell-renderer/masked-number-cell-renderer.component';
import { SingleSelectCellComponent } from './components/single-select-cell/single-select-cell.component';
import { SingleSelectCellRendererComponent } from './components/shared/single-select-cell-renderer/single-select-cell-renderer.component';
import { SingleSelectCellEditorComponent } from './components/shared/single-select-cell-editor/single-select-cell-editor.component';
import { OverridableNumberCellComponent } from './components/overridable-number-cell/overridable-number-cell.component';
import { MultiSelectCellComponent } from './components/multi-select-cell/multi-select-cell.component';
import { MultiSelectCellEditorComponent } from './components/shared/multi-select-cell-editor/multi-select-cell-editor.component';
import { MultiSelectCellRendererComponent } from './components/shared/multi-select-cell-renderer/multi-select-cell-renderer.component';
import { PercentNumberCellComponent } from './components/percent-number-cell/percent-number-cell.component';
import { GrossMarginPercentNumberCellComponent } from './components/percent-number-cell/gross-margin-percent-cell.component';
import { CellEditorInputDirective } from './directives/cell-editor-input.directive';
import { CellRendererContentDirective } from './directives/cell-renderer-content.directive';

const PUBLIC_DECLARATIONS: Type<unknown>[] = [
  TextCellRendererComponent,
  TextCellEditorComponent,
  TextCellComponent,
  MaskedNumberCellComponent,
  MaskedNumberCellEditorComponent,
  MaskedNumberCellRendererComponent,
  SingleSelectCellComponent,
  SingleSelectCellRendererComponent,
  SingleSelectCellEditorComponent,
  OverridableNumberCellComponent,
  MultiSelectCellComponent,
  MultiSelectCellEditorComponent,
  MultiSelectCellRendererComponent,
  PercentNumberCellComponent,
  GrossMarginPercentNumberCellComponent,
];

const PUBLIC_IMPORTS: Type<unknown>[] = [
  CellEditorInputDirective,
  CellRendererContentDirective,
];

/** Cell module. */
@NgModule({
  declarations: [...PUBLIC_DECLARATIONS],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CommonSharedModule,
    ...PUBLIC_IMPORTS,
  ],
  exports: [
    ...PUBLIC_IMPORTS,
    ...PUBLIC_DECLARATIONS,
  ],
})
export class CellModule { }
