import { BehaviorSubject, Observable, debounceTime, map, shareReplay, switchMap, tap } from 'rxjs';

import { CollapseRowsStrategy, CollapsibleRowData } from '../utils/collapse-rows-strategy';

/** Collapse data. */
export type CollapseData<TRowType extends string> = Readonly<{

  /** Inverted rows. */
  invertedRows: readonly CollapsibleRowData<TRowType>[];

  /** Collapse row types. */
  collapsedRowTypes: readonly TRowType[];
}>;

/** Abstract collapse rows service. */
export abstract class AbstractCollapseRowsService<TRowType extends string> {

  /** Collapsible row types. */
  public readonly abstract collapsibleRowTypes: readonly TRowType[];

  /** Empty collapse data. */
  protected readonly emptyCollapseData: CollapseData<TRowType> = {
    collapsedRowTypes: [],
    invertedRows: [],
  };

  private readonly collapseData$ = new BehaviorSubject<CollapseData<TRowType>>(this.emptyCollapseData);

  private readonly strategy = new CollapseRowsStrategy<TRowType>();

  private get invertedRows(): CollapseData<TRowType>['invertedRows'] {
    return this.collapseData$.value.invertedRows;
  }

  private get collapsedRowTypes(): CollapseData<TRowType>['collapsedRowTypes'] {
    return this.collapseData$.value.collapsedRowTypes;
  }

  /** Collapse changed. */
  public readonly collapseChanged$ = this.createCollapseChangedStream();

  /**
   * Set expand rows with type.
   * @param rowTypes Expand row types.
   */
  public setExpandRowsWithType(rowTypes: readonly TRowType[]): void {
    const currentCollapsedRowTypes = this.collapsedRowTypes;
    const newCollapsedRowTypes = currentCollapsedRowTypes.filter(rowType => !rowTypes.includes(rowType));

    if (this.checkIsAllCollapsedRowTypes(rowTypes)) {
      this.collapseData$.next(this.emptyCollapseData);
    } else {
      this.patchCollapsedData({ collapsedRowTypes: newCollapsedRowTypes });
      this.expandRowsByTypes(rowTypes);
    }
  }

  /**
   * Set collapse rows with type.
   * @param rowTypes Expand row types.
   */
  public setCollapseRowsWithType(rowTypes: readonly TRowType[]): void {
    const currentCollapsedRowTypes = this.collapsedRowTypes;
    const newCollapsedRowTypes = [...new Set(currentCollapsedRowTypes.concat(rowTypes))];

    this.patchCollapsedData({ collapsedRowTypes: newCollapsedRowTypes });
    this.collapseRowsByTypes(rowTypes);
  }

  /** @inheritdoc */
  public checkIsRowCollapsed(row: CollapsibleRowData<TRowType>): boolean {
    return this.strategy.checkIsRowCollapsed(
      row,
      this.invertedRows,
      this.collapsedRowTypes,
    );
  }

  /** @inheritdoc */
  public toggleRowCollapse(row: CollapsibleRowData<TRowType>): void {
    const invertedRows = this.strategy.getInvertedRowsWithToggledRowCollapse(
      row,
      this.invertedRows,
      this.collapsedRowTypes,
    );
    this.patchCollapsedData({ invertedRows });
  }

  /** @inheritdoc */
  public collapseRows(rows: readonly CollapsibleRowData<TRowType>[]): void {
    const invertedRows = this.strategy.getInvertedRowsWithCollapse(
      rows,
      this.invertedRows,
      this.collapsedRowTypes,
    );
    this.patchCollapsedData({ invertedRows });
  }

  /** @inheritdoc */
  public expandRows(rows: readonly CollapsibleRowData<TRowType>[]): void {
    const invertedRows = this.strategy.getInvertedRowsWithExpand(
      rows,
      this.invertedRows,
      this.collapsedRowTypes,
    );
    this.patchCollapsedData({ invertedRows });
  }

  private expandRowsByTypes(
    rowTypes: readonly TRowType[],
  ): void {
    const rows = this.invertedRows.filter(row => rowTypes.includes(row.type));
    this.expandRows(rows);
  }

  private collapseRowsByTypes(
    rowTypes: readonly TRowType[],
  ): void {
    const rows = this.invertedRows.filter(row => rowTypes.includes(row.type));
    this.collapseRows(rows);
  }

  private checkIsAllCollapsedRowTypes(
    rowTypes: readonly TRowType[],
  ): boolean {
    return this.collapsibleRowTypes.every(rowType => rowTypes.includes(rowType));
  }

  private patchCollapsedData(data: Partial<CollapseData<TRowType>>): void {
    this.collapseData$.next({
      ...this.collapseData$.value,
      ...data,
    });
  }

  /**
   * Create collapse changed stream.
   * @param startCollapseData$ Stream with the start collapse data.
   */
  protected createCollapseChangedStream(
    startCollapseData$?: Observable<CollapseData<TRowType>>,
  ): Observable<CollapseData<TRowType>> {
    const startData$ = startCollapseData$ ?? this.collapseData$;
    return startData$.pipe(
      tap(collapseData => {
        if (startData$ !== this.collapseData$) {
          this.collapseData$.next(collapseData);
        }
      }),
      switchMap(() => this.collapseData$),
      debounceTime(0),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Create stream that shows whether row is collapsed.
   * @param rowData Row data.
   */
  public createIsRowCollapsedStream(rowData: CollapsibleRowData<TRowType>): Observable<boolean> {
    return this.collapseChanged$.pipe(
      map(() => this.checkIsRowCollapsed(rowData)),
    );
  }

  /**
   * Create stream that shows whether row is expanded.
   * @param rowData Row data.
   */
  public createIsRowExpandedStream(rowData: CollapsibleRowData<TRowType>): Observable<boolean> {
    return this.collapseChanged$.pipe(
      map(() => !this.checkIsRowCollapsed(rowData)),
    );
  }
}
