import { inject, Injectable } from '@angular/core';

import { EstimateServiceModules } from '@dartsales/common/core/models/estimate-services/estimate-service-modules';
import { ModulePropertiesMapper } from '@dartsales/common/core/services/mappers/estimate/modules/module-properties.mapper';
import { EstimateServiceModulesDto } from '@dartsales/common/core/services/mappers/dto/estimate-services/estimate-service-modules.dto';
import { IMapperFromDto } from '@dartsales/common/core/services/mappers/mappers';

import { ModulesPropertiesListMapper } from '../estimate/modules-propterties-list.mapper';

/** Estimate service modules mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimateServiceModulesMapper implements
  IMapperFromDto<EstimateServiceModulesDto, EstimateServiceModules> {

  private readonly modulePropertiesMapper = inject(ModulePropertiesMapper);

  private readonly modulesPropertiesListMapper = inject(ModulesPropertiesListMapper);

  /** @inheritdoc */
  public fromDto(dto: EstimateServiceModulesDto): EstimateServiceModules {
    return new EstimateServiceModules({
      ...this.modulesPropertiesListMapper.fromBaseDto(dto),
      totals: {
        properties: this.modulePropertiesMapper.fromDto(dto.totalsModule.properties),
      },
    });
  }
}
