import { Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';

import { StickyColumnSide } from '@dartsales/common/core/models/list-utilities/editable-table-column-config';
import { getAllNextSiblings, getAllPreviousSiblings } from '@dartsales/common/core/utils/select-sibling-element';

/** Sticky column cell in editable table. */
@Directive({
  selector: '[dartsalescEditableTableStickyColumnCell]',
})
export class EditableTableStickyColumnCellDirective implements OnInit, OnDestroy {
  /** Sticky direction. */
  @Input('dartsalescEditableTableStickyColumnCell')
  public stickyColumnSide?: StickyColumnSide;

  /** Sticky cell CSS class. */
  @HostBinding('class')
  protected get stickyCellClass(): Record<string, boolean> {
    return {
      [this.stickyLeftSideClass]: this.stickyColumnSide === 'left',
      [this.stickyRightSideClass]: this.stickyColumnSide === 'right',
    };
  }

  private readonly stickyLeftSideClass = 'editable-table-sticky-colum-cell_left';

  private readonly stickyRightSideClass = 'editable-table-sticky-colum-cell_right';

  private readonly resizeObserver = this.createResizeObserver();

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  /** Trigger resize. */
  public triggerResize(): void {
    // A timeout is necessary so that all neighboring cells have time to appear.
    setTimeout(() => {
      this.setSticky();
    }, 0);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  private createResizeObserver(): ResizeObserver {
    // This solution might have some performance issues in large tables.
    // We don't use "debounce" because it worsens the UI.
    return new ResizeObserver(() => {
      this.setSticky();
    });
  }

  private setSticky(): void {
    if (this.stickyColumnSide === 'left') {
      this.setLeftOffset();
    } else if (this.stickyColumnSide === 'right') {
      this.setRightOffset();
    }
  }

  private setLeftOffset(): void {
    const element = this.elementRef.nativeElement;
    const leftOffset = this.calculateLeftOffset();

    element.style.position = 'sticky';
    element.style.left = `${leftOffset}px`;
    element.style.zIndex = '4';
  }

  private setRightOffset(): void {
    const element = this.elementRef.nativeElement;
    const rightOffset = this.calculateRightOffset();

    element.style.position = 'sticky';
    element.style.right = `${rightOffset}px`;
    element.style.zIndex = '3';
  }

  private calculateRightOffset(): number {
    // This solution might have some performance issues in large tables.
    const element = this.elementRef.nativeElement;
    const siblings = getAllNextSiblings(element, `.${this.stickyRightSideClass}`);
    return siblings.reduce((offset, sibling) => offset + (sibling.getBoundingClientRect().width ?? 0), 0);
  }

  private calculateLeftOffset(): number {
    // This solution might have some performance issues in large tables.
    const element = this.elementRef.nativeElement;
    const siblings = getAllPreviousSiblings(element, `.${this.stickyLeftSideClass}`);
    return siblings.reduce((offset, sibling) => offset + (sibling.getBoundingClientRect().width ?? 0), 0);
  }
}
