import { OptionSelect } from '../models/option-select';
import { enumToArray } from '../utils/enum-to-array';

/** Part performer type. */
export enum PartPerformerType {
  Self = 'Self',
  Subcontractor = 'Subcontractor',
  Other = 'Other',
  Existing = 'Existing',
}

/** Namespace related to PartPerformerType. */
export namespace PartPerformerType {
  const TO_TITLE_MAP: Readonly<Record<PartPerformerType, string>> = {
    [PartPerformerType.Self]: 'Self',
    [PartPerformerType.Subcontractor]: 'Subcontractor',
    [PartPerformerType.Other]: 'Other',
    [PartPerformerType.Existing]: 'Existing',
  };

  /**
   * Converts a certain part performer type to readable title.
   * @param value Part performer type.
   */
  export function toReadable(value: PartPerformerType): string {
    return TO_TITLE_MAP[value];
  }

  /** Gets list of all part performer types. */
  export function toArray(): PartPerformerType[] {
    return enumToArray(PartPerformerType);
  }

  /**
   * Track by function.
   * @param _index Index.
   * @param item Item.
   */
  export function trackBy(_index: number, item: PartPerformerType): PartPerformerType {
    return item;
  }

  /** Get select options list. */
  export function toSelectOptions(): OptionSelect<PartPerformerType>[] {
    return toArray()
      .map(status => ({
        label: PartPerformerType.toReadable(status),
        value: status,
      }));
  }
}
