import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NonNullableFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable, combineLatest, map, shareReplay, startWith, switchMap } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { createTrackByFunction, trackByIndex } from '@dartsales/common/core/utils/trackby';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';
import { listenControlChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { BaseEstimateApiService } from '@dartsales/common/core/services/api/base-estimate-api.service';
import { assertNonNull } from '@dartsales/common/core/utils/assert-non-null';
import { SuggestedMergeEstimate } from '@dartsales/common/core/models/estimate/suggested-merge-estimate';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';

/**
 * Estimate option.
 * 'null' in properties is possible if option is empty.
 */
type EstimateOption = Readonly<{

  /** Estimate ID ('null' if empty estimate is used). */
  id: EstimateId | null;

  /** Estimate description. */
  description: string;

  /** Estimate name. */
  name: string;
}>;

type TemplateEstimateOption = Readonly<{

  /** Template ID. */
  templateId: number;

  /** Name. */
  templateName: string;

  /** Organization name. */
  organizationName: string;

  /** Options. */
  options: readonly EstimateOption[];
}> & {

  /** Are child elements expanded. */
  areChildrenExpanded: boolean;
};

type EstimateOptions = Readonly<{

  /** Non template options. */
  nonTemplateOptions: readonly EstimateOption[];

  /** Template options. */
  templateOptions: readonly TemplateEstimateOption[];
}>;

/** Estimate select component. */
@Component({
  selector: 'dartsalesw-estimate-select',
  templateUrl: './estimate-select.component.html',
  styleUrls: [
    '../search-input/search-input.component.css',
    './estimate-select.component.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => EstimateSelectComponent)],
})
export class EstimateSelectComponent extends SimpleValueAccessor<EstimateId | null> implements OnInit {

  /** Empty option name. */
  @Input()
  public emptyOptionName = '';

  /** Whether to display change orders. */
  @Input()
  public hideChangeOrders = false;

  /** Estimates. */
  @Input({ required: true })
  public estimateId: EstimateId | null = null;

  @ViewChild(MatMenuTrigger)
  private readonly menuTriggerButton?: MatMenuTrigger;

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  private readonly baseEstimateApiService = inject(BaseEstimateApiService);

  /** Selected option ID. */
  protected readonly selectedEstimateId$ = new BehaviorSubject<EstimateId | null>(null);

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

  /** Search control. */
  protected readonly searchControl = this.fb.control('');

  /** Options. */
  protected readonly options$ = this.createOptions();

  /** Selected estimate option. */
  protected readonly selectedEstimateOption$ = this.createSelectedEstimateOptionStream();

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** Track by ID. */
  protected readonly trackById = createTrackByFunction<TemplateEstimateOption>('templateId');

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSelectedIdChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    this.selectedEstimateId$.next(value);
    super.writeValue(value);
  }

  /**
   * Select estimate option.
   * @param value Estimate option.
   */
  protected selectEstimateOption(value: EstimateOption): void {
    this.selectedEstimateId$.next(value.id);
    this.menuTriggerButton?.closeMenu();
  }

  /** Reset search control. */
  protected resetSearchControl(): void {
    this.searchControl.reset();
  }

  /**
   * Clear input.
   * @param event Event.
   */
  protected onClear(event: Event): void {
    event.stopPropagation();
    this.searchControl.reset();
  }

  private createSelectedEstimateOptionStream(): Observable<EstimateOption> {
    return combineLatest([
      this.options$,
      this.selectedEstimateId$,
    ]).pipe(
      map(([estimateOptions, selectedEstimateId]) => {
        const templateOptions = estimateOptions.templateOptions.flatMap(template => template.options);
        const allOptions = estimateOptions.nonTemplateOptions.concat(templateOptions);
        return allOptions.find(estimate => estimate.id === selectedEstimateId) ?? this.getEmptyEstimateOption();
      }),
    );
  }

  private createOptions(): Observable<EstimateOptions> {
    return listenControlChanges(this.searchControl).pipe(
      switchMap(searchValue => {
        assertNonNull(this.estimateId);
        return this.baseEstimateApiService.getEstimatesToMerge(this.estimateId, searchValue).pipe(
          toggleExecutionState(this.isLoading$),
          startWith([]),
          map(estimates => ({
            nonTemplateOptions: this.getNonTemplateEstimateOptions(estimates),
            templateOptions: this.getTemplateEstimateOptions(estimates),
          })),
        );
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getNonTemplateEstimateOptions(estimates: readonly SuggestedMergeEstimate[]): EstimateOptions['nonTemplateOptions'] {
    const emptyOption = this.getEmptyEstimateOption();
    const nonTemplateOptions = estimates
      .filter(estimate => !estimate.isTemplate)
      .flatMap(estimate => this.getEstimateOptions(estimate));
    return [emptyOption, ...nonTemplateOptions];
  }

  private getTemplateEstimateOptions(estimates: readonly SuggestedMergeEstimate[]): EstimateOptions['templateOptions'] {
    const suggestedMergeTemplates = estimates.filter(estimate => estimate.isTemplate);

    return suggestedMergeTemplates.map(template => ({
      templateId: template.id,
      templateName: template.name,
      organizationName: template.organizationName,
      areChildrenExpanded: false,
      options: this.getEstimateOptions(template),
    }));
  }

  private getEstimateOptions(estimate: SuggestedMergeEstimate): EstimateOption[] {
    const baseEstimateOption: EstimateOption = {
      id: estimate.id,
      name: estimate.name,
      description: 'Base Estimate',
    };
    const changeOrdersOptions = this.hideChangeOrders ? [] : estimate.changeOrders;
    const alternatesOptions = estimate.alternates;
    return [baseEstimateOption].concat(alternatesOptions, changeOrdersOptions);
  }

  private getEmptyEstimateOption(): EstimateOption {
    return {
      id: null,
      name: this.emptyOptionName,
      description: 'Without initial values.',
    };
  }

  private subscribeToSelectedIdChanges(): void {
    this.selectedEstimateId$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(selectedId => {
      this.controlValue = selectedId;
    });
  }
}
