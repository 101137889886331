<dartsalesc-editable-table-cell
  [editModeOnly]="true"
  [isReadonly]="isReadonly"
  [matTooltip]="errorMessage ?? ''"
  (cellFocus)="select.open()"
>
  <ng-container dartsalescTableCellEditMode>
    <mat-select
      #select
      dartsalescTableCellInput
      [multiple]="true"
      [placeholder]="placeholder"
      [formControl]="selectControl"
      [compareWith]="compareWith"
      [errorStateMatcher]="errorStateMatcher"
      (selectionChange)="onSelectionChange()"
    >
      <mat-select-trigger>
        <span [title]="select.triggerValue">
          {{ select.triggerValue }}
        </span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of options; trackBy: trackByIndex"
        tabindex="0"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
  </ng-container>
</dartsalesc-editable-table-cell>
