import { Overridable } from '@dartsales/common/core/utils/types/overridable';

import { PointsListMaterialLaborItemTotals, PointsListMaterialLaborTotals } from './points-list-material-labor-item-totals';

/** Points list material totals. */
export class PointsListMaterialTotals extends PointsListMaterialLaborTotals<PointsListMaterialGroupTotals> {

  /** Quantity. */
  public readonly quantity: Overridable<number>;

  public constructor(data: PointsListMaterialTotalsInitArgs) {
    super(data);
    this.quantity = data.quantity;
  }
}

type PointsListMaterialTotalsInitArgs = PointsListMaterialTotals;

/** Points list material group totals. */
export class PointsListMaterialGroupTotals extends PointsListMaterialLaborItemTotals {

  /** Quantity. */
  public readonly quantity: Overridable<number>;

  public constructor(data: PointsListMaterialGroupTotalsInitArgs) {
    super(data);
    this.quantity = data.quantity;
  }
}

type PointsListMaterialGroupTotalsInitArgs = PointsListMaterialGroupTotals;
