import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { WINDOW } from '@dartsales/common/core/utils/window-token';

import { PageSearchFormControls } from './page-search-form';

/** Search node. */
type SearchNode = {

  /** Text. */
  readonly text: string;

  /** Element. */
  readonly element: Node;
};

/** Project page search component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-project-page-search',
  templateUrl: './project-page-search.component.html',
  styleUrls: ['./project-page-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectPageSearchComponent implements OnInit {

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly document = inject(DOCUMENT);

  private readonly window = inject(WINDOW);

  /** Search on page form. */
  protected readonly searchForm = this.createSearchForm();

  /** Search result. */
  // We use "null" for do not display results if search value is empty string.
  protected searchResults: readonly HTMLElement[] | null = null;

  /** Accented item index of highlighted results. */
  protected activeIndex = 0;

  private readonly highlightCssClass = 'search-highlight';

  private readonly activeHighlightCssClass = `${this.highlightCssClass}_active`;

  private readonly highlightWrapperCssClass = `${this.highlightCssClass}-wrapper`;

  /** Is search active. */
  protected get isSearchActive(): boolean {
    return this.searchForm.controls.search.value !== '' && this.searchResults !== null;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSearchFormChanges();
  }

  /** Handle submit form. */
  protected onSubmit(): void {
    const mainElement = this.document.querySelector('main');
    if (mainElement === null) {
      return;
    }

    this.searchForm.markAllAsTouched();
    const searchValue = this.searchForm.controls.search.value.toLowerCase();

    this.resetSelection();
    this.removeHighlight(mainElement);

    if (searchValue === '') {
      return;
    }

    const allNodes = this.getAllTextNodes(mainElement);
    const filteredBySearchNodes = allNodes.filter(node => node.text.includes(searchValue));

    filteredBySearchNodes.forEach(node => {
      this.highlightElement(node, searchValue);
    });

    const allResults = mainElement.querySelectorAll<HTMLElement>(`.${this.highlightCssClass}`);
    const filteredVisibleResults: HTMLElement[] = [];

    allResults.forEach(element => {
      const isVisibleElement = this.checkElementVisibility(element);
      if (isVisibleElement) {
        filteredVisibleResults.push(element);
      }
    });

    this.searchResults = [...filteredVisibleResults];

    if (filteredVisibleResults.length > 0) {
      const firstElement = filteredVisibleResults[0];
      this.selectActiveElement(firstElement);
      this.activeIndex = 1;
    }
  }

  /**
   * Handle click on next or prev button.
   * @param indexOffset Step for get index next or prev element.
   * @param event Click event.
   */
  protected onNavigateClick(indexOffset: 1 | -1, event?: MouseEvent): void {
    event?.stopPropagation();
    const activeHighlightEl = this.document.querySelector(`.${this.activeHighlightCssClass}`);
    activeHighlightEl?.classList.remove(this.activeHighlightCssClass);

    const currentIndex = this.searchResults?.findIndex(element => element === activeHighlightEl) ?? -1;
    if (currentIndex !== -1 && this.searchResults) {
      let nextIndex = currentIndex + indexOffset;
      if (nextIndex < 0) {
        nextIndex = this.searchResults.length - 1;
      } else {
        nextIndex %= this.searchResults.length;
      }
      const nextElement = this.searchResults[nextIndex];
      if (nextElement) {
        this.selectActiveElement(nextElement);
        this.activeIndex = nextIndex + 1;
      }
    }
  }

  private createSearchForm(): FormGroup<PageSearchFormControls> {
    return this.fb.group<PageSearchFormControls>({
      search: this.fb.control(''),
    });
  }

  private getAllTextNodes(mainElement: HTMLElement): SearchNode[] {
    // This implementation is taken from 'CSS Custom Highlight API' examples.
    // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Custom_Highlight_API#highlighting_search_results.
    const treeWalker = this.document.createTreeWalker(mainElement, NodeFilter.SHOW_TEXT);
    const allTextNodes: Node[] = [];
    let currentNode = treeWalker.nextNode();

    while (currentNode) {
      allTextNodes.push(currentNode);
      currentNode = treeWalker.nextNode();
    }

    return allTextNodes.map(element => ({
      element,
      text: element.textContent?.toLowerCase() ?? '',
    }));
  }

  private removeHighlight(mainElement: HTMLElement): void {
    const elements = mainElement.querySelectorAll(`.${this.highlightCssClass}`);
    const regex = new RegExp(`(<mark class="${this.highlightCssClass}">|</mark>)`, 'gim');

    elements.forEach(el => {
      const { parentElement } = el;
      const isParentElementWrapper = parentElement?.classList.contains(this.highlightWrapperCssClass);
      if (parentElement != null && isParentElementWrapper) {
        const replacedText = (parentElement.textContent ?? '').replace(regex, '');
        const textNode = this.document.createTextNode(replacedText);
        parentElement.replaceWith(textNode);
      }
    });
  }

  private highlightElement(selected: SearchNode, query: string): void {
    const { parentElement } = selected.element;
    if (parentElement === null || parentElement.classList.contains('mat-icon')) {
      return;
    }

    const regex = new RegExp(query, 'gi');
    parentElement.childNodes.forEach(node => {
      if (node instanceof Text) {
        const nodeText = node.textContent ?? '';
        const replacedNodeText = nodeText.replace(regex, `<mark class="${this.highlightCssClass}">$&</mark>`);
        if (nodeText !== replacedNodeText) {
          const nodeWithHighlightedText = this.document.createElement('span');
          nodeWithHighlightedText.classList.add(this.highlightWrapperCssClass);
          nodeWithHighlightedText.innerHTML = (node.textContent ?? '').replace(regex, `<mark class="${this.highlightCssClass}">$&</mark>`);
          node.replaceWith(nodeWithHighlightedText);
        }
      }
    });
  }

  private resetSelection(): void {
    const activeHighlightEl = this.document.querySelector(`.${this.activeHighlightCssClass}`);
    activeHighlightEl?.classList.remove(this.activeHighlightCssClass);
    this.activeIndex = 0;
    this.searchResults = null;
  }

  private selectActiveElement(element: HTMLElement): void {
    const observer = new IntersectionObserver(elementsList => {
      if (elementsList.some(item => item.isIntersecting === false)) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      observer.disconnect();
    });

    observer.observe(element);
    element.classList.add(this.activeHighlightCssClass);
  }

  private subscribeToSearchFormChanges(): void {
    this.searchForm.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        if (value.search === '') {
          this.onSubmit();
        }
      });
  }

  private checkElementVisibility(element: HTMLElement): boolean {
    const parent = element.parentElement;

    if (parent === null) {
      return false;
    }

    // We get Y-offset and X-offset for element and its parent.
    // If offset for element less than for parent, element is visible on the window.
    const innerRect = element.getBoundingClientRect();
    const outerRect = parent.getBoundingClientRect();
    const isVisibleVertically = innerRect.y + innerRect.height <= outerRect.y + outerRect.height;
    const isVisibleHorizontally = innerRect.x + innerRect.width <= outerRect.x + outerRect.width;
    if (isVisibleVertically && isVisibleHorizontally) {
      return true;
    }

    // For elements with offset less than their parents offset we add check to see whether this parents scrollable or not.
    if (parent.clientHeight < parent.scrollHeight) {
      const { overflowY } = this.window.getComputedStyle(parent);
      return overflowY === 'auto' || overflowY === 'scroll';
    }
    if (parent.clientWidth < parent.scrollWidth) {
      const { overflowX } = this.window.getComputedStyle(parent);
      return overflowX === 'auto' || overflowX === 'scroll';
    }

    return false;
  }
}
