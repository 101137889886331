import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl } from '@angular/forms';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateMultipleExportParamsFormMapper } from '@dartsales/common/core/services/mappers/forms/export/estimate-multiple-export-config-params.mapper';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { ValidationErrorCode } from '@dartsales/common/core/models/errors/validation-error-code';
import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { EstimateMultipleExportParams } from '@dartsales/common/core/models/exports/estimate-multiple-export-params';
import { ViewType } from '@dartsales/common/core/models/view-type';

import { injectEstimateExportService } from '../../../../project-dashboard/services/export/estimate/estimate-export.service';

import { ExportMultipleEstimateFormErrorStateMatcher } from './export-multiple-estimate-error-state-matcher';

/** Export multiple estimate dialog data. */
export type ExportMultipleEstimateDialogData = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;
};

/** Export multiple estimate dialog component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-export-multiple-estimate-dialog',
  templateUrl: './export-multiple-estimate-dialog.component.html',
  styleUrls: ['../shared-export-dialog-styles.css', './export-multiple-estimate-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportMultipleEstimateDialogComponent {

  /** Estimate Id. */
  protected readonly estimateId = inject<ExportMultipleEstimateDialogData>(MAT_DIALOG_DATA).estimateId;

  /** Are exported files loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  /** Dialog ref. */
  protected readonly dialogRef = inject<MatDialogRef<ExportMultipleEstimateDialogComponent>>(MatDialogRef);

  private readonly exportEstimateConfigFormMapper = inject(EstimateMultipleExportParamsFormMapper);

  private readonly exportEstimateService = injectEstimateExportService();

  /** Config form. */
  protected readonly configForm = this.exportEstimateConfigFormMapper.modelToForm();

  /** Export format. */
  protected readonly exportFormat = ExportFormat;

  /** View type. */
  protected readonly viewType = ViewType;

  private readonly errorStateMatcher = new ExportMultipleEstimateFormErrorStateMatcher(this.configForm);

  /** Form app error. */
  protected get formAppError(): string | null {
    return this.configForm.getError(ValidationErrorCode.AppError);
  }

  /** Should show form error. */
  protected get shouldShowFormError(): boolean {
    return this.errorStateMatcher.isErrorState();
  }

  /**
   * Handle selected modules changes.
   * @param modules Modules.
   * @param control Control.
   */
  protected handleSelectedModulesChanges(modules: ModuleType[], control: FormControl<readonly ModuleType[]>): void {
    control.setValue(modules);
    control.markAsTouched();
  }

  /** Handle submit form. */
  protected onSubmit(): void {
    this.configForm.markAllAsTouched();

    if (this.configForm.invalid) {
      return;
    }

    const formValues = this.configForm.getRawValue();
    const config = this.exportEstimateConfigFormMapper.formToModel(formValues);

    this.exportEstimateService.exportMultiple(
      new EstimateMultipleExportParams({
        estimateId: this.estimateId,
        ...config,
      }),
    ).pipe(
      toggleExecutionState(this.isLoading$),
      untilDestroyed(this),
    )
      .subscribe(() => this.dialogRef.close());
  }
}
