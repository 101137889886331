import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Info card. */
@Component({
  selector: 'dartsalesw-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {

  /** Info message. */
  @Input()
  public infoMessage = '';
}
