<button
  mat-icon-button
  type="button"
  class="expand-button"
  [class.expand-button_rotate]="isExpanded"
  (click)="buttonClick.emit()"
>
  <mat-icon
    class="expand-button__icon"
    svgIcon="extend"
  />
</button>
