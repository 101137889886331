import { InjectionToken, inject } from '@angular/core';
import { FormControl } from '@angular/forms';

/** Filter component. */
export type FilterComponentType<T, D = void> = {

  /** Form control. */
  readonly formControl: FormControl<T>;

  /** Data. */
  readonly data?: D;
};

export const FILTER_COMPONENT_CONTROL = new InjectionToken<FormControl>(
  'Token to pass form control instance to dynamic component.',
);

export const FILTER_COMPONENT_DATA = new InjectionToken<unknown>(
  'Token to pass data payload to dynamic component.',
);

/** Inject filter control. */
export function injectFilterControl<T>(): FormControl<T> {
  return inject(FILTER_COMPONENT_CONTROL);
}

/** Inject filter data. */
export function injectFilterData<T>(): T {
  return inject<T>(FILTER_COMPONENT_DATA);
}
