import { FilterGroup } from '../filter-data';
import { FilterHelper } from '../filter-helper';

import { BidInfoField } from './filter-groups/bid-info-filter-fields';
import { CompanyField } from './filter-groups/company-filter-fields';
import { ContractingTeamField } from './filter-groups/contracting-team-filter-fields';
import { ProjectField } from './filter-groups/project-filter-fields';
import { TeamField } from './filter-groups/team-info-filter-fields';

export type ProjectFilterFieldType = ProjectField | ContractingTeamField | BidInfoField | CompanyField | TeamField;

const ALL_FILTER_FIELDS = [
  ...ProjectField.getAllFields(),
  ...ContractingTeamField.getAllFields(),
  ...BidInfoField.getAllFields(),
  ...CompanyField.getAllFields(),
  ...TeamField.getAllFields(),
] as const;

/** Get filter groups. */
const FILTER_GROUPS: FilterGroup<ProjectFilterFieldType>[] = [
  {
    label: ProjectField.GROUP_NAME,
    filters: ProjectField.getAllFields(),
  },
  {
    label: ContractingTeamField.GROUP_NAME,
    filters: ContractingTeamField.getAllFields(),
  },
  {
    label: BidInfoField.GROUP_NAME,
    filters: BidInfoField.getAllFields(),
  },
  {
    label: CompanyField.GROUP_NAME,
    filters: CompanyField.getAllFields(),
  },
  {
    label: TeamField.GROUP_NAME,
    filters: TeamField.getAllFields(),
  },
];

/** Class for all project filters fields. */
class ProjectsFilterHelper extends FilterHelper<ProjectFilterFieldType> {
  /** @inheritdoc */
  protected override readonly filterFields = ALL_FILTER_FIELDS;

  /** Filter groups list. */
  public readonly filterGroups = FILTER_GROUPS;
}

/** All project filters fields. */
export const projectsFilterHelper = new ProjectsFilterHelper();
