import { MatTooltip } from '@angular/material/tooltip';

import { CellEditorService } from './cell-editor.service';
import { CellRendererService } from './cell-renderer.service';

/** Cell services. */
export const CELL_PROVIDERS = [
  CellRendererService,
  CellEditorService,
  MatTooltip,
];
