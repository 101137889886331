import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, concat, finalize, first, fromEvent, ignoreElements, merge, skip, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HubConnectionState } from '@microsoft/signalr';

import { filterNull } from '@dartsales/common/core/utils/rxjs/filter-null';
import { WINDOW } from '@dartsales/common/core/utils/window-token';

import { EstimateId } from '../../../models/estimate/estimate';
import { AppUrlsConfig } from '../app-urls.config';

import { AbstractHubService } from './abstract-hub.service';

type ClonedEventParams = {

  /** ID of an existing base estimate, based on which cloning should be performed. */
  readonly estimateId: EstimateId;

  /** Whether a base estimate is template. */
  readonly isTemplate: boolean;
};

type ClonedEventPayload = {

  /** Operation ID. */
  readonly operationId: string;

  /** ID of a new cloned estimate. */
  readonly clonedBaseEstimateId: EstimateId;

  /** Whether a cloned estimate is template. */
  readonly isTemplate: boolean;
};

type ClonedEventSubscription = {

  /** Payload. */
  readonly payload$: Observable<ClonedEventPayload>;

  /** Cancel subscription. */
  readonly unsubscribe: () => void;
};

/** Methods. */
export const methods = {
  join: (baseEstimateId: EstimateId, ...args: unknown[]) => ['server-join-base-estimate-group', baseEstimateId, ...args] as const,
  leave: (baseEstimateId: EstimateId) => ['server-leave-base-estimate-group', baseEstimateId] as const,
};

/** Events. */
export const events = {
  cloned: 'client-base-estimate-data-duplicated',
} as const;

/** Estimates hub service. */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class EstimatesHubService extends AbstractHubService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly window = inject(WINDOW);

  /**
   * `subscribeToClonedEvents` doesn't create a separate connection for each execution.
   * If unsubscribe happen in the middle of other cloning, we'd lose notification.
   */
  private clonedEventsSubscribersCount = 0;

  /** @inheritdoc */
  protected readonly connection = this.createHubConnection(this.apiUrls.hubs.baseEstimates);

  public constructor() {
    super();
    this.listenForWindowFocus();
  }

  /** Establish connection with the estimates hub. */
  public startConnection(): Observable<never> {
    const initialConnection$ = this.secretStorage.currentSecret$.pipe(
      filterNull(),
      first(),
      switchMap(() => this.connect()),
    );

    // Reset connection after 401 error.
    const connectionReset$ = this.secretStorage.currentSecret$.pipe(
      filterNull(),

      // Skip initial secret.
      skip(1),
      switchMap(secret => {
        const { token } = secret;

        if (token !== this.currentToken && this.isConnected()) {
          return concat(this.disconnect(), this.connect());
        }

        return EMPTY;
      }),
    );

    return merge(
      initialConnection$,
      connectionReset$,
    ).pipe(
      finalize(() => this.disconnect()),
      ignoreElements(),
    );
  }

  /**
   * Subscribe to the estimate/template cloning event.
   * @param args Connection params.
   */
  public subscribeToClonedEvents({ estimateId, isTemplate }: ClonedEventParams): ClonedEventSubscription {
    this.clonedEventsSubscribersCount += 1;

    const payloadSubject = new ReplaySubject<ClonedEventPayload>(1);
    const handleClonedEvent = (operationId: string, clonedBaseEstimateId: EstimateId, isResultTemplate: boolean): void => {
      payloadSubject.next({
        operationId, clonedBaseEstimateId, isTemplate: isResultTemplate,
      });
    };

    // Trying to reconnect in case it being disconnected.
    this.connect().then(() => {
      this.connection.on(events.cloned, handleClonedEvent);
      this.connection.invoke(...methods.join(estimateId, isTemplate));
    });

    return ({
      payload$: payloadSubject.asObservable(),
      unsubscribe: () => {
        this.clonedEventsSubscribersCount -= 1;
        if (this.clonedEventsSubscribersCount === 0) {
          this.connection.off(events.cloned, handleClonedEvent);
          this.connection.invoke(...methods.leave(estimateId));
        }
      },
    });
  }

  private async connect(): Promise<void> {
    if (!this.isDisconnected()) {
      return;
    }

    try {
      await this.connection.start();
    } catch (err: unknown) {
      // Retry initial start failure.
      // `.withAutomaticReconnect()` won't fire retry on start failure.
      setTimeout(() => this.connect(), 5000);
    }
  }

  private async disconnect(): Promise<void> {
    if (this.isConnected()) {
      await this.connection.stop();
      this.connection.off(events.cloned);
    }
  }

  private isConnected(): boolean {
    return this.connection.state === HubConnectionState.Connected;
  }

  private isDisconnected(): boolean {
    return this.connection.state === HubConnectionState.Disconnected;
  }

  /**
   * Browsers have a tab freezing or sleeping feature
   * to reduce computer resource usage for inactive tabs.
   * This can cause SignalR connections to close.
   *
   * We're not trying to keeps tab from sleeping,
   * but instead reconnecting when tab in focus.
   */
  private listenForWindowFocus(): void {
    fromEvent(this.window, 'focus').pipe(
      switchMap(() => this.connect()),
      untilDestroyed(this),
    )
      .subscribe();
  }
}
