import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, inject } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PERCENT_MASK, PERCENT_ROUND_FRACTION_DIGITS } from '@dartsales/common/core/utils/constants';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';
import { parseMaskedNumber } from '@dartsales/common/core/utils/parse-masked-number';
import { mapDecimalToPercent } from 'projects/web/src/app/features/shared/components/inputs/abstract-input';

import { TableCellFormControl } from '../table-cell-form-control.component';
import { TableCellPrefixDirective } from '../../directives/table-cell-prefix.directive';

/** Percent number table cell. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-percent-number-table-cell',
  templateUrl: './percent-number-table-cell.component.html',
  styleUrls: ['./percent-number-table-cell.component.css'],

  // We need Default change detection to properly display errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PercentNumberTableCellComponent extends TableCellFormControl<number> implements OnInit {
  /** Whether value in view mode should be hidden. */
  @Input()
  public hideValue = false;

  /** Is readonly. */
  @Input()
  public isReadonly = false;

  /** Cell prefix. */
  @ContentChild(TableCellPrefixDirective)
  protected readonly cellPrefix?: TableCellPrefixDirective;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Percent form control. */
  protected readonly percentControl = this.fb.control<string>('');

  /** Percent mask. */
  protected readonly percentMask = PERCENT_MASK;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  /** @inheritdoc */
  public override writeValue(value: number | null): void {
    const percent = this.roundPercent(mapDecimalToPercent(value ?? 0));
    this.percentControl.setValue(percent.toString(), { emitEvent: false });
    super.writeValue(value);
  }

  private subscribeToControlChanges(): void {
    this.percentControl.valueChanges.pipe(
      untilDestroyed(this),
    )
      .subscribe(value => {
        const oldValue = this.controlValue ?? 0;
        const number = parseMaskedNumber(value, this.percentMask);
        const newPercentValue = this.roundPercent(number / 100);

        if (!compareRoundNumbers(oldValue, newPercentValue)) {
          this.controlValue = newPercentValue;
        }
      });
  }

  private roundPercent(value: number): number {
    return Number(value.toFixed(PERCENT_ROUND_FRACTION_DIGITS));
  }
}
