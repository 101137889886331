import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/** Base dialog component. */
@Component({
  selector: 'dartsalesc-base-dialog',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseDialogComponent {

  private readonly dialogRef = inject<MatDialogRef<BaseDialogComponent, void>>(MatDialogRef);

  /** Dialog title. */
  @Input()
  public dialogTitle = '';

  /** Subtitle. */
  @Input()
  public subtitle = '';

  /**
   * Whether custom dialog action should be used.
   * It might be useful if you want to confirm dialog close.
   * If 'true' then dialogClose event will be emitted.
   */
  @Input()
  public useCustomCloseAction = false;

  /** Dialog close event. */
  @Output()
  public readonly dialogClose = new EventEmitter<void>();

  /** Handle close click. */
  protected onClose(): void {
    if (this.useCustomCloseAction) {
      this.dialogClose.emit();
    } else {
      this.dialogRef.close();
    }
  }
}
