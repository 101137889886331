import { LaborRole } from './labor-role';

/** Labor role internal rates. */
export class LaborRoleInternalRates {
  /** Regular rate amount. */
  public readonly rate: LaborRole['rate']['internal'];

  /** Overtime one rate amount. */
  public readonly rateOvertimeOne: LaborRole['rateOvertimeOne']['internal'];

  /** Overtime two rate amount. */
  public readonly rateOvertimeTwo: LaborRole['rateOvertimeTwo']['internal'];

  public constructor(data: LaborRoleInternalRatesInitArgs) {
    this.rate = data.rate;
    this.rateOvertimeOne = data.rateOvertimeOne;
    this.rateOvertimeTwo = data.rateOvertimeTwo;
  }
}

type LaborRoleInternalRatesInitArgs = LaborRoleInternalRates;
