import { AmountCalcUnits } from '@dartsales/common/core/enums/amount-calc-units';
import { MarginType } from '@dartsales/common/core/enums/margin-type';
import { calculatePercentByFraction } from '@dartsales/common/core/utils/percents';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { MAX_GROSS_MARGIN_PERCENT_NUMBER } from '@dartsales/common/core/utils/constants';
import { toFiniteNumber } from '@dartsales/common/core/utils/to-finite-number';

import { MarginParams } from '../../margin-params';
import {
  Margin,
  MarginValues,
  OverridableMargin,
  OverridableMarginValues,
} from '../../estimate/modules/margin';

import { DomainCostCalculator } from './domain-cost-calculator';

/** Domain margin calculator. */
export namespace DomainMarginCalculator {

  /**
   * Params for gross margin and markup calculation with partial `MarginValues`.
   * It's used when it is necessary to redefine only part of the initial parameters and re-calculate
   * all calculated values.
   **/
  export type CalculationMarginParams = {

    /** Gross margin or markup source values for calculation. */
    readonly values: MarginValues;

    /**
     * The parameters determine which characteristic from `GrossMargin`, `Markup` or `Amount` is the initial one
     * for calculations.
     * By default, the basis is the `grossMargin`, but if the user entered, for example, the `amount`, then in this
     * calculation it is the `amount` should be the basis.
     **/
    readonly marginParams: MarginParams;
  } & DomainCostCalculator.TotalDirectCostParams;

  /**
   * Params for gross margin and markup calculation with partial Overrided `MarginValues`.
   * It's used when it is necessary to redefine only part of the initial parameters and re-calculate
   * all calculated values.
   **/
  export type CalculationOverrideMarginParams = StrictOmit<CalculationMarginParams, 'values'> & {

    /** Source prefilled margin object which props will be used for calculation. */
    readonly source?: OverridableMargin;

    /** Values of gross margin and markup. */
    readonly values?: Partial<OverridableMarginValues>;
  };

  /**
   * Calculate margin.
   * For calculation details see formulas from
   * https://docs.google.com/spreadsheets/d/1VzxlBUTNTsKc2SRX-DNj0MGar7zejjTqUkTlMQooJso/edit#gid=0.
   * @param args Arguments.
   */
  export function calculateMargin({
    directCost,
    contingencyAmount,
    escalationAmount,
    values,
    marginParams,
  }: CalculationMarginParams): Margin {
    const totalDirectCost = DomainCostCalculator.calculateTotalDirectCost({
      directCost,
      contingencyAmount,
      escalationAmount,
    });

    if (marginParams.units === AmountCalcUnits.Amount) {
      return calculateMarginByAmount(totalDirectCost, values.amount ?? 0);
    } else if (marginParams.marginType === MarginType.Markup) {
      return calculateMarginByMarkupPercent(totalDirectCost, values.markup ?? 0);
    }
    return calculateMarginByMarginGrossMarginPercent(totalDirectCost, values.grossMargin ?? 0);
  }

  /**
   * Calculate the value of the `GrossMargin` and `Markup` pair based on the `Amount`.
   * @param totalDirectCost Total direct cost.
   * @param amount Margin/Markup amount.
   * @returns Values of gross margin and markup.
   */
  function calculateMarginByAmount(
    totalDirectCost: number,
    amount: number,
  ): Margin {
    return Margin.fromValues({
      amount,
      grossMargin: calculatePercentByFraction(totalDirectCost + amount, amount),
      markup: calculatePercentByFraction(totalDirectCost, amount),
    });
  }

  /**
   * Calculate the value of the `GrossMargin` and `Markup` pair based on the `GrossMargin` percent.
   * @param totalDirectCost Total direct cost.
   * @param grossMarginPercent Gross margin percent.
   * @returns Values of gross margin and markup.
   */
  export function calculateMarginByMarginGrossMarginPercent(
    totalDirectCost: number,
    grossMarginPercent: number,
  ): Margin {
    const limitedGrossMargin = grossMarginPercent < 1 ? grossMarginPercent : MAX_GROSS_MARGIN_PERCENT_NUMBER / 100;
    const amount =
        (totalDirectCost * (limitedGrossMargin ?? 0)) /
        (1 - (limitedGrossMargin ?? 0));

    return Margin.fromValues({
      amount,
      grossMargin: limitedGrossMargin,
      markup: calculatePercentByFraction(totalDirectCost, amount),
    });
  }

  /**
   * Calculate the value of the `GrossMargin` and `Markup` pair based on the `Markup` percent.
   * @param totalDirectCost Total direct cost.
   * @param markupPercent Markup percent.
   * @returns Values of gross margin and markup.
   */
  export function calculateMarginByMarkupPercent(
    totalDirectCost: number,
    markupPercent: number,
  ): Margin {
    const amount = totalDirectCost * markupPercent;

    return Margin.fromValues({
      amount,
      grossMargin: calculateGrossMarginPercentByMarkupPercent(markupPercent),
      markup: markupPercent,
    });
  }

  /**
   * Calculate gross margin percent by markup percent.
   * @param markupPercent Markup percent.
   */
  export function calculateGrossMarginPercentByMarkupPercent(
    markupPercent: number,
  ): number {
    return toFiniteNumber(markupPercent / (1 + markupPercent));
  }

  type CalculateMarkupPercentParams = {

    /** Markup amount. */
    readonly amount: number;
  } & DomainCostCalculator.TotalDirectCostParams;

  /**
   * Calculate margin pair percents usually for tables headers bulk updater.
   * @param args Arguments.
   */
  export function calculateMarginValues({
    directCost,
    contingencyAmount,
    amount,
    escalationAmount,
  }: CalculateMarkupPercentParams): MarginValues {
    const margin = calculateMargin({
      directCost,
      contingencyAmount,
      values: { amount },
      marginParams: { units: AmountCalcUnits.Amount, marginType: MarginType.DEFAULT },
      escalationAmount,
    });

    return {
      amount,
      grossMargin: margin.grossMargin.percent,
      markup: margin.markup.percent,
    };
  }

  /**
   * Calculate margin for overridable value.
   * @param args Arguments.
   */
  export function calculateOverridableMargin({
    directCost,
    contingencyAmount,
    source,
    values,
    marginParams,
    escalationAmount,
  }: CalculationOverrideMarginParams): OverridableMargin {

    const initialMargin = DomainMarginCalculator.calculateMargin({
      directCost,
      contingencyAmount,
      escalationAmount,
      values: {
        grossMargin: values?.initial?.grossMargin ?? source?.grossMargin.initial.percent,
        markup: values?.initial?.markup ?? source?.markup.initial.percent,
        amount: values?.initial?.amount ??
          (marginParams.marginType === MarginType.GrossMargin ? source?.grossMargin.initial.amount : source?.markup.initial.amount),
      },
      marginParams,
    });

    let overrideMarkup: number | undefined;
    let overrideGrossMargin: number | undefined;
    let overrideAmount: number | undefined;

    /*
      Set some overridden parameter, only consistent with the corresponding calculation parameters, in order to
      prevent the calculation of the overridden value for unimportant parameters.
    */
    if (marginParams.units === AmountCalcUnits.Amount) {
      overrideAmount = values?.override?.amount ??
        (marginParams.marginType === MarginType.GrossMargin ? source?.grossMargin.override?.amount : source?.markup.override?.amount);
    } else if (marginParams.marginType === MarginType.GrossMargin) {
      overrideGrossMargin = values?.override?.grossMargin ?? source?.grossMargin.override?.percent;
    } else {
      overrideMarkup = values?.override?.markup ?? source?.markup.override?.percent;
    }

    const overrideMargin =
      overrideMarkup !== undefined ||
      overrideGrossMargin !== undefined ||
      overrideAmount !== undefined ?
        DomainMarginCalculator.calculateMargin({
          directCost,
          contingencyAmount,
          escalationAmount,
          values: {
            grossMargin: overrideGrossMargin,
            markup: overrideMarkup,
            amount: overrideAmount,
          },
          marginParams,
        }) : null;

    return OverridableMargin.fromValues({
      initial: {
        amount: initialMargin.grossMargin.amount,
        grossMargin: initialMargin.grossMargin.percent,
        markup: initialMargin.markup.percent,
      },
      override: overrideMargin ?
        {
          amount: overrideMargin.grossMargin.amount,
          grossMargin: overrideMargin.grossMargin.percent,
          markup: overrideMargin.markup.percent,
        } :
        null,
    });
  }
}
