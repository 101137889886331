import { CollapsibleRowData } from '@dartsales/common/shared/components/ag-grid-table/utils/collapse-rows-strategy';
import { LaborRowType } from 'projects/web/src/app/features/project-dashboard/services/labor/labor-row-expansion.service';

import { ModuleSettingsLocalStorage } from './module-settings';

/** Labor module to save in local storage. */
export class LaborModuleLocalStorage extends ModuleSettingsLocalStorage {

  /** Is rates expanded. */
  public readonly isExpandRates: boolean;

  /** Collapse row types. */
  public readonly collapsedRowTypes: readonly LaborRowType[];

  /** Collapse rows data. */
  public readonly collapsibleRowData: readonly CollapsibleRowData<LaborRowType>[];

  public constructor(data: LaborModuleLocalStorageInitArgs) {
    super(data);
    this.isExpandRates = data.isExpandRates;
    this.collapsedRowTypes = data.collapsedRowTypes;
    this.collapsibleRowData = data.collapsibleRowData;
  }
}

type LaborModuleLocalStorageInitArgs = LaborModuleLocalStorage;
