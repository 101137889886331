import { PointsListOverridableItemTotal } from '../points-list-overridable-item-total';

import { PointsListTabLaborTask } from './points-list-tab-labor-task';

/** Points list tab properties. */
export class PointsListTabProperties {
  /** Total calculated values of a tab. */
  public readonly total: PointsListOverridableItemTotal;

  /** Tab labor tasks. */
  public readonly tasks: readonly PointsListTabLaborTask[];

  public constructor(data: PointsListTabPropertiesInitArgs) {
    this.total = data.total;
    this.tasks = data.tasks;
  }
}

type PointsListTabPropertiesInitArgs = PointsListTabProperties;
