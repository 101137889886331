import { Injectable } from '@angular/core';

import { UserRole } from '../../enums/user-role';

import { OrganizationUserPermissionFlagsDto } from './dto/organization-user-permissions.dto';
import { IMapperFromDto } from './mappers';

const flagsToUserRoleMap: Record<keyof OrganizationUserPermissionFlagsDto, UserRole> = {
  isAdmin: UserRole.OrganizationAdmin,
  isUserAdmin: UserRole.UserAdmin,
  isManager: UserRole.Manager,
  isDeveloper: UserRole.Developer,
  isSalesUser: UserRole.Sales,
  isSalesManager: UserRole.SalesManager,
  isSalesPerson: UserRole.SalesPerson,
  isProjectCxEngineer: UserRole.ProjectCxEngineer,
  isProjectDeveloper: UserRole.ProjectDeveloper,
  isProjectEngineer: UserRole.ProjectEngineer,
  isProjectTechnician: UserRole.ProjectTechnician,
};

/** Mapper for Organization User Roles. */
@Injectable({
  providedIn: 'root',
})
export class OrganizationUserRolesMapper implements IMapperFromDto<OrganizationUserPermissionFlagsDto, UserRole[]> {
  /** @inheritdoc */
  public fromDto(dto: OrganizationUserPermissionFlagsDto): UserRole[] {
    return Object.entries({ ...dto })
      .map(([key, hasRole]) => {
        const role = flagsToUserRoleMap[key as keyof OrganizationUserPermissionFlagsDto];
        return hasRole === true ? role : undefined;
      })
      .filter((item): item is UserRole => item !== undefined);
  }

}
