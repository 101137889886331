<div class="sort">
  <button
    class="sort-option"
    mat-button
    type="button"
    [class.sort-option_active]="isAscendingSortActive"
    [disabled]="isAscendingSortActive"
    (click)="onAscendingSortClick()"
  >
    Sort A → Z
  </button>
  <button
    class="sort-option"
    mat-button
    type="button"
    [class.sort-option_active]="isDescendingSortActive"
    [disabled]="isDescendingSortActive"
    (click)="onDescendingSortClick()"
  >
    Sort Z → A
  </button>
</div>
