import { Type } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { FilterComponentType } from './filter-component';
import { FilterOperatorMapper } from './filter-operator-mapper';
import { FilterOperatorType } from './filter-operator-type';

/** Filter operator. */
export class FilterOperator<T = unknown, D = unknown> {
  /** Operator type. */
  public readonly type: FilterOperatorType;

  /** Operator label. */
  public readonly label: string;

  /** Default value. */
  public readonly defaultValue: T;

  /** Component. If 'undefined' then no component will be rendered. */
  public readonly component?: Type<FilterComponentType<T, D>>;

  /** Component payload. */
  public readonly componentPayload?: D;

  /** Validators. */
  public readonly validators: ValidatorFn[];

  /** Function to get operator value from string params. */
  public readonly mapper: FilterOperatorMapper<T>;

  public constructor(data: FilterOperatorInitArgs<T, D>) {
    this.type = data.type;
    this.label = data.label;
    this.defaultValue = data.defaultValue;
    this.validators = data.validators ?? [];
    this.component = data.component;
    this.componentPayload = data.componentPayload;
    this.mapper = data.mapper;
  }
}

type FilterOperatorInitArgs<T, D> = StrictOmit<FilterOperator<T, D>, 'validators'> & {
  readonly validators?: FilterOperator<T>['validators'];
};
