/** Batch edit entity. */
export abstract class BatchEditEntity<TKey, TValue> {
  /** Item identifier. */
  public readonly id: TKey;

  /** Item value. */
  public readonly value: TValue;

  public constructor(data: BatchEditEntityInitArgs<TKey, TValue>) {
    this.id = data.id;
    this.value = data.value;
  }
}

type BatchEditEntityInitArgs<TKey, TValue> = BatchEditEntity<TKey, TValue>;
