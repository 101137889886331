import { Injectable } from '@angular/core';

import { LaborRoleHours } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';

import { IMapper } from '../../../mappers';
import { LaborRoleHoursDto } from '../../../dto/estimate/modules/labor/labor-role-hours.dto';

/** Labor role hours entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleHoursMapper implements IMapper<LaborRoleHoursDto, LaborRoleHours> {

  /** @inheritdoc */
  public fromDto(dto: LaborRoleHoursDto): LaborRoleHours {
    return new LaborRoleHours({
      regular: dto.hours ?? 0,
      overtimeOne: dto.hoursOvertimeOne ?? 0,
      overtimeTwo: dto.hoursOvertimeTwo ?? 0,
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborRoleHours): LaborRoleHoursDto {
    return {
      hours: data.regular,
      hoursOvertimeOne: data.overtimeOne,
      hoursOvertimeTwo: data.overtimeTwo,
    };
  }
}
