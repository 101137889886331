import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Subject } from 'rxjs';

/**
 * This directive solves problem with scroll triggering again.
 * Issue - https://github.com/orizens/ngx-infinite-scroll/issues/316.
 */
@UntilDestroy()
@Directive({
  selector: '[infiniteScroll][dartsalescScrollToTop]',
})
export class ScrollToTopDirective implements OnInit {

  /** Trigger for reset infiniteScroll and scroll to top. */
  @Input()
  public set dartsalescScrollToTop(_: unknown) {
    this.trigger$.next();
  }

  private readonly infiniteScrollDirective = inject(InfiniteScrollDirective);

  private readonly nativeElement = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

  private readonly trigger$ = new Subject<void>();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.trigger$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.scrollToTop());
  }

  /**
   * This code solves problem with scroll triggering again.
   * Issue - https://github.com/orizens/ngx-infinite-scroll/issues/316.
   */
  public scrollToTop(): void {
    this.nativeElement.scroll({ top: 0, behavior: 'smooth' });

    this.infiniteScrollDirective.destroyScroller();
    this.infiniteScrollDirective.setup();
  }
}
