import { Injectable, inject } from '@angular/core';

import { ModuleProperties } from '@dartsales/common/core/models/estimate/modules/module-properties';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';

import { IMapper } from '../../mappers';
import { AmountPercentMapper } from '../amount-percent.mapper';
import { ModulePropertiesDto } from '../../dto/estimate/modules/module-properties.dto';
import { BasePricingMapper } from '../../pricing/base-pricing.mapper';
import { ModuleLockablePercentsStatusMapper } from '../module-lockable-percents-status.mapper';

/** Module properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModulePropertiesMapper implements IMapper<ModulePropertiesDto, ModuleProperties> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: ModulePropertiesDto): ModuleProperties {
    return new ModuleProperties({
      ...this.basePricingMapper.fromOverridableDto(dto),
      customerMarkup: this.amountPercentMapper.fromDto(dto.markupCustomer),
      disableEscalation: dto.excludeRegularEscalation,
      lockablePercentsStatus: dto.lockablePercentsStatus ?
        this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus) :
        ModuleLockablePercentsStatus.getDefaultStatus(),
    });
  }

  /** @inheritdoc */
  public toDto(data: ModuleProperties): ModulePropertiesDto {
    return {
      ...this.basePricingMapper.toOverridableDto(data),
      markupCustomer: this.amountPercentMapper.toDto(data.customerMarkup),
      excludeRegularEscalation: data.disableEscalation,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),

    };
  }
}
