import { InputmaskOptions } from '@ngneat/input-mask';

/**
 * Parse number from masked number string.
 * If mask is not provided then simple Number() conversion will be used.
 * @param input Input masked number.
 * @param mask Mask.
 * @returns Parsed number or `0` if number can't be parsed.
 */
export function parseMaskedNumber(input: string, mask?: InputmaskOptions<unknown> | null): number {
  if (mask) {
    return Number(Inputmask.unmask(input, mask));
  }
  const number = Number(input);
  return isNaN(number) ? 0 : number;
}
