import { DestroyRef, inject, Injectable, Signal } from '@angular/core';
import { AbstractControl, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { first, map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { EstimateSettingsLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/estimate-settings';
import { EstimateSettingsStorageService } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/estimate-settings-storage.service';
import { RawFormValues } from '@dartsales/common/core/utils/types/form';
import { listenFormRawValueChanges } from '@dartsales/common/core/utils/rxjs/listen-control-changes';
import { DEFAULT_ESTIMATE_SETTINGS, DEFAULT_MODULE_SETTINGS, DEFAULT_POINT_LIST_EXPAND_STATE } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-estimate-settings';
import { DEFAULT_SYSTEM_TAB_COLUMNS, DEFAULT_PART_TAB_COLUMNS, DEFAULT_PROJECT_TAB_COLUMNS } from '@dartsales/common/core/services/local-storage/estimate-settings-storage/default-catalog-tabs-settings';
import { PaletteSharedFilters } from '@dartsales/common/core/models/filters/palette-shared-filters';
import { PalettePartFilters } from '@dartsales/common/core/models/filters/palette-part-filters';
import { PaletteSystemFilters } from '@dartsales/common/core/models/filters/palette-system-filters';
import { CatalogTab, PointListLocalStorage } from '@dartsales/common/core/models/estimate/local-storage/point-list-local-storage';
import { ResizableColumnsWidth } from '@dartsales/common/core/utils/catalog-table/catalog-table-columns-resize-manager';

import { EstimateSettingsFormControls, ExpensesModuleSettingsFormControls, LaborModuleSettingsFormControls, MaterialModuleSettingsFormControls, PointListSettingsFormControls, ModuleSettingsFormControls, ModuleUnitsSettingsFormControls, CatalogTabSettingsFormControls, PointListCatalogSettingsFormControls } from './forms/estimate-settings-form';
import { injectEstimateService } from './estimate.service';

/** Local storage manager service. */
@Injectable()
export class LocalStorageManagerService {
  private readonly fb = inject(NonNullableFormBuilder);

  private readonly destroyRef = inject(DestroyRef);

  private readonly estimateStorageService = inject(EstimateSettingsStorageService);

  private readonly estimateService = injectEstimateService();

  private readonly form = this.createStorageManagerForm();

  public constructor() {
    this.estimateService.estimateSettings$.pipe(
      first(),
      tap(settings => this.form.patchValue(settings)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    listenFormRawValueChanges(this.form, { skipInitial: true, debounceTime: 1000 }).pipe(
      withLatestFrom(this.estimateService.estimateId$),
      switchMap(([settings, estimateId]) => this.saveToStorage(estimateId, settings)),
      takeUntilDestroyed(this.destroyRef),
    )
      .subscribe();
  }

  /**
   * Get settings. We use this to avoid change detection issues.
   * @param selector Selector.
   */
  public getSettings<TControl extends AbstractControl>(
    selector: (form: FormGroup<EstimateSettingsFormControls>) => TControl,
  ): StorageSettingValue<TControl> {
    const control = selector(this.form);
    const valueChanges$ = control.valueChanges.pipe(
      map(() => control.getRawValue()),
    );

    return {
      control,
      value: toSignal(valueChanges$, { initialValue: control.value }),
      set(value) {
        control.patchValue(value);
      },
    };
  }

  private saveToStorage(estimateId: EstimateId, value: RawFormValues<EstimateSettingsFormControls>): Observable<void> {
    const data = new EstimateSettingsLocalStorage({
      meta: {
        timestamp: new Date().getTime(),
      },
      modules: value,
      pointList: value.pointList,
    });
    return this.estimateStorageService.save(estimateId, data);
  }

  private createStorageManagerForm(): FormGroup<EstimateSettingsFormControls> {
    return this.fb.group<EstimateSettingsFormControls>({
      subcontractor: this.fb.group({
        isExpandCalculator: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.subcontractor.isExpandCalculator),
        lumpSumPricing: this.createModuleSettingsForm(),
        unitPricing: this.createModuleSettingsForm(),
      }),
      custom: this.createModuleSettingsForm(),
      expenses: this.fb.group<ExpensesModuleSettingsFormControls>({
        calculationDataSection: this.fb.group({
          isExpanded: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.expenses.calculationDataSection.isExpanded),
          isExpandResourceSection:
            this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.expenses.calculationDataSection.isExpandLaborRolesSection),
          isExpandLaborRolesSection:
            this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.expenses.calculationDataSection.isExpandLaborRolesSection),
        }),
        summarySection: this.fb.group({
          isExpanded: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.expenses.summarySection.isExpanded),
          table: this.createModuleSettingsForm(),
        }),
      }),
      labor: this.fb.group<LaborModuleSettingsFormControls>({
        ...this.createModuleSettingsForm().controls,
        isExpandRates: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.labor.isExpandRates),
        collapsedRowTypes: this.fb.control([]),
        collapsibleRowData: this.fb.control([]),
      }),
      material: this.fb.group<MaterialModuleSettingsFormControls>({
        ...this.createModuleSettingsForm().controls,
        isExpandWFS: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.material.isExpandWFS),
        isExpandModel: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.modules.material.isExpandModel),
        collapsedRowTypes: this.fb.control([]),
        collapsibleRowData: this.fb.control([]),
      }),
      pointList: this.createStoragePointListForm(),
    });
  }

  private createStoragePointListForm(): FormGroup<PointListSettingsFormControls> {
    return this.fb.group<PointListSettingsFormControls>({
      activeTabId: this.fb.control<PointListLocalStorage['activeTabId']>(null),
      collapseRowsByTabs: this.fb.control({}),
      columnsWidthByTabs: this.fb.control({}),
      expandColumns: this.fb.control(DEFAULT_POINT_LIST_EXPAND_STATE),
      catalog: this.fb.group<PointListCatalogSettingsFormControls>({
        width: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.width),
        isExpanded: this.fb.control(DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.isExpanded),
        systemTab: this.createCatalogTabSettingsForm<PaletteSystemFilters>(
          DEFAULT_SYSTEM_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.systemTab,
        ),
        partTab: this.createCatalogTabSettingsForm<PalettePartFilters>(
          DEFAULT_PART_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.partTab,
        ),
        projectTab: this.createCatalogTabSettingsForm(
          DEFAULT_PROJECT_TAB_COLUMNS,
          DEFAULT_ESTIMATE_SETTINGS.pointList.catalog.projectTab,
        ),
      }),
    });
  }

  private createCatalogTabSettingsForm<TFilter extends PaletteSharedFilters>(
    defaultColumnsWidth: ResizableColumnsWidth, defaultTab: CatalogTab<TFilter>,
  ): FormGroup<CatalogTabSettingsFormControls<TFilter>> {
    return this.fb.group<CatalogTabSettingsFormControls<TFilter>>({
      columnsWidth: this.fb.control(defaultColumnsWidth),
      filterParams: this.fb.group({
        filter: this.fb.control(defaultTab.filterParams.filter),
        sort: this.fb.control(defaultTab.filterParams.sort),
      }),
    });
  }

  private createModuleSettingsForm(): FormGroup<ModuleSettingsFormControls> {
    return this.fb.group<ModuleSettingsFormControls>({
      isExpandedEscalation: this.fb.control(DEFAULT_MODULE_SETTINGS.isExpandedEscalation),
      isExpandedPricing: this.fb.control(DEFAULT_MODULE_SETTINGS.isExpandedPricing),
      units: this.createModuleUnitsSettingsForm(),
    });
  }

  private createModuleUnitsSettingsForm(): FormGroup<ModuleUnitsSettingsFormControls> {
    return this.fb.group<ModuleUnitsSettingsFormControls>({
      contingencyUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.contingencyUnits),
      escalationUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.escalationUnits),
      dcUnits: this.fb.control(DEFAULT_MODULE_SETTINGS.units.dcUnits),
      marginParams: this.fb.control(DEFAULT_MODULE_SETTINGS.units.marginParams),
    });
  }
}

type StorageSettingValue<TControl extends AbstractControl> = {

  /** Value. */
  readonly value: Signal<ReturnType<TControl['getRawValue']>>;

  /** Set value. */
  set(value: TControl['value']): void;

  /** Control. */
  readonly control: TControl;
};
