<mat-form-field class="no-label">
  <input
    matInput
    type="text"
    placeholder="Enter text"
    [formControl]="formControl"
  />
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
