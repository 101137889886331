import { PortType } from '@dartsales/common/core/enums/port-type';
import { PortCategoryType } from '@dartsales/common/core/enums/port-category-type';

import { ConfirmedOverridable } from '../../confirmed-overridable';

import { OverridablePartPortProperty, PartPortProperty } from './estimate-part-port-property';

/** Abstract estimate part port. */
abstract class AbstractEstimatePartPort {

  /** Custom ID (UUID). Only custom ports can have it, in other cases value is 'null'. */
  public readonly customId: string | null;

  /** Type. */
  public readonly type: PortType;

  /** Category type. */
  public readonly categoryType: PortCategoryType;

  /** Name. */
  public readonly name: string;

  /** Quantity. */
  public abstract readonly quantity: ConfirmedOverridable<number> | number;

  /** Properties. */
  public abstract readonly properties: readonly OverridablePartPortProperty[] | readonly PartPortProperty[];

  public constructor(data: AbstractEstimatePartPortInitArgs) {
    this.customId = data.customId;
    this.type = data.type;
    this.categoryType = data.categoryType;
    this.name = data.name;
  }
}

type AbstractEstimatePartPortInitArgs = AbstractEstimatePartPort;

/** Overridable estimate part port information. */
export class OverridableEstimatePartPort extends AbstractEstimatePartPort {

  /** All names. */
  public readonly allNames: readonly string[];

  /** @inheritdoc */
  public readonly quantity: ConfirmedOverridable<number>;

  /** @inheritdoc */
  public readonly properties: readonly OverridablePartPortProperty[];

  /** If 'true' this port should be counted as Binary Input even if it has different category and port type. */
  public readonly displayAsBinaryInput: boolean;

  public constructor(data: OverridableEstimatePartPortInitArgs) {
    super(data);
    this.allNames = data.allNames;
    this.quantity = data.quantity;
    this.properties = data.properties;
    this.displayAsBinaryInput = data.displayAsBinaryInput;
  }
}

type OverridableEstimatePartPortInitArgs = OverridableEstimatePartPort;

/** Estimate part port information. */
export class EstimatePartPort extends AbstractEstimatePartPort {

  /** @inheritdoc */
  public readonly quantity: number;

  /** @inheritdoc */
  public readonly properties: readonly PartPortProperty[];

  public constructor(data: EstimatePartPortInitArgs) {
    super(data);
    this.quantity = data.quantity;
    this.properties = data.properties;
  }
}

type EstimatePartPortInitArgs = EstimatePartPort;
