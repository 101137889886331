import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';
import { PaletteSystem } from '@dartsales/common/core/models/components/palette/palette-system';

import { PointsListPart } from '../part/points-list-part';
import { PointsListPartGroup } from '../part-group/points-list-part-group';
import { PointsListSystemGroup } from '../system-group/points-list-system-group';

import { PointsListSystemDetails } from './points-list-system-details';
import { PointsListSystemProperties } from './points-list-system-properties';

/** Points list system. */
export class PointsListSystem {
  /** System UUID. */
  public readonly id: string;

  /** System group UUID. */
  public readonly systemGroupId: PointsListSystemGroup['id'];

  /** Palette component UUID ('null' if system was not created from palette). */
  public readonly paletteComponentId: PaletteSystem['id'] | null;

  /** Order. */
  public readonly order: number;

  /** Part groups. */
  public readonly partGroups: readonly PointsListPartGroup[];

  /** System details. */
  public readonly data: PointsListSystemDetails;

  /** System properties. */
  public readonly properties: PointsListSystemProperties;

  public constructor(data: PointsListSystemInitArgs) {
    this.id = data.id;
    this.systemGroupId = data.systemGroupId;
    this.paletteComponentId = data.paletteComponentId;
    this.order = data.order;
    this.partGroups = data.partGroups;
    this.data = data.data;
    this.properties = data.properties;
  }

  /** Get system parts. */
  public getParts(): PointsListPart[] {
    return this.partGroups.flatMap(group => group.parts);
  }
}

type PointsListSystemInitArgs = StrictOmit<PointsListSystem, 'getParts'>;
