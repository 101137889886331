import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { PortCategoryType } from '../../../enums/port-category-type';
import { PortType } from '../../../enums/port-type';

/** Port type with category. */
export class PortTypeWithCategory {

  /** Type. */
  public readonly type: PortType;

  /** Category type. */
  public readonly categoryType: PortCategoryType;

  public constructor(data: PortTypeWithCategoryInitArgs) {
    this.type = data.type;
    this.categoryType = data.categoryType;
  }

  /**
   * Is item type and category equal.
   * @param item Item.
   */
  public equals(item: PortTypeWithCategory): boolean {
    return item.categoryType === this.categoryType && item.type === this.type;
  }
}

type PortTypeWithCategoryInitArgs = StrictOmit<PortTypeWithCategory, 'equals'>;
