import { BasePricing, BasePricingInitArgs } from '../../../pricing/base-pricing';
import { ModuleLockablePercentsStatus } from '../../module-lockable-percents';

/** Subcontractor Unit Pricing Summary. */
export class SubcontractorModulePricingSummary extends BasePricing {

  /** Number of systems in selected contractors. */
  public readonly quantity: number;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(initArgs: SubcontractorUnitPricingSummaryInitArgs) {
    super(initArgs);
    this.quantity = initArgs.quantity;
    this.lockablePercentsStatus = initArgs.lockablePercentsStatus;
  }
}

export type SubcontractorUnitPricingSummaryInitArgs = BasePricingInitArgs &
  Pick<SubcontractorModulePricingSummary, 'quantity' | 'lockablePercentsStatus'>;
