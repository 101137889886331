import { inject, Injectable } from '@angular/core';

import { PointsListMaterialGroupTotals, PointsListMaterialTotals } from '@dartsales/common/core/models/estimate/modules/points-list/system-totals/points-list-material-totals';

import { IMapper } from '../../../mappers';
import { PointsListMaterialTotalsDto } from '../../../dto/estimate/modules/points-list/system-totals/points-list-material-totals.dto';
import { OverridableMapper } from '../../../overridable.mapper';

/** PointsListMaterialTotals entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListMaterialTotalsMapper implements IMapper<PointsListMaterialTotalsDto, PointsListMaterialTotals> {

  private readonly overridableMapper = inject(OverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListMaterialTotalsDto): PointsListMaterialTotals {
    return new PointsListMaterialTotals({
      directCost: this.overridableMapper.fromDto(dto.directCost),
      quantity: this.overridableMapper.fromDto(dto.quantity),
      items: dto.materialGroupsTotals.map(item => new PointsListMaterialGroupTotals({
        id: item.id,
        name: item.name,
        directCost: this.overridableMapper.fromDto(item.directCost),
        quantity: this.overridableMapper.fromDto(item.quantity),
      })),
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListMaterialTotals): PointsListMaterialTotalsDto {
    return {
      directCost: this.overridableMapper.toDto(data.directCost),
      quantity: this.overridableMapper.toDto(data.quantity),
      materialGroupsTotals: data.items.map(item => ({
        id: item.id,
        name: item.name,
        directCost: this.overridableMapper.toDto(item.directCost),
        quantity: this.overridableMapper.toDto(item.quantity),
      })),
    };
  }
}
