import { ModuleProperties, ModulePropertiesInitArgs } from '../module-properties';

import { SubcontractorModulePricingSummary } from './subcontractor-module-tables-summary';

/** Subcontractors module properties. */
export class SubcontractorModuleProperties extends ModuleProperties {

  /** Unit pricing total. */
  public readonly unitPricingTotal: SubcontractorModulePricingSummary;

  /** Lump sum pricing total. */
  public readonly lumpSumPricingTotal: SubcontractorModulePricingSummary;

  /** Common cost of selected unit pricing and lump sum pricing items. */
  public readonly grandTotalCost: number;

  /** Common sell price of selected unit pricing and lump sum pricing items.  */
  public readonly grandTotalSellPrice: number;

  /** Selected subcontractors count. */
  public readonly selectedSubcontractorsCount: number;

  public constructor(data: SubcontractorModulePropertiesInitArgs) {
    super(data);
    this.unitPricingTotal = data.unitPricingTotal;
    this.lumpSumPricingTotal = data.lumpSumPricingTotal;
    this.grandTotalCost = data.grandTotalCost;
    this.grandTotalSellPrice = data.grandTotalSellPrice;
    this.selectedSubcontractorsCount = data.selectedSubcontractorsCount;
  }
}

type SubcontractorModulePropertiesInitArgs = ModulePropertiesInitArgs & Pick<SubcontractorModuleProperties,
  'unitPricingTotal' | 'lumpSumPricingTotal' | 'grandTotalCost' | 'grandTotalSellPrice' | 'selectedSubcontractorsCount'>;
