import { Injectable } from '@angular/core';

import { PointsListPartGroupProperties } from '@dartsales/common/core/models/estimate/modules/points-list/part-group/points-list-part-group-properties';

import { IMapper } from '../../../mappers';
import { PointsListPartGroupPropertiesDto } from '../../../dto/estimate/modules/points-list/part/points-list-part-group-properties.dto';

/** Points list part group properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListPartGroupPropertiesMapper implements IMapper<PointsListPartGroupPropertiesDto, PointsListPartGroupProperties> {
  /** @inheritdoc */
  public fromDto(dto: PointsListPartGroupPropertiesDto): PointsListPartGroupProperties {
    return new PointsListPartGroupProperties({
      partsCount: dto.partsCount,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListPartGroupProperties): PointsListPartGroupPropertiesDto {
    return {
      partsCount: data.partsCount,
    };
  }
}
