import { MaterialPricingNoOverride } from '../material-pricing-no-override';

import { AbstractMaterialItem } from './abstract-material-item';

/** Non-standard material item. */
export class NonStandardMaterialItem extends AbstractMaterialItem {

  /** Part ID. */
  public readonly partId: string;

  /** Item pricing. */
  public readonly pricing: MaterialPricingNoOverride;

  public constructor(data: NonStandardMaterialItemInitArgs) {
    super(data);
    this.partId = data.partId;
    this.pricing = data.pricing;
  }
}

type NonStandardMaterialItemInitArgs = NonStandardMaterialItem;
