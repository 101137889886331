import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Organization avatar component. */
@Component({
  selector: 'dartsalesw-organization-avatar',
  templateUrl: './organization-avatar.component.html',
  styleUrls: ['./organization-avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationAvatarComponent {

  /** Avatar url. */
  @Input()
  public avatarUrl: string | null = null;
}
