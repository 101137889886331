import { Injectable, inject } from '@angular/core';

import { SubcontractorModuleProperties } from '@dartsales/common/core/models/estimate/modules/subcontractor/subcontractor-module-properties';
import { SubcontractorModulePricingSummary } from '@dartsales/common/core/models/estimate/modules/subcontractor/subcontractor-module-tables-summary';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';
import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';

import { IMapper } from '../../../mappers';
import { SubcontractorModulePropertiesDto } from '../../../dto/estimate/modules/subcontractor/subcontractor-module-data.dto';
import { ModulePropertiesMapper } from '../module-properties.mapper';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { ModuleLockablePercentsStatusMapper } from '../../module-lockable-percents-status.mapper';
import { OverridableDto } from '../../../dto/overridable.dto';
import { AmountPercentValueDto } from '../../../dto/estimate/amount-percent-value.dto';

/** Subcontractor module properties mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubcontractorModulePropertiesMapper implements IMapper<SubcontractorModulePropertiesDto, SubcontractorModuleProperties> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(ModuleLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: SubcontractorModulePropertiesDto): SubcontractorModuleProperties {
    const lockablePercentsStatus = new ModuleLockablePercentsStatus({
      isContingencyLocked: dto.unitPricingTotal.lockablePercentsStatus.isContingencyLocked &&
        dto.lumpSumPricingTotal.lockablePercentsStatus.isContingencyLocked,
      isEscalationLocked: dto.unitPricingTotal.lockablePercentsStatus.isEscalationLocked &&
        dto.lumpSumPricingTotal.lockablePercentsStatus.isEscalationLocked,
      isGrossMarginLocked: dto.unitPricingTotal.lockablePercentsStatus.isGrossMarginLocked &&
        dto.lumpSumPricingTotal.lockablePercentsStatus.isGrossMarginLocked,
    });
    return new SubcontractorModuleProperties({
      ...this.propertiesMapper.fromDto({
        ...dto,
        lockablePercentsStatus,
        contingency: this.getValue({
          moduleProperties: dto,
          moduleValue: dto.contingency,
          unitPricingValue: dto.unitPricingTotal.contingency,
          lumpSumPricingValue: dto.lumpSumPricingTotal.contingency,
          isLockedValue: lockablePercentsStatus.isContingencyLocked,
        }),
        escalation: this.getValue({
          moduleProperties: dto,
          moduleValue: dto.escalation,
          unitPricingValue: dto.unitPricingTotal.escalation,
          lumpSumPricingValue: dto.lumpSumPricingTotal.escalation,
          isLockedValue: lockablePercentsStatus.isEscalationLocked,
        }),
        grossMargin: this.getValue({
          moduleProperties: dto,
          moduleValue: dto.grossMargin,
          unitPricingValue: dto.unitPricingTotal.grossMargin,
          lumpSumPricingValue: dto.lumpSumPricingTotal.grossMargin,
          isLockedValue: lockablePercentsStatus.isGrossMarginLocked,
        }),
        markup: this.getValue({
          moduleProperties: dto,
          moduleValue: dto.markup,
          unitPricingValue: dto.unitPricingTotal.markup,
          lumpSumPricingValue: dto.lumpSumPricingTotal.markup,
          isLockedValue: lockablePercentsStatus.isGrossMarginLocked,
        }),
      }),
      unitPricingTotal: new SubcontractorModulePricingSummary({
        ...this.basePricingMapper.fromDto(dto.unitPricingTotal),
        quantity: dto.unitPricingTotal.quantity,
        lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.unitPricingTotal.lockablePercentsStatus),
      }),
      lumpSumPricingTotal: new SubcontractorModulePricingSummary({
        ...this.basePricingMapper.fromDto(dto.lumpSumPricingTotal),
        quantity: dto.lumpSumPricingTotal.quantity,
        lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lumpSumPricingTotal.lockablePercentsStatus),
      }),
      grandTotalCost: dto.grandTotalCost,
      grandTotalSellPrice: dto.grandTotalSellPrice,
      selectedSubcontractorsCount: dto.quantity,
    });
  }

  /** @inheritdoc */
  public toDto(data: SubcontractorModuleProperties): SubcontractorModulePropertiesDto {
    return {
      ...this.propertiesMapper.toDto(data),
      unitPricingTotal: {
        ...this.basePricingMapper.toDto(data.unitPricingTotal),
        quantity: data.unitPricingTotal.quantity,
        lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.unitPricingTotal.lockablePercentsStatus),
      },
      lumpSumPricingTotal: {
        ...this.basePricingMapper.toDto(data.lumpSumPricingTotal),
        quantity: data.lumpSumPricingTotal.quantity,
        lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lumpSumPricingTotal.lockablePercentsStatus),
      },
      grandTotalCost: data.grandTotalCost,
      grandTotalSellPrice: data.grandTotalSellPrice,
      quantity: data.selectedSubcontractorsCount,
    };
  }

  private getValue(args: {
    readonly moduleProperties: SubcontractorModulePropertiesDto;
    readonly moduleValue: OverridableDto<AmountPercentValueDto>;
    readonly unitPricingValue: AmountPercent;
    readonly lumpSumPricingValue: AmountPercent;
    readonly isLockedValue: boolean;
  }): OverridableDto<AmountPercent> {
    const emptyOverridableAmountPercent: OverridableDto<AmountPercentValueDto> = {
      value: new AmountPercent(),
      override: null,
    };
    const { moduleProperties, moduleValue, unitPricingValue, lumpSumPricingValue, isLockedValue } = args;
    const isEqualSubcontractorSectionsValues = unitPricingValue.percent === lumpSumPricingValue.percent;
    const isEmptyModule = moduleProperties.directCost.value === 0 && moduleValue.override == null;

    if (isEmptyModule && !isLockedValue || (isEmptyModule && isLockedValue && !isEqualSubcontractorSectionsValues)) {
      return emptyOverridableAmountPercent;
    }

    if (isEmptyModule && isLockedValue && isEqualSubcontractorSectionsValues) {
      return { value: unitPricingValue, override: null };
    }

    return moduleValue;
  }
}
