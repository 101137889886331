import { Directive, Input } from '@angular/core';

/** Cell directive. */
@Directive({
  host: {
    class: 'dartsalesc-cell',
  },
})
export abstract class CellDirective<T> {

  /** Value. */
  @Input()
  public value: T | null = null;
}
