import { Injectable, inject } from '@angular/core';

import { PointsListLaborRoleRate } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-labor-role-rate';
import { LaborRoleInternalRates } from '@dartsales/common/core/models/resources/labor-role-internal-rates';

import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { IMapper } from '../../../mappers';
import { PointsListLaborRoleRateDto, PointsListLaborRoleRatesDto } from '../../../dto/estimate/modules/points-list/system/points-list-labor-role-rate.dto';

/** Points list labor role rate mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListLaborRoleRateMapper implements IMapper<PointsListLaborRoleRateDto, PointsListLaborRoleRate> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListLaborRoleRateDto): PointsListLaborRoleRate {
    const rates = this.confirmedOverridableMapper.fromDtoWithMapper(dto.rates, {
      fromDto: ratesDto => new LaborRoleInternalRates({
        rate: ratesDto.rate,
        rateOvertimeOne: ratesDto.rateOvertimeOne,
        rateOvertimeTwo: ratesDto.rateOvertimeTwo,
      }),
    });
    return new PointsListLaborRoleRate({
      rates,
      rateType: dto.rateType,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListLaborRoleRate): PointsListLaborRoleRateDto {
    const rates = this.confirmedOverridableMapper.toDtoWithMapper(data.rates, {
      toDto(ratesData): PointsListLaborRoleRatesDto {
        return {
          rate: ratesData.rate,
          rateOvertimeOne: ratesData.rateOvertimeOne,
          rateOvertimeTwo: ratesData.rateOvertimeTwo,
        };
      },
    });
    return {
      rates,
      rateType: data.rateType,
    };
  }
}
