import { MaterialModuleLockablePercentsStatus } from '../properties/material-lockable-percents';

/** Abstract material item. */
export abstract class AbstractMaterialItem {
  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Supplier. */
  public readonly supplier: string;

  /** Model number. */
  public readonly modelNumber: string;

  /** Tags list. */
  public readonly tags: readonly string[];

  /** Manufacturer. */
  public readonly manufacturer: string;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: MaterialModuleLockablePercentsStatus;

  public constructor(data: AbstractMaterialItemInitArgs) {
    this.name = data.name;
    this.description = data.description;
    this.supplier = data.supplier;
    this.modelNumber = data.modelNumber;
    this.tags = data.tags;
    this.manufacturer = data.manufacturer;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }
}

type AbstractMaterialItemInitArgs = AbstractMaterialItem;
