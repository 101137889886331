import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DATE_TIME_FORMAT } from '@dartsales/common/core/utils/constants';

/** Format date. */
@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {

  public constructor(
    private readonly datePipe: DatePipe,
  ) { }

  /**
   * Transform date to format.
   * @param value Date value to transform.
   */
  public transform(value: Date | null): string {
    if (value == null) {
      return '';
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    switch (value.toDateString()) {
      case today.toDateString():
        return 'Today';
      case yesterday.toDateString():
        return 'Yesterday';
      default:
        return this.datePipe.transform(value, DATE_TIME_FORMAT) ?? '';
    }
  }
}
