import { inject, Injectable } from '@angular/core';

import { PalettePartFilters } from '@dartsales/common/core/models/filters/palette-part-filters';

import { IMapperToDto } from '../mappers';
import { PalettePartFiltersDto } from '../dto/filters/palette-part-filters.dto';

import { PaletteSharedFiltersMapper } from './palette-shared-filters.mapper';

/** Mapper for palette part filters. */
@Injectable({ providedIn: 'root' })
export class PalettePartFiltersMapper implements IMapperToDto<PalettePartFiltersDto, PalettePartFilters> {

  private readonly paletteSharedFiltersMapper = inject(PaletteSharedFiltersMapper);

  /** @inheritdoc */
  public toDto(data: PalettePartFilters): PalettePartFiltersDto {
    return {
      ...this.paletteSharedFiltersMapper.toDto(data),
      'ManufacturedBy': data.manufacturers,
      'ModelContains': data.modelNumber,

      // We only have two possible options, so we can assume that if the length is 0 or 2, then we have all options selected.
      'ComponentType': data.componentType?.length === 1 ? data.componentType[0] : undefined,
      'ExcludeWithEmptyProperties': data.emptyFieldsToExcludeParts,
      'CostRange.MinCost': data.unitCost?.start,
      'CostRange.MaxCost': data.unitCost?.end,
    };
  }
}
