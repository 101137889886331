import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';

import { OptionSelect } from '@dartsales/common/core/models/option-select';
import { BaseOrganization } from '@dartsales/common/core/models/organization';
import { PaletteComponentsApiService } from '@dartsales/common/core/services/api/palette-components-api.service';
import { toggleExecutionState } from '@dartsales/common/core/utils/rxjs/toggle-execution-state';
import { PaletteSupplier } from '@dartsales/common/core/models/components/palette/palette-supplier';
import { SortDirection } from '@dartsales/common/core/enums/sort-direction';

/** Supplier filters. */
@Component({
  selector: 'dartsalesw-supplier-filters',
  templateUrl: './supplier-filters.component.html',
  styleUrls: ['./supplier-filters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierFiltersComponent {

  /** Current sort direction. */
  @Input()
  public sortDirection: SortDirection | null = SortDirection.ASC;

  /** Organization ID. */
  @Input({ required: true })
  public set organizationId(organizationId: BaseOrganization['id'] | null) {
    if (organizationId != null) {
      this.organizationId$.next(organizationId);
    }
  }

  /** Whether this filter is for system component. */
  @Input()
  public isSystemComponent = false;

  /** Active suppliers. */
  @Input()
  public activeSuppliers: readonly string[] = [];

  /** Organization ID. */
  private readonly organizationId$ = new ReplaySubject<BaseOrganization['id']>(1);

  /** Suppliers change. */
  @Output()
  public readonly suppliersChange = new EventEmitter<readonly string[]>();

  /** Sort direction change. */
  @Output()
  public readonly sortDirectionChange = new EventEmitter<SortDirection>();

  private readonly paletteComponentsApiService = inject(PaletteComponentsApiService);

  /** Is loading. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  private readonly search$ = new BehaviorSubject('');

  /** Options. */
  protected readonly options$ = this.createOptionsStream();

  /** Current sort direction. */
  protected readonly sortDirections = SortDirection;

  /** Whether sort field is active. */
  protected get isActive(): boolean {
    return this.sortDirection != null;
  }

  /**
   * Sort direction change handler.
   * @param direction Sort direction.
   */
  public onSortDirectionChange(direction: SortDirection): void {
    this.sortDirectionChange.emit(direction);
  }

  /**
   * Options change handler.
   * @param selectedValues Selected values.
   */
  protected onSelectedOptionsChange(selectedValues: readonly PaletteSupplier['name'][]): void {
    this.suppliersChange.emit([...selectedValues]);
  }

  /**
   * Search change handler.
   * @param query Search query.
   */
  protected onOptionsSearchChange(query: string): void {
    this.search$.next(query);
  }

  private createSuppliersStream(): Observable<PaletteSupplier[]> {
    return combineLatest([
      this.organizationId$,
      this.search$,
    ]).pipe(
      switchMap(([organizationId, name]) => this.paletteComponentsApiService.getSuppliersPalette(
        organizationId,
        { name, isSystemComponent: this.isSystemComponent },
      ).pipe(
        toggleExecutionState(this.isLoading$),
      )),
    );
  }

  private createOptionsStream(): Observable<OptionSelect<PaletteSupplier['name']>[]> {
    const suppliers$ = this.createSuppliersStream();
    return suppliers$.pipe(
      map(
        suppliers => suppliers
          .map<OptionSelect<PaletteSupplier['name']>>(supplier => ({
            value: supplier.name,
            label: supplier.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
