import { Injectable, inject } from '@angular/core';

import { AbstractCreateLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/create-labor-role-task';
import { BatchEditLaborRoleTask, EditCustomLaborRoleTask, EditDefaultLaborRoleTask, EditServiceLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-task';
import { AbstractLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';

import { BatchEditLaborRoleTaskDto, CreateLaborRoleTaskDto, DefaultLaborRoleTaskDto, ServiceLaborRoleTaskDto, LaborRoleTaskDto } from '../../../dto/estimate/modules/labor/labor-role-task.dto';
import { IMapper } from '../../../mappers';

import { LaborRoleCustomTaskMapper } from './labor-role-custom-task.mapper';
import { LaborRoleDefaultTaskMapper } from './labor-role-default-task.mapper';
import { LaborRoleServiceTaskMapper } from './labor-role-service-task.mapper';
import { CommonLaborRoleTaskPropertiesMapper } from './common-labor-role-task-properties.mapper';
import { LaborRoleHoursMapper } from './labor-role-hours.mapper';

/** Labor role task entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleTaskMapper implements IMapper<LaborRoleTaskDto, AbstractLaborRoleTask> {

  private readonly laborRoleHoursMapper = inject(LaborRoleHoursMapper);

  private readonly laborRoleCustomTaskMapper = inject(LaborRoleCustomTaskMapper);

  private readonly laborRoleDefaultTaskMapper = inject(LaborRoleDefaultTaskMapper);

  private readonly laborRoleServiceTaskMapper = inject(LaborRoleServiceTaskMapper);

  private readonly commonLaborRoleTaskPropertiesMapper = inject(CommonLaborRoleTaskPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborRoleTaskDto): AbstractLaborRoleTask {
    if (this.checkIsDefaultTaskDto(dto)) {
      return this.laborRoleDefaultTaskMapper.fromDto(dto);
    }

    if (this.checkIsServiceTaskDto(dto)) {
      return this.laborRoleServiceTaskMapper.fromDto(dto);
    }

    return this.laborRoleCustomTaskMapper.fromDto({
      ...dto,
      servicePercent: null,
      hours: dto.hours ?? 0,
      hoursOvertimeOne: dto.hoursOvertimeOne ?? 0,
      hoursOvertimeTwo: dto.hoursOvertimeTwo ?? 0,
    });
  }

  /** @inheritdoc */
  public toDto(data: AbstractLaborRoleTask): LaborRoleTaskDto {
    return {
      ...this.commonLaborRoleTaskPropertiesMapper.toDto(data),
      originalTaskId: data.originalTaskId,
      servicePercent: data.servicePercent,
      hours: data.hours?.regular ?? null,
      hoursOvertimeOne: data.hours?.overtimeOne ?? null,
      hoursOvertimeTwo: data.hours?.overtimeTwo ?? null,
      isCustom: data.isCustom,
    };
  }

  /**
   * Map to create DTO.
   * @param data Data.
   */
  public toCreateDto(data: AbstractCreateLaborRoleTask): CreateLaborRoleTaskDto {
    const hours = data.hours != null ?
      this.laborRoleHoursMapper.toDto(data.hours) : null;
    return {
      originalTaskId: data.originalTaskId,
      name: data.name,
      code: data.code,
      servicePercent: data.servicePercent,
      hours: hours?.hours ?? null,
      hoursOvertimeOne: hours?.hoursOvertimeOne ?? null,
      hoursOvertimeTwo: hours?.hoursOvertimeTwo ?? null,
    };
  }

  /**
   * Map batch edit task to DTO.
   * @param data Batch edit task.
   */
  public toBatchEditDto(data: BatchEditLaborRoleTask): BatchEditLaborRoleTaskDto {
    if (data.value instanceof EditCustomLaborRoleTask) {
      return {
        taskId: data.id,
        task: this.laborRoleCustomTaskMapper.toEditDto(data.value),
      };
    }

    if (data.value instanceof EditDefaultLaborRoleTask) {
      return {
        taskId: data.id,
        task: this.laborRoleDefaultTaskMapper.toEditDto(data.value),
      };
    }

    if (data.value instanceof EditServiceLaborRoleTask) {
      return {
        taskId: data.id,
        task: this.laborRoleServiceTaskMapper.toEditDto(data.value),
      };
    }

    return {
      taskId: data.id,
      task: {
        ...this.commonLaborRoleTaskPropertiesMapper.toOverridablePropertiesDto(data.value),
        hours: data.value.hours?.regular ?? undefined,
        hoursOvertimeOne: data.value.hours?.overtimeOne ?? undefined,
        hoursOvertimeTwo: data.value.hours?.overtimeTwo ?? undefined,
      },
    };
  }

  private checkIsServiceTaskDto(task: LaborRoleTaskDto): task is ServiceLaborRoleTaskDto {
    return task.servicePercent !== null;
  }

  private checkIsDefaultTaskDto(task: LaborRoleTaskDto): task is DefaultLaborRoleTaskDto {
    return task.servicePercent === null && !task.isCustom;
  }
}
