<span class="header-units-label">
  {{ label }}
</span>
<div
  *ngIf="!isReadonly; else readonlyCell"
  class="input-container"
>
  <div
    *ngIf="cellPrefix"
    class="prefix"
  >
    <ng-content select="[dartsalescTableCellPrefix]" />
  </div>
  <input
    #input
    class="input-container__input"
    [class.cell__has-prefix]="cellPrefix"
    [inputMask]="inputMask"
    [formControl]="inputValueControl"
    [readonly]="isLockedValue"
    (blur)="onInputBlur()"
    (keydown.enter)="input.blur(); $event.stopPropagation()"
  />
</div>

<ng-template #readonlyCell>
  <dartsalesw-table-header-readonly-cell
    [label]="label"
    [value]="value"
  />
</ng-template>
