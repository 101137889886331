import { Injectable, inject } from '@angular/core';

import { CustomLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/labor-role-task';
import { EditCustomLaborRoleTask } from '@dartsales/common/core/models/estimate/modules/labor/edit-labor-role-task';

import { IMapperFromDto } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';
import { EditLaborRoleCustomTaskDto, CustomLaborRoleTaskDto } from '../../../dto/estimate/modules/labor/labor-role-task.dto';

import { LaborRoleHoursMapper } from './labor-role-hours.mapper';
import { CommonLaborRoleTaskPropertiesMapper } from './common-labor-role-task-properties.mapper';

/** Labor role custom task entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborRoleCustomTaskMapper implements IMapperFromDto<CustomLaborRoleTaskDto, CustomLaborRoleTask> {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly laborRoleHoursMapper = inject(LaborRoleHoursMapper);

  private readonly commonLaborRoleTaskPropertiesMapper = inject(CommonLaborRoleTaskPropertiesMapper);

  /** @inheritdoc */
  public fromDto(dto: CustomLaborRoleTaskDto): CustomLaborRoleTask {
    return new CustomLaborRoleTask({
      ...this.commonLaborRoleTaskPropertiesMapper.fromDto(dto),
      originalTaskId: dto.originalTaskId,
      hours: this.laborRoleHoursMapper.fromDto(dto),
    });
  }

  /**
   * Map to edit custom task DTO.
   * @param data Data.
   */
  public toEditDto(data: EditCustomLaborRoleTask): EditLaborRoleCustomTaskDto {
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      code: this.confirmedOverridableMapper.toDto(data.code),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      originalLaborRoleId: this.confirmedOverridableMapper.toDto(data.originalLaborRoleId),
      hours: data.hours.regular ?? undefined,
      hoursOvertimeOne: data.hours.overtimeOne ?? undefined,
      hoursOvertimeTwo: data.hours.overtimeTwo ?? undefined,
      originalTaskId: data.originalTaskId,
    };
  }
}
