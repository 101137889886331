import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { PointsListOverridableItemTotal } from '../points-list-overridable-item-total';
import { OverridableLaborTaskHours } from '../overridable-labor-task-hours';

import { PointsListSystemPortsTotal } from './points-list-system-ports-total';

/** Points list system total. */
export class PointsListSystemTotal {
  /** System total. */
  public readonly total: PointsListOverridableItemTotal;

  /** Part labor tasks total hours. */
  public readonly partTasksTotalHours: readonly OverridableLaborTaskHours[];

  /** Ports total. */
  public readonly portsTotal: readonly PointsListSystemPortsTotal[];

  /** Ports total count. */
  public readonly portsTotalCount: number;

  public constructor(data: PointsListSystemTotalInitArgs) {
    this.total = data.total;
    this.partTasksTotalHours = data.partTasksTotalHours;
    this.portsTotal = data.portsTotal;
    this.portsTotalCount = this.calculatePortsTotalCount(data.portsTotal);
  }

  private calculatePortsTotalCount(ports: readonly PointsListSystemPortsTotal[]): number {
    return ports.reduce((acc, port) => acc + port.quantity, 0);
  }

}

type PointsListSystemTotalInitArgs = StrictOmit<PointsListSystemTotal, 'portsTotalCount'>;
