import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';

/** Default digits info. */
const DEFAULT_DIGITS_INFO = '1.0-2';

/** Decimal pipe. */
@Pipe({
  name: 'decimalNumber',
})
export class DecimalNumberPipe implements PipeTransform {

  private readonly decimalPipe = inject(DecimalPipe);

  /**
   * Transforms a number to a percentage.
   * @param value Value.
   * @param digitsInfo Digits info.
   * @param locale Locale.
   */
  public transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    return this.decimalPipe.transform(value, digitsInfo ?? DEFAULT_DIGITS_INFO, locale);
  }

}
