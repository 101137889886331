import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { SimpleValueAccessor } from '@dartsales/common/core/utils/value-accessors/simple-value-accessor';

/** Export format. */
@Component({
  selector: 'dartsalesw-select-format-control',
  templateUrl: './select-format-control.component.html',
  styleUrls: ['./select-format-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => SelectFormatControlComponent)],
})
export class SelectFormatControlComponent extends SimpleValueAccessor<ExportFormat> {

  /** Supported export formats. */
  @Input()
  public supportedExportFormats: readonly ExportFormat[] = ExportFormat.toArray();

  /** Export format. */
  protected readonly exportFormat = ExportFormat;
}
