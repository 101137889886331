import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { BatchEditEntity } from '../../../batch-edit-entity';
import { ConfirmedOverridable } from '../../../confirmed-overridable';
import { LaborRole } from '../../../resources/labor-role';
import { LaborTask } from '../../../resources/labor-task';

import { LaborRoleHoursPricing } from './labor-role-hours-pricing';
import { AbstractLaborRoleTask, LaborRoleHours } from './labor-role-task';

/** Abstract edit labor role task. */
export abstract class AbstractEditLaborRoleTask {
  /** ID of role to which task is attached. */
  public readonly roleId: LaborRoleHoursPricing['id'];

  /** ID of role from resources to which task is attached. */
  public readonly originalLaborRoleId: ConfirmedOverridable<LaborRole['id']>;

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Code. */
  public readonly code: ConfirmedOverridable<string>;

  /** Is role removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  /** Service percent. Null is possible for task from resources or point list. */
  public abstract readonly servicePercent: number | null;

  /** Hours. Null is possible there is a default task. */
  public abstract readonly hours: LaborRoleHours | null;

  public constructor(data: AbstractEditLaborRoleTaskInitArgs) {
    this.roleId = data.roleId;
    this.originalLaborRoleId = data.originalLaborRoleId;
    this.name = data.name;
    this.code = data.code;
    this.isRemoved = data.isRemoved;
  }
}

type AbstractEditLaborRoleTaskInitArgs = StrictOmit<AbstractEditLaborRoleTask, 'hours' | 'servicePercent'>;

/** Edit custom labor role task. */
export class EditCustomLaborRoleTask extends AbstractEditLaborRoleTask {
  /** @inheritdoc */
  public readonly originalTaskId: LaborTask['id'] | null;

  /** @inheritdoc */
  public readonly servicePercent = null;

  /** @inheritdoc */
  public readonly hours: LaborRoleHours;

  public constructor(data: EditCustomLaborRoleTaskInitArgs) {
    super(data);
    this.originalTaskId = data.originalTaskId;
    this.hours = data.hours;
  }
}

type EditCustomLaborRoleTaskInitArgs = StrictOmit<EditCustomLaborRoleTask, 'servicePercent'>;

/** Edit default labor role task. */
export class EditDefaultLaborRoleTask extends AbstractEditLaborRoleTask {

  /** @inheritdoc */
  public readonly servicePercent = null;

  /** @inheritdoc */
  public readonly hours = null;

  public constructor(data: EditDefaultLaborRoleTaskInitArgs) {
    super(data);
  }
}

type EditDefaultLaborRoleTaskInitArgs = StrictOmit<EditDefaultLaborRoleTask, 'hours' | 'servicePercent'>;

/** Edit service labor role task. */
export class EditServiceLaborRoleTask extends AbstractEditLaborRoleTask {

  /** @inheritdoc */
  public readonly servicePercent: number;

  /** @inheritdoc */
  public readonly hours = null;

  public constructor(data: EditServiceLaborRoleTaskInitArgs) {
    super(data);
    this.servicePercent = data.servicePercent;
  }
}

type EditServiceLaborRoleTaskInitArgs = StrictOmit<EditServiceLaborRoleTask, 'hours'>;

/** Batch edit labor role tasks. */
export class BatchEditLaborRoleTask extends BatchEditEntity<AbstractLaborRoleTask['id'], AbstractEditLaborRoleTask> { }
