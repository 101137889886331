import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

/** Success notification data. */
export type SuccessNotificationData = {

  /** Title. */
  readonly title: string;

  /** Message. */
  readonly message: string;

  /** Navigation link after successful action. */
  readonly link?: string;
};

/** Success notification. */
@Component({
  selector: 'dartsalesw-success-notification',
  templateUrl: './success-notification.component.html',
  styleUrls: ['./success-notification.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessNotificationComponent {

  private readonly ref = inject(MatSnackBarRef);

  /** Notification data. */
  protected readonly data = inject<SuccessNotificationData>(MAT_SNACK_BAR_DATA);

  /** Close notification. */
  protected close(): void {
    this.ref.dismissWithAction();
  }
}
