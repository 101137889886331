import { Directive, inject } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

/**
 * Custom implementation of `MatMenuTrigger` from Angular Material.
 * We replace `MatMenuTrigger` with this custom directive to solve issue described here:
 * https://github.com/angular/components/issues/29578. \
 * We didn't have this error message before because Chrome didn't detect it.
 * Such check was added in one of the recent Chrome updates:
 * https://source.chromium.org/chromium/chromium/src/+/62d86ddddc8fad157321e42e2acdb18b6ebba951.
 * For more details see this discussion: https://github.com/mui/material-ui/issues/43106.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matMenuTriggerFor]',
})
export class MatMenuTriggerDirective {

  private readonly matMenuTrigger = inject(MatMenuTrigger);

  public constructor() {
    this.matMenuTrigger.restoreFocus = false;
  }

}
