import { SubcontractorLumpSumPricing } from './lump-sum-pricing/lump-sum-pricing';
import { SubcontractorUnitPricing } from './unit-pricing/unit-pricing';

/** Subcontractor module data. */
export class SubcontractorModuleData {

  /** Lump sum pricing data. */
  public readonly lumpSumPricing: readonly SubcontractorLumpSumPricing[];

  /** Unit pricing data. */
  public readonly unitPricing: readonly SubcontractorUnitPricing[];

  public constructor(data: SubcontractorModuleDataInitArgs) {
    this.lumpSumPricing = data.lumpSumPricing;
    this.unitPricing = data.unitPricing;
  }
}

type SubcontractorModuleDataInitArgs = Pick<SubcontractorModuleData,
  | 'lumpSumPricing'
  | 'unitPricing'
>;
