import { Observable, filter, map, fromEvent, combineLatest, tap, first, switchMap } from 'rxjs';
import { inject } from '@angular/core';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { uuidv4 } from '@dartsales/common/core/utils/generate-uuid';
import { Project } from '@dartsales/common/core/models/project/project';
import { ProjectLayoutService } from '@dartsales/common/core/services/project-layout.service';

import { injectEstimateService } from '../services/estimate.service';

type BroadcastPayload<T> = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Project ID. */
  readonly projectId: Project['id'];

  /** Sender ID. We need it to detect if message is from the same tab. */
  readonly senderId: string;

  /** Payload. */
  readonly payload: T | null;
};

/**
 * Module data broadcast channel.
 * Important: be aware that data in the BroadcastChannel is serialized automatically.
 * From MDN [docs](https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API#sending_a_message):
 * "Data sent to the channel is serialized using the structured clone algorithm.". \
 * It means that class' function properties (methods, getters, setters) will be removed in the class. \
 * TODO (DART-1671): This solution should become generic to sync data in all modules.
 * It's not a final solution, implementation can improved in the future.
 */
export class ModuleDataBroadcastChannel<T> {
  private readonly broadcastChannel: BroadcastChannel;

  private readonly uuid = uuidv4();

  private readonly estimateId$ = injectEstimateService().estimateId$;

  private readonly projectId$ = inject(ProjectLayoutService).projectId$;

  public constructor(private readonly args: ModuleDataBroadcastChannelInitArgs) {
    this.broadcastChannel = new BroadcastChannel(args.name);
  }

  /** Get value from broadcast channel. */
  public get(): Observable<T | null> {
    return combineLatest([
      this.estimateId$,
      this.projectId$,
    ]).pipe(
      switchMap(([estimateId, projectId]) => this.createBroadcastMessages().pipe(
        filter(data => data?.senderId !== this.uuid &&
          projectId === data?.projectId &&
          (this.args.syncBetweenEstimates || data?.estimateId === estimateId)),
        map(data => data?.payload ?? null),
      )),
    );
  }

  /**
   * Post message to broadcast channel.
   * @param payload Payload.
   */
  public postMessage(payload: T | null): Observable<void> {
    return combineLatest([
      this.estimateId$,
      this.projectId$,
    ]).pipe(
      first(),
      tap(([estimateId, projectId]) => {
        const data: BroadcastPayload<T> = {
          estimateId,
          projectId,
          payload,
          senderId: this.uuid,
        };

        this.broadcastChannel.postMessage(data);
      }),
      map(() => undefined),
    );
  }

  private createBroadcastMessages(): Observable<BroadcastPayload<T> | null> {
    return fromEvent<MessageEvent<BroadcastPayload<T> | null>>(this.broadcastChannel, 'message').pipe(
      map(event => event.data),
    );
  }
}

type ModuleDataBroadcastChannelInitArgs = {

  /** Broadcast channel name. Channels with the same name are shared across tabs. */
  readonly name: string;

  /**
   * Sync data between estimates inside a project.
   * It means new values will be emitted if any estimate (base, alternate, change order) inside a project changes.
   */
  readonly syncBetweenEstimates: boolean;
};
