<ng-container
  *ngrxLet="{
    isRendererMode: (isEditMode$ | async) === false,
    isFocusable: (isFocusable$ | async) ?? false
  } as data"
>
  <dartsalesc-multi-select-cell-renderer
    *ngIf="data.isRendererMode; else editMode"
    [value]="controlValue"
    [options]="options"
    [class.focusable]="data.isFocusable"
    [isFocusable]="data.isFocusable"
  />
  <ng-template #editMode>
    <dartsalesc-multi-select-cell-editor
      [errorStateMatcher]="errorStateMatcher"
      [compareWith]="compareWith"
      [options]="options"
      [value]="controlValue ?? null"
      (valueChange)="controlValue = $event"
    />
  </ng-template>
</ng-container>
