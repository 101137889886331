import { ChangeDetectionStrategy, Component } from '@angular/core';

import { controlProviderFor } from '@dartsales/common/core/utils/value-accessors/base-value-accessor';
import { compareRoundNumbers } from '@dartsales/common/core/utils/rounds';
import { AMOUNT_ROUND_FRACTION_DIGITS } from '@dartsales/common/core/utils/constants';

import { AbstractInputComponent, AmountInputHelpers } from '../abstract-input';

/** Amount input for simple numbers. */
@Component({
  selector: 'dartsalesw-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => AmountInputComponent)],
})
export class AmountInputComponent extends AbstractInputComponent<number> {

  /** @inheritdoc */
  protected checkIsInputValuesChanged(a: number, b: number): boolean {
    return !compareRoundNumbers(a, b, AMOUNT_ROUND_FRACTION_DIGITS);
  }

  /** @inheritdoc */
  protected override valueToInput(value: number | null): string {
    return AmountInputHelpers.numberToInput(value ?? 0);
  }

  /** @inheritdoc */
  protected override valueFromInput(valueVm: string): number | null {
    return AmountInputHelpers.numberFromInput(valueVm);
  }
}
