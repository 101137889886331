<div class="actions">
  <ng-container
    *ngFor="let action of actions; let index = index; trackBy: trackByAction"
  >
    <mat-divider
      *ngIf="index !== 0"
      class="actions__divider"
      [vertical]="true"
    />
    <button
      class="actions__button"
      mat-button
      type="button"
      [class.active]="action.type === activeType"
      (click)="action.click()"
    >
      {{ action.label }}
    </button>
  </ng-container>
</div>
