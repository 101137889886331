import { ChangeDetectionStrategy, Component, Injector, Input, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';

import { ViewType } from '@dartsales/common/core/models/view-type';

import { ExportMultipleModuleComponent } from '../export-dialogs/export-multiple-module/export-multiple-module.component';
import { injectModuleExportService } from '../../../project-dashboard/services/export/module/module-export.service';
import { ExportDialogComponent, ExportDialogData } from '../export-dialogs/export-dialog/export-dialog.component';

type ExportButtonLabels = {

  /** Label for export customer view button. */
  readonly customer: string;

  /** Label for export internal view button. */
  readonly internal: string;

  /** Label for export multiple. */
  readonly multiple: string;
};

const defaultExportButtonLabels: ExportButtonLabels = {
  customer: 'Export Customer View',
  multiple: 'Export Multiple',
  internal: 'Export Internal View',
};

/** Module options button component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-module-options-button',
  templateUrl: './module-options-button.component.html',
  styleUrls: ['./module-options-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleOptionsButtonComponent {

  /** Should display customer export button. */
  @Input()
  public shouldDisplayCustomerExportButton = false;

  /** Labels. */
  @Input()
  public customLabels?: Partial<ExportButtonLabels>;

  private readonly moduleExportService = injectModuleExportService();

  private readonly dialogService = inject(MatDialog);

  private readonly injector = inject(Injector);

  /** View type. */
  protected readonly viewType = ViewType;

  /** Default export button labels.  */
  protected readonly defaultExportButtonLabels = defaultExportButtonLabels;

  /**
   * On export button click.
   * @param viewType ViewType.
   */
  protected onExportButtonClick(viewType: ViewType): void {
    const data: ExportDialogData = {
      supportedExportFormats: this.moduleExportService.supportedExportFormats,
      exportFn: exportFormat => this.moduleExportService.export({
        exportFormat,
        views: [viewType],
      }),
    };

    this.dialogService.open<ExportDialogComponent, ExportDialogData>(ExportDialogComponent, {
      data,
    });
  }

  /** On export multiple button click. */
  protected onExportMultipleButtonClick(): void {
    this.dialogService.open<ExportMultipleModuleComponent>(ExportMultipleModuleComponent, {
      injector: this.injector,
    });
  }
}
