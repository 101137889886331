import { inject, Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';

import { ExportFormat } from '@dartsales/common/core/enums/export-format';
import { ModuleExportParams } from '@dartsales/common/core/models/exports/module-export-params';
import { ViewType } from '@dartsales/common/core/models/view-type';
import { ModuleExportParamsFormControls, ModuleExportParamsFormValues } from '@dartsales/common/core/models/forms/export/module-export-params-form';

import { IFormToModel, IModelToForm } from '../form-mappers';

import { ModuleExportParamsFormValidators } from './module-export-config-form.validators';

/** Export module params form mapper. */
@Injectable({
  providedIn: 'root',
})
export class ModuleExportParamsFormMapper implements
  IModelToForm<ModuleExportParams, ModuleExportParamsFormControls>,
  IFormToModel<ModuleExportParamsFormControls, ModuleExportParams> {

  private readonly fb = inject(NonNullableFormBuilder);

  /** @inheritdoc */
  public modelToForm(): FormGroup<ModuleExportParamsFormControls> {
    return this.fb.group({
      isExportInternalView: this.fb.control(false),
      isExportCustomerView: this.fb.control(false),
      exportFormat: this.fb.control(ExportFormat.Csv),
    }, {
      validators: [ModuleExportParamsFormValidators.exportedDataSelected],
    });
  }

  /** @inheritdoc */
  public formToModel(formValues: ModuleExportParamsFormValues): ModuleExportParams {
    return {
      exportFormat: formValues.exportFormat,
      views: this.getViews(formValues),
    };
  }

  private getViews(formValues: ModuleExportParamsFormValues): ViewType[] {
    const views: ViewType[] = [];

    if (formValues.isExportCustomerView) {
      views.push(ViewType.Customer);
    }

    if (formValues.isExportInternalView) {
      views.push(ViewType.Internal);
    }

    return views;
  }
}
