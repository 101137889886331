import { Injectable, inject } from '@angular/core';

import { ExploreProjectsItem } from '../../models/project/explore-projects-item';
import { ExploreBidCategory } from '../../models/resources/bid-category';

import { ExploreBidCategoryDto } from './dto/resources/bid-category.dto';
import { AddressMapper } from './address.mapper';
import { DateMapper } from './date-mapper';
import { ExploreProjectsItemDto } from './dto/explore-projects-item.dto';
import { IMapperFromDto } from './mappers';

/** Explore Projects item mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExploreProjectsItemMapper implements IMapperFromDto<ExploreProjectsItemDto, ExploreProjectsItem> {

  private readonly dateMapper = inject(DateMapper);

  private readonly addressMapper = inject(AddressMapper);

  /** @inheritdoc */
  public fromDto(dto: ExploreProjectsItemDto): ExploreProjectsItem {
    return new ExploreProjectsItem({
      id: dto.id,
      name: dto.name,
      status: dto.status,
      projectNumber: dto.organizationProjectNumber,
      salesperson: dto.salesperson,
      organizationId: dto.organizationId,
      organizationName: dto.organizationName,
      code: dto.number,
      updatedAt: this.dateMapper.fromDto(dto.updatedAt),
      description: dto.description,
      location: dto.location ? this.addressMapper.fromDto(dto.location) : null,
      bidCategories: dto.bidCategories.map(bidCategoryDto => this.mapExploreBidCategory(bidCategoryDto)),
      baseEstimateSellPrice: dto.sellPrice,
      awardedSellPrice: dto.awardedSellPrice,
      logoUrl: dto.organizationLogoDownloadUrl?.url ?? null,
    });
  }

  private mapExploreBidCategory(bidCategoryDto: ExploreBidCategoryDto): ExploreBidCategory {
    return {
      id: bidCategoryDto.id,
      name: bidCategoryDto.name,
    };
  }
}
