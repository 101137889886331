import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommonSharedModule } from '@dartsales/common/shared/common-shared.module';
import { bindEventPluginProvider } from '@dartsales/common/core/utils/bind-event-plugin';
import { clickModifiersPluginProvider } from '@dartsales/common/core/utils/click-modifiers-plugin';
import { CellModule } from '@dartsales/common/shared/components/table-cell/cell.module';

import { MaterialExpansionActionsComponent } from './components/material-module/components/material-expansion-actions/material-expansion-actions.component';
import { AppAccessErrorPageComponent } from './components/app-access-error-page/app-access-error-page.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { CollapsableSectionComponent } from './components/collapsable-section/collapsable-section.component';
import { EditAccountSettingsDialogComponent } from './components/edit-account-settings-dialog/edit-account-settings-dialog.component';
import { ExpandableRowComponent } from './components/expandable-row/expandable-row.component';
import { FlatExpandableRowComponent } from './components/expandable-row/flat-expandable-row/flat-expandable-row.component';
import { OverridableAmountToggledInputComponent } from './components/inputs/amount-toggled-input/overridable-toggled-input/amount-overridable-toggled-input.component';
import { MaskedNumberInputComponent } from './components/inputs/masked-number-input/masked-number-input.component';
import { AppLayoutComponent } from './components/layouts/app-layout/app-layout.component';
import { ProjectLayoutComponent } from './components/layouts/project-layout/project-layout.component';
import { WelcomeLayoutComponent } from './components/layouts/welcome-layout/welcome-layout.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TotalSelectComponent } from './components/total-select/total-select.component';
import { ModuleHeaderLayoutComponent } from './components/module-header-layout/module-header-layout.component';
import { NavListItemComponent } from './components/nav-list-item/nav-list-item.component';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { OrganizationAvatarComponent } from './components/organization-avatar/organization-avatar.component';
import { PasswordFormFieldComponent } from './components/password-form-field/password-form-field.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { ProjectLayoutHeaderComponent } from './components/project-layout-header/project-layout-header.component';
import { ProjectSidebarComponent } from './components/project-sidebar/project-sidebar.component';
import { ProjectStatusComponent } from './components/project-status/project-status.component';
import { ProjectsSharedTableComponent } from './components/projects-shared-table/projects-shared-table.component';
import { ResizeDirective } from './components/resizable-content-container/directives/resize.directive';
import { ResizableContentContainerComponent } from './components/resizable-content-container/resizable-content-container.component';
import { LaborRateTypeSelectComponent } from './components/table-header-selects/labor-rate-type-select/labor-rate-type-select.component';
import { MarginTableHeaderCellComponent } from './components/table-header-selects/margin-table-header-cell/margin-table-header-cell.component';
import { TagsSelectComponent } from './components/tags-select/tags-select.component';
import { NotificationsButtonComponent } from './components/toolbars/notifications-button/notifications-button.component';
import { ProfileButtonComponent } from './components/toolbars/profile-button/profile-button.component';
import { ProjectToolbarComponent } from './components/toolbars/project-toolbar/project-toolbar.component';
import { WelcomeToolbarComponent } from './components/toolbars/welcome-toolbar/welcome-toolbar.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { TableHeaderSelectComponent } from './components/table-header-selects/table-header-select/table-header-select.component';
import { TagsTableCellComponent } from './components/tags-table-cell/tags-table-cell.component';
import { ProjectPageSearchComponent } from './components/project-page-search/project-page-search.component';
import { ExportMultipleEstimateDialogComponent } from './components/export-dialogs/export-multiple-estimate-dialog/export-multiple-estimate-dialog.component';
import { SuccessNotificationComponent } from './components/success-notification/success-notification.component';
import { DownloadDialogComponent } from './components/download-dialog-component/download-dialog.component';
import { ExportMultipleModuleComponent } from './components/export-dialogs/export-multiple-module/export-multiple-module.component';
import { ModuleOptionsButtonComponent } from './components/module-options-button/module-options-button.component';
import { GrossMarginAmountToggledInputComponent } from './components/inputs/amount-toggled-input/overridable-toggled-input/gross-margin-amount-toggled-input.component';
import { NestedTreeSelectComponent } from './components/nested-tree-select/nested-tree-select.component';
import { ExportDialogComponent } from './components/export-dialogs/export-dialog/export-dialog.component';
import { TermMultipleExportDialogComponent } from './components/export-dialogs/term-multiple-export-dialog/term-multiple-export-dialog.component';
import { SelectFormatControlComponent } from './components/export-dialogs/select-format-control/select-format-control.component';
import { HorizontalExpandButtonComponent } from './components/horizontal-expand-button/horizontal-expand-button.component';
import { EstimateSelectComponent } from './components/estimate-select/estimate-select.component';
import { AwardedBadgeComponent } from './components/awarded-badge/awarded-badge.component';
import { FiltersComponent } from './components/point-list-filters/base-filters/base-filters.component';
import { SortFiltersComponent } from './components/point-list-filters/sort-filters/sort-filters.component';
import { TagFiltersComponent } from './components/point-list-filters/tag-filters/tag-filters.component';
import { StringValueChangesComponent } from './components/string-value-changes/string-value-changes.component';
import { VerticalExpandButtonComponent } from './components/vertical-expand-button/vertical-expand-button.component';
import { TableHeaderReadonlyCellComponent } from './components/table-header-selects/table-header-readonly-cell/table-header-readonly-cell.component';
import { OrganizationFiltersComponent } from './components/point-list-filters/organization-filters/organization-filters.component';
import { ResizableContentLeftSectionComponent } from './components/resizable-content-container/components/resizable-content-left-section/resizable-content-left-section.component';
import { ResizableContentRightSectionComponent } from './components/resizable-content-container/components/resizable-content-right-section/resizable-content-right-section.component';
import { SelectEstimateChildrenControlComponent } from './components/export-dialogs/export-multiple-estimate-dialog/components/select-estimate-children-control/select-estimate-children-control.component';
import { SortSectionComponent } from './components/point-list-filters/sections/sort-section/sort-section.component';
import { CheckboxesSectionComponent } from './components/point-list-filters/sections/checkboxes-section/checkboxes-section.component';
import { ExpansionActionsComponent } from './components/expansion-actions/expansion-actions.component';
import { VisibleModuleSelectComponent } from './components/visible-module-select/visible-module-select.component';
import { OverridableAmountInputComponent } from './components/inputs/overridable-amount-input/overridable-amount-input.component';
import { OverridablePercentInputComponent } from './components/inputs/overridable-percent-input/overridable-percent-input.component';
import { SelectTermCategoriesTreeComponent } from './components/export-dialogs/term-multiple-export-dialog/select-term-categories-tree/select-term-categories-tree.component';
import { AmountInputComponent } from './components/inputs/amount-input/amount-input.component';
import { ComponentTypeFiltersComponent } from './components/point-list-filters/component-type-filters/component-type-filters.component';
import { ContingencyTableHeaderCellComponent } from './components/table-header-selects/amount-percent-table-header-cell/contingency-table-header-cell.component';
import { EscalationTableHeaderCellComponent } from './components/table-header-selects/amount-percent-table-header-cell/escalation-table-header-cell.component';
import { AppPermissionErrorPageComponent } from './components/app-permission-error-page/app-permission-error-page.component';
import { ComponentTypeIconComponent } from './components/component-type-icon/component-type-icon.component';
import { AmountToggledInputComponent } from './components/inputs/amount-toggled-input/amount-toggled-input/amount-toggled-input.component';
import { ManufacturerFiltersComponent } from './components/point-list-filters/manufacturer-filters/manufacturer-filters.component';
import { TableHeaderDcSelectComponent } from './components/table-header-selects/table-header-dc-select/table-header-dc-select.component';
import { PercentInputComponent } from './components/inputs/percent-input/percent-input.component';
import { ReadonlyAmountPercentDisplayComponent } from './components/readonly-amount-percent-display/readonly-amount-percent-display.component';
import { TableLockButtonComponent } from './components/table-lock-button/table-lock-button.component';
import { ConfirmedOverridableChangesComponent } from './components/confirmed-overridable-changes/confirmed-overridable-changes.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ReadonlyProjectMessageComponent } from './components/info-card/readonly-project-message/readonly-project-message.component';
import { ProjectPageSearchPortalComponent } from './components/project-page-search-portal/project-page-search-portal.component';
import { OverridableMaskedNumberInputComponent } from './components/overridable-mat-form-fields/overridable-masked-number-input/overridable-masked-number-input.component';
import { OverridableTextInputComponent } from './components/overridable-mat-form-fields/overridable-text-input/overridable-text-input.component';
import { ToggleableExpansionActionsComponent } from './components/toggleable-expansion-actions/toggleable-expansion-actions.component';
import { SearchPanelComponent } from './components/search-panel/search-panel.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { DraggableItemWrapperComponent } from './components/draggable-item-wrapper/draggable-item-wrapper.component';
import { DraggableItemPreviewComponent } from './components/draggable-item-preview/draggable-item-preview.component';
import { PercentTableHeaderCellComponent } from './components/table-header-selects/percent-table-header-cell/percent-table-header-cell.component';
import { CatalogSelectedItemsChipComponent } from './components/catalog-selected-items-chip/catalog-selected-items-chip.component';
import { RangeFilterComponent } from './components/point-list-filters/range-filter/range-filter.component';
import { SupplierFiltersComponent } from './components/point-list-filters/supplier-filters/supplier-filters.component';
import { CatalogTableHeaderResizeComponent } from './components/catalog-table-header-resize/catalog-table-header-resize.component';
import { BulkUpdateChangesConfirmationCellComponent } from './components/bulk-update-changes-confirmation-cell/bulk-update-changes-confirmation-cell.component';
import { BulkUpdateRemoveConfirmationCellComponent } from './components/bulk-update-remove-confirmation-cell/bulk-update-remove-confirmation-cell.component';

const EXPORTED_MODULES: Type<unknown>[] = [
  RouterModule,
  FormsModule,
  CommonSharedModule,
  CellModule,
];

const PRIVATE_MODULES: Type<unknown>[] = [];

const EXPORTED_DECLARATIONS: Type<unknown>[] = [
  ProjectPageSearchPortalComponent,
  ExportDialogComponent,
  AppLayoutComponent,
  WelcomeLayoutComponent,
  ProjectLayoutComponent,
  NavigationTabsComponent,
  ProjectLayoutHeaderComponent,
  PlaceholderComponent,
  ProjectsSharedTableComponent,
  OverridableAmountInputComponent,
  OverridablePercentInputComponent,
  AmountToggledInputComponent,
  OverridableAmountToggledInputComponent,
  GrossMarginAmountToggledInputComponent,
  LoadingComponent,
  ProjectStatusComponent,
  OrganizationAvatarComponent,
  ProjectToolbarComponent,
  NotificationsButtonComponent,
  ProfileButtonComponent,
  ProjectSidebarComponent,
  EscalationTableHeaderCellComponent,
  MarginTableHeaderCellComponent,
  TableHeaderReadonlyCellComponent,
  LaborRateTypeSelectComponent,
  MaskedNumberInputComponent,
  EditAccountSettingsDialogComponent,
  ChangePasswordDialogComponent,
  ResizableContentContainerComponent,
  ResizableContentLeftSectionComponent,
  ResizableContentRightSectionComponent,
  CollapsableSectionComponent,
  ResizeDirective,
  ExpandableRowComponent,
  FlatExpandableRowComponent,
  ModuleHeaderLayoutComponent,
  PasswordFormFieldComponent,
  TagsSelectComponent,
  TotalSelectComponent,
  AlertMessageComponent,
  TableHeaderSelectComponent,
  TagsTableCellComponent,
  ProjectPageSearchComponent,
  ExportMultipleEstimateDialogComponent,
  SuccessNotificationComponent,
  DownloadDialogComponent,
  ExportMultipleModuleComponent,
  ModuleOptionsButtonComponent,
  NestedTreeSelectComponent,
  TermMultipleExportDialogComponent,
  SelectFormatControlComponent,
  HorizontalExpandButtonComponent,
  EstimateSelectComponent,
  AwardedBadgeComponent,
  FiltersComponent,
  SortFiltersComponent,
  TagFiltersComponent,
  StringValueChangesComponent,
  VerticalExpandButtonComponent,
  OrganizationFiltersComponent,
  ComponentTypeFiltersComponent,
  SelectEstimateChildrenControlComponent,
  ExpansionActionsComponent,
  ToggleableExpansionActionsComponent,
  VisibleModuleSelectComponent,
  SelectTermCategoriesTreeComponent,
  AmountInputComponent,
  ComponentTypeIconComponent,
  ContingencyTableHeaderCellComponent,
  EscalationTableHeaderCellComponent,
  PercentTableHeaderCellComponent,
  AppPermissionErrorPageComponent,
  MaterialExpansionActionsComponent,
  TableHeaderDcSelectComponent,
  ManufacturerFiltersComponent,
  PercentInputComponent,
  ReadonlyAmountPercentDisplayComponent,
  TableLockButtonComponent,
  ConfirmedOverridableChangesComponent,
  InfoCardComponent,
  ReadonlyProjectMessageComponent,
  OverridableTextInputComponent,
  OverridableMaskedNumberInputComponent,
  SearchPanelComponent,
  SearchInputComponent,
  DraggableItemWrapperComponent,
  DraggableItemPreviewComponent,
  CatalogSelectedItemsChipComponent,
  RangeFilterComponent,
  SupplierFiltersComponent,
  CatalogTableHeaderResizeComponent,
  BulkUpdateChangesConfirmationCellComponent,
  BulkUpdateRemoveConfirmationCellComponent,
];

const PRIVATE_DECLARATIONS: Type<unknown>[] = [
  WelcomeToolbarComponent,
  NavListItemComponent,
  AppAccessErrorPageComponent,
  SortSectionComponent,
  CheckboxesSectionComponent,
];

/** Module that contains entities shared between features/layouts. */
@NgModule({
  declarations: [...PRIVATE_DECLARATIONS, ...EXPORTED_DECLARATIONS],
  imports: [...PRIVATE_MODULES, ...EXPORTED_MODULES],
  exports: [...EXPORTED_MODULES, ...EXPORTED_DECLARATIONS],
  providers: [bindEventPluginProvider, clickModifiersPluginProvider],
})
export class WebSharedModule { }
