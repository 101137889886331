import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProjectStatus } from '@dartsales/common/core/enums/project-status';

const TO_CSS_CLASS_MAP: Record<ProjectStatus, string> = {
  [ProjectStatus.InProgress]: 'project-status_in-progress',
  [ProjectStatus.InReview]: 'project-status_in-review',
  [ProjectStatus.Ready]: 'project-status_ready',
  [ProjectStatus.Submitted]: 'project-status_submitted',
  [ProjectStatus.Accepted]: 'project-status_accepted',
  [ProjectStatus.Rejected]: 'project-status_rejected',
  [ProjectStatus.Canceled]: 'project-status_cancelled',
  [ProjectStatus.OnHold]: 'project-status_on-hold',
};

/** Loading content. */
@Component({
  selector: 'dartsalesw-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusComponent {

  /** Project status. */
  @Input()
  public status: ProjectStatus | null = null;

  /** Project statuses. */
  protected readonly projectStatus = ProjectStatus;

  /**
   * Get css class for a certain project status.
   * @param value Project status.
   */
  protected toCSSClass(value: ProjectStatus): string {
    return TO_CSS_CLASS_MAP[value];
  }
}
