import { UserRole } from '../enums/user-role';
import { StrictOmit } from '../utils/types/strict-omit';

import { BaseOrganization } from './organization';

/** User's organization. */
export class UserOrganization extends BaseOrganization {
  /** Roles. */
  public readonly roles: readonly UserRole[];

  public constructor(data: UserOrganizationInitArgs) {
    super(data);
    this.roles = data.roles;
  }

  /**
   * Whether user has allowed roles.
   * @param allowedRoles Allowed roles.
   */
  public hasAllowedRoles(allowedRoles: readonly UserRole[]): boolean {
    return this.roles.some(role => allowedRoles.includes(role));
  }
}

type UserOrganizationInitArgs = StrictOmit<UserOrganization, 'hasAllowedRoles'>;
