import { EditProjectDetails } from './edit-project-details';
import { OtherTeamMember } from './other-team-member';
import { ProjectBid } from './project-bid';
import { ProjectCompany } from './project-company';
import { ProjectDetails } from './project-details';
import { ProjectTeam } from './project-team';

/** Project info base. */
class ProjectInfoBase {

  /** Project bid info. */
  public readonly bidInfo: ProjectBid;

  /** Project company. */
  public readonly company: ProjectCompany;

  /** Project team. */
  public readonly team: ProjectTeam;

  /** Project team other members. */
  public readonly otherTeamMembers: readonly OtherTeamMember[];

  public constructor(data: ProjectInfoBaseInitArgs) {
    this.bidInfo = data.bidInfo;
    this.company = data.company;
    this.team = data.team;
    this.otherTeamMembers = data.otherTeamMembers;
  }
}

/** Project Info. */
export class ProjectInfo extends ProjectInfoBase {

  /** Project details. */
  public readonly details: ProjectDetails;

  public constructor(data: ProjectInfoInitArgs) {
    super(data);
    this.details = data.details;
  }
}

/** Data for edit project info. */
export class ProjectInfoEdit extends ProjectInfoBase {

  /** Project details. */
  public readonly details: EditProjectDetails;

  public constructor(data: ProjectInfoEditInitArgs) {
    super(data);
    this.details = data.details;
  }
}

type ProjectInfoBaseInitArgs = ProjectInfoBase;
type ProjectInfoInitArgs = ProjectInfo;
type ProjectInfoEditInitArgs = ProjectInfoEdit;
