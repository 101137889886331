import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_SEPARATOR_VALUE = ', ';
const DEFAULT_EMPTY_STRING_SEPARATOR_VALUE = '';

/** Join pipe. The same join array method. */
@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {

  /**
   * Join elements of an array separated by the specified separator string.
   * @param value Array.
   * @param args Arguments.
   * @param args.separator Separator.
   * @param args.emptyValue Empty value. Displayed if the array is empty.
   */
  public transform(
    value: readonly (string | number)[] | null | undefined,
    args?: {
      readonly separator?: string;
      readonly emptyValue?: string;
    },
  ): string {
    const emptyStringSeparator = args?.emptyValue ?? DEFAULT_EMPTY_STRING_SEPARATOR_VALUE;
    const separator = args?.separator ?? DEFAULT_SEPARATOR_VALUE;
    if (value == null || value.length === 0) {
      return emptyStringSeparator;
    }

    return value.filter(item => item !== '').join(separator);
  }

}
