import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/errors/app-error';
import { PasswordReset } from '../../models/auth/password-reset';

import { PasswordResetDto } from './dto/auth/password-reset.dto';
import { MappedValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './errors/extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Mapper for reset password data. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordConfirmationMapper
implements
    IMapperToDto<PasswordResetDto.Confirmation, PasswordReset.Confirmation>,
    IValidationErrorMapper<PasswordResetDto.Confirmation, PasswordReset.Confirmation> {
  /** @inheritdoc */
  public toDto(
    data: PasswordReset.Confirmation,
  ): PasswordResetDto.Confirmation {
    return {
      email: data.email,
      newPassword: data.passwordConfirmation,
      token: data.key,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: MappedValidationErrorDto<PasswordResetDto.Confirmation>,
  ): EntityValidationErrors<PasswordReset.Confirmation> {
    return {
      passwordConfirmation:
        extractErrorMessage(errorDto.newPassword) ??
        extractErrorMessage(errorDto.email) ??
        extractErrorMessage(errorDto.token) ??
        extractErrorMessage(errorDto.non_field_errors),
    };
  }
}
