<ng-container
  *ngrxLet="{
    isEditMode: (isEditMode$ | async) ?? false,
    isFocusable: (isFocusable$ | async) ?? false
  } as data"
>
  <dartsalesc-single-select-cell-renderer
    *ngIf="!data.isEditMode; else editMode"
    [value]="controlValue"
    [options]="options"
    [class.focusable]="data.isFocusable"
    [isFocusable]="data.isFocusable"
  />
  <ng-template #editMode>
    <dartsalesc-single-select-cell-editor
      [errorStateMatcher]="errorStateMatcher"
      [compareWith]="compareWith"
      [options]="options"
      [value]="controlValue"
      (valueChange)="controlValue = $event"
    />
  </ng-template>
</ng-container>
