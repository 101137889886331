import { Injectable, inject } from '@angular/core';

import { OverridablePartPortProperty, PartPortProperty } from '@dartsales/common/core/models/components/estimate-part/estimate-part-port-property';

import { OverridablePartPortPropertyDto, PartPortPropertyDto } from '../../dto/components/estimate-part/estimate-part-port-property.dto';
import { IMapper } from '../../mappers';
import { ConfirmedOverridableMapper } from '../../confirmed-overridable.mapper';

/** EstimatePartPort entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class EstimatePartPortPropertyMapper implements IMapper<PartPortPropertyDto, PartPortProperty> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(dto: PartPortPropertyDto): PartPortProperty {
    return new PartPortProperty({
      type: dto.type,
      value: dto.value,
    });
  }

  /** @inheritdoc */
  public toDto(data: PartPortProperty): PartPortPropertyDto {
    return {
      type: data.type,
      value: data.value,
    };
  }

  /** @inheritdoc */
  public fromOverridableDto(dto: OverridablePartPortPropertyDto): OverridablePartPortProperty {
    return new OverridablePartPortProperty({
      type: dto.type,
      value: this.confirmedOverridableMapper.fromDto(dto.value),
    });
  }

  /** @inheritdoc */
  public toOverridableDto(data: OverridablePartPortProperty): OverridablePartPortPropertyDto {
    return {
      type: data.type,
      value: this.confirmedOverridableMapper.toDto(data.value),
    };
  }
}
