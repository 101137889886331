import { Injectable, inject } from '@angular/core';

import { PointsListSystemDetails } from '@dartsales/common/core/models/estimate/modules/points-list/system/points-list-system-details';
import { CreatePointsListSystemDetails } from '@dartsales/common/core/models/estimate/modules/points-list/system/create-points-list-system-details';
import { EditPointsListSystemDetails } from '@dartsales/common/core/models/estimate/modules/points-list/system/edit-points-list-system-details';

import { CreatePointsListSystemDataDto, EditPointsListSystemDataDto, PointsListSystemDataDto } from '../../../dto/estimate/modules/points-list/system/points-list-system-data.dto';
import { IMapper } from '../../../mappers';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

import { PointsListSystemClassificationMapper } from './points-list-system-classification.mapper';

/** Points list system details mapper. */
@Injectable({
  providedIn: 'root',
})
export class PointsListSystemDetailsMapper implements IMapper<PointsListSystemDataDto, PointsListSystemDetails> {
  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  private readonly systemClassificationMapper = inject(PointsListSystemClassificationMapper);

  /** @inheritdoc */
  public fromDto(dto: PointsListSystemDataDto): PointsListSystemDetails {
    return new PointsListSystemDetails({
      name: this.confirmedOverridableMapper.fromDto(dto.name),
      description: this.confirmedOverridableMapper.fromDto(dto.description),
      classification: this.confirmedOverridableMapper.fromDtoWithMapper(dto.classification, this.systemClassificationMapper),
      typicalCount: dto.typicalCount,
      salesNotes: dto.salesNotes,
      instanceNames: dto.instanceNames,
      tags: dto.tags,
    });
  }

  /** @inheritdoc */
  public toDto(data: PointsListSystemDetails): PointsListSystemDataDto {
    return {
      name: this.confirmedOverridableMapper.toDto(data.name),
      description: this.confirmedOverridableMapper.toDto(data.description),
      classification: this.confirmedOverridableMapper.toDtoWithMapper(data.classification, this.systemClassificationMapper),
      typicalCount: data.typicalCount,
      salesNotes: data.salesNotes,
      instanceNames: data.instanceNames,
      tags: data.tags,
    };
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreatePointsListSystemDetails): CreatePointsListSystemDataDto {
    return {
      name: data.name,
      description: data.description,
      classificationId: data.classificationId,
      typicalCount: data.typicalCount,
      salesNotes: data.salesNotes,
      instanceNames: data.instanceNames,
      tags: data.tags,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditPointsListSystemDetails): EditPointsListSystemDataDto {
    return this.toDto(data);
  }
}
