import { Overridable } from '@dartsales/common/core/utils/types/overridable';
import { BasePricing } from '@dartsales/common/core/models/pricing/base-pricing';
import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { Margin, OverridableMargin } from '../estimate/modules/margin';
import { ClassProperties } from '../../utils/types/class-properties';
import { DomainCostCalculator } from '../calculators/domain/domain-cost-calculator';
import { AmountPercent } from '../estimate/amount-percent';

/** Overridable base pricing. */
export class OverridableBasePricing {
  /** Cost. */
  public readonly directCost: Overridable<BasePricing['directCost']>;

  /** Contingency. */
  public readonly contingency: Overridable<BasePricing['contingency']>;

  /** Escalation. */
  public readonly escalation: Overridable<BasePricing['escalation']>;

  /** Escalation + WFS parameters from material module. */
  public readonly aggregatedWEFS: Overridable<AmountPercent>;

  /** Values of gross margin and markup. */
  public readonly margin: OverridableMargin;

  /** Sell price. */
  public readonly sellPrice: Overridable<BasePricing['sellPrice']>;

  /**
   * DC info.
   * @param percent Ratio percentage of pricing direct cost to parent direct cost.
   * @param amount Total direct cost.
   */
  public readonly dc: AmountPercent | null;

  /**
   * TDC info.
   * @param percent Ratio percentage of pricing direct cost to estimate direct cost.
   * @param amount Total direct cost.
   */
  public readonly tdc: AmountPercent | null;

  public constructor(initArgs: OverridableBasePricingInitArgs) {
    this.directCost = initArgs.directCost;
    this.contingency = initArgs.contingency;
    this.margin = initArgs.margin;
    this.sellPrice = initArgs.sellPrice;
    this.escalation = initArgs.escalation;
    this.aggregatedWEFS = initArgs.aggregatedWEFS;
    this.dc = initArgs.dc ?? null;
    this.tdc = initArgs.tdc ?? null;
  }

  /**
   * Total direct cost, according to specification
   * https://docs.google.com/spreadsheets/d/1VzxlBUTNTsKc2SRX-DNj0MGar7zejjTqUkTlMQooJso/edit#gid=241181340.
   */
  public getTotalDirectCost(): number {
    return DomainCostCalculator.calculateTotalDirectCost({
      directCost: this.directCost.combinedValue,
      contingencyAmount: this.contingency.combinedValue.amount,
      escalationAmount: this.escalation.combinedValue.amount,
    });
  }

  /** Convert Overridable pricing to simple domain representation with loosing override information. */
  public toBasePricing(): BasePricing {
    return new BasePricing({
      directCost: this.directCost.combinedValue,
      contingency: this.contingency.combinedValue,
      margin: new Margin({
        grossMargin: this.margin.grossMargin.combinedValue,
        markup: this.margin.markup.combinedValue,
      }),
      markup: this.margin.markup.combinedValue,
      sellPrice: this.sellPrice.combinedValue,
      escalation: this.escalation.combinedValue,
      dc: this.dc,
      tdc: this.tdc,
    });
  }

  /** Get pricing with reset override properties. */
  public resetPricingOverride(): OverridableBasePricing {
    return new OverridableBasePricing({
      directCost: new Overridable({
        initial: this.directCost.initial,
      }),
      contingency: new Overridable({
        initial: this.contingency.initial,
      }),
      margin: new OverridableMargin({
        grossMargin: new Overridable({
          initial: this.margin.grossMargin.initial,
        }),
        markup: new Overridable({
          initial: this.margin.markup.initial,
        }),
      }),
      sellPrice: new Overridable({
        initial: this.sellPrice.initial,
      }),
      escalation: new Overridable({
        initial: this.escalation.initial,
      }),
      aggregatedWEFS: new Overridable({
        initial: this.aggregatedWEFS.initial,
      }),
    });
  }

  /**
   * Convert domain base pricing to Overridable with empty overrides.
   * @param pricing Source domain base pricing.
   **/
  public static fromDomainBasePricing(pricing: BasePricing): OverridableBasePricing {
    return new OverridableBasePricing({
      directCost: new Overridable({
        initial: pricing.directCost,
      }),
      contingency: new Overridable({
        initial: pricing.contingency,
      }),
      margin: OverridableMargin.fromValues({
        initial: {
          amount: pricing.margin.grossMargin.amount,
          grossMargin: pricing.margin.grossMargin.percent,
          markup: pricing.margin.markup.percent,
        },
      }),
      sellPrice: new Overridable({
        initial: pricing.sellPrice,
      }),
      escalation: new Overridable({
        initial: pricing.escalation,
      }),
      aggregatedWEFS: new Overridable({
        initial: new AmountPercent(),
      }),
      dc: pricing.dc,
      tdc: pricing.tdc,
    });
  }
}

type NotOverridableFields = 'tdc' | 'dc';

export type OverridableBasePricingInitArgs = StrictOmit<ClassProperties<OverridableBasePricing>, NotOverridableFields> &
  Partial<Pick<OverridableBasePricing, NotOverridableFields>>;

/** Partial base pricing for calculations. */
export type CalculationOverridableBasePricing = Pick<OverridableBasePricing,
  | 'directCost'
  | 'contingency'
  | 'margin'
  | 'escalation'
  | 'aggregatedWEFS'
> & { sellPrice?: OverridableBasePricing['sellPrice']; };
