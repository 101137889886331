import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, map, first, switchMap, tap, EMPTY, filter, shareReplay, startWith, combineLatest } from 'rxjs';

import { injectWebAppRoutes, PROJECT_ID_PARAM } from 'projects/web/src/app/web-route-paths';

import { ProjectStatus } from '../enums/project-status';
import { Project } from '../models/project/project';
import { catchAppError } from '../utils/rxjs/catch-app-error';
import { UserOrganization } from '../models/user-organization';
import { UserProfile } from '../models/user/user-profile';
import { ProjectDetails } from '../models/project/project-details';
import { UserRole } from '../enums/user-role';
import { UserPermission } from '../enums/user-permission';
import { ExploreProjectsItem } from '../models/project/explore-projects-item';

import { ProjectsApiService } from './api/projects-api.service';
import { CurrentUserService } from './current-user.service';
import { PermissionsService } from './permissions.service';
import { TemplatesApiService } from './api/templates-api.service';

/** Project layout service. */
@Injectable()
export class ProjectLayoutService {

  private readonly route = inject(ActivatedRoute);

  private readonly router = inject(Router);

  private readonly projectsApiService = inject(ProjectsApiService);

  private readonly userService = inject(CurrentUserService);

  private readonly permissionsService = inject(PermissionsService);

  private readonly templatesApiService = inject(TemplatesApiService);

  private readonly refresh$ = new Subject<void>();

  /** Project ID. */
  public readonly projectId$ = this.createProjectIdStream();

  /** Project. */
  public readonly project$ = this.createProjectStream(this.projectId$);

  /** Organization stream. */
  public readonly organization$ = this.createOrganizationStream(this.userService.currentUser$, this.project$);

  /** User roles. */
  public readonly userRoles$ = this.createUserRolesStream(this.organization$);

  /** Organization ID stream. */
  public readonly organizationId$ = this.createOrganizationIdStream(this.project$);

  /** Organization templates stream. */
  public readonly templates$ = this.createOrganizationTemplatesStream();

  private readonly routePaths = injectWebAppRoutes();

  /** Refresh project. */
  public refreshProject(): void {
    this.refresh$.next();
  }

  /**
   * Update project status.
   * @param status Project status.
   */
  public updateProjectStatus(status: ProjectStatus): Observable<void> {
    return this.project$.pipe(
      first(),
      switchMap(project => this.projectsApiService.updateProjectStatus(project.id, status)),
      tap(() => this.refreshProject()),
      catchAppError(error => {

        // TODO (DART-103): add handle for errors with popup messages feature.
        console.warn(error.message);
        return EMPTY;
      }),
    );
  }

  /**
   * Whether user has permission in current organization.
   * @param permissions Permissions.
   */
  public hasPermissionInCurrentOrganization(permissions: readonly UserPermission[]): Observable<boolean> {
    return this.organizationId$.pipe(
      switchMap(organizationId => this.permissionsService.hasPermissionsInOrganization(permissions, organizationId)),
    );
  }

  private createProjectIdStream(): Observable<Project['id']> {
    return this.route.paramMap.pipe(
      map(params => Number(params.get(PROJECT_ID_PARAM))),
      filter(id => !Number.isNaN(id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private createUserRolesStream(organization$: Observable<UserOrganization | undefined>): Observable<UserRole[]> {
    return organization$.pipe(map(organization => [...(organization?.roles ?? [])]));
  }

  private createProjectStream(projectId$: Observable<Project['id']>): Observable<Project> {
    const project$ = projectId$.pipe(
      switchMap(id => this.projectsApiService.getProjectById(id)),
      catchAppError(() => {
        this.router.navigate([this.routePaths.welcome.children.exploreProjects.url]);
        return EMPTY;
      }),
    );

    return this.refresh$.pipe(
      startWith(null),
      switchMap(() => project$),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private createOrganizationStream(
    currentUser$: Observable<UserProfile | null>,
    project$: Observable<Project>,
  ): Observable<UserOrganization | undefined> {
    return combineLatest([currentUser$, project$]).pipe(
      map(([currentUser, project]) => {
        const { organizationId } = project.projectInfo.details;
        return currentUser?.organizations.find(organization => organization.id === organizationId);
      }),
    );
  }

  private createOrganizationIdStream(
    project$: Observable<Project>,
  ): Observable<ProjectDetails['organizationId']> {
    return project$.pipe(
      map(project => project.projectInfo.details.organizationId),
    );
  }

  private createOrganizationTemplatesStream(): Observable<ExploreProjectsItem[]> {
    return this.organizationId$.pipe(
      switchMap(organizationId => this.templatesApiService.getProjectsByOrganizationId(organizationId)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
