import { TreeItem } from '../tree-item';

import { TermCategoryTreeItem } from './term-category-tree-item';

/** Term item. */
export type TermItem = {

  /** Term ID. */
  readonly termId: number;
};

/** Term tree item. */
export class TermTreeItem extends TreeItem<TermItem, TermCategoryTreeItem> {}
