import { ExpensesModuleProperties } from './modules/expenses/expenses-module-properties';
import { LaborModuleProperties } from './modules/labor/labor-module-properties';
import { MaterialModuleProperties } from './modules/material/properties/material-module-properties';
import { ModuleProperties } from './modules/module-properties';
import { PointsListModuleProperties } from './modules/points-list/points-list-module-properties';
import { SubcontractorModuleProperties } from './modules/subcontractor/subcontractor-module-properties';

/** Base module properties list. */
export class BaseModulePropertiesList {

  /** Subcontractor module. */
  public readonly subcontractor: SubcontractorModuleProperties;

  /** Expenses module. */
  public readonly expenses: ExpensesModuleProperties;

  /** Custom module. */
  public readonly custom: ModuleProperties;

  /** Labor module. */
  public readonly labor: LaborModuleProperties;

  /** Materials module. */
  public readonly material: MaterialModuleProperties;

  public constructor(initArgs: BaseModulePropertiesListInitArgs) {
    this.subcontractor = initArgs.subcontractor;
    this.expenses = initArgs.expenses;
    this.custom = initArgs.custom;
    this.labor = initArgs.labor;
    this.material = initArgs.material;
  }
}

/** Modules list. */
export class ModulePropertiesList extends BaseModulePropertiesList {

  /** Points list module properties. */
  public readonly pointsList: PointsListModuleProperties;

  public constructor(initArgs: ModulePropertiesListInitArgs) {
    super(initArgs);
    this.pointsList = initArgs.pointsList;
  }
}

type ModulePropertiesListInitArgs = ModulePropertiesList;

type BaseModulePropertiesListInitArgs = BaseModulePropertiesList;
