<ng-container
  *ngrxLet="resizeContentContainerServices.isExpanded$ | async as isExpanded"
>
  <div
    #targetElement
    class="section"
    [style.width]="getSectionWidth(isExpanded ?? true, width)"
    [style.min-width]="!isExpanded ? 'fit-content' : ''"
    [style.max-width]="!isExpanded ? 'fit-content' : ''"
  >
    <dartsalesw-collapsable-section
      [headerTitle]="sectionTitle"
      [isExpanded]="isExpanded ?? true"
      [isOpenInNewButtonDisplayed]="isOpenInNewButtonDisplayed"
      (expandChange)="onSectionExpandedChange($event)"
      (openInNewButtonClick)="openInNewButtonClick.emit()"
    >
      <ng-content />
    </dartsalesw-collapsable-section>
  </div>

  <div
    *ngIf="isExpanded"
    dartsaleswResize
    class="separator"
    [target]="targetElement"
    [minWidth]="resizeContentContainerServices.leftSectionMinWidth"
    [maxWidth]="resizeContentContainerServices.leftSectionMaxWidth$ | async"
    (changeWidth)="changeWidth.emit($event)"
  >
    <div class="separator__button"></div>
  </div>
</ng-container>
