import { Directive, ElementRef, inject, Input } from '@angular/core';

/** This directive helps to dynamically change the input mask if the input is displayed by condition. */
@Directive({
  selector: 'input[dartsalescInputMask]',
})
export class InputMaskDirective {

  /** Input mask. */
  @Input({ required: true })
  public set dartsalescInputMask(value: Inputmask.Options) {
    Inputmask(value).mask(this.inputElement);
  }

  private readonly inputElement = inject<ElementRef<HTMLInputElement>>(ElementRef).nativeElement;
}
