import { inject, Injectable } from '@angular/core';

import { TermSubmodulePricingDto } from '@dartsales/common/core/services/mappers/dto/pricing/term-submodule-pricing.dto';
import { AmountPercent } from '@dartsales/common/core/models/estimate/amount-percent';
import { OverridableBasePricing } from '@dartsales/common/core/models/pricing/overridable-base-pricing';
import { BasePricing } from '@dartsales/common/core/models/pricing/base-pricing';

import { IMapper } from '../mappers';

import { BasePricingMapper } from './base-pricing.mapper';

/** Term submodule pricing mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermSubmodulePricingMapper implements IMapper<TermSubmodulePricingDto, BasePricing> {

  private readonly basePricingMapper = inject(BasePricingMapper);

  /** @inheritdoc */
  public fromDto(dto: TermSubmodulePricingDto): BasePricing {
    const pricing = this.basePricingMapper.fromOverridableDto(dto);
    return new BasePricing({
      ...pricing.toBasePricing(),
    });
  }

  /** @inheritdoc */
  public toDto(model: BasePricing): TermSubmodulePricingDto {
    const pricingDto = this.basePricingMapper.toOverridableDto(
      OverridableBasePricing.fromDomainBasePricing(model),
    );
    return {
      ...pricingDto,
      markupCustomer: new AmountPercent(),
    };
  }
}
