import { ClassProperties } from '../../utils/types/class-properties';
import { Overridable } from '../../utils/types/overridable';
import { TermCompoundEscalationOptions } from '../compound-escalation-options';
import { AmountPercent } from '../estimate/amount-percent';
import { ModuleProperties, ModulePropertiesInitArgs } from '../estimate/modules/module-properties';
import { BasePricing } from '../pricing/base-pricing';

import { TermSummary } from './term-summary';

/** Summary information on the term broken down by module type. */
export class TermSubmoduleTotals {

  /** Subcontractor module summary. */
  public readonly subcontractor: TermSubmoduleProperties;

  /** Expenses module summary. */
  public readonly expenses: TermSubmoduleProperties;

  /** Custom module term summary. */
  public readonly custom: TermSubmoduleProperties;

  /** Labor module summary. */
  public readonly labor: TermSubmoduleProperties;

  /** Material module summary. */
  public readonly material: TermSubmoduleProperties;

  public constructor(data: TermSubmoduleTotalsInitArgs) {
    this.subcontractor = data.subcontractor;
    this.expenses = data.expenses;
    this.custom = data.custom;
    this.labor = data.labor;
    this.material = data.material;
  }
}

export class TermSubmoduleProperties extends BasePricing {

  /** Escalation + WFS parameters from material module. */
  public readonly aggregatedWEFS: Overridable<AmountPercent>;

  /** Amount of units. */
  public readonly quantity: number | null;

  public constructor(data: TermSubmodulePropertiesInitArgs) {
    super(data);
    this.aggregatedWEFS = data.aggregatedWEFS;
    this.quantity = data.quantity;
  }
}
type TermSubmodulePropertiesInitArgs = ClassProperties<TermSubmoduleProperties>;

/** Term totals properties. */
export class TermProperties extends ModuleProperties {

  /** Order. */
  public readonly order: number;

  /** Term summaries. */
  public readonly submoduleTotals: TermSubmoduleTotals;

  /** Compound escalation options. */
  public readonly compoundEscalation: TermCompoundEscalationOptions;

  public constructor(data: TermPropertiesInitArgs) {
    super(data);
    this.order = data.order;
    this.submoduleTotals = data.submoduleTotals;
    this.compoundEscalation = data.compoundEscalation;
  }
}

type TermSubmoduleTotalsInitArgs = TermSubmoduleTotals;

export type TermPropertiesInitArgs = ModulePropertiesInitArgs
  & Pick<TermProperties, 'submoduleTotals' | 'compoundEscalation'>
  & Pick<TermSummary, 'order'>;
