import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NestedNode } from './models/nested-node';
import { FlatNode } from './models/flat-node';
import { injectNestedTreeSelectionService } from './nested-tree-selection-service.provider';

/**
 * Nested tree select component.
 * Requires provide service to manage selection. Look at nested-tree-selection-service.provider.ts.
 */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-nested-tree-select',
  templateUrl: './nested-tree-select.component.html',
  styleUrls: ['./nested-tree-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NestedTreeSelectComponent<T> implements OnInit {

  /** Tree service. */
  protected readonly treeService = injectNestedTreeSelectionService<T>();

  private _isAllExpanded = false;

  /** Whether all nodes are expanded. */
  @Input()
  public set isAllExpanded(isAllExpanded: boolean) {
    this._isAllExpanded = isAllExpanded;
  }

  /** Whether all nodes are expanded. */
  public get isAllExpanded(): boolean {
    return this._isAllExpanded;
  }

  /** Nested nodes. */
  @Input()
  public set nestedNodes(nestedNodes: readonly NestedNode<T>[] | null) {
    const nodes = nestedNodes ?? [];
    this.treeService.dataSource.data = [...nodes];

    if (this.isAllExpanded) {
      this.treeService.treeControl.expandAll();
    }
  }

  /** Selected. */
  @Output()
  public readonly selected = new EventEmitter<FlatNode<T>[]>();

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSelectNodesChanges();
  }

  /** Deselect all. */
  public deselectAll(): void {
    this.treeService.deselectAll();
  }

  private subscribeToSelectNodesChanges(): void {
    this.treeService.selectedNodes$.pipe(
      untilDestroyed(this),
    )
      .subscribe(selectedValues => {
        this.selected.emit(selectedValues);
      });
  }
}
