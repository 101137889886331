<div
  *ngIf="isLoading"
  class="spinner-container"
>
  <mat-progress-spinner
    mode="indeterminate"
    class="spinner"
    [diameter]="diameter"
  />
</div>
