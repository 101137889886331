import { Injectable } from '@angular/core';

import { BasePricingOverrides } from '@dartsales/common/core/models/pricing/base-pricing-overrides';
import { ModuleProperties } from '@dartsales/common/core/models/estimate/modules/module-properties';

import { IMapperToDto } from '../mappers';
import { BasePricingOverridesDto } from '../dto/pricing/base-pricing.dto';

/** Modules properties overrides mapper. */
@Injectable({
  providedIn: 'root',
})
export class BasePricingOverridesMapper implements
  IMapperToDto<BasePricingOverridesDto, BasePricingOverrides> {

  /** @inheritdoc */
  public toDto(data: BasePricingOverrides): BasePricingOverridesDto {
    return {
      directCost: data.directCost ?? undefined,
      contingency: data.contingency ?? undefined,
      escalation: data.escalation ?? undefined,
      grossMargin: data.grossMargin ?? undefined,
      sellPrice: data.sellPrice ?? undefined,
      aggregatedWEFS: data.aggregatedWEFS ?? undefined,
    };
  }

  /**
   * Map module properties to modules properties overrides.
   * @param properties Module properties.
   */
  public fromModuleProperties(properties: ModuleProperties): BasePricingOverrides {
    return new BasePricingOverrides({
      directCost: properties.directCost.override,
      contingency: properties.contingency.override,
      escalation: properties.escalation.override,
      grossMargin: properties.margin.grossMargin.override,
      sellPrice: properties.sellPrice.override,
      aggregatedWEFS: properties.aggregatedWEFS.override,
    });
  }
}
