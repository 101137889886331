import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { AbstractCellFormControlComponent } from '../../base-components/abstract-cell-form-control.component';
import { CELL_PROVIDERS } from '../../services/cell.provider';

/** Multi select cell component. */
@Component({
  selector: 'dartsalesc-multi-select-cell',
  templateUrl: './multi-select-cell.component.html',
  styleUrls: ['./multi-select-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CELL_PROVIDERS],
})
export class MultiSelectCellComponent<T> extends AbstractCellFormControlComponent<readonly T[]> {

  /** Select options. */
  @Input({ required: true })
  public options: readonly OptionSelect<T>[] = [];

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  public compareWith = (first?: T, second?: T): boolean => first === second;
}
