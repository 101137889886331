import { Observable, tap, map, of } from 'rxjs';

import { EstimateId } from '@dartsales/common/core/models/estimate/estimate';
import { PointsListAllEntitiesIds } from '@dartsales/common/core/models/estimate/modules/points-list/points-list-all-entities-ids';
import { PointsListGroupsApiService } from '@dartsales/common/core/services/api/points-list/points-list-groups-api.service';
import { ApiTransactionId } from '@dartsales/common/core/models/api-transaction-id';
import { TransactionsApiService } from '@dartsales/common/core/services/api/transactions-api.service';

import { PointsListAbstractCommand } from '../points-list-abstract-command';

/** Remove points list entities command. */
export class RemoveEntitiesCommand extends PointsListAbstractCommand<RemoveEntitiesCommandInitArgs> {
  private readonly pointsListGroupsApiService = this.injector.get(PointsListGroupsApiService);

  private readonly transactionsApiService = this.injector.get(TransactionsApiService);

  /** Transaction ID. */
  private transactionId: ApiTransactionId | null = null;

  /** @inheritdoc */
  public override execute(): Observable<void> {
    return this.pointsListGroupsApiService.delete(this.args.estimateId, this.args.payload).pipe(
      tap(transaction => {
        this.transactionId = transaction;
      }),
      map(() => undefined),
    );
  }

  /** @inheritdoc */
  public override undo(): Observable<void> {
    if (this.transactionId == null) {
      return of(undefined);
    }

    return this.transactionsApiService.undoTransaction(this.transactionId);
  }
}

type RemoveEntitiesCommandInitArgs = {

  /** Estimate ID. */
  readonly estimateId: EstimateId;

  /** Payload. */
  readonly payload: PointsListAllEntitiesIds;
};
