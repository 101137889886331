import { Pipe, PipeTransform } from '@angular/core';

import { convertBytesToReadableSize } from '@dartsales/common/core/utils/convert-bytes-to-readable-size';

/** Convert file size with units.  */
@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {

  /**
   * Convert file size.
   * @param value File size in bytes.
   */
  public transform(value: number | null): string {
    if (value == null) {
      return '-';
    }

    return convertBytesToReadableSize(value);
  }
}
