import { FilterData } from './filter-data';
import { FilterOperator } from './filter-operator';
import { FilterOperatorType } from './filter-operator-type';

/** Base abstract class for multi condition filters. */
export abstract class FilterHelper<T extends string> {
  /** Filter fields list. */
  protected abstract readonly filterFields: readonly FilterData<T>[];

  /**
   * Get filter data.
   * @param field Filter field.
   */
  public getFilterData(field: T): FilterData<T> | undefined {
    return this.filterFields.find(item => item.type === field);
  }

  /**
   * Get filter operator.
   * @param field Filter field.
   * @param operator Filter operator.
   */
  public getOperator(field: T, operator: FilterOperatorType): FilterOperator | undefined {
    return this.getFilterData(field)?.operators.find(item => item.type === operator);
  }
}
