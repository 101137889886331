import { StrictOmit } from '@dartsales/common/core/utils/types/strict-omit';

import { PointsListSystemGroup } from '../system-group/points-list-system-group';
import { PointsListSystem } from '../system/points-list-system';

import { PointsListTabProperties } from './points-list-tab-properties';
import { ShortPointsListTab } from './short-points-list-tab';

/** Points list tab. */
export class PointsListTab extends ShortPointsListTab {
  /** System groups. */
  public readonly systemGroups: readonly PointsListSystemGroup[];

  /** Properties. */
  public readonly properties: PointsListTabProperties;

  public constructor(data: PointsListTabInitArgs) {
    super(data);
    this.systemGroups = data.systemGroups;
    this.properties = data.properties;
  }

  /** Get tab systems. */
  public getSystems(): PointsListSystem[] {
    return this.systemGroups.flatMap(group => group.systems);
  }
}

type PointsListTabInitArgs = StrictOmit<PointsListTab, 'getSystems'>;
