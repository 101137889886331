import { TreeItem } from '@dartsales/common/core/models/tree-item';
import { ModuleType } from '@dartsales/common/core/enums/module-type';
import { TermCategoryTreeItem, TermCategoryItem } from '@dartsales/common/core/models/estimate-services/term-category-tree-item';
import { TermServiceTreeItem, TermServiceItem } from '@dartsales/common/core/models/estimate-services/term-service-tree-item';
import { TermSubCategoryTreeItem, TermSubCategoryItem } from '@dartsales/common/core/models/estimate-services/term-subcategory-tree-item';
import { TermItem, TermTreeItem } from '@dartsales/common/core/models/estimate-services/term-tree-item';

import { NestedNode } from '../../nested-tree-select/models/nested-node';

import { EstimateItemsMultipleExportTreeTypes as Types } from './estimate-items-multiple-export-tree-types';
import { EstimateItemsMultipleExportTreeUtils as Utils } from './estimate-items-multiple-export-tree-utils';

export namespace EstimateItemsMultipleExportTreeMapperStrategies {

  /** Node mapper strategy. */
  export type NodeMapperStrategy<TValue, TChild> = {

    /** Node type. */
    readonly type: Types.NodeType;

    /**
     * Map node to tree item.
     * @param node Nested node.
     */
    map(node: NestedNode<Types.NodeValue<Types.NodeType, unknown>>): TreeItem<TValue, TChild> | null;
  };

  /** Term mapper strategy. */
  export class TermMapperStrategy implements NodeMapperStrategy<TermItem, TermCategoryTreeItem> {

    /** @inheritdoc */
    public readonly type = Types.NodeType.Term;

    private readonly childrenMappingStrategy = new TermCategoryMapperStrategy();

    /** @inheritdoc */
    public map(node: NestedNode<Types.TermNodeValue>): TreeItem<TermItem, TermCategoryTreeItem> | null {
      return new TermTreeItem({
        title: node.title,
        value: node.value.item,
        children: Utils.mapItems(node.children, this.childrenMappingStrategy),
      });
    }
  }

  /** Term category mapper strategy. */
  export class TermCategoryMapperStrategy implements NodeMapperStrategy<TermCategoryItem, TermSubCategoryTreeItem> {

    /** @inheritdoc */
    public readonly type = Types.NodeType.Category;

    private readonly childrenMappingStrategy = new SubCategoryMapperStrategy();

    /** @inheritdoc */
    public map(node: NestedNode<Types.CategoryNodeValue>): TreeItem<TermCategoryItem, TermSubCategoryTreeItem> | null {
      return new TermCategoryTreeItem({
        title: node.title,
        value: node.value.item,
        children: Utils.mapItems(node.children, this.childrenMappingStrategy),
      });
    }
  }

  /** Sub-category mapper strategy. */
  class SubCategoryMapperStrategy implements NodeMapperStrategy<TermSubCategoryItem, TermServiceTreeItem> {

    /** @inheritdoc */
    public readonly type = Types.NodeType.SubCategory;

    private readonly childrenMappingStrategy = new TermServiceMapperStrategy();

    /** @inheritdoc */
    public map(node: NestedNode<Types.SubCategoryNodeValue>): TreeItem<TermSubCategoryItem, TermServiceTreeItem> | null {
      return new TermSubCategoryTreeItem({
        title: node.title,
        value: node.value.item,
        children: Utils.mapItems(node.children, this.childrenMappingStrategy),
      });
    }
  }

  /** Term service mapper strategy. */
  class TermServiceMapperStrategy implements NodeMapperStrategy<TermServiceItem, ModuleType> {

    /** @inheritdoc */
    public readonly type = Types.NodeType.Service;

    /** @inheritdoc */
    public map(node: NestedNode<Types.ServiceNodeValue>): TreeItem<TermServiceItem, ModuleType> | null {
      return new TermServiceTreeItem({
        title: node.title,
        value: node.value.item,
        children: this.mapModuleTypes(node.children),
      });
    }

    private mapModuleTypes(nodes: readonly Types.EstimateChildrenNestedNode[]): ModuleType[] {
      return nodes.reduce<ModuleType[]>((items, node) => {
        if (node.value.type === Types.NodeType.ServiceModuleType) {
          return items.concat([node.value.item]);
        }
        return items;
      }, []);
    }
  }
}
