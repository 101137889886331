import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { EstimateId } from '../models/estimate/estimate';
import { TermTreeItem } from '../models/estimate-services/term-tree-item';
import { ViewType } from '../models/view-type';

import { EstimateTermsTreeApiService } from './api/estimate-terms-tree-api.service';

/** Estimate terms tree service. */
@Injectable({
  providedIn: 'root',
})
export class EstimateTermsTreeService {
  private readonly termsTreeApiService = inject(EstimateTermsTreeApiService);

  /**
   * Get estimate terms tree.
   * @param estimateId Estimate ID.
   * @param viewType Estimate view type.
   */
  public getEstimateTermTrees(
    estimateId: EstimateId,
    viewType: ViewType,
  ): Observable<TermTreeItem[]> {
    return this.termsTreeApiService.getTree(estimateId, viewType);
  }

  /**
   * Get term tree.
   * @param estimateId Estimate ID.
   * @param termId Term ID.
   */
  public getTermTreeFromInternalView(estimateId: EstimateId, termId: number): Observable<TermTreeItem | null> {
    return this.getEstimateTermTrees(estimateId, ViewType.Internal).pipe(
      map(trees => this.parseTerm(termId, trees)),
    );
  }

  private parseTerm(termId: number, termTrees: readonly TermTreeItem[]): TermTreeItem | null {
    return termTrees.find(termTree => termTree.value.termId === termId) ?? null;
  }
}
