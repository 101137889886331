import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FilterComponentType, injectFilterControl } from '@dartsales/common/core/models/filters/multi-condition-filters/filter-component';

/** String array filter input component. */
@Component({
  selector: 'dartsalesc-string-array-filter-input',
  templateUrl: './string-array-filter-input.component.html',
  styleUrls: ['./string-array-filter-input.component.css'],

  // We have nested form controls. 'Default' change detection is required for displaying errors.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StringArrayFilterInputComponent implements FilterComponentType<string[]> {

  /** @inheritdoc */
  public readonly formControl = injectFilterControl<string[]>();
}
