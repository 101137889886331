<button
  mat-icon-button
  type="button"
  class="filters-button"
  tpPlacement="right-start"
  [tpOffset]="[-20, 10]"
  [tp]="popover"
  [class.filters-button_has-indicator]="isIndicatorVisible"
>
  <mat-icon
    class="filters-button__icon"
    svgIcon="filter-list"
  />
</button>

<ng-template #popover>
  <div>
    <dartsalesc-progress-bar
      class="progress-bar"
      [loading]="isLoading"
    />
    <div class="content">
      <ng-content />
    </div>
  </div>
</ng-template>
