import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { OptionSelect } from '@dartsales/common/core/models/option-select';

import { AbstractSelectCellEditorComponent } from '../abstract-select-cell-editor.component';

/** Multi select cell editor component. */
@Component({
  selector: 'dartsalesc-multi-select-cell-editor',
  templateUrl: './multi-select-cell-editor.component.html',
  styleUrls: ['../select-cell-editor-shared-styles.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectCellEditorComponent<T> extends AbstractSelectCellEditorComponent<readonly T[]> {

  /** Options. */
  @Input({ required: true })
  public options: readonly OptionSelect<T>[] = [];

  /**
   * Compare function.
   * @param first First.
   * @param second Second.
   */
  @Input()
  public compareWith = (first?: T, second?: T): boolean => first === second;
}
