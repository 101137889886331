import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatInput } from '@angular/material/input';
import { debounceTime } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { matFormFieldControlProviderFor, MatFormFieldValueAccessor } from '@dartsales/common/core/utils/value-accessors/mat-form-field-value-accessor';
import { trackByIndex } from '@dartsales/common/core/utils/trackby';

/** Chips list component for selected items from inputComponent and from suggested autocomplete. */
@UntilDestroy()
@Component({
  selector: 'dartsalesc-chips-list-with-suggested',
  templateUrl: './chips-list-with-suggested.component.html',
  styleUrls: ['./chips-list-with-suggested.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [matFormFieldControlProviderFor(() => ChipsListWithSuggestedComponent)],
})
export class ChipsListWithSuggestedComponent extends MatFormFieldValueAccessor<string[]> implements OnInit {
  /** Select options list. */
  @Input()
  public optionsList: readonly string[] | null = [];

  /** Whether options list is loading or not. */
  @Input()
  public isLoading: boolean | null = false;

  /** Text for suggested autocomplete hint. */
  @Input()
  public suggestedText = '';

  /** Search value change event emitter. */
  @Output()
  public readonly searchChange = new EventEmitter<string>();

  /** Max length. */
  @Input()
  public maxLength: number | null = null;

  /** Go to options list next page event emitter. */
  @Output()
  public readonly goToNextPage = new EventEmitter<void>();

  /** MatInput. */
  @ViewChild(MatInput)
  private readonly matInput?: MatInput;

  /** MatAutocomplete. */
  @ViewChild(MatAutocomplete)
  private readonly matAutocomplete?: MatAutocomplete;

  private readonly fb = inject(NonNullableFormBuilder);

  /** Track by index. */
  protected readonly trackByIndex = trackByIndex;

  /** Search input control. */
  // We use "null" for first search trigger by focus after creating.
  protected readonly searchControl = this.fb.control<string | null>(null);

  /** Should disable tooltip. */
  protected get shouldDisableTooltip(): boolean {
    if (this.disabled) {
      return true;
    }
    const isControlValueEmpty = this.controlValue === null || this.controlValue.length === 0;
    const isSearchControlValueLengthEqualLimit =
      this.searchControl?.value != null && this.searchControl.value.length === this.maxLength;
    return !isControlValueEmpty && !isSearchControlValueLengthEqualLimit;
  }

  /** Tooltip text. */
  public get tooltipText(): string {
    const searchControlLength = this.searchControl.value?.length;
    if (this.maxLength != null && searchControlLength === this.maxLength) {
      return `Maximum length is ${this.maxLength}.`;
    }

    return 'Press enter to record the new value, or select from existing ones.';
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToSearchChanges();
  }

  /** @inheritdoc */
  public override setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /** @inheritdoc */
  protected focus(): void {
    this.matInput?.focus();

    // We use "null" for first search trigger by focus after creating.
    if (this.searchControl.value === null) {
      this.searchControl.setValue('');
    }
  }

  /**
   * Remove chip item.
   * @param index Index of chip item to be removed.
   */
  protected removeChipItem(index: number): void {
    const controlValue = this.controlValue ?? [];
    this.updateControlValue(controlValue.filter((_, i) => i !== index));
  }

  /**
   * Handler for selected option.
   * @param event Option selected event.
   */
  protected onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const optionValue: string = event.option.value;
    const controlValue = this.controlValue ?? [];
    const hasDuplicates = this.checkItemIsInArray(optionValue, controlValue);

    if (optionValue && hasDuplicates === false) {
      this.updateControlValue([...controlValue, optionValue]);
    }
    this.searchControl.setValue('');

    if (this.optionsList && this.optionsList.length < 10) {
      this.onOptionsScroll();
    }
  }

  /**
   * Add chip item.
   * @param event Chip input event.
   */
  protected addChipItem(event: MatChipInputEvent): void {
    const activeSelectOptions = this.matAutocomplete?.options.find(option => option.active);
    const controlValue = this.controlValue ?? [];
    const newValue = event.value.trim();
    const hasDuplicates = this.checkItemIsInArray(newValue, controlValue);
    if (
      newValue !== '' &&
      activeSelectOptions === undefined &&
      hasDuplicates === false
    ) {
      this.updateControlValue([...controlValue, newValue]);
    }
    this.searchControl.setValue('');
  }

  /** Handle optionsList scroll. */
  protected onOptionsScroll(): void {
    this.goToNextPage.emit();
  }

  private updateControlValue(newValue: readonly string[]): void {
    this.controlValue = [...newValue];
  }

  private subscribeToSearchChanges(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      untilDestroyed(this),
    )
      .subscribe(value => {
        this.searchChange.emit(value ?? '');
      });
  }

  private checkItemIsInArray(newItem: string, itemsList: readonly string[]): boolean {
    return itemsList.some(item => newItem.toLowerCase() === item?.toLowerCase());
  }
}
