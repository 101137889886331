import { Directive, ContentChildren, QueryList, forwardRef, ForwardRefFn, Provider, ViewChildren, Output, inject } from '@angular/core';

import { TableHeaderRowSortingService } from '../services/table-header-row-sorting.service';

import { EditableTableColDirective } from './editable-table-column.directive';

/**
 * In this file we have two alternative approaches for declaring header row element of EditableTable component.
 * We can use either use a directive or create a new component.
 * There is no preferable way, each solution is appropriate in different cases.
 * So it's up to you to decide which approach to use.
 */

/**
 * Create provider for a EditableTableHeaderRowComponent.
 * @param factory Factory providing a reference to component class.
 */
export function tableHeaderRowProviderFor(factory: ForwardRefFn): Provider {
  return [
    {
      provide: EditableTableHeaderRowComponent,
      useExisting: forwardRef(factory),
      multi: true,
    },
    TableHeaderRowSortingService,
  ];
}

/**
 * Base class for table header row component.
 * You have to extend this class to create your own table row component compatible with EditableTable component.
 * @example
 * ```ts
 * ‌@Component({
 *   selector: 'dartsalesw-subcontractor-table-header-row',
 *   templateUrl: './subcontractor-table-header-row.component.html',
 *   providers: [tableHeaderRowProviderFor(() => SubcontractorTableHeaderRowComponent)],
 * })
 * export class SubcontractorTableHeaderRowComponent extends EditableTableHeaderRowComponent {
 *   // Component implementation.
 * }
 * ```
 * You can use this component inside EditableTable like this:
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table header rows. -->
 *   <dartsalesw-subcontractor-table-header-row />
 * </dartsalesc-editable-table>
 * ```
 */
@Directive()
export abstract class EditableTableHeaderRowComponent {
  /** Table header row sorting service. */
  protected readonly tableHeaderRowSortingService = inject(TableHeaderRowSortingService);

  /** Sort change event. */
  @Output()
  // eslint-disable-next-line rxjs/finnish
  public readonly sortChanged = this.tableHeaderRowSortingService.activeSort$;

  /** Table columns. */
  @ViewChildren(EditableTableColDirective)
  public readonly tableColumns?: QueryList<EditableTableColDirective>;
}

/**
 * Editable table header row directive.
 * You can apply this directive to mark element inside EditableTable component as a table header row.
 * @example
 * ```html
 * <!-- dartsalesw-subcontractor-table.html -->
 * <dartsalesc-editable-table>
 *   <!-- Table header rows. -->
 *   <ng-container dartsalescEditableTableHeaderRow>
 *     <!-- Columns definitions. -->
 *   </ng-container>
 * </dartsalesc-editable-table>
 * ```
 */
@Directive({
  selector: '[dartsalescEditableTableHeaderRow]',
  providers: [tableHeaderRowProviderFor(() => EditableTableHeaderRowDirective)],
})
export class EditableTableHeaderRowDirective extends EditableTableHeaderRowComponent {
  /** Table columns. */
  @ContentChildren(EditableTableColDirective)
  public override readonly tableColumns?: QueryList<EditableTableColDirective>;
}
