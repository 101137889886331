import { Inject, Injectable } from '@angular/core';
import { defer, filter, fromEvent, map, merge, Observable, of, shareReplay, startWith, Subject } from 'rxjs';

import { WINDOW } from '../utils/window-token';

const APP_PREFIX = 'ats-dart-sales';

/**
 * Storage service. Uses `localStorage` underhood.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /** Emits the key of the changed value. */
  private readonly valueChangedSubject$ = new Subject<string>();

  private readonly localStorage: Storage;

  public constructor(
    @Inject(WINDOW) public readonly window: Window,
  ) {
    this.localStorage = this.window.localStorage;
  }

  /**
   * Save data to storage.
   * @param key Key.
   * @param data Data for save.
   */
  public save<T>(key: string, data: T): Observable<void> {
    const keyWithPrefix = this.getKeyWithAppPrefix(key);
    return defer(() => {
      this.localStorage.setItem(keyWithPrefix, JSON.stringify(data));
      this.valueChangedSubject$.next(keyWithPrefix);

      return of(undefined);
    });
  }

  /**
   * Get item from storage by key.
   * @param key Key.
   */
  public get<T = unknown>(key: string): Observable<T | null> {
    const keyWithPrefix = this.getKeyWithAppPrefix(key);
    return this.watchStorageChangeByKey(keyWithPrefix).pipe(
      startWith(undefined),
      map(() => this.obtainFromStorageByKey<T>(keyWithPrefix)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** Get all storage. */
  public getAll(): Observable<[string, string][]> {
    return defer(() => {
      const values = Object.entries<string>(this.localStorage);
      return of(values);
    });
  }

  /**
   * Removed data from storage.
   * @param key Key.
   */
  public remove(key: string): Observable<void> {
    const keyWithPrefix = this.getKeyWithAppPrefix(key);
    return defer(() => {
      this.localStorage.removeItem(keyWithPrefix);
      this.valueChangedSubject$.next(keyWithPrefix);

      return of(undefined);
    });
  }

  /**
   * Remove data from storage by predicate.
   * Keys in predicate function don't have any prefixes.
   * @param predicate Predicate that matches items to delete.
   */
  public removeByPredicate(predicate: (key: string, value: string) => boolean): Observable<void> {
    return defer(() => {
      for (const [key, value] of Object.entries(this.localStorage)) {
        if (key.startsWith(APP_PREFIX) && predicate(this.getKeyWithoutAppPrefix(key), value)) {
          this.localStorage.removeItem(key);
        }
      }

      return of(undefined);
    });
  }

  private getKeyWithoutAppPrefix(key: string): string {
    return key.replace(`${APP_PREFIX}__`, '');
  }

  private getKeyWithAppPrefix(key: string): string {
    return `${APP_PREFIX}__${key}`;
  }

  private watchStorageChangeByKey(keyToWatch: string): Observable<void> {
    const otherPageChange$ = fromEvent(this.window, 'storage').pipe(
      filter((event): event is StorageEvent => event instanceof StorageEvent),
      map(event => event.key),
    );

    // storage event happens only for the other pages of this domain, so we need to handle the local changes manually
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    const currentPageChange$ = this.valueChangedSubject$;

    return merge(
      otherPageChange$,
      currentPageChange$,
    ).pipe(
      filter(key => key === keyToWatch),
      map(() => undefined),
    );
  }

  private obtainFromStorageByKey<T = unknown>(key: string): T | null {
    const rawData = this.localStorage.getItem(key);
    if (rawData == null) {
      return null;
    }
    return JSON.parse(rawData) as T;
  }
}
